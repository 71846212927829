import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from "react";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
const ApproverItem = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const [confirm, setConfirm] = useState();
    const history = useHistory();
    function deleteFormHandler(props) {
        props.onDelete({
            "approvalMasterID": props.approvalMasterID,
            "serviceTypeID": props.serviceTypeID
        });
    }
    function onView() {
        sessionStorage.setItem("EditApprover", JSON.stringify({
            "corporateMasterID": props.corporateMasterID, "corporateMasterName": props.corporateMasterName,
            "serviceTypeID": props.serviceTypeID, "serviceTypeName": props.serviceTypeName
        }))
        history.push({
            pathname: `/UpdateApprover/${props.corporateMasterID}/0/${props.corporateMasterName}`,
            edit: 0
        })
    }
    function onEdit() {
        sessionStorage.setItem("EditApprover", JSON.stringify({
            "corporateMasterID": props.corporateMasterID, "corporateMasterName": props.corporateMasterName,
            "serviceTypeID": props.serviceTypeID, "serviceTypeName": props.serviceTypeName
        }))
        history.push({
            pathname: `/UpdateApprover/${props.corporateMasterID}/1/${props.corporateMasterName}`,
            edit: 1
        })
    }
    function onConfirm() {
        setConfirm(true);
    }
    function okHandler() {
        deleteFormHandler(props);
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };
  
    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}


        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className={props.serviceTypeName !== null?"col-md-4":"col-md-8"}>
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.corporateMasterName}
                                </h6>
                            </div>
                            {props.serviceTypeName !== null && <div className="col-md-4">
                                <p className="card-text mb-0"><strong>Service </strong>{props.serviceTypeName}</p>
                            </div>}
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end gt-test'>

                                    {props.approvalMasterID !== null && checkSecurityDetails("Settings", "Workflow", "Settings_Workflow_View") &&
                                        <span><a id={"view" + props.corporateMasterID} title="Show" onClick={() => { onView() }} className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" ><i className="fa-sharp fa-solid fa-tv"></i></a>
                                            <ReactTooltip
                                                anchorId={"view" + props.corporateMasterID}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"View"}
                                            /></span>}
                                    {checkSecurityDetails("Settings", "Workflow", "Settings_Workflow_Update") &&
                                        <span><a id={"edit" + props.corporateMasterID} onClick={() => { onEdit() }} title="Edit" className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn"
                                        ><i className="fa-solid fa-pen"></i></a> <ReactTooltip
                                                anchorId={"edit" + props.corporateMasterID}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"Edit"}
                                            /></span>}
                                    {props.approvalMasterID !== null && checkSecurityDetails("Settings", "Workflow", "Settings_Workflow_Delete") &&
                                        <span><button id={"delete" + props.corporateMasterID} title="Delete" onClick={() => { onConfirm() }} className="btn btn-sm btn-danger gt-border-radius-50 gt-red-btn text-white" ><i className="fas fa-trash-alt"></i></button>
                                            <ReactTooltip
                                                anchorId={"delete" + props.corporateMasterID}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"Delete"}
                                            /></span>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Fragment >

};

export default ApproverItem;
import { Fragment } from "react";
import RotatingPlan from "../../Flights/Components/RotatingPlan";
import WorldMap from "../../Flights/Components/WorldMap";

const BookingLoadingSpinner = (props) => {
  const isCorporate = JSON.parse(localStorage.getItem("isCorporate"));

  return (
    <Fragment>
      {/* <div className='centered'>
        <div className={classes.spinner}></div>
      </div> */}

      <div className="loading">
        <div className="loader">
          <div className="plane">
            {/* <img src="assets/img/loader5.gif" className="plane-img" /> */}
            <RotatingPlan />
          </div>
          <div className="postion-relative">
            <div className="earth-wrapper cstm">
              {/* <div className="earth"></div> */}
              <div className="worldMap">
                <div className="worldMap__inner">
                  <WorldMap className={"img1"} />
                  <WorldMap className={"img2"} />
                  <WorldMap className={"img3"} />
                  <WorldMap className={"img4"} />
                </div>
              </div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {props.action === "shopping" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait while the airline and hotel reservation system
              confirms your booking.
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          )}
          {props.action === "flight" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              {isCorporate
                ? "Please wait while your travel request is submitted."
                : "Please wait while the airline reservation system confirms your booking."}
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          )}
          {props.action === "import" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait....
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          )}
          {props.action === "requestTypeOf" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              {isCorporate
                ? "Please wait while your travel request is submitted."
                : "Please wait while your booking request is submitted."}
            </div>
          )}
          {props.action === "service" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait while your service request is updated.
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BookingLoadingSpinner;

import { getToken } from '../utils';
import {

    GET_APPROVER_ID,
    ADD_APPROVER,
    DELETE_APPROVER,
    SEARCH_APPROVER,
    APPROVER_WORKFLOW_TYPE,
    LIST_OF_SERVICE,
    GET_APPROVAL_HISTORY,
    SEARCH_WORKFLOW_STATUS,
    UPDATE_TRAVEL_REQUEST_APPROVAL,

} from './CONSTANTS';


//function to get single approver
export async function getSingleApprover(requestData) {
    const response = await fetch(GET_APPROVER_ID(), {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch approver');
    }
    return data;

}


//function to add approver
export async function addApprover(addData) {
    const response = await fetch(ADD_APPROVER(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not create approver');
    }
    return data;

}


export async function deleteSingleApprover(deleteData) {
    const response = await fetch(DELETE_APPROVER(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete approver');
    }
    return data;
}

export async function searchApprover(searchData) {
    const response = await fetch(SEARCH_APPROVER(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch approver');
    }
    return data;
}


export async function listApporverWorkflowType(searchData) {
    const response = await fetch(APPROVER_WORKFLOW_TYPE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch approver');
    }
    return data;
}

export async function listofService(searchData) {
    const response = await fetch(LIST_OF_SERVICE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch approver');
    }
    return data;
}

export async function getApprovalHistory(searchData) {
    const response = await fetch(GET_APPROVAL_HISTORY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch approval history');
    }
    return data;
}

export async function searchWorkflowStatus(searchData) {
    const response = await fetch(SEARCH_WORKFLOW_STATUS(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch workflow status');
    }
    return data;
}

export async function updateTravelRequestApproval(searchData) {
    const response = await fetch(UPDATE_TRAVEL_REQUEST_APPROVAL(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not update travel request approval status');
    }
    return data;
}
import { Fragment } from "react";
import { toCamelCase, splitString, removeHtmlTags } from "../../../utils/CommonFunction";

const Nearby = (props) => {
    let attaractios = "";
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.forEach(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
    }
    function splitLocationString(locationString) {
        // Remove the " - " delimiter if present
        locationString = locationString.replace(' - ', ' ');
        
        // Define the regex
        let regex = /^([^\d]+)([\d\.]+\s*\w+.*)$/;
        let match = locationString.match(regex);
        
        if (match) {
          return {
            name: match[1].trim(),
            distance: match[2].trim()
          };
        } else {
          return {
            name: locationString.trim(),
            distance: ''
          };
        }
      }
      
    let Attractions = [];
    let regex = /^([^\d]+)([\d\.]+\s*\w+)/;
    if (attaractios !== null && attaractios !== undefined) {
        let attaraction;
        if (attaractios.includes('<br />') || attaractios.includes('<br>')) {
            attaraction = attaractios.split(/<br\s*\/?>/i);
        } else if (attaractios.includes('\n')) {
            attaraction = attaractios.split('\n');
        } else {
            attaraction = [attaractios];
        }

        attaraction.forEach((element, index) => {
            if (element !== '' && element.trim() !== "Attractions -") {
                const parts = splitString(element.trim());
                let match = element.trim().match(regex);
                if (parts !== null && parts.length === 2) {
                    let firstPart = parts[0].trim();
                    let secondPart = parts[1].trim();
                    Attractions.push(
                        <div key={element} className='row py-1'>
                            <div className='col-md-8 fff col-sm-8 col-8'><i class="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{removeHtmlTags(firstPart)}</span></div>
                            <div className='col-md-4 gg col-sm-4 col-4 text-end'><span >{(secondPart)}</span></div>
                        </div>

                    );

                }
                else if (match !== null) {
                    let result=splitLocationString(element);
                    Attractions.push(
                        <div key={element} className='row py-1'>
                            <div className='col-md-8 fff col-sm-8 col-8'><i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{removeHtmlTags(toCamelCase(result.name))}</span></div>
                            <div className='col-md-4 gg col-sm-4 col-4 text-end'><span >{removeHtmlTags(toCamelCase(result.distance))}</span></div>
                        </div>

                    );

                }
                else {
                    Attractions.push(
                        <div key={element} className='row py-1'>
                            <div className='col-md-9 fff'><i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{removeHtmlTags(toCamelCase(element))}</span></div>

                        </div>
                    )
                }

            }



        });
    }
    // const showMoreNearBy = () => {

    //     var textElement = document.querySelector(".text-nearby");
    //     var buttonElement = document.getElementById('showMoreNearby11');
    //     if (textElement.classList.contains("show-more-nearby-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down double angle
    //     }

    //     textElement.classList.toggle("show-more-nearby-height");

    // }
    return <Fragment>
        {attaractios === '' ? "" : <div className='tour_detail_right_sidebar'>
            <h4 className="h5 mt-1 text-primary"><i className="fas fa-map-marker-alt"></i> What's Nearby</h4>
            <div className="text-nearby show-more-nearby-height">
                {Attractions}
            </div>

            {/* {Attractions.length >= 3 && 
                <div class="text-white" id='showMoreNearby11' onClick={() => { showMoreNearBy() }}>
                    <span>Show More <i class="fas fa-angle-double-right"></i></span></div>
            } */}
        </div>}
    </Fragment>
};
export default Nearby;
import { useState } from 'react';
import Dropdown from 'react-select';
import { CustomToFixed, calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';

const AddMarkup = (props) => {
    const [markupLabel, setMarkupLabel] = useState('');
    const [intialBookingJSON, setIntialBookingJSON] = useState([]);

    const prefernce_list = [
        { value: '1', label: 'Amount' },
        { value: '2', label: 'Percentage' }
    ];

    function assignMarkup() {
        if (document.querySelector('#markup').value !== "" && markupLabel === '') {
            document.querySelector('#markupPrefernce').innerHTML = "Service Fee preference is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (document.querySelector('#markup').value === "" && markupLabel !== '') {
            document.querySelector('#markupValue').innerHTML = "Service Fee is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (markupLabel === 'Percentage') {
            if (document.querySelector('#markup').value > 100) {
                document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed 100%.";
                document.querySelector('#markup').focus();
                return;
            }
            else {
                document.querySelector('#markupValue').innerHTML = "";
                document.querySelector('#markupPrefernce').innerHTML = "";
                //props.onContinueButton("5");
            }
        }
        else if (markupLabel === 'Amount') {
            let spnTotalAmount = 0;
            if (localStorage.getItem("FlightIntialTotalAmount") === null) {
                //User Story 1073: Booking Summary for Single PNR
                if (document.querySelector('#spnTotalAmountFlight').innerHTML.length > 1) {
                    if (document.querySelector('#spnTotalAmountFlight').innerHTML.indexOf('&nbsp;') !== -1) {
                        spnTotalAmount = document.querySelector('#spnTotalAmountFlight').innerHTML.split('&nbsp;')[1];
                    }
                    else {
                        spnTotalAmount = document.querySelector('#spnTotalAmountFlight').innerHTML.trim().substring(1, document.querySelector('#spnTotalAmountFlight').innerHTML.length);
                    }
                    spnTotalAmount = spnTotalAmount.replace(',', '');
                    localStorage.setItem("FlightIntialTotalAmount", spnTotalAmount);
                }
            }
            else {
                spnTotalAmount = localStorage.getItem("FlightIntialTotalAmount");
            }

            if (document.querySelector('#markup').value !== '') {
                if (parseFloat(document.querySelector('#markup').value) > parseFloat(spnTotalAmount)) {
                    document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed Total Amount.";
                    document.querySelector('#markup').focus();
                    return;
                }
                else {
                    document.querySelector('#markupValue').innerHTML = "";
                    document.querySelector('#markupPrefernce').innerHTML = "";
                    //props.onContinueButton("5");
                }
            }

        }
        else {
            document.querySelector('#markupValue').innerHTML = "";
            document.querySelector('#markupPrefernce').innerHTML = "";
            //props.onContinueButton("5");
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            if (!document.querySelector('#chkTandcZeroDispute:checked')) {
                document.querySelector('#chkTandcZeroDispute').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else if (!document.querySelector('#chkTandcChargeName:checked')) {
                document.querySelector('#chkTandcChargeName').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else {
                document.querySelector('#spnTandCMessage').innerHTML = "";
            }
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));            
            var totalFareAmount = 0;
            let calculatedAmount = 0;
            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            if (intialBookingJSON.length === 0) {
                setIntialBookingJSON(JSON.parse(localStorage.getItem("BookingData")));
            }

            if (intialBookingJSON.length === 0) {
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (markupLabel === 'Amount') {
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markup').value);                        
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }
                    else if (markupLabel === 'Percentage') {
                        calculatedAmount = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markup').value)) / 100;
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmount);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }

                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined) {
                            let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                            maxKey = maxKey + 1;
                            elementPassengerTypeQuantity.fees.fee.push({
                                "key": maxKey.toString(),
                                "currencyCode": elementPassengerTypeQuantity.fees.fee[0].currencyCode,
                                "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabel
                            });
                        }
                        else {
                            let fee = [];
                            fee.push({
                                "key": "1",
                                "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabel
                            })
                            elementPassengerTypeQuantity.fees = { fee };
                        }
                    });
                });
            }
            else {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (markupLabel === 'Amount') {
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markup').value);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }
                    else if (markupLabel === 'Percentage') {
                        calculatedAmount = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markup').value)) / 100;
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmount);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }

                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined) {
                            let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                            maxKey = maxKey + 1;
                            elementPassengerTypeQuantity.fees.fee.push({
                                "key": maxKey.toString(),
                                "currencyCode": elementPassengerTypeQuantity.fees.fee[0].currencyCode,
                                "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabel
                            });
                        }
                        else {
                            let fee = [];
                            fee.push({
                                "key": "1",
                                "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabel
                            })
                            elementPassengerTypeQuantity.fees = { fee };
                        }
                    });
                });
            }

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnMarkupAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
            }).format(markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, currencyDecimalLength) : document.querySelector('#markup').value);

            document.querySelector('#spnServiceFeeTop').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
            }).format(markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, currencyDecimalLength) : document.querySelector('#markup').value);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmount = 0;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            otherServiceMarupAmount = markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, currencyDecimalLength) : document.querySelector('#markup').value;

            let otherServiceTotal = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmount);

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(CustomToFixed(otherServiceTotal, currencyDecimalLength));

            document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountFlight') !== null) {
                document.querySelector('#spnEquivalentAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            // reset total price of payment section 
            if (document.querySelector('#spnTotalAmountTop') !== null) {
                document.querySelector('#spnTotalAmountTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));
            }
            if (document.querySelector('#spnTotalEqAmountTop') !== null) {
                document.querySelector('#spnTotalEqAmountTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
            //console.log(bookingJSON);
        }
        else {
            let bookingJSON = [];
            if (Object.keys(intialBookingJSON).length > 0) {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
            }
            else {
                bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
            }

            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnMarkupAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(0);

            document.querySelector('#spnServiceFeeTop').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(0);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmount = 0;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            //User Story 1073: Booking Summary for Single PNR
            let otherServiceTotal = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmount);
            //console.log(otherServiceTotal)
            document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(CustomToFixed(otherServiceTotal, currencyDecimalLength));

            var totalFareAmount = 0;
            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                totalFareAmount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalBaseFare.amount) + parseFloat(elementAirItinerary.airItineraryPricingInfo.totalTax.amount) + parseFloat(otherServiceTotal);
                elementAirItinerary.airItineraryPricingInfo.totalFare.amount = totalFareAmount.toFixed(currencyDecimalLength);
            });

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountFlight') !== null) {
                document.querySelector('#spnEquivalentAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            // reset total price of payment section 
            if (document.querySelector('#spnTotalAmountTop') !== null) {
                document.querySelector('#spnTotalAmountTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));
            }
            if (document.querySelector('#spnTotalEqAmountTop') !== null) {
                document.querySelector('#spnTotalEqAmountTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    if (elementPassengerTypeQuantity.fees !== undefined) {
                        let fee = [];
                        fee = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount' && a.text !== 'ServiceFee-Percentage');
                        elementPassengerTypeQuantity.fees.fee = fee;
                    }
                });
            });

            localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        }

        props.onContinueButton("5");
        // window.scrollTo({
        //     top: 680,
        //     behavior: "smooth",
        // });
        if (document.querySelector("#headingSix") !== null) {
            const yOffset = -200;
            const element2 = document.getElementById("headingSix");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const onMarkupChangeHandler = (event) => {
        setMarkupLabel(event.label);
        document.querySelector('#markupPrefernce').innerHTML = "";
    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const onTandcChargeNameChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    const onTandcZeroDisputeChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    return (<div className="accordion_flex_area">
        <div id="sequenceFour" className="accordion_left_side position-relative text-muted">
            <h5>5</h5>
            <span id="completeFive" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" >
                <button id="headingMarkup" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseMarkup"
                    aria-expanded="false" aria-controls="collapseMarkup">
                    Add Service Fee
                </button>
            </h2>
            <div id="collapseMarkup" className="accordion-collapse collapse"
                aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div className='row'>
                    {/* <div className='col-md-12'>
                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Add Service Fee</label>
            </div> */}
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='control'>
                            <input type="number" className="mb-0 form-control" id='markup' min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10"></input>
                            <span id="markupValue" className="error-text"></span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='control'>
                            <Dropdown
                                options={prefernce_list}
                                textField=""
                                dataItemKey=""
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                onChange={onMarkupChangeHandler}
                            />
                            <span id="markupPrefernce" className="error-text"></span>
                        </div>
                    </div>
                </div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcZeroDispute" onChange={(e) => onTandcZeroDisputeChangeHandler(e)}></input>&nbsp;
                    I agree that it is the agent's responsibility to ensure zero disputes on service fees and markups.</div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcChargeName" onChange={(e) => onTandcChargeNameChangeHandler(e)}></input>&nbsp;
                    I agree that this booking platform will not handle payments for service fees and markups. It is the agent's responsibility to charge and collect the payment.</div>
                <span id='spnTandCMessage' className="error-text"></span>
                <div className="row col-md-12 my-2">
                    <div className="float-end">
                        <button type="button" onClick={assignMarkup} className="btn float-end  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default AddMarkup
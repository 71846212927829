import { Fragment, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHttp from '../../../../services/use-http';
import { searchUser } from "../../../../services/User-API";
import DialogList from './Component/DialogList';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction'
import WorkflowTypeNote from './Component/WorkflowTypeNote';
import { sortData } from '../../../../utils';

const ApproverDialog = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const history = useHistory();
    const location = useLocation();
    const [hasRecord, setHasRecord] = useState(true);
    const { sendRequest, status, data: masterApproversResponse, error } = useHttp(searchUser);
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [departmentName, setDearptmentName] = useState("");
    const parms = useParams();
    const { Id, Action, Name } = parms;
    const queryParams = new URLSearchParams(location.search);
    let labelName = getConfigurationByValue(getConfigurationByValue("APPROVER_TYPE").split("-")[0]);
    const [masterApprovers, setMasterApprovers] = useState([]);
    const [filteredMasterApprovers, setFilteredMasterApprovers] = useState();
    const [selectedApprovers, setSelectedApprovers] = useState([]);

    const allowMultipleApprovers = useMemo(() => getConfigurationByBoolen("ALLOW_TO_SELECT_MULTIPLE_SAME_LEVEL_APPROVAL"), []);

    const approversForCurrentApprovalType = useMemo(() => {
        if (props.selectedApproversForAllApprovalTypes && props.selectedApproversForAllApprovalTypes.length) {
            return props.selectedApproversForAllApprovalTypes
                .filter(approver => approver.approvalTypeID === props.approvalTypeInfo.approvalTypeID);
        }
        else {
            return [];
        }
    }, [props.selectedApproversForAllApprovalTypes, props.approvalTypeInfo.approvalTypeID]);

    function namechangehandler(event) {
        setName(event.target.value.trim());
    }

    function firstNameChangehandler(event) {
        setFirstName(event.target.value.trim());
    }

    function departmentchangehandler(event) {
        setDearptmentName(event.target.value.trim());
    }

    function searchMasterApprovers() {
        let approvalTypeID = props.approvalTypeInfo.approvalTypeID === "5" ? "2" : "1";
        const requestInput = {
            firstName: firstName,
            lastName: name,
            roleID: "",
            status: "",
            isActive: true,
            isCorporate: true,
            ApprovalType: approvalTypeID,
            officeID: JSON.parse(localStorage.getItem('officeID')),
            KeyCode: getConfigurationByValue("APPROVER_TYPE").split("-")[0],
            CorporateMasterName: departmentName
        };

        sendRequest(requestInput);
    }

    function onSearchHandler(event) {
        event.preventDefault();
        setMasterApprovers(null);
        setFilteredMasterApprovers(null);
        searchMasterApprovers();
    }

    function addApproverToSelectedList(approverUserID) {

        let tempSelectedApprovers = [];

        if (allowMultipleApprovers && selectedApprovers && selectedApprovers.length) {
            tempSelectedApprovers = [...selectedApprovers];
        }

        const matchingApprover = masterApprovers.find((mApprover) => mApprover.id === approverUserID);

        if (matchingApprover) {

            const matchingCorpData = matchingApprover.corporateMasterData.find(x => x.keyCode === matchingApprover.keyCode);

            tempSelectedApprovers.push({
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: JSON.parse(localStorage.getItem('userID')),
                ipAddress: localStorage.getItem("IPAddress"),
                officeID: JSON.parse(localStorage.getItem("officeID")),
                approvalConfigurationID: getConfigurationByValue("APPROVER_TYPE"),
                approvalMasterID: Id,
                serviceTypeID: props.approvalTypeInfo.serviceTypeID,
                serviceTypeName: props.approvalTypeInfo.serviceTypeName,
                approvalMasterName: Action,
                corporateMasterID: matchingCorpData.id,
                corporateMasterName: matchingCorpData.name,
                keyCode: getConfigurationByValue("APPROVER_TYPE"),
                sequenceNumber: 1,
                approvalLevel: props.approvalTypeInfo.approvalLevel,
                approvalUserID: matchingApprover.id,
                approvalUserName: (matchingApprover.middleName !== "" ? matchingApprover.title +
                    " " + matchingApprover.firstName + " " + matchingApprover.middleName +
                    " " + matchingApprover.lastName : matchingApprover.title +
                    " " + matchingApprover.firstName + " " + matchingApprover.lastName),
                approvalUserCode: matchingApprover.employeeCode,
                approvalTypeID: props.approvalTypeInfo.approvalTypeID + '',
                approvalTypeName: props.approvalTypeInfo.approvalTypeName,
                approvalTypeNameHeader: matchingApprover.approvalTypeName
            });

            if (allowMultipleApprovers) {
                setSelectedApprovers(tempSelectedApprovers);
                filterAndSetMasterApprovers({
                    masterApprovers: masterApprovers,
                    tempSelectedApprovers: tempSelectedApprovers,
                    isFromSearch: false
                });
            }
            else {
                props.onAddToSelectedApprovalWorkflow(tempSelectedApprovers)
            }
        }
    }

    function deleteApproverFromSelectedList(id) {
        let tempSelectedApprovers = [...selectedApprovers];
        tempSelectedApprovers = tempSelectedApprovers.filter((approver) => approver.approvalUserID !== id);
        setSelectedApprovers(tempSelectedApprovers);
        filterAndSetMasterApprovers({
            masterApprovers: masterApprovers,
            tempSelectedApprovers: tempSelectedApprovers,
            isFromSearch: false
        });
    }

    function getApproversToExclude(tempSelectedApprovers) {
        let approversToExclude = [];

        if (tempSelectedApprovers) {
            //Those sent by the method caller.
            //This value can come from methods after adding or deleting from the selected list.
            //This can be different from selectedApprovers because selectedApprovers state value will be
            //updated only in the next render cycle.
            approversToExclude = [...tempSelectedApprovers];
        }
        else if (selectedApprovers) {
            //Approvers selected in the current session
            approversToExclude = [...selectedApprovers];
        }
        else {
            //Initial approvers of same level during edit 
            //or previously selected approvers in case of workflows without approval levels.
            approversToExclude = getInitialSelectedApprovers();
        }

        //Approvers already selected for other approval levels
        if (approversForCurrentApprovalType && approversForCurrentApprovalType.length) {
            approversForCurrentApprovalType.forEach(approver => {
                if (approver.approvalLevel !== props.approvalTypeInfo.approvalLevel) {
                    approversToExclude.push(approver);
                }
            });
        }

        return approversToExclude;
    }

    function filterAndSetMasterApprovers(filterCriteria) {
        
        //From the master approver list, exclude below users/approvers:
        // 1. Those already selected in the current popup session
        // 2. In case of multi level approval, approvers already selected for other approval levels
        const approversToExclude = getApproversToExclude(filterCriteria.tempSelectedApprovers);
        let tempMasterApprovers = filterCriteria.masterApprovers || masterApprovers;

        if (tempMasterApprovers && tempMasterApprovers.length) {
            let tempFilteredMasterApprovers = [];
            if (approversToExclude && approversToExclude.length) {
                tempMasterApprovers.forEach(mApprover => {
                    if (approversToExclude.every(eApprover => eApprover.approvalUserID !== mApprover.id)) {
                        tempFilteredMasterApprovers.push(mApprover);
                    }
                });
            }
            else {
                tempFilteredMasterApprovers = [...tempMasterApprovers];
            }

            if (tempFilteredMasterApprovers.length) {
                sortAndSetFilteredMasterApprovers(tempFilteredMasterApprovers);
                setHasRecord(true);
            }
            else {
                setFilteredMasterApprovers(null);
                if (filterCriteria.isFromSearch) {
                    setHasRecord(false);
                }
            }
        }
        else {
            setFilteredMasterApprovers(null);
            if (filterCriteria.isFromSearch) {
                setHasRecord(false);
            }
        }
    }

    function sortAndSetFilteredMasterApprovers(tempFilteredMasterApprovers) {
        if (!tempFilteredMasterApprovers) {
            tempFilteredMasterApprovers = [...masterApprovers];
        }

        const sortKey = queryParams.get('sort');
        let isSortingAscending = sortKey ? sortKey === 'asc' : true;

        tempFilteredMasterApprovers = sortData(tempFilteredMasterApprovers, 'firstName', isSortingAscending);
        setFilteredMasterApprovers(tempFilteredMasterApprovers);
    }

    function onAddToSelectedApprovalWorkflow() {
        let tempCurrentApprovers = [];

        selectedApprovers.forEach(selectedApprover => {

            const approvalDetails = {
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: JSON.parse(localStorage.getItem('userID')),
                ipAddress: localStorage.getItem("IPAddress"),
                officeID: JSON.parse(localStorage.getItem("officeID")),
                approvalConfigurationID: getConfigurationByValue("APPROVER_TYPE"),
                approvalMasterID: selectedApprover.approvalMasterID,
                serviceTypeID: selectedApprover.serviceTypeID,
                serviceTypeName: selectedApprover.serviceTypeName,
                approvalMasterName: selectedApprover.approvalMasterName,
                corporateMasterID: selectedApprover.corporateMasterID,
                corporateMasterName: selectedApprover.corporateMasterName,
                keyCode: getConfigurationByValue("APPROVER_TYPE"),
                sequenceNumber: 1,
                approvalLevel: props.approvalTypeInfo.approvalLevel,
                approvalUserID: selectedApprover.approvalUserID,
                approvalUserName: selectedApprover.approvalUserName,
                approvalUserCode: selectedApprover.approvalUserCode,
                approvalTypeID: selectedApprover.approvalTypeID,
                approvalTypeName: selectedApprover.approvalTypeName,
                approvalTypeNameHeader: selectedApprover.approvalTypeName
            };

            tempCurrentApprovers.push(approvalDetails);

        });

        props.onAddToSelectedApprovalWorkflow(tempCurrentApprovers);

    }

    function onSortChange(event) {
        history.push({
            pathname: location.pathname,
            search: `?sort=${event.target.value}`
        });
        sortAndSetFilteredMasterApprovers();
    }

    function getInitialSelectedApprovers() {
        let initialApprovers = [];
        if (props.selectedApproversForAllApprovalTypes && props.selectedApproversForAllApprovalTypes.length) {
            props.selectedApproversForAllApprovalTypes.forEach(approver => {
                if (approver.approvalTypeID === props.approvalTypeInfo.approvalTypeID
                    && approver.approvalLevel === props.approvalTypeInfo.approvalLevel) {
                    initialApprovers.push(approver);
                }
            });
        }
        return initialApprovers;
    }

    useEffect(() => {
        setSelectedApprovers(() => getInitialSelectedApprovers());
        searchMasterApprovers();
    }, []);

    useEffect(() => {
        if (masterApproversResponse) {
            if (masterApproversResponse.errors.status === 'FALSE' &&
                masterApproversResponse.data && masterApproversResponse.data.length) {
                setMasterApprovers(() => masterApproversResponse.data);
                filterAndSetMasterApprovers({
                    masterApprovers: masterApproversResponse.data,
                    tempSelectedApprovers: null,
                    isFromSearch: true
                });
            } else {
                setMasterApprovers(null);
                setHasRecord(false);
            }
        }
    }, [masterApproversResponse]);

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="card-body">
                    <div className="col-md-12 ">
                        <div className="form-group col-md-12">
                            {/* Approver Details */}
                            <form onSubmit={onSearchHandler}>
                                <div className="row">
                                    <div className='control col-md-3'>
                                        <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12">{t('First Name')}</label>
                                        <input id="firstName"
                                            value={firstName}
                                            onChange={firstNameChangehandler}
                                            minLength="1"
                                            maxLength="100"
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                    <div className='control col-md-3'>
                                        <label htmlFor="airlineName" className="mb-2 h6 text-primary col-lg-12">{t('Last Name')}</label>
                                        <input id="lastName"
                                            value={name}
                                            onChange={namechangehandler}
                                            minLength="1"
                                            maxLength="100"
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                    <div className='control col-md-4'>
                                        <label htmlFor="airlineName" className="mb-2 h6 text-primary col-lg-12">{t('Department')}</label>
                                        <input id="name"
                                            value={departmentName}
                                            onChange={departmentchangehandler}
                                            minLength="1"
                                            maxLength="100"
                                            className="form-control form-control-sm"
                                        />
                                    </div>

                                    <div className='control col-md-2'>
                                        <label className="mb-2 h6 text-primary col-lg-12"></label>
                                        <button id='btnSearch'
                                            type="submit"
                                            className='btn gt-btn_md text-white gt-main-btn mt-1'>
                                            {status === 'pending' ? <i className="fa fa-spinner fa-spin" ></i> : t('Search')}
                                        </button>

                                    </div>
                                </div>
                            </form>

                            <WorkflowTypeNote
                                approvalTypeID={props.approvalTypeInfo.approvalTypeID}
                                name={Name}
                                labelName={labelName} />

                            <div className="row">
                                <div className='control mt-2 col-md-12'>
                                    {!hasRecord &&
                                        <p className="error-text">{t('ErrorMessage')}</p>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {((filteredMasterApprovers && filteredMasterApprovers.length > 0) ||
                    (selectedApprovers && selectedApprovers.length > 0)) &&
                    <DialogList
                        filteredMasterApprovers={filteredMasterApprovers}
                        selectedApprovers={selectedApprovers}
                        onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
                        onAddApproverToSelectedList={addApproverToSelectedList}
                        onDeleteApproverFromSelectedList={deleteApproverFromSelectedList}
                        onSortChange={onSortChange} />
                }
            </div>
        </Fragment>)
};

export default ApproverDialog;


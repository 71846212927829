import { Fragment } from "react";
import Overview from "./Components/Overview";
import Ameneties from "./Components/Ameneties";
import HotelDetails from "./Components/HotelDetails"
const Recommendation = () => {
    let loadedHotelDetails;
    let loadedRoomDetails;
    if (sessionStorage.getItem('HotelRecommendationJson') !== null) {
        loadedHotelDetails = JSON.parse(sessionStorage.getItem('HotelRecommendationJson'));
    }
    if (sessionStorage.getItem('RoomDetailJson') !== null) {
        loadedRoomDetails = JSON.parse(sessionStorage.getItem('RoomDetailJson'));
    }
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-large-hotel.png"
    let Background = loadedHotelDetails !== null ? loadedHotelDetails.data !== undefined ? loadedHotelDetails.data.hotelResults !== undefined ? loadedHotelDetails.data.hotelResults[0].images !== undefined && loadedHotelDetails.data.hotelResults[0].images !== null ? loadedHotelDetails.data.hotelResults[0].images[0].imagePath : hotelLogoLink : "" : "" : "";
    let showPropertyAmenities = false;
    if (loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined) {
        loadedHotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining' || result.text.type === 'SafetyInfo' || result.text.type === 'Recreation' || result.text.type === 'Services' || result.text.type === 'Facilities') {
                showPropertyAmenities = true;
            }
        })

    }
    return <Fragment>

        <section className="sub-banner1">
            <div className="bg-parallax bg-1 w-100"></div>
        </section>
        <div className="col-md-12 " >
            <div className="logo-banner text-center mt-3  " style={{ width: "100%" }} >
                <img src={Background} className="px-4" style={{ height: "420px", width: "100%", backgroundSize: "cover" }}></img>
            </div>
        </div>
        <section id="tour_details_main" className="section_padding">
            <div className="container">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="row">
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && <Overview hotelDetails={loadedHotelDetails}></Overview>}
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].hotelAmenities !== undefined && <Ameneties hotelDetails={loadedHotelDetails}></Ameneties>}
                            {showPropertyAmenities && <HotelDetails hotelDetails={loadedHotelDetails}></HotelDetails>}
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </Fragment>
}
export default Recommendation;
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import { Fragment, useRef, useState, useCallback, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UPDATERATEPLAN } from "../../../navigation/CONSTANTS";
import useHttp from "../../../services/use-http";
import { searchRatePlan } from '../../../services/RatePlan-API'
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import RatePlanList from './Components/RatePlanList'
const SearchRatePlan = () => {
    useDocumentTitle('Search Rate Plan');
    const { t } = useTranslation(['translation.RatePlan']);
    const { sendRequest, status, data: loadedRatePlanData, error: ratePlanError } = useHttp(searchRatePlan);
    //Variables
    const [isFirstTime, setFirstTime] = useState("");
    const [error, setError] = useState(false);
    const rateplanCodeinputRef = useRef('');
    const gtCodeinputRef = useRef('');
    const rateplanNameinputRef = useRef('');
    const history = useHistory();

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Search")) {
            return false;
        }

        setFirstTime(true);
        const onSerachRatePlan = ({

            id: "",
            code: rateplanCodeinputRef.current.value,
            gtCode: gtCodeinputRef.current.value,
            gtName: rateplanNameinputRef.current.value,
            default: false

        });
        sendRequest(onSerachRatePlan);


    }, [sendRequest])
    useEffect(() => {
        if (status === 'completed') {
            if (loadedRatePlanData['errors'].error['code'] === '1008' || loadedRatePlanData['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedRatePlanData])
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    if (ratePlanError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>

    }
    let loadedData = [];
    for (let i in loadedRatePlanData) {
        if (loadedRatePlanData['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedRatePlanData[i];
            }
        }
    }

    function onResetHandler(event) {
        history.go('/SearchRatePlan');
    }
    function okayHandler() {
        setError(false);
        history.go('/SearchRatePlan');

    }
    const ratePlanList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <RatePlanList ratePlan={loadedData} /> : ""
    return <Fragment>
        {error && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('searchRatePlan')}</label>
                                            <form>
                                                <div className="row">
                                                    <div className='control col-lg-6'>
                                                        <label htmlFor='ratePlanCode' className="h6 text-primary mb-2 col-lg-12">{t('ratePlanCode')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='ratePlanCode' ref={rateplanCodeinputRef} maxLength={10} autoFocus />
                                                    </div>
                                                    <div className='control col-lg-6'>
                                                        <label htmlFor='gtCode' className="h6 text-primary mb-2 col-lg-12">{t('gtCode')}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='gtCode' ref={gtCodeinputRef} maxLength={10}
                                                        />

                                                    </div>
                                                </div>
                                                <div className='control col-lg-12'>
                                                    <label htmlFor='ratePlanName' className="h6 text-primary mb-2 col-lg-12">{t('ratePlanName')}</label>
                                                    <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='ratePlanName' ref={rateplanNameinputRef} maxLength={50}
                                                    />

                                                </div>
                                                <div>
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATERATEPLAN}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Search") && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {ratePlanList}
    </Fragment>
};
export default SearchRatePlan;
import { Fragment } from 'react';
import Dropdown from 'react-dropdown';


const serviceOptions = [
    { value: 'Hidden', label: 'Hidden' },
    { value: 'Mandatory', label: 'Mandatory' },
    { value: 'Optional', label: 'Optional' }
];

const DepartmentNew = () => {
    // useDocumentTitle('Home')
    return <Fragment>
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>


        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {/* {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    } */}

                                    <div className="card-body">
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Department New</label>
                                                </div>
                                                <div className='col-md-6'>
                                                    {/* <div className='float-lg-end'><span title="Send a Mail" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fas fa-envelope"></i></span><span title="Take a Print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i></span></div> */}
                                                </div>
                                            </div>


                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'>Name <span class="error-icon"> *</span></label>
                                                            <input type="text" className="form-control bg_input" />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className='mt-3 actions row'>
                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                    <a href="/SearchDepartment" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                    <div className="float-md-end float-lg-end">
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' >Reset</button>

                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' >
                                                            Save
                                                        </button>
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




    </Fragment>

};

export default DepartmentNew;
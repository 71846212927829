import { Fragment, useEffect, useState } from 'react';
import RulesForm from "./Components/RulesForm";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useHttpGet from '../../../services/use-http'
import useHttp from '../../../services/use-http'
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { addPolicy, updatePolicy, getSinglePolicy } from '../../../services/Rules-API';


const UpdateRules = () => {
    useDocumentTitle('Policy')
    const { t } = useTranslation(['translation.Rules']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Action, Id } = parms;
    let varHttp;
    if (Id === undefined || Id === '0') {
        varHttp = addPolicy;
    } else {
        varHttp = updatePolicy;
    }
    const { sendRequest: sendRequestPolicy, data: loadedPolicy, error } = useHttpGet(getSinglePolicy);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();
    const sucsssMessage = (Id !== undefined || Id !== 0) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {
        if (isEdit || Id === undefined || Id === 'null') {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])
    useEffect(() => {
        if (Id !== 'null' && Id !== undefined) {
            sendRequestPolicy(Id)
        }
    }, [sendRequestPolicy, Id])
    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }
    let loadedData = [];
    for (let index in loadedPolicy) {
        if (loadedPolicy['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedPolicy[index];
            }
        }
    }
    const addPolicyHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/SearchPolicy');
        }
    };
    if (Id !== undefined && Id !== 'null' && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    const RulesFormContent = (Id !== undefined && Id !== 'null' && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <RulesForm loadedPolicyDetails={loadedData} isLoading={status === 'pending'} onAddPolicy={addPolicyHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return (<Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {RulesFormContent}
    </Fragment>);
}

export default UpdateRules;
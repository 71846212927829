import gtDB from "../indexedDatabase/db";
import { useLiveQuery } from "dexie-react-hooks";

export function useUploadedDocuments() {
    return useLiveQuery(() => gtDB.travelRequest_document_upload.orderBy('documentSortKey').toArray(response => {
        let uploadedDocs = [];
        response.forEach(doc => {
            uploadedDocs.push(doc.document);
        });

        return uploadedDocs;
    }));
};

export function addDocument(documentToUpload) {
    return gtDB.travelRequest_document_upload.add({
        id: documentToUpload.documentID,
        documentSortKey: documentToUpload.documentSortKey,
        document: documentToUpload
    });
}

export function deleteDocument(docID) {
    return gtDB.travelRequest_document_upload.delete(docID);
}

export function deleteAllDocuments() {
    return gtDB.travelRequest_document_upload.clear();
}

export function updateDocumentRemarks(remarks) {
    return gtDB.travelRequest_document_upload
        .toCollection()
        .modify(doc => {
            doc.document.remarks = remarks
        });
}

import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import Wrapper from "../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import Dropdown from 'react-select'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { RoleOfficeWiseList, searchApprovalTypeList } from '../../../services/User-API'
import useHttp from '../../../services/use-http'
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { validateEmail, passwordValidation } from "../../../utils/Validation";
import { encrypted, scrollToLoader } from '../../../utils';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import CountryDropDownList from '../../../components/Common/DropDownList/CountryDropDownList';
import CityDropDownList from '../../../components/Common/DropDownList/CityDropDownList';
import { searchCorporateMaster } from "../../../services/CorporateMaster-API";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';




const approvalOptions = [
    { value: "2", label: "Final Approver Only" },
    { value: "3", label: "None" },
    { value: "1", label: "Standard" },
];
const documentOptions = [
    { value: 'Iqama', label: "Iqama" },
    { value: 'National ID', label: "National ID" },
    { value: 'Passport', label: "Passport" },

];
const titleOptions = [
    { value: 'Mr.', label: "Mr." },
    { value: 'Mrs.', label: "Mrs." },
    { value: 'Ms.', label: "Ms." },
    { value: 'Dr.', label: "Dr." },

];
const genderOptions = [
    { value: 'Male', label: "Male" },
    { value: 'Female', label: "Female" },
];




const UserForm = (props) => {
    const { t } = useTranslation(['translation.User']);
    const history = useHistory();
    const inputFirstNameRef = useRef("");
    const inputmiddleNameRef = useRef("");
    const inputLastNameRef = useRef("");
    const inputEmployeeCodeRef = useRef("");
    const inputEmailIdeRef = useRef("");
    const activeInputRef = useRef('');
    const phoneNumberInputRef = useRef('');
    const postalCodeInputRef = useRef("");
    const addressInputRef = useRef("");

    const [active, setActive] = useState(true);
    const [activeUserAction, setActiveUserAction] = useState(false);
    const [role, setRole] = useState("");
    const [docType, setDocType] = useState('');
    const inputLoginEmailIdRef = useRef("");
    const inputPasswordRef = useRef('');
    const inputConfirmPasswordRef = useRef('');
    const firstApproverInputRef = useRef('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    const [roleDisable, setRoleDisable] = useState(false);
    const [iSDisabled, setiSDisabledDDL] = useState('false');
    const [isFirtTime, setFirstTime] = useState(true);
    const [docId, setDocId] = useState();
    const [isactualApprover, setIsActualApprover] = useState(false);
    const [isPersonalBooking, setIsPersonalBooking] = useState(false);
    const [isNeedApproval, setIsNeedApproval] = useState(false);
    const [isTravelPolicy, setIsTravelPolicy] = useState(false);
    const [issueDate, setIssueDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [approvalValue, setApprovalValue] = useState('');
    const [approvalType, setApprovalType] = useState('');
    const [approvalTypeValue, setApprovalTypeValue] = useState([]);
    const [isDropDownDisable, setDropDownDisable] = useState(false);
    const [isactionButtonDisable, setActionButtonDisable] = useState(false);
    const [number, setNumber] = useState('');
    const [title, setTitle] = useState('');
    const [gender, setGender] = useState('');


    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [documentIdforDelete, setDocumentIdForDelete] = useState(null);
    const [confirm, setConfirm] = useState();


    const [titleInputHasError, setTitleInputHasError] = useState(false);
    const [genderInputHasError, setGenderInputHasError] = useState(false);
    const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
    const [firstNameInvalidInput, setFirstNameInvalidInput] = useState(false);

    const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
    const [employeeCodeInputHasError, setEmployeeCodeInputHasError] = useState(false);
    const [employeeCodeInvalidInput, setEmployeeCodeInvalidInput] = useState(false);
    const [lastNameInvalidInput, setLastNameInvalidInput] = useState(false);
    const [emailIdInputHasError, setInputEmailIdHasError] = useState(false);
    const [emailIdInvalidInput, setEmailIdInvalidInput] = useState(false);
    const [middleNameInvalidInputError, setMiddleNameInvalidInputError] = useState(false);
    const [roleBlankError, setRoleBalnkError] = useState(false);
    const [loginEmailIdInputHasEroor, setLoginEmailIdInputHasError] = useState(false);
    const [invalidLoginEmailId, setInvalidLoginEmailId] = useState(false);
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordBlankError, setPasswordBlankError] = useState(false);
    const [confirmPasswordBlankError, setConfirmPasswordBlankError] = useState(false);
    const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);
    const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
    const [pageTitle, setPageTitle] = useState(" User");
    const [costCenter, setCostCenter] = useState('')
    const [department, setdeparment] = useState('')
    const [postion, setpostion] = useState('');
    const [DOB, setDob] = useState('');
    const [DOJ, setDOJ] = useState('');
    const [nationality, setNationality] = useState('');
    const [placeOfIssue, setPlaceOfIssue] = useState('');
    const [placeOfIssueName, setPlaceOfIssueName] = useState('');
    const [nationalityName, setNationalityName] = useState('');
    const [documentDetails, setDocumentDetails] = useState([]);
    const [costCenterInputHasError, setCostCenterInputHasError] = useState(false);
    const [deparmentInputHasError, setDepartmentInputHasError] = useState(false);
    const [postionInputHasError, setPositionInputHasError] = useState(false);
    const [DOBInputHasError, setDOBInputHasError] = useState(false);
    const [DOJInputHasError, setDOJInputHasError] = useState(false);
    const [DocTypeInputHasError, setDocTypeInputHasError] = useState(false);
    const [DocNumberInputHasError, setDocNumberInputHasError] = useState(false);
    const [IssueDateInputHasError, setDocIssueDateInputHasError] = useState(false);
    const [expiryDateInputHasError, setExpiryDateInputHasError] = useState(false);
    const [invalidExpiryDate, setInvalidExpiryDate] = useState(false)
    const [placeOfIssueInputHasError, setPlaceOfIssueInputHasError] = useState(false);
    const [nationalityInputHasError, setNationalityInputHasError] = useState(false);
    const [addressInputHasError, setAddressInputHasError] = useState(false);
    const [approvalRequiredError, setapprovalRequiredError] = useState(false);
    const [invalidPassportNumber, setInvalidPassportNumber] = useState(false);
    const [invalidNationalId, setInvalidNationalID] = useState(false);
    const [invalidIqamaNumber, setInvalidIqamaNumber] = useState(false);
    const [invalidPostalCode, setinavlidPostalCode] = useState(false);
    const [invalidDojError, setInvalidDojError] = useState(false);
    const [duplicateDocumentType, setDuplicateDocumentType] = useState(false);
    const [isDisableDocumentType, setIsDisableDocumentType] = useState(false);
    const [isPhoneNumberFieldTouched, setIsPhoneNumberFieldTouched] = useState(false);
    var TodayDate = new Date();

    const { sendRequest: sendRequestRoleList, data: loadedRole } = useHttp(RoleOfficeWiseList);
    const { sendRequest: sendRequestApprovalTypeList, data: loadedApprovalType } = useHttp(searchApprovalTypeList);
    const { sendRequest: sendRequestCostCenterList, data: loadedCostCenter } = useHttp(searchCorporateMaster);
    const { sendRequest: sendRequestDeparmentList, data: loadedDeparment } = useHttp(searchCorporateMaster);
    const { sendRequest: sendRequestPostionList, data: loadedPostion } = useHttp(searchCorporateMaster);
    const { sendRequest: sendEncrypted, data: loadedEcryptedData, status: encryptedStatus, } = useHttp(encrypted);

    //Change Handlers
    const travelPolicyChangeHandler = (event) => {
        setIsTravelPolicy(!isTravelPolicy)
    }
    const titleChangeHandler = (event) => {
        setTitle(event.value);
        setTitleInputHasError(false);
    }
    const genderChangeHandler = (event) => {
        setGender(event.value);
        setGenderInputHasError(false);
    }
    const numberChangeHandler = (event) => {
        setNumber(event.target.value);
        if (event.target.value.trim() !== '') {
            setDocNumberInputHasError(false);
        } else {
            setDocNumberInputHasError(true);
        }

        if (docType === 'Passport') {
            if (event.target.value.trim() !== '' && !event.target.value.match('^[a-zA-Z0-9]+$') || event.target.value.length > 15) {
                setInvalidPassportNumber(true);
                setInvalidNationalID(false);
                setInvalidIqamaNumber(false);
                setDocNumberInputHasError(false);
                return;
            } else {
                setInvalidPassportNumber(false);

            }

        }
        if (docType === 'National ID') {
            if (event.target.value.trim() !== '' && !event.target.value.match('^[a-zA-Z0-9-]+$') || event.target.value.length > 21) {
                setInvalidNationalID(true);
                setInvalidPassportNumber(false);
                setInvalidIqamaNumber(false);
                setDocNumberInputHasError(false);
                return;
            } else {
                setInvalidNationalID(false);
            }
        }
        if (docType === 'Iqama') {
            if (event.target.value.trim() !== '' && !event.target.value.match('^[0-9]+$') || event.target.value.length !== 10) {
                setInvalidIqamaNumber(true);
                setInvalidNationalID(false);
                setInvalidPassportNumber(false);
                setDocNumberInputHasError(false);
                return;
            } else {
                setInvalidIqamaNumber(false);
            }
        }

    }
    const needApprovalChangeHandler = (event) => {
        setIsNeedApproval(!isNeedApproval);
    }
    const personalBookingChangeHandler = (event) => {
        setIsPersonalBooking(!isPersonalBooking);
    }
    const actualApproverChangeHandler = (event) => {
        setIsActualApprover(!isactualApprover);
    }
    const costCenterChangeHandler = (event) => {
        setCostCenter(event.value);
        setCostCenterInputHasError(false);
    }
    const approvalChangeHandler = (event) => {
        setApprovalValue(event.value);
        setapprovalRequiredError(false);
    }
    const approvalTypeChangeHandler = (event) => {
        let temp = [];
        event.forEach(element => {
            temp.push({ value: element.value })
        });
        setApprovalType(event);
    }
    const documentTypeChangeHandler = (event) => {
        setDuplicateDocumentType(false);
        setDocTypeInputHasError(false);
        setNumber('');
        if (document.getElementById("AddButton") !== null) {

            document.getElementById('AddButton').style.display = ''
        }
        setInvalidPassportNumber(false);
        setInvalidNationalID(false);
        setInvalidIqamaNumber(false);
        setDocType(event.value);
    }
    const departmentChangeHandler = (event) => {
        setdeparment(event.value);
        setDepartmentInputHasError(false);
    }
    const postionChangeHandler = (event) => {
        setpostion(event.value);
        setPositionInputHasError(false);
    }
    const dobChangeHandler = (inputDate) => {
        setDob(inputDate.target.value.trim())
        setDOBInputHasError(false);
        document.getElementById("dateOfBirth").value = moment(inputDate.target.value.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
    }
    const dojChangeHandler = (inputDate) => {
        setDOJ(inputDate.target.value.trim())
        setDOJInputHasError(false);
        document.getElementById("dateOfJoiningId").value = moment(inputDate.target.value.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
    }
    const issueDateChangeHandler = (inputDate) => {
        setIssueDate(inputDate.target.value.trim())
        setDocIssueDateInputHasError(false);
        document.getElementById("DocIssueDate").value = moment(inputDate.target.value.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
    }
    const expiryDateChangeHandler = (inputDate) => {
        setExpiryDate(inputDate.target.value.trim())
        setExpiryDateInputHasError(false);
        document.getElementById("DocExpiryDate").value = moment(inputDate.target.value.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
    }
    const countryNameText = (country_Name) => {
        setPlaceOfIssue(country_Name)
    };
    const handleNationalityNameChange = (country_Name) => {
        setNationalityName(country_Name);

    };
    const handlePlaceOfIssueChange = (country_Name) => {
        setPlaceOfIssueName(country_Name)

    };
    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);


    };
    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };
    const activeChangeHandler = () => {
        setActiveUserAction(true);
        if (document.querySelector('#active').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }
    const clearDocument = () => {
        setIsDisableDocumentType(false);
        setDocType('');
        setPlaceOfIssue('');
        setPlaceOfIssueName('');
        setNationality('');
        setNationalityName("");
        setIssueDate('');
        setExpiryDate('');
        setNumber('');
        document.getElementById('docNumber').value = "";
        document.getElementById('DocIssueDate').value = "";
        document.getElementById('DocExpiryDate').value = "";

    }

    const onAddDocument = (event) => {
        event.preventDefault();
        const tempDoc = documentDetails;
        const DocType = {
            "documentTypeID": "",
            "documentNumber": "",
            "issueDate": "",
            "expiryDate": "",
            "placeOfIssue": "",
            "placeOfIssueName": "",
            "nationality": "",
            "nationalityName": "",
            "documentID": "",

        };
        if (docType === '') {
            setDocTypeInputHasError(true);
            return;
        } else {
            setDocTypeInputHasError(false);
        }

        if (docType !== '') {
            if (number.trim() === '') {
                setDocNumberInputHasError(true);
                setInvalidNationalID(false);
                setInvalidIqamaNumber(false);
                setInvalidPassportNumber(false);
                return;
            } else {
                setDocNumberInputHasError(false);

            }
            if (docType === 'Passport') {
                if (number.trim() !== '' && !number.match('^[a-zA-Z0-9]+$') || number.length > 15) {
                    setInvalidPassportNumber(true);
                    setInvalidNationalID(false);
                    setInvalidIqamaNumber(false);
                    setDocNumberInputHasError(false);
                    return;
                } else {
                    setInvalidPassportNumber(false);

                }

            }
            if (docType === 'National ID') {
                if (number.trim() !== '' && !number.match('^[a-zA-Z0-9-]+$') || number.length > 21) {
                    setInvalidNationalID(true);
                    setInvalidPassportNumber(false);
                    setInvalidIqamaNumber(false);
                    setDocNumberInputHasError(false);
                    return;
                } else {
                    setInvalidNationalID(false);
                }
            }
            if (docType === 'Iqama') {
                if (number.trim() !== '' && !number.match('^[0-9]+$') || number.length !== 10) {
                    setInvalidIqamaNumber(true);
                    setInvalidNationalID(false);
                    setInvalidPassportNumber(false);
                    setDocNumberInputHasError(false);
                    return;
                } else {
                    setInvalidIqamaNumber(false);
                }
            }


            if (issueDate === '') {
                setDocIssueDateInputHasError(true);
                return;
            } else {
                setDocIssueDateInputHasError(false);

            }
            if (expiryDate === '') {
                setExpiryDateInputHasError(true);
                return;
            } else {
                setExpiryDateInputHasError(false);

            }
            if (new Date(expiryDate) < new Date(issueDate)) {
                setInvalidExpiryDate(true);
                return;
            } else {
                setInvalidExpiryDate(false);
            }
            if (placeOfIssue === '') {
                setPlaceOfIssueInputHasError(true);
                return;
            } else {
                setPlaceOfIssueInputHasError(false);
            }
            if (nationality === '') {
                setNationalityInputHasError(true);
                return;
            } else {
                setNationalityInputHasError(false);
            }


        }

        if (docId === undefined || docId === '' || docId === null) {
            //Add Documnet
            if (docType !== '' && documentDetails.length !== 0) {
                let duplicate = documentDetails.filter(x => x.documentID === docType)
                if (duplicate.length !== 0) {
                    setDuplicateDocumentType(true);
                    const yOffset = -250;
                    const element2 = document.getElementById("docSection");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                } else {
                    setDuplicateDocumentType(false);
                }
            }
            DocType.documentID = docType;
            DocType.documentTypeID = docType;
            DocType.documentNumber = number;
            DocType.issueDate = issueDate;
            DocType.expiryDate = expiryDate;
            DocType.placeOfIssue = placeOfIssue;
            DocType.placeOfIssueName = placeOfIssueName;
            DocType.nationality = nationality;
            DocType.nationalityName = nationalityName;
            tempDoc.push(DocType);

        } else {
            tempDoc.forEach(element => {
                if (element.documentID === docId) {
                    element.documentID = docId;
                    element.documentTypeID = docType;
                    element.documentNumber = number;
                    element.issueDate = issueDate
                    element.expiryDate = expiryDate
                    element.placeOfIssue = placeOfIssue;
                    element.placeOfIssueName = placeOfIssueName;
                    element.nationality = nationality;
                    element.nationalityName = nationalityName;
                }
            });
            if (document.querySelector('#AddButton') !== undefined && document.querySelector('#AddButton') !== null) {
                document.querySelector('#AddButton').innerHTML = "Add";
            }
            setDocId(null);
        }
        setDocumentDetails(tempDoc)
        clearDocument();
    }

    const setPhone = (value) => {
        setIsPhoneNumberFieldTouched(true);
        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberHasError(true);
            setPhoneNumber("");
            return;
        }
        else {
            let countryDialCodeLength = phoneNumberInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneNumberInputRef.current.numberInputRef.value.split(' ')[0]);

            setPhoneNumber(phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberHasError(false);
        }
    };

    useEffect(() => {

        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedUserDetails.length !== 0 && isEditMode === 100) {

            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (props.loadedUserDetails.length !== 0) {
            if (inputFirstNameRef.current.value === '') {
                inputFirstNameRef.current.value = props.loadedUserDetails.firstName;
            }
            if (inputLastNameRef.current.value === '') {
                inputLastNameRef.current.value = props.loadedUserDetails.lastName;
            }
            if (inputmiddleNameRef.current.value === '') {
                inputmiddleNameRef.current.value = props.loadedUserDetails.middleName;
            }
            if (inputEmailIdeRef.current.value === '') {
                inputEmailIdeRef.current.value = props.loadedUserDetails.email;
            }
            if (inputLoginEmailIdRef.current.value === '') {
                inputLoginEmailIdRef.current.value = props.loadedUserDetails.loginEmailID;
            }




            if (title === '' && props.loadedUserDetails.title !== null) {
                setTitle(props.loadedUserDetails.title)
            }
            if (gender === '' && props.loadedUserDetails.gender) {
                setGender(props.loadedUserDetails.gender)
            }
            if (isPhoneNumberFieldTouched === false) {
                const phoneNumberCode = props.loadedUserDetails.phoneCode;
                const phoneNumbers = props.loadedUserDetails.phoneNumber;

                setCountryDialCode(phoneNumberCode.length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : phoneNumberCode);
                setPhoneNumber(phoneNumbers);
            }

            setRoleDisable(false);

            if (role === '') {
                setRole(props.loadedUserDetails.roleID);
            }
            if (activeUserAction === false) {
                setActive(props.loadedUserDetails.isActive);
            }

            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                if (inputEmployeeCodeRef.current.value === '') {
                    inputEmployeeCodeRef.current.value = props.loadedUserDetails.employeeCode;
                }
                if (addressInputRef.current.value === '') {
                    addressInputRef.current.value = props.loadedUserDetails.address;
                }
                if (postalCodeInputRef.current.value === '') {
                    postalCodeInputRef.current.value = props.loadedUserDetails.postalCode;
                }
                if (firstApproverInputRef.current.value === '') {
                    firstApproverInputRef.current.value = props.loadedUserDetails.firstApprover;
                }



                setDropDownDisable(false);
                setActionButtonDisable(false);
                if (costCenter === '' && props.loadedUserDetails.costCentreID !== null) {
                    setCostCenter(props.loadedUserDetails.costCentreID)
                }
                if (department === '' && props.loadedUserDetails.departmentID !== null) {
                    setdeparment(props.loadedUserDetails.departmentID)
                }
                if (postion === '' && props.loadedUserDetails.positionID !== null) {
                    setpostion(props.loadedUserDetails.positionID)
                }
                if (DOB === '' && props.loadedUserDetails.dob !== null) {
                    setDob(props.loadedUserDetails.dob);
                    if (document.getElementById("dateOfBirth") !== null) {
                        document.getElementById("dateOfBirth").value = moment(props.loadedUserDetails.dob, 'MM/DD/YYYY').format('D MMM YYYY')
                    }

                }
                if (DOJ === '' && props.loadedUserDetails.doj !== null) {
                    setDOJ(props.loadedUserDetails.doj);
                    if (document.getElementById("dateOfJoiningId") !== null) {
                        document.getElementById("dateOfJoiningId").value = moment(props.loadedUserDetails.doj.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
                    }

                }
                if (countryCode === '') {
                    setCountryCode(props.loadedUserDetails.country)
                }
                if (cityCode === '') {
                    setCityCode(props.loadedUserDetails.city)
                }
                if (approvalValue === '') {
                    setApprovalValue(props.loadedUserDetails.approvalRequiredID)
                }
                if (approvalType === '') {
                    setApprovalTypeValue(props.loadedUserDetails.approvalType);


                }
                if (documentDetails.length === 0) {
                    setDocumentDetails(props.loadedUserDetails.documentTypes)
                }
                setIsPersonalBooking(props.loadedUserDetails.isMakePersonalBookings);
                setIsNeedApproval(props.loadedUserDetails.isNeedsApprovalForPersonalBookings);
                setIsActualApprover(props.loadedUserDetails.isDelegateActualApprover);
                setIsTravelPolicy(props.loadedUserDetails.isTravelPolicy)


            }


        }
        else {
            if (phoneNumber === '' && countryDialCode === '') {
                setCountryDialCode(getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE"));
            }
            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                document.getElementById('dateOfBirth').value = "";
                document.getElementById('dateOfJoiningId').value = "";
                document.getElementById('DocIssueDate').value = "";
                document.getElementById('DocExpiryDate').value = "";
            }

        }

        // setFirstTime(false);
        setiSDisabledDDL('false');
        if (props.loadedUserDetails.length === 0) {
            setRoleDisable(false);
            document.getElementById('btnNew').style.display = 'none';
            setPageTitle(" New User");
        }
        //View Page
        if (isEditMode === 0) {
            document.getElementById("firstName").readOnly = true;
            document.getElementById("lastName").readOnly = true;
            document.querySelector("#active").disabled = true;
            if (props.loadedUserDetails.middleName.length !== '') {
                document.getElementById("middleName").readOnly = true;
            }
            else {
                document.getElementById("middleName").style.visibility = "hidden";
            }

            document.getElementById("emailId").readOnly = true;
            document.getElementById("loginEmailId").readOnly = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            [].forEach.call(document.querySelectorAll('password'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
            setPageTitle(" View User");
            setRoleDisable(true);
            setiSDisabledDDL('true');
            document.getElementById("dvPasswordArea").style.display = "none";
            setDropDownDisable(true);
            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                document.getElementById('docSection1').style.display = 'none';
                if (props.loadedUserDetails.documentTypes.length === 0) {
                    document.getElementById('docSection2').style.display = 'none';
                } else {
                    document.getElementById('docSection2').style.display = '';
                }

                document.getElementById('checkBoxes1').disabled = true;
                document.getElementById('checkBoxes2').disabled = true;
                document.getElementById('checkBoxes3').disabled = true;
                document.getElementById('checkBoxes4').disabled = true;

                setActionButtonDisable(true);
                document.getElementById("employeeCode").readOnly = true;
                document.getElementById("address").readOnly = true;
                document.getElementById("postalCode").readOnly = true;
                document.getElementById("firstApprover").readOnly = true;
                if (props.loadedUserDetails.dob === null) {
                    document.getElementById('dateOfBirth').value = "";
                }
                if (props.loadedUserDetails.doj === null) {
                    document.getElementById('dateOfJoiningId').value = "";
                }


            }
        }
        else if (isEditMode === 1) {
            document.getElementById("firstName").readOnly = false;
            document.getElementById("emailId").readOnly = false;
            document.getElementById("loginEmailId").readOnly = true;
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setRoleDisable(false);
            document.getElementById("requiredIndicator").style.display = "";
            setPageTitle(" Edit User");
            document.getElementById("dvPasswordArea").style.display = "none";
            setiSDisabledDDL('false');
            setDropDownDisable(false);
            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                document.getElementById('docSection1').style.display = '';
                document.getElementById('docSection2').style.display = '';
                setActionButtonDisable(false);
                document.getElementById("employeeCode").readOnly = false;
                document.getElementById('DocIssueDate').value = "";
                document.getElementById('DocExpiryDate').value = "";
                document.getElementById("firstApprover").readOnly = true;
                if (props.loadedUserDetails.dob === null) {
                    document.getElementById('dateOfBirth').value = "";
                }
                if (props.loadedUserDetails.doj === null) {
                    document.getElementById('dateOfJoiningId').value = "";
                }

            }
        }

    }, [props.loadedUserDetails.length,
    props.loadedUserDetails.firstName,
    props.loadedUserDetails.lastName,
    props.loadedUserDetails.employeeCode,
    props.loadedUserDetails.emailID,
    props.loadedUserDetails.middleName,
    props.loadedUserDetails.phoneCode,
    props.loadedUserDetails.phoneNumber,
    props.loadedUserDetails.loginEmailID,
    props.loadedUserDetails.roleID,
    props.loadedUserDetails.email,
    props.loadedUserDetails.isActive,
    props.loadedUserDetails.title,
    props.loadedUserDetails.gender,
        role,
        activeUserAction,
        active,
        phoneNumber,
        countryDialCode,
        roleDisable, isPhoneNumberFieldTouched,
        // documentDetails

    ])




    let roleOptions = []
    let roleArrayData = [];
    for (let i in loadedRole) {
        if (loadedRole['errors'].status === 'FALSE') {
            if (i === 'data') {
                roleArrayData = loadedRole[i];
            }
        }
    }

    for (let index = 0; index < roleArrayData.length; index++) {
        roleOptions = [...roleOptions, { value: roleArrayData[index].id, label: roleArrayData[index].roleName }]
    }
    let CostCenterOptions = []
    let costCenterArray = [];
    for (let i in loadedCostCenter) {
        if (loadedCostCenter['errors'].status === 'FALSE') {
            if (i === 'data') {
                costCenterArray = loadedCostCenter[i];
            }
        }
    }

    for (let index = 0; index < costCenterArray.length; index++) {
        CostCenterOptions = [...CostCenterOptions, { value: costCenterArray[index].id, label: costCenterArray[index].name }]
    }
    let deparmentOptions = []
    let deparmentArray = [];
    for (let i in loadedDeparment) {
        if (loadedDeparment['errors'].status === 'FALSE') {
            if (i === 'data') {
                deparmentArray = loadedDeparment[i];
            }
        }
    }

    for (let index = 0; index < deparmentArray.length; index++) {
        deparmentOptions = [...deparmentOptions, { value: deparmentArray[index].id, label: deparmentArray[index].name }]
    }
    let postionOptions = []
    let postionArray = [];
    for (let i in loadedPostion) {
        if (loadedPostion['errors'].status === 'FALSE') {
            if (i === 'data') {
                postionArray = loadedPostion[i];
            }
        }
    }

    for (let index = 0; index < postionArray.length; index++) {
        postionOptions = [...postionOptions, { value: postionArray[index].id, label: postionArray[index].name }]
    }
    let approvalTypeOptions = []
    let approvalTypeArray = [];
    for (let i in loadedApprovalType) {
        if (loadedApprovalType['errors'].status === 'FALSE') {
            if (i === 'data') {
                approvalTypeArray = loadedApprovalType[i];
            }
        }
    }

    for (let index = 0; index < approvalTypeArray.length; index++) {
        approvalTypeOptions = [...approvalTypeOptions, { value: approvalTypeArray[index].approvalTypeID, label: approvalTypeArray[index].approvalType }]
    }



    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
    };
    const handleNationalityCode = (countryCode) => {
        setNationality(countryCode);
    };
    const handlePlaceOfIssueCountryCode = (countryCode) => {
        setPlaceOfIssue(countryCode);
    };


    function changeLocation(event) {

        if (event.target.id === 'btnNew') {
            history.push('/NewUser');
            history.go();
        }

        if (event.target.id === 'btnReset') {
            if (props.props.loadedUserDetails === null || props.props.loadedUserDetails.length === 0) {

                history.go('/NewUser');
            } else {

                history.go(`/NewUser/${props.loadedProfileDetails.id}`);
            }
        }
    }


    function hideLastNameValidation() {
        setLastNameInputHasError(false);
    }

    function onBlurCheck() {
        const enteredFirstName = inputFirstNameRef.current.value;
        const enteredMiddleName = inputmiddleNameRef.current.value;
        const enteredLastName = inputLastNameRef.current.value;
        const enteredEmailId = inputEmailIdeRef.current.value;
        const enteredLoginEmailId = inputLoginEmailIdRef.current.value;
        const enteredPassword = inputPasswordRef.current.value;
        const enteredConfirmPassword = inputConfirmPasswordRef.current.value;
        let element;
        var element1;;
        if (document.getElementById("collapseThree") !== null) {
            element = document.getElementById("collapseThree")
        }
        if (document.getElementById("collapseTwo") !== null) {
            element1 = document.getElementById("collapseTwo")
        }



        //Validation for First Name
        if (title === '') {
            setTitleInputHasError(true);
            return;
        } else {
            setTitleInputHasError(false);
        }
        if (enteredFirstName.trim() === '') {
            setFirstNameInputHasError(true);
            setFirstNameInvalidInput(false);
            return;
        } else {
            setFirstNameInputHasError(false);

        }
        if (!enteredFirstName.match('^[a-zA-Z ]+$')) {
            setFirstNameInvalidInput(true);
            setFirstNameInputHasError(false);
            return;
        }
        else {
            setFirstNameInvalidInput(false);
        }
        //Validation For Middle Name
        if (enteredMiddleName.trim().length > 0) {
            if (!enteredMiddleName.match('^[a-zA-Z ]+$')) {
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        }
        else {
            setMiddleNameInvalidInputError(false);
        }
        //Validation for Last Name
        if (enteredLastName.trim() === '') {
            setLastNameInputHasError(true);
            setLastNameInvalidInput(false);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }
        if (!enteredLastName.match('^[a-zA-Z -]+$') || enteredLastName.trim().length < 2) {
            setLastNameInvalidInput(true);
            setLastNameInputHasError(false);
            return;
        }
        else {
            setLastNameInvalidInput(false);
        }


        //email Id Validation
        if (enteredEmailId.trim().length === 0) {
            setInputEmailIdHasError(true);
            setEmailIdInvalidInput(false);
            return;
        }
        else {
            setInputEmailIdHasError(false);
        }
        if (!validateEmail(enteredEmailId)) {
            setEmailIdInvalidInput(true);
            setInputEmailIdHasError(false);
            return;
        }
        else {
            setEmailIdInvalidInput(false);
        }

        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberHasError(true);
            return;
        }
        else {
            setPhoneNumberHasError(false);
        }
        if (role === '' || role === undefined) {
            setRoleBalnkError(true);
            return;
        }
        else {
            setRoleBalnkError(false);
        }

        //loginEmailId
        if (enteredLoginEmailId.trim().length === 0) {
            setLoginEmailIdInputHasError(true);
            setInvalidLoginEmailId(false);
            return;
        }
        else {
            setLoginEmailIdInputHasError(false);
        }
        if (!validateEmail(enteredLoginEmailId)) {
            setInvalidLoginEmailId(true);
            setLoginEmailIdInputHasError(false);
            return;
        } else {
            setInvalidLoginEmailId(false);

        }
        ///password Validation
        if (enteredPassword.trim().length === 0) {
            setPasswordBlankError(true);
            setPasswordHasError(false);
            return;
        } else {
            setPasswordBlankError(false);
        }
        if (!passwordValidation(enteredPassword)) {
            setPasswordBlankError(false);
            setPasswordHasError(true);
            return;
        }
        else {
            setPasswordHasError(false);
        }
        ///confirmPasswordValidation
        if (enteredConfirmPassword.trim().length === 0) {
            setConfirmPasswordBlankError(true);
            setConfirmPasswordHasError(false);
            return;
        }
        else {
            setConfirmPasswordBlankError(false);
        }
        if (enteredConfirmPassword !== enteredPassword) {
            setConfirmPasswordBlankError(false);
            setConfirmPasswordHasError(true);
            return;
        }
        else {
            setConfirmPasswordHasError(false);
            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                element.classList.add("show");
            }

        }
        if (JSON.parse(localStorage.getItem("isCorporate"))) {
            const enteredEmployeeCode = inputEmployeeCodeRef.current.value;
            const enteredAddress = addressInputRef.current.value;
            const enteredPostalCode = postalCodeInputRef.current.value;
            // //Validation for employeeCode
            if (enteredEmployeeCode.trim() === '') {
                setEmployeeCodeInputHasError(true);
                setEmployeeCodeInvalidInput(false);
                return;
            }
            else {
                setEmployeeCodeInputHasError(false);
            }
            if (!enteredEmployeeCode.match('^[a-zA-Z0-9]+$')) {
                setEmployeeCodeInvalidInput(true);
                setEmployeeCodeInputHasError(false);
                return;
            } else {
                setEmployeeCodeInvalidInput(false);
            }
            if (costCenter === '') {
                setCostCenterInputHasError(true);
                return;
            } else {
                setCostCenterInputHasError(false);
            }
            if (department === '') {
                setDepartmentInputHasError(true);
                return;
            } else {
                setDepartmentInputHasError(false);
            }
            if (postion === '') {
                setPositionInputHasError(true);
                return;
            } else {
                setPositionInputHasError(false);
            }
            if (DOB === '') {
                setDOBInputHasError(true);
                return;
            } else {
                setDOBInputHasError(false);
            }
            if (gender === '') {
                setGenderInputHasError(true);
                return;
            } else {
                setGenderInputHasError(false);
            }

            if (DOJ === '') {
                setDOJInputHasError(true);
                return;
            } else {
                setDOJInputHasError(false);
            }
            if (new Date(DOJ) < new Date(DOB)) {
                setInvalidDojError(true);
                return;

            } else {
                setInvalidDojError(false);
            }
            if (enteredAddress !== '' && enteredAddress.length !== 0) {
                if (!enteredAddress.match('^[a-zA-Z0-9 ]+$')) {
                    setAddressInputHasError(true);
                    return;
                } else {
                    setAddressInputHasError(false);
                }
            } else {
                setAddressInputHasError(false);
            }
            if (enteredPostalCode !== '') {
                if (!enteredPostalCode.match('^[a-zA-Z0-9-]+$') || enteredPostalCode.length < 5) {
                    setinavlidPostalCode(true);
                    return;
                } else {
                    setinavlidPostalCode(false);
                }
            } else {
                setinavlidPostalCode(false);
            }
            if (approvalValue === '') {
                element1.classList.add("show");
                setapprovalRequiredError(true);
                return;
            } else {
                setapprovalRequiredError(false);
            }

        }


    }
    useEffect(() => {
        if (approvalTypeOptions.length !== 0 && approvalTypeValue.length !== 0) {
            let temp = approvalTypeValue;
            let temp1 = [];
            temp.forEach(element => {
                let match = approvalTypeOptions.filter(item => item.value === element.approvalTypeID)
                if (match.length > 0) {
                    temp1.push(match[0]);
                }

            });
            if (temp1.length > 0) {
                setApprovalType(temp1);
            }

        }



    }, [loadedApprovalType, approvalTypeValue])

    async function submitFormHandler(event) {
        event.preventDefault();
        const enteredFirstName = inputFirstNameRef.current.value;
        const enteredMiddleName = inputmiddleNameRef.current.value;
        const enterdLastName = inputLastNameRef.current.value;
        const enteredEmailId = inputEmailIdeRef.current.value;
        const enteredLoginEmailId = inputLoginEmailIdRef.current.value;
        const enteredPassword = inputPasswordRef.current.value;
        const enteredConfirmPassword = inputConfirmPasswordRef.current.value;
        const enteredAddress = addressInputRef.current.value;
        const enteredEmployeeCode = inputEmployeeCodeRef.current.value;
        const enteredPostalCode = postalCodeInputRef.current.value;

        if (title === '') {
            setTitleInputHasError(true);
            window.scrollTo({
                top: 10,
                behavior: "smooth",
            });
            return;
        } else {
            setTitleInputHasError(false);
        }
        if (enteredFirstName.trim().length === 0) {
            inputFirstNameRef.current.focus();
            setFirstNameInputHasError(true);
            return;

        } else {
            setFirstNameInputHasError(false);
        }
        if (!enteredFirstName.match('^[a-zA-Z ]+$')) {
            inputFirstNameRef.current.focus();
            setFirstNameInvalidInput(true);
            return;
        }
        else {
            setFirstNameInvalidInput(false);
        }
        if (enteredMiddleName.trim().length > 0) {
            if (!enteredMiddleName.match('^[a-zA-Z ]+$')) {
                inputmiddleNameRef.current.focus();
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        } else {
            setMiddleNameInvalidInputError(false);
        }

        if (enterdLastName.trim().length === 0) {
            inputLastNameRef.current.focus();
            setLastNameInputHasError(true);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }
        if (!enterdLastName.match('^[a-zA-Z -]+$')|| enterdLastName.trim().length < 2) {
            inputLastNameRef.current.focus();
            setLastNameInvalidInput(true);
            return;
        }
        else {
            setLastNameInvalidInput(false);
        }

        if (enteredEmailId.trim().length === 0) {
            inputEmailIdeRef.current.focus();
            setInputEmailIdHasError(true);
            return;
        } else {
            setInputEmailIdHasError(false);
        }
        if (!validateEmail(enteredEmailId)) {
            inputEmailIdeRef.current.focus();
            setEmailIdInvalidInput(true);
            return;
        }
        else {
            setEmailIdInvalidInput(false);
        }

        if (phoneNumber === '' || phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setPhoneNumberHasError(true);
            return;
        }
        else {
            setPhoneNumberHasError(false);
        }
        if (role === '' || role === undefined) {
            setRoleBalnkError(true);
            window.scrollTo({
                top: 400,
                behavior: "smooth",
            });
            return;
        } else {
            setRoleBalnkError(false);
        }
        if (enteredLoginEmailId.trim().length === 0) {
            inputLoginEmailIdRef.current.focus();
            setLoginEmailIdInputHasError(true);
            return;
        } else {
            setLoginEmailIdInputHasError(false);
        }
        if (!validateEmail(enteredLoginEmailId)) {
            inputLoginEmailIdRef.current.focus();
            setInvalidLoginEmailId(true);
            return;
        } else {
            setInvalidLoginEmailId(false);
        }

        if (props.loadedUserDetails.length === 0) {
            if (enteredPassword.trim().length === 0) {
                inputPasswordRef.current.focus();
                setPasswordBlankError(true);
                return;
            } else {
                setPasswordBlankError(false);
            }
            if (!passwordValidation(enteredPassword)) {
                inputPasswordRef.current.focus();
                setPasswordHasError(true);
                return;
            }
            else {
                setPasswordHasError(false);
            }
            if (enteredConfirmPassword.trim().length === 0) {
                inputConfirmPasswordRef.current.focus();
                setConfirmPasswordBlankError(true);
                return;
            } else {
                setConfirmPasswordBlankError(false);

            } if (enteredConfirmPassword !== enteredPassword) {
                inputConfirmPasswordRef.current.focus();
                setConfirmPasswordHasError(true);
                return;
            }
            else {
                setConfirmPasswordHasError(false);
            }
        }

        if (JSON.parse(localStorage.getItem("isCorporate"))) {
            //Validation for employeeCode
            var element = document.getElementById("collapseThree");
            var element1 = document.getElementById("collapseTwo");
            if (enteredEmployeeCode.trim() === '') {
                inputEmployeeCodeRef.current.focus();
                element.classList.add("show");
                setEmployeeCodeInputHasError(true);
                setEmployeeCodeInvalidInput(false);
                return;
            }
            else {
                setEmployeeCodeInputHasError(false);
            }
            if (!enteredEmployeeCode.match('^[a-zA-Z0-9]+$')) {
                inputEmployeeCodeRef.current.focus();
                element.classList.add("show");
                setEmployeeCodeInvalidInput(true);
                setEmployeeCodeInputHasError(false);
                return;
            } else {
                setEmployeeCodeInvalidInput(false);
            }
            if (costCenter === '') {
                setCostCenterInputHasError(true);
                element.classList.add("show");
                window.scrollTo({
                    top: 500,
                    behavior: "smooth",
                });
                return;
            } else {
                setCostCenterInputHasError(false);
            }
            if (department === '') {
                element.classList.add("show");
                setDepartmentInputHasError(true);
                window.scrollTo({
                    top: 550,
                    behavior: "smooth",
                });
                return;
            } else {
                setDepartmentInputHasError(false);
            }
            if (postion === '') {
                element.classList.add("show");
                setPositionInputHasError(true);
                window.scrollTo({
                    top: 550,
                    behavior: "smooth",
                });
                return;
            } else {
                setPositionInputHasError(false);
            }
            if (DOB === '') {
                element.classList.add("show");
                setDOBInputHasError(true);
                const yOffset = -250;
                const element2 = document.getElementById("dateOfBirth");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });

                return;
            } else {
                setDOBInputHasError(false);
            }
            if (gender === '') {
                element.classList.add("show");
                const yOffset = -250;
                const element2 = document.getElementById("gender");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                setGenderInputHasError(true);
                return;
            } else {
                setGenderInputHasError(false);
            }

            if (DOJ === '') {
                element.classList.add("show");
                setDOJInputHasError(true);
                const yOffset = -250;
                const element2 = document.getElementById("dateOfJoiningId");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            } else {
                setDOJInputHasError(false);
            }
            if (new Date(DOJ) < new Date(DOB)) {
                setInvalidDojError(true);
                element.classList.add("show");
                const yOffset = -250;
                const element2 = document.getElementById("dateOfJoiningId");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;

            } else {
                setInvalidDojError(false);
            }

            if (enteredAddress !== '' && enteredAddress.length !== 0) {
                if (!enteredAddress.match('^[a-zA-Z0-9 ]+$')) {
                    element.classList.add("show");
                    addressInputRef.current.focus();
                    setAddressInputHasError(true);
                    return;
                } else {
                    setAddressInputHasError(false);
                }
            } else {
                setAddressInputHasError(false);
            }
            if (enteredPostalCode !== '') {
                if (!enteredPostalCode.match('^[a-zA-Z0-9-]+$') || enteredPostalCode.length < 5) {
                    postalCodeInputRef.current.focus();
                    element.classList.add("show");
                    setinavlidPostalCode(true);
                    return;
                } else {
                    setinavlidPostalCode(false);
                }
            } else {
                setinavlidPostalCode(false);
            }
            if (approvalValue === '') {
                element1.classList.add("show");
                setapprovalRequiredError(true);
                const yOffset = -250;
                const element2 = document.getElementById("approvalRequired");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            } else {
                setapprovalRequiredError(false);
            }


        }
        sendEncrypted(enteredPassword)

    }
    useEffect(() => {
        if (encryptedStatus === 'completed') {
            const enteredFirstName = inputFirstNameRef.current.value;
            const enteredMiddleName = inputmiddleNameRef.current.value;
            const enterdLastName = inputLastNameRef.current.value;
            const enteredEmailId = inputEmailIdeRef.current.value;
            const enteredLoginEmailId = inputLoginEmailIdRef.current.value;
            const enteredEmployeeCode = inputEmployeeCodeRef.current.value;
            const tempApprovalTypeValue = [];
            approvalType.length !== 0 && approvalType.forEach(element => {
                tempApprovalTypeValue.push({
                    "approvalTypeID": element.value,
                })
            });
            let ID;
            if (props.loadedUserDetails === null || props.loadedUserDetails.length === 0) {
                ID = '';
            } else {
                ID = props.loadedUserDetails.id;
            }
            props.onAddUser({
                id: ID,
                title: title,
                firstName: enteredFirstName,
                middleName: enteredMiddleName,
                lastName: enterdLastName,
                gender: gender,
                employeeCode: enteredEmployeeCode,
                loginEmailID: enteredLoginEmailId,
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: JSON.parse(localStorage.getItem('userID')),
                email: enteredEmailId,
                //password: await encrypted(enteredPassword),
                password: loadedEcryptedData,
                phoneCode: countryDialCode,
                phoneNumber: phoneNumber,
                roleID: role,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                isActive: active,
                companyName: JSON.parse(localStorage.getItem('companyName')),
                iPAddress: localStorage.getItem("IPAddress"),
                costCentreID: costCenter === undefined ? "" : costCenter,
                departmentID: department === undefined ? "" : department,
                positionID: postion === undefined ? "" : postion,
                costCentreKeyCode: costCenter === undefined ? "" : "FIRST",
                departmentKeyCode: department === undefined ? "" : "SECOND",
                positionKeyCode: postion === undefined ? "" : "THIRD",
                dob: DOB === undefined ? "" : DOB,
                doj: DOJ === undefined ? "" : DOJ,
                address: addressInputRef.current.value === undefined ? "" : addressInputRef.current.value,
                country: countryCode === undefined ? "" : countryCode,
                city: cityCode === undefined ? "" : cityCode,
                postalCode: postalCodeInputRef.current.value === undefined ? "" : postalCodeInputRef.current.value,
                documentTypes: documentDetails === undefined ? [] : documentDetails,
                firstApprover: firstApproverInputRef.current.value === undefined ? "" : firstApproverInputRef.current.value,
                approvalRequiredID: approvalValue === undefined ? "" : approvalValue,
                isMakePersonalBookings: isPersonalBooking,
                isNeedsApprovalForPersonalBookings: isNeedApproval,
                isDelegateActualApprover: isactualApprover,
                isTravelPolicy: isTravelPolicy,
                approvalType: tempApprovalTypeValue,
            });

        }
    }, [encryptedStatus])



    const roleChangeHandler = (event) => {
        setRole(event.value);
        setRoleBalnkError(false);
    }
    useEffect(() => {
        if (isFirtTime) {
            const onRoleList = ({
                officeID: JSON.parse(localStorage.getItem('officeID')),
                roleName: "",

            });
            const costCenterInput = (
                {
                    "id": '',
                    userID: JSON.parse(localStorage.getItem('userID')),
                    "name": "",
                    "code": "",
                    "keyCode": "FIRST",
                    "status": "2",
                    "officeID": JSON.parse(localStorage.getItem("officeID"))
                }

            );
            const deparmentInput = (
                {
                    "id": '',
                    userID: JSON.parse(localStorage.getItem('userID')),
                    "name": "",
                    "code": "",
                    "keyCode": "SECOND",
                    "status": "2",
                    "officeID": JSON.parse(localStorage.getItem("officeID"))
                }

            );
            const PostionInput = (
                {
                    "id": '',
                    userID: JSON.parse(localStorage.getItem('userID')),
                    "name": "",
                    "code": "",
                    "keyCode": "THIRD",
                    "status": "2",
                    "officeID": JSON.parse(localStorage.getItem("officeID"))
                }

            );

            sendRequestRoleList(onRoleList);
            if (JSON.parse(localStorage.getItem("isCorporate"))) {
                sendRequestCostCenterList(costCenterInput);
                sendRequestDeparmentList(deparmentInput);
                sendRequestPostionList(PostionInput);
                sendRequestApprovalTypeList({
                    id: "",
                    "officeID": JSON.parse(localStorage.getItem("officeID")),
                    "Type": "2"
                })
            }

        }
        setFirstTime(false);

    }, [sendRequestRoleList, isFirtTime, sendRequestCostCenterList, sendRequestDeparmentList, sendRequestApprovalTypeList]);

    //useEffect(() => {
    //    if (props.loadedUserDetails.length === 0) {
    //        document.getElementById("btnSave").click();

    //    }
    //}, [props.loadedUserDetails.length]);

    function onEditDocument(id) {
        setDuplicateDocumentType(false);
        setIsDisableDocumentType(true);
        document.getElementById('docSection').style.display = '';
        document.getElementById("collapseEight").classList.add("show");
        let tempDocList = documentDetails.filter(a => a.documentID === id);
        setDocId(id);
        if (document.querySelector('#AddButton') !== undefined && document.querySelector('#AddButton') !== null) {
            document.querySelector('#AddButton').innerHTML = "Update";
        }
        const yOffset = -250;
        const element2 = document.getElementById("docNumber");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        setDocType(tempDocList[0].documentTypeID);
        setNumber(tempDocList[0].documentNumber);
        document.querySelector("#docNumber").value = tempDocList[0].documentNumber;
        document.getElementById("DocIssueDate").value = moment(tempDocList[0].issueDate.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
        setIssueDate(tempDocList[0].issueDate.trim());
        document.getElementById("DocExpiryDate").value = moment(tempDocList[0].expiryDate.trim(), 'MM/DD/YYYY').format('D MMM YYYY')
        setExpiryDate(tempDocList[0].expiryDate.trim());
        setPlaceOfIssue(tempDocList[0].placeOfIssue);
        setPlaceOfIssueName(tempDocList[0].placeOfIssueName);
        setNationalityName(tempDocList[0].nationalityName);
        setNationality(tempDocList[0].nationality);
    }

    function onDeleteDocument(id) {
        let tempDocList = [];
        if (documentDetails.length !== 0) {
            documentDetails.forEach(element => {
                if (element.documentID !== id) {
                    tempDocList.push(element);
                }
            });

        }

        setDocumentDetails(tempDocList);
    }

    function onDelete(id) {
        setDocumentIdForDelete(id);
        setConfirm(true);
    }

    function okHandler() {
        onDeleteDocument(documentIdforDelete);
        setConfirm(false);
    }

    function cancelHandler() {
        setConfirm(false);
    }

    useEffect(() => {
        if(props.isLoading || encryptedStatus === 'pending') {
            scrollToLoader();
        }
    }, [props.isLoading, encryptedStatus]);

    return <Wrapper>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {/* {encryptedStatus === 'pending' && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )} */}
                                        {(props.isLoading || encryptedStatus === 'pending') && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                                    <form
                                                        className='form'>

                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className='control'>
                                                                    <label htmlFor="title" className="mb-2 h6 text-primary col-lg-12">{t('title')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown onChange={titleChangeHandler}
                                                                        value={titleOptions.filter(option => option.value === title)}
                                                                        options={titleOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        onBlur={onBlurCheck}
                                                                        isDisabled={isDropDownDisable}

                                                                    />

                                                                    {titleInputHasError && <p className="error-text">{t('validationTitle')}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='control'>
                                                                    <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12">{t('firstName')}<span className="error-icon"> *</span></label>
                                                                    <input id="firstName"
                                                                        ref={inputFirstNameRef}
                                                                        type='text'
                                                                        minLength="1"
                                                                        maxLength="50"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    >
                                                                    </input>
                                                                    {firstNameInputHasError && <p className="error-text">{t('validationFirstName')}</p>}
                                                                    {firstNameInvalidInput && <p className="error-text">{t('invalidInput')}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='control'>
                                                                    <label htmlFor="middleName" className="mb-2 h6 text-primary col-lg-12">{t('middleName')}</label>
                                                                    <input id="middleName"
                                                                        ref={inputmiddleNameRef}
                                                                        type='text'
                                                                        minLength="1"
                                                                        maxLength="50"
                                                                        className="form-control form-control-sm"
                                                                        onBlur={onBlurCheck}
                                                                        onFocus={hideLastNameValidation}
                                                                    >
                                                                    </input>
                                                                    {middleNameInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='control'>
                                                                    <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12">{t('lastName')}<span className="error-icon"> *</span></label>
                                                                    <input id="lastName"

                                                                        ref={inputLastNameRef}
                                                                        minLength="2"
                                                                        maxLength="50"
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {lastNameInputHasError && <p className="error-text">{t('validationLastName')}</p>}
                                                                    {lastNameInvalidInput && <p className="error-text">{t('invalidInput')}</p>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className='control'>
                                                                    <label htmlFor="emailId" className="mb-2 h6 text-primary col-lg-12">{t('emailId')}<span className="error-icon"> *</span></label>
                                                                    <input id="emailId"
                                                                        ref={inputEmailIdeRef}
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {emailIdInputHasError && <p className="error-text">{t('blankEmailId')}</p>}
                                                                    {emailIdInvalidInput && <p className="error-text">{t('invalidEmailId')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div>
                                                                    <label htmlFor="phoneNumber" className="mb-2 h6 text-primary col-lg-12">{t('phoneNumber')}<span className="error-icon"> *</span></label>
                                                                    <PhoneInput type="text"
                                                                        country={'us'}
                                                                        value={countryDialCode + phoneNumber}
                                                                        onChange={setPhone}
                                                                        placeholder=''
                                                                        enableSearch={true}
                                                                        disabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        id="phoneNumber"
                                                                        ref={phoneNumberInputRef} />
                                                                    {phoneNumberHasError && <p className="error-text">{t('phoneNumberBlankError')}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='control'>
                                                            <div className="form-switch form-switch-sm padding-left-0 margin-top-15">
                                                                <label htmlFor="active" className="mb-2 h6 text-primary">   {t('active')}</label>
                                                                <input id="active" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} value={active} ref={activeInputRef} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="role" className="mb-2 h6 text-primary col-lg-12">{t('role')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown onChange={roleChangeHandler}
                                                                        value={roleOptions.filter(option => option.value === role)}
                                                                        options={roleOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={roleDisable}

                                                                    />

                                                                    {roleBlankError && <p className="error-text">{t('blankRole')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="control">
                                                                    <label htmlFor="loginEmailId" className="mb-2 h6 text-primary col-lg-12">{t('loginEmailId')}<span className="error-icon"> *</span></label>
                                                                    <input id="loginEmailId"
                                                                        ref={inputLoginEmailIdRef}
                                                                        minLength="1"
                                                                        maxLength="100"
                                                                        type="text"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {loginEmailIdInputHasEroor && <p className="error-text">{t('blankLoginEmailId')}</p>}
                                                                    {!loginEmailIdInputHasEroor && invalidLoginEmailId && <p className="error-text">{t('invalidLoginEmailId')}</p>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mt-2 mb-3">
                                                                <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold">Warning : </span> The role <em>Admin</em> gives the highest-level access to a user. To reduce access, please create a new user role from <em> User Management  <i className="fas fa-long-arrow-alt-right mx-1"></i>  Role.</em></span>
                                                            </div>
                                                        </div>

                                                        <div className="row" id='dvPasswordArea'>
                                                            <div className="col-md-6">
                                                                <div className="control">
                                                                    <label id="password1" htmlFor="password" className="mb-2 h6 text-primary col-lg-12">{t('password')}<span className="error-icon"> *</span></label>
                                                                    <input id="password"
                                                                        ref={inputPasswordRef}
                                                                        minLength="8"
                                                                        maxLength="30"
                                                                        type="password"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                        autocomplete="new-password"
                                                                    />
                                                                    {passwordBlankError && <p className="error-text">{t('blankPassword')}</p>}
                                                                    {passwordHasError && <p className="error-text">{t('invalidPassword')}</p>}
                                                                    <ReactTooltip
                                                                        anchorId="password"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={t('passwordHint')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="control">
                                                                    <label htmlFor="confirmPassword" id="confirmPassword1" className="mb-2 h6 text-primary col-lg-12">{t('confirmPassword')}<span className="error-icon"> *</span></label>
                                                                    <input id="confirmPassword"

                                                                        ref={inputConfirmPasswordRef}
                                                                        minLength="8"
                                                                        maxLength="30"
                                                                        type="password"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {confirmPasswordBlankError && <p className="error-text">{t('blankConfirmPassord')}</p>}
                                                                    {confirmPasswordHasError && <p className="error-text">{t('invalidConfirmPassord')}</p>}
                                                                    <ReactTooltip
                                                                        anchorId="confirmPassword"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={t('passwordHint')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {JSON.parse(localStorage.getItem("isCorporate")) && <div>
                                                            <div>
                                                                <div class="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExampleThree">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon-user-form">

                                                                            <div className="accordion-item col-md-12">
                                                                                <h2 className="accordion-header" id="headingTwo">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseThree" aria-expanded="true"
                                                                                        aria-controls="collapseThree"> <i class="fas fa-user me-3"></i> Employee Details
                                                                                    </button>
                                                                                </h2>
                                                                                <div id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExampleThree">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="employeeCode" className="mb-2 h6 text-primary col-lg-12"> Employee Code <span class="error-icon"> *</span></label>
                                                                                            <input id="employeeCode"
                                                                                                placeholder='Employee Code'
                                                                                                ref={inputEmployeeCodeRef}
                                                                                                minLength="1"
                                                                                                maxLength="10"
                                                                                                type="text"
                                                                                                onBlur={onBlurCheck}
                                                                                                className="form-control form-control-sm"
                                                                                            />
                                                                                            {employeeCodeInputHasError && <p className="error-text">{t('validationEmployeeCode')}</p>}
                                                                                            {employeeCodeInvalidInput && <p className="error-text">{t('inputEmployeeCode')}</p>}


                                                                                        </div>
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="" className="mb-2 h6 text-primary col-lg-12"> Cost Center <span className="error-icon"> *</span></label>
                                                                                            <Dropdown
                                                                                                options={CostCenterOptions}
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                placeholder="Please Select"
                                                                                                value={CostCenterOptions.filter(option => option.value === costCenter)}
                                                                                                onChange={costCenterChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                isDisabled={isDropDownDisable}
                                                                                            />
                                                                                            {costCenterInputHasError && <p className="error-text">{t('validationCostCenter')}</p>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="" className="mb-2 h6 text-primary col-lg-12"> Department <span className="error-icon"> *</span></label>

                                                                                            <Dropdown
                                                                                                options={deparmentOptions}
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                placeholder="Please Select"
                                                                                                value={deparmentOptions.filter(option => option.value === department)}
                                                                                                onChange={departmentChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                isDisabled={isDropDownDisable}
                                                                                            />
                                                                                            {deparmentInputHasError && <p className="error-text">{t('validationdepartment')}</p>}
                                                                                        </div>
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="" className="mb-2 h6 text-primary col-lg-12"> Position <span className="error-icon"> *</span></label>

                                                                                            <Dropdown
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                options={postionOptions}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                placeholder="Please Select"
                                                                                                value={postionOptions.filter(option => option.value === postion)}
                                                                                                onChange={postionChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                isDisabled={isDropDownDisable}
                                                                                            />
                                                                                            {postionInputHasError && <p className="error-text">{t('validationpostion')}</p>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="dateOfBirthd" className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"> Date of Birth <span className="error-icon"> *</span></label>
                                                                                            <div class=" position-relative">

                                                                                                <DateRangePicker
                                                                                                    onHide={dobChangeHandler}
                                                                                                    initialSettings={{

                                                                                                        singleDatePicker: true, maxDate: TodayDate,
                                                                                                        showDropdowns: true, autoApply: true,
                                                                                                    }} >
                                                                                                    <input type="text" className="form-control col-lg-12 col-sm-12 col-12" id='dateOfBirth' autoComplete='off' disabled={isDropDownDisable} />
                                                                                                </DateRangePicker>
                                                                                                <i class="date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                                                {DOBInputHasError && <p className="error-text">{t('validationDOB')}</p>}
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="gender" className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"> Gender <span className="error-icon"> *</span></label>
                                                                                            <Dropdown onChange={genderChangeHandler}
                                                                                                value={genderOptions.filter(option => option.value === gender)}
                                                                                                options={genderOptions}
                                                                                                textField="label"
                                                                                                dataItemKey="value"
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                                onBlur={onBlurCheck}
                                                                                                id="gender"
                                                                                                isDisabled={isDropDownDisable}

                                                                                            />
                                                                                            {genderInputHasError && <p className="error-text">{t('validationgender')}</p>}

                                                                                        </div>
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="dateOfJoiningId" className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"> Date of Joining <span className="error-icon"> *</span></label>
                                                                                            <div class="position-relative mb-3">
                                                                                                <DateRangePicker
                                                                                                    onHide={dojChangeHandler}
                                                                                                    initialSettings={{
                                                                                                        singleDatePicker: true, maxDate: TodayDate,
                                                                                                        showDropdowns: true, autoApply: true,

                                                                                                    }} >
                                                                                                    <input type="text" className="form-control col-lg-12 col-sm-12 col-12" id='dateOfJoiningId' disabled={isDropDownDisable} />
                                                                                                </DateRangePicker>
                                                                                                <i class="date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                                                {DOJInputHasError && <p className="error-text">{t('validationDOj')}</p>}
                                                                                                {invalidDojError && <p className="error-text">{t('invalidDOj')}</p>}
                                                                                            </div>
                                                                                            {/* <input type='date' id='dateOfJoiningId' className="mb-2 form-control form-control-sm col-lg-12" placeholder='Date of Joining' value={DOJ} onChange={dojChangeHandler} /> */}

                                                                                        </div>
                                                                                        <div className="col-md-12 mt-2 mb-5">
                                                                                            <label htmlFor="address" className="mb-2 h6 text-primary col-lg-12"> Address</label>
                                                                                            <textarea id="address" type="text" rows="2" className=" form-control form-control-sm col-lg-12" ref={addressInputRef} onBlur={onBlurCheck} maxLength={100}></textarea>
                                                                                            {addressInputHasError && <p className="error-text">{t('validationAddress')}</p>}
                                                                                        </div>
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="country" className="mb-2 h6 text-primary col-lg-12">Country</label>
                                                                                            <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={iSDisabled} />
                                                                                        </div>
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="city" className="mb-2 h6 text-primary col-lg-12">City</label>
                                                                                            <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} iSDisabled={iSDisabled} countryCode={countryCode} />
                                                                                        </div>
                                                                                        <div className="col-md-4 mt-3">
                                                                                            <label htmlFor="postalCode" className="mb-2 h6 text-primary col-lg-12">Postal Code</label>
                                                                                            <input type='text' id='postalCode' maxLength={12} minLength={5} className="mb-2 form-control form-control-sm col-lg-12" placeholder='Postal Code' ref={postalCodeInputRef} onBlur={onBlurCheck} />
                                                                                            {invalidPostalCode && <p className="error-text">{t('validationPosatlCode')}</p>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* User : Add Corporate Specific Employee Information */}
                                                            <div className="" id="docSection2" >
                                                                <div class="tour_details_boxed_inner" >
                                                                    <div className="accordion" id="accordionExampleEight">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon-user-form">

                                                                            <div className="accordion-item col-md-12">
                                                                                <h2 className="accordion-header" id="headingEight">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseEight" aria-expanded="true"
                                                                                        aria-controls="collapseEight"> <i class="fas fa-file me-3"></i> Documents
                                                                                    </button>
                                                                                </h2>
                                                                                <div id="collapseEight" className="accordion-collapse collapse " aria-labelledby="headingEight" data-bs-parent="#accordionExampleEight">
                                                                                    <div id="docSection1">

                                                                                        <div className="row" id="docSection">
                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="docType1" className="mb-2 h6 text-primary col-lg-12">Document Type</label>
                                                                                                <Dropdown
                                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                                    options={documentOptions}
                                                                                                    arrowOpen={<span className="arrow-open" />}
                                                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                                                    placeholder="Please Select"
                                                                                                    id="docType"
                                                                                                    value={documentOptions.filter(options => options.value === docType)}
                                                                                                    onChange={documentTypeChangeHandler}
                                                                                                    isDisabled={isDisableDocumentType}
                                                                                                />
                                                                                                {DocTypeInputHasError && <p className="error-text">{t('validationDocType')}</p>}
                                                                                                {duplicateDocumentType && <p className="error-text">{t('validationDuplicateDocType')}</p>}
                                                                                            </div>
                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="docNumber" className="mb-2 h6 text-primary col-lg-12">Number</label>
                                                                                                <input type='text' id='docNumber' maxLength={20} className=" form-control form-control-sm col-lg-12" placeholder='Number' value={number} onChange={numberChangeHandler} />
                                                                                                {DocNumberInputHasError && <p className="error-text">{t('validationDocNumber')}</p>}
                                                                                                {invalidPassportNumber && <p className="error-text">{t('validationPassportNumber')}</p>}
                                                                                                {invalidNationalId && <p className="error-text">{t('validationNationalID')}</p>}
                                                                                                {invalidIqamaNumber && <p className="error-text">{t('validationIqamaNumber')}</p>}
                                                                                            </div>

                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="DocIssueDate" className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"> Issue Date</label>
                                                                                                <div class="position-relative ">
                                                                                                    <DateRangePicker
                                                                                                        onHide={issueDateChangeHandler}
                                                                                                        initialSettings={{
                                                                                                            singleDatePicker: true, maxDate: TodayDate,
                                                                                                            showDropdowns: true, autoApply: true,
                                                                                                        }} >
                                                                                                        <input type="text" className="form-control col-lg-12 col-sm-12 col-12" id='DocIssueDate' disabled={isDropDownDisable} />
                                                                                                    </DateRangePicker>
                                                                                                    <i class="date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                                                    {IssueDateInputHasError && <p className="error-text">{t('validationIssueDate')}</p>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="DocExpiryDate" className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"> Expiry Date</label>
                                                                                                <div class="position-relative mb-3">
                                                                                                    <DateRangePicker
                                                                                                        onHide={expiryDateChangeHandler}
                                                                                                        initialSettings={{
                                                                                                            singleDatePicker: true,
                                                                                                            showDropdowns: true, autoApply: true,
                                                                                                        }} >
                                                                                                        <input type="text" className="form-control col-lg-12 col-sm-12 col-12" id='DocExpiryDate' disabled={isDropDownDisable} />
                                                                                                    </DateRangePicker>
                                                                                                    <i class=" date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                                                    {expiryDateInputHasError && <p className="error-text">{t('validationExpiryDate')}</p>}
                                                                                                    {invalidExpiryDate && <p className="error-text">{t('InvalidExpiryDate')}</p>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 ">
                                                                                                <label htmlFor="DocPlaceOfIssue" className="mb-2 h6 text-primary col-lg-12">Place of Issue</label>
                                                                                                <CountryDropDownList countryCodeValue={handlePlaceOfIssueCountryCode} countryNameText={handlePlaceOfIssueChange} currentInputValue={placeOfIssue} iSDisabled={iSDisabled} id={'DocPlaceOfIssue'} />
                                                                                                {placeOfIssueInputHasError && <p className="error-text">{t('validationPlaceOfIssue')}</p>}
                                                                                            </div>
                                                                                            <div className="col-md-6 margin-top-15">
                                                                                                <label htmlFor="DocNationality" className="mb-2 h6 text-primary col-lg-12">Nationality</label>
                                                                                                <CountryDropDownList countryCodeValue={handleNationalityCode} countryNameText={handleNationalityNameChange} currentInputValue={nationality} iSDisabled={iSDisabled} id={'DocNationality'} />
                                                                                                {nationalityInputHasError && <p className="error-text">{t('validationNationality')}</p>}
                                                                                            </div>
                                                                                            <div className="col-md-12 mt-4">
                                                                                                <button class="btn btn_theme btn_sm float-end mb-2" id="AddButton" style={{ display: '' }} onClick={onAddDocument}><span class="">Add </span></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {documentDetails.length !== 0 && <div className='row'>
                                                                                        <div className='col-md-12'>
                                                                                            <div className=' table-responsive mt-2 table-wrapper width-900px'>
                                                                                                <table className="table table-bordered">
                                                                                                    <thead>
                                                                                                        <tr >
                                                                                                            <th scope='col'>Doc Type</th>
                                                                                                            <th className="" scope='col'>Number</th>
                                                                                                            <th scope="col">Issue Date</th>
                                                                                                            <th scope="col">Expiry Date</th>
                                                                                                            <th scope="col">Place of Issue</th>
                                                                                                            <th scope="col">Nationality</th>
                                                                                                            <th scope="col" className='text-center sticky-col last-col table-static-bg'>Action</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        {
                                                                                                            documentDetails.length !== 0 && documentDetails.map((item, index) => (


                                                                                                                <tr>
                                                                                                                    <td>{item.documentTypeID}</td>
                                                                                                                    <td>{item.documentNumber}</td>
                                                                                                                    <td>{moment(item.issueDate, 'MM/DD/YYYY').format('D MMM YYYY')}</td>
                                                                                                                    <td>{moment(item.expiryDate, 'MM/DD/YYYY').format('D MMM YYYY')}</td>
                                                                                                                    <td>{item.placeOfIssueName}</td>
                                                                                                                    <td>{item.nationalityName}</td>


                                                                                                                    <td className='text-center sticky-col last-col bg-light'>
                                                                                                                        <div className="text-center">
                                                                                                                            <button type="button" className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title="Edit" disabled={isactionButtonDisable} onClick={() => onEditDocument(item.documentID)}><i className="fa-solid fa-pen"></i></button>
                                                                                                                            <button type="button" className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" title="Delete" disabled={isactionButtonDisable} onClick={() => onDelete(item.documentID)}><i className="fas fa-trash-alt"></i></button>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            ))

                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div>
                                                                <div class="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExampleTwo">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon-user-form">

                                                                            <div className="accordion-item col-md-12">
                                                                                <h2 className="accordion-header" id="headingTwo">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseTwo" aria-expanded="true"
                                                                                        aria-controls="collapseTwo"> <i class="fas fa-user-tag me-2"></i> Roles and Privileges
                                                                                    </button>
                                                                                </h2>
                                                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleTwo">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="firstApprover" className="mb-2 h6 text-primary col-lg-12">First Approver</label>
                                                                                            <input type='text' id='firstApprover' maxLength={50}
                                                                                                ref={firstApproverInputRef}
                                                                                                className="mb-2 form-control form-control-sm col-lg-12" placeholder='' readOnly />
                                                                                        </div>
                                                                                        <div className="col-md-6 mt-3">
                                                                                            <label htmlFor="approvalRequired" className="mb-2 h6 text-primary col-lg-12">Approval Required <span class="error-icon"> *</span></label>
                                                                                            <Dropdown
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                options={approvalOptions}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                placeholder="Please Select"
                                                                                                value={approvalOptions.filter(options => options.value === approvalValue)}
                                                                                                onChange={approvalChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                id="approvalRequired"
                                                                                                isDisabled={isDropDownDisable}
                                                                                            />
                                                                                            {approvalRequiredError && <p className="error-text">{t('validationApprovalRequired')}</p>}
                                                                                        </div>
                                                                                        <div className="col-md-12 mt-3">
                                                                                            <label htmlFor="approvalWorkflowType" className="mb-2 h6 text-primary col-lg-12">Roles </label>
                                                                                            <Dropdown
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                options={approvalTypeOptions}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                placeholder="Please Select"
                                                                                                value={approvalType}
                                                                                                onChange={approvalTypeChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                isMulti={true}
                                                                                                isDisabled={isDropDownDisable}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="row mt-3">
                                                                                        <div className="col-md-12">
                                                                                            <label class="mt-3 mb-2 h5 text-secondary col-lg-12"> Privileges </label>
                                                                                        </div>
                                                                                        <div className='col-md-12 mt-2'>
                                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                                <label htmlFor="checkBoxes1" className="mb-2 h6 text-primary">   {t('Can make Personal Bookings')}</label>
                                                                                                <input id="checkBoxes1" className="form-check-input ms-3" type="checkbox" onChange={personalBookingChangeHandler} checked={isPersonalBooking} value={isPersonalBooking} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-12 mt-2' >
                                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                                <label htmlFor="checkBoxes2" className="mb-2 h6 text-primary">   {t('Is Exempt from the Travel Policy')}</label>
                                                                                                <input id="checkBoxes2" className="form-check-input ms-3" type="checkbox" onChange={travelPolicyChangeHandler} checked={isTravelPolicy} value={isTravelPolicy} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className='mt-2'>
                                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                                <label htmlFor="checkBoxes3" className="mb-2 h6 text-primary">   {t('Needs approval for Personal Bookings')}</label>
                                                                                                <input id="checkBoxes3" className="form-check-input ms-3" type="checkbox" onChange={needApprovalChangeHandler} checked={isNeedApproval} value={isNeedApproval} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='mt-2'>
                                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                                <label htmlFor="checkBoxes4" className="mb-2 h6 text-primary">   {t('Can delegate approvers on behalf of the actual approver')}</label>
                                                                                                <input id="checkBoxes4" className="form-check-input ms-3" type="checkbox" onChange={actualApproverChangeHandler} checked={isactualApprover} value={isactualApprover} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        <p className="my-2"><span className="error-icon ">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/AllUser" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/AllUser" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                    {(checkSecurityDetails("User Management", "User", "User Management_User_Insert") || checkSecurityDetails("User Management", "User", "User Management_User_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler} >
                                                                            {t('save')}
                                                                        </button>
                                                                        : <span id="btnSave"></span>}
                                                                    {checkSecurityDetails("User Management", "User", "User Management_User_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    </Wrapper >
};

export default UserForm;

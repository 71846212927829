import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectDelegate from "./SelectDelegate";
import SearchDelegateApprover from "./SearchDelegateApprover";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";

export default function DelegatedApprover() {
    const { t } = useTranslation(["translation.Delegate"]);
    useDocumentTitle(t('delegateWorkflow'));
    const loginData = JSON.parse(localStorage.getItem("loginData"));

    const [isDelegateActualApprover, setIsDelegateActualApprover] = useState(
        loginData.isDelegateActualApprover
    );
    const [selectedUser, setSelectedUser] = useState(null);

    const [selectDelegateResetKey, setSelectDelegateResetKey] = useState(1);

    function handleResetSelectDelegateForm() {
        setSelectDelegateResetKey(key => key + 1);
    }

    return (
        <Fragment>
            {!isDelegateActualApprover ?
                <SelectDelegate key={selectDelegateResetKey}
                    selectedUser={selectedUser}
                    onReset={handleResetSelectDelegateForm}    />
                :
                <SearchDelegateApprover
                    setSelectedUser={setSelectedUser}
                    setIsDelegateActualApprover={setIsDelegateActualApprover}
                />
            }
        </Fragment>
    );
}

import {
    GET_AUTHENTICATION_DETAILS,GENERATEBARCODE,CHECKAUTHENTICATION,
    UPDATE_FORGOT_PASSWORD,UPDATE_CHANGE_PASSWORD,UPDATE_PASSWORD,AUTHENTICATIONWITHLOGIN,
    CHECKAUTHENTICATIONB2C, OTPAUTHENTICATION, OTPAUTHENTICATIONCHECK, GET_AUTHENTICATION_TOKEN,
    RESENDOTPAUTHENTICATION
} from './CONSTANTS'


export async function getAuthentication(addData) {    
    const response = await fetch(GET_AUTHENTICATION_DETAILS(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}

export async function generateBarCode(addData) {    
    const response = await fetch(GENERATEBARCODE(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}

export async function checkAuthentication(addData) {    
    const response = await fetch(CHECKAUTHENTICATION(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}
export async function authenticationOTP(addData) {    
    const response = await fetch(OTPAUTHENTICATION(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}

export async function authenticationOTPCheck(addData) {    
    const response = await fetch(OTPAUTHENTICATIONCHECK(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}

export async function checkAuthenticationB2C(addData) {    
    const response = await fetch(CHECKAUTHENTICATIONB2C(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',         
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}
export async function AuthenticationWithLogin(loginID) {    
    const response = await fetch(AUTHENTICATIONWITHLOGIN(), {
        method: 'POST',  
        body: JSON.stringify(loginID),      
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
  
    const data = await response.json();  

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }

    return data;
}
export async function ForgotPassword(addData) {    
    const response = await fetch(UPDATE_FORGOT_PASSWORD(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }
    return data;
}
export async function ChangePassword(addData) {    
    const response = await fetch(UPDATE_CHANGE_PASSWORD(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }
    return data;
}
export async function ForgotUpdatePassword(addData) {    
    const response = await fetch(UPDATE_PASSWORD(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Login details.');
    }
    return data;
}

export async function getAuthToken(addData) {

    let authResponse = {
        data: '',
        errors: null
    };

    const response = await fetch(GET_AUTHENTICATION_TOKEN(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const httpResponse = await response;

    if (httpResponse.ok) {
        const responseData = await httpResponse.json();
        authResponse = responseData;
    }

    //For non OK condition, we can't write/include the error response because the format usually is not in json format

    //empty string will be returned for data property if there's error

    return authResponse;
}

export async function authenticationResendOTP(addData) {    
    const response = await fetch(RESENDOTPAUTHENTICATION(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },
    });
    const data = await response.json();   
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch OTP details.');
    }

    return data;
}
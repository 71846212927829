import { sortData } from "../../../../utils";
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import FeesItem from "./FeesItem";
import { deleteFees } from '../../../../services/Fees-API'
import useHttp from "../../../../services/use-http";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
const FeesList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['translation.Fees']);
  const queryParams = new URLSearchParams(location.search);
  const isSortingAscending = queryParams.get('sort') === 'asc';
  const [error1, setError] = useState();

  let sortedProvider = sortData(props.fees, 'serviceName', isSortingAscending);
  sortedProvider = sortData(props.fees, 'serviceName', isSortingAscending);
  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };
  useEffect(() => {

    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
    history.push({
      pathname: '/SearchFees',
      search: `?sort=${('asc')}`
    })
  }, [])

  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteFees, true);
  const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);


  useEffect(() => {
    //document.querySelector("#sortBy").selectedIndex = 0;
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });

    history.push({
      pathname: '/SearchFees',
      search: `?sort=${('asc')}`
    })

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }
    if (status === 'completed') {
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
        setIsErrorOnDelete(true);
      }
      else {
        setError({
          title: "Message",
          message: `${t('recordDeletedMessage')}`,
        });
      }
    }
  }, [status, history, loadedQuote, t])

  const deleteItemsHandler = (props) => {
    const onDeleteAirport = ({
      id: props.id,
      traceID: "",
      officeID: "",
      userID: "",
      countryCode: "",
      cityCode: "",
      airportCode: "",
      airportName: ""
    });

    sendRequest(onDeleteAirport);

    if (error) {
      return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordDelete')}</div>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  const errorHandler = () => {
    setError(null);
    if (!isErrorOnDelete) {
      history.go({
        pathname: '/SearchFees'
      })
    }
    else {
      history.push({
        pathname: '/SearchFees'
      })
    }
  };
  //End code for delete

  return <Fragment>
    {error1 && (
      <ErrorModal
        title={error1.title}
        message={error1.message}
        onConfirm={errorHandler}
      ></ErrorModal>
    )}
    <div className="container-fluid mb-3 list-view">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
          <div className='row mb-3'>
            <div className="col-md-6"></div>
            <div className='col-md-2 my-auto text-muted text-left'>
              <div className="float-lg-end float-md-end">{t('sortBy')}</div>
            </div>
            <div className="col-md-4 form-group">
              <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                <option>{t('sortServiceDsc')}</option>
                <option>{t('sortServiceAsc')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {sortedProvider.map((fee) => (
        <FeesItem onDelete={deleteItemsHandler}
          key={fee.id}
          id={fee.id}
          serviceName={fee.serviceName}
          providerName={fee.providerName}
          toRegionName={fee.toRegion}
          fromRegionName={fee.fromRegion}
          isActive={fee.isActive}
          markupType={fee.markupType}
          pos={fee?.pos}
          paxType={fee?.paxType}
          fareTypeID={fee?.fareTypeID}
          cabinClass={fee?.cabinClass}
          serviceID={fee?.serviceID}
          companyName={props.companyName}
        />
      ))}
    </div>
  </Fragment>
};
export default FeesList;
import { Fragment } from 'react';
const Services = (props) => {
    let service = props.hotelDetails.data.hotelResults[0].hotelAmenities;
    let services = [];
    service.forEach(result => {
        services.push(<li key={result.name}><i className="fas fa-check"></i>{result.name}</li>);
    });
    // const showMoreAmenities = () => {

     
    //     var textElement = document.querySelector(".text-amenities");
    //     var buttonElement = document.getElementById('showMoreAmenities11');
    //     if (textElement.classList.contains("show-more-amenities-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down double angle
    //     }

    //     textElement.classList.toggle("show-more-amenities-height");

    // }
    return <Fragment>
        <div className="col-lg-4 margin-top-15 ">
            <div className="tour_detail_right_sidebar">
                <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                        <h3>Amenities</h3>
                    </div>
                    <div className="tour_package_details_bar_list first_child_padding_none text-amenities show-more-amenities-height">
                        <ul>
                            {services}
                        </ul>
                    </div>
                    {/* {services.length >= 19 && 
                        <div className="text-white" id='showMoreAmenities11' onClick={() => { showMoreAmenities() }}> <span>Show More  <i class="fas fa-angle-double-right"></i></span></div>
                    } */}

                    {/* <span id='room_section'></span> */}
                </div>
            </div>

        </div>
    </Fragment>
}

export default Services;
import { Fragment } from 'react';
import { getFormatDate, timeConvert, getDateDiffDays, getFormatTime } from './../../../utils/CommonFunction'

const BookingDetails = (props) => {
    //User Story 1073: Booking Summary for Single PNR
    let hotelBookingJSON = localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(sessionStorage.getItem("HotelBookingData"));

    return (<Fragment>
        <div className="left_side_search_area">
            <div className="left_side_search_boxed fff mb-0">
                <div className="left_side_search_heading">
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" &&
                        <h5 className='mb-1'>Hotel Booking Details</h5>
                    }
                    {
                        localStorage.getItem("AddHotel") === null &&
                        <h5 className='mb-1'>Booking Details</h5>
                    }
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='text-14px'>Check-in</p>
                        <p className='fw-bold text-14px text-primary'>{getFormatDate(props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].date)}{props.guestInfo.hotelResults[0].hotelCheckInPolicy !== undefined && props.guestInfo.hotelResults[0].hotelCheckInPolicy !== null && props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].value !== undefined && props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].value !== null ? ", " + getFormatTime(props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].value) : ''}</p>
                        {/* <p className='text-muted'>12:00 PM</p> */}
                    </div>
                    <div className='col-md-12'>
                        <p className='text-14px'>Check-out</p>
                        <p className='fw-bold text-14px text-primary'>{getFormatDate(props.guestInfo.hotelResults[0].hotelCheckInPolicy[0].date)}{props.guestInfo.hotelResults[0].hotelCheckInPolicy !== undefined && props.guestInfo.hotelResults[0].hotelCheckInPolicy !== null && props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].value !== undefined && props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].value !== null ? ", " + getFormatTime(props.guestInfo.hotelResults[0].hotelCheckInPolicy[0].value) : ''}</p>
                        {/* <div className='text-muted'>10:00 AM</div> */}
                    </div>
                    <div className='col-md-12 mt-1'>
                        <p className='text-14px'> Total length of stay</p>
                        <p className='fw-bold text-14px text-primary'>{getDateDiffDays(props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].date, props.guestInfo.hotelResults[0].hotelCheckInPolicy[0].date)} {getDateDiffDays(props.guestInfo.hotelResults[0].hotelCheckInPolicy[1].date, props.guestInfo.hotelResults[0].hotelCheckInPolicy[0].date) > 1 ? 'nights' : 'night'}</p>
                    </div>
                    {
                        (props.guestInfo.hotelResults[0].roomRateDetails[0].roomTypeName !== undefined && props.guestInfo.hotelResults[0].roomRateDetails[0].roomTypeName !== null && props.guestInfo.hotelResults[0].roomRateDetails[0].roomTypeName !== '') &&
                        <>
                            <hr className='my-1'></hr>
                            <div className='col-md-12 my-1'>
                                <p className='text-14px'> You selected</p>
                                {
                                    hotelBookingJSON.data.hotelResults[0].NoOfRooms > 1 &&
                                    <span className='text-14px fw-bold text-black'>{hotelBookingJSON.data.hotelResults[0].NoOfRooms + " x "}</span>
                                }
                                <span className='text-14px  fw-bold text-primary'>{props.guestInfo.hotelResults[0].roomRateDetails[0].roomTypeName}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </Fragment>)
}

export default BookingDetails;
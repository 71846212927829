import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchCorporateMaster } from '../../../services/CorporateMaster-API'
import CorporateMasterList from './Components/CorporateMasterList'
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { UPDATECORPORATEMASTER, UPDATEUSERFRIENDLY } from '../../../navigation/CONSTANTS';
import Dropdown from 'react-select';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { useTranslation } from 'react-i18next';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
const SearchCorporateMaster = () => {
    const statusOption = [
        { value: "2", label: "Active" },
        { value: "1", label: "Both" },
        { value: "3", label: "Inactive" },
    ];

    const parms = useParams();
    const { KeyCode } = parms;
    useDocumentTitle(getConfigurationByValue(KeyCode));
    const [enteredName, setEnterName] = useState("");
    const [isFirtTime, setFirstTime] = useState(false);
    const [UserStatus, setUserStatus] = useState(statusOption[0].value);
    const { t } = useTranslation(['translation.CorporateMaster']);

    const history = useHistory();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const { sendRequest, status, data: loadedCorproateMaster, error } = useHttp(searchCorporateMaster);

    const statusChangeHandler = (event) => {
        setUserStatus(event.value);
    }
    const onResetHandler = (event) => {
        history.push('/SearchCorporateMaster/' + KeyCode);
        history.go();
    }
    useEffect(() => {
        setFirstTime(false);
    }, [KeyCode]);

    console.log(KeyCode);
    const onSearchHandler = (event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Search")) {
            return false;
        }

        setFirstTime(true);
        const onSearchCorporateMaster = ({
            "id": '',
            userID: JSON.parse(localStorage.getItem('userID')),
            "name": enteredName,
            "code": "",
            "keyCode": KeyCode,
            "status": UserStatus,
            "officeID": JSON.parse(localStorage.getItem("officeID"))
        });
        setIsSubmitClicked(true);
        sendRequest(onSearchCorporateMaster);

    };

    useEffect((sendRequest) => {

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    if (error) {
        return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }
    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }
    const namechangehandler = (event) => {
        setEnterName(event.target.value);
    };
    const errorHandler = () => {
        history.go('/SearchCorporateMaster');
    };
    const ErrorShow = (isSubmitClicked) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const corporateMasterList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <CorporateMasterList corproateMaster={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12 gt-country-details-form">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search {getConfigurationByValue(KeyCode)}</label>

                                            <div className='row'>
                                                <div className='col-md-12 col-sm-12'>
                                                    <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Name'>Name</label>
                                                    <input type='text' maxLength="100" id='name' className="mb-2 form-control form-control-sm col-lg-12" placeholder={t('name')} value={enteredName} onChange={namechangehandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="status" className="mb-2 h6 text-primary col-lg-12">{t('status')}</label>
                                                    <Dropdown
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />}
                                                        controlClassName='form-control control form-select bg_input mb-0'
                                                        placeholder="Please Select"
                                                        options={statusOption}

                                                        onChange={statusChangeHandler}
                                                        value={statusOption.filter(option => option.value === UserStatus)} />
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-12 actions'>
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>
                                                {checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Insert") &&
                                                    <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={{ pathname: '../UpdateCorporateMaster/' + KeyCode, MasterSequence: 1 }}>
                                                        {t('new')}
                                                    </NavLink>
                                                }
                                                {checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Search") &&
                                                    <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {corporateMasterList}
        </Fragment>)
};

export default SearchCorporateMaster

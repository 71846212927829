import { Fragment } from "react";
import AboutArea from "./Components/AboutArea";
import Policies from "./Components/Policies";
import { removeHtmlTags,removeFirstAndLastSpecialChar } from "../../utils/CommonFunction";
const RoomRecommendationTwo = () => {
    let loadedHotelDetails;
    if (sessionStorage.getItem('HotelRecommendationJson') !== null) {
        loadedHotelDetails = JSON.parse(sessionStorage.getItem('HotelRecommendationJson'));
    }
    let showNearBy = false;
    if (loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined) {
        loadedHotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Attractions' || result.text.type === 'TransportationInfo') {
                showNearBy = true;
            }
            
        })

    }
   
    let recreation = "";
    let safetyInfo = "";
    if (loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== null) {
        loadedHotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Recreation') {
                recreation = result.text.value.replace(/\n\n/g, '\n');
            }
            if (result.text.type === 'SafetyInfo') {
                safetyInfo = result.text.value;
            }
        });
    }
    let recreations = "";
    let ShowRecreation = [];
    if (recreation !== undefined && recreation !== '') {
        recreations = recreation.split(/\r?\n/);
        if (recreations !== '') {
            recreations.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    ShowRecreation.push(<div className='py-1'>{item}</div>);

                }
            });
        } else {
            ShowRecreation.push(<div className='py-1'>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</div>)

        }

    }
    let safetyInfos = "";
    let showSafetyInfo = [];
    if (safetyInfo !== undefined && safetyInfo !== '') {
        safetyInfos = safetyInfo.split(/\r?\n/);
        if (safetyInfos !== '') {
            safetyInfos.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showSafetyInfo.push(<div className='py-1'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{item}</div>);
                }
            });
        } else {
            showSafetyInfo.push(<div className='py-1'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</div>)

        }

    }

    return <Fragment>

        <section className="sub-banner1">
            <div className="bg-parallax bg-1 w-100"></div>
        </section>
        <section id="tour_details_main" className="section_padding">
            <div className="container">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            {recreation !== '' && <div className='col-md-6'>
                                <h5 className="h5 mt-1 text-primary"><i class="fas fa-theater-masks"></i> Recreation</h5>
                                <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                                    <div className="">
                                        <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                            <div className='row'>
                                                <table><tr><td>{ShowRecreation}</td></tr></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {safetyInfo !== '' && <div className='col-md-6'>
                                <h4 className="h5 mt-1 text-primary"><i class="fas fa-shield-alt"></i> Safety Info</h4>
                                <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                                    <div className="">
                                        <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                            <div className='row'>
                                                <table><tr><td>{showSafetyInfo}</td></tr></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="row">

                            {showNearBy && <AboutArea hotelDetails={loadedHotelDetails}></AboutArea>}
                            {loadedHotelDetails !== null && loadedHotelDetails.data !== undefined && loadedHotelDetails.data.hotelResults !== undefined && loadedHotelDetails.data.hotelResults[0].descriptions !== undefined && <Policies hotelDetails={loadedHotelDetails}></Policies>}
                        </div>


                    </div>
                </div>
            </div>

        </section>
    </Fragment>
}

export default RoomRecommendationTwo;
import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from "../../../pages/Flights/MdModal";
import { sendEmailProposal } from "../../../services/SendMail-API";
import useHttp from "../../../services/use-http";
import { validateEmail } from '../../../utils/Validation'

import ReactDOMServer from 'react-dom/server';
import { clearAll } from "../../../utils/SendRecommendation";
import TravelRequestEmailTemplate from "./Components/TravelRequestEmailTemplate";

const TravelRequestEmail = (props) => {
    const inputEmailIdRef = useRef("");
    const remarksInputRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(sendEmailProposal);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState('');
    const errorHandler1 = () => {
        setError1(null);
    };
    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'FALSE') {
                setMailConfirm('Email sent successfully.');
                clearAll();
                document.getElementById("btnSend").disabled = true;
            } else {
                setMailConfirm("Some error occurred");
            }
            return;
        }

    }, [status])
    function onBlurCheck(event) {
        event.preventDefault();
        setMailConfirm('');
        document.querySelector('#msgSendMail').innerHTML = "";
    }
    function submitFormHandler(event) {
        event.preventDefault();

        let mail = inputEmailIdRef.current.value.trim()
        let cleanMail = mail.replace(/[\r\n]/gm, '');

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        if (inputEmailIdRef.current.value.includes(";")) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;
        }
        else {
            setInvalidEmailError(false);
        }

        if (!mail.includes(',') && validateEmail(mail) === null) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;

        } else {
            setInvalidEmailError(false);
        }
        if (cleanMail.includes(',')) {
            const mailArray = cleanMail.split(',');
            for (let email of mailArray) {
                if (validateEmail(email.trim()) === null) {
                    setInvalidEmailError(true);
                    inputEmailIdRef.current.focus();
                    return;

                } else {
                    setInvalidEmailError(false);
                }
            }

        }
        const data = ReactDOMServer.renderToString(<html>
            <head >


                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha512-0S+nbAYis87iX26mmj/+fWt1MmaKCv80H+Mbo+Ne7ES4I6rxswpfnC6PxmLiw33Ywj2ghbtTw0FkLbMWqh4F7Q==" crossorigin="anonymous" referrerpolicy="no-referrer" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/fontawesome.min.css" integrity="sha512-RtgdY8FN0wFbR4ascGaGFDgjPJBzXP6WZZExVmmWJOaTCm4B0nF3e4G9iy9SfATuFQxyc+Mq7hXINynh3iwLDg==" crossorigin="anonymous" referrerpolicy="no-referrer" />

                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />

                <link rel="stylesheet" href="https://staging.fareladder.com/assets/css/bootstrap.min.css" />
                <link rel="stylesheet" href="https://staging.fareladder.com/assets/css/navber.css" />
                <link rel="stylesheet" href="https://staging.fareladder.com/assets/css/animate.min.css" />
                <link rel="stylesheet" href="https://staging.fareladder.com/assets/css/fontawesome.all.min.css" />
                <link rel="stylesheet" href="https://staging.fareladder.com/assets/css/responsive.css" />


            </head>

            <body style={{ fontFamily: 'Poppins' }}>
                <TravelRequestEmailTemplate></TravelRequestEmailTemplate>
            </body></html>);


        const inputData = {
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "data": {
                "receipent": cleanMail,
                "remarks": remarksInputRef.current.value,
                "subject": "Proposed Flights",
                "pdfHtml": data,
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "userID": JSON.parse(localStorage.getItem('userID')),
            }
        }
        if (inputEmailIdRef.current.value !== '' && invalidEmailError === false) {
            sendRequest(
                inputData
            );
        }
    }

    function functionExit() {
        props.errorHandler();
        // clearAll();
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-1">
                <div className="container">
                    <div className="row">
                        <div className="gt-h-400px">
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Email ID<span className="error-icon"> * </span>
                                </label>
                                <div>
                                    <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={inputEmailIdRef}
                                        autoFocus>
                                    </textarea>
                                    <span className="h6 text-primary mb-2 col-lg-12" > <span className="text-black fw-bold"><i class="fas fa-info-circle"></i> Note : </span>  Please use commas to separate multiple Email IDs.</span>
                                    {balnkMailError && <p className="error-text">Email ID is required.</p>
                                    }
                                    {invalidEmailError && <p className="error-text">Please specify valid Email ID.</p>}

                                </div>
                            </div>
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Remarks
                                </label>
                                <div>
                                    <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={remarksInputRef}
                                    >
                                    </textarea>
                                </div>
                                {/* <span className="h6 text-primary mb-2 col-lg-12" > <span className="text-black fw-bold">Note : </span>  Please use commas to separate multiple Email IDs.</span> */}
                                <span id="msgSendMail" className="error-text"></span>
                                {!balnkMailError && !invalidEmailError && <p className="success-text">{mailConfirm}</p>}
                            </div>


                        </div>
                        <div className="col-md-6 my-auto"></div>
                        <div className="col-md-6 my-auto align-right " >
                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler} >
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : 'Send'}
                            </button>
                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnClose' onClick={functionExit} >
                                Close
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </Fragment>
};
export default TravelRequestEmail;
import React, { Fragment, useState, useEffect, useCallback, } from 'react';
import Dropdown from 'react-select';
import { UPDATERULES } from '../../../navigation/CONSTANTS';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import useHttp from '../../../services/use-http'
import { searchTripReason } from '../../../services/TripReason-API';
import { searchCorporateMaster } from '../../../services/CorporateMaster-API';
import { objectToArray } from '../../../utils/CommonFunction';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { searchPolicy } from '../../../services/Rules-API';
import RulesList from './Components/RulesList';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
const SearchRules = () => {
    useDocumentTitle("Search Policy")
    const [postion, setPostion] = useState('');
    const [tripReason, setTripReason] = useState('');
    const { t } = useTranslation(['translation.Rules']);
    const history = useHistory();
    const [error1, setError1] = useState(false);
    const [isFirstTime, setFirstTime] = useState("");
    const { sendRequest: sendRequestPostionList, data: loadedPostion } = useHttp(searchCorporateMaster);
    const { sendRequest: sendRequestTripReasonList, data: loadedTripReason, } = useHttp(searchTripReason);
    const { sendRequest, status, data: loadedPolicy, error: policyError } = useHttp(searchPolicy);

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        const PostionInput = (
            {
                "id": '',
                userID: JSON.parse(localStorage.getItem('userID')),
                "name": "",
                "code": "",
                "keyCode": getConfigurationByValue('POLICY_TYPE'),
                "status": "2",
                "officeID": JSON.parse(localStorage.getItem("officeID"))
            }

        );
        const onSerachTripReason = ({
            id: "",
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            name: "",
            code: "",
            meetingID: "",
            isFamilyBooking: true,
            officeID: JSON.parse(localStorage.getItem("officeID")),

        });

        sendRequestPostionList(PostionInput);
        sendRequestTripReasonList(onSerachTripReason)

    }, [
        sendRequestPostionList, sendRequestTripReasonList
    ])

    let postionOptions = objectToArray(loadedPostion);
    let tripReasonOptions = objectToArray(loadedTripReason);
    const positionChangeChangeHandler = (event) => {
        setPostion(event.value);
    };

    const tripReasonChangeHandler = (event) => {
        setTripReason(event.value);
    };
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Settings", "Policy", "Settings_Policy_Search")) {
            return false;
        }

        setFirstTime(true);
        const onSerachPolicy = ({
            "position": postion,
            "tripReason": tripReason,
            "fromRegion": "",
            "toRegion": "",
            officeID: JSON.parse(localStorage.getItem("officeID")),

        });
        sendRequest(onSerachPolicy)


    }, [sendRequest,postion,tripReason])
    useEffect(() => {
        if (status === 'completed') {
            if (loadedPolicy['errors'].error['code'] === '1008' || loadedPolicy['errors'].error['code'] === '9001') {
                setError1(true);
                return;
            }
        }
    }, [status, loadedTripReason])
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    if (policyError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }
    let loadedData = [];
    for (let i in loadedPolicy) {
        if (loadedPolicy['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedPolicy[i];
            }
        }
    }
    const onResetHandler = (event) => {
        history.push('/SearchPolicy');
        history.go();
    }
    function okayHandler() {
        setError1(false);
        history.go('/SearchPolicy');

    }
    const errorHandler = () => {
        history.go('/SearchPolicy');
    };
    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''
    const policyList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <RulesList policy={loadedData} /> : ErrorShow

    return (
        <Fragment>
            {error1 && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <LoadingSpinner></LoadingSpinner>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Policy</label>
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='position'>{getConfigurationByValue(getConfigurationByValue('POLICY_TYPE'))}</label>
                                                    <Dropdown
                                                        onChange={positionChangeChangeHandler}
                                                        value={postionOptions.filter(option => option.value === postion)}
                                                        options={postionOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className='col-md-6 col-sm-12 control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='region'>{t('tripReason')}</label>
                                                    <Dropdown onChange={tripReasonChangeHandler}
                                                        value={tripReasonOptions.filter(option => option.value === tripReason)}
                                                        options={tripReasonOptions}
                                                        textField="listValue"
                                                        dataItemKey="listID"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        isDisabled={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 col-md-12">
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>
                                                {checkSecurityDetails("Settings", "Policy", "Settings_Policy_Insert")
                                                    && <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATERULES}>
                                                        {t('new')}
                                                    </NavLink>}
                                                {checkSecurityDetails("Settings", "Policy", "Settings_Policy_Search")
                                                    && <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {policyList}

        </Fragment>)
}

export default SearchRules;
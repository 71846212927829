import { Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
const HomePageTemp = () => {
    useDocumentTitle('Home')
    return <Fragment>
   {/*  <!-- Banner Area --> */}

    <header class="main_header_arae homepage">
        <div class="topbar-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <ul class="topbar-list">
                            <a class="navbar-brand" href="/homepage">
                                <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo"  style={{height: "85px"}}/>
                            </a>
                            {/* <li>
                                <a href="#!"><i class="fab fa-facebook"></i></a>
                                <a href="#!"><i class="fab fa-twitter-square"></i></a>
                                <a href="#!"><i class="fab fa-instagram"></i></a>
                                <a href="#!"><i class="fab fa-linkedin"></i></a>
                            </li>
                            <li><a href="#!"><span>+011 234 567 89</span></a></li>
                            <li><a href="#!"><span>contact@domain.com</span></a></li> */}
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                    <div class="others-options text-end align-items-center mb-2 ">

<li style={{ listStyle: "none" }}><a href="#!" className='text-white text-16px'><span><i class="fas fa-phone"></i> +971 4 4082323</span></a></li>

</div>
                        <ul class="topbar-others-options">
                           
                            {/* <li>
                                <div class="dropdown language-option">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="lang-name"></span>
                                    </button>
                                    <div class="dropdown-menu language-dropdown-menu">
                                        <a class="dropdown-item" href="#">Help </a>
                                        <a class="dropdown-item" href="#">Help Center</a>
                                        <a class="dropdown-item" href="#">Contact Us</a>
                                    </div>
                                </div>
                            </li> */}
                            <li><a href="login.html">My Trips</a></li>
                            {/* <li><a href="register.html">Login</a></li> */}
                            <li>
                                <div class="dropdown language-option">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="lang-name"></span>
                                    </button>
                                    <div class="dropdown-menu language-dropdown-menu">
                                        <a class="dropdown-item" href="#">SAR</a>
                                        <a class="dropdown-item" href="#">BD</a>
                                        <a class="dropdown-item" href="#">URO</a>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div class="dropdown language-option">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="lang-name"></span>
                                    </button>
                                    <div class="dropdown-menu language-dropdown-menu">
                                        <a class="dropdown-item" href="#">USD</a>
                                        <a class="dropdown-item" href="#">BD</a>
                                        <a class="dropdown-item" href="#">URO</a>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="navbar-area1">
            <div class="main-responsive-nav">
                <div class="container">
                    <div class="main-responsive-menu">
                        <div class="logo">
                            <a href="index.html">
                                <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-navbar">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand d-none" href="/homepage">
                            <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo"/>
                        </a>
                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                                {/* <li class="nav-item">
                                    <a href="#" class="nav-link active">
                                        Home
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="index.html" class="nav-link">Home One</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="index-2.html" class="nav-link active">Home Two</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Tours
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="tour-search.html" class="nav-link">Tour</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-details.html" class="nav-link">Tour Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-booking-submission.html" class="nav-link">Tour Booking</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="top-destinations.html" class="nav-link">Top Destination</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="top-destinations-details.html" class="nav-link">Destination
                                                Details</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Flights
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="flight-search-result.html" class="nav-link">Flight</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="flight-booking-submission.html" class="nav-link">Flight Booking</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Hotel
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="hotel-search.html" class="nav-link">Hotel</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="hotel-details.html" class="nav-link">Hotel Booking</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="room-details.html" class="nav-link">Room Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="room-booking.html" class="nav-link">Room Booking</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Visa
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="visa-details.html" class="nav-link">Visa Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-application.html" class="nav-link">Visa Application</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Business visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Education visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Working visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Tourist visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Medical visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">On-arrival visa </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Pages
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="about.html" class="nav-link">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-guides.html" class="nav-link">Team</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="testimonials.html" class="nav-link">Testimonials</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="faqs.html" class="nav-link">FAQ</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="booking-confirmation.html" class="nav-link">Booking
                                                Confirmation</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">News</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="news.html" class="nav-link">News</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="news-details.html" class="nav-link">News Details</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">User Pages</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="login.html" class="nav-link">Login</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="register.html" class="nav-link">Register</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="forgot-password.html" class="nav-link">Forget Password</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="verify-otp.html" class="nav-link">Verify OTP</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="reset-password.html" class="nav-link">Reset Password</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">Customer Dashboard</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="dashboard.html" class="nav-link">Dashboard</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="hotel-booking.html" class="nav-link">Hotel booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="flight-booking.html" class="nav-link">Flight booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="tour-booking.html" class="nav-link">Tour booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="booking-history.html" class="nav-link">Booking history</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="my-profile.html" class="nav-link">My profile</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="wallet.html" class="nav-link">Wallet</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="notification.html" class="nav-link">Notifications</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="error.html" class="nav-link">404 Error</a>
                                        </li>
                                    </ul>
                                </li> */}
                                {/* <li class="nav-item">
                                    <a href="/homepage" class="nav-link kt-nav-btn"><i class="fas fa-plane-departure"></i> Flights</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-hotel"></i> Hotels</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-hiking"></i> Sightseeing</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-umbrella-beach"></i> Holidays</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-kaaba"></i> Haj and Umrah</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-ship"></i> Cruises</a>
                                </li> */}
                            </ul>
                            <div class="others-options d-flex align-items-center">

                                    {/* <li style={{ listStyle: "none" }}><a href="#!" className='text-white text-16px'><span><i class="fas fa-phone"></i> +971 4 4082323</span></a></li> */}
                                {/* <div class="option-item">
                                    <a href="#" class="search-box">
                                        <i class="bi bi-search"></i></a>
                                </div> */}
                                {/* <div class="option-item">
                                    <a href="become-vendor.html" class="btn  btn_navber">Become a partner</a>
                                </div> */}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="others-option-for-responsive">
                <div class="container">
                    <div class="dot-menu">
                        <div class="inner">
                            <div class="circle circle-one"></div>
                            <div class="circle circle-two"></div>
                            <div class="circle circle-three"></div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="option-inner">
                            <div class="others-options d-flex align-items-center">
                                <div class="option-item">
                                    <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                                </div>
                                <div class="option-item">
                                    <a href="contact.html" class="btn  btn_navber">Get free quote</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section id="home_two_banner">
        <div class="home_two_banner_slider_wrapper owl-carousel owl-theme">
            <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                style={{
                     backgroundImage: "url(https://bahrainwtc.com/wp-content/uploads/2022/04/Image-1.jpg)", 

                    //  background: "url(https://www.kanootravel.com/kanootravel.com/images/blog/Sheikh-Zayed-Grand-Mosque.jpg)"
                
                }}>
                {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                style={{ backgroundImage: "url(./assets/img/banner/homepage2.png)"}}>
                {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                style={{ backgroundImage: "url(./assets/img/banner/homepage1.png)"}}>
                {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    <section id="theme_search_form" className='home-original-search'>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mx-auto">
                    <div class="theme_search_form_area home-slider-serach-section">
                        <div class="theme_search_form_tabbtn">
                            <ul class="nav nav-tabs mb-4" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i class="fas fa-plane-departure"></i>Flights</button>
                                </li>
                                
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="hotels-tab" data-bs-toggle="tab"
                                        data-bs-target="#hotels" type="button" role="tab" aria-controls="hotels"
                                        aria-selected="false"><i class="fas fa-hotel"></i>Hotels</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="visa-tab" data-bs-toggle="tab"
                                        data-bs-target="#visa-application" type="button" role="tab" aria-controls="visa"
                                        aria-selected="false"><i class="fas fa-hiking"></i>  Sightseeing</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i class="fas fa-umbrella-beach"></i>   Holidays</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="visa-tab" data-bs-toggle="tab"
                                        data-bs-target="#visa-application" type="button" role="tab" aria-controls="visa"
                                        aria-selected="false"><i class="fas fa-kaaba"></i> Haj and Umrah</button>
                                </li>
                                {/* <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="visa-tab" data-bs-toggle="tab"
                                        data-bs-target="#visa-application" type="button" role="tab" aria-controls="visa"
                                        aria-selected="false"><i class="fas fa-ship"></i>   Cruises</button>
                                </li> */}
                              

                            </ul>
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg-12">
                                <div class="flight_categories_search">
                                    <ul class="nav nav-tabs mb-4" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link " id="oneway-tab" data-bs-toggle="tab"
                                                data-bs-target="#oneway_flight" type="button" role="tab"
                                                aria-controls="oneway_flight" aria-selected="true">One
                                                Way</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="roundtrip-tab" data-bs-toggle="tab"
                                                data-bs-target="#roundtrip" type="button" role="tab"
                                                aria-controls="roundtrip"
                                                aria-selected="false">Round Trip</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                                data-bs-target="#multi_city" type="button" role="tab"
                                                aria-controls="multi_city" aria-selected="false">Multi
                                                city</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="flights" role="tabpanel"
                                aria-labelledby="flights-tab">
                               
                                <div class="tab-content" id="myTabContent1">
                                    <div class="tab-pane fade  " id="oneway_flight" role="tabpanel"
                                        aria-labelledby="oneway-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="row">
                                                            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>From</p>
                                                                    <input type="text" value="New York"/>
                                                                    <span>JFK - John F. Kennedy International...</span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>To</p>
                                                                    <input type="text" value="London "/>
                                                                    <span>LCY, London city airport </span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div class="range_plan">
                                                                        <i class="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed date_flex_area">
                                                                        <div class="Journey_date">
                                                                            <p>Journey date</p>
                                                                            <input type="date" value="2022-05-05"/>
                                                                            <span>Thursday</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div
                                                                    class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>Passenger, Class </p>
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            0 Passenger
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="passengers">
                                                                                    <h6>Passengers</h6>
                                                                                    <div class="passengers-types">
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count pcount">2</span>
                                                                                                <div class="type-label">
                                                                                                    <p>Adult</p>
                                                                                                    <span>12+
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count ccount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Children
                                                                                                    </p><span>2
                                                                                                        - Less than 12
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-c">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-c">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count incount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Infant
                                                                                                    </p><span>Less
                                                                                                        than 2
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-in">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-in">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="cabin-selection">
                                                                                    <h6>Cabin Class</h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="muiButton-label">Economy
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn active">
                                                                                            <span
                                                                                                class="muiButton-label">
                                                                                                Business
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="MuiButton-label">First
                                                                                                Class </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>Business</span>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" id="roundtrip" role="tabpanel"
                                        aria-labelledby="roundtrip-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="row">
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>From</p>
                                                                  
                                                                    <input type="text" value="Riyadh "/>
                                                                    <span>RUH - King Khaled Intl, Saudi Arabia, SA</span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>To</p>
                                                                    <input type="text" value="Dubai"/>
                                                                    <span>DXB - Dubai, United Arab Emirates, AE</span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div class="range_plan">
                                                                        <i class="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed date_flex_area">
                                                                        <div class="Journey_date">
                                                                            <p>Journey date</p>
                                                                            <input type="date" value="2024-05-05"/>
                                                                            <span>Thursday</span>
                                                                        </div>
                                                                        <div class="Journey_date">
                                                                            <p>Return date</p>
                                                                            <input type="date" value="2024-05-08"/>
                                                                            <span>Saturday</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div
                                                                    class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>Passenger, Class </p>
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            1 Passenger
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="passengers">
                                                                                    <h6>Passengers</h6>
                                                                                    <div class="passengers-types">
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count pcount">2</span>
                                                                                                <div class="type-label">
                                                                                                    <p>Adult</p>
                                                                                                    <span>12+
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count ccount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Children
                                                                                                    </p><span>2
                                                                                                        - Less than 12
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-c">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-c">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count incount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Infant
                                                                                                    </p><span>Less
                                                                                                        than 2
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-in">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-in">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="cabin-selection">
                                                                                    <h6>Cabin Class</h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="muiButton-label">Economy
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn active">
                                                                                            <span
                                                                                                class="muiButton-label">
                                                                                                Business
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="MuiButton-label">First
                                                                                                Class </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>Business</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            {/* <button class="btn btn_theme btn_md">Search</button> */}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="multi_city" role="tabpanel"
                                        aria-labelledby="multi_city-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="multi_city_form_wrapper">
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" value="New York"/>
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text" value="London "/>
                                                                            <span>LCY, London city airport </span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div
                                                                                class="flight_Search_boxed date_flex_area">
                                                                                <div class="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-05"/>
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div class="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-10"/>
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, Class </p>
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        class="traveller-calulate-persons">
                                                                                        <div class="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                class="passengers-types">
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count pcount">2</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count ccount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-c">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-c">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count incount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-in">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-in">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="cabin-selection">
                                                                                            <h6>Cabin Class</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn active">
                                                                                                    <span
                                                                                                        class="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="MuiButton-label">First
                                                                                                        Class </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" value="New York"/>
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text" value="London "/>
                                                                            <span>LCY, London city airport </span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div
                                                                                class="flight_Search_boxed date_flex_area">
                                                                                <div class="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-05"/>
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div class="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-12"/>
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, Class </p>
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        class="traveller-calulate-persons">
                                                                                        <div class="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                class="passengers-types">
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count pcount">2</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count ccount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-c">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-c">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count incount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-in">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-in">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="cabin-selection">
                                                                                            <h6>Cabin Class</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn active">
                                                                                                    <span
                                                                                                        class="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="MuiButton-label">First
                                                                                                        Class </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="add_multy_form">
                                                                    <button type="button" id="addMulticityRow">+ Add
                                                                        another
                                                                        flight</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button class="btn btn_theme btn_md">Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date" value="2022-05-03"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date" value="2022-05-05"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>Cabin Class</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span
                                                                                        class="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn active">
                                                                                    <span class="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span class="MuiButton-label">First
                                                                                        Class </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date" value="2022-05-03"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date" value="2022-05-05"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>Cabin Class</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span
                                                                                        class="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn active">
                                                                                    <span class="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span class="MuiButton-label">First
                                                                                        Class </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="visa-application" role="tabpanel" aria-labelledby="visa-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Select country</p>
                                                            <input type="text" value="United states"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Your nationality</p>
                                                            <input type="text" value="Bangladesh"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Entry date</p>
                                                                    <input type="date" value="2022-05-03"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Exit date</p>
                                                                    <input type="date" value="2022-05-05"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Traveller, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Traveller
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Traveller</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                      
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center"><button class="btn text-white mt-2 kt-btn btn_md aaaa">Search</button></div>
                           
                        </div>
                   

                        <div class="theme_search_form_tabbtn d-none">
                            <ul class="nav nav-tabs mb-4" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i class="fas fa-plane-departure"></i>Flights</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                            class="fas fa-globe"></i>Tours</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="hotels-tab" data-bs-toggle="tab"
                                        data-bs-target="#hotels" type="button" role="tab" aria-controls="hotels"
                                        aria-selected="false"><i class="fas fa-hotel"></i>Hotels</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="visa-tab" data-bs-toggle="tab"
                                        data-bs-target="#visa-application" type="button" role="tab" aria-controls="visa"
                                        aria-selected="false"><i class="fas fa-passport"></i> e-Visa</button>
                                </li>

                            </ul>
                        </div>
                        <div class="tab-content d-none" id="myTabContent">
                            <div class="tab-pane fade show active" id="flights" role="tabpanel"
                                aria-labelledby="flights-tab">
                                <div class="row d-none">
                                    <div class="col-lg-12">
                                        <div class="flight_categories_search">
                                            <ul class="nav nav-tabs mb-4" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="oneway-tab" data-bs-toggle="tab"
                                                        data-bs-target="#oneway_flight" type="button" role="tab"
                                                        aria-controls="oneway_flight" aria-selected="true">One
                                                        Way</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="roundtrip-tab" data-bs-toggle="tab"
                                                        data-bs-target="#roundtrip" type="button" role="tab"
                                                        aria-controls="roundtrip"
                                                        aria-selected="false">Roundtrip</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                                        data-bs-target="#multi_city" type="button" role="tab"
                                                        aria-controls="multi_city" aria-selected="false">Multi
                                                        city</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent1">
                                    <div class="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                        aria-labelledby="oneway-tab">
                                        <div className='row'>
                                            <div class="dropdown trip-dropdown">
                                                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span class="lang-name"></span>
                                                </button>
                                                <div class="dropdown-menu trip-dropdown-menu">
                                                    <a class="dropdown-item" href="#">Return </a>
                                                    <a class="dropdown-item" href="#">One Way</a>
                                                    <a class="dropdown-item" href="#">Multi-City</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!" className='d-none'>
                                                        <div class="row">
                                                            <div className='col-md-7'>
                                                               <div className='row homepage-search-bg'>
                                                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                                        <div class="flight_Search_boxed">
                                                                            {/* <p>From</p> */}
                                                                            {/* <input type="text" value="New York"/> */}
                                                                            <span>JFK - John F. Kennedy International...</span>
                                                                            <div class="plan_icon_posation">
                                                                                {/* <i class="fas fa-plane-departure"></i> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            {/* <p>To</p> */}
                                                                            {/* <input type="text" value="London "/> */}
                                                                            <span>LCY, London city airport </span>
                                                                            <div class="plan_icon_posation">
                                                                                {/* <i class="fas fa-plane-arrival"></i> */}
                                                                            </div>
                                                                            <div class="range_plan">
                                                                            <i class="fas fa-sync-alt text-dark"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                               </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-12 col-12 ">
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed date_flex_area homepage-search-bg">
                                                                        <div class="Journey_date">
                                                                            {/* <p>Journey date</p> */}
                                                                            {/* <input type="date" value="2022-05-05"/> */}
                                                                            <span><i class="far fa-calendar-alt"></i> Fri, 19 Apr - Sat, 20 Apr</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                {/* <div
                                                                    class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>Passenger, Class </p>
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            0 Passenger
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="passengers">
                                                                                    <h6>Passengers</h6>
                                                                                    <div class="passengers-types">
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count pcount">2</span>
                                                                                                <div class="type-label">
                                                                                                    <p>Adult</p>
                                                                                                    <span>12+
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count ccount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Children
                                                                                                    </p><span>2
                                                                                                        - Less than 12
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-c">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-c">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count incount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Infant
                                                                                                    </p><span>Less
                                                                                                        than 2
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-in">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-in">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="cabin-selection">
                                                                                    <h6>Cabin Class</h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="muiButton-label">Economy
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn active">
                                                                                            <span
                                                                                                class="muiButton-label">
                                                                                                Business
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="MuiButton-label">First
                                                                                                Class </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>Business</span>
                                                                </div> */}
                                                                <div class="top_form_search_button mt-0">
                                                                    <button class="btn kt-btn text-white btn_md">Search Flights</button>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade d-none" id="roundtrip" role="tabpanel"
                                        aria-labelledby="roundtrip-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="row">
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>From</p>
                                                                    <input type="text" value="New York"/>
                                                                    <span>JFK - John F. Kennedy International...</span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div class="flight_Search_boxed">
                                                                    <p>To</p>
                                                                    <input type="text" value="London "/>
                                                                    <span>LCY, London city airport </span>
                                                                    <div class="plan_icon_posation">
                                                                        <i class="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div class="range_plan">
                                                                        <i class="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div class="form_search_date">
                                                                    <div class="flight_Search_boxed date_flex_area">
                                                                        <div class="Journey_date">
                                                                            <p>Journey date</p>
                                                                            <input type="date" value="2022-05-05"/>
                                                                            <span>Thursday</span>
                                                                        </div>
                                                                        <div class="Journey_date">
                                                                            <p>Return date</p>
                                                                            <input type="date" value="2022-05-08"/>
                                                                            <span>Saturday</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div
                                                                    class="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>Passenger, Class </p>
                                                                    <div class="dropdown">
                                                                        <button class="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            0 Passenger
                                                                        </button>
                                                                        <div class="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div class="traveller-calulate-persons">
                                                                                <div class="passengers">
                                                                                    <h6>Passengers</h6>
                                                                                    <div class="passengers-types">
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count pcount">2</span>
                                                                                                <div class="type-label">
                                                                                                    <p>Adult</p>
                                                                                                    <span>12+
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count ccount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Children
                                                                                                    </p><span>2
                                                                                                        - Less than 12
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-c">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-c">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="passengers-type">
                                                                                            <div class="text"><span
                                                                                                    class="count incount">0</span>
                                                                                                <div class="type-label">
                                                                                                    <p
                                                                                                        class="fz14 mb-xs-0">
                                                                                                        Infant
                                                                                                    </p><span>Less
                                                                                                        than 2
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="button-set">
                                                                                                <button type="button"
                                                                                                    class="btn-add-in">
                                                                                                    <i
                                                                                                        class="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn-subtract-in">
                                                                                                    <i
                                                                                                        class="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="cabin-selection">
                                                                                    <h6>Cabin Class</h6>
                                                                                    <div class="cabin-list">
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="muiButton-label">Economy
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn active">
                                                                                            <span
                                                                                                class="muiButton-label">
                                                                                                Business
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="label-select-btn">
                                                                                            <span
                                                                                                class="MuiButton-label">First
                                                                                                Class </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>Business</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button class="btn btn_theme btn_md">Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade d-none" id="multi_city" role="tabpanel"
                                        aria-labelledby="multi_city-tab">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="oneway_search_form">
                                                    <form action="#!">
                                                        <div class="multi_city_form_wrapper">
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" value="New York"/>
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text" value="London "/>
                                                                            <span>LCY, London city airport </span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div
                                                                                class="flight_Search_boxed date_flex_area">
                                                                                <div class="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-05"/>
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div class="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-10"/>
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, Class </p>
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        class="traveller-calulate-persons">
                                                                                        <div class="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                class="passengers-types">
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count pcount">2</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count ccount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-c">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-c">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count incount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-in">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-in">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="cabin-selection">
                                                                                            <h6>Cabin Class</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn active">
                                                                                                    <span
                                                                                                        class="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="MuiButton-label">First
                                                                                                        Class </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="multi_city_form">
                                                                <div class="row">
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" value="New York"/>
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div class="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text" value="London "/>
                                                                            <span>LCY, London city airport </span>
                                                                            <div class="plan_icon_posation">
                                                                                <i class="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div class="range_plan">
                                                                                <i class="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div class="form_search_date">
                                                                            <div
                                                                                class="flight_Search_boxed date_flex_area">
                                                                                <div class="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-05"/>
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div class="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date"
                                                                                        value="2022-05-12"/>
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            class="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, Class </p>
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    class="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        class="traveller-calulate-persons">
                                                                                        <div class="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                class="passengers-types">
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count pcount">2</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count ccount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-c">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-c">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="passengers-type">
                                                                                                    <div class="text">
                                                                                                        <span
                                                                                                            class="count incount">0</span>
                                                                                                        <div
                                                                                                            class="type-label">
                                                                                                            <p
                                                                                                                class="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-add-in">
                                                                                                            <i
                                                                                                                class="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn-subtract-in">
                                                                                                            <i
                                                                                                                class="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="cabin-selection">
                                                                                            <h6>Cabin Class</h6>
                                                                                            <div class="cabin-list">
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn active">
                                                                                                    <span
                                                                                                        class="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="label-select-btn">
                                                                                                    <span
                                                                                                        class="MuiButton-label">First
                                                                                                        Class </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="add_multy_form">
                                                                    <button type="button" id="addMulticityRow">+ Add
                                                                        another
                                                                        flight</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="top_form_search_button">
                                                            <button class="btn btn_theme btn_md">Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date" value="2022-05-03"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date" value="2022-05-05"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>Cabin Class</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span
                                                                                        class="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn active">
                                                                                    <span class="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span class="MuiButton-label">First
                                                                                        Class </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date" value="2022-05-03"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date" value="2022-05-05"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="cabin-selection">
                                                                            <h6>Cabin Class</h6>
                                                                            <div class="cabin-list">
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span
                                                                                        class="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn active">
                                                                                    <span class="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="label-select-btn">
                                                                                    <span class="MuiButton-label">First
                                                                                        Class </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="visa-application" role="tabpanel" aria-labelledby="visa-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Select country</p>
                                                            <input type="text" value="United states"/>
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed">
                                                            <p>Your nationality</p>
                                                            <input type="text" value="Bangladesh" />
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div class="flight_Search_boxed date_flex_area">
                                                                <div class="Journey_date">
                                                                    <p>Entry date</p>
                                                                    <input type="date" value="2022-05-03" />
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div class="Journey_date">
                                                                    <p>Exit date</p>
                                                                    <input type="date" value="2022-05-05" />
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div class="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Traveller, Class </p>
                                                            <div class="dropdown">
                                                                <button class="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Traveller
                                                                </button>
                                                                <div class="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div class="traveller-calulate-persons">
                                                                        <div class="passengers">
                                                                            <h6>Traveller</h6>
                                                                            <div class="passengers-types">
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count pcount">2</span>
                                                                                        <div class="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count ccount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-c">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-c">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="passengers-type">
                                                                                    <div class="text"><span
                                                                                            class="count incount">0</span>
                                                                                        <div class="type-label">
                                                                                            <p class="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="button-set">
                                                                                        <button type="button"
                                                                                            class="btn-add-in">
                                                                                            <i class="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn-subtract-in">
                                                                                            <i class="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div class="top_form_search_button">
                                                        <button class="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section id="destinations_area" class="section_padding_top position-relative mt-5 pt-5">
        <div class="container">
            <div class="row mt-5 pt-5">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="section_heading_left">
                        <h2 className='d-none'>Hot flight deals for you!</h2>
                        {/* <p>Nostrud aliqua ipsum dolore velit labore nulla fugiat.</p> */}
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-end">
                    <div class="section_heading_left_view_all">
                        {/* <a href="top-destinations.html">View all</a> */}
                    </div>
                </div>
            </div>
            {/* <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small1.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small3.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small1.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small4.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small6.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="tab_destinations_boxed">
                        <div class="tab_destinations_img">
                            <a href="top-destinations.html"><img src="https://andit.co/projects/html/and-tour/demo/assets/img/destination/destination-small5.png" alt="img" /></a>
                        </div>
                        <div class="tab_destinations_conntent">
                            <h3><a href="top-destinations.html">Sidney To Gold Coast</a></h3>
                            <p>Economy Class <span>$105.00</span></p>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    </section>
    
    <section id="popular_destination_eight" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    {/* <h2>Top trending holiday deals</h2> */}
                     <div class="section_heading_eight">
                        {/* <h5>Popular destinations</h5> */}
                        <h2>Top Deals</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="popular_tours_eight_slider button_style_top_left owl-theme owl-carousel owl-loaded owl-drag">
                    <div class="owl-stage-outer">
                        <div class="owl-stage">
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Costa-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/CARRIBEAN-Cruise-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/MSC-Bellissima-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Beauty-of-Seychelles-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/AARAH-MALDIVES-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">London aliqua irure proident esse</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Costa-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/MSC-Bellissima-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/CARRIBEAN-Cruise-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/AARAH-MALDIVES-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/SWISS-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Beauty-of-Seychelles-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Beauty-of-Seychelles-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">London aliqua irure proident esse</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Disney-Land-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Sydney irure dolor anim exerciton</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Istanbul-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Phuket non ullamco amet dolore</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/images/April2022/Costa-Th.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                <div class="common_card_eight_bottom_left">
                                    <p>4.8/5 Excellent</p>
                                    <h6>(1214 reviewes)</h6>
                                </div>
                                <div class="common_card_eight_bottom_right">
                                    <h4>$99.00 <sub>/Per person</sub>
                                    </h4>
                                </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="owl-nav">
                        <button type="button" role="presentation" class="owl-prev">
                        <i class="fas fa-arrow-left"></i>
                        </button>
                        <button type="button" role="presentation" class="owl-next">
                        <i class=" fas fa-arrow-right"></i>
                        </button>
                    </div>
                    <div class="owl-dots disabled"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="popular_destination_eight" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    {/* <h2>Top trending holiday deals</h2> */}
                     <div class="section_heading_eight">
                        {/* <h5>Popular destinations</h5> */}
                        <h2>Discover the many wonders of Saudi Arabia</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="popular_tours_eight_slider button_style_top_left owl-theme owl-carousel owl-loaded owl-drag">
                    <div class="owl-stage-outer">
                        <div class="owl-stage">
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/1.jpg" alt="img" />
                                </a>
                            </div>
                            {/* <div class="common_card_eight_text">
                                <ul class="common_card_eight_list">
                                <li>7 nights 8 days tour <i class="fas fa-circle"></i>
                                </li>
                                <li>Family tour</li>
                                </ul>
                                <h3>
                                <a href="tour-search.html">Gangtok magna amet voluptate</a>
                                </h3>
                                <p>
                                <i class="fas fa-map-marker-alt"></i>London, United Kingdom
                                </p>
                                <div class="common_card_eight_bottom">
                                    <div class="common_card_eight_bottom_left">
                                        <p className='text-16px' style={{fontSize: "18px"}}><i class="fa-solid fa-bed"></i> Stay</p>
                                        <h6 className='mt-2 text-14px' style={{fontSize: "14px"}}>2 adults, 2 children from</h6>
                                    </div>
                                    <div class="common_card_eight_bottom_right">
                                        <h4><i class="fas fa-plane"></i> Fly + stay</h4>
                                        <h6 className='text-muted mt-2'>2 adults, 2 children from</h6>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <span className='h4 text-primary'><strong>$1,159</strong>/total</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/2.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/3.jpg" alt="img" />
                                </a>
                            </div>
                           
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/4.jpg" alt="img" />
                                </a>
                            </div>
                           
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/5.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/6.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/7.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/8.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/9.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/10.jpg" alt="img" />
                                </a>
                            </div>
                           
                            </div>
                        </div>
                        <div class="owl-item active" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/11.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/5.jpg" alt="img" />
                                </a>
                            </div>
                            
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/6.jpg" alt="img" />
                                </a>
                            </div>
                           
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/7.jpg" alt="img" />
                                </a>
                            </div>
                       
                        
                            </div>
                        </div>
                        <div class="owl-item cloned" style={{ width: "331px", marginRight: "30px" }}>
                            <div class="common_card_eight">
                            <div class="common_card_eight_img">
                                <a href="tour-search.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/deals/SaudiTourism/8.jpg" alt="img" />
                                </a>
                            </div>
                          
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="owl-nav">
                        <button type="button" role="presentation" class="owl-prev">
                        <i class="fas fa-arrow-left"></i>
                        </button>
                        <button type="button" role="presentation" class="owl-next">
                        <i class=" fas fa-arrow-right"></i>
                        </button>
                    </div>
                    <div class="owl-dots disabled"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="home_news_four" class="section_padding d-none">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="section_heading_left">
                        <h2>Blogs</h2>
                        {/* <p>Nostrud aliqua ipsum dolore velit labore nulla fugiat.</p> */}
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="section_heading_left_view_all">
                        <a href="news.html">View all</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="news_card_four_wrapper">
                        <div class="news_card_four_img">
                            <a href="news-details.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Cambodia.jpg" alt="img"/>
                            </a>
                        </div>
                        <div class="news_card_four_heading">
                            <h3>
                                <a href="news-details.html">
                                    Revolutionizing the travel industry, one partnership at a time
                                </a>
                            </h3>
                            <ul>
                                <li>24th January <i class="fas fa-circle"></i></li>
                                <li>5min read</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="news_card_four_wrapper">
                        <div class="news_card_four_img">
                            <a href="news-details.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Legoland-Dubai.jpg" alt="img"/>
                            </a>
                        </div>
                        <div class="news_card_four_heading">
                            <h3>
                                <a href="news-details.html">
                                Top 5 Activities for Families at Legoland Dubai
                                </a>
                            </h3>
                            <ul>
                                <li>24th January <i class="fas fa-circle"></i></li>
                                <li>5min read</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div class="news_card_four_wrapper">
                        <div class="news_card_four_img">
                            <a href="news-details.html">
                                <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Sheikh-Zayed-Grand-Mosque.jpg" alt="img"/>
                            </a>
                        </div>
                        <div class="news_card_four_heading">
                            <h3>
                                <a href="news-details.html">
                                    There are many variations of passages of sum available
                                </a>
                            </h3>
                            <ul>
                                <li>24th January <i class="fas fa-circle"></i></li>
                                <li>5min read</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

    <section id="home_six_news_area" class=" section_padding slider_btn">
        <div class="container">
            <div class="col-12">
            <div class="home_six_section_heading">
                {/* <h5>News</h5> */}
                <h2> Blogs</h2>
            </div>
            <div class="home_six_news_slider_wrapper owl-theme owl-carousel owl-loaded owl-drag">
                <div class="owl-stage-outer">
                <div class="owl-stage">
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Est minimoccaecat trie ut duis occaecat sit eu nisi
                            esse incididunt officia laboris</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Top Destinations in Saudi Arabia for Art Lovers</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Why Louvre Abu Dhabi is a Must-See Attraction</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item active col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Sheikh-Zayed-Grand-Mosque.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item active col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Cambodia.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">BERLIN – TOP ATTRACTIONS IN GERMANY’S CAPITAL, KAMPOT, Koh Rong Samloem</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item active col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Legoland-Dubai.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Top 5 Activities for Families at Legoland Dubaii <br></br> <br></br></a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Legoland-Dubai.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Top 5 Activities for Families at Legoland Dubai<br></br><br></br></a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Louvre-Abu-Dhabi.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                            esse incididunt officia laboris</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Louvre-Abu-Dhabi.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Sheikh-Zayed-Grand-Mosque.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Cambodia.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list" >
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                            esse incididunt officia laboris</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/blog/Cambodia.jpg" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                            esse incididunt officia laboris</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-3.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>8<br></br>
                            MAR
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item cloned col-md-4"  >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-1.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>26 <br></br>
                            DEC
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Visiting Sheikh Zayed Grand Mosque – A Must for any Trip to Abu Dhabi</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                    <div class="owl-item clone col-md-4" >
                    <div class="news_card_six">
                        <div class="news_card_six_img">
                        <a href="news-details.html">
                        <img src="https://andit.co/projects/html/and-tour/demo/assets/img/home-six/news/news-six-2.png" alt="img"/>
                        </a>
                        </div>
                        <div class="news_card_six_text">
                        <div class="news_card_six_top">
                            <h3>14 <br></br>
                            FEB
                            </h3>
                        </div>
                        <ul class="news_card_six_list">
                            <li><a href="#!">Travel</a> <i class="fas fa-square"></i></li>
                            <li>Admin <i class="fas fa-square"></i></li>
                            <li>Comments (8)</li>
                        </ul>
                        <h3><a href="news-details.html">Est minim ut duis occaecat sit eu nisi
                            esse incididunt officia laboris</a>
                        </h3>
                        <a href="news-details.html">READ MORE <i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                <div class="owl-dots"><button role="button" class="owl-dot active"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button></div>
            </div>
            </div>
        </div>
    </section>

    <footer id="footer_area">
        <div class="container">
            <div className='row mb-5'>
                <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                    <div class="copyright_right">
                        <img src="https://www.kanootravel.com/kanootravel.com/images/kanoologo.png" alt="img" style={{float: "left"}} />
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>OUR COMPANY</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><a href="about.html">About Us</a></li>
                            <li><a href="testimonials.html">YBA Kanoo</a></li>
                            <li><a href="faqs.html">Services</a></li>
                            <li><a href="terms-service.html">Awards</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>PRESENCE</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><a href="room-details.html">United Arab Emirates</a></li>
                            <li><a href="hotel-details.html">Saudi Arabiak</a></li>
                            <li><a href="hotel-booking.html">Bahrain</a></li>
                            <li><a href="tour-search.html">Oman</a></li>
                            <li><a href="tour-booking.html">Egypt </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>MEDIA CENTRE</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><a href="room-details.html">Press Releases</a></li>
                            <li><a href="hotel-details.html">Media Library</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>POLICIES</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><a href="dashboard.html">Privacy Policy</a></li>
                            <li><a href="faq.html">Terms & Conditions</a></li>
                            <li><a href="testimonials.html">Website Acceptable Use Policy</a></li>
                            <li><a href="contact.html">Cookies Policy</a></li>
                         
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>GET IN TOUCH</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul>
                            <li><a href="top-destinations-details.html">Contact Us</a></li>
                            <li><a href="top-destinations-details.html">Disclaimer</a></li>
                            <li><a href="flight-search-result.html">FAQs</a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="co-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div class="copyright_right">
                          
                        <div class="text-center" style={{ color: "red" }}>
                            <span className="h4"><a href="#!"><i class="fab fa-facebook"></i></a></span>
                            <span className="ms-2 h4"><a href="#!"><i class="fab fa-twitter-square"></i></a></span>
                            <span className="ms-2 h4"><a href="#!"><i class="fab fa-instagram"></i></a></span>
                            <span className="ms-2 h4"><a href="#!"><i class="fab fa-linkedin"></i></a></span>
                        </div>
                    </div>    
                </div>
        </div>
    </footer>
    <div class="copyright_area" style={{ background: "#032942" }}>
        <div class="container">
            <div class="row align-items-center">
                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="copyright_left">
                        
                        <p className='text-white'>Copyright © 2024 Kanoo Travel. All Rights Reserved</p>
                    </div>
                </div>
                <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="copyright_right d-none">
                        <span><img src="https://www.kanootravel.com/kanootravel.com/images/pg2.png" alt="img"/><img src="https://www.kanootravel.com/kanootravel.com/images/pg3.png" className="ms-2" alt="img2"></img></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="go-top">
        <i class="fas fa-chevron-up"></i>
        <i class="fas fa-chevron-up"></i>
    </div>

    </Fragment>

};

export default HomePageTemp;
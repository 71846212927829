import { Fragment } from 'react';
import { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { emailValidation } from './../../../utils/Validation';
import { useTranslation } from 'react-i18next';
import { getConfigurationByValue } from './../../../utils/ConfigurationsFunction';

const ContactPerson = (props) => {
    const { t } = useTranslation(['translation.Booking']);
    const emailInputRef = useRef('');
    const phoneInputRef = useRef('');
    const phoneDestinationInputRef = useRef('');

    const [emailInputHasError, setEmailInputHasError] = useState(false);
    const [emailInvalidInputHasError, setEmailInvalidInputHasError] = useState(false);
    const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);
    const [phoneNumberDestinationInputHasError, setPhoneNumberDestinationInputHasError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    //const [activeTabSet, setActiveTab] = useState();
    const [phoneNumberDestination, setPhoneNumberDestination] = useState('');
    const [countryDialCodeDestination, setCountryDialCodeDestination] = useState('');

    useEffect(() => {
        //setActiveTab(JSON.parse(localStorage.getItem("tabActiveStatusSet")).Traveller);
        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId === '' && localStorage.getItem('email') !== null) {
            emailInputRef.current.value = getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3" ? JSON.parse(localStorage.getItem('email')) : ''
        }
        else if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId !== '') {
            emailInputRef.current.value = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId;
        }

        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === "3") {
            if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
                let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));

                setPhoneNumber(profileJSON !== null ? profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[1] : '')
                setCountryDialCode(profileJSON !== null ? profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[0] : '')
            }
            else if (phoneNumber === '' && countryDialCode === '') {
                let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0].length;
                let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').length;

                setCountryDialCode(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0]);
                setPhoneNumber(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            }
        }
        else {
            if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
                setPhoneNumber(JSON.parse(localStorage.getItem('phoneNumber')))
                setCountryDialCode(JSON.parse(localStorage.getItem('phoneCode')))
            }
            else if (phoneNumber === '' && countryDialCode === '') {
                let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ')[0].length;
                let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.replace(/[^0-9]/g, '').length;

                setCountryDialCode(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ')[0]);
                setPhoneNumber(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            }
        }

        // if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
        //     setPhoneNumberDestination(JSON.parse(localStorage.getItem('phoneNumber')))
        //     setCountryDialCodeDestination(JSON.parse(localStorage.getItem('phoneCode')))
        // }
        if (phoneNumberDestination === '' && countryDialCodeDestination === '') {
            let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0].length;
            let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').length;

            setCountryDialCodeDestination(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile !== undefined ? hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0] : '');
            setPhoneNumberDestination(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
        }
    }, [])

    const setPhone = (value) => {
        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            let countryDialCodeLength = phoneInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneInputRef.current.numberInputRef.value.split(' ')[0]);
            setPhoneNumber(phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberInputHasError(false);
        }
    };

    const setPhoneDestination = (value) => {
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
            if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
                //setPhoneNumberDestinationInputHasError(true);
                //return;
            }
            else {
                let countryDialCodeLength = phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0].length;
                let phoneNumberLength = phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

                setCountryDialCodeDestination(phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0]);

                setPhoneNumberDestination(phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
                setPhoneNumberDestinationInputHasError(false);
            }
        }
    };

    const onBlurPhoneDestination = (value) => {
        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
    };

    function assignContactPerson(event) {
        event.preventDefault();
        if (emailInputRef.current.value === '') {
            setEmailInputHasError(true);
            setEmailInvalidInputHasError(false);
            return;
        }
        else {
            setEmailInputHasError(false);
        }

        if (!emailValidation(emailInputRef.current.value)) {
            setEmailInvalidInputHasError(true);
            setEmailInputHasError(false);
            return;
        }
        else {
            setEmailInvalidInputHasError(false);
        }

        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
            if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
                setPhoneNumberDestinationInputHasError(true);
                return;
            }
            else {
                setPhoneNumberDestinationInputHasError(false);
            }
        }

        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        hotelBookingJSON.data.TravelerInfo.GuestInfo.forEach(element => {
            element.Email[0].EmailId = emailInputRef.current.value;
            element.Telephone[0].PhoneNumber = countryDialCode + " " + phoneNumber;
            element.Telephone[0].Mobile = countryDialCodeDestination + " " + phoneNumberDestination;
        });
        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

        props.onContinueButton("2");
        // window.scrollTo({
        //     top: 530,
        //     behavior: "smooth",
        // });
        if (document.querySelector("#headingThree") !== null) {
            const yOffset = -250;
            const element2 = document.getElementById("headingThree");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    function onBlurCheck() {

        if (emailInputRef.current.value === '') {
            setEmailInputHasError(true);
            setEmailInvalidInputHasError(false);
            return;
        }
        else {
            setEmailInputHasError(false);
        }

        if (!emailValidation(emailInputRef.current.value)) {
            setEmailInvalidInputHasError(true);
            setEmailInputHasError(false);
            return;
        }
        else {
            setEmailInvalidInputHasError(false);
        }

        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
        else {
            setPhoneNumberDestinationInputHasError(false);
        }
    }

    return (<Fragment>
        <div className="accordion_flex_area">
            <div className="accordion_left_side position-relative text-muted">
                <h5>2</h5>
                <span id="completeTwo" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button id="headingTwo" className="text-muted accordion-button collapsed" type="button"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">Booking Contact
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse"
                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='control'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Email<span className="error-icon"> *</span></label>
                                    <input type='text' className="mb-0 form-control"
                                        ref={emailInputRef}
                                        maxLength={100}
                                        onBlur={onBlurCheck}
                                        id='email'></input>
                                    {emailInputHasError &&
                                        <p className="error-text">{t('validationEmailId')}</p>
                                    }
                                    {emailInvalidInputHasError &&
                                        <p className="error-text">{t('validationEmailId')}</p>
                                    }
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='control'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone<span className="error-icon"> *</span></label>
                                    <PhoneInput type='text' required
                                        country={'us'}
                                        id='phone'
                                        value={countryDialCode + phoneNumber}
                                        placeholder=''
                                        enableSearch={true}
                                        ref={phoneInputRef}
                                        onChange={setPhone}
                                    />
                                    {phoneNumberInputHasError &&
                                        <p className="error-text">{t('validationPhoneNumber')}</p>
                                    }
                                </div>
                            </div>
                            {
                                getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3" &&
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <div className='control'>
                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone at Destination</label>
                                        <PhoneInput type='text' required
                                            country={'us'}
                                            id='phoneDestination'
                                            value={countryDialCodeDestination + phoneNumberDestination}
                                            onBlur={onBlurPhoneDestination}
                                            placeholder=''
                                            enableSearch={true}
                                            ref={phoneDestinationInputRef}
                                            onChange={setPhoneDestination}
                                        />
                                        {phoneNumberDestinationInputHasError &&
                                            <p className="error-text">{t('validationPhoneDestinationNumber')}</p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="float-end">
                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                    <button type="button" onClick={assignContactPerson} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>)
}

export default ContactPerson;
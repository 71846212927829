import { Fragment, useState, useEffect } from "react";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { useParams, useHistory } from "react-router-dom";
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import useHttp from "../../../services/use-http";
import useHttpGet from "../../../services/use-http";
import CustomizeForm from "./Components/CustomizeForm";
import { useTranslation } from 'react-i18next';
import { updateCustomizeSetting, getCustomizeSetting } from "../../../services/CustomizeSetting-API";

const NewCustomize = () => {
    useDocumentTitle("Customize");
    const { t } = useTranslation(['translation.Customize']);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id } = parms;
    const { sendRequest: customSendRequest, data: loadedCustomizeDetails, status: customStatus, error } = useHttpGet(getCustomizeSetting);
    const [duplicatePageNameErrorRefreshToken, setDuplicatePageNameErrorRefreshToken] = useState(0);

    let varHttp;

    if (customStatus === 'completed') {
        varHttp = updateCustomizeSetting
    }

    //const { sendRequest: defaultSendRequest, data: defaultCustomizeData, status: status2 } = useHttp(getCustomize);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();
    let loadedData = [];

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (customStatus === 'completed') {
        if (loadedCustomizeDetails && loadedCustomizeDetails['errors'].status === 'FALSE') {
            for (let i in loadedCustomizeDetails) {
                if (loadedCustomizeDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedCustomizeDetails[i];

                        if (loadedData.id !== null) {
                            localStorage.setItem("CUSTOMIZE_ID", loadedData.id);
                            //setIsFirstTime(true);
                        } else {
                            localStorage.setItem("CUSTOMIZE_ID", '');
                        }

                    }
                }
            }

        }
    }

    useEffect(() => {
        if (Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote && loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote.errors.error?.code === '1010') {
                        //Case of duplicate page name
                        setDuplicatePageNameErrorRefreshToken(t => t + 1);
                    }
                    else {
                        setError({
                            title: "Message",
                            message: t('errorRecordSave'),
                        });
                    }
                }
                else {

                    localStorage.setItem("CUSTOMIZE_ID", loadedQuote['data'].id);
                    setError({
                        title: "Message",
                        message: sucsssMessage,
                    });
                }
            }
        }
    }, [status, history, Id, sucsssMessage, t, loadedQuote]);


    const officeID = JSON.parse(localStorage.getItem('officeID')) + "@@11@@" + JSON.parse(localStorage.getItem('parentOfficeID'));

    useEffect(() => {
        //console.log(officeID);
        customSendRequest(officeID);
        // setIsFirstTime(true);
    }, [customSendRequest])

    if (customStatus === 'pending') { //|| status2 === 'pending'
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });
        
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    if (error) {
        history.push('/Error')
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }

    const getDefaultCustomizeSettingHandler = (officeID) => {
        customSendRequest(officeID);
    }

    const addCustomizeHandler = (requestData) => {
        // console.log(requestData)
        sendRequest(requestData);
    };

    const errorHandler = () => {
        setError(null);
        // history.go('')
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    const customizeFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p>{t('CustomizeDetailsError')}</p> :
        <CustomizeForm loadedCustomizeDetails={loadedData}
            isLoading={status === 'pending'}
            onAddCustomize={addCustomizeHandler}
            updateForm={getDefaultCustomizeSettingHandler}
            duplicatePageNameErrorRefreshToken={duplicatePageNameErrorRefreshToken}
        />

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {customizeFormContent}
    </Fragment>
};

export default NewCustomize;
import { useEffect, useState, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
//import './styles.css'
import ConfirmationModal from './ConfirmationModal'
import { useHistory } from 'react-router-dom';
import AuthContext from './auth-context'
import moment from 'moment';
let timeout = 900_000//10_000 //900_000
const promptBeforeIdle = 300_000//4_000// 300_000


export default function useIdle() {
    const [state, setState] = useState('Active')
    const [remaining, setRemaining] = useState(timeout)
    const [open, setOpen] = useState(false)
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const onIdle = () => {
        setState('Idle')

    }

    const onActive = () => {
        setState('Active')

    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
    }

    const { getRemainingTime, activate, getTabId } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        promptBeforeIdle,
        throttle: 500,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus'
        ],
    })
    const tabId = getTabId() === null ? 'loading' : getTabId().toString()
    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)
      
        return () => {
            const today = moment(new Date());  
            localStorage.setItem("SessionLoginDateTime", JSON.stringify(today))
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
    
        setOpen(false);
        activate();
        timeout = 900_000;
        setRemaining(timeout);
      

    }

    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    function cancelHandler() {
        authCtx.logout();
        history.push('/Session');
    };
    useEffect(() => {
        if (remaining === 0) {
            setOpen(false);
            authCtx.logout();
            history.push('/Session');
        }
    }, [remaining])
    return (
        <>
            {open && (
                <ConfirmationModal
                    title="Session Alert"
                    message="Your session will end in 5 minutes due to inactivity. Do you want to extend the session?"
                    onOk={handleStillHere}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            {/*  {timeTillPrompt > 0 && (

                <p>
                    <p> Tab ID: {tabId}</p>
                    {timeTillPrompt} {seconds} until prompt
                </p>
            )}
            <p>Current State: {state}</p>

            <p>{remaining} seconds remaining</p> */}


        </>
    )
}

import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DialogItem from './DialogItem'
import { getConfigurationByBoolen } from '../../../../../utils/ConfigurationsFunction'

const DialogList = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const allowMultipleApprovers = useMemo(() => getConfigurationByBoolen("ALLOW_TO_SELECT_MULTIPLE_SAME_LEVEL_APPROVAL"), []);

    function handleAddApproverToSelectedList(approverUserID) {
        props.onAddApproverToSelectedList(approverUserID);
    }

    function handleDeleteApproverFromSelectedList(approverUserID) {
        props.onDeleteApproverFromSelectedList(approverUserID);
    }

    function handleAddToSelectedApprovalWorkflow(event) {
        event.preventDefault();
        props.onAddToSelectedApprovalWorkflow();
    }

    function handleSortChange(event) {
        props.onSortChange(event);
    }

    return <Fragment>

        <div className="container-fluid mb-3 list-view">
            {allowMultipleApprovers && props.selectedApprovers && props.selectedApprovers.length > 0 &&
                   
                    <div className="col-lg-12 mb-1">
                        <div className="row">
                            <div className="col-md-12">
                                <button id="btnAddSelectedApprovalWorkflow"
                                    type="button"
                                    onClick={handleAddToSelectedApprovalWorkflow}
                                    className="btn text-white gt-main-btn float-end btn-sm mb-2">
                                    <i class="fas fa-add"></i> {t('Add to Approval Workflow')}
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card text-dark card-bg-blue mb-2 col-md-12">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="card-title fw-bold text-primary mt-1">
                                                {props.selectedApprovers.map(approver =>
                                                    <span key={'SA' + approver.approvalUserID}
                                                        className='btn h6 text-white gt-yellow-btn ms-1 btn-sm'>
                                                        {approver.approvalUserName}
                                                        <span className="text-white"
                                                            onClick={() => { handleDeleteApproverFromSelectedList(approver.approvalUserID) }}>
                                                            <i class="fas fa-times ms-3"></i>
                                                        </span>
                                                    </span>
                                                )}
                                            </span>
                                        </div>

                                    </div>

                                </div>
                            </div>    
                        </div>
                    </div>
                
            }

            {props.filteredMasterApprovers && props.filteredMasterApprovers.length > 0 &&
                <Fragment>
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className='row mb-3'>
                                <div className="col-md-6" >

                                </div>
                                <div className='col-md-2 my-auto text-muted text-left'>
                                    <div className="float-lg-end float-md-end">Sort By</div>
                                </div>


                                <div className="col-md-4 form-group">

                                    <select id="sortBy" onChange={handleSortChange} className="form-control form-select bg_input">
                                        <option value="asc">{t('sortFirstNameAsc')}</option>
                                        <option value="desc">{t('sortFirstNameDsc')}</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.filteredMasterApprovers.map((approver) =>
                        <DialogItem
                            key={'MA' + approver.id}
                            userID={approver.id}
                            title={approver.title}
                            firstName={approver.firstName}
                            middleName={approver.middleName}
                            lastName={approver.lastName}
                            employeeCode={approver.employeeCode}
                            corporateMasterName={approver.corporateMasterName}
                            approvalTypeName={approver.approvalTypeName[0]}
                            onAddApproverToSelectedList={handleAddApproverToSelectedList}
                        />
                    )}
                </Fragment>
            }
        </div>
    </Fragment>
};

export default DialogList;
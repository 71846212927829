import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';
import { useHistory } from "react-router-dom";
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const FlightBookingItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    function openRetrieveBooking(id) {
        sendRequest(id);
    }

    useEffect(() => {
        if (status === 'completed') {
            let bookingJSON = [];


            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", props.reportJSON.status === 'Canceled' ? true : false);

                // history.push({
                //     pathname: '/RetrieveTrip',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])

    return <Fragment>

        <tr>
            <td align={'right'}>1</td>
            <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking("0002J8")}><div className='min-width-75'>0002J8 &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
            <td scope="row">08 Oct 2024 12:09 AM</td>
            <td scope="row">Microsoft</td>
            <td scope="row">Mr. Akhilesh Pathak</td>
            <td scope="row" align={'center'}>2303</td>
            <td scope="row" align={'left'}>Rebooking </td>
            <td scope="row">Rebooking required</td>
            <td scope="row">0002JC</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>
                120.00
            </td>
        </tr>
        <tr>
            <td align={'right'}>2</td>
            <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking("0002JD")}><div className='min-width-75'>0002JD &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
            <td scope="row">05 Oct 2024 9:09 AM</td>
            <td scope="row">Microsoft</td>
            <td scope="row">Mr. Akhilesh Pathak</td>
            <td scope="row" align={'center'}>2303</td>
            <td scope="row" align={'left'}>Cancellation</td>
            <td scope="row">Cancellation required</td>
            <td scope="row">0002JB</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>
                450.00
            </td>
        </tr>

    </Fragment>

};

export default FlightBookingItem;
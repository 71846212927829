import {
    SEARCH_POLICY, ADD_POLICY, GET_POLICY, DELETE_POLICY,
    UPDATE_POLICY,

} from "./CONSTANTS";
export async function searchPolicy(searchData) {
    const response = await fetch(SEARCH_POLICY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch policy');
    }
    return data;
}
export async function addPolicy(addData) {
    const response = await fetch(ADD_POLICY(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch policy.');
    }
    return data;
}
export async function updatePolicy(updateData) {
    const response = await fetch(UPDATE_POLICY(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update policy.');
    }
    return data;
}
export async function getSinglePolicy(ID) {
    const response = await fetch(GET_POLICY(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch policy .');
    }

    return data;
}
export async function deleteSinglePolicy(deleteData) {
    const response = await fetch(DELETE_POLICY(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete policy.');
    }
    return data;
}
import { Fragment } from 'react';
import { removeHtmlTags } from '../../../utils/CommonFunction';

const hotelBookingJSON = (props) => {

    function checkNewlinePresence(str) {
        return str.includes('\n');
    }
    let show;
    if (props.hotelBookingJSON.data.hotelResults[0].hotelDescription !== undefined) {
        // Remove '\n' from the string
        const removedNewlines = props.hotelBookingJSON.data.hotelResults[0].hotelDescription.replace(/\n/g, ' ');
        // Split the string based on full stops
        //const sentences = removedNewlines.split(/(?<!sq|ft|0)\.(?![a-z]| ft)/i);
        const sentences = removedNewlines.split(/(?<!sq|ft|0)\.(?![a-z]| ft(?= [a-z]))/i);
        if (sentences.length !== 0) {
            let desc = sentences.map((item, index) => {

                if (item.trim() !== '') {
                    const sentenceWithFullStop = index !== sentences.length - 1 ? item + '.' : item;
                    if (index === sentences.length - 1) {
                        return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}</li>;
                    }
                    return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}<br /><br /></li>;
                }

            });

            show = <ul>{desc}</ul>;
        }
        else {

            show = removeHtmlTags(props.hotelBookingJSON.data.hotelResults[0].hotelDescription);
        }
    }
    return <Fragment>
        <div className="sort-view clearfix">
        </div>
        <div className="hotel-list-cn clearfix shadow-none">
            <div className="hotel-list-item position-relative">
                <div className="hotel-text m-0 pt-3">
                    <div className="mb-2">
                        <a title="" className='text-primary fw-bold h3'>{props.hotelBookingJSON.data.hotelResults[0].hotelName}
                        </a>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='text-16px my-3'>{props.hotelBookingJSON.data.hotelResults[0].address.addressLine + ", " + props.hotelBookingJSON.data.hotelResults[0].address.city.cityName + ", " + props.hotelBookingJSON.data.hotelResults[0].address.country.countryName}</div>
                                {/* <div className='text-16px my-3'>{props.hotelBookingJSON.data.hotelResults[0].address.addressLine + ", " + props.hotelBookingJSON.data.hotelResults[0].address.city.cityName + ", " + props.hotelBookingJSON.data.hotelResults[0].address.country.countryName}</div> */}
                                {/* <div className='text-16px my-3'>{props.hotelBookingJSON.data.hotelResults[0].address.addressLine + ", " + props.hotelBookingJSON.data.hotelResults[0].address.city.cityName + ", " + props.hotelBookingJSON.data.hotelResults[0].address.country.countryName}</div> */}
                                {/* <div className='text-16px my-3'>{props.hotelBookingJSON.data.hotelResults[0].address.addressLine + ", " + props.hotelBookingJSON.data.hotelResults[0].address.city.cityName + ", " + props.hotelBookingJSON.data.hotelResults[0].address.country.countryName}</div> */}



                            </div>
                            {/* <div className="col-md-12 mb-2"><span className="text-muted">Room Type:</span><span className="text-primary fw-bold ms-1">Deluxe</span></div> */}
                            <div className='col-md-12 text-16px display-line'>
                                {/* {props.hotelBookingJSON.data.hotelResults[0].hotelDescription !== undefined && checkNewlinePresence(props.hotelBookingJSON.data.hotelResults[0].hotelDescription) === true && removeHtmlTags(props.hotelBookingJSON.data.hotelResults[0].hotelDescription)}
                                {checkNewlinePresence(props.hotelBookingJSON.data.hotelResults[0].hotelDescription) === false && show} */}
                                {show}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='my-1'></hr>
                <div className='row'>
                    <div className='col-md-12 my-auto'>
                        <div className='mb-2'>
                            {props.hotelBookingJSON.data.hotelResults[0].roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true' ? <div className="ms-2 text-14px text-danger mt-2"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="ms-2 text-14px text-sky-blue mt-2"><i className="fas fa-money-check-alt"></i>  Refundable</div>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Fragment>

};

export default hotelBookingJSON;
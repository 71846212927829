import { SEARCH_VISA } from "./CONSTANTS";
export async function searchVisa(searchData) {
    const response = await fetch(SEARCH_VISA(), {
        method: "POST",
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = response.json();
    if (!response.ok) {

        throw new Error(data.message || 'could not fetch visa');
    }

    return data;

}
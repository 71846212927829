import { Fragment, useState, useRef, useEffect } from 'react';
import Dropdown from "react-select";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';


const serviceOptions = [
    { value: '1', label: 'Hidden' },
    { value: '2', label: 'Mandatory' },
    { value: '3', label: 'Optional' }
];
const TripReasonForm = (props) => {
    const [pageTitle, setPageTitle] = useState(" New Trip Type");
    const { t } = useTranslation(['translation.TripReason']);

    const nameInputRef = useRef('');
    const codeInputRef = useRef('');
    const [meetingIdValue, setMeetingIdValue] = useState('');
    const [isFamilyBooking, SetIsFamilyBooking] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const history = useHistory();

    //Error State
    const [nameInputHasError, setNameInputHasError] = useState(false);
    const [nameInvalidInput, setNameInvalidInput] = useState(false);
    const [codeInputHasError, setcodeInputHasError] = useState(false);
    const [CodeInvalidInput, setCodeInvalidInput] = useState(false);
    const [meetingInputHasError, setmeetingInputHasError] = useState(false);



    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
        if (props.loadedTripReasonDetails.length !== 0) {
            nameInputRef.current.value = props.loadedTripReasonDetails.name !== undefined ? props.loadedTripReasonDetails.name : "";
            codeInputRef.current.value = props.loadedTripReasonDetails.code !== undefined ? props.loadedTripReasonDetails.code : "";
            if (props.loadedTripReasonDetails.meetingID !== undefined) {
                setMeetingIdValue(props.loadedTripReasonDetails.meetingID)
            }
            if (props.loadedTripReasonDetails.isFamilyBooking !== undefined) {
                SetIsFamilyBooking(props.loadedTripReasonDetails.isFamilyBooking)
            }



        }
        if (props.loadedTripReasonDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            setPageTitle(" View Trip Type ")
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("name").readOnly = true;
            document.getElementById("code").readOnly = true;
            document.querySelector("#default").disabled = true;
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
            setIsDisable(true);

        }
        else if (isEditMode === 1) {
            setPageTitle(" Edit Trip Type ")
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementById("name").readOnly = false;
            document.getElementById("code").readOnly = false;
            document.getElementById("name").focus();
            document.getElementById("requiredIndicator").style.display = "";
            setIsDisable(false);

        }

        if (props.loadedTripReasonDetails.length === 0) {
            setPageTitle(" New Trip Type ")
            document.getElementById("btnNew").style.display = "none";
            sessionStorage.setItem('isEditable', '100');


        }

    }, [props.loadedTripReasonDetails.length, props.loadedTripReasonDetails.code, props.loadedTripReasonDetails.name,
    props.loadedTripReasonDetails.meetingID, props.loadedTripReasonDetails.isFamilyBooking]);
    //useEffect(() => {
    //    if (props.loadedTripReasonDetails.length === 0) {
    //        document.getElementById("btnSave").click();
    //    }
    //}, [props.loadedTripReasonDetails.length]);


    const familyBookingChangeHandler = () => {
        SetIsFamilyBooking(!isFamilyBooking)
    }
    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }
    const meetingChangeHandler = (event) => {
        setMeetingIdValue(event.value);
        setmeetingInputHasError(false);

    }

    function handleNameOnBlur(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value.trim();
        if (enteredName.length === 0) {
            setNameInputHasError(true);
            setNameInvalidInput(false);
            return;
        } else {
            setNameInputHasError(false);
        }
        if (!enteredName.match('^[a-zA-Z0-9.,() :-]+$')) {
            setNameInputHasError(false);
            setNameInvalidInput(true);
            return;
        } else {
            setNameInvalidInput(false);
        }
    }

    function handleCodeOnBlur(event) {
        event.preventDefault();
        const enteredCode = codeInputRef.current.value.trim();
        if (enteredCode.length !== 0) {
            if (!enteredCode.match('^[a-zA-Z0-9]+$')) {
                setCodeInvalidInput(true);
                return;
            } else {
                setCodeInvalidInput(false);
            }
        }
        else {
            setCodeInvalidInput(false);
        }
    }

    function handleMeetingDetailsOnBlur(event) {
        event.preventDefault();
        if (meetingIdValue.length === 0) {
            setmeetingInputHasError(true);
            return;
        } else {
            setmeetingInputHasError(false);
        }
    }
        
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value.trim();
        const enteredCode = codeInputRef.current.value.trim();
        if (enteredName.length === 0) {
            nameInputRef.current.focus()
            setNameInputHasError(true);
            setNameInvalidInput(false);
            return;
        } else {
            setNameInputHasError(false);
        }
        if (!enteredName.match('^[a-zA-Z0-9.,() :-]+$')) {
            nameInputRef.current.focus()
            setNameInputHasError(false);
            setNameInvalidInput(true);
            return;
        } else {
            setNameInvalidInput(false);
        }
        if (enteredCode.length !== 0) {
            if (!enteredCode.match('^[a-zA-Z0-9]+$')) {
                codeInputRef.current.focus()
                setCodeInvalidInput(true);
                return;
            } else {
                setCodeInvalidInput(false);
            }
        }

        if (meetingIdValue.length == 0) {
            setmeetingInputHasError(true);
            return;
        } else {
            setmeetingInputHasError(false);
        }


        let ID = '';
        if (props.loadedTripReasonDetails.length !== 0) {
            ID = props.loadedTripReasonDetails.id
        }
        const tripReasonRequest = ({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            name: nameInputRef.current.value.trim(),
            code: codeInputRef.current.value.trim(),
            meetingID: meetingIdValue,
            isFamilyBooking: isFamilyBooking,
            officeID: JSON.parse(localStorage.getItem("officeID")),


        });
        console.log(tripReasonRequest)
        props.onAdTripReason(
            tripReasonRequest
        );

    }
    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdateTripReason')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedTripReasonDetails.length === 0) {
                history.go('/UpdateTripReason')
            }
            else {
                history.go(`/UpdateTripReason/${props.loadedTripReasonDetails.id}`)
            }
        }
    }
    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {props.isLoading && (
                                        <div className='loading'>
                                            <LoadingSpinner />
                                        </div>
                                    )}

                                    <div className="card-body">
                                        <div className="col-md-12 ">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                                <form className='form'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='control'>
                                                                <div className="form-group">
                                                                    <label htmlFor='name' className='h6 text-primary mb-2'>Name <span class="error-icon"> *</span></label>
                                                                    <input type="text"
                                                                        className="form-control bg_input"
                                                                        maxLength={50} ref={nameInputRef}
                                                                        id='name'
                                                                        onBlur={handleNameOnBlur} />
                                                                    {nameInputHasError && <p className="error-text">{t('NameValidation')}</p>}
                                                                    {nameInvalidInput && <p className="error-text">{t('nameInputValidation')}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='control'>
                                                                <div className="form-group">
                                                                    <label htmlFor='code' className='h6 text-primary mb-2'>Code </label>
                                                                    <input type="text"
                                                                        className="form-control bg_input"
                                                                        maxLength={2} onInput={changeToUpperCase}
                                                                        ref={codeInputRef} id='code'
                                                                        onBlur={handleCodeOnBlur} />
                                                                    {CodeInvalidInput && <p className="error-text">{t('codeInputValidation')}</p>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'>Meeting Details <span class="error-icon"> *</span></label>
                                                            <Dropdown
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                placeholder="Please Select"
                                                                options={serviceOptions}
                                                                onChange={meetingChangeHandler}
                                                                value={serviceOptions.filter(option => option.value === meetingIdValue)}
                                                                isDisabled={isDisable}
                                                                onBlur={handleMeetingDetailsOnBlur}
                                                            />
                                                            {meetingInputHasError && <p className="error-text">{t('meetingValidation')}</p>}

                                                        </div>
                                                    </div>
                                                    <div className='control'>
                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                            <label htmlFor="default" className="mb-2 h6 text-primary">   {t('Allow Personal Booking')}</label>
                                                            <input id="default" className="form-check-input ms-3" type="checkbox" onChange={familyBookingChangeHandler} checked={isFamilyBooking} />
                                                        </div>
                                                    </div>
                                                    <p className="mt-2"><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                    <div className='mt-3 actions row'>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                            <a href="/SearchTripReason" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9">
                                                            <div className="float-md-end float-lg-end">
                                                                <a href="/SearchTripReason" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                {(checkSecurityDetails("Master", "TripReason", "Master_TripReason_Insert")
                                                                    || checkSecurityDetails("Master", "TripReason", "Master_TripReason_Update")

                                                                ) ?
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                        {t('save')}
                                                                    </button>
                                                                    : <span id="btnSave"></span>
                                                                }
                                                                {checkSecurityDetails("Master", "TripReason", "Master_TripReason_Insert")
                                                                    ?
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                        {t('new')}
                                                                    </button>
                                                                    : <span id="btnNew"></span>}
                                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                    {t('reset')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




    </Fragment>
}

export default TripReasonForm;
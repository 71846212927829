import { SEARCH_TRIP_REASON, ADD_TRIP_REASON, UPDATE_TRIP_REASON,GET_TRIP_REASON,DELETE_TIP_REASON } from "./CONSTANTS";
export async function searchTripReason(searchData) {    
    const response = await fetch(SEARCH_TRIP_REASON(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
        },

    });    
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Trip Reason');
    }
    return data;
}
export async function addTripReason(addData) {
    const response = await fetch(ADD_TRIP_REASON(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create Trip Reason.');
    }
    return data;
}
export async function updateTripReason(updateData) {
    const response = await fetch(UPDATE_TRIP_REASON(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update Trip Reason.');
    }
    return data;
}
export async function getSingleTripReason(ID) {
    const response = await fetch(GET_TRIP_REASON(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Trip Reason .');
    }

    return data;
}
export async function deleteSingleTripReason(deleteData) {
    const response = await fetch(DELETE_TIP_REASON(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete Trip Reason.');
    }
    return data;
}
import { Fragment, useState, useRef, useEffect } from "react";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import Dropdown from "react-select";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { useHistory } from "react-router-dom";
const pageNameOptions = [
    { value: "", label: "Please Select" },
    { value: "1", label: "Terms and Conditions" },
    ,
]
const StaticPageForm = (props) => {
    const { t } = useTranslation(['translation.StaticPage']);
    const [pageTitle, setPageTitle] = useState(" Page Builder");
    const pageTitleInputRef = useRef('');
    const activeInputRef = useRef('');
    const [pageContent, setPageContent] = useState('');
    const [active, setActive] = useState(true);
    const [activeUserAction, setActiveUserAction] = useState(false);
    const [pageName, setPageName] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const history = useHistory();

    //Error State
    const [pageNameInputHasError, setPageNameInputHasError] = useState(false);
    const [pageTitleInputHasError, setPageTitleInputHasError] = useState(false);
    const [pageTitleInvalidInput, setPageTitleInvalidInput] = useState(false);
    const [pageContentInputHasError, setPageContentInputHasError] = useState(false);
    const modules = {
        toolbar: [
            [{ font: [] }], // Font family
            [{ size: ['small', false, 'large', 'huge'] }], // Font sizes
            ['bold', 'italic', 'underline', 'strike'], // Text styling
            [{ color: [] }, { background: [] }], // Text and background colors
            [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript
            [{ header: 1 }, { header: 2 }], // Headers 1 and 2
            [{ list: 'ordered' }, { list: 'bullet' }], // Lists
            [{ indent: '-1' }, { indent: '+1' }], // Indentation
            [{ align: [] }], // Text alignment
            // ['blockquote', 'code-block'], // Blockquote and code block
            ['code-block'], // Blockquote and code block
            ['link',], // Media links
            ['clean'], // Clear formatting
        ],
    };





    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
        if (props.loadedStaticPageDetails.length !== 0) {
            pageTitleInputRef.current.value = props.loadedStaticPageDetails[0].pageTitle !== undefined ? props.loadedStaticPageDetails[0].pageTitle : "";

            if (props.loadedStaticPageDetails[0].pageID !== undefined) {
                setPageName(props.loadedStaticPageDetails[0].pageID);
            }
            if (props.loadedStaticPageDetails[0].content !== undefined) {
                setPageContent(props.loadedStaticPageDetails[0].content);
            }
            if (props.loadedStaticPageDetails[0].isActive !== undefined) {
                setActive(props.loadedStaticPageDetails[0].isActive);
            }
        }
        if (props.loadedStaticPageDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            //view Mode
            setPageTitle(" View Page Builder ")
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            setDisabled(true);
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";

        }
        else if (isEditMode === 1) {
            //edit  Mode
            setPageTitle(" Edit Page Builder ")
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setDisabled(false);
            if (document.getElementById("pageTitleId") !== null) {
                document.getElementById("pageTitleId").readOnly = false;
            }
            document.getElementById("requiredIndicator").style.display = "";

        }

        if (props.loadedStaticPageDetails.length === 0) {
            setPageTitle(" New Page Builder ")
            document.getElementById("btnNew").style.display = "none";
            sessionStorage.setItem('isEditable', '100');
        }

    }, [props.loadedStaticPageDetails.length, props.loadedStaticPageDetails.pageTitle,]);
    useEffect(() => {
        if (props.loadedStaticPageDetails.length === 0) {
            document.getElementById("btnSave").click();
        }
    }, [props.loadedStaticPageDetails.length]);
    function containsOnlyHTMLTags(input) {
        const regex = /^(<\/?[\w\s="'-:;]+>)+$/;
        return regex.test(input.trim());
    }

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }


    const pageContentChangeHandler = (e) => {
        console.log(e)
        let tempPageContent = e.trim();
        setPageContent(tempPageContent);
        // tempPageContent = (tempPageContent === '<p><br></p>' || tempPageContent === '<p></p>' || tempPageContent === '<p><br></p><p><br></p>') ? '' : tempPageContent;
        if (!containsOnlyHTMLTags(tempPageContent) && removeTags(tempPageContent).trim() !== '') {
            setPageContentInputHasError(false);

        } else if (!pageTitleInputHasError && !pageTitleInvalidInput) {
            setPageContentInputHasError(true);
            return;
        }


    };
    const activeChangeHandler = () => {
        setActiveUserAction(true);
        if (document.querySelector('#active').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }
    const pageNameChangeHandler = (event) => {
        setPageName(event.value);
        if (event.value !== '') {
            setPageNameInputHasError(false);
        }

    }
    const onBlurCheck = (event) => {
        event.preventDefault();
        const enteredPageTitle = pageTitleInputRef.current.value.trim()
        if (pageName === '') {
            setPageNameInputHasError(true);
            setPageContentInputHasError(false);
            return;
        } else {
            setPageNameInputHasError(false);
        }
        if (enteredPageTitle === '') {
            setPageTitleInputHasError(true);
            setPageTitleInvalidInput(false);
            setPageContentInputHasError(false);
            return;
        } else {
            setPageTitleInputHasError(false);
        }
        if (!enteredPageTitle.match('^[a-zA-Z ]+$')) {
            setPageTitleInvalidInput(true);
            setPageTitleInputHasError(false);
            setPageContentInputHasError(false);
            return;
        } else {
            setPageTitleInvalidInput(false);
        }

    };
    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdatePageBuilder')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedStaticPageDetails.length === 0) {
                history.go('/UpdatePageBuilder')
            }
            else {
                history.go(`/UpdatePageBuilder/${props.loadedStaticPageDetails.id}`)
            }
        }
    }
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredPageTitle = pageTitleInputRef.current.value.trim()

        if (pageName === '') {
            setPageNameInputHasError(true);
            window.scrollTo({
                top: 10,
                behavior: "smooth",
            });
            return;
        } else {
            setPageNameInputHasError(false);
        }
        if (enteredPageTitle === '') {
            setPageTitleInputHasError(true);
            pageTitleInputRef.current.focus();
            return;
        } else {
            setPageTitleInputHasError(false);
        }
        if (!enteredPageTitle.match('^[a-zA-Z ]+$')) {
            setPageTitleInvalidInput(true);
            pageTitleInputRef.current.focus();
            return;
        } else {
            setPageTitleInvalidInput(false);
        }

        if (pageContent.trim() === '') {
            setPageContentInputHasError(true);
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
            return;
        } else {
            setPageContentInputHasError(false);
        }
        if (!containsOnlyHTMLTags(pageContent)) {
            setPageContentInputHasError(false);
        } else {
            setPageContentInputHasError(true);
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
            return;
        }
        if (removeTags(pageContent).trim() === '') {
            setPageContentInputHasError(true);
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
            return
        } else {
            setPageContentInputHasError(false);
        }
        let ID = '';
        if (props.loadedStaticPageDetails.length !== 0) {
            ID = props.loadedStaticPageDetails.id
        }
        props.onAddStaticPage(
            {
                id: ID,
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: JSON.parse(localStorage.getItem('userID')),
                iPAddress: localStorage.getItem("IPAddress"),
                content: pageContent,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                pageID: pageName,
                pageTitle: enteredPageTitle,
                isActive: active
            }
        )
    };
    return (<Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>

                                                    <form className='form'>

                                                        <div className="col-md-12 mb-3">
                                                            <label htmlFor="pageName" className="mb-2 h6 text-primary col-lg-12">{t('pageName')}<span className="error-icon"> *</span></label>
                                                            <Dropdown onChange={pageNameChangeHandler}
                                                                value={pageNameOptions.filter(option => option.value === pageName)}
                                                                options={pageNameOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                isDisabled={isDisabled}
                                                                onBlur={onBlurCheck}
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            />
                                                            {pageNameInputHasError && <p className="error-text">{t('pageNameValidation')}</p>}
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <label htmlFor="pageTitleId" className="mb-2 h6 text-primary col-lg-12"> {t('pageTitle')}<span className="error-icon"> *</span></label>
                                                            <input id="pageTitleID"
                                                                ref={pageTitleInputRef}
                                                                type='text'
                                                                minLength="1"
                                                                maxLength="100"
                                                                onBlur={onBlurCheck}
                                                                readOnly={isDisabled}
                                                                className="form-control form-control-sm"
                                                            >
                                                            </input>
                                                            {pageTitleInputHasError && <p className="error-text">{t('pageTitleValidation')}</p>}
                                                            {pageTitleInvalidInput && <p className="error-text">{t('pageTitleInputValidation')}</p>}
                                                        </div>
                                                        <div className='row mb-4'>
                                                            <div className='col-md-12  mb-10px'>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor="pageContent">{t('pageContent')}<span className="error-icon"> *</span></label>
                                                                <div className=" pb-5 col-md-12 mb-4 position-relative">

                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        value={pageContent}
                                                                        onChange={pageContentChangeHandler}
                                                                        maxLength={3000}
                                                                        modules={modules}
                                                                        style={{ height: 200 }}
                                                                        readOnly={isDisabled}
                                                                    />

                                                                </div>
                                                                {pageContentInputHasError && <p className="error-text  margin-top-45 ">{t('pageContentValidation')}</p>}
                                                            </div>

                                                        </div>
                                                        <div className=" mt-2 ">
                                                            <div className='control  '>
                                                                <div className="form-switch form-switch-sm padding-left-0 margin-top-15">
                                                                    <label htmlFor="active" className="mb-2 h6 text-primary">   {t('active')}</label>
                                                                    <input id="active" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} value={active} ref={activeInputRef} disabled={isDisabled} />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/SearchPageBuilder" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchPageBuilder" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                    {(checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Insert") || checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                            {t('save')}
                                                                        </button>
                                                                        : <span id="btnSave"></span>
                                                                    }
                                                                    {checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button>
                                                                        : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>);

}

export default StaticPageForm;
import { Fragment } from 'react';
import { getFormatDate, timeConvert, getDateDiffDays, getFormatTime } from '../../../../utils/CommonFunction'
const Booking = (props) => {

    return <Fragment>
        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                Booking
            </h5>
            <div id="collapsesix" className="accordion-collapse collapse show">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4"><div className='h6 mb-2 text-primary'>Check-in</div> </div>
                            <div className="col-lg-9 col-md-9  col-sm-8 col-8 "><div className='h6 mb-2'>{getFormatDate(props.hotelBookingJSON.data.onlineHotelDetails[0].checkInDate)}{props.hotelBookingJSON.data.onlineHotelDetails[0].checkInTime !== undefined ? ", " + getFormatTime(props.hotelBookingJSON.data.onlineHotelDetails[0].checkInTime) : ''} </div> </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 text-primary'> Check-out</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>{getFormatDate(props.hotelBookingJSON.data.onlineHotelDetails[0].checkOutDate)}{props.hotelBookingJSON.data.onlineHotelDetails[0].checkOutTime !== undefined ? ", " + getFormatTime(props.hotelBookingJSON.data.onlineHotelDetails[0].checkOutTime) : ''} </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 text-primary'>Total length of stay</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>{getDateDiffDays(props.hotelBookingJSON.data.onlineHotelDetails[0].checkInDate, props.hotelBookingJSON.data.onlineHotelDetails[0].checkOutDate)} {getDateDiffDays(props.hotelBookingJSON.data.onlineHotelDetails[0].checkInDate, props.hotelBookingJSON.data.onlineHotelDetails[0].checkOutDate) > 1 ? 'nights' : 'night'} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>

};

export default Booking;
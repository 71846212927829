import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
const DelegatedApproverListTable = (props) => {
  const { t } = useTranslation(["translation.Delegate"]);
  const { delegateApprover, setDelegateApprover } = props;
  const [confirm, setConfirm] = useState();
  const onConfirm = (event) => {
    event.preventDefault();
    setConfirm(true);
  };
  function okHandler(event) {
    event.preventDefault();
    setDelegateApprover(null);
    setConfirm(false);
  }
  function cancelHandler() {
    setConfirm(false);
  }
  return (
    <Fragment>
      {confirm && (
        <ConfirmationModal
          title={t("confirmationMessageTitle")}
          message={t("confirmationMessage")}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}
      <div className="row position-relative">
        <div className="col-lg-12">
          <div className="tour_search_form">
            <form action="!#">
              <div className="row mt-3">
                {delegateApprover && (
                  <div className="col-md-12 mb-4">
                    <div className="card text-dark card-bg-blue mb-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            {delegateApprover.delegateUserName && (
                              <h6 className="card-title fw-bold text-primary mt-1">
                                {delegateApprover.delegateUserName}
                              </h6>
                            )}
                            {delegateApprover.title &&
                              delegateApprover.firstName &&
                              delegateApprover.lastName && (
                                <h6 className="card-title fw-bold text-primary mt-1">
                                  {`${delegateApprover.title} ${delegateApprover.firstName} ${delegateApprover.lastName}`}
                                </h6>
                              )}
                          </div>
                        </div>
                        <div className="row">
                          <hr className="my-1" />
                          <div className="col-md-8">
                            <p className="card-text mb-0">
                              <span>
                                <strong>{t("department")} </strong>
                                {delegateApprover.corporateMasterName && (
                                  <h6>
                                    {delegateApprover.corporateMasterName}
                                  </h6>
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-4 my-auto">
                            <div className="float-lg-end float-md-end">
                              {props.approvalMasterID !== null && (
                                <button
                                  id={"delete" + props.corporateMasterID}
                                  title="Delete"
                                  onClick={(event) => {
                                    onConfirm(event);
                                  }}
                                  className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DelegatedApproverListTable;

import { Fragment, useEffect, useCallback, useState } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import { NavLink } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { IMPORTPNR } from '../../../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import { getGdsRetrieveTrip } from '../../../services/Booking-API';
import useHttp from '../../../services/use-http';
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { CreateImportAirItineraryBookingJSON } from '../../../utils/BookingFunction';
import { v4 as uuidv4 } from 'uuid';
import { checkSecurityDetails } from '../../../utils/RoleUtils';

const SearchPNR = () => {
    useDocumentTitle('Search PNR');
    const [sourcePNRInputHasError, setSourcePNRInputHasError] = useState(false);
    const [sourcePNRInvalidInputError, setSourcePNRInvalidInputError] = useState(false);
    const [isFirtTime, setFirstTime] = useState("");
    const history = useHistory();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const { t } = useTranslation(['translation.CreditLimit']);

    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getGdsRetrieveTrip);
    const [error1, setError] = useState();

    function onChangeGetPnr(option) {
        if (option === 'manual') {
            document.querySelector('#divSourcePNR').classList.add('d-none');
            document.querySelector('#sourcePNRErrorIcon').classList.add("d-none");
            setSourcePNRInputHasError(false);
            document.querySelector('#requiredIndicatorIcon').classList.add("d-none");
            document.querySelector('#requiredIndicatorText').classList.add("d-none");
        }
        else {
            document.querySelector('#divSourcePNR').classList.remove('d-none');
            document.querySelector('#sourcePNRErrorIcon').classList.remove("d-none");
            setSourcePNRInputHasError(false);
            document.querySelector('#requiredIndicatorIcon').classList.remove("d-none");
            document.querySelector('#requiredIndicatorText').classList.remove("d-none");
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
        if (document.querySelector('#importPNR') !== null) {
            document.querySelector('#importPNR').checked = true;
        } else {
            if (document.querySelector('#manualPNR') !== null) {
                document.querySelector('#manualPNR').checked = true;
            }

        }

        sessionStorage.removeItem("sourcePNR");
        localStorage.removeItem("AddHotel");
        sessionStorage.removeItem("AddHotelCheck");
        sessionStorage.removeItem("finalTotalFare");
    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (document.querySelector('#importPNR') !== null && document.querySelector('#importPNR').checked === true) {
                if (loadedRetrieveTripDetails !== null && loadedRetrieveTripDetails !== '') {
                    if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                        if (loadedRetrieveTripDetails.data.airItinerary !== undefined && loadedRetrieveTripDetails.data.airItinerary[0] !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption[0] !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0] !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].status !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].status === 'YK') {
                            setError({
                                title: "Message",
                                message: 'This PNR cannot be retrieved since mandatory information, such as pricing, is missing.',
                            });
                            return;
                        }
                        else {
                            sessionStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                            history.push(`/ImportPNR?pnr=I`)
                            history.go();
                        }
                    }
                    //user friendly message for View Sabre PNR-if segment is not found
                    else if (loadedRetrieveTripDetails.data.errors.status === 'TRUE' && loadedRetrieveTripDetails.data.errors.error.description === 'As per Sabre, this PNR does not contain any flights.') {
                        if (loadedRetrieveTripDetails.data.airItinerary === undefined) {
                            loadedRetrieveTripDetails.data.airItinerary = CreateImportAirItineraryBookingJSON();
                        }
                        loadedRetrieveTripDetails.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                        loadedRetrieveTripDetails.data.UserID = JSON.parse(localStorage.getItem('userID'));
                        sessionStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                        history.push(`/ImportPNR?pnr=I`)
                        history.go();
                    }
                    else {
                        setError({
                            title: "Message",
                            message: loadedRetrieveTripDetails.data.errors.error.description,
                        });
                        return;
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: 'Some thing went wrong.',
                    });
                    return;
                }
            }
        }
    }, [status, loadedRetrieveTripDetails])

    const onResetHandler = (event) => {
        history.push('/SearchPNR');
        history.go();
    }

    const onSearchHandler = useCallback((event) => {
        localStorage.setItem('traceID', JSON.stringify(uuidv4()));
        event.preventDefault();
        setFirstTime(true);

        if (document.querySelector('#importPNR') !== null && document.querySelector('#importPNR').checked === true) {
            document.querySelector('#sourcePNRErrorIcon').classList.remove("d-none");
            if (document.querySelector('#sourcePNR').value === '') {
                setSourcePNRInputHasError(true);
                return;
            }
            else {
                setSourcePNRInputHasError(false);
            }

            if (document.querySelector('#sourcePNR').value !== '' && (document.querySelector('#sourcePNR').value.length < 6 || !document.querySelector('#sourcePNR').value.match('^[a-zA-Z0-9]+$'))) {
                setSourcePNRInvalidInputError(true);
                return;
            }
            else {
                setSourcePNRInvalidInputError(false);
            }

            setIsSubmitClicked(true);
            sessionStorage.removeItem("BookingData");
            sessionStorage.removeItem("FlightIntialTotalAmountImport");
            sessionStorage.setItem("sourcePNR", document.querySelector('#sourcePNR').value !== '' ? document.querySelector('#sourcePNR').value.toUpperCase() : '');

            sendRequest(
                {
                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "data": {
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),
                        "UserID": JSON.parse(localStorage.getItem('userID')),
                        "bookingReferenceID": {
                            "type": "PNR",
                            "id": document.querySelector('#sourcePNR').value !== '' ? document.querySelector('#sourcePNR').value.toUpperCase() : ''
                        }
                    }
                }
            );
        }
        else {
            // document.querySelector('#sourcePNRErrorIcon').classList.remove("d-none");
            // if (document.querySelector('#sourcePNR').value === '') {
            //     setSourcePNRInputHasError(true);
            //     return;
            // }
            // else {
            //     setSourcePNRInputHasError(false);
            // }

            // if (document.querySelector('#sourcePNR').value !== '' && !document.querySelector('#sourcePNR').value.match('^[a-zA-Z0-9]+$')) {
            //     setSourcePNRInvalidInputError(true);
            //     return;
            // }
            // else {
            //     setSourcePNRInvalidInputError(false);
            // }

            // document.querySelector('#sourcePNRErrorIcon').classList.add("d-none");
            // setSourcePNRInputHasError(false);
            // if (document.querySelector('#sourcePNR').value !== '' && !document.querySelector('#sourcePNR').value.match('^[a-zA-Z0-9]+$')) {
            //     setSourcePNRInvalidInputError(true);
            //     return;
            // }
            // else {
            //     setSourcePNRInvalidInputError(false);
            // }

            setIsSubmitClicked(true);
            sessionStorage.removeItem("BookingData");
            sessionStorage.removeItem("FlightIntialTotalAmountImport");
            sessionStorage.setItem("sourcePNR", '');

            history.push('/ImportPNR?pnr=M');
            history.go();
        }

    }, []);

    useEffect((sendRequest) => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    function okayHandler() {
        setError(false);
        //history.go('/SearchPNR');
    }

    return (
        <Fragment>
            {error1 && <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Import Flight</label>
                                            <form>
                                                <div className='row mt-3'>
                                                    {(checkSecurityDetails("Book", "ImportPNR", "Book_ImportPNR_Retrieve PNR from GDS")) &&
                                                        <div className='col-lg-4'>
                                                            <div className="form-check">
                                                                <input type="radio" name='getPnr' className="form-check-input" id="importPNR" onClick={() => onChangeGetPnr('import')} />
                                                                <label htmlFor="activeUser" className="form-check-label mb-2 h6 text-primary ">Retrieve PNR from GDS</label>
                                                            </div>
                                                        </div>}
                                                    {(checkSecurityDetails("Settings", "ImportPNR", "Book_ImportPNR_Add non-GDS booking")) &&
                                                        < div className='col-lg-4'>
                                                            <div className="form-check">
                                                                <input type="radio" name='getPnr' className="form-check-input" id="manualPNR" onClick={() => onChangeGetPnr('manual')} />
                                                                <label htmlFor="activeUser" className="form-check-label mb-2 h6 text-primary ">Add non-GDS booking</label>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(checkSecurityDetails("Book", "ImportPNR", "Book_ImportPNR_Retrieve PNR from GDS")) && <div className='col-lg-12 my-2'>
                                                        <div className='control' id='divSourcePNR'>
                                                            <label className="mb-2 h6 text-primary col-lg-12" htmlFor='sourcePNR'>Sabre PNR<span id='sourcePNRErrorIcon' className="error-icon"> *</span></label>
                                                            <input maxLength="6" type='text' className="form-control form-control-sm" id='sourcePNR' />
                                                            {sourcePNRInputHasError && <p className="error-text">{'Sabre PNR is required.'}</p>}
                                                            {sourcePNRInvalidInputError && <p className="error-text">{'Sabre PNR is not valid.'}</p>}
                                                        </div>
                                                    </div>}
                                                </div>
                                                <div className='row'>
                                                    {(checkSecurityDetails("Book", "ImportPNR", "Book_ImportPNR_Retrieve PNR from GDS")) && <div className=" col-md-12"><span id="requiredIndicatorIcon" className="error-icon">*</span><span id="requiredIndicatorText">Indicates required fields.</span></div>}
                                                    <div className='mt-3 col-md-12 actions'>
                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                            Reset
                                                        </button>
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section >
        </Fragment >)
};

export default SearchPNR

import { useState, useEffect } from 'react';
import Dropdown from 'react-select';
import { CustomToFixed, calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

const AddMarkup = (props) => {
    const [markupLabelFlight, setMarkupLabelFlight] = useState('');
    const [markupLabelHotel, setMarkupLabelHotel] = useState('');
    const [intialBookingJSON, setIntialBookingJSON] = useState([]);

    const prefernce_list = [
        { value: '1', label: 'Amount' },
        { value: '2', label: 'Percentage' }
    ];

    function assignMarkup() {
        let calculatedAmountFlight = 0;
        let calculatedAmountHotel = 0;
        let serviceFeeSinglePnrToatl = 0;

        var totalFareAmount = 0;
        var totalRateAmount = 0;
        let fareSinglePnrToatl = 0;

        let totalEqPrice = 0;

        let serviceFeeSinglePnrCurrencyCode = 'USD';

        if (document.querySelector('#markupFlight').value !== "" && markupLabelFlight === '') {
            document.querySelector('#markupPrefernceFlight').innerHTML = "Service Fee preference is required.";
            document.querySelector('#markupFlight').focus();
            return;
        }
        else if (document.querySelector('#markupFlight').value === "" && markupLabelFlight !== '') {
            document.querySelector('#markupValueFlight').innerHTML = "Service Fee is required.";
            document.querySelector('#markupFlight').focus();
            return;
        }
        else if (markupLabelFlight === 'Percentage') {
            if (document.querySelector('#markupFlight').value > 100) {
                document.querySelector('#markupValueFlight').innerHTML = "Service Fee cannot exceed 100%.";
                document.querySelector('#markupFlight').focus();
                return;
            }
            else {
                document.querySelector('#markupValueFlight').innerHTML = "";
                document.querySelector('#markupPrefernceFlight').innerHTML = "";
                //props.onContinueButton("5");
            }
        }
        else if (markupLabelFlight === 'Amount') {
            let spnTotalAmount = 0;
            if (sessionStorage.getItem("FlightIntialTotalAmount") === null) {
                if (document.querySelector('#spnTotalAmountFlight').innerHTML.length > 1) {
                    if (document.querySelector('#spnTotalAmountFlight').innerHTML.indexOf('&nbsp;') !== -1) {
                        spnTotalAmount = document.querySelector('#spnTotalAmountFlight').innerHTML.split('&nbsp;')[1];
                    }
                    else {
                        spnTotalAmount = document.querySelector('#spnTotalAmountFlight').innerHTML.trim().substring(1, document.querySelector('#spnTotalAmountFlight').innerHTML.length);
                    }
                    spnTotalAmount = spnTotalAmount.replace(',', '');
                    sessionStorage.setItem("FlightIntialTotalAmount", spnTotalAmount);
                }
            }
            else {
                spnTotalAmount = sessionStorage.getItem("FlightIntialTotalAmount");
            }

            if (document.querySelector('#markupFlight').value !== '') {
                if (parseFloat(document.querySelector('#markupFlight').value) > parseFloat(spnTotalAmount)) {
                    document.querySelector('#markupValueFlight').innerHTML = "Service Fee cannot exceed Total Amount.";
                    document.querySelector('#markupFlight').focus();
                    return;
                }
                else {
                    document.querySelector('#markupValueFlight').innerHTML = "";
                    document.querySelector('#markupPrefernceFlight').innerHTML = "";
                    //props.onContinueButton("5");
                }
            }

        }
        else {
            document.querySelector('#markupValueFlight').innerHTML = "";
            document.querySelector('#markupPrefernceFlight').innerHTML = "";
            //props.onContinueButton("5");
        }

        //for hotel
        if (document.querySelector('#markupHotel').value !== "" && markupLabelHotel === '') {
            document.querySelector('#markupPrefernceHotel').innerHTML = "Service Fee preference is required.";
            document.querySelector('#markupHotel').focus();
            return;
        }
        else if (document.querySelector('#markupHotel').value === "" && markupLabelHotel !== '') {
            document.querySelector('#markupValueHotel').innerHTML = "Service Fee is required.";
            document.querySelector('#markupFlight').focus();
            return;
        }
        else if (markupLabelHotel === 'Percentage') {
            if (document.querySelector('#markupHotel').value > 100) {
                document.querySelector('#markupValueHotel').innerHTML = "Service Fee cannot exceed 100%.";
                document.querySelector('#markupHotel').focus();
                return;
            }
            else {
                document.querySelector('#markupValueHotel').innerHTML = "";
                document.querySelector('#markupPrefernceHotel').innerHTML = "";
                //props.onContinueButton("5");
            }
        }
        else if (markupLabelHotel === 'Amount') {
            let spnTotalAmount = 0;
            if (sessionStorage.getItem("HotelIntialTotalAmount") === null) {
                if (document.querySelector('#spnTotalAmountHotel').innerHTML.length > 1) {
                    if (document.querySelector('#spnTotalAmountHotel').innerHTML.indexOf('&nbsp;') !== -1) {
                        spnTotalAmount = document.querySelector('#spnTotalAmountHotel').innerHTML.split('&nbsp;')[1];
                    }
                    else {
                        spnTotalAmount = document.querySelector('#spnTotalAmountHotel').innerHTML.trim().substring(1, document.querySelector('#spnTotalAmountHotel').innerHTML.length);
                    }
                    spnTotalAmount = spnTotalAmount.replace(',', '');
                    sessionStorage.setItem("HotelIntialTotalAmount", spnTotalAmount);
                }
            }
            else {
                spnTotalAmount = sessionStorage.getItem("HotelIntialTotalAmount");
            }

            if (document.querySelector('#markupHotel').value !== '') {
                if (parseFloat(document.querySelector('#markupHotel').value) > parseFloat(spnTotalAmount)) {
                    document.querySelector('#markupValueHotel').innerHTML = "Service Fee cannot exceed Total Rate.";
                    document.querySelector('#markupHotel').focus();
                    return;
                }
                else {
                    document.querySelector('#markupValueHotel').innerHTML = "";
                    document.querySelector('#markupPrefernceHotel').innerHTML = "";
                    //props.onContinueButton("5");
                }
            }

        }
        else {
            document.querySelector('#markupValueHotel').innerHTML = "";
            document.querySelector('#markupPrefernceHotel').innerHTML = "";
            //props.onContinueButton("5");
        }

        if (document.querySelector('#markupFlight').value !== "" && markupLabelFlight !== '' && parseFloat(document.querySelector('#markupFlight').value) > 0) {
            if (!document.querySelector('#chkTandcZeroDispute:checked')) {
                document.querySelector('#chkTandcZeroDispute').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else if (!document.querySelector('#chkTandcChargeName:checked')) {
                document.querySelector('#chkTandcChargeName').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else {
                document.querySelector('#spnTandCMessage').innerHTML = "";
            }
        }

        //for hotel
        if (document.querySelector('#markupHotel').value !== "" && markupLabelHotel !== '' && parseFloat(document.querySelector('#markupHotel').value) > 0) {
            if (!document.querySelector('#chkTandcZeroDispute:checked')) {
                document.querySelector('#chkTandcZeroDispute').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else if (!document.querySelector('#chkTandcChargeName:checked')) {
                document.querySelector('#chkTandcChargeName').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else {
                document.querySelector('#spnTandCMessage').innerHTML = "";
            }
        }

        if (document.querySelector('#markupFlight').value !== "" && markupLabelFlight !== '' && parseFloat(document.querySelector('#markupFlight').value) > 0) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            if (intialBookingJSON.length === 0) {
                setIntialBookingJSON(JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")));
            }

            if (intialBookingJSON.length === 0) {
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (markupLabelFlight === 'Amount') {
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markupFlight').value);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }
                    else if (markupLabelFlight === 'Percentage') {
                        calculatedAmountFlight = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markupFlight').value)) / 100;
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmountFlight);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }

                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees !== null) {
                            let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                            maxKey = maxKey + 1;
                            elementPassengerTypeQuantity.fees.fee.push({
                                "key": maxKey.toString(),
                                "currencyCode": elementPassengerTypeQuantity.fees.fee[0].currencyCode,
                                "amount": markupLabelFlight === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmountFlight, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markupFlight').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabelFlight
                            });
                        }
                        else {
                            let fee = [];
                            fee.push({
                                "key": "1",
                                "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                "amount": markupLabelFlight === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmountFlight, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markupFlight').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabelFlight
                            })
                            elementPassengerTypeQuantity.fees = { fee };
                        }
                    });
                });
            }
            else {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (markupLabelFlight === 'Amount') {
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markupFlight').value);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }
                    else if (markupLabelFlight === 'Percentage') {
                        calculatedAmountFlight = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markupFlight').value)) / 100;
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmountFlight);
                        elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, currencyDecimalLength);
                        totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                    }

                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees !== null) {
                            let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                            maxKey = maxKey + 1;
                            elementPassengerTypeQuantity.fees.fee.push({
                                "key": maxKey.toString(),
                                "currencyCode": elementPassengerTypeQuantity.fees.fee[0].currencyCode,
                                "amount": markupLabelFlight === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmountFlight, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markupFlight').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabelFlight
                            });
                        }
                        else {
                            let fee = [];
                            fee.push({
                                "key": "1",
                                "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                "amount": markupLabelFlight === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmountFlight, currencyDecimalLength)).toString() : parseFloat(document.querySelector('#markupFlight').value).toFixed(currencyDecimalLength).toString(),
                                "text": "ServiceFee-" + markupLabelFlight
                            })
                            elementPassengerTypeQuantity.fees = { fee };
                        }
                    });
                });
            }

            document.querySelector('#spnMarkupAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
            }).format(markupLabelFlight === 'Percentage' ? CustomToFixed(calculatedAmountFlight, currencyDecimalLength) : document.querySelector('#markupFlight').value);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmountFlight = 0;
            serviceFeeSinglePnrCurrencyCode = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            otherServiceMarupAmountFlight = markupLabelFlight === 'Percentage' ? CustomToFixed(calculatedAmountFlight, currencyDecimalLength) : document.querySelector('#markupFlight').value;

            let otherServiceTotalFlight = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmountFlight);

            document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(CustomToFixed(otherServiceTotalFlight, currencyDecimalLength));

            document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountFlight') !== null) {
                document.querySelector('#spnEquivalentAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
        }
        else {
            let bookingJSON = [];
            if (Object.keys(intialBookingJSON).length > 0) {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
            }
            else {
                bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
            }

            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            document.querySelector('#spnMarkupAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(0);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmountFlight = 0;
            serviceFeeSinglePnrCurrencyCode = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            let otherServiceTotalFlight = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmountFlight);

            document.querySelector('#dvOtherServicesAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(CustomToFixed(otherServiceTotalFlight, currencyDecimalLength));

            var totalFareAmount = 0;
            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                totalFareAmount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalBaseFare.amount) + parseFloat(elementAirItinerary.airItineraryPricingInfo.totalTax.amount) + parseFloat(otherServiceTotalFlight);
                elementAirItinerary.airItineraryPricingInfo.totalFare.amount = totalFareAmount.toFixed(currencyDecimalLength);
            });

            document.querySelector('#spnTotalAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountFlight') !== null) {
                document.querySelector('#spnEquivalentAmountFlight').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false)));
            }

            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                    if (elementPassengerTypeQuantity.fees !== undefined) {
                        let fee = [];
                        fee = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount' && a.text !== 'ServiceFee-Percentage');
                        elementPassengerTypeQuantity.fees.fee = fee;
                    }
                });
            });

            if (bookingJSON.data.hotelResults !== undefined && bookingJSON.data.hotelResults[0] !== undefined && parseFloat(document.querySelector('#markupHotel').value) <= 0) {
                bookingJSON.data.hotelResults.forEach(elementHotelResults => {
                    elementHotelResults.price.forEach(elementPrice => {
                        if (elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUpType !== undefined) {
                            elementPrice.agentMarkUp = "0"
                        }
                    });
                });
            }

            sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
        }

        //for hotel
        if (document.querySelector('#markupHotel').value !== "" && markupLabelHotel !== '' && parseFloat(document.querySelector('#markupHotel').value) > 0) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            if (intialBookingJSON.length === 0) {
                setIntialBookingJSON(JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")));
            }

            if (intialBookingJSON.length === 0) {
                bookingJSON.data.hotelResults.forEach(elementHotelResults => {
                    elementHotelResults.price.forEach(elementPrice => {
                        if (markupLabelHotel === 'Amount') {
                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.roomPrice = elementPrice.roomPrice.toFixed(currencyDecimalLength);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.offeredPrice.priceOffered = elementPrice.offeredPrice.priceOffered.toFixed(currencyDecimalLength);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(currencyDecimalLength);

                            elementPrice.agentMarkUp = parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.agentMarkUp = elementPrice.agentMarkUp.toFixed(currencyDecimalLength);
                            elementPrice.agentMarkUpType = "ServiceFee-Amount";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(document.querySelector('#markupHotel').value);
                                element.price.agentMarkUp = element.price.agentMarkUp.toFixed(currencyDecimalLength);
                                element.price.agentMarkUpType = "ServiceFee-Amount";
                            });

                            totalRateAmount = elementPrice.offeredPrice.priceOffered;
                        }
                        else if (markupLabelHotel === 'Percentage') {
                            calculatedAmountHotel = (parseFloat(elementPrice.offeredPrice.priceOffered) * parseFloat(document.querySelector('#markupHotel').value)) / 100;

                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(calculatedAmountHotel);
                            elementPrice.roomPrice = CustomToFixed(elementPrice.roomPrice, currencyDecimalLength);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(calculatedAmountHotel);
                            elementPrice.offeredPrice.priceOffered = CustomToFixed(elementPrice.offeredPrice.priceOffered, currencyDecimalLength);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(calculatedAmountHotel);
                            elementPrice.offeredPrice.perNight = CustomToFixed(elementPrice.offeredPrice.perNight, currencyDecimalLength);

                            elementPrice.agentMarkUp = parseFloat(calculatedAmountHotel);
                            elementPrice.agentMarkUp = CustomToFixed(elementPrice.agentMarkUp, currencyDecimalLength);
                            elementPrice.agentMarkUpType = "ServiceFee-Percentage";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(calculatedAmountHotel);
                                element.price.agentMarkUp = CustomToFixed(element.price.agentMarkUp, currencyDecimalLength);
                                element.price.agentMarkUpType = "ServiceFee-Percentage";
                            });

                            totalRateAmount = elementPrice.offeredPrice.priceOffered;
                        }
                    });
                });
            }
            else {
                let bookingJSONTemp = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));

                bookingJSONTemp.data.airItinerary.forEach(elementAirItinerary => {
                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined) {
                            bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
                        }
                        else {
                            bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
                        }
                    });
                });

                bookingJSON.data.hotelResults.forEach(elementHotelResults => {
                    elementHotelResults.price.forEach(elementPrice => {
                        if (markupLabelHotel === 'Amount') {
                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.roomPrice = elementPrice.roomPrice.toFixed(currencyDecimalLength);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.offeredPrice.priceOffered = elementPrice.offeredPrice.priceOffered.toFixed(currencyDecimalLength);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(currencyDecimalLength);

                            elementPrice.agentMarkUp = parseFloat(document.querySelector('#markupHotel').value);
                            elementPrice.agentMarkUp = elementPrice.agentMarkUp.toFixed(currencyDecimalLength);
                            elementPrice.agentMarkUpType = "ServiceFee-Amount";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(document.querySelector('#markupHotel').value);
                                element.price.agentMarkUp = element.price.agentMarkUp.toFixed(currencyDecimalLength);
                                element.price.agentMarkUpType = "ServiceFee-Amount";
                            });

                            totalRateAmount = elementPrice.offeredPrice.priceOffered;
                        }
                        else if (markupLabelHotel === 'Percentage') {
                            calculatedAmountHotel = (parseFloat(elementPrice.offeredPrice.priceOffered) * parseFloat(document.querySelector('#markupHotel').value)) / 100;

                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(calculatedAmountHotel);
                            elementPrice.roomPrice = CustomToFixed(elementPrice.roomPrice, currencyDecimalLength);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(calculatedAmountHotel);
                            elementPrice.offeredPrice.priceOffered = CustomToFixed(elementPrice.offeredPrice.priceOffered, currencyDecimalLength);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(calculatedAmountHotel);
                            elementPrice.offeredPrice.perNight = CustomToFixed(elementPrice.offeredPrice.perNight, currencyDecimalLength);

                            elementPrice.agentMarkUp = parseFloat(calculatedAmountHotel);
                            elementPrice.agentMarkUp = CustomToFixed(elementPrice.agentMarkUp, currencyDecimalLength);
                            elementPrice.agentMarkUpType = "ServiceFee-Percentage";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(calculatedAmountHotel);
                                element.price.agentMarkUp = CustomToFixed(element.price.agentMarkUp, currencyDecimalLength);
                                element.price.agentMarkUpType = "ServiceFee-Percentage";
                            });

                            totalRateAmount = elementPrice.offeredPrice.priceOffered;
                        }
                    });
                });
            }

            document.querySelector('#spnMarkupAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode,
            }).format(markupLabelHotel === 'Percentage' ? CustomToFixed(calculatedAmountHotel, currencyDecimalLength) : document.querySelector('#markupHotel').value);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmountHotel = 0;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            otherServiceMarupAmountHotel = markupLabelHotel === 'Percentage' ? CustomToFixed(calculatedAmountHotel, currencyDecimalLength) : document.querySelector('#markupHotel').value;

            let otherServiceTotalHotel = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmountHotel);

            document.querySelector('#dvOtherServicesAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(CustomToFixed(otherServiceTotalHotel, currencyDecimalLength));

            document.querySelector('#spnTotalAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(parseFloat(totalRateAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountHotel') !== null) {
                document.querySelector('#spnEquivalentAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.hotelResults[0].price[0].currencyCode, totalRateAmount, false)));
            }

            // reset total price of payment section 
            let totalPrice = parseFloat(totalFareAmount) + parseFloat(totalRateAmount)

            let flightEqAmount = parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false));
            let hotelEqAmount = parseFloat(calculateRoeAmountAdditional(bookingJSON.data.hotelResults[0].price[0].currencyCode, totalRateAmount, false));
            totalEqPrice = parseFloat(flightEqAmount) + parseFloat(hotelEqAmount)

            if (document.querySelector('#spnTotalAmountSinglePnrTop') !== null) {
                document.querySelector('#spnTotalAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.hotelResults[0].price[0].currencyCode,
                }).format(totalPrice);
            }
            if (document.querySelector('#spnTotalEqAmountSinglePnrTop') !== null) {
                document.querySelector('#spnTotalEqAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(totalEqPrice);
            }

            sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
        }
        else {
            let bookingJSON = [];
            if (Object.keys(intialBookingJSON).length > 0) {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
            }
            else {
                bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
            }

            let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format('0').split('.')[1].length;

            document.querySelector('#spnMarkupAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(0);

            let otherServiceSeatAmount = 0;
            let otherServiceMarupAmountHotel = 0;

            if (document.querySelector('#spnSeatsAmount').innerHTML.length > 1) {
                if (document.querySelector('#spnSeatsAmount').innerHTML.indexOf('&nbsp;') !== -1) {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.split('&nbsp;')[1];
                }
                else {
                    otherServiceSeatAmount = document.querySelector('#spnSeatsAmount').innerHTML.trim().substring(1, document.querySelector('#spnSeatsAmount').innerHTML.length);
                }
            }

            let otherServiceTotalHotel = parseFloat(otherServiceSeatAmount) + parseFloat(otherServiceMarupAmountHotel);

            document.querySelector('#dvOtherServicesAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(CustomToFixed(otherServiceTotalHotel, currencyDecimalLength));

            var totalRateAmount = 0;
            bookingJSON.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    totalRateAmount = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(otherServiceTotalHotel);
                    elementPrice.offeredPrice.priceOffered = totalRateAmount.toFixed(currencyDecimalLength);

                    elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(otherServiceTotalHotel);
                    elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(currencyDecimalLength);
                })
            })

            document.querySelector('#spnTotalAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(parseFloat(totalRateAmount).toFixed(currencyDecimalLength));

            if (document.querySelector('#spnEquivalentAmountHotel') !== null) {
                document.querySelector('#spnEquivalentAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(bookingJSON.data.hotelResults[0].price[0].currencyCode, totalRateAmount, false)));
            }

            // reset total price of payment section 
            let totalPrice = parseFloat(totalFareAmount) + parseFloat(totalRateAmount)

            let flightEqAmount = parseFloat(calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, totalFareAmount, false));
            let hotelEqAmount = parseFloat(calculateRoeAmountAdditional(bookingJSON.data.hotelResults[0].price[0].currencyCode, totalRateAmount, false));
            totalEqPrice = parseFloat(flightEqAmount) + parseFloat(hotelEqAmount)

            if (document.querySelector('#spnTotalAmountSinglePnrTop') !== null) {
                document.querySelector('#spnTotalAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: bookingJSON.data.hotelResults[0].price[0].currencyCode,
                }).format(totalPrice);
            }
            if (document.querySelector('#spnTotalEqAmountSinglePnrTop') !== null) {
                document.querySelector('#spnTotalEqAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(totalEqPrice);
            }

            bookingJSON.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    if (elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUpType !== undefined) {
                        elementPrice.agentMarkUp = "0"
                    }
                });
            });

            if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && parseFloat(document.querySelector('#markupFlight').value) <= 0) {
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                        if (elementPassengerTypeQuantity.fees !== undefined) {
                            let fee = [];
                            fee = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount' && a.text !== 'ServiceFee-Percentage');
                            elementPassengerTypeQuantity.fees.fee = fee;
                        }
                    });
                });
            }

            sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
        }

        let currencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: serviceFeeSinglePnrCurrencyCode }).format('0').split('.')[1].length;

        if (markupLabelFlight === 'Amount' && markupLabelHotel === 'Amount') {
            serviceFeeSinglePnrToatl = parseFloat(document.querySelector('#markupFlight').value) + parseFloat(document.querySelector('#markupHotel').value)
        }
        else if (markupLabelFlight === 'Percentage' && markupLabelHotel === 'Percentage') {
            serviceFeeSinglePnrToatl = calculatedAmountFlight + calculatedAmountHotel;
        }
        else if (markupLabelFlight === 'Amount' && markupLabelHotel === 'Percentage') {
            serviceFeeSinglePnrToatl = parseFloat(document.querySelector('#markupFlight').value) + calculatedAmountHotel;
        }
        else if (markupLabelFlight === 'Percentage' && markupLabelHotel === 'Amount') {
            serviceFeeSinglePnrToatl = calculatedAmountFlight + parseFloat(document.querySelector('#markupHotel').value);
        }

        document.querySelector('#spnServiceFeeSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: serviceFeeSinglePnrCurrencyCode,
        }).format(CustomToFixed(serviceFeeSinglePnrToatl, currencyDecimalLength));

        fareSinglePnrToatl = parseFloat(totalFareAmount) + parseFloat(totalRateAmount);

        document.querySelector('#spnTotalAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: serviceFeeSinglePnrCurrencyCode,
        }).format(parseFloat(fareSinglePnrToatl).toFixed(currencyDecimalLength));

        if (document.querySelector('#spnTotalEqAmountSinglePnrTop') !== null) {
            document.querySelector('#spnTotalEqAmountSinglePnrTop').innerHTML = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                style: 'currency',
                currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
            }).format(totalEqPrice);
        }

        props.onContinueButton("5");
        // window.scrollTo({
        //     top: 1100,
        //     behavior: "smooth",
        // });
        if (document.querySelector("#headingSix") !== null) {
            const yOffset = -150;
            const element2 = document.getElementById("headingSix");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const onMarkupChangeHandlerFlight = (event) => {
        setMarkupLabelFlight(event.label);
        document.querySelector('#markupPrefernceFlight').innerHTML = "";
    }

    const onMarkupChangeHandlerHotel = (event) => {
        setMarkupLabelHotel(event.label);
        document.querySelector('#markupPrefernceHotel').innerHTML = "";
    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const onTandcChargeNameChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    const onTandcZeroDisputeChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    return (<div className="accordion_flex_area">
        <div id="sequenceFour" className="accordion_left_side position-relative text-muted">
            <h5>5</h5>
            <span id="completeFive" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" >
                <button id="headingMarkup" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseMarkup"
                    aria-expanded="false" aria-controls="collapseMarkup">
                    Add Service Fee
                </button>
            </h2>

            <div id="collapseMarkup" className="accordion-collapse collapse"
                aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <span>Flight</span>
                <div style={{ display: "block" }} id="divFlight" className='col-md-12 mt-2'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <input type="number" className="mb-0 form-control" id='markupFlight' min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10"></input>
                                <span id="markupValueFlight" className="error-text"></span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <Dropdown
                                    options={prefernce_list}
                                    textField=""
                                    dataItemKey=""
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                    onChange={onMarkupChangeHandlerFlight}
                                />
                                <span id="markupPrefernceFlight" className="error-text"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <span>Hotel</span>
                <div style={{ display: "block" }} id="divHotel" className='col-md-12 mt-2'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <input type="number" className="mb-0 form-control" id='markupHotel' min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10"></input>
                                <span id="markupValueHotel" className="error-text"></span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='control'>
                                <Dropdown
                                    options={prefernce_list}
                                    textField=""
                                    dataItemKey=""
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                    onChange={onMarkupChangeHandlerHotel}
                                />
                                <span id="markupPrefernceHotel" className="error-text"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcZeroDispute" onChange={(e) => onTandcZeroDisputeChangeHandler(e)}></input>&nbsp;
                    I agree that it is the agent's responsibility to ensure zero disputes on service fees and markups.</div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcChargeName" onChange={(e) => onTandcChargeNameChangeHandler(e)}></input>&nbsp;
                    I agree that this booking platform will not handle payments for service fees and markups. It is the agent's responsibility to charge and collect the payment.</div>
                <span id='spnTandCMessage' className="error-text"></span>
                <div className="col-md-12 my-2">
                    <div className="float-end">
                        <button type="button" onClick={assignMarkup} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default AddMarkup
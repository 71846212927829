import gtDB from "./db";

export async function addTravelRequestJson(id, jsonData) {
    try {
        // Add the id to the jsonData object
        jsonData.id = id;

        // Insert the data into the 'travelrequestdata' table
        await gtDB.travelrequestdata.add(jsonData);

        console.log(`Data added with id: ${id}`);
        return "Success";
    } catch (error) {
        console.error(`Failed to add data with id ${id}:`, error);
        return "Something Went Wrong";
    }
}

export async function getTravelRequestJsonById(id) {
    try {
        // Retrieve data from the 'travelrequestdata' table using the custom id
        const data = await gtDB.travelrequestdata.get(id);

        if (data) {
            console.log(`Data retrieved for id ${id}:`, data);
            return data;
        } else {
            console.log(`No data found for id: ${id}`);
            return null;
        }
    } catch (error) {
        console.error(`Failed to retrieve data for id ${id}:`, error);
        return null;
    }
}
export async function deleteAllData() {
    try {
        // Clear all data from the 'travelrequestdata' table
        await gtDB.travelrequestdata.clear();
        console.log('All data has been deleted from the travelrequestdata table.');

        // Return 1 on success
        return 1;
    } catch (error) {
        console.error('Failed to delete all data:', error);

        // Return 0 on failure
        return 0;
    }
}


import { SEARCH_ROE, DELETE_ROE, ADD_ROE, UPDATE_ROE } from "./CONSTANTS";
export async function searchRoe(searchData) {
    const response = await fetch(SEARCH_ROE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch roe.');
    }
    return data;
}
export async function deleteSingleRoe(deleteData) {
    const response = await fetch(DELETE_ROE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete roe.');
    }
    return data;
}
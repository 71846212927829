import Dropdown from 'react-select';
const MealDetails = (props) => {

    const DropDowndata = ({ keyName, data, value, id, updatePropertyName, placeholder, traveler }) => {
        function changeHandler(e) {
            updateValue(id, e.value, updatePropertyName, props.id, traveler)
        }

        return (<div><Dropdown textField="ddName"
            dataItemKey="ddID" arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
            controlClassName='form-control control form-select bg_input mb-0'
            placeholder={placeholder === "" ? "Selcet" : placeholder}
            defaultValue={data.filter(option => option.value === value)}
            onChange={changeHandler}
            options={data}
            id={keyName} />
            <span id={'msg' + keyName} className="error-text"></span></div>);

    };

    let specialServiceRequestTemp = [];
    let totalItinerary = 0;
    let itineraryStatus = "";
    function updateValue(id, Value, updatePropertyName, passengerSequence, traveler) {
        traveler.airItinerary.forEach(elementOuter => {
            totalItinerary = totalItinerary + elementOuter.originDestinationOptions.originDestinationOption.length;
            itineraryStatus = elementOuter.originDestinationOptions.originDestinationOption[0].flightSegment[0].status;
        });

        let found = specialServiceRequestTemp.find(element => element.PassengerNo === passengerSequence && element.ServiceType === updatePropertyName);
        if (found) {
            let specialServiceRequestTemp2 = JSON.parse(localStorage.getItem("AdditionalServicesData"));
            let specialServiceRequestTemp3 = [];
            specialServiceRequestTemp2.forEach(element => {
                if (element.ServiceType !== updatePropertyName) {
                    specialServiceRequestTemp3.push(element);
                }
                else {
                    if (element.PassengerNo !== passengerSequence) {
                        specialServiceRequestTemp3.push(element);
                    }
                }
            });

            if (Value !== '') {
                specialServiceRequestTemp3.push({
                    "PassengerNo": passengerSequence,
                    "ServiceType": updatePropertyName,
                    "ServiceCode": Value,
                    "ItineraryCount": totalItinerary,
                    "itineraryStatus": itineraryStatus
                });
            }

            specialServiceRequestTemp = specialServiceRequestTemp3;
        }
        else {
            if (localStorage.getItem("AdditionalServicesData") === null) {
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value,
                        "ItineraryCount": totalItinerary,
                        "itineraryStatus": itineraryStatus
                    });
                }
            } else {
                specialServiceRequestTemp = JSON.parse(localStorage.getItem("AdditionalServicesData"));
                if (Value !== '') {
                    specialServiceRequestTemp.push({
                        "PassengerNo": passengerSequence,
                        "ServiceType": updatePropertyName,
                        "ServiceCode": Value,
                        "ItineraryCount": totalItinerary,
                        "itineraryStatus": itineraryStatus
                    });
                }
            }
        }

        localStorage.setItem("AdditionalServicesData", JSON.stringify(specialServiceRequestTemp));
        //console.log(specialServiceRequestTemp);
    }

    let mealDetailsOptionsLcc = [];
    if (props.mealDetailsOptionsLcc.length > 0) {
        if (props.mealDetailsOptionsLcc[parseInt(props.segment.rph) - 1].availableSSRList !== undefined && props.mealDetailsOptionsLcc[parseInt(props.segment.rph) - 1].availableSSRList.availableMeal !== undefined) {
            mealDetailsOptionsLcc.push({ value: '', label: 'Select...' });
            //filter(a => a.flightSegment.rph === props.segment.rph)[0]
            props.mealDetailsOptionsLcc[parseInt(props.segment.rph) - 1].availableSSRList.availableMeal.forEach(elementAvailableMeal => {
                mealDetailsOptionsLcc = [...mealDetailsOptionsLcc, { value: elementAvailableMeal.mealCode, label: elementAvailableMeal.description }]
            });
        } else {
            mealDetailsOptionsLcc.push({ value: '', label: 'No options' });
        }
    }

    return (
        <>
            {
                props.mealDetailsOptionsLcc.length === 0 &&
                <div className='col-md-6' >
                    <div id={"dvMeal" + props.segment.rph + props.id} className='control hide-meals'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight No. {props.segment.flightSegment[0].marketingAirline.code} {props.segment.flightSegment[0].marketingAirline.flightNumber} ({props.segment.flightSegment[0].departureAirport.locationCode} to {props.segment.flightSegment[0].arrivalAirport.locationCode})</label>
                        <DropDowndata keyName={"mealDetails" + props.segment.rph}
                            value={props.traveler.travelerInfo.SpecialReqDetails !== undefined ? props.traveler.travelerInfo.SpecialReqDetails[0] !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== null && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.rph && a.TravelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.rph && a.TravelerRefNumber === props.id)[0].SSRCode : '' : props.traveler.travelerInfo.specialReqDetails[0] !== undefined && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests !== undefined && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests !== null && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.flightRefNumber === props.segment.rph && a.travelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.flightRefNumber === props.segment.rph && a.travelerRefNumber === props.id)[0].SSRCode : ''}
                            //id={props.segment.rph}                    
                            data={props.mealDetailsOptions}
                            updatePropertyName={"MealDetails" + "_" + props.segment.rph}
                            traveler={props.traveler}>
                        </DropDowndata>
                    </div>
                </div>
            }
            {
                props.mealDetailsOptionsLcc.length > 0 &&
                <div className='col-md-6' >
                    <div id={"dvMeal" + props.segment.rph + props.id} className='control hide-meals'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight No. {props.segment.flightSegment[0].marketingAirline.code} {props.segment.flightSegment[0].marketingAirline.flightNumber} ({props.segment.flightSegment[0].departureAirport.locationCode} to {props.segment.flightSegment[0].arrivalAirport.locationCode})</label>
                        <DropDowndata keyName={"mealDetails" + props.segment.rph}
                            value={props.traveler.travelerInfo.SpecialReqDetails !== undefined ? props.traveler.travelerInfo.SpecialReqDetails[0] !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== undefined && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests !== null && props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.rph && a.TravelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.SpecialReqDetails[0].SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.FlightRefNumber === props.segment.rph && a.TravelerRefNumber === props.id)[0].SSRCode : '' : props.traveler.travelerInfo.specialReqDetails[0] !== undefined && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests !== undefined && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests !== null && props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.flightRefNumber === props.segment.rph && a.travelerRefNumber === props.id).length > 0 ? props.traveler.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.flightRefNumber === props.segment.rph && a.travelerRefNumber === props.id)[0].SSRCode : ''}
                            //id={props.segment.rph}                            
                            data={mealDetailsOptionsLcc}
                            updatePropertyName={"MealDetails" + "_" + props.segment.rph}
                            traveler={props.traveler}>
                        </DropDowndata>
                    </div>
                </div>
            }
        </>
    )
}
export default MealDetails
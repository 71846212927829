// import { Fragment } from 'react';
import { Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import BookingDetails from './Components/BookingDetails';
import HotelDetails from './Components/HotelDetails';
import RateDetails from './Components/RateDetails';
import ContactPerson from './Components/ContactPerson';
import GuestInformation from './Components/GuestInformation';
import BillingDetails from './Components/BillingDetails';
import BookingRemarks from './Components/BookingRemarks';
import Booking from './Components/Booking';
import CancellationPolicy from './Components/CancellationPolicy';
import HotelPolicy from './Components/HotelPolicy';
const HotelConfirmations = (props) => {
    useDocumentTitle('Hotel Confirmation')
    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
    //const hotelLogoLink = 'https://localhost:3000/assets/logos/hotels/default-confirm-hotel.png';

    //let hotelBookingJSON = JSON.parse(sessionStorage.getItem('hotelBookingJSON'));
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-confirm-hotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    let hotelFirstImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length !== 0 ? hotelBookingJSON.data.hotelResults[0].images[0].imagePath : hotelLogoLink;
    let hotelSecondImage = hotelBookingJSON.data.hotelResults[0].images !== undefined && hotelBookingJSON.data.hotelResults[0].images.length > 1 ? hotelBookingJSON.data.hotelResults[0].images[1].imagePath : "";
    // console.log(hotelBookingJSON.data.hotelResults[0].images[1].imagePath)


    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="col-lg-12">
                                <div className='row'>
                                    <BookingDetails hotelBookingJSON={hotelBookingJSON} hide="1"></BookingDetails>
                                    {hotelSecondImage !== '' &&
                                        <div className="hotel-list mt-1">
                                            <div className='row'>
                                                <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12'>
                                                    <img src={hotelFirstImage} className='w-100' />
                                                </div>
                                                <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12'>
                                                    <img src={hotelSecondImage} className='w-100' />
                                                </div>
                                            </div>
                                            <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                        </div>}
                                    {hotelSecondImage === '' &&
                                        <div className="hotel-list mt-1 row">
                                            <div className='hotel-confirmation-single-img col md-12'>
                                                <img src={hotelFirstImage} className='w-100' />
                                            </div>

                                            <HotelDetails hotelBookingJSON={hotelBookingJSON}></HotelDetails>
                                        </div>}
                                    <div className="tour_details_boxed_inner">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion_flex_area">
                                                <Booking hotelBookingJSON={hotelBookingJSON}></Booking>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">

                                                <RateDetails hotelBookingJSON={hotelBookingJSON} show=""></RateDetails>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <ContactPerson hotelBookingJSON={hotelBookingJSON}></ContactPerson>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <GuestInformation hotelBookingJSON={hotelBookingJSON}></GuestInformation>
                                            </div>
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <BillingDetails hotelBookingJSON={hotelBookingJSON}></BillingDetails>
                                            </div>
                                            {hotelBookingJSON.data.hotelMiscellaneousInfo.miscellaneousInfo[0].text !== "" ? <Fragment><hr></hr>
                                                <div className="accordion_flex_area">
                                                    <BookingRemarks hotelBookingJSON={hotelBookingJSON}></BookingRemarks>
                                                </div></Fragment> : ''}
                                            <hr></hr>
                                            <div className="accordion_flex_area">
                                                <HotelPolicy hotelBookingJSON={hotelBookingJSON}></HotelPolicy>
                                            </div>
                                            {/* {hotelBookingJSON.data.hotelResults[0].descriptions !== undefined && hotelBookingJSON.data.hotelResults[0].descriptions.filter(result => result.text.type === 'CancellationPolicy').length > 0 && <CancellationPolicy hotelBookingJSON={hotelBookingJSON} />} */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment >

};

export default HotelConfirmations;
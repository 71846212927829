import { Fragment, useState, useEffect } from "react";
import { getCustomizeDetails, getSocialMediaIcon } from '../../../utils/CustomizeUtils'
//import logo from '../../../assets/images/Globetrotterdark.png';
const FooterNavigation = () => {
    const [date, setDate] = useState();
    const getYear = () => setDate(new Date().getFullYear())

    function disableClick() {
        return false;
    }

    useEffect(() => {
        getYear();
    }, []);

    return (
        <Fragment>
            <footer id="footer_area">
                <div className="container">
                    <div className="row">
                        {(!JSON.parse(localStorage.getItem("isMobile")) && getCustomizeDetails("contactUsPhone") !== '' && getCustomizeDetails("contactUsEmail") !== '') && <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="footer_heading_area">
                                <h5>Contact Us</h5>
                            </div>
                            <div className="footer_first_area">
                                <div className="footer_inquery_area">
                                    {/* <h5>Call 24/7 for any help</h5> */}
                                    <h3 className="pt-0"> <a href={"tel:" + getCustomizeDetails("contactUsPhone")}><i className="fas fa-mobile-alt text-primary"></i> {getCustomizeDetails("contactUsPhone")}</a></h3>
                                </div>
                                <div className="footer_inquery_area">
                                    {/* <h5>Mail to our support team</h5> */}
                                    <h3 className="pt-0"> <a href={"mailto:" + getCustomizeDetails("contactUsEmail")}><span>
                                        <i className="fas fa-envelope text-primary"></i> {getCustomizeDetails("contactUsEmail")}</span></a></h3>
                                </div>
                            </div>
                        </div>}
                        {!JSON.parse(localStorage.getItem("isMobile")) && (getCustomizeDetails("linkName1") !== '' || getCustomizeDetails("linkName2") !== ''
                            || getCustomizeDetails("linkName3") !== '' || getCustomizeDetails("linkName4") !== ''
                            || getCustomizeDetails("linkName5") !== '' || getCustomizeDetails("linkName6") !== ''
                            || getCustomizeDetails("linkName7") !== '' || getCustomizeDetails("linkName8") !== ''
                            || getCustomizeDetails("linkName9") !== '' || getCustomizeDetails("linkName10") !== '') &&

                            <Fragment>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="footer-link-padding">
                                        <div className="footer_heading_area">
                                            <h5>Links</h5>
                                        </div>
                                        <div className="footer_link_area padding-top-mobile-view">
                                            <ul>
                                                {getCustomizeDetails("linkNameURL1") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL1")}>{getCustomizeDetails("linkName1")}</a></li>}
                                                {getCustomizeDetails("linkNameURL3") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL3")}>{getCustomizeDetails("linkName3")}</a></li>}
                                                {getCustomizeDetails("linkNameURL5") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL5")}>{getCustomizeDetails("linkName5")}</a></li>}
                                                {getCustomizeDetails("linkNameURL7") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL7")}>{getCustomizeDetails("linkName7")}</a></li>}
                                                {getCustomizeDetails("linkNameURL9") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL9")}>{getCustomizeDetails("linkName9")}</a></li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="footer_heading_area1">
                                    </div>
                                    <div className="footer_link_area">
                                        <ul>
                                            {getCustomizeDetails("linkNameURL2") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL2")}>{getCustomizeDetails("linkName2")}</a></li>}
                                            {getCustomizeDetails("linkNameURL4") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL4")}>{getCustomizeDetails("linkName4")}</a></li>}
                                            {getCustomizeDetails("linkNameURL6") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL6")}>{getCustomizeDetails("linkName6")}</a></li>}
                                            {getCustomizeDetails("linkNameURL8") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL8")}>{getCustomizeDetails("linkName8")}</a></li>}
                                            {getCustomizeDetails("linkNameURL10") !== '' && <li><a target="_blank" href={getCustomizeDetails("linkNameURL10")}>{getCustomizeDetails("linkName10")}</a></li>}

                                        </ul>
                                    </div>
                                </div></Fragment>}

                        {!JSON.parse(localStorage.getItem("isMobile")) && (getCustomizeDetails("rightlinkName1") !== '' || getCustomizeDetails("rightlinkName2") !== ''
                            || getCustomizeDetails("rightlinkName3") !== '' || getCustomizeDetails("rightlinkName4") !== ''
                            || getCustomizeDetails("rightlinkName5") !== '') &&
                            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                <div className="footer_heading_area mb-4">
                                    <h5 className="mb-3"> Follow us on</h5><br></br>
                                    {getCustomizeDetails("rightlinkName1") !== '' && <div className="mb-3">
                                        <a className="" target="_blank" rel="noreferrer" href={getCustomizeDetails("rightLinkNameURL1")}> {getCustomizeDetails("rightlinkName1")} <i className={getSocialMediaIcon(getCustomizeDetails("rightLinkNameURL1"))}></i></a>
                                    </div>}
                                    {getCustomizeDetails("rightlinkName2") !== '' && <div className="mb-3">
                                        <a className="" target="_blank" rel="noreferrer" href={getCustomizeDetails("rightLinkNameURL2")}> {getCustomizeDetails("rightlinkName2")} <i className={getSocialMediaIcon(getCustomizeDetails("rightLinkNameURL2"))}></i></a></div>}
                                    {getCustomizeDetails("rightlinkName3") !== '' && <div className="mb-3"> <a className="" target="_blank" rel="noreferrer" href={getCustomizeDetails("rightLinkNameURL3")}> {getCustomizeDetails("rightlinkName3")} <i className={getSocialMediaIcon(getCustomizeDetails("rightLinkNameURL3"))}></i></a></div>}
                                    {getCustomizeDetails("rightlinkName4") !== '' && <div className="mb-3"> <a className="" target="_blank" rel="noreferrer" href={getCustomizeDetails("rightLinkNameURL4")}> {getCustomizeDetails("rightlinkName4")} <i className={getSocialMediaIcon(getCustomizeDetails("rightLinkNameURL4"))}></i></a></div>}
                                    {getCustomizeDetails("rightlinkName5") !== '' && <div className="mb-3"> <a className="" target="_blank" rel="noreferrer" href={getCustomizeDetails("rightLinkNameURL5")}> {getCustomizeDetails("rightlinkName5")} <i className={getSocialMediaIcon(getCustomizeDetails("rightLinkNameURL5"))}></i></a></div>}
                                </div>

                            </div>}
                        {/* <div  className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>New York</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>666 Third Avenue, 4th Floor, New York, NY 10017</li>
                                </ul>
                            </div>
                            <div  className="footer_heading_area">
                                <h5>Dubai</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>Office 103, Al Khaleej Building, Abu Baker Al Siddique Street, Deira, Clock Tower, Dubai</li>
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div  className="footer_heading_area">
                                <h5>London</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>15 Little Portland Street, London W1W 0BW</li>
                                </ul>
                            </div>
                            <div  className="footer_heading_area">
                                <h5>Pune</h5>
                            </div>
                            <div  className="footer_link_area">
                                <ul>
                                   <li>A 4030, Solitaire Business Hub, Sr. No.207 Opp. NECO Garden Society, Viman Nagar, Pune, Maharashtra 411014</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            {(!JSON.parse(localStorage.getItem("isMobile")) && getCustomizeDetails("partnerSlider")) &&
                <div className="container-fluid slider-bg-footer">
                    <div className="container py-2">
                        <div className="col-lg-12">
                            <div className="pt-3 partner_slider_area owl-theme owl-carousel">
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/1.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/2.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/3.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/4.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/5.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/6.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/7.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/8.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/9.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/10.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/11.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/12.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/13.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/14.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/15.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/16.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/17.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/18.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/19.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/20.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/21.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/22.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/23.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/24.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/25.png" alt="logo"></img></a>
                                </div>
                                <div className="partner_logo">
                                    <a href={disableClick}><img src="assets/img/partner/26.png" alt="logo"></img></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {!JSON.parse(localStorage.getItem("isMobile")) && <div className="copyright_area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="co-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="copyright_left">
                                <p className="text-white">{getCustomizeDetails("bottomLeftText")}</p>
                            </div>
                        </div>
                        <div className="co-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="copyright_right row">
                                {getCustomizeDetails("certificate") ?
                                    <div className="col-md-12 copyright_right my-auto">
                                        <p className="text-white">
                                            <a href="https://www.fareladder.com/"
                                                className="text-white"
                                                target="_blank"
                                                rel="noreferrer">
                                                {getCustomizeDetails("bottomRightText")}
                                            </a>
                                        </p>
                                    </div> :
                                    <div className="col-md-12 my-auto">
                                        <p className="text-white">
                                            <a href="https://www.fareladder.com/"
                                                className="text-white"
                                                target="_blank"
                                                rel="noreferrer">
                                                {getCustomizeDetails("bottomRightText")}
                                            </a>
                                        </p>
                                    </div>
                                }
                                {getCustomizeDetails("certificate") &&
                                    <div className="col-md-2">
                                        {/* <img src="assets/img/bottom-footer-logo.png" alt="img" className="h-40" /> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="go-top">
                <i className="fas fa-chevron-up"></i>
                <i className="fas fa-chevron-up"></i>
            </div>
        </Fragment>
    )
}

export default FooterNavigation;
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';

import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import Dropdown from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import StateDropDownList from '../../../../components/Common/DropDownList/StateDropDownList';
import { searchCommonList } from '../../../../services/Office-API';
import useHttp from '../../../../services/use-http';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { encrypted } from '../../../../utils';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { validateEmail } from '../../../../utils/Validation';
import { getLoginUrl } from '../../../../utils/CommonFunction';

const SignUpForm = (props) => {
    const { t } = useTranslation(['translation.Office']);

    const firstNameInputRef = useRef('');
    const lastNameInputRef = useRef('');
    const middleNameInputRef = useRef('');
    const emailIdInputRef = useRef('');
    const companyNameInputRef = useRef('');
    const addressInputRef = useRef('');
    const phoneNumberInputRef = useRef('');
    const cityNameInputRef = useRef('');

    const postalCodeInputRef = useRef('');
    const faxNumberInputRef = useRef('');
    const iataNumberInputRef = useRef('');
    const arcNumberInputRef = useRef('');
    const taxIdInputRef = useRef('');

    const activeInputRef = useRef('');
    const loginEmailIdInputRef = useRef('');
    const passwordInputRef = useRef('');
    const confirmPasswordInputRef = useRef('');
    const profileLocatorInputRef = useRef('');
    const dkNumberInputRef = useRef('');

    const [postalCode, setPostalCode] = useState('');
    const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
    const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
    const [emailIdInputHasError, setEmailIdInputHasError] = useState(false);
    const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);
    const [companyNameInputHasError, setCompanyNameInputHasError] = useState(false);
    const [addressInputHasError, setAddressInputHasError] = useState(false);
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [categoryCodeInputHasError, setCategoryCodeInputHasError] = useState(false);
    const [dkNumberInputHasError, setDkNumberInputHasError] = useState(false);
    const [cityNameInputHasError, setCityNameInputHasError] = useState(false);

    const [subCategoryCodeInputHasError, setSubCategoryCodeInputHasError] = useState(false);
    const [stateCodeInputHasError, setStateCodeInputHasError] = useState(false);
    const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
    const [stateNameInputHasError, setStateNameInputHasError] = useState(false);
    const [postalCodeInputHasError, setPostalCodeInputHasError] = useState(false);
    const [loginEmailIdInputHasError, setLoginEmailIdInputHasError] = useState(false);
    const [passwordInputHasError, setPasswordInputHasError] = useState(false);
    const [confirmPasswordInputHasError, setConfirmPasswordInputHasError] = useState(false);
    const [activeUserInputHasError, setActiveUserInputHasError] = useState(false);

    const [firstNameInvalidInputError, setFirstNameInvalidInputError] = useState(false);
    const [lastNameInvalidInputError, setLastNameInvalidInputError] = useState(false);
    const [middleNameInvalidInputError, setMiddleNameInvalidInputError] = useState(false);
    const [emailIdInvalidInputError, setEmailIdInvalidInputError] = useState(false);
    const [companyNameInvalidInputError, setCompanyNameInvalidInputError] = useState(false);
    const [addressInvalidInputError, setAddressInvalidInputError] = useState(false);
    const [stateNameInvalidInputError, setStateNameInvalidInputError] = useState(false);
    const [profileLocatorInvalidInputError, setProfileLocatorInvalidInputError] = useState(false);
    const [dkNumberInvalidInputError, setDKNumberInvalidInputError] = useState(false);
    const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);

    const [postalCodeInvalidInputError, setPostalCodeInvalidInputError] = useState(false);
    const [faxNumberInvalidInputError, setFaxNumberInvalidInputError] = useState(false);
    const [iataNumberInvalidInputError, setIataNumberInvalidInputError] = useState(false);
    const [arcNumberInvalidInputError, setArcNumberInvalidInputError] = useState(false);
    const [taxIdInvalidInputError, setTaxIdInvalidInputError] = useState(false);
    const [loginEmailIdInvalidInputError, setLoginEmailIdInvalidInputError] = useState(false);
    const [passwordInvalidInputError, setPasswordInvalidInputError] = useState(false);
    const [confirmPasswordInvalidInputError, setConfirmPasswordInvalidInputError] = useState(false);
    const [confirmPasswordDoestNotMatchInputError, setConfirmPasswordDoestNotMatchInputError] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [controlType, setControlType] = useState('');
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [iSDisabled, setiSDisabledDDL] = useState('false');
    const [iSCategoryDisabled, setiSCategoryDisabledDDL] = useState('false');
    const [categoryCode, setCategoryCode] = useState('');
    const [subCategoryCode, setSubCategoryCode] = useState('');
    const [active, setActive] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    const [officeId, setOfficeId] = useState('');
    const [roleID, setRoleID] = useState('1');
    const [activeUserAction, setActiveUserAction] = useState(false);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [isCategoryChanged, setIsCategoryChanged] = useState(false);

    const validatePassword = (password) => {
        var regExp = /^(?=.*\d)(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return regExp.test(password);
    }

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
        //setRefresh(true);
        setIsCountryChanged(true);
        // setCityCode('');
        setCityList(cityList);
        setStateCode('');
        setStateList(stateList);
        setStateName('');
        setCountryCodeInputHasError(false);
    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };

    const stateCodeValue = (stateCode) => {
        setStateCode(stateCode);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        setStateNameInputHasError(false);
        setIsStateChanged(true);
    };

    const stateNameText = (stateName) => {
        if (stateName.length > 0) {
            if (!stateName.match('^[a-zA-Z ]+$')) {
                setStateNameInvalidInputError(true);
                //return;
            }
            else {
                setStateNameInvalidInputError(false);
            }
        }
        else {
            setStateNameInvalidInputError(false);
        }

        setStateName(stateName);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        if (stateName.trim() === '') {
            //setStateNameInputHasError(true);
        }
        else {
            setStateNameInputHasError(false);
        }
        setIsStateChanged(true);
    };

    const exposedControlType = (controlType) => {
        setControlType(controlType);
    };

    const categoryChangeHandler = (event) => {
        setCategoryCode(event.value);
        setIsCategoryChanged(true);
        setCategoryCodeInputHasError(false);

        if (subCategoryCode === '') {
            setSubCategoryCodeInputHasError(true);
        }
        else {
            setSubCategoryCodeInputHasError(false);
        }
    };

    const subCategoryChangeHandler = (event) => {
        setSubCategoryCode(event.value);
        setIsCategoryChanged(false);
        setSubCategoryCodeInputHasError(false);

        if (dkNumberInputRef.current.value.trim() === '') {
            setDkNumberInputHasError(true);
            setDKNumberInvalidInputError(false);
            return;
        } else {
            setDkNumberInputHasError(false);
        }

        if (loginEmailIdInputRef.current.value.trim() === '') {
            setLoginEmailIdInputHasError(true);
            setLoginEmailIdInvalidInputError(false);
            return;
        }
        else {
            setLoginEmailIdInputHasError(false);
        }
    };

    const activeChangeHandler = () => {
        setActiveUserAction(true);
        if (document.querySelector('#activeUser').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }

        if (props.loadedProfileDetailsUser.length === 0 && props.loadedProfileDetailsOffice.length === 0) {
            if (document.querySelector('#activeUser').checked === false) {
                //setActiveUserInputHasError(true);
            }
            else {
                setActiveUserInputHasError(false);
            }
        }
    }

    const { sendRequest: sendRequestCommonListCat, data: loadedCategory } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListSubCat, data: loadedSubCategory } = useHttp(searchCommonList);

    const history = useHistory();
    const setPhone = (value) => {
        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            let countryDialCodeLength = phoneNumberInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneNumberInputRef.current.numberInputRef.value.split(' ')[0]);
            setPhoneNumber(phoneNumberInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberInputHasError(false);

            if (companyNameInputRef.current.value.trim() === '') {
                setCompanyNameInputHasError(true);
                setCompanyNameInvalidInputError(false);
                return;
            }
            else {
                setCompanyNameInputHasError(false);
            }
        }
    };

    const [address, setAddress] = useState('');

    useEffect(() => {
        loadCaptchaEnginge(6, '#daeff8', 'black', 'numbers');
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedProfileDetailsUser.length !== 0 && props.loadedProfileDetailsOffice.length !== 0) {
            firstNameInputRef.current.value = `${firstNameInputRef.current.value === '' ? props.loadedProfileDetailsUser.firstName : firstNameInputRef.current.value}`;
            lastNameInputRef.current.value = `${lastNameInputRef.current.value === '' ? props.loadedProfileDetailsUser.lastName : lastNameInputRef.current.value}`;
            middleNameInputRef.current.value = `${middleNameInputRef.current.value === '' ? props.loadedProfileDetailsUser.middleName : middleNameInputRef.current.value}`;
            emailIdInputRef.current.value = `${emailIdInputRef.current.value === '' ? props.loadedProfileDetailsUser.email : emailIdInputRef.current.value}`;



            if (phoneNumber === '' && countryDialCode === '') {
                setCountryDialCode(props.loadedProfileDetailsUser.phoneCode.length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : props.loadedProfileDetailsUser.phoneCode);
                setPhoneNumber(props.loadedProfileDetailsUser.phoneNumber);
            }

            companyNameInputRef.current.value = `${companyNameInputRef.current.value === '' ? props.loadedProfileDetailsOffice.companyName : companyNameInputRef.current.value}`;
            if (address === '') {
                setAddress(props.loadedProfileDetailsOffice.address);
            }

            if (countryCode === '') {
                setCountryCode(props.loadedProfileDetailsOffice.countryCode);
            }

            if (stateCode === '' && isCountryChanged === false) {
                setStateCode(props.loadedProfileDetailsOffice.stateCode);
            }

            if (stateName.trim() === '' && isCountryChanged === false && isStateChanged === false) {
                setStateName(props.loadedProfileDetailsOffice.stateName);
            }

            cityNameInputRef.current.value = `${cityNameInputRef.current.value === '' ? props.loadedProfileDetailsOffice.cityCode : cityNameInputRef.current.value}`;
            postalCodeInputRef.current.value = `${postalCodeInputRef.current.value === '' ? props.loadedProfileDetailsOffice.postalCode : postalCodeInputRef.current.value}`;
            faxNumberInputRef.current.value = `${faxNumberInputRef.current.value === '' ? props.loadedProfileDetailsOffice.faxNumber : faxNumberInputRef.current.value}`;
            iataNumberInputRef.current.value = `${iataNumberInputRef.current.value === '' ? props.loadedProfileDetailsOffice.iataNumber : iataNumberInputRef.current.value}`;
            arcNumberInputRef.current.value = `${arcNumberInputRef.current.value === '' ? props.loadedProfileDetailsOffice.arcNumber : arcNumberInputRef.current.value}`;
            taxIdInputRef.current.value = `${taxIdInputRef.current.value === '' ? props.loadedProfileDetailsOffice.taxID : taxIdInputRef.current.value}`;
            profileLocatorInputRef.current.value = `${profileLocatorInputRef.current.value === '' ? props.loadedProfileDetailsOffice.profileLocaterNumber !== null ? props.loadedProfileDetailsOffice.profileLocaterNumber : '' : profileLocatorInputRef.current.value}`;
            dkNumberInputRef.current.value = `${dkNumberInputRef.current.value === '' ? props.loadedProfileDetailsOffice.dkNumber !== null ? props.loadedProfileDetailsOffice.dkNumber : '' : dkNumberInputRef.current.value}`;

            if (categoryCode === '') {
                setCategoryCode(props.loadedProfileDetailsOffice.categoryID);
            }

            if (subCategoryCode === '') {
                setSubCategoryCode(props.loadedProfileDetailsOffice.subCategoryID);
            }

            if (activeUserAction === false) {
                setActive(props.loadedProfileDetailsOffice.isActive);
            }

            loginEmailIdInputRef.current.value = `${loginEmailIdInputRef.current.value === '' ? props.loadedProfileDetailsUser.loginEmailID : loginEmailIdInputRef.current.value}`;

            setOfficeId(props.loadedProfileDetailsUser.officeID);
            setRoleID(props.loadedProfileDetailsUser.roleID);
        }
        else {
            if (phoneNumber === '' && countryDialCode === '') {
                setCountryDialCode(getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE"));
            }
        }

        if (isCategoryChanged === true) {
            setSubCategoryCode('');
            setSubCategoryCodeInputHasError(true);
        }

        // const onSearchCommonListCat = ({
        //     methodName: "Category",
        //     methodCode: "CAT",
        //     listID: "",
        //     listValue: "",
        //     subListID: ""
        // });

        // sendRequestCommonListCat(onSearchCommonListCat);

        // const onSearchCommonListSubCat = ({
        //     methodName: "",
        //     methodCode: "SUBCAT",
        //     listID: "",
        //     listValue: "",
        //     subListID: categoryCode
        // });

        // if (categoryCode !== '') {
        //     sendRequestCommonListSubCat(onSearchCommonListSubCat);
        // }

        setiSDisabledDDL('false');
        setiSCategoryDisabledDDL('false');

        if (props.loadedProfileDetailsOffice.length !== 0 && isEditMode === 100) {

            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View Office';
            document.getElementById("loginEmailId").readOnly = true;
            document.querySelector("#firstName").readOnly = true;
            document.querySelector("#middleName").readOnly = true;
            document.querySelector("#lastName").readOnly = true;
            document.querySelector("#emailId").readOnly = true;

            document.querySelector("#companyName").readOnly = true;
            document.querySelector("#address").disabled = true;
            document.querySelector("#postalCode").readOnly = true;
            document.querySelector("#cityName").readOnly = true;
            document.querySelector("#faxNumber").readOnly = true;

            document.querySelector("#iataNumber").readOnly = true;
            document.querySelector("#arcNumber").readOnly = true;
            document.querySelector("#taxId").readOnly = true;

            setiSDisabledDDL('true');

            document.querySelector("#activeUser").disabled = true;
            document.querySelector("#loginEmailId").readOnly = true;

            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";

            setiSCategoryDisabledDDL('true');
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });

            document.getElementById("requiredIndicator").style.display = "none";
            document.getElementById("dvPasswordArea").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit Office';
            document.getElementById("loginEmailId").readOnly = true;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL('false');
            setiSCategoryDisabledDDL('true');
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            document.getElementById("dvPasswordArea").style.display = "none";
        }

        if (props.loadedProfileDetailsOffice.length === 0) {
            document.getElementById('pageTitle').innerHTML = '  Sign Up';
        }

    }, [props.loadedProfileDetailsUser.length,
    props.loadedProfileDetailsOffice.length,
    props.loadedProfileDetailsUser.firstName,
    props.loadedProfileDetailsUser.lastName,
    props.loadedProfileDetailsUser.middleName,
    props.loadedProfileDetailsUser.email,
    props.loadedProfileDetailsUser.phoneCode,
    props.loadedProfileDetailsUser.phoneNumber,
    props.loadedProfileDetailsOffice.companyName,
    props.loadedProfileDetailsOffice.address,
    props.loadedProfileDetailsOffice.countryCode,
    props.loadedProfileDetailsOffice.stateCode,
    props.loadedProfileDetailsOffice.cityCode,
    props.loadedProfileDetailsOffice.postalCode,
    props.loadedProfileDetailsOffice.faxNumber,
    props.loadedProfileDetailsOffice.iataNumber,
    props.loadedProfileDetailsOffice.arcNumber,
    props.loadedProfileDetailsOffice.taxID,
    props.loadedProfileDetailsOffice.categoryID,
    props.loadedProfileDetailsOffice.subCategoryID,
    props.loadedProfileDetailsUser.loginEmailID,
    props.loadedProfileDetailsOffice.stateName,
    props.loadedProfileDetailsUser.officeID,
    props.loadedProfileDetailsUser.roleID,
        address, countryDialCode, phoneNumber,
        stateCode, subCategoryCode, countryCode, sendRequestCommonListCat,
        sendRequestCommonListSubCat, categoryCode, stateName, isCountryChanged,
        isStateChanged, props.loadedProfileDetailsOffice.isActive
    ]);

    let categoryOptions = []
    let categoryArrayData = [];
    for (let i in loadedCategory) {
        if (loadedCategory['errors'].status === 'FALSE') {
            if (i === 'data') {
                categoryArrayData = loadedCategory[i];
            }
        }
    }

    for (let index = 0; index < categoryArrayData.length; index++) {
        categoryOptions = [...categoryOptions, { value: categoryArrayData[index].listID, label: categoryArrayData[index].listValue }]
    }

    let subCategoryOptions = []
    let subCategoryArrayData = [];
    for (let i in loadedSubCategory) {
        if (loadedSubCategory['errors'].status === 'FALSE') {
            if (i === 'data') {
                subCategoryArrayData = loadedSubCategory[i];
            }
        }
    }

    for (let index = 0; index < subCategoryArrayData.length; index++) {
        subCategoryOptions = [...subCategoryOptions, { value: subCategoryArrayData[index].listID, label: subCategoryArrayData[index].listValue }]
    }

    function hideLastNameValidation() {
        setLastNameInputHasError(false);
    }

    function onBlurCheck() {
        const enteredCityName = cityNameInputRef.current.value;
        if (firstNameInputRef.current.value.trim() === '') {
            setFirstNameInputHasError(true);
            setFirstNameInvalidInputError(false);
            return;
        }
        else {
            setFirstNameInputHasError(false);
        }

        if (!firstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
            setFirstNameInvalidInputError(true);
            setFirstNameInputHasError(false);
            return;
        }
        else {
            setFirstNameInvalidInputError(false);
        }

        if (middleNameInputRef.current.value.length > 0) {
            if (!middleNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        }
        else {
            setMiddleNameInvalidInputError(false);
        }

        if (middleNameInputRef.current.value.length > 0 && middleNameInputRef.current.value.trim().length === 0) {
            setMiddleNameInvalidInputError(true);
            return;
        }
        else {
            setMiddleNameInvalidInputError(false);
        }

        if (lastNameInputRef.current.value.trim() === '') {
            setLastNameInputHasError(true);
            setLastNameInvalidInputError(false);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }

        if (!lastNameInputRef.current.value.match('^[a-zA-Z- ]+$') || lastNameInputRef.current.value.length < 2) {
            setLastNameInvalidInputError(true);
            setLastNameInputHasError(false);
            return;
        }
        else {
            setLastNameInvalidInputError(false);
        }

        if (emailIdInputRef.current.value.trim() === '') {
            setEmailIdInputHasError(true);
            setEmailIdInvalidInputError(false);
            return;
        }
        else {
            setEmailIdInputHasError(false);
        }

        if (!validateEmail(emailIdInputRef.current.value)) {
            setEmailIdInvalidInputError(true);
            setEmailIdInputHasError(false);
            return;
        }
        else {
            setEmailIdInvalidInputError(false);
            loginEmailIdInputRef.current.value = loginEmailIdInputRef.current.value === '' ? emailIdInputRef.current.value : loginEmailIdInputRef.current.value;
        }

        if (phoneNumberInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (companyNameInputRef.current.value.trim() === '') {
            setCompanyNameInputHasError(true);
            setCompanyNameInvalidInputError(false);
            return;
        }
        else {
            setCompanyNameInputHasError(false);
        }

        if (!companyNameInputRef.current.value.match('^[a-zA-Z 0-9&-.]+$')) {
            setCompanyNameInvalidInputError(true);
            setCompanyNameInputHasError(false);
            return;
        }
        else {
            setCompanyNameInvalidInputError(false);
        }

        if (address.trim() === '') {
            setAddressInputHasError(true);
            setAddressInvalidInputError(false);
            return;
        }
        else {
            setAddressInputHasError(false);
        }

        if (!address.match('^[a-zA-Z 0-9#/,-/\n/]+$')) {
            setAddressInvalidInputError(true);
            setAddressInputHasError(false);
            return;
        }
        else {
            setAddressInvalidInputError(false);
        }

        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (controlType !== '') {
            if (controlType === 'txt') {
                setStateCode('');
                setStateCodeInputHasError(false);
                if (stateName.trim() === '') {
                    //setStateNameInputHasError(true);
                    //return;
                }
                else {
                    setStateNameInputHasError(false);
                }
            }
            else {
                setStateName('');
                setStateNameInputHasError(false);
                if (stateCode === '') {
                    //setStateCodeInputHasError(true);
                    //return;
                }
                else {
                    setStateCodeInputHasError(false);
                }
            }
        }

        if (isCountryChanged === true || (stateCode === '' && stateName.trim() === '')) {
            //setStateCodeInputHasError(true);
            //return
        }


        if (enteredCityName.trim() === '') {
            setCityNameInputHasError(true);
            setCityNameInvalidInputError(false);
            return;
        }
        else {
            setCityNameInputHasError(false);
        }

        if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
            setCityNameInvalidInputError(true);
            setCityNameInputHasError(false);
            return;
        }
        else {
            setCityNameInvalidInputError(false);
        }

        if (postalCode.trim().length > 0) {
            if (!postalCode.match('^[a-zA-Z0-9 -]+$') || postalCode.length < 5) {
                setPostalCodeInvalidInputError(true);
                return;
            }
            else {
                setPostalCodeInvalidInputError(false);
            }
        } else {
            setPostalCodeInvalidInputError(false);
        }

        if (faxNumberInputRef.current.value.length > 0) {
            if (!faxNumberInputRef.current.value.match('^[0-9]+$') || faxNumberInputRef.current.value.length < 10) {
                setFaxNumberInvalidInputError(true);
                return;
            }
            else {
                setFaxNumberInvalidInputError(false);
            }
        }
        else {
            setFaxNumberInvalidInputError(false);
        }

        if (iataNumberInputRef.current.value.length > 0) {
            if (!iataNumberInputRef.current.value.match('^[0-9]+$') || iataNumberInputRef.current.value.length < 8) {
                setIataNumberInvalidInputError(true);
                return;
            }
            else {
                setIataNumberInvalidInputError(false);
            }
        }
        else {
            setIataNumberInvalidInputError(false);
        }

        if (arcNumberInputRef.current.value.length > 0) {
            if (!arcNumberInputRef.current.value.match('^[0-9]+$') || arcNumberInputRef.current.value.length < 8) {
                setArcNumberInvalidInputError(true);
                return;
            }
            else {
                setArcNumberInvalidInputError(false);
            }
        }
        else {
            setArcNumberInvalidInputError(false);
        }

        if (taxIdInputRef.current.value.length > 0) {
            if (!taxIdInputRef.current.value.match('^[a-zA-Z0-9]+$')) {
                setTaxIdInvalidInputError(true);
                return;
            }
            else {
                setTaxIdInvalidInputError(false);
            }
        }
        else {
            setTaxIdInvalidInputError(false);
        }

        // if (categoryCode === '') {
        //     setCategoryCodeInputHasError(true);
        //     return;
        // }
        // else {
        //     setCategoryCodeInputHasError(false);
        // }

        // if (subCategoryCode === '') {
        //     setSubCategoryCodeInputHasError(true);
        //     return;
        // }
        // else {
        //     setSubCategoryCodeInputHasError(false);
        // }

        if (profileLocatorInputRef.current.value.trim() !== '') {
            if (!profileLocatorInputRef.current.value.match('^[a-zA-Z 0-9]+$')) {
                setProfileLocatorInvalidInputError(true);
                return;
            }
            else {
                setProfileLocatorInvalidInputError(false);
            }
        }
        else {
            setProfileLocatorInvalidInputError(false);
        }

        if (dkNumberInputRef.current.value.trim() === '') {
            setDkNumberInputHasError(true);
            setDKNumberInvalidInputError(false);
            return;
        } else {
            setDkNumberInputHasError(false);
        }

        if (dkNumberInputRef.current.value.trim() !== '') {
            if (dkNumberInputRef.current.value.length === 6 || dkNumberInputRef.current.value.length === 7 || dkNumberInputRef.current.value.length === 10) {
                setDKNumberInvalidInputError(false);
            }
            else {
                setDKNumberInvalidInputError(true);
                return;
            }
        }
        else {
            setDKNumberInvalidInputError(false);
        }

        if (dkNumberInputRef.current.value.trim() !== '') {
            if (!dkNumberInputRef.current.value.match('^[a-zA-Z 0-9]+$')) {
                setDKNumberInvalidInputError(true);
                //setProfileLocatorInputHasError(false);
                return;
            }
            else {
                setDKNumberInvalidInputError(false);
            }
        }
        else {
            setDKNumberInvalidInputError(false);
        }


        if (loginEmailIdInputRef.current.value.trim() === '') {
            setLoginEmailIdInputHasError(true);
            setLoginEmailIdInvalidInputError(false);
            return;
        }
        else {
            setLoginEmailIdInputHasError(false);
        }

        if (!validateEmail(loginEmailIdInputRef.current.value)) {
            setLoginEmailIdInvalidInputError(true);
            setLoginEmailIdInputHasError(false);
            return;
        }
        else {
            setLoginEmailIdInvalidInputError(false);
        }

        if (passwordInputRef.current.value.trim() === '') {
            setPasswordInputHasError(true);
            setPasswordInvalidInputError(false);
            return;
        }
        else {
            setPasswordInputHasError(false);
        }

        if (!validatePassword(passwordInputRef.current.value)) {
            setPasswordInvalidInputError(true);
            setPasswordInputHasError(false);
            return;
        }
        else {
            setPasswordInvalidInputError(false);
        }

        if (confirmPasswordInputRef.current.value.trim() === '') {
            setConfirmPasswordInputHasError(true);
            setConfirmPasswordInvalidInputError(false);
            return;
        }
        else {
            setConfirmPasswordInputHasError(false);
        }

        if (!validatePassword(confirmPasswordInputRef.current.value)) {
            setConfirmPasswordInvalidInputError(true);
            setConfirmPasswordInputHasError(false);
            return;
        }
        else {
            setConfirmPasswordInvalidInputError(false);
        }

        if (passwordInputRef.current.value !== confirmPasswordInputRef.current.value) {
            setConfirmPasswordDoestNotMatchInputError(true);
            return;
        }
        else {
            setConfirmPasswordDoestNotMatchInputError(false);
        }
    }

    async function submitFormHandler(event) {
        event.preventDefault();

        const enteredCityName = cityNameInputRef.current.value;
        if (firstNameInputRef.current.value.trim() === '') {
            firstNameInputRef.current.focus();
            setFirstNameInputHasError(true);
            setFirstNameInvalidInputError(false);
            return;
        }
        else {
            setFirstNameInputHasError(false);
        }

        if (!firstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
            firstNameInputRef.current.focus();
            setFirstNameInvalidInputError(true);
            setFirstNameInputHasError(false);
            return;
        }
        else {
            setFirstNameInvalidInputError(false);
        }

        if (middleNameInputRef.current.value.length > 0) {
            if (!middleNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                middleNameInputRef.current.focus();
                setMiddleNameInvalidInputError(true);
                return;
            }
            else {
                setMiddleNameInvalidInputError(false);
            }
        }

        if (middleNameInputRef.current.value.length > 0 && middleNameInputRef.current.value.trim().length === 0) {
            middleNameInputRef.current.focus();
            setMiddleNameInvalidInputError(true);
            return;
        }
        else {
            setMiddleNameInvalidInputError(false);
        }

        if (lastNameInputRef.current.value.trim() === '') {
            lastNameInputRef.current.focus();
            setLastNameInputHasError(true);
            setLastNameInvalidInputError(false);
            return;
        }
        else {
            setLastNameInputHasError(false);
        }

        if (!lastNameInputRef.current.value.match('^[a-zA-Z- ]+$') || lastNameInputRef.current.value.length < 2) {
            lastNameInputRef.current.focus();
            setLastNameInvalidInputError(true);
            setLastNameInputHasError(false);
            return;
        }
        else {
            setLastNameInvalidInputError(false);
        }

        if (emailIdInputRef.current.value.trim() === '') {
            emailIdInputRef.current.focus();
            setEmailIdInputHasError(true);
            setEmailIdInvalidInputError(false);
            return;
        }
        else {
            setEmailIdInputHasError(false);
        }

        if (!validateEmail(emailIdInputRef.current.value)) {
            emailIdInputRef.current.focus();
            setEmailIdInvalidInputError(true);
            setEmailIdInputHasError(false);
            return;
        }
        else {
            setEmailIdInvalidInputError(false);
        }

        if (phoneNumber === '') {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (companyNameInputRef.current.value.trim() === '') {
            companyNameInputRef.current.focus();
            setCompanyNameInputHasError(true);
            setCompanyNameInvalidInputError(false);
            return;
        }
        else {
            setCompanyNameInputHasError(false);
        }

        if (!companyNameInputRef.current.value.match('^[a-zA-Z 0-9&-.]+$')) {
            companyNameInputRef.current.focus();
            setCompanyNameInvalidInputError(true);
            setCompanyNameInputHasError(false);
            return;
        }
        else {
            setCompanyNameInvalidInputError(false);
        }

        if (address.trim() === '') {
            addressInputRef.current.focus();
            setAddressInputHasError(true);
            setAddressInvalidInputError(false);
            return;
        }
        else {
            setAddressInputHasError(false);
        }

        if (!address.match('^[a-zA-Z 0-9#/,-/\n/]+$')) {
            addressInputRef.current.focus();
            setAddressInvalidInputError(true);
            setAddressInputHasError(false);
            return;
        }
        else {
            setAddressInvalidInputError(false);
        }

        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            window.scrollTo({
                top: 700,
                behavior: "smooth",
            });
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (controlType !== '') {
            if (controlType === 'txt') {
                setStateCode('');
                setStateCodeInputHasError(false);
                if (stateName.trim() === '') {
                    // window.scrollTo({
                    //     top: 700,
                    //     behavior: "smooth",
                    // });
                    // setStateNameInputHasError(true);
                    // return;
                }
                else {
                    setStateNameInputHasError(false);
                }
            }
            else {
                setStateName('');
                setStateNameInputHasError(false);
                if (stateCode === '') {
                    //setStateCodeInputHasError(true);
                    // window.scrollTo({
                    //     top: 700,
                    //     behavior: "smooth",
                    // });
                    // return;
                }
                else {
                    setStateCodeInputHasError(false);
                }
            }
        }

        if (stateName.length > 0) {
            if (!stateName.match('^[a-zA-Z ]+$')) {
                window.scrollTo({
                    top: 700,
                    behavior: "smooth",
                });
                setStateNameInvalidInputError(true);
                return;
            }
            else {
                setStateNameInvalidInputError(false);
            }
        }
        else {
            setStateNameInvalidInputError(false);
        }

        if (enteredCityName.trim() === '') {
            cityNameInputRef.current.focus();
            setCityNameInputHasError(true);
            return;
        }
        else {
            setCityNameInputHasError(false);
        }

        if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
            cityNameInputRef.current.focus();
            setCityNameInvalidInputError(true);
            return;
        }
        else {
            setCityNameInvalidInputError(false);
        }



        if (postalCode.trim().length > 0) {
            if (!postalCode.match('^[a-zA-Z0-9 -]+$') || postalCode.length < 5) {
                postalCodeInputRef.current.focus();
                setPostalCodeInvalidInputError(true);
                return;
            }
            else {
                setPostalCodeInvalidInputError(false);
            }
        } else {
            setPostalCodeInvalidInputError(false);
        }


        if (faxNumberInputRef.current.value.length > 0) {
            if (!faxNumberInputRef.current.value.match('^[0-9]+$') || faxNumberInputRef.current.value.length < 10) {
                faxNumberInputRef.current.focus();
                setFaxNumberInvalidInputError(true);
                return;
            }
            else {
                setFaxNumberInvalidInputError(false);
            }
        }

        if (iataNumberInputRef.current.value.length > 0) {
            if (!iataNumberInputRef.current.value.match('^[0-9]+$') || iataNumberInputRef.current.value.length < 8) {
                iataNumberInputRef.current.focus();
                setIataNumberInvalidInputError(true);
                return;
            }
            else {
                setIataNumberInvalidInputError(false);
            }
        }

        if (arcNumberInputRef.current.value.length > 0) {
            if (!arcNumberInputRef.current.value.match('^[0-9]+$') || arcNumberInputRef.current.value.length < 8) {
                arcNumberInputRef.current.focus();
                setArcNumberInvalidInputError(true);
                return;
            }
            else {
                setArcNumberInvalidInputError(false);
            }
        }

        if (taxIdInputRef.current.value.length > 0) {
            if (!taxIdInputRef.current.value.match('^[a-zA-Z0-9]+$')) {
                taxIdInputRef.current.focus();
                setTaxIdInvalidInputError(true);
                return;
            }
            else {
                setTaxIdInvalidInputError(false);
            }
        }

        // if (categoryCode === '') {
        //     setCategoryCodeInputHasError(true);
        //     window.scrollTo({
        //         top: 1000,
        //         behavior: "smooth",
        //     });
        //     return;
        // }
        // else {
        //     setCategoryCodeInputHasError(false);
        // }

        // if (subCategoryCode === '') {
        //     setSubCategoryCodeInputHasError(true);
        //     window.scrollTo({
        //         top: 1000,
        //         behavior: "smooth",
        //     });
        //     return;
        // }
        // else {
        //     setSubCategoryCodeInputHasError(false);
        // }        

        if (props.loadedProfileDetailsUser.length === 0 && props.loadedProfileDetailsOffice.length === 0) {
            if (document.querySelector('#activeUser').checked === false) {
                //setActiveUserInputHasError(true);
                //return;
            }
            else {
                setActiveUserInputHasError(false);
            }
        }

        if (loginEmailIdInputRef.current.value.trim() === '') {
            loginEmailIdInputRef.current.focus();
            setLoginEmailIdInputHasError(true);
            setLoginEmailIdInvalidInputError(false);
            return;
        }
        else {
            setLoginEmailIdInputHasError(false);
        }

        if (!validateEmail(loginEmailIdInputRef.current.value)) {
            loginEmailIdInputRef.current.focus();
            setLoginEmailIdInvalidInputError(true);
            setLoginEmailIdInputHasError(false);
            return;
        }
        else {
            setLoginEmailIdInvalidInputError(false);
        }

        if (props.loadedProfileDetailsOffice.length === 0) {
            if (passwordInputRef.current.value.trim() === '') {
                passwordInputRef.current.focus();
                setPasswordInputHasError(true);
                setPasswordInvalidInputError(false);
                return;
            }
            else {
                setPasswordInputHasError(false);
            }

            if (!validatePassword(passwordInputRef.current.value)) {
                passwordInputRef.current.focus();
                setPasswordInvalidInputError(true);
                setPasswordInputHasError(false);
                return;
            }
            else {
                setPasswordInvalidInputError(false);
            }

            if (confirmPasswordInputRef.current.value.trim() === '') {
                confirmPasswordInputRef.current.focus();
                setConfirmPasswordInputHasError(true);
                setConfirmPasswordInvalidInputError(false);
                return;
            }
            else {
                setConfirmPasswordInputHasError(false);
            }

            if (!validatePassword(confirmPasswordInputRef.current.value)) {
                confirmPasswordInputRef.current.focus();
                setConfirmPasswordInvalidInputError(true);
                setConfirmPasswordInputHasError(false);
                return;
            }
            else {
                setConfirmPasswordInvalidInputError(false);
            }

            if (passwordInputRef.current.value !== confirmPasswordInputRef.current.value) {
                confirmPasswordInputRef.current.focus();
                setConfirmPasswordDoestNotMatchInputError(true);
                return;
            }
            else {
                setConfirmPasswordDoestNotMatchInputError(false);
            }
        }

        let user_captcha = document.getElementById('user_captcha_input').value;

        if (user_captcha === '') {
            document.querySelector('#captchaInputErrorMsg').innerHTML = "Captcha is required.";
            return;
        }
        else {
            document.querySelector('#captchaInputErrorMsg').innerHTML = "";
        }

        if (validateCaptcha(user_captcha) === true) {
            loadCaptchaEnginge(6, '#daeff8', 'black', 'numbers');
            document.getElementById('user_captcha_input').value = "";
            document.querySelector('#captchaInputErrorMsg').innerHTML = "";
        }
        else {
            document.querySelector('#captchaInputErrorMsg').innerHTML = "Please specify valid captcha.";
            document.getElementById('user_captcha_input').value = "";
            return;
        }


        let officeDetailsId;
        if (props.loadedProfileDetailsOffice === null || props.loadedProfileDetailsOffice.length === 0) {
            officeDetailsId = '';
        } else {
            officeDetailsId = props.loadedProfileDetailsOffice.id;
        }

        let userDetailsId;
        if (props.loadedProfileDetailsUser === null || props.loadedProfileDetailsUser.length === 0) {
            userDetailsId = '';
        } else {
            userDetailsId = props.loadedProfileDetailsUser.id;
        }

        let newStateCode = '';
        let newStateName = '';

        if (controlType === '') {
            if (stateCode !== '') {
                newStateCode = stateCode;
                newStateName = '';
            }
            else {
                newStateCode = '';
                newStateName = stateName;
            }
        }
        else {
            if (controlType === 'ddl') {
                newStateCode = stateCode;
                newStateName = '';
            }
            else {
                newStateCode = '';
                newStateName = stateName;
            }
        }

        props.onAddOffice({
            officeDetails: {
                id: officeDetailsId,
                traceID: JSON.parse(localStorage.getItem('traceID')),
                companyName: companyNameInputRef.current.value,
                address: address,
                countryCode: countryCode,
                stateCode: newStateCode,
                stateName: newStateName,
                cityCode: cityNameInputRef.current.value,
                postalCode: postalCodeInputRef.current.value,
                faxNumber: faxNumberInputRef.current.value,
                iataNumber: iataNumberInputRef.current.value,
                arcNumber: arcNumberInputRef.current.value,
                taxID: taxIdInputRef.current.value,
                categoryID: "1",
                subCategoryID: "4",
                isActive: active,
                iPAddress: localStorage.getItem("IPAddress"),
                parentOfficeID: process.env.SIGN_UP_OFFICEID,
                userID: JSON.parse(localStorage.getItem('userID')),
                // profileLocaterNumber: profileLocatorInputRef.current.value,
                // dkNumber: dkNumberInputRef.current.value,
                isCorporate: JSON.parse(localStorage.getItem('isTravelAgent')) === "1" ? true : false,
                isTravelAgent: getConfigurationByBoolen("ALLOW_OBT") ? "1" : "2",
                IsRegistraion: true
            },
            userDetails: {
                id: userDetailsId,
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: "",
                firstName: firstNameInputRef.current.value,
                middleName: middleNameInputRef.current.value,
                lastName: lastNameInputRef.current.value,
                loginEmailID: loginEmailIdInputRef.current.value,
                email: emailIdInputRef.current.value,
                password: await encrypted(passwordInputRef.current.value),
                phoneCode: countryDialCode,
                phoneNumber: phoneNumber,
                roleID: roleID,
                officeID: officeId,
                isActive: active
            }
        });
    }

    function changeLocation(event) {
        if (event.target.id === 'btnReset') {
            if (props.loadedProfileDetailsOffice.length === 0) {
                history.go('/SignUp')
            }
            else {
                history.go(`/SignUp/${props.loadedProfileDetailsOffice.id}`)
            }
        }
    }

    useEffect(() => {
        if (props.loadedProfileDetailsOffice.length === 0) {
            document.getElementById("btnSave").click();
            setCountryCode('US');
            //setCountryCode('IN');            
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [props.loadedProfileDetailsOffice.length]);

    function postalCodeChangeHandler(event) {
        //  postalCodeInputRef.current.value = event.target.value;
        setPostalCode(event.target.value);
        // if (event.target.value.trim() === '') {
        //     setPostalCodeInputHasError(false)
        //     if (document.getElementById('postalCodeError') !== null) {
        //         document.getElementById('postalCodeError').innerHTML = "";
        //     }
        // }
    }

    function cityNameChangeHandler(event) {

    }

    return (
        <Wrapper>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>
                                                        <form
                                                            className='form'>
                                                            <p><h4>{t('contactPersonDetails')}</h4></p><br></br>
                                                            <div id='image'></div>
                                                            <div className='row'>
                                                                <div className='col-md-4 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='firstName' className='h6 text-primary mb-2'>{t('firstName')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength={50} className="form-control form-control-sm" type='text' required
                                                                            id='firstName'
                                                                            ref={firstNameInputRef}
                                                                            onBlur={onBlurCheck}
                                                                            autoFocus
                                                                        />
                                                                        {firstNameInputHasError &&
                                                                            <p className="error-text">{t('validationFirstName')}</p>
                                                                        }
                                                                        {firstNameInvalidInputError &&
                                                                            <p className="error-text">{t('validationFirstNameInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='middleName' className='h6 text-primary mb-2'>{t('middleName')}</label>
                                                                        <input minLength={1} maxLength={50} className="form-control form-control-sm" type='text'
                                                                            id='middleName'
                                                                            ref={middleNameInputRef}
                                                                            onBlur={onBlurCheck}
                                                                            onFocus={hideLastNameValidation}
                                                                        />
                                                                        {middleNameInvalidInputError &&
                                                                            <p className="error-text">{t('validationMiddleNameInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='lastName' className='h6 text-primary mb-2'>{t('lastName')}<span className="error-icon"> *</span></label>
                                                                        <input minLength={2} maxLength={50} className="form-control form-control-sm" type='text' required
                                                                            id='lastName'
                                                                            ref={lastNameInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {lastNameInputHasError &&
                                                                            <p className="error-text">{t('validationLastName')}</p>
                                                                        }
                                                                        {lastNameInvalidInputError &&
                                                                            <p className="error-text">{t('validationLastNameInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-8 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='emailId' className='h6 text-primary mb-2'>{t('emailId')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength={100} className="form-control form-control-sm" type='text' required
                                                                            id='emailId'
                                                                            ref={emailIdInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {emailIdInputHasError &&
                                                                            <p className="error-text">{t('validationEmailId')}</p>
                                                                        }
                                                                        {emailIdInvalidInputError &&
                                                                            <p className="error-text">{t('validationEmailIdInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='phoneNumber' className='h6 text-primary mb-2 focus'>{t('phoneNumber')}<span className="error-icon"> *</span></label>
                                                                        <PhoneInput type='text' required
                                                                            country={'us'}
                                                                            value={countryDialCode + phoneNumber}
                                                                            onBlur={setPhone}
                                                                            //onBlur={onBlurCheck}
                                                                            placeholder=''
                                                                            // preferredCountries={preferredCountries}
                                                                            enableSearch={true}
                                                                            ref={phoneNumberInputRef}
                                                                            disabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        />
                                                                        {phoneNumberInputHasError &&
                                                                            <p className="error-text">{t('validationPhoneNumber')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <p><h4>{t('agencyDetails')}</h4></p><br></br>
                                                            <div className='col-md-12 col-sm-12'>
                                                                <div className='control input-placeholder'>
                                                                    <label htmlFor='companyName' className='h6 text-primary mb-2'>{t('companyName')}<span className="error-icon"> *</span></label>
                                                                    <input maxLength={100} className="form-control form-control-sm" type='text' required
                                                                        id='companyName'
                                                                        ref={companyNameInputRef}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    {companyNameInputHasError &&
                                                                        <p className="error-text">{t('validationCompanyName')}</p>
                                                                    }
                                                                    {companyNameInvalidInputError &&
                                                                        <p className="error-text">{t('validationCompanyNameInvalidData')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-md-12 col-sm-12'>
                                                                <div className='control input-placeholder'>
                                                                    <label htmlFor='address' className='h6 text-primary mb-2'>{t('address')}<span className="error-icon"> *</span></label>
                                                                    {/* <MultilineInput
                                                                        value={address}
                                                                        id="someId"
                                                                        onChange={(e) => setInputValue(e.target.value)}
                                                                    /> */}
                                                                    <textarea id='address'
                                                                        value={address}
                                                                        className="form-control form-control-sm"
                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                        rows={3} cols={100} maxLength={300}
                                                                        onBlur={onBlurCheck}
                                                                        ref={addressInputRef}
                                                                    />
                                                                    {addressInputHasError &&
                                                                        <p className="error-text">{t('validationAddress')}</p>
                                                                    }
                                                                    {addressInvalidInputError &&
                                                                        <p className="error-text">{t('validationAddressInvalidData')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}<span className="error-icon"> *</span></label>
                                                                        <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={iSDisabled} />
                                                                        {countryCodeInputHasError &&
                                                                            <p className="error-text">{t('validationCountryCode')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>{t('state')}<span className="error-icon"> </span></label>
                                                                        <StateDropDownList stateCodeValue={stateCodeValue} stateNameText={stateNameText} currentInputValue={stateCode} currentInputText={stateName} iSDisabled={iSDisabled} countryCode={countryCode} exposedControlType={exposedControlType} />
                                                                        {stateCodeInputHasError &&
                                                                            <p className="error-text">{t('validationStateCode')}</p>
                                                                        }
                                                                        {stateNameInputHasError &&
                                                                            <p className="error-text">{t('validationStateName')}</p>
                                                                        }
                                                                        {stateNameInvalidInputError &&
                                                                            <p className="error-text">{t('validationStateNameInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* User Story 4858: Change "City" drop-down to textbox */}
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength={100} className="form-control form-control-sm" type='text'
                                                                            id='cityName'
                                                                            ref={cityNameInputRef}
                                                                            onBlur={onBlurCheck}

                                                                        />
                                                                        {cityNameInputHasError &&
                                                                            <p className="error-text">{t('validationCityName')}</p>
                                                                        }
                                                                        {cityNameInvalidInputError &&
                                                                            <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='postalCode' className='h6 text-primary mb-2'>{t('postalCode')}</label>
                                                                        <input maxLength={12} className="form-control form-control-sm" type='text'
                                                                            id='postalCode'
                                                                            ref={postalCodeInputRef}
                                                                            onBlur={onBlurCheck}
                                                                            onChange={postalCodeChangeHandler}
                                                                        />
                                                                        {/* {postalCodeInputHasError &&
                                                                            <p className="error-text">{t('validationPostalCode')}</p>
                                                                        } */}
                                                                        {postalCodeInvalidInputError &&
                                                                            <p id="postalCodeError" className="error-text">{t('validationPostalCodeInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='faxNumber' className='h6 text-primary mb-2'>{t('faxNumber')}</label>
                                                                        <input maxLength={10} className="form-control form-control-sm" type='text'
                                                                            id='faxNumber'
                                                                            ref={faxNumberInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {faxNumberInvalidInputError &&
                                                                            <p className="error-text">{t('validationFaxNumberInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='iataNumber' className='h6 text-primary mb-2'>{t('iataNumber')}</label>
                                                                        <input maxLength={8} className="form-control form-control-sm" type='text'
                                                                            id='iataNumber'
                                                                            ref={iataNumberInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {iataNumberInvalidInputError &&
                                                                            <p className="error-text">{t('validationIataNumberInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-4 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='arcNumber' className='h6 text-primary mb-2'>{t('arcNumber')}</label>
                                                                        <input maxLength={8} className="form-control form-control-sm" type='text'
                                                                            id='arcNumber'
                                                                            ref={arcNumberInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {arcNumberInvalidInputError &&
                                                                            <p className="error-text">{t('validationArcNumberInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-8 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='taxId' className='h6 text-primary mb-2'>{t('taxId')}</label>
                                                                        <input maxLength={20} className="form-control form-control-sm" type='text'
                                                                            id='taxId'
                                                                            ref={taxIdInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {taxIdInvalidInputError &&
                                                                            <p className="error-text">{t('validationTaxIdInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row d-none'>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>{t('category')}<span className="error-icon"> *</span></label>
                                                                        <Dropdown onChange={categoryChangeHandler}
                                                                            value={categoryOptions.filter(option => option.value === categoryCode)}
                                                                            options={categoryOptions}
                                                                            textField="listValue"
                                                                            dataItemKey="listID"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                            isDisabled={iSCategoryDisabled !== undefined ? iSCategoryDisabled === "true" ? true : false : false}
                                                                        />
                                                                        {categoryCodeInputHasError &&
                                                                            <p className="error-text">{t('validationCategoryCode')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='subCategory'>{t('subCategory')}<span className="error-icon"> *</span></label>
                                                                        <Dropdown onChange={subCategoryChangeHandler}
                                                                            value={subCategoryOptions.filter(option => option.value === subCategoryCode)}
                                                                            options={subCategoryOptions}
                                                                            textField="listValue"
                                                                            dataItemKey="listID"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                            isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                                                                        />
                                                                        {subCategoryCodeInputHasError &&
                                                                            <p className="error-text">{t('validationSubCategoryCode')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row d-none'>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='profileLocator' className='h6 text-primary mb-2'>{t('profileLocator')}</label>
                                                                        <input maxLength={100} className="form-control form-control-sm" type='text' required
                                                                            id='profileLocator'
                                                                            ref={profileLocatorInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />

                                                                        {profileLocatorInvalidInputError &&
                                                                            <p className="error-text">{t('validationProfileLocatorInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <label htmlFor='dkNumber' className='h6 text-primary mb-2'>{t('dkNumber')}<span className="error-icon"> *</span></label>
                                                                        <input minLength={6} maxLength={10} className="form-control form-control-sm" type='text' required
                                                                            id='dkNumber'
                                                                            ref={dkNumberInputRef}
                                                                            onBlur={onBlurCheck}
                                                                        />
                                                                        {dkNumberInputHasError &&
                                                                            <p className="error-text">{t('validationDKNumber')}</p>
                                                                        }

                                                                        {dkNumberInvalidInputError &&
                                                                            <p className="error-text">{t('validationDKNumberInvalidData')}</p>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='row d-none'>
                                                                <div className='col-md-6 col-sm-12'>
                                                                    <div className='control input-placeholder'>
                                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                                            <label htmlFor="activeUser" className="mb-2 h6 text-primary col-lg-12">{t('active')}</label>
                                                                            <input type="checkbox" onChange={activeChangeHandler} className="form-check-input ms-1" checked={active} value={active} ref={activeInputRef} id="activeUser" />
                                                                            {activeUserInputHasError &&
                                                                                <p className="error-text">{t('validationActiveUser')}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className='mt-2'><h4>{t('loginDetails')}</h4></p><br></br>
                                                            <div className='col-md-12 col-sm-12'>
                                                                <div className='control input-placeholder'>
                                                                    <label htmlFor='loginEmailId' className='h6 text-primary mb-2'>{t('loginEmailId')}<span className="error-icon"> *</span></label>
                                                                    <input maxLength={100} className="form-control form-control-sm" type='text' required
                                                                        id='loginEmailId'
                                                                        ref={loginEmailIdInputRef}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    {loginEmailIdInputHasError &&
                                                                        <p className="error-text">{t('validationLoginEmailId')}</p>
                                                                    }
                                                                    {loginEmailIdInvalidInputError &&
                                                                        <p className="error-text">{t('validationLoginEmailIdInvalidData')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row' id='dvPasswordArea'>
                                                                <div className='control col-md-6 col-sm-12'>
                                                                    <div className=' input-placeholder input-group position-relative'>
                                                                        <label htmlFor='password' className='h6 text-primary mb-2 col-12 col-lg-12'>{t('password')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength={30} className=" col-lg-12 form-control form-control-sm col-sm-12" type='password' required
                                                                            id='password'
                                                                            ref={passwordInputRef}
                                                                            onBlur={onBlurCheck}
                                                                            autocomplete="new-password"

                                                                        />

                                                                        <span className="input-group-text w-50px h-50px">
                                                                            <i className="fa fa-eye-slash text-primary" id="togglePassword"></i>
                                                                        </span>

                                                                        <ReactTooltip
                                                                            anchorId="password"
                                                                            place="bottom"
                                                                            variant="info"
                                                                            className="tooltip"
                                                                            html={t('passwordHint')}
                                                                        // content={t('passwordHint')}
                                                                        />
                                                                    </div>
                                                                    {passwordInputHasError &&
                                                                        <p className="error-text col-lg-12">{t('validationPassword')}</p>
                                                                    }
                                                                    {passwordInvalidInputError &&
                                                                        <p className="error-text col-lg-12">{t('validationPasswordInvalidData')}</p>
                                                                    }
                                                                </div>
                                                                <div className='control col-md-6 col-sm-12'>
                                                                    <div className=' input-placeholder input-group position-relative col-lg-12'>
                                                                        <label htmlFor='confirmPassword' className='h6 text-primary mb-2 col-lg-12 col-12'>{t('confirmPassword')}<span className="error-icon"> *</span></label>
                                                                        <input maxLength={30} className="form-control form-control-sm col-lg-12 col-sm-12" type='password' required
                                                                            id='confirmPassword'
                                                                            ref={confirmPasswordInputRef}
                                                                            onBlur={onBlurCheck}
                                                                            autocomplete="new-password"

                                                                        />
                                                                        <span className="input-group-text w-50px h-50px">
                                                                            <i className="fa fa-eye-slash text-primary" id="togglePassword3"></i>
                                                                        </span>

                                                                        <ReactTooltip
                                                                            anchorId="confirmPassword"
                                                                            place="bottom"
                                                                            variant="info"
                                                                            className="tooltip"
                                                                            html={t('passwordHint')}
                                                                        // content={t('passwordHint')}
                                                                        />
                                                                    </div>
                                                                    {confirmPasswordInputHasError &&
                                                                        <p className="error-text col-lg-12">{t('validationConfirmPassword')}</p>
                                                                    }
                                                                    {confirmPasswordInvalidInputError &&
                                                                        <p className="error-text col-lg-12">{t('validationConfirmPasswordInvalidData')}</p>
                                                                    }
                                                                    {confirmPasswordDoestNotMatchInputError &&
                                                                        <p className="error-text col-lg-12">{t('validationConfirmPasswordDoestNotMatch')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6 col-sm-12 mb-3'>
                                                                    <label htmlFor='user_captcha_input' className='h6 text-primary mb-2'>{t('Captcha')}<span className="error-icon"> *</span></label>
                                                                    <input className='form-control form-control-sm' placeholder='Enter numbers shown in image' id="user_captcha_input" name="user_captcha_input" type="text"></input>
                                                                    <span className="error-text" id='captchaInputErrorMsg'></span>
                                                                </div>
                                                                <div className='col-md-6 col-sm-12 mt-3'>
                                                                    <div className='position-relative mt-3'>
                                                                        <LoadCanvasTemplate reloadText='<i id="ancRefreshCaptcha" class="fa fa-refresh fa-lg"></i>' reloadColor="#12688B" />
                                                                        <ReactTooltip
                                                                            anchorId="ancRefreshCaptcha"
                                                                            place="bottom"
                                                                            variant="info"
                                                                            className="tooltip"
                                                                            html={t('Refresh Captcha')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href={getLoginUrl()} className='btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth ms-1 d-none d-sm-block d-sm-none d-md-block'><span></span>Back to Login</a>

                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                                                    <div className="float-end">
                                                                        {/* <a href="/login" className="btn gt-btn_md text-white gt-main-btn-WithoutAuth ms-1 mt-1 d-md-none d-lg-none d-xl-none">Back</a> */}

                                                                        <a href={getLoginUrl()} className='btn back-btn-sm text-white gt-main-btn-WithoutAuth mt-1  ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>


                                                                        <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white gt-main-btn-WithoutAuth ms-1 mt-1  '>{t('Submit')}</button>

                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white gt-main-btn-WithoutAuth ms-1 mt-1  '>{t('reset')}</button>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default SignUpForm
import { useState, useRef, useEffect } from "react";
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import Dropdown from 'react-select'
import { useTranslation } from "react-i18next";
import useHttp from "../../../../services/use-http";
import { geteCommonListtByName } from "../../../../services/Content-API";
import { searchContentWiseRegion } from "../../../../services/Fees-API";
import { useHistory } from "react-router-dom";
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { searchCommonList } from "../../../../services/Fee-API";
import { searchOfficeList } from '../../../../services/Office-API'

const FeesForm = (props) => {
    const isTravelAgent = JSON.parse(localStorage.getItem('isTravelAgent'));

    const CabinTypeOption = [
        { value: "0", label: "All" },
        { value: "Economy", label: "Economy" },
        { value: "Premium Economy", label: "Premium Economy" },
        { value: "Business", label: "Business" },
        { value: "First", label: "First" },
    ];

    const PosTypeOption = [
        { value: "0", label: "All" },
        { value: "B2B", label: "B2B" },
        { value: "API", label: "API" },
    ];

    const MarupOption = [
        { value: "percentage", label: "Percentage" },
        { value: "amount", label: "Amount" },
    ];
    //constant
    const { t } = useTranslation(['translation.Fees']);
    const { sendRequest: sendServiceList, data: loadedServiceList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendProviderList, data: loadedProviderList } = useHttp(searchCommonList)
    const { sendRequest: sendToRegionList, data: loadedToRegionList, status: toRegionStatus } = useHttp(searchContentWiseRegion)
    const { sendRequest: sendFromRegionList, data: loadedFromRegionList, status: fromRegionStatus } = useHttp(searchContentWiseRegion)
    let ToRegionOptions = [];
    let FromRegionOptions = [];

    const history = useHistory();

    //State
    const [markupType, setMarkupType] = useState('');
    const [markupTypeLabel, setMarkupTypeLabel] = useState('');
    const [markup, setMarkup] = useState("");
    const [pageTitle, setPageTitle] = useState(" Fee");
    const [providerValue, setProviderValue] = useState("");
    const [serviceValue, setServiceValue] = useState("");
    const [fareLabel, setFareLabel] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [isDropdownDisable, setIsDropdownDisable] = useState(false);
    const [toRegionValue, setToRegionValue] = useState('-000');
    const [toRegionTouched, setToRegionTouched] = useState(false);
    const [fromRegionTouched, setFromRegionTouched] = useState(false);
    const [fromRegionValue, setFromRegionValue] = useState('-000');
    const [amount, setAmount] = useState("");
    const [fareType, setFareType] = useState("0");
    const [cabinType, setCabinType] = useState("0");
    const [paxType, setPaxType] = useState("0");
    const [posType, setPosType] = useState("0");
    const [selectedService, setSelectedService] = useState("FL");
    const [selectedCabin, setSelectedCabin] = useState("FL");
    //useRef
    const inputPasswordRef = useRef('');
    const markupInputRef = useRef('');
    const inputEprRef = useRef('');
    const inputPccRef = useRef('');

    //Error State
    // const [passwordHasError, setPasswordHasError] = useState(false);
    const [markupTypeBlankError, setMarkupTypeBlankError] = useState(false);
    const [providerBlankError, setProviderBlankError] = useState(false);
    const [serviveBlankError, setServiceBlankError] = useState(false);
    const [fareTypeBlankError, setFareTypeBlankError] = useState(false)
    const [markupInputError, setMarkupInputError] = useState(false)
    const [markupLengthError, setMarupLengthError] = useState(false)
    const [markupBlankError, setMarkupBlankError] = useState(false)

    const [fromRegionBlankError, setFromRegionBlankErr] = useState(false);
    const [toRegionBlankError, setToRegionBlankErr] = useState(false);
    const [percenatgeError, setPercentageError] = useState(false);
    const [active, setActive] = useState(true);

    const [companyNameError, setCompanyNameError] = useState(false);

    const FareOption = providerValue !== 'LCC' ? [
        { value: "0", label: "All" },
        { value: "Mixed", label: "Mixed" },
        { value: "Negotiated", label: "Negotiated" },
        { value: "Private", label: "Private" },
        { value: "Published", label: "Published" },
        { value: "Special", label: "Special" }
    ] : [
        { value: "0", label: "All" }
    ];

    const PaxTypeOption = providerValue !== 'LCC' ? [
        { value: "0", label: "All" },
        { value: "ADT", label: "Adult" },
        { value: "CNN", label: "Child" },
        { value: "INF", label: "Lap Infant" },
        { value: "INS", label: "Seat Infant" }
    ] : [
        { value: "0", label: "All" },
        { value: "ADT", label: "Adult" },
        { value: "CNN", label: "Child" },
        { value: "INF", label: "Lap Infant" }
    ];

    useEffect(() => {
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });
    })
    useEffect(() => {
        if (props.loadedFeesDetails.length === 0) {
            document.getElementById("btnSave").click();

        }
    }, [props.loadedFeesDetails.length]);
    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));


        if (props.loadedFeesDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (props.loadedFeesDetails.length !== 0) {


            markupInputRef.current.value = props.loadedFeesDetails.markup;
            if (props.loadedFeesDetails.serviceID === "20") {
                setSelectedService("FL");
                setSelectedCabin("FL");
            } else {
                setSelectedService("HT");
                setSelectedCabin("HT");
            }

            if (providerValue === '') {

                if (props.loadedFeesDetails.serviceID === '20') {
                    const FlightProviderList = ({
                        "methodName": "FeeProvider",
                        "methodCode": "FEEPRO",
                        "listID": "",
                        "listValue": "",
                        "subListID": "21",
                        "officeID": ""
                    });
                    sendProviderList(FlightProviderList)

                } else {
                    const HotelProviderList = ({
                        "methodName": "FeeProviderHotel",
                        "methodCode": "FEEPROHOTEL",
                        "listID": "",
                        "listValue": "",
                        "subListID": "20",
                        "officeID": ""
                    });
                    sendProviderList(HotelProviderList)
                }
                setProviderValue(props.loadedFeesDetails.providerID)
            }
            if (companyNameInput === '') {
                setCompanyNameInput(props.loadedFeesDetails.officeID)
            }
            if (serviceValue === '') {
                setServiceValue(props.loadedFeesDetails.serviceID)
                const onToRegionSearch = (
                    {

                        serviceID: props.loadedFeesDetails.serviceID,
                        officeID: JSON.parse(localStorage.getItem('officeID')),
                        regionType: "to"
                    }
                );
                const onFromRegionSearch = (
                    {

                        serviceID: props.loadedFeesDetails.serviceID,
                        officeID: JSON.parse(localStorage.getItem('officeID')),
                        regionType: "from"
                    }
                );
                sendToRegionList(onToRegionSearch);
                sendFromRegionList(onFromRegionSearch)

            }

            if (markupType === '') {
                setMarkupType(props.loadedFeesDetails.markupType)
            }
            if (markupTypeLabel === '') {
                setMarkupTypeLabel(props.loadedFeesDetails.markupType)
            }


            if (toRegionTouched === false || toRegionValue === '') {
                setToRegionValue(props.loadedFeesDetails.toRegion);
            }
            if (selectedService === "FL" && (fromRegionTouched === false || fromRegionValue === '')) {
                setFromRegionValue(props.loadedFeesDetails.fromRegion);
            } else {
                setFromRegionValue("-000");
            }

            setPosType(props.loadedFeesDetails.pos);
            if (selectedService === "FL") {
                setCabinType(props.loadedFeesDetails.cabinClass);
                setPaxType(props.loadedFeesDetails.paxType);
                setFareType(props.loadedFeesDetails.fareTypeID);
            }
            else {
                setCabinType("0");
                setPaxType("0");
                setFareType("0");
            }
            setIsDisable(false);
            setIsDropdownDisable(false);


        }

        if (props.loadedFeesDetails.length === 0) {

            setIsDisable(false);
            setIsDropdownDisable(false);
            document.getElementById('btnNew').style.display = 'none';
            setPageTitle(" New Fee");
            sessionStorage.setItem('isEditable', '100');
        }




        //View Page
        if (isEditMode === 0) {
            document.getElementById("markup").readOnly = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            [].forEach.call(document.querySelectorAll('password'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
            setPageTitle(" View Fee");


            setIsDisable(true);
            setIsDropdownDisable(true);

            //document.getElementById("dvPasswordArea").style.display = "none";
        }
        else if (isEditMode === 1) {

            document.getElementById("markup").readOnly = false;
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnReset").style.display = "";


            document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            setPageTitle(" Edit Fee");
            //   document.getElementById("dvPasswordArea").style.display = "none";
            setIsDisable(true);
            setIsDropdownDisable(false);
        }

    }, [props.loadedFeesDetails.length,

    props.loadedFeesDetails.providerID,
    props.loadedFeesDetails.serviceID,
    props.loadedFeesDetails.serviceName,
    props.loadedFeesDetails.providerName,
    props.loadedFeesDetails.markup,
    props.loadedFeesDetails.markupType,
    props.loadedFeesDetails.toRegion,
    props.loadedFeesDetails.fromRegion,
    props.loadedFeesDetails.fareType,

    ])

    //Code for get company List
    const [companyNameInput, setCompanyNameInput] = useState('');
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }
    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
    }
    //End


    const markupchangehandler = (event) => {
        setMarkup(event.target.value);

    };
    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };
    const fareChangeHandler = (event) => {
        setFareType(event.value)
        setFareLabel(event.label);
    }
    const markupTypeChangeHandler = (event) => {
        setMarkupType(event.value);
        setMarkupTypeLabel(event.label);
    }
    const posTypeChangeHandler = (event) => {
        setPosType(event.value);
    }
    const cabinTypeChangeHandler = (event) => {
        setCabinType(event.value);
    }
    const paxTypeTypeChangeHandler = (event) => {
        setPaxType(event.value);
    }
    function providerChangeHandler(event) {
        setProviderValue(event.value);
        setProviderBlankError(false);
        setPaxType("0");
        setFareType("0");
    }
    function getDataArray(json) {

        const dataArray = [];
        if (json !== null && json.errors && json.errors.status === "FALSE" && json.data && json.data.length > 0) {
            json.data.forEach(item => {
                const { regionID, regionName } = item;

                dataArray.push({
                    label: regionName,
                    value: regionID
                });
            });
        }

        return dataArray;
    }
    function serviceChangeHandler(event) {
        setServiceValue(event.value);
        setProviderValue('');
        setServiceBlankError(false);
        if (event.label === 'Hotel') {

            const HotelProviderList = ({
                "methodName": "FeeProviderHotel",
                "methodCode": "FEEPROHOTEL",
                "listID": "",
                "listValue": "",
                "subListID": "20",
                "officeID": ""
            });
            sendProviderList(HotelProviderList)

        }
        if (event.label === 'Flight') {
            const FlightProviderList = ({
                "methodName": "FeeProvider",
                "methodCode": "FEEPRO",
                "listID": "",
                "listValue": "",
                "subListID": "21",
                "officeID": ""
            });
            sendProviderList(FlightProviderList)

        }

        const onToRegionSearch = (
            {
                serviceID: event.value,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                regionType: "to"
            }
        );
        const onFromRegionSearch = (
            {
                serviceID: event.value,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                regionType: "from"
            }
        );
        sendToRegionList(onToRegionSearch);
        sendFromRegionList(onFromRegionSearch);

        if (event.value == 20) {
            setSelectedService("FL");
            setSelectedCabin("FL");
        }
        else {
            setSelectedService("HT");
            setSelectedCabin("HT");
        }

    }
    function toRegionChangeHandler(event) {
        setToRegionValue(event.value);
        setToRegionTouched(true);


    }
    function fromRegionChangeHandler(event) {
        setFromRegionValue(event.value);
        setFromRegionTouched(true);

    }

    useEffect(() => {
        sendServiceList('service');
    }, [sendServiceList]);

    if (toRegionStatus === 'completed') {
        ToRegionOptions = getDataArray(loadedToRegionList);
    }
    if (fromRegionStatus === 'completed') {
        FromRegionOptions = getDataArray(loadedFromRegionList);
    }


    function objectToArray(data, resultArray) {
        let arrayData = [];
        for (let i in data) {
            if (data['errors'].status === 'FALSE') {
                if (i === 'data') {
                    arrayData = data[i];
                }
            }
        }
        for (let index = 0; index < arrayData.length; index++) {
            resultArray = [...resultArray, { value: arrayData[index].listID, label: arrayData[index].listValue }]
        }
        return resultArray;

    }

    let serviceOption = [];
    serviceOption = objectToArray(loadedServiceList, serviceOption);

    let providerOption = [];
    providerOption = objectToArray(loadedProviderList, providerOption);

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            if (props.loadedFeesDetails.length === 0) {
                history.go('/UpdateFees')
            }
            else {
                history.push('/UpdateFees')
                history.go();
            }
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedFeesDetails.length === 0) {

                history.go('/UpdateFees');
            }
            else {

                history.go(`/UpdateFees/${props.loadedFeesDetails.id}`);
            }
        }

    }
    function onBlurCheck(event) {
        const enterdmarkupValue = markupInputRef.current.value;
        const enteredMarkup = enterdmarkupValue.trim();
        if (event.target.id !== 'btnReset') {
            if (isTravelAgent === "1") {
                if (companyNameInput === '' || companyNameInput === undefined) {
                    setCompanyNameError(true);
                    return;
                }
                else {
                    setCompanyNameError(false);
                }
            }

            if (serviceValue === '' || serviceValue === undefined) {
                setServiceBlankError(true);
                return;
            }
            else {
                setServiceBlankError(false);
            }
            if (providerValue === '' || providerValue === undefined) {
                setProviderBlankError(true);

                return;
            }
            else {
                setProviderBlankError(false);
            }
            if (fromRegionValue === '' || fromRegionValue === undefined) {
                setFromRegionBlankErr(true);
                return;
            } else {
                setFromRegionBlankErr(false);
            }
            if (toRegionValue === '' || toRegionValue === undefined) {
                setToRegionBlankErr(true);
                return;
            } else {
                setToRegionBlankErr(false);
            }
            if (markupType === '') {
                setMarkupTypeBlankError(true);
                return;
            } else {
                setMarkupTypeBlankError(false);
            }
            if (enteredMarkup === '') {

                setMarkupBlankError(true);
                setMarkupInputError(false);
                setMarupLengthError(false);
                return;
            }
            else {
                setMarkupBlankError(false);
            }
            if (!enteredMarkup.match('^[0-9]*$')) {
                setMarkupInputError(true);
                setMarkupBlankError(false);
                setMarupLengthError(false);
                return;
            } else {
                setMarkupInputError(false);
            }
            if (markupTypeLabel?.toUpperCase() === "PERCENTAGE") {
                if (enteredMarkup > 100) {
                    setPercentageError(true);
                    setMarkupBlankError(false);
                    setMarkupInputError(false);

                } else {
                    setPercentageError(false);
                }
            }
            if (fareType === '' || fareType === undefined) {
                setFareTypeBlankError(true);
                return;
            } else {
                setFareTypeBlankError(false);
            }

        }
    };
    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    function submitFormHandler(event) {
        event.preventDefault();
        const enterdmarkupValue = markupInputRef.current.value;
        const enteredMarkup = enterdmarkupValue.trim();
        if (isTravelAgent === "1") {
            if (companyNameInput === '' || companyNameInput === undefined) {
                setCompanyNameError(true);

                window.scrollTo({
                    top: 120,
                    behavior: "smooth",
                });
                return;
            }
            else {
                setCompanyNameError(false);
            }
        }
        if (serviceValue === '' || serviceValue === undefined) {
            setServiceBlankError(true);

            window.scrollTo({
                top: 120,
                behavior: "smooth",
            });
            return;
        }
        else {
            setServiceBlankError(false);
        }
        if (providerValue === '' || providerValue === undefined) {
            setProviderBlankError(true);
            window.scrollTo({
                top: 120,
                behavior: "smooth",
            });
            return;
        }
        else {
            setProviderBlankError(false);
        }

        if ((fromRegionValue === '' || fromRegionValue === undefined)) {
            setFromRegionBlankErr(true);
            return;
        } else {
            setFromRegionBlankErr(false);
        }
        if ((toRegionValue === '' || toRegionValue === undefined)) {
            setToRegionBlankErr(true);
            return;
        } else {
            setToRegionBlankErr(false);
        }
        if (markupType === '' || markupType === undefined) {
            setMarkupTypeBlankError(true);
            return;
        } else {
            setMarkupTypeBlankError(false);
        }
        if (enteredMarkup === '') {
            markupInputRef.current.focus();
            setMarkupBlankError(true);
            setMarkupInputError(false);
            setMarupLengthError(false);
            return;
        }
        else {
            setMarkupBlankError(false);
        }
        if (!enteredMarkup.match('^[0-9]*$')) {
            markupInputRef.current.focus();
            setMarkupInputError(true);
            setMarkupBlankError(false);
            setMarupLengthError(false);
            return;
        } else {
            setMarkupInputError(false);
        }

        if (markupTypeLabel?.toUpperCase() === 'PERCENTAGE') {
            if (enteredMarkup > 100) {
                markupInputRef.current.focus();
                setPercentageError(true);
                setMarkupBlankError(false);
                setMarkupInputError(false);
                return;

            } else {
                setPercentageError(false);
            }
        }
        if (fareType === '' || fareType === undefined) {
            setFareTypeBlankError(true);
            return;
        } else {
            setFareTypeBlankError(false);
        }
        let ID;
        if (props.loadedFeesDetails === null || props.loadedFeesDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedFeesDetails.id;
        }
        const feesInput = {
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            // "createdDate": "2023-06-02T05:39:33.788Z",
            //"ipAddress": "string",
            serviceID: serviceValue,
            serviceName: "",
            providerID: providerValue,
            providerName: "",
            officeID: JSON.parse(localStorage.getItem('isTravelAgent')) === "1" ? companyNameInput : JSON.parse(localStorage.getItem('officeID')),
            fromRegion: fromRegionValue,
            toRegion: toRegionValue,
            markupType: markupType,
            markup: markupInputRef.current.value,
            fareTypeID: fareType,
            fareValue: fareLabel,
            cabinClass: cabinType,
            paxType: paxType,
            pos: posType,
            isActive: active,
            status: 0
        };


        props.onAddFees(
            feesInput
        );


    };

    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>

                                                    <form
                                                        className='form'>
                                                        {props.isLoading && (
                                                            <div className='loading'>
                                                                <LoadingSpinner />
                                                            </div>
                                                        )}
                                                        {isTravelAgent === "1" && <div className='row'>
                                                            <div className='col-md-12 col-sm-12 control'>
                                                                <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}<span className="error-icon"> *</span></label>
                                                                <Dropdown onChange={comapnyNameChaneHandler}
                                                                    value={companyOptions.filter(option => option.value === companyNameInput)}
                                                                    options={companyOptions}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                    isDisabled={isDisable}

                                                                />
                                                                {companyNameError && <p className="error-text">{t('companyNameBlankValidation')}</p>}
                                                            </div>
                                                        </div>}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('serviceLabel')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        value={serviceOption.filter(option => option.value === serviceValue)}
                                                                        options={serviceOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        onBlur={onBlurCheck}
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        onChange={serviceChangeHandler}
                                                                        isDisabled={isDisable}
                                                                        autoFocus={isTravelAgent !== "1" && true}
                                                                    />
                                                                    {serviveBlankError && <p className="error-text">{t('serviceBlankValidation')}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="provider" className="mb-2 h6 text-primary col-lg-12">{t('provider')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={providerChangeHandler}
                                                                        value={providerOption.filter(option => option.value === providerValue)}
                                                                        options={providerOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        onBlur={onBlurCheck}
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDisable}
                                                                    />
                                                                    {providerBlankError && <p className="error-text">{t('providerBlankValidation')}</p>}

                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="row">
                                                            <div className={selectedService == "FL" && "col-md-6"}>
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('fromRegion')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={fromRegionChangeHandler}
                                                                        value={FromRegionOptions.filter(option => option.value === fromRegionValue)}
                                                                        options={FromRegionOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    {fromRegionBlankError && <p className="error-text">{t('fromRegionBlankValidation')}</p>}
                                                                </div>
                                                            </div>

                                                            {selectedService == "FL" && <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('toRegion')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={toRegionChangeHandler}
                                                                        value={ToRegionOptions.filter(option => option.value === toRegionValue)}
                                                                        options={ToRegionOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    {toRegionBlankError && <p className="error-text">{t('toRegionBlankValidation')}</p>}
                                                                </div>
                                                            </div>}

                                                        </div>

                                                        <div className="row">
                                                            {selectedService == "FL" && <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('paxType')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={paxTypeTypeChangeHandler}
                                                                        value={PaxTypeOption.filter(option => option.value === paxType)}
                                                                        options={PaxTypeOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}
                                                                    />

                                                                </div>
                                                            </div>}
                                                            <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('markupType')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={markupTypeChangeHandler}
                                                                        value={MarupOption.filter(option => option.value === markupType)}
                                                                        options={MarupOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    {markupTypeBlankError && <p className="error-text">{t('markupTypeBlankValidation')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className={selectedService == "FL" ? "col-md-4" : "col-md-8"}>
                                                                <div className='control'>

                                                                    <label htmlFor="markup" className="mb-2 h6 text-primary col-lg-12"> {t('markup')}<span className="error-icon"> *</span></label>
                                                                    <input type='number' id='markup' maxLength={10} className="form-control form-control-sm col-lg-12" ref={markupInputRef} onChange={markupchangehandler} min="0" onBlur={onBlurCheck} onKeyDown={preventActions} onPaste={preventPasteNegative} />
                                                                    {markupBlankError && <p className="error-text">{t('markupBlankValidation')}</p>}
                                                                    {markupInputError && <p className="error-text">{t('markupInputValidation')}</p>}
                                                                    {percenatgeError && <p className="error-text">{t('markupPercenatgeValidation')}</p>}


                                                                </div>
                                                            </div>
                                                            {markupType == "percentage" && selectedService == "FL" && <div className="row my-2">
                                                                <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span> Markup will be applied on Base Fare.</span>
                                                            </div>}
                                                            {markupType == "percentage" && selectedService == "HT" && <div className="row my-2">
                                                                <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span> Markup will be applied on Total Rate.</span>
                                                            </div>}
                                                        </div>
                                                        <div className="row">
                                                            <div className={selectedCabin == "FL" ? "col-md-4" : "col-md-12"} >
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('posType')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={posTypeChangeHandler}
                                                                        value={PosTypeOption.filter(option => option.value === posType)}
                                                                        options={PosTypeOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}

                                                                    />
                                                                    {fareTypeBlankError && <p className="error-text">{t('fareTypeBlankValidation')}</p>}
                                                                </div>
                                                            </div>
                                                            {selectedCabin == "FL" && <div className="col-md-4"   >
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('fareType')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={fareChangeHandler}
                                                                        value={FareOption.filter(option => option.value === fareType)}
                                                                        options={FareOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}

                                                                    />
                                                                    {fareTypeBlankError && <p className="error-text">{t('fareTypeBlankValidation')}</p>}
                                                                </div>
                                                            </div>}

                                                            {selectedCabin == "FL" && <div className="col-md-4">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('cabinType')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={cabinTypeChangeHandler}
                                                                        value={CabinTypeOption.filter(option => option.value === cabinType)}
                                                                        options={CabinTypeOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                </div>
                                                            </div>}
                                                        </div>
                                                        {
                                                            selectedCabin === "FL" && cabinType !== '0' &&
                                                            <div className="col-md-12">
                                                                <div class="text-black fw-bold mb-2"><i className="fas fa-info-circle"></i> Note : </div>
                                                                <div class="h6 text-primary"><i class="fa-solid fa-circle fa-solid fa-circle text-14px me-1"></i> In the case of mixed cabins, fees will be based on the highest cabin class.</div>
                                                                <div class="h6 text-primary"><i class="fa-solid fa-circle fa-solid fa-circle text-14px me-1"></i> If the base currency and flight currency are different, Fee "Percentage" will be applied. Fee "Amount" will not be applied.</div>
                                                            </div>
                                                        }
                                                        {
                                                            (selectedCabin !== "FL" || cabinType === '0') &&
                                                            <div className="col-md-12">
                                                                <span className="h6 text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span>
                                                                <span className="ms-1 text-primary h6">If the base currency and flight currency are different, Fee "Percentage" will be applied. Fee "Amount" will not be applied.</span>
                                                            </div>
                                                        }

                                                        <p className="mt-2"><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/SearchFees" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchFees" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                    {(checkSecurityDetails("Master", "Fee", "Master_Fee_Insert") || checkSecurityDetails("Master", "Fee", "Master_Fee_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                            {t('save')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    {checkSecurityDetails("Master", "Fee", "Master_Fee_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper >
};
export default FeesForm;
import React from "react";
import ReactDOM from "react-dom";

import Button from "../../components/Common/Button/Button";


const Backdrop = (props) => {
    return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {

    let showOKButton = true;
    let isForNonAuth = false;
    if (props.showOKButton !== undefined) {
        showOKButton = props.showOKButton;
    }

    if (props.isForNonAuth !== undefined) {
        isForNonAuth = props.isForNonAuth;
    }

    return (
        <div className='modalcard modal modal-top'>
            <header className={isForNonAuth ? 'header gt-main-btn-WithoutAuth-MFA' : 'header'}>
                {/* <h4>{props.title}</h4> */}
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                        <h4 className="text-white">{props.title}</h4>

                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={props.onConfirm}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
                </div>
            </header>
            <div className='content'>
                <p>{props.message}</p>
            </div>
            {showOKButton &&
                <footer className='actions float-end px-3 py-2'>
                    <Button onClick={props.onConfirm}
                        isForNonAuth={isForNonAuth}
                    >
                        OK
                    </Button>
                </footer>
            }
        </div>
    );
};

const MdModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClick={props.onConfirm}></Backdrop>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                    title={props.title}
                    message={props.message}
                    onConfirm={props.onConfirm}
                    showOKButton={props.showOKButton}
                    isForNonAuth={props.isForNonAuth}
                ></ModalOverlay>,
                document.getElementById("overlay-root")
            )}
        </React.Fragment>
    );
};
export default MdModal;

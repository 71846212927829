import { Fragment } from 'react';
import Guest from '../Components/SubComponents/Guest'
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
const GuestInformation = (props) => {
    return <Fragment>
        <div className="accordion-item">
            <h5 className="accordion-header text-sky-blue fw-bold mb-3" >
                Guest
            </h5>
            <div 
                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <div className='row'>
                        <div className='col-md-6'>

                        </div>
                        <div className='col-md-12'>
                           <div className='table-responsive'>
                            <table className="table table-bordered sm-first-col-width mb-0">
                                    <thead style={{background:getCustomizeDetails('buttonColor')}}>
                                        <tr>
                                            <th className='w-15' scope='col'>Room No.</th>
                                            <th scope="col">Name</th>
                                            {/* <th scope="col">Pax Type</th> */}
                                            {/* <th scope="col">Age(yrs)</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                    {props.hotelBookingJSON.data.travelerInfo.guestInfo.map((guestInfo) =>
                                    <Guest  key={guestInfo.roomIndex}
                                    id={guestInfo.roomIndex}
                                    guestInfo={guestInfo}></Guest>)}
                                    </tbody>
                                </table>
                           </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Fragment>

};

export default GuestInformation;
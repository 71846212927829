import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import useHttp from '../../../../services/use-http';
import { getRetrieveHotel } from '../../../../services/Hotel-API';
import { useHistory } from "react-router-dom";
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

const HotelBookingItem = (props) => {
    const history = useHistory();
    const { sendRequest: sendRequestRetrieveHotel, status: statusRetrieveHotel, data: loadedRetrieveHotel, error: errorRetrieveHotel } = useHttp(getRetrieveHotel);
    function openRetrieveBooking(id) {
        sendRequestRetrieveHotel(id);
    }

    useEffect(() => {
        if (statusRetrieveHotel === 'completed') {
            let bookingJSON = [];

            if (loadedRetrieveHotel.data.errors.status === 'FALSE') {
                let currentStatus = loadedRetrieveHotel.data.hotelResults[0] !== undefined && loadedRetrieveHotel.data.hotelResults[0].isCancelled !== undefined ? loadedRetrieveHotel.data.hotelResults[0].isCancelled : false;
                bookingJSON = loadedRetrieveHotel;
                sessionStorage.setItem("HotelBookingData", JSON.stringify(loadedRetrieveHotel));
                sessionStorage.setItem("myTripIsHotelCancelled", currentStatus);
                // history.push({
                //     pathname: '/RetrieveHotel',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveHotel?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [statusRetrieveHotel, loadedRetrieveHotel])

    let resultCurrRoundingValue = props.reportJSON.roundingValue !== undefined && props.reportJSON.roundingValue !== null && props.reportJSON.roundingValue !== '' ? props.reportJSON.roundingValue : 2;
    //let baseCurrRoundingValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'), style: 'currency', currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD' }).format(0).toString().split('.')[1].length;

    return <Fragment>
        {
            (props.reportJSON.sequenceNo !== "0") &&
            <tr>
                <td align={'right'}>{props.reportJSON.sequenceNo}</td>
                <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking(props.reportJSON.confirmationNumber)}><div className='min-width-75'> {props.reportJSON.confirmationNumber} &nbsp;</div><i className="fas fa-external-link-alt line-height-22px"></i></a></td>
                <td scope="row">{getFormatDate(props.reportJSON.bookingDate.split('T')[0].replaceAll('-', ''))} {props.reportJSON.bookingDate.split('T')[1] !== undefined ? getFormatTime(props.reportJSON.bookingDate.split('T')[1].replaceAll(':', '').substring(0, 4)) : ''}</td>
                <td scope="row">{props.reportJSON.companyName}</td>
                <td scope="row">{props.reportJSON.hotelName}</td>
                <td scope="row">{props.reportJSON.location}</td>
                <td scope="row">{props.reportJSON.passengerName}</td>
                <td scope="row" align={'center'}>{props.reportJSON.passengerCount}</td>
                <td scope="row" align={'center'}>{props.reportJSON.numberOfRoom}</td>
                <td scope="row" align={'center'}>{props.reportJSON.numberOfNight}</td>
                <td scope="row">{getFormatDate(props.reportJSON.checkInDate)}</td>
                <td scope="row">{getFormatDate(props.reportJSON.checkOutDate)}</td>
                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <td scope="row">{props.reportJSON.source}</td>}
                <td scope="row">{props.reportJSON.pnr}</td>
                <td scope="row">{props.reportJSON.hotelConfirmationNumber}</td>
                <td scope="row">{props.reportJSON.source === 'GTB' ? 'Negotiated Net' : props.reportJSON.rateCodeName === '' ? '' : props.reportJSON.rateCodeName}</td>
                <td scope="row">{props.reportJSON.status}</td>
                <td scope="row" align={'center'}>{props.reportJSON.currencyCode}</td>
                <td scope="row" align={'right'}>{props.reportJSON.baseFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.baseFare).toFixed(parseFloat(props.reportJSON.baseFare) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.taxes !== '' ? numberWithCommas(parseFloat(props.reportJSON.taxes).toFixed(parseFloat(props.reportJSON.taxes) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row" align={'right'}>{props.reportJSON.serviceFee !== '' ? numberWithCommas(parseFloat(props.reportJSON.serviceFee).toFixed(parseFloat(props.reportJSON.serviceFee) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>}
                <td scope="row" align={'right'}>{props.reportJSON.markup !== '' ? numberWithCommas(parseFloat(props.reportJSON.markup).toFixed(parseFloat(props.reportJSON.markup) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.cancelationAmount !== '' ? numberWithCommas(parseFloat(props.reportJSON.cancelationAmount).toFixed(parseFloat(props.reportJSON.cancelationAmount) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.totalRate !== '' ? numberWithCommas(parseFloat(props.reportJSON.totalRate).toFixed(parseFloat(props.reportJSON.totalRate) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row" align={'right'}>{props.reportJSON.commissionType === 'Variable' ? 'Variable' : props.reportJSON.commissionType === 'Percentage' ? props.reportJSON.commission !== '' && props.reportJSON.commission !== '0' ? props.reportJSON.commission + "%" : '' : props.reportJSON.commission}</td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <td scope="row" align={'center'}>{props.reportJSON.equivCurrencyCode}</td>}
                {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <td scope="row" align={'right'}>{props.reportJSON.conversionRate}</td>}
                {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(Number(props.reportJSON.equivTotalFare).toFixed(props.reportJSON.equivTotalFare.toString().split('.').length > 1 ? props.reportJSON.equivTotalFare.toString().split('.')[1].length : 2)) : ''}</td>}
                <td scope="row">{props.reportJSON.formOfPayment}</td>
                <td scope="row">{props.reportJSON.bookedBy}</td>
            </tr>
        }
        {
            (props.reportJSON.sequenceNo === "0" && props.reportJSON.equivTotalFare !== '') &&
            <tr>
                <td></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row"></td>}
                <td scope="row"></td>
                <td scope="row"></td>
                {/* <td scope="row">Total</td> */}
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row"></td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                <td scope="row"></td>
                <td scope="row"></td>
            </tr>
        }

    </Fragment>

};

export default HotelBookingItem;
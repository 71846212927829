import { useState, Fragment } from "react";
import ApprovalServiceHistory from "./ApprovalServiceHistory";
import { useTranslation } from 'react-i18next';
import { setSanitizedHtml } from '../../../../utils';

const ApprovalHistoryServiceWise = (props) => {

    const { t } = useTranslation(['translation.Approver']);
    
    function approvalStatusLabel(history) {
        let statusHtml = "";
        switch (history.approvalStatusCode.toUpperCase()) {
            case "PD":
                statusHtml = <span className="badge rounded-pill text-16px bg-warning">
                    <i className="fa-solid fa-pause"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "AP":
                statusHtml = <span className="badge rounded-pill text-16px bg-success">
                    <i className="fa-solid fa-check-circle"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "RJ":
                statusHtml = <span className="badge rounded-pill text-16px bg-danger">
                    <i className="fa-solid fa-times-circle"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "SU":
                statusHtml = <span className="badge rounded-pill text-16px bg-sky-blue">
                    <i className="fa-solid fa-pen"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "UP":
                statusHtml = <span className="badge rounded-pill text-16px gt-bg-org">
                    <i className="fa-solid fa-pencil"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "UA":
                statusHtml = <span className="badge rounded-pill text-16px class-purple-bg">
                    <i className="fa-solid fa-check-circle"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            case "UT":
                statusHtml = <span className="badge rounded-pill text-16px bg-primary">
                    <i className="fa-solid fa-check-circle"></i>
                    <span className="ms-1"></span>
                    {history.approvalStatusName}
                </span>;
                break;
            default:
                statusHtml = history.approvalStatusCode;
                break;
        }
        return statusHtml;
    }

    function getServiceName() {
        let serviceName = '';
        switch (props.serviceWiseHistory.serviceID) {
            case 'FL':
                serviceName = 'Flight';
                break;
            case 'HT':
                serviceName = 'Hotel';
                break;
            default:
                serviceName = 'Service Request';
                break;
        }

        return serviceName;
    }

    function shouldShowServiceRequestHistory(history) {
        return history.serviceRequestHistory &&
            history.serviceRequestHistory.length > 0 &&
            (props.serviceWiseHistory.serviceID === 'ALL' || props.serviceWiseHistory.serviceID === 'SR') &&
            (history.approvalStatusCode === 'SU' ||
                history.approvalStatusCode === 'UT' ||
                history.approvalStatusCode === 'UA' ||
                history.approvalStatusCode === 'UP');
    }

    return <Fragment>
        <div className="row">
            {props.isServiceWiseApproval &&
                <h5 className="Yyy fw-bold text-primary mt-1 mb-1">
                    {getServiceName()}
                </h5>
            }
            <div className="row">
                <div className="col-lg-12 mb-1">

                    {props.serviceWiseHistory.historyList && props.serviceWiseHistory.historyList.length > 0 &&
                        props.serviceWiseHistory.historyList.map((history) =>
                            <div key={"MST" + history.key} class="tour_details_boxed_inner mobile-toggle-btn">
                                <div className="accordion" id="accordionExampleNine">
                                    <div className="accordion_flex_area mt-2 Accordion-toggle-icon">
                                        {/* <div className="accordion-item col-md-12"></div> */}
                                        <div id={"MST_" + history.key}
                                            className="card text-dark card-bg-blue mb-2 col-md-12">
                                            <div className="card-body">
                                                <div className="">
                                                    <div className="col-md-12">
                                                        <div className='row'>
                                                            <div className='col-md-4'>
                                                                <p className="card-text pb-1 mb-0 text-primary">
                                                                    <strong className="text-primary"> {t('labelWorkflow')}  </strong>
                                                                    <span className="">
                                                                        {history.approvalTypeName}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <p className="card-text mb-0 pb-1">
                                                                    <strong>
                                                                        {history.approvalStatusCode === 'PD' ? t('labelWaitingAt') :
                                                                            t('labelDoneBy')
                                                                        }
                                                                        &nbsp;
                                                                    </strong>                                                        
                                                                    {history.approvalUserName}
                                                                </p>
                                                            </div>
                                                            <div className='col-md-5'>
                                                                <div className="float-md-end float-lg-end" >
                                                                    <p className="card-text mb-0 pb-1"><strong>{t('labelStatus')}  </strong>
                                                                        <label className="h5 text-primary">
                                                                            {approvalStatusLabel(history)}
                                                                        </label>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div className="row">
                                                            <div className='col-md-7'>
                                                                {history.remarks !== undefined && history.remarks.length > 0 &&
                                                                    <p className="card-text mb-0 pb-1">
                                                                        <strong>{t('labelRemarks')} </strong>
                                                                        {setSanitizedHtml(history.remarks)}
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className='col-md-5'>
                                                                {history.approvalStatusCode !== 'PD' &&
                                                                    <p className="float-md-end float-lg-end">
                                                                        <span className="fw-bold h6">{t('labelDoneOn')} </span>
                                                                        {history.serverUpdatedDate}<span className=""></span>
                                                                    </p>
                                                                }
                                                            </div> 
                                                        </div>
                                                        {shouldShowServiceRequestHistory(history) &&
                                                            <Fragment>
                                                                <div className="col-md-12"><hr className="my-1" /></div>
                                                                <div className="Accordion-toggle-icon">
                                                                    <button className="accordion-button fw-bold collapsed"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={"#DTL_" + history.key}
                                                                        style={{ paddingLeft: "12px !important" }}>
                                                                        <i className="fa-solid fa-cog me-1"></i> {t('labelServiceRequests')}
                                                                    </button>
                                                                </div>

                                                                <ApprovalServiceHistory
                                                                    historyKey={history.key}
                                                                    serviceHistory={history.serviceRequestHistory}
                                                                    approvalStatusCode={history.approvalStatusCode}
                                                                >
                                                                </ApprovalServiceHistory>
                                                            </Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                        )
                    }
                </div>
            </div>

        </div>
    </Fragment>
};

export default ApprovalHistoryServiceWise;
import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';
import { useHistory } from "react-router-dom";
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const FlightBookingItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    function openRetrieveBooking(id) {
        sendRequest(id);
    }

    useEffect(() => {
        if (status === 'completed') {
            let bookingJSON = [];


            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", props.reportJSON.status === 'Canceled' ? true : false);

                // history.push({
                //     pathname: '/RetrieveTrip',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])

    return <Fragment>

        <tr>
            <td align={'right'}>1</td>
            <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking("0002JD")}><div className='min-width-75'>0002JD &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
            <td scope="row">Booked</td>
            <td scope="row">Microsoft</td>
            <td scope="row">Mr. Akhilesh Pathak</td>
            <td scope="row" align={'center'}>2303</td>
            <td scope="row" >Team Leader</td>
            <td scope="row">Onward</td>
            <td scope="row">Economy</td>
            <td scope="row">AI 992, AI 111</td>
            <td scope="row">01 Nov 2024</td>
            <td scope="row">09:15 PM</td>
            <td scope="row">Jeddah</td>
            <td scope="row">London</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>1,514.23</td>
            <td scope="row">EY 330, EY 17</td>
            <td scope="row">01 Nov 2024</td>
            <td scope="row">02:35 AM</td>
            <td scope="row">Jeddah</td>
            <td scope="row">London</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>363.13</td>
            <td scope="row" align={'right'}>1,151.10</td>
            <td scope="row">Urgent Meeting</td>
        </tr>
        <tr>

            <td scope="row" colSpan={16}></td>
            <td scope="row">KU 784, KU 101</td>
            <td scope="row">01 Nov 2024</td>
            <td scope="row">03:10 AM</td>
            <td scope="row">Jeddah</td>
            <td scope="row">London</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>368.83</td>
            <td scope="row" align={'right'}>1,145.40</td>
            <td scope="row"></td>
        </tr>
        <tr>
            <td scope="row" colSpan={16}></td>
            <td scope="row">MS 666, MS 777</td>
            <td scope="row">01 Nov 2024</td>
            <td scope="row">06:40 AM</td>
            <td scope="row">Jeddah</td>
            <td scope="row">London</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>397.31</td>
            <td scope="row" align={'right'}>1,116.92</td>
            <td scope="row"></td>
        </tr>

        <tr>
            <td align={'right'}>2</td>
            <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking("0002J8")}><div className='min-width-75'>0002J8 &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
            <td scope="row">Booked</td>
            <td scope="row">Microsoft</td>
            <td scope="row">Mr. Akhilesh Pathak</td>
            <td scope="row" align={'center'}>2303</td>
            <td scope="row" >Team Leader</td>
            <td scope="row">Onward</td>
            <td scope="row">Economy</td>
            <td scope="row">SK 500,  SK 935</td>
            <td scope="row">04 Nov 2024</td>
            <td scope="row">06:40 AM</td>
            <td scope="row">London</td>
            <td scope="row">San Francisco</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>2,057.40</td>
            <td scope="row"> TP 1363, TP 237</td>
            <td scope="row">04 Nov 2024</td>
            <td scope="row">6:00 AM</td>
            <td scope="row">London</td>
            <td scope="row">San Francisco</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>680.40</td>
            <td scope="row" align={'right'}>1,377.00</td>
            <td scope="row">No in-policy available</td>
        </tr>
        <tr>
            <td scope="row" colSpan={16}></td>
            <td scope="row">WS 19, WS 1520</td>
            <td scope="row">04 Nov 2024</td>
            <td scope="row">12:20 PM</td>
            <td scope="row">London</td>
            <td scope="row">San Francisco</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>858.60</td>
            <td scope="row" align={'right'}>1,198.80</td>
            <td scope="row"></td>
        </tr>
        <tr>
            <td scope="row" colSpan={16}></td>
            <td scope="row">IB 5657, IB 2621</td>
            <td scope="row">04 Nov 2024</td>
            <td scope="row">10:00 AM</td>
            <td scope="row">London</td>
            <td scope="row">San Francisco</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>875.60</td>
            <td scope="row" align={'right'}>1,181.80</td>
            <td scope="row"></td>
        </tr>

        <tr>
            <td align={'right'}>3</td>
            <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking("0002J7")}><div className='min-width-75'>0002J7 &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
            <td scope="row">Booked</td>
            <td scope="row">Microsoft</td>
            <td scope="row">Mr. Akhilesh Pathak</td>
            <td scope="row" align={'center'}>2303</td>
            <td scope="row" >Team Leader</td>
            <td scope="row">Onward</td>
            <td scope="row">Economy</td>
            <td scope="row"> UA 1812, EK 212</td>
            <td scope="row">02 Dec 2024</td>
            <td scope="row">01:52 PM</td>
            <td scope="row">Denver</td>
            <td scope="row">Dubai</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>6,870.20</td>
            <td scope="row">DL 8282, DL 8626</td>
            <td scope="row">02 Dec 2024</td>
            <td scope="row">10:25 AM</td>
            <td scope="row">Denver</td>
            <td scope="row">Dubai</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>1,081.70</td>
            <td scope="row" align={'right'}>5,788.50</td>
            <td scope="row">Urgent Meeting</td>
        </tr>
        <tr>
            <td scope="row" colSpan={16}></td>
            <td scope="row">DL 396, DL 84, DL 8626</td>
            <td scope="row">02 Dec 2024</td>
            <td scope="row">3:00 AM</td>
            <td scope="row">Denver</td>
            <td scope="row">Dubai</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>1,086.20</td>
            <td scope="row" align={'right'}>5,784.00</td>
            <td scope="row"></td>
        </tr>
        <tr>
            <td scope="row" colSpan={16}></td>
            <td scope="row">DL 840, DL 9375, DL 9320</td>
            <td scope="row">02 Dec 2024</td>
            <td scope="row">6:00 AM</td>
            <td scope="row">Denver</td>
            <td scope="row">Dubai</td>
            <td scope="row" align={'center'}>USD</td>
            <td scope="row" align={'right'}>1,090.60</td>
            <td scope="row" align={'right'}>5,779.60</td>
            <td scope="row"></td>
        </tr>
    </Fragment>

};

export default FlightBookingItem;
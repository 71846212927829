const Document = (props) => {
    return <>
        <hr className="mt-4 mb-2"></hr>
        {/* <p className="h5 mb-3 text-black fw-bold mt-3">Documents</p> */}
        <label for="" class="h5 text-primary my-3"><span class="gt-border-1px"> <i class="fas fa-file"></i> Documents</span></label>
        <div>
            <div className="col-lg-12 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="col-md-12">
                            <div className="row">
                            <p class="card-text mb-0 pb-0"><strong>Files </strong>
                            </p>
                                {props.documents.length > 0 &&
                                    props.documents.map(file =>
                                        <div key={file.documentID}
                                            className="col-md-4">
                                            <p className="card-text  mb-0 mt-1">
                                                <a href={file.documentBinaryContent}
                                                    download={file.documentName}>
                                                    {file.documentName} <i className="fas fa-download"></i>
                                                </a>
                                            </p>
                                        </div>
                                    )
                                }
                                  {props.documents[0].remarks !== undefined && props.documents[0].remarks.length !== 0 &&
                                  
                                    <div className='control col-lg-12'>
                                        <hr className="my-2"></hr>
                                        <p class="card-text mb-0 pb-1"><strong>Remarks </strong>
                                            <div><p>{props.documents[0].remarks}</p></div>
                                        </p>
                                        {/* <input className="mb-2 form-control form-control-sm col-lg-12" type='text' readOnly value={props.documents[0].remarks} /> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

    </>
}
export default Document;
import { Fragment, useState } from "react";
import useDocumentTitle from "../../../../components/Common/layout/useDocumentTitle";

const TravelRequestEmailTemplate = () => {
  useDocumentTitle("Flight Book");
  return <Fragment>
    {/* <!-- Common Banner Area --> */}
     <section id="common_banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12"></div>
        </div>
      </div>
    </section>
    <section id="theme_search_form_tour" className="my-5">
      <div className="container">
        <div className="col-md-12">
          <table align="center" width="100%" bgcolor="" className="d-none">
            <tr className="row">
              <td className="col-lg-4 col-md-6 col-sm-4 px-1 mt-2">
                <div className="card">
                  <div className="card-header bg-main-color text-light fw-bold"> Option 1 </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="flight_logo">
                          <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                          <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines </span>
                          <span className="text-muted text-14px ms-2"> DL 264 <br />Airbus A330-300 </span>
                        </div>
                      </div>
                      <hr className="my-1">
                      </hr>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">4 Mar 2024, 10:30 PM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon"></div>
                              <div className="mt-2 fw-bold text-primary">15h 50m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">4 Mar 2024, 11:20 PM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center mb-3">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="col-md-12">
                          <div className="flight_logo">
                            <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                            <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines</span>
                            <span className="text-muted text-14px ms-2"> DL 265 <br />Airbus A330-300 </span>
                          </div>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">11 Mar 2024, 1:30 AM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon gt-return-flight"></div>
                              <div className="mt-2 fw-bold text-primary">24h 45m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">11 Mar 2024, 6:15 PM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-1">
                  </hr>
                  <div className="col-md-12 my-auto">
                    <div className="h4 text-primary text-end fw-bold pt-1 mb-0 me-3"> $ 10,236.65 </div>
                  </div>
                  <div className="">
                    <div className="row  mb-2">
                      <div className="col-md-6">
                        <div className="text-12px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="text-14px text-end me-3">Includes taxes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="col-lg-4 col-md-6 col-sm-4 px-1 mt-2">
                <div className="card">
                  <div className="card-header bg-main-color text-light fw-bold"> Option 2 </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="flight_logo">
                          <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" className=" p-1 gt-h30" />
                          <span className="text-14px text-primary fw-bold gt-w-75px">Multi Airlines </span>
                          <span className="text-muted text-14px ms-2"> Virgin Atlantic VS 46 <br />Airbus A330-300 </span>
                        </div>
                      </div>
                      <hr className="my-1">
                      </hr>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">4 Mar 2024, 7:01 PM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon"></div>
                              <div className="mt-2 fw-bold text-primary">15h 59m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">4 Mar 2024, 8:00 PM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center mb-3">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="col-md-12">
                          <div className="flight_logo">
                            <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" className=" p-1 gt-h30" />
                            <span className="text-14px text-primary fw-bold gt-w-75px">Multi Airlines </span>
                            <span className="text-muted text-14px ms-2"> Virgin Atlantic VS 45 <br />Airbus A330-300 </span>
                          </div>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">11 Mar 2024, 7:45 AM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon gt-return-flight"></div>
                              <div className="mt-2 fw-bold text-primary">17h 40m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">11 Mar 2024, 5:25 PM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-1">
                  </hr>
                  <div className="col-md-12 my-auto">
                    <div className="h4 text-primary text-end fw-bold pt-1 mb-0 me-3"> $ 9,252.95 </div>
                  </div>
                  <div className="">
                    <div className="row  mb-2">
                      <div className="col-md-6">
                        <div className="text-12px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="text-14px text-end me-3">Includes taxes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="col-lg-4 col-md-6 col-sm-4 px-1 mt-2">
                <div className="card">
                  <div className="card-header bg-main-color text-light fw-bold"> Option 3 </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="flight_logo">
                          <img src="https://dev.fareladder.com/assets/logos/flights/AF.gif" alt="img" className=" p-1 gt-h30" />
                          <span className="text-14px text-primary fw-bold gt-w-75px">Air France </span>
                          <span className="text-muted text-14px ms-2"> AF 3634 <br />Airbus A330-300 </span>
                        </div>
                      </div>
                      <hr className="my-1">
                      </hr>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">4 Mar 2024, 10:30 PM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon"></div>
                              <div className="mt-2 fw-bold text-primary">15h 50m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">4 Mar 2024, 11:20 PM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center mb-3">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="col-md-12">
                          <div className="flight_logo">
                            <img src="https://dev.fareladder.com/assets/logos/flights/DL.gif" alt="img" className=" p-1 gt-h30" />
                            <span className="text-14px text-primary fw-bold gt-w-75px">Delta Air Lines </span>
                            <span className="text-muted text-14px ms-2"> DL 8747 <br />Airbus A350-900 </span>
                          </div>
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> Dubai </div>
                            <div className="text-14px">DXB - Dubai, United Arab Emirates, AE</div>
                            <div className="h6">11 Mar 2024, 1:30 AM</div>
                          </div>
                          <div className="col-md-12 px-0 my-2">
                            <div className="text-center text-12px">
                              <div className="recomendation-flight-icon gt-return-flight"></div>
                              <div className="mt-2 fw-bold text-primary">18h 10m (1 stop)</div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <div className=" text-primary h5 my-1"> New York </div>
                            <div className="text-14px">JFK - John F Kennedy, United States of America, US</div>
                            <div className="h6">11 Mar 2024, 11:40 AM</div>
                          </div>
                        </div>
                        <div className="col-md-12 text-14px mt-2 text-center">
                          <span className="fw-bold text-primary">Check-in Baggage:</span>
                          <span>Per Adult 2 pieces</span>
                        </div>
                        <div className="text-center">
                          <span className="text-14px badge rounded-pill class-purple-bg  mt-2">Business</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-1">
                  </hr>
                  <div className="col-md-12 my-auto">
                    <div className="h4 text-primary text-end fw-bold pt-1 mb-0 me-3"> $10,313.25 </div>
                  </div>
                  <div className="">
                    <div className="row  mb-2">
                      <div className="col-md-6">
                        <div className="text-12px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="text-14px text-end me-3">Includes taxes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div className="col-md-12">
            <div className="container">
              <div className="col-md-12">
                <label for="" class="h5 text-primary">
                  <i class="fas fa-globe-asia"></i> Travel Request <span class="badge h6 rounded-pill mb-0 bg-warning">
                    <i class="fa-solid fa-check-circle"></i>
                    <span class="ms-1"></span> Hold </span>
                </label>
              </div>
              <div className="col-md-12 mt-3">
                <span className="text-primary">
                  <strong>Confirmation No.</strong>
                  <span className="text-primary"> 0002DW </span>
                </span>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 text-sky-blue">
                  <strong> Mr. Akhilesh Pathak</strong>
                </div>
                <div className="col-md-6">
                  <span class="badge mb-0 bg-primary">
                    <span class="ms-1"></span> 1 Mar 2024 To 3 Mar 2024 </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-14px my-3"> This trip will be on hold till <strong>Tuesday, May 7, 2024 9:45 PM Dubai time </strong>. <div className="mt-2">
                  <strong>Important: </strong> If this trip is not confirmed by the above date and time, it will be automatically cancelled.
                </div>
                </div>
              </div>
              <div className="col-md-12 text-14px">
                <div>
                  <div className="row">
                    <div className="col-md-4">Traveler Name </div>
                    <div className="col-md-8"> Mr. Akhilesh Pathak </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Source PNR No. </div>
                    <div className="col-md-8"> DHDHD </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Designation</div>
                    <div className="col-md-8"> Team Leader </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Department</div>
                    <div className="col-md-8">Software </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Cost Center</div>
                    <div className="col-md-8">Software </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Employee ID</div>
                    <div className="col-md-8"> 2301 </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Trip Reason</div>
                    <div className="col-md-8"> Business </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Purpose</div>
                    <div className="col-md-8"> Meeting </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Travel Approver</div>
                    <div className="col-md-8"> Mr. Rahul Srinivasan </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">Travel Cost</div>
                    <div className="col-md-8"> AED 45,000.70 + USD 500.00 + SAR 2,000.00 </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-14px mt-3" style={{ background: "#EAEAEA", padding: "20px 25px", borderRadius: "10px" }}>
                <div className=""> Please click on the below buttons to approve or reject this travel request: </div>
                <div class="mt-3 col-md-12">
                  <button className="btn gt-btn_md text-white gt-main-btn ms-1">Approve</button>
                  <a className="btn gt-btn_md text-white btn-danger ms-1" href="">Reject</a>
                </div>
                <div className="mt-3 text-sky-blue fw-bold"> Approval deadline: Tuesday, May 7, 2024 9:45 PM. </div>
                <div className="mt-2"> Approval deadline is provided in the timezone of the first departing city in your booking. </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="text-sky-blue h6">
                  <strong> Selected Flight </strong>
                </div>
              </div>
              <div className="col-md-12">
                <div className="flight_policy_refund collapse show">
                  <div class="departure-text">
                    <span class="departure-text">
                      <span>
                        <i class="fas fa-plane-departure"></i>
                      </span> Departure <span class="text-primary airlineName fw-500">Airline PNR: DHDHD</span>
                    </span>
                  </div>
                  <div class="d-lg-flex flight_show_down_wrapper w-100">
                    <div class="flight-shoe_dow_item col-md-9 aaa">
                      <div class="airline-details">
                        <div class="img">
                          <img src="https://staging.fareladder.com/assets/logos/flights/AA.gif" alt="img" class="details-flight-img" />
                        </div>
                        <span class="airlineName fw-500">American Airlines &nbsp;AA 8603</span>
                        <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> Economy</span>
                        <span class="flightNumber">Airbus A320</span>
                      </div>
                      <div class="flight_inner_show_component">
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">London</span>
                            </div>
                            <div class="code_time">
                              <span class="time">11:30 AM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">London Heathrow(LHR), United Kingdom(GB)</p>
                            <p class="airport mb-2">Terminal 5</p>
                          </div>
                        </div>
                        <div class="flight_duration">
                          <div class="arrow_right"></div>
                          <span>2h 25m</span>
                          <p> 0 stops</p>
                        </div>
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">Madrid</span>
                            </div>
                            <div class="code_time">
                              <span class="time">2:55 PM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">Barajas(MAD), Spain(ES)</p>
                            <p class="airport mb-2"></p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="text-primary text-14px fw-bold">
                          <i class="fas fa-tachometer-alt"></i> 3578 Air miles
                        </div>
                      </div>
                    </div>
                    <div class="flight_refund_policy col-md-3">
                      <div class="TabPanelInner col-sm-12">
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom">Check-in Baggage </span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>23 kg &nbsp; </span>
                          </p>
                        </div>
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom"> Cabin Baggage</span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>10 kg &nbsp; </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="row">
                  <div className="text-sky-blue h6">
                    <strong> Price </strong>
                  </div>
                </div>
                <div className="row text-14px">
                  <div className="col-md-4"> Base Fare (1 * $110.00) </div>
                  <div className="col-md-8"> $110.00 </div>
                </div>
                <div className="row text-14px">
                  <div className="col-md-4"> Taxes (1 * $12.00) </div>
                  <div className="col-md-8"> $12.00 </div>
                </div>
                <div className="row text-primary fw-bold">
                  <div className="col-md-4"> Fare </div>
                  <div className="col-md-8"> $122.00 </div>
                </div>
                <div className="row text-primary fw-bold h5">
                  <div className="col-md-4"> Total Fare </div>
                  <div className="col-md-8"> $122.00 </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="text-sky-blue h6">
                  <strong> Policy </strong>
                </div>
                <div className="text-14px"> Out of Policy </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="text-sky-blue h6">
                  <strong> Remarks </strong>
                </div>
                <div className="text-14px"> Due to meeting. </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="">
                  <strong> Lowest Flight details </strong>
                </div>
                <div className="text-primary mt-2">
                  <strong> Option 1</strong>
                </div>
              </div>
              <div className="col-md-12 mt-2 2222">
                <div className="flight_policy_refund collapse show">
                  <div class="departure-text">
                    <span class="departure-text">
                      <span>
                        <i class="fas fa-plane-departure"></i>
                      </span> Departure <span class="text-primary airlineName fw-500">Airline PNR: DHDHD</span>
                    </span>
                  </div>
                  <div class="d-lg-flex flight_show_down_wrapper w-100">
                    <div class="flight-shoe_dow_item col-md-9 aaa">
                      <div class="airline-details">
                        <div class="img">
                          <img src="https://staging.fareladder.com/assets/logos/flights/AA.gif" alt="img" class="details-flight-img" />
                        </div>
                        <span class="airlineName fw-500">American Airlines &nbsp;AA 8603</span>
                        <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> Economy</span>
                        <span class="flightNumber">Airbus A320</span>
                      </div>
                      <div class="flight_inner_show_component">
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">London</span>
                            </div>
                            <div class="code_time">
                              <span class="time">11:30 AM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">London Heathrow(LHR), United Kingdom(GB)</p>
                            <p class="airport mb-2">Terminal 5</p>
                          </div>
                        </div>
                        <div class="flight_duration">
                          <div class="arrow_right"></div>
                          <span>2h 25m</span>
                          <p> 0 stops</p>
                        </div>
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">Madrid</span>
                            </div>
                            <div class="code_time">
                              <span class="time">2:55 PM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">Barajas(MAD), Spain(ES)</p>
                            <p class="airport mb-2"></p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="text-primary text-14px fw-bold">
                          <i class="fas fa-tachometer-alt"></i> 3578 Air miles
                        </div>
                      </div>
                    </div>
                    <div class="flight_refund_policy col-md-3">
                      <div class="TabPanelInner col-sm-12">
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom">Check-in Baggage </span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>23 kg &nbsp; </span>
                          </p>
                        </div>
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom"> Cabin Baggage</span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>10 kg &nbsp; </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Base Fare (1 * $110.00) </div>
                    <div className="col-md-8"> $110.00 </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Taxes (1 * $12.00) </div>
                    <div className="col-md-8"> $12.00 </div>
                  </div>
                  <div className="row text-primary fw-bold">
                    <div className="col-md-4"> Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                  <div className="row text-primary fw-bold h5">
                    <div className="col-md-4"> Total Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-2 333">
                <div className="text-primary mt-4">
                  <strong> Option 2</strong>
                </div>
                <div className="flight_policy_refund collapse show">
                  <div class="departure-text">
                    <span class="departure-text">
                      <span>
                        <i class="fas fa-plane-departure"></i>
                      </span> Departure <span class="text-primary airlineName fw-500">Airline PNR: DHDHD</span>
                    </span>
                  </div>
                  <div class="d-lg-flex flight_show_down_wrapper w-100">
                    <div class="flight-shoe_dow_item col-md-9 aaa">
                      <div class="airline-details">
                        <div class="img">
                          <img src="https://staging.fareladder.com/assets/logos/flights/AA.gif" alt="img" class="details-flight-img" />
                        </div>
                        <span class="airlineName fw-500">American Airlines &nbsp;AA 8603</span>
                        <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> Economy</span>
                        <span class="flightNumber">Airbus A320</span>
                      </div>
                      <div class="flight_inner_show_component">
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">London</span>
                            </div>
                            <div class="code_time">
                              <span class="time">11:30 AM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">London Heathrow(LHR), United Kingdom(GB)</p>
                            <p class="airport mb-2">Terminal 5</p>
                          </div>
                        </div>
                        <div class="flight_duration">
                          <div class="arrow_right"></div>
                          <span>2h 25m</span>
                          <p> 0 stops</p>
                        </div>
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">Madrid</span>
                            </div>
                            <div class="code_time">
                              <span class="time">2:55 PM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">Barajas(MAD), Spain(ES)</p>
                            <p class="airport mb-2"></p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="text-primary text-14px fw-bold">
                          <i class="fas fa-tachometer-alt"></i> 3578 Air miles
                        </div>
                      </div>
                    </div>
                    <div class="flight_refund_policy col-md-3">
                      <div class="TabPanelInner col-sm-12">
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom">Check-in Baggage </span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>23 kg &nbsp; </span>
                          </p>
                        </div>
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom"> Cabin Baggage</span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>10 kg &nbsp; </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Base Fare (1 * $110.00) </div>
                    <div className="col-md-8"> $110.00 </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Taxes (1 * $12.00) </div>
                    <div className="col-md-8"> $12.00 </div>
                  </div>
                  <div className="row text-primary fw-bold">
                    <div className="col-md-4"> Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                  <div className="row text-primary fw-bold h5">
                    <div className="col-md-4"> Total Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                </div>
              </div>
              <div className="text-primary mt-4">
                <strong> Option 3</strong>
              </div>
              <div className="col-md-12 mt-2 444">
                <div className="flight_policy_refund collapse show">
                  <div class="departure-text">
                    <span class="departure-text">
                      <span>
                        <i class="fas fa-plane-departure"></i>
                      </span> Departure <span class="text-primary airlineName fw-500">Airline PNR: DHDHD</span>
                    </span>
                  </div>
                  <div class="d-lg-flex flight_show_down_wrapper w-100">
                    <div class="flight-shoe_dow_item col-md-9 aaa">
                      <div class="airline-details">
                        <div class="img">
                          <img src="https://staging.fareladder.com/assets/logos/flights/AA.gif" alt="img" class="details-flight-img" />
                        </div>
                        <span class="airlineName fw-500">American Airlines &nbsp;AA 8603</span>
                        <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> Economy</span>
                        <span class="flightNumber">Airbus A320</span>
                      </div>
                      <div class="flight_inner_show_component">
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">London</span>
                            </div>
                            <div class="code_time">
                              <span class="time">11:30 AM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">London Heathrow(LHR), United Kingdom(GB)</p>
                            <p class="airport mb-2">Terminal 5</p>
                          </div>
                        </div>
                        <div class="flight_duration">
                          <div class="arrow_right"></div>
                          <span>2h 25m</span>
                          <p> 0 stops</p>
                        </div>
                        <div class="flight_det_wrapper">
                          <div class="flight_det">
                            <div class="code_time">
                              <span class="code">Madrid</span>
                            </div>
                            <div class="code_time">
                              <span class="time">2:55 PM</span>
                            </div>
                            <p class="date">15 Jun 2024</p>
                            <p class="airport">Barajas(MAD), Spain(ES)</p>
                            <p class="airport mb-2"></p>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="text-primary text-14px fw-bold">
                          <i class="fas fa-tachometer-alt"></i> 3578 Air miles
                        </div>
                      </div>
                    </div>
                    <div class="flight_refund_policy col-md-3">
                      <div class="TabPanelInner col-sm-12">
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom">Check-in Baggage </span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>23 kg &nbsp; </span>
                          </p>
                        </div>
                        <h4 class="col-md-12">
                          <span class="gt-border-bottom"> Cabin Baggage</span>
                        </h4>
                        <div class="flight_info_taable">
                          <p>Per Adult &nbsp; &nbsp; <span>10 kg &nbsp; </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Base Fare (1 * $110.00) </div>
                    <div className="col-md-8"> $110.00 </div>
                  </div>
                  <div className="row text-14px">
                    <div className="col-md-4"> Taxes (1 * $12.00) </div>
                    <div className="col-md-8"> $12.00 </div>
                  </div>
                  <div className="row text-primary fw-bold">
                    <div className="col-md-4"> Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                  <div className="row text-primary fw-bold h5">
                    <div className="col-md-4"> Total Fare </div>
                    <div className="col-md-8"> $122.00 </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="">
                  <div class="text-sky-blue h6">
                    <strong> Selected Hotel </strong>
                  </div>
                </div>
                <div class="hotel-list row">
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/hbbuykibkonr8ekingqu.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/qrli0agyux3cng7teszm.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="sort-view clearfix"></div>
                  <div class="hotel-list-cn clearfix shadow-none">
                    <div class="hotel-list-item position-relative">
                      <div class="hotel-text m-0 pt-3">
                        <div class="mb-2">
                          <a title="" class="text-primary fw-bold h3">Grand Airport Ensuites</a>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="text-16px my-3">330 Bath Rd , West Drayton, United Kingdom</div>
                            </div>
                            <div class="col-md-12 text-16px display-line"></div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-1">
                      </hr>
                      <div class="row">
                        <div class="col-md-12 my-auto">
                          <div class="mb-2">
                            <div class="ms-2 text-14px text-sky-blue mt-2">
                              <i class="fas fa-money-check-alt"></i> Refundable
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="row">
                    <div class="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-md-12 fw-bold"> 1 X Superior Room </div>
                    <div class="col-md-4 text-14px">Base Rate</div>
                    <div class="col-md-8 text-14px">$45.56</div>
                  </div>
                  <div class="row text-14px">
                    <div class="col-md-4">Taxes</div>
                    <div class="col-md-8">$5.46</div>
                  </div>
                  <div class="row text-primary fw-bold h5">
                    <div class="col-md-4">Total Rate</div>
                    <div class="col-md-8">$51.02</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="text-sky-blue h6">
                    <strong> Booking </strong>
                  </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Check-in </div>
                  <div className="col-md-8">15 Apr 2024 , 02:00 PM </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Check-out </div>
                  <div className="col-md-8">16 Apr 2024 , 12:00 PM </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Total length of stay </div>
                  <div className="col-md-8">Total length of stay </div>
                </div>
              </div>
              <div className="col-md-12 mt-4  2222">
                <div className="">
                  <strong> Lowest Hotel details</strong>
                </div>
                <div className="text-primary mt-2 fw-bold"> Option 1 </div>
                <div class="hotel-list row">
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/hbbuykibkonr8ekingqu.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/qrli0agyux3cng7teszm.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="sort-view clearfix"></div>
                  <div class="hotel-list-cn clearfix shadow-none">
                    <div class="hotel-list-item position-relative">
                      <div class="hotel-text m-0 pt-3">
                        <div class="mb-2">
                          <a title="" class="text-primary fw-bold h3">Grand Airport Ensuites</a>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="text-16px my-3">330 Bath Rd , West Drayton, United Kingdom</div>
                            </div>
                            <div class="col-md-12 text-16px display-line"></div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-1">
                      </hr>
                      <div class="row">
                        <div class="col-md-12 my-auto">
                          <div class="mb-2">
                            <div class="ms-2 text-14px text-sky-blue mt-2">
                              <i class="fas fa-money-check-alt"></i> Refundable
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="row">
                    <div class="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-md-12 fw-bold"> 1 X Superior Room </div>
                    <div class="col-md-4 text-14px">Base Rate</div>
                    <div class="col-md-8 text-14px">$45.56</div>
                  </div>
                  <div class="row text-14px">
                    <div class="col-md-4">Taxes</div>
                    <div class="col-md-8">$5.46 </div>
                  </div>
                  <div class="row text-primary fw-bold h5">
                    <div class="col-md-4">Total Rate</div>
                    <div class="col-md-8">$51.02</div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4 333">
                <div className="text-primary mt-2 fw-bold"> Option 2 </div>
                <div class="hotel-list row">
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/hbbuykibkonr8ekingqu.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="hotel-confirmation-img col-lg-6 col-md-6 col-sm-12">
                    <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/102226323/qrli0agyux3cng7teszm.jpg" class="w-100" loading="lazy" />
                  </div>
                  <div class="sort-view clearfix"></div>
                  <div class="hotel-list-cn clearfix shadow-none">
                    <div class="hotel-list-item position-relative">
                      <div class="hotel-text m-0 pt-3">
                        <div class="mb-2">
                          <a title="" class="text-primary fw-bold h3">Grand Airport Ensuites</a>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="text-16px my-3">330 Bath Rd , West Drayton, United Kingdom</div>
                            </div>
                            <div class="col-md-12 text-16px display-line"></div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-1">
                      </hr>
                      <div class="row">
                        <div class="col-md-12 my-auto">
                          <div class="mb-2">
                            <div class="ms-2 text-14px text-sky-blue mt-2">
                              <i class="fas fa-money-check-alt"></i> Refundable
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="row">
                    <div class="text-sky-blue h6">
                      <strong> Price </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-md-12 fw-bold"> 1 X Superior Room </div>
                    <div class="col-md-4 text-14px">Base Rate</div>
                    <div class="col-md-8 text-14px">$45.56</div>
                  </div>
                  <div class="row text-14px">
                    <div class="col-md-4">Taxes</div>
                    <div class="col-md-8">$5.46 </div>
                  </div>
                  <div class="row text-primary fw-bold h5">
                    <div class="col-md-4">Total Rate</div>
                    <div class="col-md-8">$51.02</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="text-sky-blue h6">
                    <strong> Service Request </strong>
                  </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Service </div>
                  <div className="col-md-8">Car (Self-driven) </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Remark Existing </div>
                  <div className="col-md-8">Required car </div>
                </div>
                <div class="row text-14px">
                  <div className="col-md-4">Confirmation Number </div>
                  <div className="col-md-8">RS000R </div>
                </div>
                <div class="row text-primary fw-bold h5 ">
                  <div class="col-md-4">Total Rate</div>
                  <div class="col-md-8">$300</div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-cn hotel-page clearfix my-4 d-none">
            <div className="row">
              <div className="col-md-12 col-md-push-3">
                <section className="hotel-list">
                  <div className="sort-view clearfix"></div>
                  <div className="hotel-list-cn clearfix">
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/aak8hcx6yraydrgbb4ox.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Classic Room {/* <span className="text-warning ms-1 text-12px">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </span> */} </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Classic King Bed Courtyard Or City View 335 Sqft Flrs 4 To 21 <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> {/* <p class="card-text text-black">
                              <i class="fa-solid fa-wifi"></i> Free WiFi
                            </p> */} <p class="card-text text-black">
                              <i class="fa-solid fa-bed"></i> King Bed
                            </p>
                            <p class="card-text text-black">
                              <i class="fa-solid fa-tree-city"></i> City view
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6"> {/* <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <span class="fw-bold">Meal Plan:</span> Breakfast
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2"> {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Base Rate</span>
                              <span class="h6">$1,075.00</span>
                            </span>
                          </div>
                          <div className="">
                            <span class="area_flex_one ">
                              <span>Taxes</span>
                              <span class="h6">$269.57</span>
                            </span>
                          </div> */} {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Total Rate</span>
                              <span class="h6">$1,344.57</span>
                            </span>
                          </div> */}
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,049.61</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$224.61</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - Feb 6, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Minibar</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Cable television</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Telephone</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Air conditioning</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Superior Room {/* <span className="text-warning ms-1 text-12px">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </span> */} </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Superior King Bed. Courtyard View 360 Sqft Flrs 4 To 12 <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> {/* <p class="card-text text-black">
                              <i class="fa-solid fa-wifi"></i> Free WiFi
                            </p> */} <p class="card-text text-black">
                              <i class="fa-solid fa-bed"></i> Courtyard Bed
                            </p>
                            <p class="card-text text-black">
                              <i class="fa-solid fa-tree-city"></i> City view
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6"> {/* <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <span class="fw-bold">Meal Plan:</span> Breakfast
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2"> {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Base Rate</span>
                              <span class="h6">$1,075.00</span>
                            </span>
                          </div>
                          <div className="">
                            <span class="area_flex_one ">
                              <span>Taxes</span>
                              <span class="h6">$269.57</span>
                            </span>
                          </div> */} {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Total Rate</span>
                              <span class="h6">$1,344.57</span>
                            </span>
                          </div> */}
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,023.11</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$219.11</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - March 6, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Minibar</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Cable television</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Telephone</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Air conditioning</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Deluxe Room {/* <span className="text-warning ms-1 text-12px">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </span> */} </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Deluxe King Room Courtyard View. 375 Sqft Flrs 3 To 18 <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> {/* <p class="card-text text-black">
                              <i class="fa-solid fa-wifi"></i> Free WiFi
                            </p> */} <p class="card-text text-black">
                              <i class="fa-solid fa-bed"></i> Courtyard View
                            </p>
                            <p class="card-text text-black">
                              <i class="fa-solid fa-tree-city"></i> King Bed
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2">
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,130.39</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$241.39</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - March 6, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Minibar</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Cable television</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Telephone</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Air conditioning</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Superior Suite {/* <span className="text-warning ms-1 text-12px">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                            </span> */} </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Superior Suite - King Bed - 620 Sq Ft. Courtyard View Flr 4 To 14 <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> {/* <p class="card-text text-black">
                              <i class="fa-solid fa-wifi"></i> Free WiFi
                            </p> */} <p class="card-text text-black">
                              <i class="fa-solid fa-bed"></i> King Bed
                            </p>
                            <p class="card-text text-black">
                              <i class="fa-solid fa-tree-city"></i> Courtyard view
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6"> {/* <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <i class="fas fa-utensils"></i> Breakfast Included
                          </p>
                          <p class="card-text text-black">
                            <span class="fw-bold">Meal Plan:</span> Breakfast
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2"> {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Base Rate</span>
                              <span class="h6">$1,075.00</span>
                            </span>
                          </div>
                          <div className="">
                            <span class="area_flex_one ">
                              <span>Taxes</span>
                              <span class="h6">$269.57</span>
                            </span>
                          </div> */} {/* <div className="">
                            <span class="area_flex_one ">
                              <span>Total Rate</span>
                              <span class="h6">$1,344.57</span>
                            </span>
                          </div> */}
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,743.85</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$368.85</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - Feb 25, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Presidential Suite  </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Presidential Suite - 2722 Sq.ft 3 Bedrooms 4.5 Half Bedrooms City And Central Park View <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> <p class="card-text text-black">
                            <i class="fa-solid fa-tree-city"></i> Park view
                          </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2">
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$30,775.60</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$640,0.60</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - Feb 25, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                      <figure className="hotel-img float-left">
                        <a href="hotel-detail.html" title="">
                          <img src="https://vcmp-hotels.sabre.com/image/upload/f_auto,q_auto:best,t_vcmp_large/hotel/i/30842/gwu98e7yk8gqhiztmvpn.jpg" alt="" />
                        </a>
                      </figure>
                      <span className="hotel-flag" title="GT Blue">
                        <i className="fas fa-bell trophy-icon"></i>
                      </span>
                      <div className="hotel-text mt-2">
                        <div className="hotel-name">
                          <a href="hotel-detail.html" title="">Classic Room </a>
                        </div>
                        <div className="hotel-star-address">
                          <address className="hotel-address" style={{ height: "40px" }}> Classic King Bed Courtyard Or City View 335 Sqft Flrs 4 To 21 <br></br>
                          </address>
                        </div>
                      </div>
                      <div className="row" style={{ height: "95px" }}>
                        <div className="col-md-6">
                          <div className="text-12px ms-2 text-muted"> {/* <p class="card-text text-black">
                              <i class="fa-solid fa-wifi"></i> Free WiFi
                            </p> */} <p class="card-text text-black">
                              <i class="fa-solid fa-bed"></i> King Bed
                            </p>
                            <p class="card-text text-black">
                              <i class="fa-solid fa-tree-city"></i> City view
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                      </div>
                      <div className="col-md-12 text-14px text-muted mt-2">
                        <span className="text-14px text-sky-blue ms-3">
                          <i className="fas fa-money-check-alt"></i> Refundable </span>
                      </div>
                      <div className="col-md-12 hotel-service mt-2">
                        <div className=" px-2">
                        </div>
                        <hr className="my-1">
                        </hr>
                        <div className="row">
                          <div className="col-md-6">
                            <span class=" text-primary h4 fw-bold mb-0 ms-2">Total Rate</span>
                          </div>
                          <div className="col-md-6">
                            <div className="price special-price text-primary fw-bold h4 mb-0 text-end">$1,049.61</div>
                            <div class="text-end text-muted text-14px"> Includes <span class="text-primary fw-bold">$224.61</span> Taxes </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="my-1">
                        </hr>
                        <div className="row px-3 mt-3">
                          <div className="col-md-6">
                            <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> AMERICAN EXPRESS (AX) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DINERS CLUB CARD (DC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> DISCOVER CARD (DS) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> JCB CREDIT CARD (JC) </span>
                            </div>
                            <div class="text-black">
                              <span>
                                <i class="far fa-credit-card"></i> MASTER CARD (MC) </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <div class="card-title text-primary h5 mb-2"> Cancellation Policy</div>
                              <ul>
                                <li>
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">Deadline - Feb 24, 2024, 12:00 AM.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You may cancel free of charge until 1 day before arrival.</span>
                                </li>
                                <li class="display-line">
                                  <i class="fas fa-circle text-8px text-black py-1"></i>
                                  <span class="ms-1 text-black">You will be charged the cost of the first night if you cancel within 1 day before arrival.</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div>
                              <div class=" card-title text-primary h5 ">Amenities</div>
                              <div className="row my-3">
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Minibar</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Cable television</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathtub/shower combination</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Safe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Private bathroom</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Pay per view movies on TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> TV</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Self-controlled heating/cooling system</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Telephone</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Air conditioning</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Hairdryer</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Wireless internet connection</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Bathrobe</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Alarm clock</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> AM/FM radio</span>
                                    </li>
                                    <li>
                                      <i class="fas fa-circle text-8px text-black py-1"></i>
                                      <span class="ms-1 text-black"> Desk</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> {/*
                <!-- End Hotel List --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
}; export default TravelRequestEmailTemplate;
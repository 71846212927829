import { Fragment, React } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { ALLUSER, SEARCHOFFICE, SEARCHROLE } from '../../navigation/CONSTANTS';

const UserMenu = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const history = useHistory();
    function onOffice() {
        history.push(SEARCHOFFICE)
     
    }
    function onUser() {
        history.push(ALLUSER)
     
    }
    function onRole() {
        history.push(SEARCHROLE)
   
    }
    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8 mb-30">
                                            <div className="card card-primary card-outline">

                                                <div className="card-body">
                                                    <div className="col-md-12 ">
                                                        <div className="row position-relative">
                                                            <div className="col-lg-12">
                                                                <div className="tour_search_form">
                                                                    <form action="!#">
                                                                        <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> User Management</label>

                                                                        <div className='mt-3 actions row'>
                                                                           
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="row">

                                                                                <div className="col-lg-2 col-md-3 col-6 text-center col-sm-6">
                                                                                    <button onClick={onOffice} className='default-btn-view col-sm-6'>
                                                                                        <span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-desktop"></i></span><br></br> 
                                                                                        <span className='text-12px text-primary'>{t('Office')}
                                                                                        </span>
                                                                                    </button>
                                                                                </div>

                                                                                <div className="col-lg-2 col-md-3 col-6 text-center col-sm-6">
                                                                                    <button onClick={onRole} className='default-btn-view col-sm-6'>
                                                                                        <span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-user-tag"></i></span><br></br> 
                                                                                        <span className='text-12px text-primary'>{t('Role')}
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-lg-2 col-md-3 col-6 text-center col-sm-6">
                                                                                    <button onClick={onUser} className='default-btn-view col-sm-6'>
                                                                                        <span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-user"></i></span><br></br> 
                                                                                        <span className='text-12px text-primary'>{t('User')}
                                                                                        </span>
                                                                                    </button>
                                                                                </div>

                                                                                    {/* <button onClick={onOffice} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Office')}</button>

                                                                                    <button onClick={onRole} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('Role')}</button>

                                                                                    <button onClick={onUser} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('User')}</button> */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>)

};

export default UserMenu;


import { useHistory } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BOOKING } from '../../../navigation/CONSTANTS';
import FareSummary from './FareSummary'
import SendEmail from '../../../pages/BookingConfirmation/SendEmail';
import { getMinutetoHrsMin, getFormatDate, getFormatTime } from './../../../utils/CommonFunction';
import { CreateBookingJSON } from './../../../utils/BookingFunction';
import PrintPopup from '../Print/PrintPopup';

const PrintItiniary = (props) => {
    const getPageMargins = () => {
        return `@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }`
    };
    let hidePrint = props.showData === '1' ? 'position-relative' : 'position-relative print-d-none';
    const airlineLogoLink = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FLIGHT_LOG_DEVELOPMENT_URL : process.env.REACT_APP_FLIGHT_LOG_PRODUCTION_URL
    let result = props.airItinerary.originDestinationOptions.originDestinationOption;
    let baggageAllowance;
    let rbdValue;
    let baggageAllowanceUnit;
    let outBound_departure_details = [];
    let inBound_departure_details = [];
    let outBound_arrival_details = [];
    let inBound_arrival_details = [];
    let outBound_operating_details = [];
    let multicity_dep_details_map = new Map([]);
    let multicity_arr_details_map = new Map([]);
    let inbound_operating_details = [];
    let multicityFlightCount;

    for (let i in result) {
        baggageAllowance = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight === undefined ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === "1" ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " piece" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " pieces" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight;
        baggageAllowanceUnit = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit;
        rbdValue = result[i].flightSegment[0].resBookDesigCode;
        if (result[i].directionInd === 'I') {
            inBound_departure_details.push({
                departure: result[i].flightSegment[0].departureAirport, date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime, rph: result[i].rph, cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                flownMileageQty: result[i].flightSegment[0].flownMileageQty, duration: result[i].flightSegment[0].duration, dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '', elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime !== undefined ? result[i].flightSegment[0].elapsedLayoverTime : '',
                noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                flightRefNumber: result[i].flightSegment[0].flightRefNumber,
                marketingAirline: result[i].flightSegment[0].marketingAirline,
                technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",
            })
            inBound_arrival_details.push({ arrival: result[i].flightSegment[0].arrivalAirport, date: result[i].flightSegment[0].arrivalDate, time: result[i].flightSegment[0].arrivalTime })
            inbound_operating_details.push(result[i].flightSegment[0].marketingAirline);
        } else if (result[i].directionInd === 'O') {
            outBound_departure_details.push({
                departure: result[i].flightSegment[0].departureAirport, date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime, rph: result[i].rph, cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                flownMileageQty: result[i].flightSegment[0].flownMileageQty, duration: result[i].flightSegment[0].duration, dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '', elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime !== undefined ? result[i].flightSegment[0].elapsedLayoverTime : '',
                noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                flightRefNumber: result[i].flightSegment[0].flightRefNumber,
                equipment: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0] : '',
                resBookDesigCode: result[i].flightSegment[0].resBookDesigCode,
                marketingAirline: result[i].flightSegment[0].marketingAirline,
                operatingAirline: result[i].flightSegment[0].operatingAirline,
                flightID: result[i].flightSegment[0].flightID,
                technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",


            })
            outBound_arrival_details.push({ arrival: result[i].flightSegment[0].arrivalAirport, date: result[i].flightSegment[0].arrivalDate, time: result[i].flightSegment[0].arrivalTime })
            outBound_operating_details.push(result[i].flightSegment[0].marketingAirline);
        }
        else {
            //Multicity Case
            multicityFlightCount = result[i].group;
            if (!multicity_dep_details_map.has(result[i].group)) {
                let multicity_dep_Details = [];
                multicity_dep_Details.push(
                    {
                        departure: result[i].flightSegment[0].departureAirport,
                        date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        duration: result[i].flightSegment[0].duration,
                        cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                        flownMileageQty: result[i].flightSegment[0].flownMileageQty,
                        dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                        equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '',
                        rph: result[i].rph,
                        elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime,
                        noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                        bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                        flightRefNumber: result[i].flightSegment[0].flightRefNumber,
                        technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",

                    });

                multicity_dep_details_map.set(result[i].group, multicity_dep_Details);
            }
            else {
                let temp = [];
                temp = multicity_dep_details_map.get(result[i].group);
                temp.push(
                    {
                        departure: result[i].flightSegment[0].departureAirport,
                        date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        duration: result[i].flightSegment[0].duration,
                        cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                        flownMileageQty: result[i].flightSegment[0].flownMileageQty,
                        dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                        equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '',
                        rph: result[i].rph,
                        elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime,
                        noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                        bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                        flightRefNumber: result[i].flightSegment[0].flightRefNumber,
                        technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",

                    }
                );
                multicity_dep_details_map.set(result[i].group, temp);

            }
            if (!multicity_arr_details_map.has(result[i].group)) {

                let multicity_arr_details = [];
                multicity_arr_details.push(
                    {
                        arrival: result[i].flightSegment[0].arrivalAirport,
                        date: result[i].flightSegment[0].arrivalDate,
                        time: result[i].flightSegment[0].arrivalTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                    }
                );
                multicity_arr_details_map.set(result[i].group, multicity_arr_details);

            } else {
                let temp = [];
                temp = multicity_arr_details_map.get(result[i].group);
                temp.push(
                    {
                        arrival: result[i].flightSegment[0].arrivalAirport,
                        date: result[i].flightSegment[0].arrivalDate,
                        time: result[i].flightSegment[0].arrivalTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                    }
                );
                multicity_arr_details_map.set(result[i].group, temp);
            }
        }
    }

    const getBaggageUnitTextData = (baggageUnit, item) => {
        //let data = (baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k')) && (!baggageUnit.includes('(')) ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        let data = baggageUnit.includes('(') ? '' : baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k') ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        return data;
    }

    const getRefundType = (list) => {
        let refundType = [];
        if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType !== undefined) {
            if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 0 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "0") {
                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non Refundable</div>);
            }
            else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 1 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "1") {
                refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>);
            }
            else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 2 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "2") {
                refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>);
            }
            return refundType;
        }
        else if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined) {
            list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo.forEach(elementFareInfo => {
                if (elementFareInfo.ruleInfo !== undefined && Object.keys(elementFareInfo.ruleInfo).length > 0) {
                    elementFareInfo.ruleInfo !== undefined && elementFareInfo.ruleInfo.chargesRules !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined &&
                        elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.length !== 0
                        && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.forEach(element => {
                            if (element.conditions !== undefined) {
                                if (element.amount === undefined && element.departureStatus === 'Before' && element.conditions === 'NONREFUNDABLE') {
                                    refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non
                                        Refundable</div>);
                                }
                                else if (element.amount !== undefined && parseInt(element.amount) === 0 && element.departureStatus === 'Before' && element.conditions === 'FULL') {
                                    refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>)
                                } else if (element.amount !== undefined && parseInt(element.amount) > 0 && element.departureStatus === 'Before' && element.conditions === 'PARTIAL') {
                                    refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>)
                                }
                            }

                        });
                }
            });
        }

        return refundType.length > 1 ? refundType[0] : refundType;

    }
    const [errorMail, setErrorMail] = useState();
    const onSendEmail = () => {
        let bookingJSON = CreateBookingJSON(props.searchFlight)
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
        setErrorMail({
            title: "Send Mail",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <SendEmail mailData={bookingJSON}></SendEmail>
                </div></div>,
        })


    }

    const fareDetails = (<div>
        <hr className='my-1'></hr>

        <div className='row'>
            <div className='col-md-6'>
                {getRefundType(props.searchFlight)}
            </div>
            <div className='col-md-6'>
                <div className={hidePrint} >
                    <span>
                        <div className='btn btn_theme btn-sm px-2 text-12px email-btn' title='Send a Mail' onClick={() => onSendEmail()}><i className="fas fa-envelope"
                        ></i></div>
                        <div className='btn btn_theme btn-sm px-2 text-12px print-btn ms-1' title='Take a Print'
                        ><i className="fas fa-print"><PrintPopup key={props.key}
                            id={props.id}
                            airItinerary={props.airItinerary}
                            searchFlight={props.searchFlight}
                            showData="2"></PrintPopup></i></div>
                    </span>


                </div>

            </div>
        </div>
    </div>);

    function isMultiAirlines(Airline, Ismulticity) {

        let multiAirline = false;
        for (let i in Airline) {
            if (Ismulticity) {
                if (Airline[0].marketingAirline.code !== Airline[i].marketingAirline.code) {
                    multiAirline = true;
                    break;
                }

            } else {
                if (Airline[0].code !== Airline[i].code) {
                    multiAirline = true;
                    break;
                }
            }


        }
        return multiAirline;
    }

    function showAirlineDetails() {
        var show = [];
        let totalOutboundDuration = props.airItinerary.totalOutBoundDuration;
        if (inBound_departure_details.length === 0 && multicity_dep_details_map.size === 0) {
            let dep_cityName = outBound_departure_details[0].departure.cityName;

            let NoOfStops = outBound_arrival_details.length - 1;
            const totalStops = outBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop = totalStops + NoOfStops;
            let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
            let arr_cityName = outBound_arrival_details[outBound_arrival_details.length - 1].arrival.cityName;
            let flightNumber = outBound_operating_details[0].code + " " + outBound_operating_details[0].flightNumber
            let airlineName = isMultiAirlines(outBound_operating_details, false) === true ? "Multi Airlines" : outBound_operating_details[0].name;
            let logo = airlineLogoLink + outBound_operating_details[0].airlineLogo;
            let arrAirportCode = outBound_arrival_details[NoOfStops].arrival.locationCode === undefined ? "" : outBound_arrival_details[NoOfStops].arrival.locationCode;
            let depAirportCode = outBound_departure_details[0].departure.locationCode === undefined ? "" : outBound_departure_details[0].departure.locationCode;
            let fromAirpotDetails; let fromAirport; let toAirportDetails; let toAirport; let arrInfo; let depInfo;
            if (sessionStorage.getItem('spnFromAirportDetails1') !== null) {
                fromAirpotDetails = sessionStorage.getItem('spnFromAirportDetails1');
                fromAirport = fromAirpotDetails.split('-');
                depInfo = !fromAirport[0].match(depAirportCode) ? "Your depature airport " + `${depAirportCode}` + " is different from your choosen destination " + `${fromAirport[0]}` + "." : "";
            }
            if (sessionStorage.getItem('spnToAirportDetails1') !== null) {
                toAirportDetails = sessionStorage.getItem('spnToAirportDetails1');
                toAirport = toAirportDetails.split('-');
                arrInfo = !toAirport[0].match(arrAirportCode) ? "Your arrival airport " + `${arrAirportCode}` + " is different  from your choosen destination " + `${toAirport[0]}` + "." : "";
            }
            let isAirportChange = (arrInfo !== '' || depInfo !== '') ? false : true;
            show.push(
                <div className="flight_search_items">
                    <div className="multi_city_flight_lists w-100">
                        <div className="flight_multis_area_wrapper">
                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">
                                    {!isMultiAirlines(outBound_operating_details, false) && <img src={logo} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                    <div className="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div className="flight_search_destination">

                                    <p>From</p>
                                    <h3 className='text-black'>{dep_cityName} </h3>
                                    <h6>{getFormatDate(outBound_departure_details[0].date)}, {getFormatTime(outBound_departure_details[0].time)}</h6>

                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalOutboundDuration)}</h6>

                                    <p>{NoOftechStop + " "}{stop} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{arr_cityName}</h3>
                                    <h6>{getFormatDate(outBound_arrival_details[NoOfStops].date)}, {getFormatTime(outBound_arrival_details[NoOfStops].time)}</h6>
                                </div>
                            </div>

                        </div>
                        {fareDetails}
                    </div>
                </div>

            );

            return show;
        }
        else if (multicity_dep_details_map.size !== 0) {
            let totalItineraryDuration = props.airItinerary.groupDurations;
            //Multicity Case
            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let dep = multicity_dep_details_map.get("" + i);
                let arr = multicity_arr_details_map.get("" + i);
                let flightNumber = (multicity_dep_details_map.get("" + i)[0].marketingAirline.code + " " + multicity_dep_details_map.get("" + i)[0].marketingAirline.flightNumber);
                let airlineName = isMultiAirlines(multicity_dep_details_map.get("" + i), true) === true ? "Multi Airlines" : multicity_dep_details_map.get("" + i)[0].marketingAirline.name;
                let logoM = airlineLogoLink + dep[0].marketingAirline.airlineLogo;
                let totalDuration = totalItineraryDuration[i - 1].duration;
                let NoOfStops = dep.length - 1;
                const totalStops = dep.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
                let NoOftechStop = totalStops + NoOfStops;
                let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
                let cityNameM = dep[0].departure.cityName;
                let dateM = getFormatDate(dep[0].date);
                let timeM = getFormatTime(dep[0].time);
                let cityNameA = arr[arr.length - 1].arrival.cityName;
                let dateA = getFormatDate(arr[arr.length - 1].date);
                let timeA = getFormatTime(arr[arr.length - 1].time);
                show.push(
                    <div>
                        {i !== 1 && <hr className='my-1'></hr>}
                        <div className="flight_multis_area_wrapper">

                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">
                                    {!isMultiAirlines(multicity_dep_details_map.get("" + i), true) && <img src={logoM} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                    <div className="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div className="flight_search_destination">
                                    <p>From </p>
                                    <h3 className='text-black'>{cityNameM}</h3>
                                    <h6>{dateM}, {timeM}</h6>

                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalDuration)}</h6>
                                    <p>{NoOftechStop + " "}{stop} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{cityNameA} </h3>
                                    <h6>{dateA}, {timeA}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }

            return show;

        }
        else {
            //round Trip Case
            let dep_cityName = outBound_departure_details[0].departure.cityName;
            let arr_cityName = outBound_arrival_details[outBound_arrival_details.length - 1].arrival.cityName;
            let dep_cityName_r = inBound_departure_details[0].departure.cityName;
            let arr_cityName_r = inBound_arrival_details[inBound_arrival_details.length - 1].arrival.cityName;

            let NoOfStops = outBound_departure_details.length - 1;
            let NoOfstops_r = inBound_departure_details.length - 1;
            const totalStops_r = inBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop_r = totalStops_r + NoOfstops_r;
            const totalStops = outBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop = totalStops + NoOfStops;
            let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
            let stop_r = NoOftechStop_r === 0 || NoOftechStop_r > 1 ? "stops" : "stop";
            let airlineName = isMultiAirlines(outBound_operating_details, false) === true ? "Multi Airlines" : outBound_operating_details[0].name;
            let airlineName_r = isMultiAirlines(inbound_operating_details, false) === true ? "Multi Airlines" : inbound_operating_details[0].name;
            let flightNumber = outBound_operating_details[0].code + " " + outBound_operating_details[0].flightNumber
            let flightNumber_r = inbound_operating_details[inbound_operating_details.length - 1].code + " " + inbound_operating_details[inbound_operating_details.length - 1].flightNumber
            let logo1 = airlineLogoLink + outBound_operating_details[0].airlineLogo;
            let logo2 = airlineLogoLink + inbound_operating_details[0].airlineLogo;
            let totalInBoundDuration = props.airItinerary.totalInBoundDuration;
            let arrAirportCode = outBound_arrival_details[NoOfStops].arrival.locationCode === undefined ? "" : outBound_arrival_details[NoOfStops].arrival.locationCode;
            let depAirportCode = outBound_departure_details[0].departure.locationCode === undefined ? "" : outBound_departure_details[0].departure.locationCode;
            let fromAirpotDetails; let fromAirport; let toAirportDetails; let toAirport; let arrInfo; let depInfo;
            if (sessionStorage.getItem('spnFromAirportDetails1') !== null) {
                fromAirpotDetails = sessionStorage.getItem('spnFromAirportDetails1');
                fromAirport = fromAirpotDetails.split('-');
                depInfo = !fromAirport[0].match(depAirportCode) ? "Your depature airport " + `${depAirportCode}` + " is different from your choosen destination " + `${fromAirport[0]}` + "." : "";
            }
            if (sessionStorage.getItem('spnToAirportDetails1')) {
                toAirportDetails = sessionStorage.getItem('spnToAirportDetails1');
                toAirport = toAirportDetails.split('-');
                arrInfo = !toAirport[0].match(arrAirportCode) ? "Your arrival airport " + `${arrAirportCode}` + " is different  from your choosen destination " + `${toAirport[0]}` + "." : "";

            }

            show.push(
                <div className="flight_search_items">
                    <div className="w-100 multi_city_flight_lists">
                        <div className="flight_multis_area_wrapper">

                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">
                                    {!isMultiAirlines(outBound_operating_details, false) && <img src={logo1} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                    <div className="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div className="flight_search_destination">

                                    <p>From</p>
                                    <h3 className='text-black'>{dep_cityName} </h3>
                                    <h6>{getFormatDate(outBound_departure_details[0].date)}, {getFormatTime(outBound_departure_details[0].time)}</h6>

                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalOutboundDuration)}</h6>
                                    <p>{NoOftechStop + " "}{stop} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{arr_cityName}</h3>
                                    <h6>{getFormatDate(outBound_arrival_details[outBound_arrival_details.length - 1].date)}, {getFormatTime(outBound_arrival_details[outBound_arrival_details.length - 1].time)}</h6>
                                </div>
                            </div>
                        </div>
                        <hr className='my-1'></hr>
                        <div className="flight_multis_area_wrapper">

                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">
                                    {!isMultiAirlines(inbound_operating_details, false) && <img src={logo2} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName_r}</div>
                                    <div className="text-muted text-12px">{flightNumber_r}</div>
                                </div>
                                <div className="flight_search_destination">
                                    <p>From</p>
                                    <h3 className='text-black'>{dep_cityName_r}</h3>
                                    <h6>{getFormatDate(inBound_departure_details[0].date)}, {getFormatTime(inBound_departure_details[0].time)}</h6>
                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalInBoundDuration)}</h6>
                                    <p>{NoOftechStop_r + " "}{stop_r} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{arr_cityName_r} </h3>
                                    <h6>{getFormatDate(inBound_arrival_details[inBound_arrival_details.length - 1].date)}, {getFormatTime(inBound_arrival_details[inBound_arrival_details.length - 1].time)}</h6>
                                </div>
                            </div>
                        </div>
                        {fareDetails}
                    </div>

                </div>
            );

            return show;
        }


    }

    function showDetails() {
        var show = [];
        if (multicity_dep_details_map.size === 0) {

            //one Way 
            for (let i in outBound_departure_details) {
                let depTime = outBound_departure_details[i].time;
                let depDate = outBound_departure_details[i].date;
                let arrTime = outBound_arrival_details[i].time;
                let arrDate = outBound_arrival_details[i].date;
                let depCityCode = outBound_departure_details[i].departure.cityCode;
                let depCityName = outBound_departure_details[i].departure.cityName;
                let depCountryName = outBound_departure_details[i].departure.countryName;
                let depCountryCode = outBound_departure_details[i].departure.countryCode;
                let depAirportName = outBound_departure_details[i].departure.locationName === undefined ? "" : outBound_departure_details[i].departure.locationName;
                let depAirportCode = outBound_departure_details[i].departure.locationCode === undefined ? "" : outBound_departure_details[i].departure.locationCode;
                let depTerminal = outBound_departure_details[i].departure.terminal === undefined ? "" : "Terminal " + outBound_departure_details[i].departure.terminal;
                let flightArrive = outBound_departure_details[i].dateChangeNbr === "1" && outBound_departure_details[i].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                let arrCityCode = outBound_arrival_details[i].arrival.cityCode;
                let arrCityName = outBound_arrival_details[i].arrival.cityName;
                let arrCountryName = outBound_arrival_details[i].arrival.countryName;
                let arrCountryCode = outBound_arrival_details[i].arrival.countryCode;
                let arrTerminal = outBound_arrival_details[i].arrival.terminal === undefined ? "" : "Terminal " + outBound_arrival_details[i].arrival.terminal;
                let NoOftechStop = parseInt(outBound_departure_details[i].noOfStops);
                let stop = NoOftechStop === 0 || NoOftechStop > 1 ? "stops" : "stop";
                let equipmentName = outBound_departure_details[i].equipmentName === undefined ? "" : outBound_departure_details[i].equipmentName;
                let arrAirportName = outBound_arrival_details[i].arrival.locationName === undefined ? "" : outBound_arrival_details[i].arrival.locationName;
                let arrAirportCode = outBound_arrival_details[i].arrival.locationCode === undefined ? "" : outBound_arrival_details[i].arrival.locationCode;
                let logo = airlineLogoLink + outBound_operating_details[i].airlineLogo;
                let timeBetweenFlight = outBound_departure_details[i].elapsedLayoverTime !== undefined ? outBound_departure_details[i].elapsedLayoverTime : "";
                let rph = outBound_departure_details[i].rph;

                let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
                let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit;
                //User Story 2450: Cabin Bag Changes
                let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit;
                let seatInfantBag; let seatInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;
                if (outBound_departure_details[i].bag !== '' || outBound_departure_details[i].bag !== undefined) {
                    outBound_departure_details[i].bag.forEach(element => {
                        if (element.baggageAllowance !== undefined) {
                            if (element.type === 'ADT') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        adultBagUnit = getBaggageUnitTextData(adultBag, item);

                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                    }
                                });

                            }
                            else if (element.type === 'CNN') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        childBagUnit = getBaggageUnitTextData(childBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                    }
                                });
                            }
                            else if (element.type === 'INF') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                    }
                                });
                            }
                            else if (element.type === 'INS') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                    }
                                });

                            }
                        }

                    });
                }

                show.push(
                    <div>
                        {timeBetweenFlight !== "" &&
                            <div className='row'>
                                <div className='col-md-12 text-center gt-time-btn-flight'>Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                            </div>
                        }
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='departure-text'>

                                    <span><i className="fas fa-plane-departure"></i></span> Departure {outBound_departure_details[i].flightRefNumber !== undefined && <span className='text-primary airlineName fw-500'> Airline PNR:  {outBound_departure_details[i].flightRefNumber}</span>}
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='pt-10 float-md-end float-lg-end'>

                                </div>
                            </div>
                        </div>
                        <div>

                            <div className="flight_show_down_wrapper d-flex w-100">

                                <div className="flight-shoe_dow_item col-md-9 sdc">
                                    <div className="airline-details ">
                                        <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                        <span className="airlineName fw-500">{outBound_operating_details[i].name} &nbsp;
                                            {outBound_operating_details[i].code + " " + outBound_operating_details[i].flightNumber}
                                        </span> <span className='fw-500 text-white badge rounded-pill class-purple-bg airlineName fw-500'> {outBound_departure_details[i].cabinName}</span>
                                        <span className="flightNumber">{equipmentName}</span>
                                    </div>
                                    <div className="flight_inner_show_component">
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{depCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(depTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBound_departure_details[i].date)}</p>
                                                <p className="airport">{depAirPortInfo}
                                                </p>
                                                <p className="airport mb-2">{depTerminal}</p>

                                            </div>
                                        </div>
                                        <div className="flight_duration">
                                            <div className="arrow_right"></div>
                                            <span>{getMinutetoHrsMin(outBound_departure_details[i].duration)}</span>
                                            <p> {NoOftechStop + " "} {stop}</p>
                                        </div>
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{arrCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(arrTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBound_arrival_details[i].date)}</p>
                                                <p className="airport">{arrAirPortInfo}
                                                </p>
                                                <p className="airport mb-2">{arrTerminal}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        {flightArrive !== '' && <div className='col-md-6'>
                                            <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle text-danger"></i>{" " + flightArrive} </div>
                                        </div>}
                                        <div className='col-md-6'>
                                            <div className='text-primary text-14px fw-bold'>
                                                <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {outBound_departure_details[i].flownMileageQty}</span> 

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flight_refund_policy col-md-3">
                                    <div className="TabPanelInner col-sm-12">
                                        <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                        <div className="flight_info_taable">

                                            {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                            {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                            {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                            {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                            {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                undefined && seatInfantBag === undefined && <p>Information
                                                    unavailable for this flight.</p>}
                                        </div>
                                        <div className="flight_info_taable">
                                            {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                            {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                            {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                            {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                            {cabinAdultBag === undefined &&
                                                cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                && <p>Information unavailable for this flight.</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight'>Technical Stop: {getMinutetoHrsMin(outBound_departure_details[i].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {outBound_departure_details[i].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}

                            </div>
                        </div>

                    </div>


                );
            }
            if (inBound_departure_details.length !== 0) {
                for (let i in inBound_departure_details) {
                    let flightArrive = inBound_departure_details[i].dateChangeNbr === '1' && inBound_departure_details[i].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                    let inDepTime = inBound_departure_details[i].time;
                    let inArrTime = inBound_arrival_details[i].time;
                    let inDepCityCode = inBound_departure_details[i].departure.cityCode;
                    let inDepCityName = inBound_departure_details[i].departure.cityName;
                    let inDepCountryName = inBound_departure_details[i].departure.countryName;
                    let inDepCountryCode = inBound_departure_details[i].departure.countryCode;
                    let inDepAirportName = inBound_departure_details[i].departure.locationName === undefined ? "" : inBound_departure_details[i].departure.locationName;
                    let inDepAirportCode = inBound_departure_details[i].departure.locationCode;
                    let inDepTerminal = inBound_departure_details[i].departure.terminal === undefined ? "" : "Terminal " + inBound_departure_details[i].departure.terminal;
                    let len = inBound_departure_details.length - 1;
                    let inArrCityCode = inBound_arrival_details[i].arrival.cityCode;
                    let inArrCityName = inBound_arrival_details[i].arrival.cityName;
                    let inArrCountryName = inBound_arrival_details[i].arrival.countryName;
                    let inArrCountryCode = inBound_arrival_details[i].arrival.countryCode;
                    let inArrAirportName = inBound_arrival_details[i].arrival.locationName === undefined ? "" : inBound_arrival_details[i].arrival.locationName;
                    let inArrAirportCode = inBound_arrival_details[i].arrival.locationCode === undefined ? "" : inBound_arrival_details[i].arrival.locationCode;
                    let inArrTerminal = inBound_arrival_details[i].arrival.terminal === undefined ? "" : "Terminal " + inBound_arrival_details[i].arrival.terminal;
                    let equipmentName = inBound_departure_details[i].equipmentName === undefined ? "" : inBound_departure_details[i].equipmentName;
                    let logo = airlineLogoLink + inbound_operating_details[i].airlineLogo;
                    let timeBetweenFlight = inBound_departure_details[i].elapsedLayoverTime !== undefined ? inBound_departure_details[i].elapsedLayoverTime : "";
                    let rph = inBound_departure_details[i].rph;
                    let inDepAirPortInfo = inDepAirportName + "(" + inDepAirportCode + ")" + ", " + inDepCountryName + "(" + inDepCountryCode + ")";
                    let inArrAirPortInfo = inArrAirportName + "(" + inArrAirportCode + ")" + ", " + inArrCountryName + "(" + inArrCountryCode + ")";
                    let NoOftechStop = parseInt(inBound_departure_details[i].noOfStops);
                    let stop = NoOftechStop === 0 || NoOftechStop > 1 ? "stops" : "stop";
                    let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit;
                    //User Story 2450: Cabin Bag Chnages
                    let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit;
                    let seatInfantBag; let seatInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;
                    if (inBound_departure_details[i].bag !== '' || inBound_departure_details[i].bag !== undefined) {
                        inBound_departure_details[i].bag.forEach(element => {
                            if (element.baggageAllowance !== undefined) {
                                if (element.type === 'ADT') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight;
                                            adultBagUnit = getBaggageUnitTextData(adultBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'CNN') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            childBagUnit = getBaggageUnitTextData(childBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INF') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INS') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                        }
                                    });

                                }
                            }
                        });
                    }


                    show.push(
                        <div>
                            {timeBetweenFlight === '' && <hr className='my-1'></hr>}
                            {timeBetweenFlight !== "" &&
                                <div className='row'>
                                    <div className='col-md-12 text-center gt-time-btn-flight'>Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='departure-text'>
                                        <span><i className="fas fa-plane-departure"></i></span> Return {inBound_departure_details[i].flightRefNumber !== undefined &&
                                            <span className='text-primary airlineName fw-500'> Airline PNR:  {inBound_departure_details[i].flightRefNumber}</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='pt-10 float-md-end float-lg-end'>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flight_show_down_wrapper d-flex w-100">
                                    <div className="flight-shoe_dow_item col-md-9">
                                        <div className="airline-details">
                                            <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                            <span className="airlineName fw-500">{inbound_operating_details[i].name} &nbsp;
                                                {inbound_operating_details[i].code + " " + inbound_operating_details[i].flightNumber}
                                            </span> <span className='fw-500 text-white badge rounded-pill class-purple-bg airlineName fw-500'> {inBound_departure_details[i].cabinName}</span>
                                            <span className="flightNumber">{equipmentName}</span>
                                        </div>
                                        <div className="flight_inner_show_component">
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{inDepCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(inDepTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(inBound_departure_details[i].date)}</p>
                                                    <p className="airport">{inDepAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{inDepTerminal}</p>

                                                </div>
                                            </div>
                                            <div className="flight_duration">
                                                <div className="arrow_right"></div>
                                                <span>{getMinutetoHrsMin(inBound_departure_details[i].duration)}</span>
                                                <p> {NoOftechStop + " "}{stop}</p>
                                            </div>
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{inArrCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(inArrTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(inBound_arrival_details[i].date)}</p>
                                                    <p className="airport">{inArrAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{inArrTerminal}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            {
                                                flightArrive !== '' && <div className='col-md-6'>
                                                    <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle text-danger"></i>{" " + flightArrive} </div>
                                                </div>
                                            }

                                            <div className='col-md-6'>
                                                <div className='text-primary text-14px fw-bold'>
                                                    <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {inBound_departure_details[i].flownMileageQty}</span> 
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight_refund_policy col-md-3">
                                        <div className="TabPanelInner col-sm-12">
                                            <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                            <div className="flight_info_taable">
                                                {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                                {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                                {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                                {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                                {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                    undefined && seatInfantBag === undefined && <p>Information
                                                        unavailable for this flight.</p>}
                                            </div>
                                            <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                            <div className="flight_info_taable">

                                                {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                                {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                                {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                                {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                                {cabinAdultBag === undefined &&
                                                    cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                    && <p>Information unavailable for this flight.</p>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight'>Technical Stop: {getMinutetoHrsMin(inBound_departure_details[i].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {inBound_departure_details[i].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}
                            </div>
                        </div>
                    )
                }
            }
            return show;

        }
        else {

            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let temp = multicity_dep_details_map.get("" + i);

                let arr = multicity_arr_details_map.get("" + i);

                for (let j = 0; j < temp.length; j++) {
                    let cabinName = temp[j].cabinName;
                    let duration = temp[j].duration;
                    let depCityName = temp[j].departure.cityName;
                    let depTime = temp[j].time
                    let depDate = temp[j].date;
                    let depCityCode = temp[j].departure.cityCode;
                    let depCountryName = temp[j].departure.countryName;
                    let depCountryCode = temp[j].departure.countryCode;
                    let depAirportName = temp[j].departure.locationName === undefined ? "" : temp[j].departure.locationName;
                    let depAirportCode = temp[j].departure.locationCode === undefined ? "" : temp[j].departure.locationCode;
                    let depTerminal = temp[j].departure.terminal === undefined ? "" : "Terminal " + temp[j].departure.terminal;
                    let flightArrive = temp[j].dateChangeNbr === '1' && temp[j].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                    let equipmentName = temp[j].equipmentName === undefined ? "" : temp[j].equipmentName;
                    let logo = airlineLogoLink + temp[j].marketingAirline.airlineLogo;
                    let timeBetweenFlight = temp[j].elapsedLayoverTime !== undefined ? temp[j].elapsedLayoverTime : "";
                    let rph = temp[j].rph;
                    let NoOftechStop = parseInt(temp[j].noOfStops);
                    let stop = NoOftechStop === 0 || NoOftechStop > 1 ? "stops" : "stop";
                    let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit;
                    //User Story 2450: Cabin Bag Chnages
                    let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit;
                    let seatInfantBag; let seatInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;
                    if (temp[j].bag !== '' || temp[j].bag !== undefined) {
                        temp[j].bag.forEach(element => {
                            if (element.baggageAllowance !== undefined) {
                                if (element.type === 'ADT') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight;
                                            adultBagUnit = getBaggageUnitTextData(adultBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'CNN') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            childBagUnit = getBaggageUnitTextData(childBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INF') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                        }
                                    });
                                }
                            }

                        });
                    }

                    let arrCityName = arr[j].arrival.cityName;
                    let arrTime = arr[j].time;
                    let arrDate = arr[j].date;
                    let arrCityCode = arr[j].arrival.cityCode;
                    let arrCountryName = arr[j].arrival.countryName;
                    let arrCountryCode = arr[j].arrival.countryCode;
                    let arrTerminal = arr[j].arrival.terminal === undefined ? "" : "Terminal " + arr[j].arrival.terminal;
                    let arrAirportName = arr[j].arrival.locationName;
                    let arrAirportCode = arr[j].arrival.locationCode;
                    let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                    let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";




                    show.push(
                        <div>
                            {(i !== 1 && j === 0) && <hr className='my-1'></hr>}
                            {timeBetweenFlight !== '' &&
                                <div className='row'>
                                    <div className='col-md-12 text-center gt-time-btn-flight'>Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='departure-text'>
                                        <span><i className="fas fa-plane-departure"></i></span> Departure {temp[j].flightRefNumber !== undefined &&

                                            <span className='text-primary airlineName fw-500'> Airline PNR: {temp[j].flightRefNumber}</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-8'>

                                    <div className='pt-10 float-md-end float-lg-end'>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flight_show_down_wrapper d-flex w-100">

                                    <div className="flight-shoe_dow_item col-md-9">
                                        <div className="airline-details">
                                            <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                            <span className="airlineName fw-500">{temp[j].marketingAirline.name} &nbsp;
                                                {temp[j].marketingAirline.code + " " + temp[j].marketingAirline.flightNumber}
                                            </span> <span className='fw-500 text-white badge rounded-pill class-purple-bg airlineName fw-500'> {cabinName} </span>
                                            <span className="flightNumber">{equipmentName}</span>
                                        </div>
                                        <div className="flight_inner_show_component">
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{depCityName}</span>

                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(depTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(depDate)}</p>
                                                    <p className="airport">{depAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{depTerminal}</p>

                                                </div>
                                            </div>
                                            <div className="flight_duration">
                                                <div className="arrow_right"></div>
                                                <span>{getMinutetoHrsMin(duration)}</span>
                                                <p> {NoOftechStop + " "}{stop}</p>
                                            </div>
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{arrCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(arrTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(arrDate)}</p>
                                                    <p className="airport">{arrAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{arrTerminal}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            {flightArrive !== '' && <div className='col-md-6'>
                                                <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle"></i>{" " + flightArrive} </div>
                                            </div>}
                                            <div className='col-md-6'>
                                                <div className='text-primary text-14px fw-bold'>
                                                    <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {temp[j].flownMileageQty}</span> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight_refund_policy col-md-3">
                                        <div className="TabPanelInner col-sm-12">
                                            <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                            <div className="flight_info_taable">

                                                {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                                {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                                {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                                {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                                {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                    undefined && seatInfantBag === undefined && <p>Information
                                                        unavailable for this flight.</p>}
                                            </div>
                                            <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                            <div className="flight_info_taable">

                                                {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                                {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                                {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                                {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                                {cabinAdultBag === undefined &&
                                                    cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                    && <p>Information unavailable for this flight.</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight'>Technical Stop: {getMinutetoHrsMin(temp[j].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {temp[j].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}
                            </div>
                        </div>
                    );
                }


            }
            return show;
        }

        return show;
    }

    return (
        <Fragment >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3 gt-test  " >
                <style>{getPageMargins()}</style>
                <div className="col-md-12" >

                    <div className="theme_search_form_area">

                        <div className='row'>
                            <div className='col-md-6'>
                                <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Recommended Flights </label>

                            </div>

                            <div className='row'>
                                <div className='col-md-4'>
                                    <label for="" className="h6 text-primary mb-2"></label>
                                </div>


                            </div>
                            <div className='col-md-12'></div>
                            {multicity_dep_details_map.size !== 0 &&
                                <>
                                    <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="multi_city_flight_lists w-100">
                                                {showAirlineDetails()}
                                                {fareDetails}
                                            </div>
                                        </div>
                                        <div data="collapseExampleCommon" className={`${props.showData === '3' ? "flight_policy_refund collapse show" : " flight_policy_refund collapse"}`} id={"collapseExample" + props.id}>
                                            {showDetails()}
                                        </div>

                                    </div>
                                </>


                            }
                            {multicity_dep_details_map.size === 0 && <div className="flight_search_item_wrappper">
                                {showAirlineDetails()}
                                <div data="collapseExampleCommon" className={`${props.showData === '3' ? "flight_policy_refund collapse show" : " flight_policy_refund collapse"}`} id={"collapseExample" + props.id}>
                                    {showDetails()}
                                </div>

                            </div>}
                            <FareSummary FlightData={props.searchFlight} hideOtherServices={props.hideData} hideIcon="1"></FareSummary>

                        </div>

                    </div>

                </div>

            </section>
        </Fragment>
    );
};

export default PrintItiniary

import React, { useState, useEffect, useCallback } from 'react';

import { getConfigurationByValue } from '../../utils/ConfigurationsFunction'
import moment from 'moment';
let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: (token) => { },
  logout: () => { },
  loginEmailID: '',
  userID: '',
  officeID: '',
  displayName: '',
  roleDetails: '',
  customizeDetails: ''
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();


  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600 && getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('loginEmailID');
    localStorage.removeItem('userID');
    localStorage.removeItem('officeID');
    localStorage.removeItem('displayName');
    localStorage.removeItem('roleDetails');
    //localStorage.removeItem('customizeDetails');
    localStorage.removeItem('companyName');
    localStorage.removeItem('traceID');
    localStorage.removeItem('RootLoginEmailID');
    localStorage.removeItem('BookingData');
    localStorage.removeItem('ConfigurationData');
    localStorage.removeItem('IPAddress');
    localStorage.removeItem('Bookingdata');
    localStorage.removeItem('CUSTOMIZE_ID');
    localStorage.removeItem('isExist');
    localStorage.removeItem('profileLocaterNumber');
    //localStorage.removeItem('configurationsDetails');
    localStorage.removeItem('ideleTimeEnable');
    localStorage.removeItem('idleTimeout');
    localStorage.removeItem('isTravelAgent');
    localStorage.removeItem('isCorporate');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('phoneCode');
    localStorage.removeItem('email');
    //secureLocalStorage.removeItem("loginData")
    sessionStorage.clear();

    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }
  const [token, setToken] = useState(initialToken);

  let userIsLoggedIn;





  userIsLoggedIn = !!token;
  const today = moment(new Date());

  const endDate = localStorage.getItem("SessionLoginDateTime") === null ? moment(new Date()) : moment(JSON.parse(localStorage.getItem("SessionLoginDateTime")));
  var duration = moment.duration(today.diff(endDate));

  if (!window.location.href.includes("MFA") && duration._milliseconds > 1200000 && getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
    localStorage.clear();
    userIsLoggedIn = false;
  }

  useEffect(() => {
    localStorage.getItem("token") != null && localStorage.setItem("SessionLoginDateTime", JSON.stringify(today))

  }, []);







  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('idToken');
    localStorage.removeItem('userIDLogin');
    localStorage.removeItem('loginEmailID');
    localStorage.removeItem('userID');
    localStorage.removeItem('officeID');
    localStorage.removeItem('displayName');
    localStorage.removeItem('roleDetails');
    localStorage.removeItem('customizeDetails');
    localStorage.removeItem("loginData")
    localStorage.removeItem('companyName');
    localStorage.removeItem('traceID');
    localStorage.removeItem('RootLoginEmailID');
    localStorage.removeItem('BookingData');
    localStorage.removeItem('ConfigurationData');
    localStorage.removeItem('IPAddress');
    localStorage.removeItem('Bookingdata');
    localStorage.removeItem('CUSTOMIZE_ID');
    localStorage.removeItem('isExist');
    localStorage.removeItem('profileLocaterNumber');
    localStorage.removeItem('configurationsDetails');
    localStorage.removeItem('ideleTimeEnable');
    localStorage.removeItem('idleTimeout');
    localStorage.removeItem('isTravelAgent');
    localStorage.removeItem('isCorporate');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('phoneCode');
    localStorage.removeItem('email');
    localStorage.removeItem('SessionLoginDateTime');
    localStorage.removeItem('fromToListResponse');
    localStorage.removeItem('airportSuggestion');
    localStorage.removeItem('IsPasswordUpdate');
    localStorage.removeItem('LoginDateTime');
    localStorage.removeItem('parentOfficeID');
    localStorage.removeItem('enable2FA');
    localStorage.removeItem('tabActiveStatusSet');
    localStorage.removeItem('parentOfficeID');
    localStorage.removeItem('dkNumber');
    localStorage.removeItem('blockedCountryCity');
    localStorage.removeItem('loginEmailIDLogin');
    localStorage.removeItem('sightseeingSuggestion');
    localStorage.removeItem('token');
    localStorage.removeItem('IPAddress');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime, loginEmailID, userID,
    officeID, displayName, roleDetails, customizeDetails, companyName, traceID, profileLocaterNumber,
    configurationsDetails, dkNumber, parentOfficeID, fromToListResponse, isCorporate, isTravelAgent,
    email, phoneCode, phoneNumber, loginData) => {
    setToken(token);

    localStorage.setItem('idToken', token);
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('loginEmailID', JSON.stringify(loginEmailID));
    localStorage.setItem('userID', JSON.stringify(userID));
    localStorage.setItem('officeID', JSON.stringify(officeID));
    localStorage.setItem('parentOfficeID', JSON.stringify(parentOfficeID));
    localStorage.setItem('displayName', JSON.stringify(displayName));
    localStorage.setItem('roleDetails', JSON.stringify(roleDetails));

    localStorage.setItem('companyName', JSON.stringify(companyName));
    localStorage.setItem('traceID', JSON.stringify(traceID));
    localStorage.setItem('profileLocaterNumber', JSON.stringify(profileLocaterNumber));
    localStorage.setItem('dkNumber', JSON.stringify(dkNumber));

    localStorage.setItem('fromToListResponse', JSON.stringify(fromToListResponse));
    localStorage.setItem('isCorporate', JSON.stringify(isCorporate));
    localStorage.setItem('isTravelAgent', JSON.stringify(isTravelAgent));
    localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
    localStorage.setItem('phoneCode', JSON.stringify(phoneCode));
    localStorage.setItem('email', JSON.stringify(email));
    localStorage.setItem('loginData', JSON.stringify(loginData));
    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
    /*  if (getConfigurationByBoolen("ALLOW_FLIGHT"))
       return '';
     // window.location.pathname = `${FLIGHTSEARCH}`;
     //return <Redirect to={FLIGHTSEARCH}></Redirect>
     else if (getConfigurationByBoolen("ALLOW_HOTEL"))
       window.location.pathname = `${SEARCHHOTEL}`;
     else
       window.location.pathname = `${DASHBOARD}`;
     //return <Redirect to={DASHBOARD}></Redirect> */

  };

  useEffect(() => {
    if (tokenData) {
      //logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
import secureLocalStorage from 'react-secure-storage';
import { dateList, monthList } from '../utils/DropdownData';
import { getConfigurationByValue } from '../utils/ConfigurationsFunction';
import { CLIENT_DOMAIN, CUSTOMIZE_IMAGE_URL, DOMAIN_IGNORE_CUSTOMIZATION } from '../config/CONSTANTS';
import { LOGIN } from '../navigation/CONSTANTS';
import { getCustomizeDetails } from './CustomizeUtils';
export const getFormatDate = (date) => {
    if (date === '' || date === undefined || date.includes('/') || date.includes('-') || date.includes(',')) {
        return "";
    }
    let dateModified = dateList.filter(a => a.value === date.slice(6))[0].label;
    let monthModified = monthList.filter(a => a.value === date.slice(4, 6))[0].label;
    let fullDate = dateModified + " " + monthModified + " " + date.slice(0, 4);
    return fullDate;
}
export function splitString(str) {
    const parts = str.split(/\s{2,}/); // Split the string on 2 or more spaces
    return parts;
}
export function removeFirstAndLastSpecialChar(str) {
    const specialChars = /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g;
    return str.replace(specialChars, '');
}

export const getFormatTime = (time) => {
    if (time === '' || time === undefined) {
        return '';
    }
    time = time.replaceAll(":", "");
    let hour = '';
    let min = '';
    let formattedTime = '';

    if (time.includes("-")) {
        time.split("-").forEach(elementTime => {
            hour = elementTime.slice(0, 2);
            min = elementTime.slice(2);
            if (formattedTime === '') {
                formattedTime = timeConvert(hour + ":" + min);
            }
            else {
                formattedTime = formattedTime + " - " + timeConvert(hour + ":" + min);
            }
        });
    }
    else {
        if (time.includes('AM') || time.includes('PM')) {
            if (time.length < 7) {
                hour = time.slice(0, 1);
                min = time.slice(1);
            } else {
                hour = time.slice(0, 2);
                min = time.slice(2);
            }
        } else {
            if (time.match('^[a-zA-Z]+$')) {
                hour = time;
                min = '';
            }
            else {
                hour = time.slice(0, 2);
                min = time.slice(2);
            }
        }

        if (time.match('^[a-zA-Z]+$')) {
            formattedTime = time;
        }
        else {
            formattedTime = timeConvert(hour + ":" + min);
        }
    }

    return formattedTime;
}

export const getMinutetoHrsMin = (time) => {
    if (time === '0' || time === '' || time === 0 || time === undefined) {
        return "";
    }
    var Hours = Math.floor(time / 60)
    var minutes = time % 60
    return Hours + "h " + minutes + "m";
}

export function toCamelCase(str) {
    return str.replace(/[-_](.)/g, (_, c) => c.toUpperCase());
}
export function toPascalCase(string) {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}
export function toTitleCase(str) {
    if (str !== undefined && str !== null && str !== '') {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
}


export function timeConvert(time) {
    if (time === undefined) {
        time = "00:00";
    }

    if (time.toString().split(':')[0].length < 2) {
        time = "0" + time.toString().split(':')[0] + ":" + time.toString().split(':')[1];


    }
    if (time.toString().split(':')[1].length < 2) {
        time = time.toString().split(':')[0] + ":0" + time.toString().split(':')[1];

    }

    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}
export function breakTeaxtOnFullStop(text) {
    let show;
    if (text !== "" && text !== undefined) {
        // Remove '\n' from the string

        const removedNewlines = text.replace(/\n/g, ' ');
        const sentences = removedNewlines.split(/(?<=(?<!\d\.)\s*(?<!i\.e\.)\.\s*)(?!\S)/);
        if (sentences.length !== 0) {
            let desc = [];
            let sentencesKVP = [];

            sentences.forEach((item, index) => {
                if (item.trim() !== '.' && item !== '') {
                    sentencesKVP.push({
                        key: index + 1,
                        value: item
                    });
                }
            });

            sentencesKVP.forEach(kvp => {
                desc.push(<div key={kvp.key} className='display-line mb-1'>{removeHtmlTags(kvp.value)}</div>);
            });

            show = desc;
        } else {
            show = removeHtmlTags(text);
        }

    }
    return show;
}
export function calculateAge(dob) {
    let today = new Date();
    let birthDate = new Date(
        dob.slice(0, 4),
        dob.slice(4, 6) - 1,
        dob.slice(6, 8)
    );

    let ageInMilliseconds = today - birthDate;
    let ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

    let tempDate = new Date(birthDate.getFullYear() + ageInYears, birthDate.getMonth(), birthDate.getDate());
    if (tempDate > today) {
        ageInYears--;
    }

    if (ageInYears >= 1) {
        return ageInYears + (ageInYears === 1 ? " year" : " years");
    } else {
        let ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12 + today.getMonth() - birthDate.getMonth();
        let daysDiff = today.getDate() - birthDate.getDate();

        // Adjust daysDiff if negative
        if (daysDiff < 0) {
            let previousMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
            daysDiff += previousMonth.getDate();
            ageInMonths--; // Decrease months since we've borrowed days from the previous month
        }

        // Return days if age is less than 1 month
        if (ageInMonths === 0) {
            return daysDiff + (daysDiff === 1 ? " day" : " days");
        }

        return ageInMonths + (ageInMonths === 1 ? " month" : " months");
    }
}





export function getAge(date, personType) {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    // Example date of birth.
    let dobDate = new Date(date.slice(0, 4), date.slice(4, 6), date.slice(6));
    // var dobDate = new Date("03/31/2001");

    var years = nowDate.getFullYear() - dobDate.getFullYear();
    var months = nowDate.getMonth() - dobDate.getMonth();
    var days = nowDate.getDate() - dobDate.getDate();

    // Work out the difference in months.
    months += years * 12;
    if (days < 0) {
        months -= 1;
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months);
    // Calculate the difference in milliseconds.
    var diff = nowDate - dobDate;
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000);
    // Now convert months back to years and months.
    years = parseInt(months / 12);
    months -= (years * 12);
    // Format age as "xx years, yy months, zz days"
    var text = "";
    if (years) {
        text = years + (years > 1 ? " years" : " year");
    }
    if (personType === 'ADT' || personType === 'CNN') {
        return text;
    }
    else {

        if (years >= 1) {
            return text;
        }
        else if (months >= 1) {
            if (months) {
                return months + (months > 1 ? " months" : " month")
            }
        }
        else {
            if (days) {
                return days + (days > 1 ? " days" : " day");
            }
        }

    }

    return text;

}

// function to validate credit card numbers using the Luhn algorithm
export function validateByLuhn(cardNumber) {
    let sum = 0;
    let shouldDouble = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i));
        if (shouldDouble) {
            if ((digit *= 2) > 9) digit = digit - 9;
        }
        sum = sum + digit;
        shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
}


export const formatDate = (date) => {
    const parts = date.split(/[- :]/);
    const wanted = `${parts[2]}/${parts[1]}/${parts[0]} ${parts[3]}:${parts[4]}`;
    return wanted;
}

export const getDateDiffDays = (sourceDate, targetDate) => {
    if (sourceDate === undefined || targetDate === undefined) {
        return "";
    }

    var dateSource = new Date(sourceDate.slice(4, 6) + "/" + sourceDate.slice(6) + "/" + sourceDate.slice(0, 4));
    var dateTarget = new Date(targetDate.slice(4, 6) + "/" + targetDate.slice(6) + "/" + targetDate.slice(0, 4));

    let diffTime = dateTarget.getTime() - dateSource.getTime();
    let diffDays = diffTime / (1000 * 3600 * 24);
    return diffDays;
}

export function removeHtmlTags(str) {
    if (str === '' || str === undefined) {
        return "";
    }
    return str.replace(/(<([^>]+)>| |amp;|&apos;|apos;|aposs|&amp;|\?)/ig, '');
}
export function containsOnlyDots(str) {
    // Check if the string is only made up of dots
    return /^[.]+$/.test(str);
}
export function removeSpacesAndSpecialChars(str) {
    // Remove all special characters and spaces using regex
    return str.replace(/[^a-zA-Z0-9]/g, '');
}
export function convertToCamelCase(str) {
    // Remove leading and trailing whitespaces
    str = str.trim();

    // Split the string into words
    const words = str.split(/\b/);

    // Convert each word to camel case
    const camelCaseWords = words.map((word, index) => {
        // Skip non-alphanumeric characters
        if (!word.match(/[a-zA-Z0-9]/)) {
            return word;
        }

        // Convert to camel case
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words together
    const camelCaseString = camelCaseWords.join('');

    return camelCaseString;
}

// Example usage
const inputString = "NOTE - THE FOLLOWING TEXT IS INFORMATIONAL AND NOT VALIDATED FOR AUTOPRICING. BASIC ECONOMY FARES APPLICATION CLASS OF SERVICE THESE FARES APPLY FOR ECONOMY CLASS SERVICE. CAPACITY LIMITATIONS SEATS ARE LIMITED. OTHER CONDITIONS NON REFUNDABLE FARES CHANGES ALLOWED FOR TICKETING ON/BEFORE 31MAY20 NO PRERESERVED SEATS UNUSED COUPONS HAVE NO RESIDUAL VALUE.";

const camelCaseResult = convertToCamelCase(inputString);


export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
export function removeStartingNewlines(string) {
    while (string.startsWith("\n")) {
        string = string.slice(1);
    }
    return string;
}

export function removeStartingAndEndingNewlines(string) {
    while (string.startsWith("\n")) {
        string = string.slice(1);
    }
    while (string.endsWith("\n")) {
        string = string.slice(0, -1);
    }
    return string;
}

export const getMinutetoHrsWithoutFormat = (time) => {
    var hours = Math.floor(time / 60)
    var minutes = time % 60
    return hours + " " + minutes;
}

export const getHrstoMinuteWithoutFormat = (time) => {
    var timeParts = time.split(":");
    return Number(timeParts[0] === undefined || timeParts[0] === '' ? 0 : timeParts[0]) * 60 + Number(timeParts[1] === undefined || timeParts[1] === '' ? 0 : timeParts[1]);
}

export function parseHotelPolicy(policy) {

    if (policy === '' || policy === undefined) {
        return;
    }
    let check = policy.includes("\n\n");
    const hotelPolicyMap = new Map();
    const hotelPolicyArray = [];
    if (check) {
        let hotelPolicy = policy.split("\n\n");
        for (let items of hotelPolicy) {
            if (items.length > 0) {
                if (!items.toLowerCase().includes("commission")) {

                    let content = items.replace(/\n/g, "-");
                    let check = content.includes("-");
                    if (check) {

                        let text;
                        if (content.trim().substring(0, 1) === '-') {
                            text = content.substring(1);
                        } else {
                            text = content;
                        }
                        let labelValue = text.split("-")

                        if (labelValue.length > 0) {

                            hotelPolicyMap.set(labelValue[0], labelValue.slice(1).join(" "));



                        }
                    } else {
                        hotelPolicyMap.set("", items)
                    }


                } else {


                }

            }
        }
    } else {
        let hotelPolicy = policy.split("\n");
        for (let items of hotelPolicy) {
            if (items.length > 0) {
                if (!items.toLowerCase().includes("commission") && !items.toLowerCase().includes('wps')) {
                    let text;
                    if (items.includes('|')) {
                        text = items.replace('|', '')
                    } else {
                        text = items;
                    }
                    hotelPolicyArray.push(text);
                }

            }

        }



    }


    let showPolicy = [];
    for (let [key, value] of hotelPolicyMap) {

        showPolicy.push(<div key={key + value} className='mb-3'>
            <div className='fw-bold text-primary mb-2'>
                {key}
            </div>
            <div className='display-line mb-1'>{removeHtmlTags(value)}</div>
        </div>)

    }
    for (let items of hotelPolicyArray) {
        showPolicy.push(<div className='mb-3'>{removeHtmlTags(items)}</div>)
    }
    return showPolicy

}
export const getRefundType = (list) => {
    let refundType = [];
    if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType !== undefined) {
        if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 0 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "0") {
            refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non Refundable</div>);
        }
        else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 1 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "1") {
            refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>);
        }
        else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 2 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "2") {
            refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>);
        }
        return refundType;
    }
    else if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined) {
        list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo.forEach(elementFareInfo => {
            if (elementFareInfo.ruleInfo !== undefined && Object.keys(elementFareInfo.ruleInfo).length > 0) {
                elementFareInfo.ruleInfo !== undefined && elementFareInfo.ruleInfo.chargesRules !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined &&
                    elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.length !== 0
                    && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.forEach(element => {
                        if (element.conditions !== undefined) {
                            if (element.amount === undefined && element.departureStatus === 'Before' && element.conditions === 'NONREFUNDABLE') {
                                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non
                                    Refundable</div>);
                            }
                            else if (element.amount !== undefined && parseInt(element.amount) === 0 && element.departureStatus === 'Before' && element.conditions === 'FULL') {
                                refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>)
                            } else if (element.amount !== undefined && parseInt(element.amount) > 0 && element.departureStatus === 'Before' && element.conditions === 'PARTIAL') {
                                refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>)
                            }
                        }

                    });
            }
        });
    }

    return refundType.length > 1 ? refundType[0] : refundType;

}

function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

export function CustomToFixed(num, scale) {
    return roundNumber(num, scale).toFixed(scale);
}
export function objectToArray(data) {
    let arrayData = [];
    let options = [];
    for (let i in data) {
        if (data['errors'].status === 'FALSE') {
            if (i === 'data') {
                arrayData = data[i];
            }
        }
    }
    for (let index = 0; index < arrayData.length; index++) {
        options = [...options, { value: arrayData[index].id, label: arrayData[index].name }]
    }
    return options;

}

export function calculateRoeAmountMain(resultCurrency, bookingJSON, isTotalFare = false, isRoe = false, isWalletAmount = false) {
    let decimalPlaces = parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2');

    if (secureLocalStorage.getItem("searchRoeResult") !== null) {
        let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';        
        let calculatedAmount = 0;
        let finalPrice = 0;
        let calculatedRoe = 0;
        let totalCommoission = 0.0;
        let totalMarkup = 0.0;
        let serviceFee = 0.0;

        if (isRoe) {
            if (resultCurrency.toString().toUpperCase() !== walletCurrency.toString().toUpperCase()) {
                let searchRoeResult = JSON.parse(secureLocalStorage.getItem("searchRoeResult"));
                let filteredSearchRoeResult = searchRoeResult.filter(a => a.baseCurrency === resultCurrency && a.currency === walletCurrency);
                if (filteredSearchRoeResult.length > 0) {
                    calculatedRoe = parseFloat(filteredSearchRoeResult[0].curConversionRate);
                }
                else {
                    calculatedRoe = 1;
                }
            }
        }
        else {
            let totalFareAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount;

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
                if (element.commisionInfo != undefined && element.commisionInfo[0] != undefined && element.commisionInfo[0].earnedCommissionAmount != undefined)
                    totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);

                // element.taxes !== undefined && element.taxes.tax !== undefined && element.taxes.tax.length !== 0 && element.taxes.tax.forEach(item => {
                //     if (item.taxCode === 'P0') {
                //         totalMarkup = totalMarkup + parseInt(element.quantity) * parseFloat(item.amount)
                //     }
                // })

                //markUp node now moved out from tax array, now moved in inside taxes element
                if (element.taxes !== undefined && element.taxes !== null && element.taxes.markUp !== undefined && element.taxes.markUp !== null && element.taxes.markUp !== '') {
                    totalMarkup = totalMarkup + parseInt(element.quantity) * parseFloat(element.taxes.markUp)
                }

                element.fees !== undefined && element.fees.fee !== undefined && element.fees.fee.length !== 0 && element.fees.fee.forEach(item => {
                    if (item.text === 'ServiceFee-Amount') {
                        serviceFee = parseFloat(item.amount)
                    }
                    else if (item.text === 'ServiceFee-Percentage') {
                        serviceFee = parseFloat(item.amount)
                    }
                })
            })

            if (isTotalFare) {
                finalPrice = parseFloat(totalFareAmount);
            }
            else {
                finalPrice = parseFloat(totalFareAmount - (serviceFee + totalCommoission + totalMarkup));
            }

            if (resultCurrency.toString().toUpperCase() !== walletCurrency.toString().toUpperCase() && secureLocalStorage.getItem("searchRoeResult") !== null) {
                let searchRoeResult = JSON.parse(secureLocalStorage.getItem("searchRoeResult"));
                let filteredSearchRoeResult = searchRoeResult.filter(a => a.baseCurrency === resultCurrency && a.currency === walletCurrency);
                if (filteredSearchRoeResult.length > 0) {
                    calculatedAmount = (parseFloat(finalPrice) * parseFloat(filteredSearchRoeResult[0].curConversionRate));
                }
            }
            else {
                calculatedAmount = finalPrice;
            }
        }

        return isRoe === true ? calculatedRoe : isWalletAmount === true ? finalPrice.toFixed(decimalPlaces) : calculatedAmount.toFixed(decimalPlaces);
    }
    else {
        let calculatedAmount = 0;
        let finalPrice = 0;
        let totalCommoission = 0.0;
        let totalMarkup = 0.0;
        let serviceFee = 0.0;

        let totalFareAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount;

        bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
            if (element.commisionInfo != undefined && element.commisionInfo[0] != undefined && element.commisionInfo[0].earnedCommissionAmount != undefined)
                totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);

            // element.taxes !== undefined && element.taxes.tax !== undefined && element.taxes.tax.length !== 0 && element.taxes.tax.forEach(item => {
            //     if (item.taxCode === 'P0') {
            //         totalMarkup = totalMarkup + parseInt(element.quantity) * parseFloat(item.amount)
            //     }
            // })

            //markUp node now moved out from tax array, now moved in inside taxes element
            if (element.taxes !== undefined && element.taxes !== null && element.taxes.markUp !== undefined && element.taxes.markUp !== null && element.taxes.markUp !== '') {
                totalMarkup = totalMarkup + parseInt(element.quantity) * parseFloat(element.taxes.markUp)
            }

            element.fees !== undefined && element.fees.fee !== undefined && element.fees.fee.length !== 0 && element.fees.fee.forEach(item => {
                if (item.text === 'ServiceFee-Amount') {
                    serviceFee = parseFloat(item.amount)
                }
                else if (item.text === 'ServiceFee-Percentage') {
                    serviceFee = parseFloat(item.amount)
                }
            })
        })

        if (isTotalFare) {
            finalPrice = parseFloat(totalFareAmount);
        }
        else {
            finalPrice = parseFloat(totalFareAmount - (serviceFee + totalCommoission + totalMarkup));
        }
        calculatedAmount = finalPrice;

        return isRoe === true ? 1 : isWalletAmount === true ? finalPrice.toFixed(decimalPlaces) : calculatedAmount.toFixed(decimalPlaces);
    }
}

export function calculateRoeAmountAdditional(resultCurrency, fareAmount, isRoe) {
    let decimalPlaces = parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2');

    if (secureLocalStorage.getItem("searchRoeResult") !== null) {
        let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';        
        let calculatedAmount = 0;
        let calculatedRoe = 0;

        if (isRoe) {
            if (resultCurrency.toString().toUpperCase() !== walletCurrency.toString().toUpperCase()) {
                let searchRoeResult = JSON.parse(secureLocalStorage.getItem("searchRoeResult"));
                let filteredSearchRoeResult = searchRoeResult.filter(a => a.baseCurrency === resultCurrency && a.currency === walletCurrency);
                if (filteredSearchRoeResult.length > 0) {
                    calculatedRoe = parseFloat(filteredSearchRoeResult[0].curConversionRate);
                }
                else {
                    calculatedRoe = 1;
                }
            }
        }
        else {
            if (resultCurrency.toString().toUpperCase() !== walletCurrency.toString().toUpperCase() && secureLocalStorage.getItem("searchRoeResult") !== null) {
                let searchRoeResult = JSON.parse(secureLocalStorage.getItem("searchRoeResult"));
                let filteredSearchRoeResult = searchRoeResult.filter(a => a.baseCurrency === resultCurrency && a.currency === walletCurrency);
                if (filteredSearchRoeResult.length > 0) {
                    calculatedAmount = (parseFloat(fareAmount) * parseFloat(filteredSearchRoeResult[0].curConversionRate));
                }
            }
            else {
                calculatedAmount = parseFloat(fareAmount);
            }
        }
        return isRoe === true ? calculatedRoe : calculatedAmount.toFixed(decimalPlaces);
    }
    else {
        let calculatedAmount = 0;
        calculatedAmount = parseFloat(fareAmount);
        return isRoe === true ? 1 : calculatedAmount.toFixed(decimalPlaces);
    }
}

export function containsOnlyAlphabetsWithSpace(str) {
    // Check if the string only contains alphabets and spaces
    return /^[a-zA-Z ]*$/.test(str);
}


export function checkB2CClient() {
    // Check if the string only contains alphabets and spaces
    const b2cClient = CLIENT_DOMAIN;
    const b2cClientList = b2cClient.split(',');

    let isB2C = false;

    for (let index = 0; index < b2cClientList.length; index++) {
        if (window.location.href.toString().includes(b2cClientList[index])) {
            isB2C = true;
            break;
        }
    }

    return isB2C;
}

//Check if customization should be ignored for current url.
//This will be used for B2B and B2E.
//Mathing is done on the basis of exact match.
export function isDomainIgnoredForCustomization() {
    let isIgnored = false;

    if (DOMAIN_IGNORE_CUSTOMIZATION) {
        const ignoreList = DOMAIN_IGNORE_CUSTOMIZATION.split(',');

        if (ignoreList.includes(window.location.href.toLowerCase())) {
            isIgnored = true;
        }
    }

    return isIgnored;
}

export function getLoginUrl() {
    let url = LOGIN;
    //This key is set in login.js
    const clientKeyLocation = localStorage.getItem('customizationClientKeyLocation');

    if (clientKeyLocation === 'param') {
        const clientKey = localStorage.getItem('customizationClientKey');
        if (clientKey) {
            url = `${url}/${clientKey}`;
        }
    }

    return url;
}

export function dateDiff(targerDate, sourceDate) {
    var year = targerDate.getFullYear();
    var month = targerDate.getMonth() + 1;
    var day = targerDate.getDate();

    var yy = sourceDate.getFullYear();
    var mm = sourceDate.getMonth() + 1;
    var dd = sourceDate.getDate();
    var years, months, days;
    // months
    months = month - mm;
    if (day < dd) {
        months = months - 1;
    }
    // years
    years = year - yy;
    if (month * 100 + day < mm * 100 + dd) {
        years = years - 1;
        months = months + 12;
    }
    // days
    days = Math.floor((targerDate.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
    //
    return { years: years, months: months, days: days };
}

export function isValidHttpUrl(url) {
    try {
        //The check for startsWith is needed because a single oblique is also considered a valid URL by JS API which we 
        //won't accept for our case, e.g. http:/www.example.com
        if (url.startsWith('http://') || url.startsWith('https://')) {

            let parsedUrl = new URL(url);
            //Checking protocol is important because values like 'mailto:example@email.com' are also considered valid URLs.
            //Checking protocol ensures that it's an actual http(s) url.            
            return (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:');
        }

        return false;
    }
    catch (err) {
        return false;
    }
}

export function isLoginPage() {
    return window.location.pathname === '/' ||
        window.location.pathname.toLowerCase().startsWith('/login');
}

export function getLoginPageCompanyLogo() {
    let companyLogo = getCustomizeDetails("loginPageCompanyLogoPath");
    if (companyLogo) {
        companyLogo = CUSTOMIZE_IMAGE_URL + companyLogo;
    }
    else {
        companyLogo = 'assets/img/globetrotterdark.png';
    }

    return companyLogo;
}

export function getLoginPageBackgroundImage() {
    let customBackgroundImage = getCustomizeDetails('backgroungImageVideoPath');
    if (customBackgroundImage) {
        return {
            backgroundImage: `url(${CUSTOMIZE_IMAGE_URL + customBackgroundImage})`
        };
    }
    else {
        return {
            backgroundImage: 'url("assets/img/banner/login-banner.jpg")'
        };
    }
}

//Removes or adds querystring parameter to an existing url string (href) and returns the new url object
//paramsToRemove: Array of string which contains the names of query params to remove
//paramsToAdd: Array of object which contains the name/value pair of the query params to add
export function modifyUrlParams(url, paramsToRemove, paramsToAdd) {
    try {
        let newUrl = new URL(url);

        if (paramsToRemove && paramsToRemove.length) {
            paramsToRemove.forEach(param => {
                newUrl.searchParams.delete(param);
            });
        }

        if (paramsToAdd && paramsToAdd.length) {
            paramsToAdd.forEach(param => {
                newUrl.searchParams.append(param.name, param.value);
            });
        }

        return newUrl;
    }
    catch (e) {
        throw new Error('Invalid url');
    }
}
// import { Fragment } from 'react';
import { Fragment } from 'react';
import FlightBookingItem from './FlightBookingItem';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';


const FlightBookingList = (props) => {
    // if (Object.keys(props.currentItems).length > 0) {
    //     props.currentItems.push({
    //         "shoppingID": "",
    //         "sequenceNo": "0",
    //         "confirmationNumber": "",
    //         "bookingData": "",
    //         "officeID": "",
    //         "companyName": "",
    //         "passengerName": "",
    //         "passengerCount": "",
    //         "bookingType": "",
    //         "departureDate": "",
    //         "arrivalDate": "",
    //         "from": "",
    //         "to": "",
    //         "validating": "",
    //         "airline": "",
    //         "rbd": "",
    //         "cabin": "",
    //         "source": "",
    //         "pnr": "",
    //         "airlinePNR": "",
    //         "ticketNumber": "",
    //         "status": "",
    //         "currencyCode": "",
    //         "baseFare": "",
    //         "taxes": "",
    //         "markup": "",
    //         "totalFare": "",
    //         "commission": "",
    //         "formOfPayment": "",
    //         "userID": "",
    //         "equivTotalFare": props.currentItems.equivTotalFare !== undefined && props.currentItems.equivTotalFare !== '' ? props.currentItems.map(a => parseFloat(a.equivTotalFare)).reduce((equivTotalFare1, equivTotalFare2) => equivTotalFare1 + equivTotalFare2).toFixed(2) : '',
    //         "bookedBy": ""
    //     })
    // }

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    return <Fragment>

        <div className="col-lg-12" >{/* className="accordion-item"> */}
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='text-end w-15'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='min-width-185'>Transaction Date</th>
                                            <th scope="col" className='w-15'>Company Name</th>
                                            <th scope="col" className='min-width-210' align={'left'}>Traveler</th>
                                            <th scope="col" className='w-15 text-center'>Pax Count</th>
                                            <th scope="col" className='min-width-125'>Trip Type</th>
                                            <th scope="col" className='min-width-125'>Travel Date</th>
                                            <th scope="col" className='w-15'>Segments</th>
                                            <th scope="col" className='w-15 text-center'>Validating</th>
                                            <th scope="col" className='w-15'>Flight No.</th>
                                            {getConfigurationByBoolen("ALLOW_RBD") && <th scope="col" className='w-15 text-center'>RBD</th>}
                                            <th scope="col" className='w-15'>Cabin</th>
                                            {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <th scope="col" className='w-15'>Source</th>}
                                            <th scope="col" className='w-15'>Source PNR</th>
                                            <th scope="col" className='w-15'>Airline PNR</th>
                                            <th scope="col" className='min-width-165'>Status</th>
                                            <th scope="col" className='w-15 text-center'>Currency</th>
                                            <th scope="col" className='text-end w-50'>Base Fare</th>
                                            <th scope="col" className='text-end w-50' align={'right'}>Taxes</th>
                                            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <th scope="col" className='text-end w-25'>Service Fee</th>}
                                            <th scope="col" className='text-end w-25'>Markup</th>
                                            <th scope="col" className='text-end w-25'>Add-ons</th>
                                            <th scope="col" className='text-end w-25'>Airline Txn. Charges</th>
                                            <th scope="col" className='text-end w-25'>Card Fees</th>
                                            <th scope="col" className='text-end w-25'>Total Fare</th>
                                            {/*   <th scope="col" className='w-15 text-center'>Equivalent Currency</th>
                                            <th scope="col" className='text-end w-25'>Equivalent Fare</th> */}
                                            {getConfigurationByBoolen("ALLOW_COMISSION") && <th scope="col" className='text-end w-25'>Commission</th>}
                                            {/* <th scope="col" className='text-end'>Equiv. Total Fare (USD)</th> */}
                                            {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <th scope="col" className='w-15 text-center'>Eq. Currency</th>}
                                            <th scope="col" className='text-end w-25'>Conversion Rate</th>
                                            {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <th scope="col" className='text-end w-25'>Eq. Total Fare</th>}
                                            <th scope="col" className='w-15 text-center'>Original Currency</th>
                                            <th scope="col" className='text-end w-25'>Original Fare</th>
                                            <th scope="col" className='min-width-185'>Form of Payment</th>
                                            <th scope="col" className='min-width-165' align={'left'}>Done By</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.currentItems.map((currentItems) =>
                                            <FlightBookingItem key={currentItems.sequenceNo}
                                                id={currentItems.sequenceNo}
                                                reportJSON={currentItems}></FlightBookingItem>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default FlightBookingList;
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
const TripReasonItem = (props) => {
    const { t } = useTranslation(['translation.TripReason']);
    const [confirm, setConfirm] = useState();
    let meetingDetails;
    if (props.meetingID === '1') {
        meetingDetails = "Hidden";
    } else if (props.meetingID === '2') {
        meetingDetails = "Mandatory";
    } else {
        meetingDetails = "Optional";
    }
    function deleteFormHandler(props) {
        props.onDelete({
            "id": props.id,
            "traceID": "",
            "userID": "",
            "ipAddress": "",
            "name": "",
            "code": "",
            "meetingID": "",
            "isFamilyBooking": true,
            "officeID": "",
        });

    }
    function onConfirm() {
        setConfirm(true);
    }

    function okHandler() {
        deleteFormHandler(props);
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };
    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}


        <div className="row" key={props.key}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.name}
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="col-md-10">
                                {props.code !== '' && < p className="card-text mb-0"><strong>Code: </strong> {props.code}</p>}
                                <p className="card-text mb-0"><strong>Allow Family Booking: </strong>{props.isFamilyBooking === false ? 'No' : "Yes"}</p>
                                <p className="card-text mb-0"><strong>Meeting Details: </strong>{meetingDetails}</p>
                            </div>
                            <div className="col-md-2 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Master", "TripReason", "Master_TripReason_View")
                                        && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateTripReason/0/${props.id}`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                    {checkSecurityDetails("Master", "TripReason", "Master_TripReason_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateTripReason/1/${props.id}`, edit: 1, }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}
                                    {checkSecurityDetails("Master", "TripReason", "Master_TripReason_Delete") &&
                                        <button title="Delete" className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" onClick={() => { onConfirm() }}><i className="fas fa-trash-alt"></i></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >
}

export default TripReasonItem;
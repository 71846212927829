import { useRef, useEffect } from 'react'
import { DEFAULT_TITLE, TITLE } from '../../../config/CONSTANTS'
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  
  const customTitle = getCustomizeDetails('browserTitle');
  const configTitle = useRef(customTitle || getConfigurationByValue('Browser_Title'));

  useEffect(() => {

    if (title === 'Login' || title === 'Forgot Password' || title === 'Session' || title === 'Sign Up') {
      if (customTitle) {
        document.title = customTitle + " " + title;
      }
      else {
        document.title = TITLE + " " + title;
      }
    }
    else if (configTitle.current !== undefined && configTitle.current !== '') {
      document.title = configTitle.current + " " + title;
    } else {
      document.title = DEFAULT_TITLE;
    }

  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle
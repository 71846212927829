import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { emailValidation, passwordValidation } from '../../../../utils/Validation'
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";

const EmailServerForm = (props) => {
    //constants
    const { t } = useTranslation(['translation.EmailServer']);
    //useState
    const [pageTitle, setPageTitle] = useState(" Email Server");
    const [SSLValue, setSSLValue] = useState(false);
    const [defaultEmailServerValue, setDefaultEmailServerValue] = useState(false);
    const [isFirtTime, setFirstTime] = useState(true);
    //ErrorState
    const [senderEmailIDBlankError, setSenderEmailIDBlankError] = useState(false);
    const [senderEmailIDInvalidError, setSenderEmailIDInvalidError] = useState(false);
    const [userNameBlankError, setUserNameBlankError] = useState(false);
    const [passwordBlankError, setPasswordBlankError] = useState(false);
    const [passwordInvalidError, setPasswordInvalidError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [blankEmailServerError, setBlankEmailServerError] = useState(false);
    const [emailServerInvalidInputError, setEmailServerInvalidInputError] = useState(false);
    const [blankSmtpPortError, setBlankSmtpPortError] = useState(false);
    const [smptpPortInvalidInputError, setSmtpInvalidInputError] = useState(false);


    //useRef
    const senderEmailIdInputRef = useRef();
    const emailServerInputRef = useRef('');
    const smtpPortInputRef = useRef('');
    const userNameInputRef = useRef('');
    const passwordInputRef = useRef('');
    const sslInputRef = useRef('');
    const defaultEmailServerInputRef = useRef('');


    //Handlers
    function sslChangeHandler() {
        setSSLValue(!SSLValue);
    };
    function defaultEmailServerChangeHandler() {
        setDefaultEmailServerValue(!defaultEmailServerValue);
    };
    useEffect(() => {
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });

        if (isFirtTime && props.loadedEmailServerDetails.length !== 0) {
            senderEmailIdInputRef.current.value = props.loadedEmailServerDetails.senderEmailID;
            userNameInputRef.current.value = props.loadedEmailServerDetails.userName
            emailServerInputRef.current.value = props.loadedEmailServerDetails.emailServer;
            smtpPortInputRef.current.value = props.loadedEmailServerDetails.smtpPort;
            passwordInputRef.current.value = props.loadedEmailServerDetails.password;
            setSSLValue(props.loadedEmailServerDetails.ssl)
            setDefaultEmailServerValue(props.loadedEmailServerDetails.defaultEmailServer)

        }
        setFirstTime(false)
        //  senderEmailIdInputRef.current.focus();

    }, [props.loadedEmailServerDetails.length,
    props.loadedEmailServerDetails.senderEmailID,
    props.loadedEmailServerDetails.defaultEmailServer,
    props.loadedEmailServerDetails.smtpPort,
    props.loadedEmailServerDetails.userName,
    props.loadedEmailServerDetails.ssl,
    props.loadedEmailServerDetails.defaultEmailServer,
    props.loadedEmailServerDetails.password,


    ]);
    function onBlurCheck(event) {
        console.log(event.target.id);
        if (event.target.id === 'save') {
            return;
        }

        const enteredSenderEmailID = senderEmailIdInputRef.current.value;
        const enteredUserName = userNameInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredEmailServer = emailServerInputRef.current.value;
        console.log(enteredEmailServer);
        const enteredSmtpPort = smtpPortInputRef.current.value;

        if (enteredSenderEmailID.trim() === '') {
            setSenderEmailIDBlankError(true);
            setSenderEmailIDInvalidError(false);
            return;
        } else {
            setSenderEmailIDBlankError(false);
        }
        if (!emailValidation(enteredSenderEmailID)) {
            setSenderEmailIDInvalidError(true);
            setSenderEmailIDBlankError(false);
            return;
        } else {
            setSenderEmailIDInvalidError(false);
        }
        if (enteredUserName.trim() === '') {
            setUserNameBlankError(true);
            return;
        } else {
            setUserNameBlankError(false);
        }
        if (enteredPassword.trim() === '') {
            setPasswordBlankError(true);
            setPasswordLengthError(false);
            setPasswordInvalidError(false);
            return;
        }
        else {
            setPasswordBlankError(false);
        }
        /*         if (!passwordValidation(enteredPassword)) {
                    setPasswordInvalidError(true);
                    setPasswordBlankError(false);
                    setPasswordLengthError(false);
                    return;
                } else {
                    setPasswordInvalidError(false);
                } */
        if (enteredPassword.trim().length < 8 || enteredPassword.trim().length > 30) {
            setPasswordLengthError(true);
            setPasswordBlankError(false);
            setPasswordInvalidError(false);
            return;
        } else {
            setPasswordLengthError(false);
        }
        if (enteredEmailServer.trim().length === 0) {
            setBlankEmailServerError(true);
            console.log('A');
            return;
        } else {
            setBlankEmailServerError(false);
            setEmailServerInvalidInputError(false);
        }
        if (!enteredEmailServer.match('^[A-Za-z0-9.]+$')) {
            setEmailServerInvalidInputError(true);
            setBlankEmailServerError(false);
            return;
        } else {
            setEmailServerInvalidInputError(false);
        }
        if (enteredSmtpPort.trim() === '') {
            setBlankSmtpPortError(true);
            setSmtpInvalidInputError(false);
            return;
        } else {
            setBlankSmtpPortError(false);
        }
        if (!enteredSmtpPort.match('^[0-9]+$')) {
            setSmtpInvalidInputError(true);
            setBlankSmtpPortError(false);
            return;
        }
        else {
            setSmtpInvalidInputError(false);
        }

    };


    function submitFormHandler(event) {
        console.log(event);
        event.preventDefault();


        const enteredSenderEmailID = senderEmailIdInputRef.current.value;
        const enteredUserName = userNameInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredEmailServer = emailServerInputRef.current.value;
        const enteredSmtpPort = smtpPortInputRef.current.value;
        const enteredSSL = SSLValue;
        const enteredDefaultServer = defaultEmailServerValue;

        if (enteredSenderEmailID.trim() === '') {
            senderEmailIdInputRef.current.focus();
            setSenderEmailIDBlankError(true);
            setSenderEmailIDInvalidError(false);
            return;
        } else {
            setSenderEmailIDBlankError(false);
        }
        if (!emailValidation(enteredSenderEmailID)) {
            senderEmailIdInputRef.current.focus();
            setSenderEmailIDInvalidError(true);
            setSenderEmailIDBlankError(false);
            return;
        } else {
            setSenderEmailIDInvalidError(false);
        }
        if (enteredUserName.trim() === '') {
            userNameInputRef.current.focus();


            setUserNameBlankError(true);
            return;
        } else {
            setUserNameBlankError(false);
        }
        if (enteredPassword.trim() === '') {
            passwordInputRef.current.focus();
            setPasswordBlankError(true);
            setPasswordLengthError(false);
            setPasswordInvalidError(false);
            return;
        }
        else {
            setPasswordBlankError(false);
        }
        /*  if (!passwordValidation(enteredPassword)) {
             passwordInputRef.current.focus();
             setPasswordInvalidError(true);
             setPasswordBlankError(false);
             setPasswordLengthError(false);
             return;
         } else {
             setPasswordInvalidError(false);
         } */
        if (enteredPassword.trim().length < 8 || enteredPassword.trim().length > 30) {
            passwordInputRef.current.focus();
            setPasswordLengthError(true);
            setPasswordBlankError(false);
            setPasswordInvalidError(false);
            return;
        } else {
            setPasswordLengthError(false);
        }
        if (enteredEmailServer.trim() === '') {
            //console.log("B");
            emailServerInputRef.current.focus();
            setBlankEmailServerError(true);
            window.scroll({
                top: 50,
                behavior: "smooth",
            });
            return;
        } else {
            setBlankEmailServerError(false);

        }
        if (!enteredEmailServer.match('^[A-Za-z0-9.]+$')) {

            emailServerInputRef.current.focus();
            setEmailServerInvalidInputError(true);
            window.scroll({
                top: 50,
                behavior: "smooth",
            });
            return;
        } else {
            setEmailServerInvalidInputError(false);
        }
        if (enteredSmtpPort.trim() === '') {
            smtpPortInputRef.current.focus();
            setBlankSmtpPortError(true);
            setSmtpInvalidInputError(false);
            return;
        } else {
            setBlankSmtpPortError(false);
        }
        if (!enteredSmtpPort.match('^[0-9]+$')) {
            smtpPortInputRef.current.focus();
            setSmtpInvalidInputError(true);
            setBlankSmtpPortError(false);
            return;
        }
        else {
            setSmtpInvalidInputError(false);
        }


        let ID;
        if (props.loadedEmailServerDetails === null || props.loadedEmailServerDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedEmailServerDetails.id;
        }
        const addData = (
            {
                id: ID,
                traceID: JSON.parse(localStorage.getItem('traceID')),
                userID: JSON.parse(localStorage.getItem('userID')),
                iPAddress: localStorage.getItem("IPAddress"),
                senderEmailID: enteredSenderEmailID,
                userName: enteredUserName,
                password: enteredPassword,
                emailServer: enteredEmailServer,
                smtpPort: enteredSmtpPort,
                ssl: enteredSSL,
                defaultEmailServer: enteredDefaultServer,
                officeID: JSON.parse(localStorage.getItem('officeID')),
            }
        );
        props.onAddEmailServer(addData);

    };
    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>

                                                    <form
                                                        className='form'>

                                                        <div className="control">
                                                            <label htmlFor="emailServerId" className="mb-2 h6 text-primary col-lg-12">{t('senderEmailId')}<span className="error-icon"> *</span></label>
                                                            <input id="emailServerId"
                                                                ref={senderEmailIdInputRef}
                                                                minLength="1"
                                                                maxLength="100"
                                                                type="textbox"
                                                                onBlur={onBlurCheck}
                                                                autoFocus
                                                                className="form-control form-control-sm"
                                                            />
                                                            {senderEmailIDBlankError && <p className="error-text">{t('blankSenderEmailIdValidation')}</p>}
                                                            {senderEmailIDInvalidError && <p className="error-text">{t('invalidSenderEmailId')}</p>}

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="userName" className="mb-2 h6 text-primary col-lg-12">{t('userName')}<span className="error-icon"> *</span></label>
                                                                    <input type="textbox" className="mb-2 form-control form-control-sm col-lg-12"
                                                                        ref={userNameInputRef} id="userName"
                                                                        onBlur={onBlurCheck} />
                                                                    {userNameBlankError && <p className="error-text">{t('blankUserName')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="password" className="mb-2 h6 text-primary col-lg-12">{t('password')}<span className="error-icon"> *</span></label>
                                                                    <input type="password" minLength={8} maxLength={30} className="mb-2 form-control form-control-sm col-lg-12"
                                                                        ref={passwordInputRef} id="password"
                                                                        onBlur={onBlurCheck} />
                                                                    {passwordBlankError && <p className="error-text">{t('blankPassword')}</p>}
                                                                    {passwordInvalidError && <p className="error-text">{t('invalidPassword')}</p>}
                                                                    {passwordLengthError && <p className="error-text">{t('invalidpasswordLength')}</p>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="emailServer" className="mb-2 h6 text-primary col-lg-12">{t('emailServer')}<span className="error-icon"> *</span></label>
                                                                    <input type="textbox" className="mb-2 form-control form-control-sm col-lg-12"
                                                                        minLength={1} maxLength={300} ref={emailServerInputRef} onBlur={onBlurCheck} id="emailServer" />
                                                                    {blankEmailServerError && <p className="error-text">{t('blankEmailServer')}</p>}
                                                                    {!blankEmailServerError && emailServerInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}



                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="smtpPort" className="mb-2 h6 text-primary col-lg-12">{t('smtpPort')}<span className="error-icon"> *</span></label>
                                                                    <input type="textbox" minLength={1} maxLength={4} className="mb-2 form-control form-control-sm col-lg-12"
                                                                        ref={smtpPortInputRef} id="smtpPort"
                                                                        onBlur={onBlurCheck} />
                                                                    {blankSmtpPortError && <p className="error-text">{t('blankSmptpPort')}</p>}
                                                                    {smptpPortInvalidInputError && <p className="error-text">{t('invalidInput')}</p>}


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='control'>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label className="b-2 h6 text-primary" htmlFor="SSL">   {t('SSL')}</label>
                                                                <input id="SSL" className="form-check-input ms-3" type="checkbox" onChange={sslChangeHandler} checked={SSLValue} value={SSLValue} ref={sslInputRef} />


                                                            </div>
                                                        </div>
                                                        <div className='control'>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label className="b-2 h6 text-primary" htmlFor="defaultEmailServer">   {t('defaultEmailServer')}</label>
                                                                <input id="defaultEmailServer" className="form-check-input ms-3" type="checkbox" onChange={defaultEmailServerChangeHandler} checked={defaultEmailServerValue} value={defaultEmailServerInputRef} ref={sslInputRef} />

                                                            </div>
                                                        </div>
                                                        <div className='text-primary my-3'><i className="fas fa-info-circle"></i><b>{t('important')}</b>{t('msg')}</div>
                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 col-md-12 actions'>
                                                            {(checkSecurityDetails("Settings", "Email Server", "Settings_Email Server_Update")) &&
                                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' id="save" onClick={submitFormHandler}>
                                                                    {t('save')}
                                                                </button>}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>
};
export default EmailServerForm;
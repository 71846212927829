import { useState, Fragment, useEffect, useMemo } from 'react';
import LoginForm from '../Components/LoginForm';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { getIPData } from "../../../services/Geolocation-API";
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST, GET_ALL_SIGHTSEEING_LIST } from '../../../services/CONSTANTS';
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
import NonAuthCustomizeLoader from '../Components/NonAuthCustomizeLoader';
import { getLoginPageBackgroundImage, getLoginPageCompanyLogo, isLoginPage } from '../../../utils/CommonFunction';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';

const Login = () => {
    useDocumentTitle('Login');

    sessionStorage.setItem("LoaderHeader", "1");

    const [showNonAuthLoader, setShowNonAuthLoader] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    //If custom company logo is present, hide the first company logo (confirm with minal)
    function shouldShowCompanyLogo() {
        let companyLogo = getCustomizeDetails("loginPageCompanyLogoPath");
        return !!!companyLogo;
    }

    function isCustomizationForNonAuthLoaded() {
        return !!JSON.parse(localStorage.getItem('customizationForNonAuthLoaded'));
    }

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        getIPData();

        if (localStorage.getItem("airportSuggestion") === null) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                }).catch(err => {
                    console.log(err)
                });
        }
        if (localStorage.getItem("blockedCountryCityListTemp") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                }).catch(err => {
                    console.log(err)
                });
        }
        if (localStorage.getItem("sightseeingSuggestionResutTemp") === null) {
            let sightseeingSuggestionResutTemp = [];
            fetch(GET_ALL_SIGHTSEEING_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            sightseeingSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
                }).catch(err => {
                    console.log(err)
                });
        }

        if (isLoginPage()) {
            if (isCustomizationForNonAuthLoaded()) {
                localStorage.setItem('customizationForNonAuthLoaded', false);
                setShowLogin(true);
            }
            else {
                setShowNonAuthLoader(true);
            }
        } else {
            localStorage.setItem('customizationForNonAuthLoaded', false);
            setShowLogin(true);
        }

    }, []);

    document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd;`);

    return <Fragment>

        {!showLogin &&
            <section id="">
                <div className="container">
                    <div className="row">
                        <div className="full-page-loader">
                            <LoadingSpinner></LoadingSpinner>
                        </div>
                    </div>
                </div>
            </section>
        }

        {showNonAuthLoader &&
            <NonAuthCustomizeLoader></NonAuthCustomizeLoader>
        }

        {showLogin &&
            <Fragment>
                <section className='gt_login_banner' style={getLoginPageBackgroundImage()}>
                    <div className='position-relative'>
                        {shouldShowCompanyLogo() &&
                            <span className="logohide">
                                <img src="assets/img/login_Globetrotter_logo.png" alt="" className='log-in-head-logo-bg position-absolute' />
                            </span>
                        }
                        <a className="fw-bold forgot-pass-bg h5 float-lg-end sign-up-btn" href="/SignUp"><span className='badge bg-prime'>Sign Up</span></a>
                    </div>
                </section>

                <img src={getLoginPageCompanyLogo()} alt="globetrotter" className='loginscroll-logo' />

                <div className="container">
                    <LoginForm />
                </div>
            </Fragment>
        }

    </Fragment>

};



export default Login;

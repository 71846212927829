import { Fragment, useState, useEffect } from "react";
import { getFormatDate, getFormatTime, getMinutetoHrsMin } from "../../../../utils/CommonFunction";
import FareSummary from "./FareSummary";


const Flight = (props) => {
    const result = props.flightDetails;
    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL
    let inBoundAirlineDetails = [];
    let outBoundAirlineDetails = [];
    let multicityAirlineDetails = new Map([]);
    let multicityFlightCount;
    let fareDetails = [];
    const [refundableType, setRefundableType] = useState();

    if (props.id1 === "onlineFlightDetails") {
        fareDetails = props.fareDetails[0];

    }
    if (props.id1 !== 'onlineFlightDetails') {
        props.fareDetails.forEach(element => {
            if (parseInt(element.lowestID) === parseInt(props.id1)) {
                fareDetails = element;
                return;
            }
        });
    }

    useEffect(() => {
        if (props.rerefundableType !== undefined) {
            setRefundableType(props.rerefundableType)
        }
    }, [props.rerefundableType, refundableType])

    for (let i in result) {
        if (result[i].directionIndicator === 'O') {
            outBoundAirlineDetails.push(result[i]);
        }
        if (result[i].directionIndicator === 'I') {
            inBoundAirlineDetails.push(result[i])
        }
        if (result[i].directionIndicator === 'M') {
            multicityFlightCount = result[i].segmentGroupID;
            if (multicityAirlineDetails.has(result[i].segmentGroupID)) {
                let temp = [];
                temp = multicityAirlineDetails.get(result[i].segmentGroupID);
                temp.push(result[i]);
                multicityAirlineDetails.set(result[i].segmentGroupID, temp);
            } else {
                let temp = [];
                temp.push(result[i]);
                multicityAirlineDetails.set(result[i].segmentGroupID, temp);
            }

        }
    }

    function isMultiAirlines(Airline) {
        let multiAirline = false;
        for (let i in Airline) {
            if (Airline[0].marketingAirlineCode !== Airline[i].marketingAirlineCode) {
                multiAirline = true;
                break;
            }

        }
        if (multiAirline) {
            return "Multi Airlines";
        }
        return Airline.length !== 0 ? Airline[0].marketingAirlineName : "";

    }
    const getRefundType = (fareType) => {
        let refundType;
        //Refundable
        if (fareType === 1) {
            refundType = (<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>);
        }
        //Non Refundable 
        else if (fareType === 0) {
            refundType = (<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non
                Refundable</div>);

        }
        //Partially Refundable
        else if (fareType === 2) {
            refundType = (<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>);
        }
        return refundType;

    }

    const ShowAirineOuterCard = () => {
        let show = [];
        //One Way Flight
        if (result[0].bookingType === 'O') {
            let logo = airlineLogoLink + outBoundAirlineDetails[0].marketingAirlineLogo;
            let flightNumber = outBoundAirlineDetails[0].marketingAirlineCode + " " + outBoundAirlineDetails[0].flightNumber
            let stop = parseInt(outBoundAirlineDetails[0].stops) > 1 ? outBoundAirlineDetails[0].stops + " stops" : outBoundAirlineDetails[0].stops + " stop";
            show.push(<>
                <div className="multi_city_flight_lists w-100">
                    <div className="flight_multis_area_wrapper">
                        <div className="flight_search_left col-md-5">
                            <div className="flight_logo">
                                {isMultiAirlines(outBoundAirlineDetails) !== "Multi Airlines" && < img src={logo} alt="img" className=" p-1 gt-h40" />}
                                <div className="text-14px text-black fw-bold gt-w-75px">{isMultiAirlines(outBoundAirlineDetails)}</div>
                                <div className="text-muted text-12px">{flightNumber}</div>
                            </div>
                            <div className="flight_search_destination">
                                <p>From</p>
                                <h3 className="text-black">{outBoundAirlineDetails[0].departureCityName} </h3>
                                <h6>{getFormatDate(outBoundAirlineDetails[0].departureDate)}{getFormatTime(outBoundAirlineDetails[0].departureTime) === '' ? '' : ', '} {getFormatTime(outBoundAirlineDetails[0].departureTime)}</h6>
                            </div>
                        </div>
                        <div className="flight_search_middel col-md-7">
                            <div className="flight_right_arrow col-md-5">
                                <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                <h6>{getMinutetoHrsMin(fareDetails.totalOutBoundDuration)}</h6>
                                <p>{stop} </p>
                            </div>
                            <div className="flight_search_destination col-md-7">
                                <p>To</p>
                                <h3 className="text-black">{outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalCityName}</h3>
                                <h6>{getFormatDate(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalDate)}
                                    {getFormatTime(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalTime) === '' ? '' : ', '}
                                    {getFormatTime(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalTime)}</h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr className="my-1 mb-2" />
                        <div className="row">
                            <div className="col-md-6">
                                {/* {getRefundType(fareDetails.refundableType)} */}
                                {refundableType !== undefined && getRefundType(refundableType)}
                            </div>
                        </div>
                    </div>
                </div>
                {props.page === 'retreive' && <div className="flight_search_right col-lg-3 col-md-12 col-sm-12  position-relative">
                    <h6 id="itineraryDetails3" className="text-primary" data-bs-toggle="collapse" data-bs-target={"#collapseExampleundefined" + props.id} aria-controls={"collapseExampleundefined" + props.id} aria-expanded="true">Show Details <i className="fas fa-chevron-down"></i>
                    </h6>
                </div>}
            </>);
        }
        //Round Trip Flight
        if (result[0].bookingType === 'R' && outBoundAirlineDetails.length !== 0 && inBoundAirlineDetails.length !== 0) {

            let logo = airlineLogoLink + outBoundAirlineDetails[0].marketingAirlineCode + ".gif";
            let flightNumber = outBoundAirlineDetails[0].marketingAirlineCode + " " + outBoundAirlineDetails[0].flightNumber
            let flightNumber_r = inBoundAirlineDetails[0].marketingAirlineCode + " " + inBoundAirlineDetails[0].flightNumber
            let stop = parseInt(outBoundAirlineDetails[0].stops) > 1 ? outBoundAirlineDetails[0].stops + " stops" : outBoundAirlineDetails[0].stops + " stop";
            let stop_r = parseInt(inBoundAirlineDetails[0].stops) > 1 ? inBoundAirlineDetails[0].stops + " stops" : inBoundAirlineDetails[0].stops + " stop";
            let logo_r = airlineLogoLink + inBoundAirlineDetails[0].marketingAirlineCode + ".gif";

            show.push(<>
                <div className="multi_city_flight_lists w-100">
                    <div className="flight_multis_area_wrapper">
                        <div className="flight_search_left col-md-5">
                            <div className="flight_logo">
                                {isMultiAirlines(outBoundAirlineDetails) !== "Multi Airlines" && <img src={logo} alt="img" className=" p-1 gt-h40" />}
                                <div className="text-14px text-black fw-bold gt-w-75px">{isMultiAirlines(outBoundAirlineDetails)}</div>
                                <div className="text-muted text-12px">{flightNumber}</div>
                            </div>
                            <div className="flight_search_destination">
                                <p>From</p>
                                <h3 className="text-black">{outBoundAirlineDetails[0].departureCityName} </h3>
                                <h6>{getFormatDate(outBoundAirlineDetails[0].departureDate)}{getFormatTime(outBoundAirlineDetails[0].departureTime) === '' ? '' : ', '} {getFormatTime(outBoundAirlineDetails[0].departureTime)}</h6>
                            </div>
                        </div>
                        <div className="flight_search_middel col-md-7">
                            <div className="flight_right_arrow col-md-5">
                                <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                <h6>{getMinutetoHrsMin(fareDetails.totalOutBoundDuration)}</h6>
                                <p>{stop} </p>
                            </div>
                            <div className="flight_search_destination col-md-7">
                                <p>To</p>
                                <h3 className="text-black">{outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalCityName}</h3>
                                <h6>{getFormatDate(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalDate)}
                                    {getFormatTime(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalTime) === '' ? '' : ', '}
                                    {getFormatTime(outBoundAirlineDetails[outBoundAirlineDetails.length - 1].arrivalTime)}</h6>
                            </div>
                        </div>
                    </div>
                    <hr className="my-1" />
                    <div className="flight_multis_area_wrapper">
                        <div className="flight_search_left col-md-5">
                            <div className="flight_logo">
                                {isMultiAirlines(inBoundAirlineDetails) !== "Multi Airlines" && <img src={logo_r} alt="img" className=" p-1 gt-h40" />}
                                <div className="text-14px text-black fw-bold gt-w-75px">{isMultiAirlines(inBoundAirlineDetails)}</div>
                                <div className="text-muted text-12px">{flightNumber_r}</div>
                            </div>
                            <div className="flight_search_destination">
                                <p>From</p>
                                <h3 className="text-black">{inBoundAirlineDetails[0].departureCityName}</h3>
                                <h6>{getFormatDate(inBoundAirlineDetails[0].departureDate)}{getFormatTime(inBoundAirlineDetails[0].departureTime) === '' ? '' : ', '} {getFormatTime(inBoundAirlineDetails[0].departureTime)}</h6>
                            </div>
                        </div>
                        <div className="flight_search_middel col-md-7">
                            <div className="flight_right_arrow col-md-5">
                                <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                <h6>{getMinutetoHrsMin(fareDetails.totalInBoundDuration)}</h6>
                                <p>{stop_r}</p>
                            </div>
                            <div className="flight_search_destination col-md-7">
                                <p>To</p>
                                <h3 className="text-black">{inBoundAirlineDetails[inBoundAirlineDetails.length - 1].arrivalCityName}</h3>
                                <h6>{getFormatDate(inBoundAirlineDetails[inBoundAirlineDetails.length - 1].arrivalDate)}
                                    {getFormatTime(inBoundAirlineDetails[inBoundAirlineDetails.length - 1].arrivalTime) === '' ? '' : ', '}
                                    {getFormatTime(inBoundAirlineDetails[inBoundAirlineDetails.length - 1].arrivalTime)}</h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr className="my-1 mb-2" />
                        <div className="row ">
                            <div className="col-md-6">
                                {refundableType !== undefined && getRefundType(refundableType)}
                            </div>
                        </div>
                    </div>
                </div>
                {props.page === 'retreive' && <div className="flight_search_right col-lg-3 col-md-12 col-sm-12  position-relative">
                    <h6 id="itineraryDetails3" className="text-primary" data-bs-toggle="collapse" data-bs-target={"#collapseExampleundefined" + props.id} aria-controls={"collapseExampleundefined" + props.id} aria-expanded="true">Show Details <i className="fas fa-chevron-down"></i>
                    </h6>
                </div>}
            </>
            )
        }
        //MultiCity Flight
        if (result[0].bookingType === 'M') {
            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let temp = multicityAirlineDetails.get(i);
                let logo = airlineLogoLink + temp[0].marketingAirlineLogo
                let flightNumber = temp[0].marketingAirlineCode + " " + temp[0].flightNumber
                let stop = parseInt(temp[0].stops) > 1 ? temp[0].stops + " stops" : temp[0].stops + " stop";
                show.push(<>
                    <div>
                        {i !== 1 && <hr class="my-1" />}
                        <div class="flight_multis_area_wrapper">
                            <div class="flight_search_left col-md-5">
                                <div class="flight_logo">
                                    <img src={logo} alt="img" class=" p-1 gt-h40" />
                                    <div class="text-14px text-black fw-bold gt-w-75px">{isMultiAirlines(temp)}</div>
                                    <div class="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div class="flight_search_destination">
                                    <p>From </p>
                                    <h3 className="text-black">{temp[0].departureCityName} </h3>
                                    <h6>{getFormatDate(temp[0].departureDate)}{getFormatTime(temp[0].departureTime) === '' ? '' : ', '} {getFormatTime(temp[0].departureTime)}</h6>
                                </div>
                            </div>
                            <div class="flight_search_middel col-md-7">
                                <div class="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>7h 5m</h6>
                                    <p>{stop} </p>
                                </div>
                                <div class="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className="text-black">{temp[temp.length - 1].arrivalCityName}</h3>
                                    <h6>{getFormatDate(temp[temp.length - 1].arrivalDate)}
                                        {getFormatTime(temp[temp.length - 1].arrivalTime) === '' ? '' : ', '}
                                        {getFormatTime(temp[temp.length - 1].arrivalTime)}</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                </>);
            }
            return show;

        }
        return show;

    }

    const showAirlineInnerCard = () => {
        let show = [];
        //outbound flight info(outgoing flight)
        if (outBoundAirlineDetails.length !== 0) {

            for (let i in outBoundAirlineDetails) {
                let timeBetweenFlight = outBoundAirlineDetails[i].layoverTime !== undefined ? outBoundAirlineDetails[i].layoverTime : "";
                let logo = airlineLogoLink + outBoundAirlineDetails[i].marketingAirlineCode + ".gif";
                let flightNumber = outBoundAirlineDetails[i].marketingAirlineCode + " " + outBoundAirlineDetails[i].flightNumber
                let depAirportName = outBoundAirlineDetails[i].departureLocationName === undefined ? "" : outBoundAirlineDetails[i].departureLocationName;
                let depAirportCode = outBoundAirlineDetails[i].departureLocationCode === undefined ? "" : outBoundAirlineDetails[i].departureLocationCode;
                let depTerminal = outBoundAirlineDetails[i].departureTerminal === undefined ? "" : "Terminal " + outBoundAirlineDetails[i].departureTerminal;
                let depCountryName = outBoundAirlineDetails[i].departureCountryName;
                let depCountryCode = outBoundAirlineDetails[i].departureCountryCode;
                let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                let arrAirportName = outBoundAirlineDetails[i].arrivalLocationName === undefined ? "" : outBoundAirlineDetails[i].arrivalLocationName;
                let arrAirportCode = outBoundAirlineDetails[i].arrivalLocationCode === undefined ? "" : outBoundAirlineDetails[i].arrivalLocationCode;
                let arrCountryName = outBoundAirlineDetails[i].arrivalCountryName;
                let arrCountryCode = outBoundAirlineDetails[i].arrivalCountryCode;
                let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
                let arrTerminal = outBoundAirlineDetails[i].arrivalTerminal === undefined ? "" : "Terminal " + outBoundAirlineDetails[i].arrivalTerminal;
                let flightArrive = outBoundAirlineDetails[i].dayChange === true && outBoundAirlineDetails[i].dayChange !== undefined ? "This flight arrives the next day." : "";
                show.push(<>
                    <div>
                        {i !== "0" && outBoundAirlineDetails[i].layoverTime !== '' && <div className="row">
                            <div className='col-md-12 text-center gt-time-btn-flight' >Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                        </div>}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="departure-text">
                                    <span>
                                        <i className="fas fa-plane-departure"></i>
                                    </span> Departure
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-lg-flex flight_show_down_wrapper w-100">
                                <div className="flight-shoe_dow_item col-md-9 ccc">
                                    <div className="airline-details ">
                                        <div className="img">
                                            <img src={logo} alt="img" className="details-flight-img" />
                                        </div>
                                        <span className="airlineName fw-500">{outBoundAirlineDetails[i].marketingAirlineName}&nbsp;{flightNumber}</span>
                                        <span className=" airlineName fw-500 text-white badge rounded-pill class-purple-bg">{outBoundAirlineDetails[i].cabin}</span>
                                        <span className="flightNumber">{outBoundAirlineDetails[i].airCraftName} </span>
                                    </div>
                                    <div className="flight_inner_show_component">
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{outBoundAirlineDetails[i].departureCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(outBoundAirlineDetails[i].departureTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBoundAirlineDetails[i].departureDate)}</p>
                                                <p className="airport">{depAirPortInfo}</p>
                                                <p className="airport mb-2">{depTerminal}</p>
                                            </div>
                                        </div>
                                        <div className="flight_duration">
                                            <div className="arrow_right"></div>
                                            <span>{getMinutetoHrsMin(outBoundAirlineDetails[i].duration)}</span>
                                            <p> 0 stops</p>
                                        </div>
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{outBoundAirlineDetails[i].arrivalCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(outBoundAirlineDetails[i].arrivalTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBoundAirlineDetails[i].arrivalDate)}</p>
                                                <p className="airport">{arrAirPortInfo}</p>
                                                <p className="airport mb-2">{arrTerminal}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        {flightArrive !== '' && <div className='col-md-6'>
                                            <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle text-danger"></i>{" " + flightArrive} </div>
                                        </div>}
                                        {
                                            outBoundAirlineDetails[i].airMiles !== undefined && outBoundAirlineDetails[i].airMiles !== '' && outBoundAirlineDetails[i].airMiles !== '0' &&
                                            <div className='col-md-6'>
                                                <div className='text-primary text-14px fw-bold'>

                                                    <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {outBoundAirlineDetails[i].airMiles}</span>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2"></div>
                        </div>
                    </div></>);
            }


        }
        //inbound flight info (incoming flight)
        if (inBoundAirlineDetails.length !== 0) {
            for (let i in inBoundAirlineDetails) {
                let timeBetweenFlight = inBoundAirlineDetails[i].layoverTime !== undefined ? inBoundAirlineDetails[i].layoverTime : "";
                let logo = airlineLogoLink + inBoundAirlineDetails[i].marketingAirlineCode + ".gif";
                let flightNumber = inBoundAirlineDetails[i].marketingAirlineCode + " " + inBoundAirlineDetails[i].flightNumber
                let depAirportName = inBoundAirlineDetails[i].departureLocationName === undefined ? "" : inBoundAirlineDetails[i].departureLocationName;
                let depAirportCode = inBoundAirlineDetails[i].departureLocationCode === undefined ? "" : inBoundAirlineDetails[i].departureLocationCode;
                let depTerminal = inBoundAirlineDetails[i].departureTerminal === undefined ? "" : "Terminal " + inBoundAirlineDetails[i].departureTerminal;
                let depCountryName = inBoundAirlineDetails[i].departureCountryName;
                let depCountryCode = inBoundAirlineDetails[i].departureCountryCode;
                let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                let arrAirportName = inBoundAirlineDetails[i].arrivalLocationName === undefined ? "" : inBoundAirlineDetails[i].arrivalLocationName;
                let arrAirportCode = inBoundAirlineDetails[i].arrivalLocationCode === undefined ? "" : inBoundAirlineDetails[i].arrivalLocationCode;
                let arrCountryName = inBoundAirlineDetails[i].arrivalCountryName;
                let arrCountryCode = inBoundAirlineDetails[i].arrivalCountryCode;
                let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
                let arrTerminal = inBoundAirlineDetails[i].arrivalTerminal === undefined ? "" : "Terminal " + inBoundAirlineDetails[i].arrivalTerminal;
                let flightArrive = inBoundAirlineDetails[i].dayChange === true && inBoundAirlineDetails[i].dayChange !== undefined ? "This flight arrives the next day." : "";
                show.push(<>
                    {inBoundAirlineDetails[i].layoverTime === '' && <hr className='my-1'></hr>}
                    <div>
                        {i !== 0 && inBoundAirlineDetails[i].layoverTime !== '' && <div className="row">
                            <div className="col-md-12 text-center gt-time-btn-flight">Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                        </div>}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="departure-text">
                                    <span>
                                        <i className="fas fa-plane-departure"></i>
                                    </span> Return
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-lg-flex flight_show_down_wrapper w-100">
                                <div className="flight-shoe_dow_item col-md-9 aaa">
                                    <div className="airline-details">
                                        <div className="img">
                                            <img src={logo} alt="img" className="details-flight-img" />
                                        </div>
                                        <span className="airlineName fw-500">{inBoundAirlineDetails[i].marketingAirlineName} &nbsp;{flightNumber}</span>
                                        <span className="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> {inBoundAirlineDetails[i].cabin}</span>
                                        <span className="flightNumber">{inBoundAirlineDetails[i].airCraftName}</span>
                                    </div>
                                    <div className="flight_inner_show_component">
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{inBoundAirlineDetails[i].departureCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(inBoundAirlineDetails[i].departureTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(inBoundAirlineDetails[i].departureDate)}</p>
                                                <p className="airport">{depAirPortInfo}</p>
                                                <p className="airport mb-2">{depTerminal}</p>
                                            </div>
                                        </div>
                                        <div className="flight_duration">
                                            <div className="arrow_right"></div>
                                            <span>{getMinutetoHrsMin(inBoundAirlineDetails[i].duration)}</span>
                                            <p> 0 stops</p>
                                        </div>
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{inBoundAirlineDetails[i].arrivalCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(inBoundAirlineDetails[i].arrivalTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(inBoundAirlineDetails[i].arrivalDate)}</p>
                                                <p className="airport">{arrAirPortInfo}</p>
                                                <p className="airport mb-2">{arrTerminal}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        {flightArrive !== '' && <div className='col-md-6'>
                                            <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle text-danger"></i>{" " + flightArrive} </div>
                                        </div>}
                                        {
                                            inBoundAirlineDetails[i].airMiles !== undefined && inBoundAirlineDetails[i].airMiles !== '' && inBoundAirlineDetails[i].airMiles !== '0' &&
                                            <div className='col-md-6'>
                                                <div className='text-primary text-14px fw-bold'>

                                                    <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {inBoundAirlineDetails[i].airMiles}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2"></div>
                    </div></>);
            }


        }
        //Multi city
        if (multicityAirlineDetails.size !== 0) {
            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let temp = multicityAirlineDetails.get(i);
                for (let j = 0; j < temp.length; j++) {
                    let logo = airlineLogoLink + temp[j].marketingAirlineLogo;
                    let timeBetweenFlight = temp[j].layoverTime !== undefined ? temp[j].layoverTime : "";
                    let flightNumber = temp[j].marketingAirlineCode + " " + temp[j].flightNumber
                    let depAirportName = temp[j].departureLocationName === undefined ? "" : temp[j].departureLocationName;
                    let depAirportCode = temp[j].departureLocationCode === undefined ? "" : temp[j].departureLocationCode;
                    let depTerminal = temp[j].departureTerminal === undefined ? "" : "Terminal " + temp[j].departureTerminal;
                    let depCountryName = temp[j].departureCountryName;
                    let depCountryCode = temp[j].departureCountryCode;
                    let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                    let arrAirportName = temp[j].arrivalLocationName === undefined ? "" : temp[j].arrivalLocationName;
                    let arrAirportCode = temp[j].arrivalLocationCode === undefined ? "" : temp[j].arrivalLocationCode;
                    let arrCountryName = temp[j].arrivalCountryName;
                    let arrCountryCode = temp[j].arrivalCountryCode;
                    let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
                    let arrTerminal = temp[j].arrivalTerminal === undefined ? "" : "Terminal " + temp[j].arrivalTerminal;
                    let flightArrive = temp[j].dayChange === true && temp[j].dayChange !== undefined ? "This flight arrives the next day." : "";
                    show.push(<>
                        <div>
                            {i !== 1 && timeBetweenFlight === '' && <hr className='my-1'></hr>}
                            {j !== 0 && <div className="row">
                                <div className="col-md-12 text-center gt-time-btn-flight">Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                            </div>}
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="departure-text">
                                        <span>
                                            <i class="fas fa-plane-departure"></i>
                                        </span> Departure
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-lg-flex flight_show_down_wrapper w-100">
                                    <div class="flight-shoe_dow_item col-md-9 bbb">
                                        <div class="airline-details">
                                            <div class="img">
                                                <img src={logo} alt="img" class="details-flight-img" />
                                            </div>
                                            <span class="airlineName fw-500">{temp[j].marketingAirlineName} &nbsp;{flightNumber}</span>
                                            <span class="text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1"> {temp[j].cabin}</span>
                                            <span class="flightNumber">{temp[j].airCraftName}</span>
                                        </div>
                                        <div class="flight_inner_show_component">
                                            <div class="flight_det_wrapper">
                                                <div class="flight_det">
                                                    <div class="code_time">
                                                        <span className="code">{temp[j].departureCityName}</span>
                                                    </div>
                                                    <div class="code_time">
                                                        <span className="time">{getFormatTime(temp[j].departureTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(temp[j].departureDate)}</p>
                                                    <p className="airport">{depAirPortInfo}</p>
                                                    <p className="airport mb-2">{depTerminal}</p>
                                                </div>
                                            </div>
                                            <div class="flight_duration">
                                                <div class="arrow_right"></div>
                                                <span>{getMinutetoHrsMin(temp[j].duration)}</span>
                                                <p> 0 stops</p>
                                            </div>
                                            <div class="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{temp[j].arrivalCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(temp[j].arrivalTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(temp[j].arrivalDate)}</p>
                                                    <p className="airport">{arrAirPortInfo}</p>
                                                    <p className="airport mb-2">{arrTerminal}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='row mb-2'>
                                            {flightArrive !== '' && <div className='col-md-6'>
                                                <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle text-danger"></i>{" " + flightArrive} </div>
                                            </div>}
                                            {
                                                temp[j].airMiles !== undefined && temp[j].airMiles !== '' && temp[j].airMiles !== '0' &&
                                                <div className='col-md-6'>
                                                    <div className='text-primary text-14px fw-bold'>
                                                        <span class="text-muted"><i class="fas fa-tachometer-alt"></i> Air miles </span><span class="text-primary"> {temp[j].airMiles} </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2"></div>
                        </div></>)

                }
            }

        }
        return show;
    };

    return (<Fragment>
        {props.id1 !== "onlineFlightDetails" && < div className="row mb-2">
            <div className="col-md-6 text-muted mt-2 fw-bold">
                Option {props.id1}
            </div>
            <div className="col-md-6">

            </div>
        </div>}
        {
            multicityAirlineDetails.size === 0 && <div>
                <div className="flight_search_item_wrappper ">
                    <div className="flight_search_items">
                        {ShowAirineOuterCard()}
                    </div>
                    <div data="collapseExampleCommon"
                        className={`${props.page === 'retreive' ? "flight_policy_refund collapse " : "flight_policy_refund collapse show"}`}
                        id={"collapseExampleundefined" + props.id} >
                        {showAirlineInnerCard()}
                    </div>
                </div>
            </div>
        }
        {/* Mulyticity */}
        {
            multicityAirlineDetails.size !== 0 && result[0].bookingType === 'M' &&
            <div class="flight_search_item_wrappper xyz">
                <div class="flight_search_items">
                    <div class="multi_city_flight_lists w-100  flight-full-border">
                        {ShowAirineOuterCard()}
                        <div>
                            <hr class="my-1" />
                            <div class="px-3 py-2"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    {getRefundType(fareDetails.fareRuleType)}
                                </div>

                            </div>
                        </div>
                    </div>
                    {props.page === 'retreive' && <div className="flight_search_right col-lg-3 col-md-12 col-sm-12  position-relative">
                        <h6 id="itineraryDetails3" className="text-primary" data-bs-toggle="collapse" data-bs-target={"#collapseExampleundefined" + props.id} aria-controls={"collapseExampleundefined" + props.id} aria-expanded="true">Show Details <i className="fas fa-chevron-down"></i>
                        </h6>
                    </div>}
                </div>
                <div data="collapseExampleCommon" className={`${props.page === 'retreive' ? "flight_policy_refund collapse " : "flight_policy_refund collapse show"}`} id={"collapseExampleundefined" + props.id} >
                    {showAirlineInnerCard()}
                </div>
            </div>
        }

        {fareDetails !== undefined && fareDetails.length !== 0 && <FareSummary fareDetails={fareDetails} ></FareSummary>}
    </Fragment >);
}

export default Flight;

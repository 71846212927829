import { Fragment } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import { removeHtmlTags } from '../../../utils/CommonFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
const Header = (props) => {
    let showPropertyAmenities = false;
    let showPolicies = false;
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining' || result.text.type === 'SafetyInfo' || result.text.type === 'Recreation' || result.text.type === 'Services' || result.text.type === 'Facilities') {
                showPropertyAmenities = true;
            }
            if (result.text.type === 'CancellationPolicy' || result.text.type === 'Policies' || result.text.type === 'GuaranteePolicy' || result.text.type === 'DepositPolicy') {
                showPolicies = true;
            }
        })

    }

    return (<Fragment>
        <div className='col-md-5 postion-relative'>
            <div className="tour_details_right_sidebar_wrapper hotel-detail-name-slider">
                <div className="tour_detail_right_sidebar">
                    <div className="tour_details_right_boxed">
                        <div className="tour_details_right_box_heading">
                            <h3>{props.hotelDetails.data.hotelResults[0].hotelName !== undefined && removeHtmlTags(props.hotelDetails.data.hotelResults[0].hotelName)}</h3>
                            {props.hotelDetails.data.hotelResults[0].reviewRating !== undefined && props.hotelDetails.data.hotelResults[0].reviewRating !== '' && props.hotelDetails.data.hotelResults[0].reviewRating !== "NA" &&
                                <div className='text-muted text-14px mt-2 '>
                                    Rating:
                                    <span className='text-primary fw-bold '> {parseFloat(props.hotelDetails.data.hotelResults[0].reviewRating)}/5</span>
                                </div>}

                            <h6 className='text-muted mt-2'>{props.hotelDetails.data.hotelResults[0].address.addressLine.replace(//g, '')}, {props.hotelDetails.data.hotelResults[0].address.city.cityName}, {props.hotelDetails.data.hotelResults[0].address.country.countryName}</h6>
                        </div>
                        <div className="tour_package_details_bar_list pt-1 buttles-position-li">
                            <ul>

                                {
                                    props.hotelDetails.data.hotelResults[0].descriptions !== undefined &&
                                    <li><i className="fas fa-circle text-10px"></i><Link className='text-skyblue cursor-pointer' to="overview_section">Overview</Link></li>
                                }
                                {
                                    props.hotelDetails.data.hotelResults[0].roomRateDetails !== undefined && props.hotelDetails.data.hotelResults[0].roomRateDetails.length !== 0 &&
                                    <li><i className="fas fa-circle text-10px"></i>
                                        <Link className='text-skyblue cursor-pointer' to="room_section">Rooms</Link>
                                    </li>
                                }



                                {
                                    showPropertyAmenities &&
                                    < li ><i className="fas fa-circle text-10px"></i><Link className='text-skyblue cursor-pointer' to="amenities_section">Hotel Details</Link>
                                    </li>
                                }
                                {
                                    props.hotelDetails.data.hotelResults[0].descriptions !== undefined &&
                                    <li><i className="fas fa-circle text-10px"></i><Link className='text-skyblue cursor-pointer' to="location_section">About this Area</Link>
                                    </li>
                                }

                                {
                                    showPolicies &&
                                    <li><i className="fas fa-circle text-10px"></i><Link className='text-skyblue cursor-pointer' to="policies_section">Hotel Policies</Link>
                                    </li>
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="tour_select_offer_bar_bottom">
                        <button className="btn btn_theme btn_md w-100" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            {props.hotelDetails.data.hotelResults[0].roomRateDetails !== undefined && props.hotelDetails.data.hotelResults[0].roomRateDetails.length !== 0 && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") !== undefined && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") === true ? <Link to="room_section">Reserve Room</Link> : checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") === undefined || checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") === false ? <Link to="room_section"> Room</Link> : <span >Rooms Unavailable</span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >)
}

export default Header;
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import CorporateMasterItem from './CorporateMasterItem';
import { sortData } from '../../../../utils';
import { deleteCorporateMaster } from '../../../../services/CorporateMaster-API'
import useHttp from "../../../../services/use-http";
import ErrorModal from '../../../../components/Common/Error/ErrorModal';
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
const CorporateMasterList = (props) => {
  const parms = useParams();
  const { KeyCode } = parms;
  const [error1, setError] = useState();
  const { t } = useTranslation(['translation.CorporateMaster']);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get('sort') === 'asc';
  //Code for delete
  const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteCorporateMaster, true);
  const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);
  const sortedCorproateMaster = sortData(props.corproateMaster, 'name', isSortingAscending);

  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
    })
  };

  useEffect(() => {
    //document.querySelector("#sortBy").selectedIndex = 0;
    /* window.scrollTo({
      top: 200,
      behavior: "smooth",
    });

    history.push({
      pathname: '/SearchFees',
      search: `?sort=${('asc')}`
    }) */

    if (status === 'pending') {
      <Fragment>
        <section id='common_banner'><div className='container'></div></section>
        <div className='centered'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </Fragment>
    }
    if (status === 'completed') {
      if (loadedQuote['errors'].status === 'TRUE') {
        if (loadedQuote['errors'].error['code'] === '1010') {
          setError({
            title: "Message",
            message: `${t('errorRecordInUseDelete')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordDelete')}`,
          });
        }
        setIsErrorOnDelete(true);
      }
      else {
        setError({
          title: "Message",
          message: `${t('recordDeletedMessage')}`,
        });
      }
    }
  }, [status, history, loadedQuote, t])
  const deleteItemsHandler = (props) => {
    const onDelete = ({
      id: props.id,
      traceID: JSON.parse(localStorage.getItem('traceID')),
      officeID: JSON.parse(localStorage.getItem('officeID')),
      userID: JSON.parse(localStorage.getItem('userID')),
    });

    sendRequest(onDelete);

    if (error) {
      return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordDelete')}</div>
    }
    if ((!loadedQuote || loadedQuote.length === 0)) {
      return <p>{t('errorRecordDelete')}</p>
    }
  };
  useEffect(() => {
    history.push({
      pathname: '/SearchCorporateMaster/' + KeyCode,
      search: `?sort=${('asc')}`
    })
  }, [])
  const errorHandler = () => {
    setError(null);

    if (!isErrorOnDelete) {
      history.go({
        pathname: '/SearchCorporateMaster/' + KeyCode,
      })
    }
    else {
      history.push({
        pathname: '/SearchCorporateMaster/' + KeyCode,
      })
      history.go();
    }
  };
  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      <div id="sortByText" className="container-fluid mb-3 list-view my-3">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "30px" }}>
            <div className='row mb-3'>
              <div className="col-md-6"></div>
              <div className='col-md-2 my-auto text-muted text-left'>
                <div className="float-lg-end float-md-end">Sort By</div>
              </div>
              <div className="col-md-4 form-group">
                <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                  <option>{t('sortOptionAsc')}</option>
                  <option>{t('sortOptionDesc')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {sortedCorproateMaster.map((corproateMaster) => (
          <CorporateMasterItem
            onDelete={deleteItemsHandler}
            key={corproateMaster.id}
            id={corproateMaster.id}
            code={corproateMaster.code}
            name={corproateMaster.name}
            isActive={corproateMaster.isActive}
          />
        ))}

      </div>
    </Fragment>
  );
};
export default CorporateMasterList

import React, { Fragment, useEffect, useState } from 'react';
import { searchCity } from '../../../services/City-API'
import useHttp from '../../../services/use-http';
import Dropdown from 'react-select';

const CityDropDownList = ({ cityCodeValue, cityNameText, currentInputValue, iSDisabled, countryCode, isMulti = false, isTravelRequestView = false }) => {

    const { sendRequest, data: cityData, status } = useHttp(searchCity);
    const [valueCityData, setValueCityData] = useState();

    useEffect(() => {
        if (countryCode !== '' && currentInputValue === '') {
            const onSearchCity = ({
                countryCode: countryCode,
                cityCode: "",
                cityName: ""
            });

            sendRequest(onSearchCity);
        }
        if (isMulti === false) {
            setValueCityData(currentInputValue);
        }
        else {

        }
    }, [countryCode, sendRequest, currentInputValue])

    if (isMulti === false) {

        const handleChangeCity = (e) => {
            if (status === 'completed') {
                setValueCityData(e.value);
                cityCodeValue(e.value);
                cityNameText(e.label);
            }
        };

        let cityOptions = []
        let cityArrayData = [];
        for (let i in cityData) {
            if (cityData['errors'].status === 'FALSE') {
                if (i === 'data') {
                    cityArrayData = cityData[i];
                }
            }
        }

        for (let index = 0; index < cityArrayData.length; index++) {
            cityOptions = [...cityOptions, { value: cityArrayData[index].cityCode, label: cityArrayData[index].cityName + "  (" + cityArrayData[index].cityCode + ")" }]
        }

        //const getCityLabelByValue = (cityOptions, value) => cityOptions.find(cityOptions => cityOptions.value === value)?.label || "";
        const getCurrentInputValue = cityOptions.filter(function (option) {
            return option.value === cityOptions;
        })
        const getValueCityData = cityOptions.filter(function (option) {
            return option.value === valueCityData;
        })

        return (
            <div className='position-relative'>
                <Dropdown onChange={handleChangeCity}
                    value={getValueCityData !== undefined ? getValueCityData : getCurrentInputValue}
                    options={cityOptions}
                    textField="CityName"
                    dataItemKey="CityCode"
                    arrowClosed={<span className="arrow-closed" />}
                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                    //disabled={iSDisabled!==undefined?iSDisabled==="true"?true:false:false} 
                    isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                />
                {status === 'pending' ? isTravelRequestView === true ? <div className="input-spineer-city-travel-request-view"><i class="fa fa-spinner fa-spin"></i></div> : <div className="input-spineer-city"><i class="fa fa-spinner fa-spin"></i></div> : ''}
            </div>
        )
    }
    else {

        const handleChangeCity = (e) => {
            if (status === 'completed') {
                setValueCityData(e);
                cityCodeValue(e);
                cityNameText(e);
            }
        };

        let cityOptions = []
        let cityArrayData = [];
        for (let i in cityData) {
            if (cityData['errors'].status === 'FALSE') {
                if (i === 'data') {
                    cityArrayData = cityData[i];
                }
            }
        }

        for (let index = 0; index < cityArrayData.length; index++) {
            cityOptions = [...cityOptions, { value: cityArrayData[index].cityCode, label: cityArrayData[index].cityName }]
        }

        //const getCityLabelByValue = (cityOptions, value) => cityOptions.find(cityOptions => cityOptions.value === value)?.label || "";
        const getCurrentInputValue = cityOptions.filter(function (option) {
            return option.value === cityOptions;
        })
        const getValueCityData = cityOptions.filter(function (option) {
            return option.value === valueCityData;
        })

        return (
            <div className='position-relative'>
                <Dropdown onChange={handleChangeCity}
                    value={currentInputValue}
                    options={countryCode === '' ? [] : cityOptions}
                    textField="CityName"
                    dataItemKey="CityCode"
                    arrowClosed={<span className="arrow-closed" />}
                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                    //disabled={iSDisabled!==undefined?iSDisabled==="true"?true:false:false} 
                    isDisabled={iSDisabled !== undefined ? iSDisabled === "true" ? true : false : false}
                    isMulti={true}
                />
                {status === 'pending' ? isTravelRequestView === true ? <div className="input-spineer-city-travel-request-view"><i class="fa fa-spinner fa-spin"></i></div> : <div className="input-spineer-city"><i class="fa fa-spinner fa-spin"></i></div> : ''}
            </div>
        )
    }
}

export default CityDropDownList

import { useEffect, useState, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import useHttp from '../../../services/use-http';
import useHttpGet from '../../../services/use-http'
import { addApprover, getSingleApprover } from '../../../services/Approver-API'
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import ApproverForm from './Components/ApproverForm';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';

const UpdateApprover = (props) => {
    useDocumentTitle('Approval Workflow');
    const { t } = useTranslation(['translation.Approver']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    const { sendRequest: sendRequest1, data: workflowDetailsResponse, status: status1, error } = useHttpGet(getSingleApprover);
    const [workflowDetails, setWorkflowDetails] = useState();
    const { sendRequest, status, data: addApproverResponse } = useHttp(addApprover);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    function onAddToSelectedApprovalWorkflow(approvers) {
        if (workflowDetails && workflowDetails.length) {

            let tempWorkflowDetails = [...workflowDetails];

            let approvalLevel = 1;
            if (approvers[0].approvalTypeID === "2" || approvers[0].approvalTypeID === "7") {
                approvalLevel = approvers[0].approvalLevel;
            }

            tempWorkflowDetails = tempWorkflowDetails
                .filter(wfItem => (wfItem.approvalTypeID !== approvers[0].approvalTypeID) ||
                    (wfItem.approvalTypeID === approvers[0].approvalTypeID && wfItem.approvalLevel !== approvalLevel));

            approvers.forEach((approver) => {
                tempWorkflowDetails.push(approver);
            });

            setWorkflowDetails(tempWorkflowDetails);
        }
        else {
            setWorkflowDetails(approvers);
        }
    }

    function addApproverHandler(requestData) {
        sendRequest(requestData);
    }

    function errorHandler() {
        setError(null);

        if (!addApproverResponse || (addApproverResponse['errors'].error['code'] === '1001')) {
            history.push('/SearchApprover');
        }
        if (addApproverResponse['errors'].status !== 'TRUE') {
            history.push('/SearchApprover');
        }
    }

    function onApproverDelete(approverInfo) {

        let tempWorkflowDetails = [...workflowDetails];

        tempWorkflowDetails = tempWorkflowDetails
            .filter((wfItem) => !(wfItem.approvalUserID === approverInfo.approvalUserID &&
                wfItem.approvalLevel === approverInfo.approvalLevel &&
                wfItem.approvalTypeID === approverInfo.approvalTypeID));

        //Re-adjust approval level if all approvers for a particular approval level are deleted
        if (approverInfo.approvalTypeID === "2" || approverInfo.approvalTypeID === "7") {
            if (!tempWorkflowDetails.some(wfItem => wfItem.approvalTypeID === approverInfo.approvalTypeID &&
                wfItem.approvalLevel === approverInfo.approvalLevel)) {

                tempWorkflowDetails.forEach(wfItem => {
                    if (wfItem.approvalTypeID === approverInfo.approvalTypeID &&
                        wfItem.approvalLevel > approverInfo.approvalLevel) {
                        wfItem.approvalLevel -= 1;
                    }
                });

            }
        }

        setWorkflowDetails(tempWorkflowDetails);
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (addApproverResponse && addApproverResponse['errors'].status === 'TRUE') {
                    if (addApproverResponse['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: t('errorRecordSave'),
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: t('errorRecordSave'),
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: sucsssMessage,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, Id, isEdit, sucsssMessage, t, addApproverResponse])


    useEffect(() => {

        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });

        if (Id !== undefined) {
            var strRequestData = ({
                approvalMasterID: Id,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                serviceTypeID: JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeID
            })
            sendRequest1(strRequestData)
        }
    }, []);

    useEffect(() => {

        if (workflowDetailsResponse) {
            if (workflowDetailsResponse.errors.status === 'FALSE') {
                setWorkflowDetails(() => workflowDetailsResponse.data);
            }
            else {
                setError(() => {
                    return {
                        title: "Message",
                        message: t('msgRecordCannotBeDisplayed'),
                    }
                });
            }
        }

    }, [workflowDetailsResponse, t]);

    return <Fragment>
        {status1 === 'pending' &&
            <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
        }
        {status1 === 'completed' &&
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {status1 === 'completed' &&
            <ApproverForm
                isLoading={status === 'pending'}
                onAddApprover={addApproverHandler}
                workflowDetails={workflowDetails}
                onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
                onApproverDelete={onApproverDelete} />
        }
    </Fragment>
};

export default UpdateApprover;
import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { getFormatDate } from '.././../../../utils/CommonFunction';
import useHttp from '../../../../services/use-http';
import { searchCorporateMaster } from '../../../../services/CorporateMaster-API';
import { getAllCountry } from '../../../../services/Country-API';
import { searchCity } from '../../../../services/City-API';

const MeetingDetails = (props) => {
    const [meetingStartDate, setMeetingStartDate] = useState();
    const [meetingEndDate, setMeetingEndDate] = useState();
    const [purposeOfTravelValue, setPurposeOfTravelValue] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [meetingDateRangeStart, setMeetingDateRangeStart] = useState();
    const [meetingDateRangeEnd, setMeetingDateRangeEnd] = useState();
    const [meetingStartTime, setMeetingStartTime] = useState();
    const [meetingEndTime, setMeetingEndTime] = useState();

    const { sendRequest: sendRequestCorproateMaster, status: statusCorproateMaster, data: loadedCorproateMaster, error } = useHttp(searchCorporateMaster);
    const [tripPurposeRemarks, setTripPurposeRemarks] = useState('');
    const [tripPurposeRemarksHasInvalidData, setTripPurposeRemarksHasInvalidData] = useState(false);

    const [countryOptions, setCountryOptions] = useState([]);
    const { sendRequest: getCountryOptions, data: countryMaster, status: getCountryOptionsStatus } = useHttp(getAllCountry);

    const [cityOptions, setCityOptions] = useState([]);
    const { sendRequest: getCityOptions, data: cityMaster, status: getCityOptionsStatus } = useHttp(searchCity);

    let purposeOfTravelOptions = [];

    const calGetMeetingStartDate = (inputDate) => {
        sessionStorage.removeItem("fromDateChangedCBT");
        document.querySelector('#meetingStartDate').value = moment(new Date(inputDate.target.value)).format("D MMM YYYY");
        const fromDate = new Date(inputDate.target.value.replace("'", ""));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingStartDate(fromDate);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingStartDate = fromDateDB;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    const calGetMeetingEndDate = (inputDate) => {
        sessionStorage.removeItem("toDateChangedCBT");
        document.querySelector('#meetingEndDate').value = moment(new Date(inputDate.target.value)).format("D MMM YYYY");
        const toDate = new Date(inputDate.target.value.replace("'", ""));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingEndDate(toDate);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingEndDate = toDateDB;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    if (document.querySelector('#meetingStartDate') !== null && (document.querySelector('#meetingStartDate').value.includes('/') || document.querySelector('#meetingStartDate').value.includes('-'))) {
        document.querySelector('#meetingStartDate').value = moment(new Date(document.querySelector('#meetingStartDate').value)).format('D MMM YYYY');
    }

    if (document.querySelector('#meetingEndDate') !== null && (document.querySelector('#meetingEndDate').value.includes('/') || document.querySelector('#meetingEndDate').value.includes('-'))) {
        document.querySelector('#meetingEndDate').value = moment(new Date(document.querySelector('#meetingEndDate').value)).format('D MMM YYYY');
    }

    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }

    loadedData.forEach(element => {
        purposeOfTravelOptions.push({ value: element.id, label: element.name });
    });

    const purposeOfTravelChangeHandler = (event) => {
        setPurposeOfTravelValue(event.value);

        document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "";

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingPurposeOfTravel = event.value;
        travelRequestFieldsDataJson.meetingPurposeOfTravelText = event.label;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    };

    function onBlurCheck() {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.meetingStartTime = document.querySelector('#meetingStartTime').value.replace(':', '');
            travelRequestFieldsDataJson.meetingEndTime = document.querySelector('#meetingEndTime').value.replace(':', '');
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));

            setMeetingStartTime(travelRequestFieldsDataJson.meetingStartTime);
            setMeetingEndTime(travelRequestFieldsDataJson.meetingEndTime);
        }
    }

    function handleTripPurposeRemarksChange(event) {
        const remarksText = event.target.value;
        setTripPurposeRemarks(remarksText);
    }

    function handleTripPurposeRemarksBlur(event) {
        let isTripPurposeRemarksInvalid = false;
        //if (tripPurposeRemarks && !tripPurposeRemarks.match("^[a-zA-Z .()0-9,\\'\\-\\n]+$")) {
        //    isTripPurposeRemarksInvalid = true;
        //}

        setTripPurposeRemarksHasInvalidData(isTripPurposeRemarksInvalid);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = isTripPurposeRemarksInvalid;
        travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks.trim();
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    function handleCountryChange(selectedOption) {
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        if (selectedOption) {
            setCountryCode(selectedOption.value);
            travelRequestFieldsDataJson.meetingCountry = selectedOption.value;
            setCityCode('');
            setCityOptions([]);
            //getCities(selectedOption.value);
        } else {
            //clear triggered
            travelRequestFieldsDataJson.meetingCountry = '';
            setCountryCode('');
            setCityCode('');
            setCityOptions([]);
        }

        travelRequestFieldsDataJson.meetingCity = '';
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    function getCities(countryCode) {
        getCityOptions({
            countryCode: countryCode,
            cityCode: '',
            cityName: ''
        });
    }

    function handleCityChange(selectedOption) {
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        if (selectedOption) {
            setCityCode(selectedOption.value);
            travelRequestFieldsDataJson.meetingCity = selectedOption.value;
        } else {
            //clear triggered
            setCityCode('');
            travelRequestFieldsDataJson.meetingCity = '';
        }

        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    //The assumption with setting travel request values in this useEffect is that user will first fill other services and then meeting details last.
    //Due to technical complexitiy, 
    useEffect(() => {
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        let mtngCountry = '';
        let mtngCity = '';
        let mtngStartDate = '';
        let mtngDateRangeStart = '';
        let mtngStartDateForTravelRequest = '';
        let mtngEndDate = '';
        let mtngDateRangeEnd = '';
        let mtngEndDateForTravelRequest = '';
        let mtngStartTime = '';
        let mtngEndTime = '';

        if (sessionStorage.getItem("spnToAirportDetails1") !== null) {
            let selectedToCountry = sessionStorage.getItem("spnToAirportDetails1") !== null ?
                sessionStorage.getItem("spnToAirportDetails1").split(',').at(-1).trim() : '';
            let selectedToCity = sessionStorage.getItem("spnToAirportDetails1") !== null ?
                sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim() : '';

            if (localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                //set meeting as per flight search
                let bookingDataTravelRequestJson = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let originDestinationOptionLengthOutbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'O').length;
                let originDestinationOptionLengthInbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I').length;
                let originDestinationOptionLengthMulticity = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'M').length;

                mtngCountry = countryCode || originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryCode;

                if (mtngCountry) {
                    mtngCity = cityCode || originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.cityCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.cityCode;
                }

                let departureDate = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate;
                let arrivalDate = originDestinationOptionLengthInbound <= 0 ?
                    originDestinationOptionLengthMulticity <= 0 ?
                        bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate :
                        bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate :
                    bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate;

                mtngDateRangeStart = new Date(getFormatDate(departureDate));
                mtngStartDateForTravelRequest = departureDate;
                mtngStartDate = meetingStartDate || new Date(getFormatDate(departureDate));
                mtngStartTime = meetingStartTime || "0900";

                mtngDateRangeEnd = new Date(getFormatDate(arrivalDate));
                mtngEndDateForTravelRequest = arrivalDate;
                mtngEndDate = meetingEndDate || new Date(getFormatDate(arrivalDate));
                mtngEndTime = meetingEndTime || "1800";
            }
            else if (localStorage.getItem("TravelRequestInput") !== null && localStorage.getItem("TravelRequestInput") !== '') {
                let travelRequestJSON = JSON.parse(localStorage.getItem("TravelRequestInput"));
                //set meeting as per hotel search
                if (travelRequestJSON.data.onlineHotelDetails !== undefined && travelRequestJSON.data.onlineHotelDetails !== null) {

                    mtngCountry = countryCode || selectedToCountry;

                    if (mtngCountry) {
                        mtngCity = cityCode ? cityCode : (sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);
                    }

                    let checkInDate = travelRequestJSON.data.onlineHotelDetails[0].checkInDate;
                    let checkOutDate = travelRequestJSON.data.onlineHotelDetails[0].checkOutDate;

                    mtngDateRangeStart = new Date(getFormatDate(checkInDate));
                    mtngStartDateForTravelRequest = checkInDate;
                    mtngStartDate = meetingStartDate || new Date(getFormatDate(checkInDate));
                    mtngStartTime = meetingStartTime || "0900";

                    mtngDateRangeEnd = new Date(getFormatDate(checkOutDate));
                    mtngEndDateForTravelRequest = checkOutDate;
                    mtngEndDate = meetingEndDate || new Date(getFormatDate(checkOutDate));
                    mtngEndTime = meetingEndTime || "1800";
                }
                else {
                    mtngCountry = countryCode || selectedToCountry;

                    if (mtngCountry) {
                        mtngCity = cityCode ? cityCode : (sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);
                    }

                    mtngDateRangeStart = new Date();
                    mtngStartDateForTravelRequest = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                    mtngStartDate = meetingStartDate || new Date();
                    mtngStartTime = meetingStartTime || "0900";

                    mtngDateRangeEnd = new Date();
                    mtngEndDateForTravelRequest = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                    mtngEndDate = meetingEndDate || new Date();
                    mtngEndTime = meetingEndTime || "1800";
                }
            }
            else {
                mtngCountry = countryCode || selectedToCountry;

                if (mtngCountry) {
                    mtngCity = cityCode ? cityCode : (sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);
                }

                mtngDateRangeStart = new Date();
                mtngStartDateForTravelRequest = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                mtngStartDate = meetingStartDate || new Date();
                mtngStartTime = meetingStartTime || "0900";

                mtngDateRangeEnd = new Date();
                mtngEndDateForTravelRequest = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                mtngEndDate = meetingEndDate || new Date();
                mtngEndTime = meetingEndTime || "1800";
            }
        }

        if (travelRequestFieldsDataJson) {
            travelRequestFieldsDataJson.meetingCountry = mtngCountry;
            travelRequestFieldsDataJson.meetingCity = mtngCity;
            travelRequestFieldsDataJson.meetingStartDate = mtngStartDateForTravelRequest;
            travelRequestFieldsDataJson.meetingEndDate = mtngEndDateForTravelRequest;
            travelRequestFieldsDataJson.meetingStartTime = mtngStartTime;
            travelRequestFieldsDataJson.meetingEndTime = mtngEndTime;
            travelRequestFieldsDataJson.meetingPurposeOfTravel = purposeOfTravelValue;
            travelRequestFieldsDataJson.meetingPurposeOfTravelText = '';
            travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks;
            travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = tripPurposeRemarksHasInvalidData;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }

        setCountryCode(() => mtngCountry);
        setCityCode(() => mtngCity);

        if (mtngStartDate) {
            setMeetingDateRangeStart(() => moment(mtngDateRangeStart).format("D MMM YYYY"));
            document.querySelector('#meetingStartDate').value = moment(mtngStartDate).format("D MMM YYYY");
        }

        if (mtngStartTime) {
            document.querySelector('#meetingStartTime').value = mtngStartTime.substring(0, 2) + ":" + mtngStartTime.substring(2, 4);
        }

        if (mtngEndDate) {
            setMeetingDateRangeEnd(() => moment(mtngDateRangeEnd).format("D MMM YYYY"));
            document.querySelector('#meetingEndDate').value = moment(mtngEndDate).format("D MMM YYYY");
        }

        if (mtngEndTime) {
            document.querySelector('#meetingEndTime').value = mtngEndTime.substring(0, 2) + ":" + mtngEndTime.substring(2, 4);
        }

    }, [props.refreshKey]);

    useEffect(() => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.meetingCountry) {
                setCountryCode(() => countryCode ? countryCode : travelRequestFieldsDataJson.meetingCountry);
            }
            if (travelRequestFieldsDataJson.meetingCity) {
                setCityCode(() => cityCode ? cityCode : travelRequestFieldsDataJson.meetingCity);
            }
            if (travelRequestFieldsDataJson.meetingStartDate) {
                setMeetingDateRangeStart(() => moment(new Date(getFormatDate(travelRequestFieldsDataJson.meetingStartDate))).format("D MMM YYYY"));
            }
            if (travelRequestFieldsDataJson.meetingEndDate) {
                setMeetingDateRangeEnd(() => moment(new Date(getFormatDate(travelRequestFieldsDataJson.meetingEndDate))).format("D MMM YYYY"));
            }
            if (travelRequestFieldsDataJson.meetingStartTime) {
                document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime ?
                    travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
            }
            if (travelRequestFieldsDataJson.meetingEndTime) {
                document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime ?
                    travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";
            }
            if (travelRequestFieldsDataJson.meetingPurposeOfTravel) {
                setPurposeOfTravelValue(() => travelRequestFieldsDataJson.meetingPurposeOfTravel);
            }
            if (travelRequestFieldsDataJson.tripPurposeRemarks) {
                setTripPurposeRemarks(() => travelRequestFieldsDataJson.tripPurposeRemarks);
            }
        }

        const onSearchCorporateMaster = ({
            id: '',
            userID: JSON.parse(localStorage.getItem('userID')),
            name: "",
            code: "",
            keyCode: "FOURTH",
            status: '2',
            officeID: JSON.parse(localStorage.getItem("officeID"))
        });
        sendRequestCorproateMaster(onSearchCorporateMaster);

        getCountryOptions();

    }, []);

    useEffect(() => {
        if (countryMaster
            && countryMaster.errors.status === 'FALSE'
            && countryMaster.data) {

            setCountryOptions(() => countryMaster.data.map(country => {
                return {
                    value: country.countryCode,
                    label: country.countryName
                };
            }));

        }
    }, [countryMaster]);

    //handle after getting city data from api
    useEffect(() => {
        if (cityMaster
            && cityMaster.errors.status === 'FALSE'
            && cityMaster.data) {

            //This condition is added in order to handle a case where user selects a country and immediately deselects/removes selection.
            //In this case, city options will not be populated
            if (countryCode) {
                setCityOptions(() => cityMaster.data.map(city => {
                    return {
                        value: city.cityCode,
                        label: `${city.cityName} (${city.cityCode})`
                    };
                }));
            }
        }
    }, [cityMaster]);

    useEffect(() => {
        //Ideally fetching cities should be done in country change handler but since country code is being set from multiple locations,
        //doing it through effect seems more clean.
        if (countryCode) {
            getCities(countryCode);
        }
    }, [countryCode]);

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="meetingCounty">Country<span className="error-icon meeting" id='spnMeetingCounty'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown
                                            value={countryOptions.filter(option => option.value === countryCode)}
                                            options={countryOptions || []}
                                            onChange={handleCountryChange}
                                            isLoading={getCountryOptionsStatus === 'pending'}
                                            controlClassName="form-control control form-select bg_input mb-0"
                                            placeholder="Please Select"
                                        />

                                        <p className="error-text" id='countryErrorMsg'></p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="meetingCity">City<span className="error-icon meeting" id='spnMeetingCity'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown
                                            value={cityOptions.filter(option => option.value === cityCode)}
                                            options={cityOptions || []}
                                            onChange={handleCityChange}
                                            isLoading={getCityOptionsStatus === 'pending'}
                                            controlClassName="form-control control form-select bg_input mb-0"
                                            placeholder="Please Select"
                                        />

                                        <p className="error-text" id='cityErrorMsg'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="meetingStartDate">Start Date<span className="error-icon meeting" id='spnMeetingStartDate'> *</span></label>
                                    <DateRangePicker key={meetingDateRangeStart}
                                        onHide={calGetMeetingStartDate}
                                        initialSettings={{
                                            startDate: meetingDateRangeStart ? new Date(meetingDateRangeStart) : new Date(),
                                            singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingStartDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                    <p className="error-text" id='startDateErrorMsg'></p>
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="meetingEndDate">End Date<span className="error-icon meeting" id='spnMeetingEndDate'> *</span></label>
                                    <DateRangePicker key={meetingDateRangeEnd}
                                        onHide={calGetMeetingEndDate}
                                        initialSettings={{
                                            startDate: meetingDateRangeEnd ? new Date(meetingDateRangeEnd) : new Date(),
                                            singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingEndDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                    <p className="error-text" id='endDateErrorMsg'></p>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingStartTime" className="mb-2 h6 text-primary col-lg-12">Start Time<span className="error-icon meeting" id='spnMeetingStartTime'> *</span></label>
                                    <input type="time" id='meetingStartTime' className="form-control form-control-sm" onBlur={onBlurCheck} />
                                    <p className="error-text" id='startTimeErrorMsg'></p>
                                </div>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingEndTime" className="mb-2 h6 text-primary col-lg-12">End Time<span className="error-icon meeting" id='spnMeetingEndTime'> *</span></label>
                                    <input type="time" id='meetingEndTime' className="form-control form-control-sm" onBlur={onBlurCheck} />
                                    <p className="error-text" id='endTimeErrorMsg'></p>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="purposeOfTravel">Trip Purpose<span className="error-icon meeting" id='spnPurposeOfTravel'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown onChange={purposeOfTravelChangeHandler}
                                            value={purposeOfTravelOptions.filter(option => option.value === purposeOfTravelValue)}
                                            options={purposeOfTravelOptions}
                                            textField="value"
                                            dataItemKey="label"
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />}
                                            controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                            placeholder="Please Select"
                                            isLoading={statusCorproateMaster === 'pending'}
                                        />
                                        <p className="error-text" id='purposeOfTravelErrorMsg'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="tripPurposeRemarks">
                                        Trip Purpose Remarks
                                    </label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='tripPurposeRemarks2'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            value={tripPurposeRemarks}
                                            onChange={handleTripPurposeRemarksChange}
                                            onBlur={handleTripPurposeRemarksBlur}
                                        />
                                        {tripPurposeRemarksHasInvalidData &&
                                            <p className="error-text">
                                                Please specify valid data.
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default MeetingDetails;
import { Link, useParams } from 'react-router-dom';
import { Fragment, useState } from 'react';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
const CorporateMasterItem = (props) => {
  const { t } = useTranslation(['translation.CorporateMaster']);
  const parms = useParams();
  const { KeyCode } = parms;
  const [confirm, setConfirm] = useState();
  function okHandler() {
    deleteFormHandler(props)
    setConfirm(false);
  };
  function cancelHandler() {
    setConfirm(false);
  };
  function onConfirm() {
    setConfirm(true);
  }
  function deleteFormHandler(props) {
    // optional: Could validate here    
    props.onDelete({
      id: props.id,
      traceID: JSON.parse(localStorage.getItem('traceID')),
      userID: JSON.parse(localStorage.getItem('userID')),
      iPAddress: localStorage.getItem("IPAddress"),
    });
  }
  return (
    <Fragment>
      {confirm && (
        <ConfirmationModal
          title={t('confirmationMessageTitle')}
          message={t('confirmationMessage')}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}

      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-1">
          <div className="card text-dark card-bg-blue mb-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="card-title fw-bold text-primary mt-1">
                    {props.name} {props.code !== '' ? `(${props.code})` : ''}
                  </h6>
                  <hr className="my-1" />
                </div>

                <div className="col-md-8">

                  <p className="card-text mb-0"><strong>Active: </strong>{props.isActive ? "Yes" : "No"}</p>

                </div>
                <div className="col-md-4 my-auto">
                  <div className='float-lg-end float-md-end'>
                    {checkSecurityDetails("Master", KeyCode.replace("_", " "), "Master_" + KeyCode.replace("_", " ") + "_View") &&
                      <span><Link id={"view" + props.id} className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" to={{ pathname: `/UpdateCorporateMaster/${KeyCode}/${props.id}/0`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                        <ReactTooltip
                          anchorId={"view" + props.id}
                          place="bottom"
                          variant="info"
                          multiline={true}
                          className="tooltip"
                          html={"View"}
                        /></span>}
                    {checkSecurityDetails("Master", KeyCode.replace("_", " "), "Master_" + KeyCode.replace("_", " ") + "_Update") &&
                      <span><Link id={"edit" + props.id} className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn" to={{ pathname: `/UpdateCorporateMaster/${KeyCode}/${props.id}/1`, edit: 1 }}>
                        <i className="fa-solid fa-pen"></i>
                      </Link>
                        <ReactTooltip
                          anchorId={"edit" + props.id}
                          place="bottom"
                          variant="info"
                          multiline={true}
                          className="tooltip"
                          html={"Edit"}
                        /></span>}
                    {checkSecurityDetails("Master", KeyCode.replace("_", " "), "Master_" + KeyCode.replace("_", " ") + "_Delete") &&
                      <span><button id={"Delete" + props.id} onClick={() => { onConfirm() }} className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" ><i className="fas fa-trash-alt"></i></button>
                        <ReactTooltip
                          anchorId={"Delete" + props.id}
                          place="bottom"
                          variant="info"
                          multiline={true}
                          className="tooltip"
                          html={"Delete"}
                        /></span>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CorporateMasterItem

import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateBookingJSON } from './../../../utils/HotelBookingFunction';
import { toTitleCase } from "../../../utils/CommonFunction";
import { hotelPriceCheck } from '../../../services/Hotel-API'
import useHttp from '../../../services/use-http'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import RoomDetailsPopup from "./RoomDetailsPopup"
import RoomPopup from './RoomPopup';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SINGLEPNRBOOKING } from '../../../navigation/CONSTANTS';
import { CreateBookingJSONShoppingCart } from './../../../utils/ShoppingCartFunction';
import { insertRoom, removeRoom } from '../../../utils/SendRecommendation';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import OutPolicy from '../../../pages/OBT/TravelRequest/Components/OutPolicy';
import { sortHotelResult } from '../../../utils/HotelSort';
import { getDateDiffDays } from '../../../../src/utils/CommonFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import OutPolicyPopupModal from '../../../../src/components/Common/Flight/OutPolicyPopupModal';

const Room = (props) => {
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "defaultHotel.png"
    //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    const history = useHistory();
    const [roomError, setRoomError] = useState();
    const [roomLimitError, setRoomLimitError] = useState();
    const [errorOutPolicy, setErrorOutPolicy] = useState();
    const [justificationTextData, setJustificationTextData] = useState();

    const { sendRequest, status, data: loadedHotelPriceCheck, error } = useHttp(hotelPriceCheck);

    let hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
    let hotelDetailsResponsePrice = hotelDetailsResponse.data.hotelResults[0].price.filter(a => a.rateKey === props.roomDetails.rateKey);
    let hotelDetailsResponseTaxes = hotelDetailsResponse.data.hotelResults[0].roomRateDetails.filter(a => a.rateKey === props.roomDetails.rateKey);

    let totalTax = 0;
    let totalBaseRate = 0;

    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let equivalentAmountValue = calculateRoeAmountAdditional(hotelDetailsResponsePrice[0].currencyCode, hotelDetailsResponsePrice[0].offeredPrice.priceOffered, false);

    // User Story 3800: Remove space in Room details section
    let noOfSpaces = 0;
    if (hotelDetailsResponse.data.hotelResults[0].numberOfService !== undefined) {
        noOfSpaces = parseInt(hotelDetailsResponse.data.hotelResults[0].numberOfService);
    }

    hotelDetailsResponseTaxes.forEach(item => {
        if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
            totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
            totalBaseRate += item.price.publishedPrice.pricePublished;
        }
        else {
            totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
            totalBaseRate += item.price !== undefined && item.price !== null ? item.price.publishedPrice.pricePublished : 0;
        }
    });

    let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'));
    const [confirm, setConfirm] = useState('');
    const [confirmModel, setConfirmModel] = useState();

    const onBookNow = (id) => {
        if (props.roomPriceIssue) {
            setConfirm({
                title: "Message",
                message: "The hotel is unable to confirm room prices. Please select another hotel.",
            });
        }
        else {
            if (props.travelReq !== undefined && props.travelReq === 'true') {
                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.justificationCodeHotel = {};
                    travelRequestFieldsDataJson.justificationDetailsHotel = {};
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }

                if (hotelDetailsResponseTaxes[0].inPolicy !== undefined && hotelDetailsResponseTaxes[0].inPolicy === true) {
                    GetHotelDetails(props.roomDetails);

                    sessionStorage.removeItem("HotelIntialTotalAmount");
                    history.push('/TravelRequest?return=1&service=hotel');
                }
                else if (hotelDetailsResponseTaxes[0].inPolicy !== undefined && hotelDetailsResponseTaxes[0].inPolicy !== '' && hotelDetailsResponseTaxes[0].inPolicy === false) {
                    OutPolicyPopup();
                }
                else if (hotelDetailsResponseTaxes[0].inPolicy === undefined || hotelDetailsResponseTaxes[0].inPolicy === '') {
                    OutPolicyPopup();
                }
            }
            else {
                //User Story 1070: Change in Search for Single PNR
                let bookingJSON = "";
                if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                    bookingJSON = CreateBookingJSONShoppingCart(props.roomDetails, "HT")
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));

                    //updated BookingDataShoppingCart json for single pnr flight price update.
                    let bookingJSONShoppingCart = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
                    let bookingJSONPriceCheck = JSON.parse(localStorage.getItem("BookingData"));
                    bookingJSONShoppingCart.data.airItinerary[0].airItineraryPricingInfo = bookingJSONPriceCheck.data.airItinerary[0].airItineraryPricingInfo;
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSONShoppingCart))

                    sessionStorage.removeItem("HotelIntialTotalAmount");
                }
                else {
                    bookingJSON = CreateBookingJSON(props.roomDetails)
                    if (getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" && localStorage.getItem("ProfileData") !== null) {
                        bookingJSON = updateBookingJSONB2CProfile(bookingJSON);
                    }
                    sessionStorage.setItem("HotelBookingData", JSON.stringify(bookingJSON));
                    sessionStorage.removeItem("HotelIntialTotalAmount");
                }
                sendRequest(bookingJSON);
            }
        }
    }

    const roomDetails = () => {
        setRoomError({
            title: 'Room Details',
            message: <RoomPopup roomDetails={props.roomDetails} hotelDetailsResponsePrice={hotelDetailsResponsePrice} hotelDetailsResponseTaxes={hotelDetailsResponseTaxes} totalBaseRate={totalBaseRate} totalTax={totalTax} onBookNow={onBookNow} hotelSearchRequestJson={hotelSearchRequestJson} status={status} roomPriceIssue={props.roomPriceIssue}></RoomPopup>
        });
    }

    const errorHandler = () => {
        setRoomError(null);
        setRoomLimitError(null);
    };

    useEffect(() => {
        if (status === 'completed') {
            if (loadedHotelPriceCheck !== null && loadedHotelPriceCheck.data !== null) {

                if (loadedHotelPriceCheck.data.errors.status === 'TRUE') {
                    setConfirm({
                        title: "Message",
                        message: loadedHotelPriceCheck.data.errors.error.description,
                    });
                }
                else {
                    let hotelBookingJSON = localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(sessionStorage.getItem("HotelBookingData"));
                    hotelBookingJSON.data.bookingKey = loadedHotelPriceCheck.data.bookingKey;

                    if (loadedHotelPriceCheck.data.hotelResults[0].isPriceChange) {
                        hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                            elementHotelResults.price.forEach(elementPrice => {
                                elementPrice.roomPrice = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.roomPrice;
                                elementPrice.publishedPrice.pricePublished = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished;
                                elementPrice.publishedPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.perNight;
                                elementPrice.offeredPrice.priceOffered = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.priceOffered;
                                elementPrice.offeredPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.perNight;
                            });
                        });

                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

                        setConfirmModel({
                            title: "Message",
                            message: `The room price has changed from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: hotelDetailsResponsePrice[0].currencyCode }).format(loadedHotelPriceCheck.data.hotelResults[0].price[0].offeredPrice.priceOffered)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: hotelDetailsResponsePrice[0].currencyCode }).format(loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.priceOffered)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
                        //User Story 1073: Booking Summary for Single PNR
                        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                            history.push(SINGLEPNRBOOKING);
                        }
                        else {
                            history.push(`HotelBookings`);
                        }
                    }
                }
            }
            else {
                setConfirm({
                    title: "Message",
                    message: `Sorry, we have an encountered an issue. Please try again.`,
                });
            }
            return;
        }
    }, [status, history, loadedHotelPriceCheck])

    function okHandler() {
        setConfirm('');
    };

    function okConfirmHandler() {
        setConfirmModel(true);
        //User Story 1073: Booking Summary for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push(SINGLEPNRBOOKING);
        }
        else {
            history.push(`HotelBookings`);
        }
    };

    function cancelHandler() {
        setConfirm(false);
        setConfirmModel(false);
    };

    function getServices(RoomData) {
        let showServices = [];
        if (RoomData.roomDetails !== undefined && RoomData.roomDetails !== null && RoomData.roomDetails.inclusions !== undefined && RoomData.roomDetails.inclusions !== null && (RoomData.roomDetails.inclusions.filter(a => a.name.toString().includes('Internet')) || RoomData.roomDetails.inclusions.filter(a => a.name.toString().includes('internet')))) {
            showServices.push(<p key={"Free WiFi"} className="card-text text-black"><i className="fa-solid fa-wifi"></i> Free WiFi</p>)
        }
        if (props.roomDetails.roomViewDescription !== undefined && props.roomDetails.roomViewDescription !== undefined && props.roomDetails.roomViewDescription !== null) {
            showServices.push(<p key={props.roomDetails.roomViewDescription} className="card-text text-black"><i className="fa-solid fa-tree-city"></i> {props.roomDetails.roomViewDescription}</p>)
        }
        if (RoomData.roomDetails !== undefined && RoomData.roomDetails !== null && RoomData.roomDetails.bedTypes !== undefined && RoomData.roomDetails.bedTypes !== null) {
            showServices.push(<p key={props.roomDetails.roomDetails.bedTypes[0].bedTypeDescription} className="card-text text-black"><i className="fa-solid fa-bed"></i> {props.roomDetails.roomDetails.bedTypes[0].bedTypeDescription}</p>)
        }
        // User Story 3800: Remove space in Room details section
        noOfSpaces = noOfSpaces - parseInt(showServices.length)
        return showServices;
    }

    function getMealType(Meals) {
        let showMeal = [];
        if (Meals !== null && Meals.length !== 0) {
            if (Meals[0].mealPlan !== undefined && Meals[0].mealPlan && Meals[0].mealPlanDescription !== undefined && Meals[0].mealPlanDescription !== '') {
                showMeal.push(<p key={Meals[0].mealPlanDescription} className="card-text text-black">  <span className='fw-bold'>Meal Plan:</span> {Meals[0].mealPlanDescription}</p>)
            }
            if (Meals[0].breakfastIncluded !== undefined && Meals[0].breakfastIncluded) {
                showMeal.push(<p key={"Breakfast Included"} className="card-text text-black"><i className="fas fa-utensils"></i> Breakfast Included</p>)
            }
            if (Meals[0].dinnerIncluded !== undefined && Meals[0].dinnerIncluded) {
                showMeal.push(<p key={"Dinner Included"} className="card-text text-black"><i className="fas fa-utensils"></i> Dinner Included</p>)
            }

            if (Meals[0].lunchIncluded !== undefined && Meals[0].lunchIncluded) {
                showMeal.push(<p key={"Lunch Included"} className="card-text text-black"><i className="fas fa-utensils"></i> Lunch Included</p>)
            }

        }
        // User Story 3800: Remove space in Room details section
        noOfSpaces = noOfSpaces - parseInt(showMeal.length)
        return showMeal
    }

    function maintainCardHeight(n) {
        let cardHeight = [];
        for (let i = 0; i < n; i++) {
            cardHeight.push(<div key={i + 1}>
                <p className="card-text text-black">&nbsp;</p>
            </div>)
        }

        return cardHeight;
    }

    const addHotel = (event, HotelData, RoomData) => {
        let RoomList = []
        if (event.target.checked === true) {
            if (sessionStorage.getItem('RoomDetailJson') !== null) {
                RoomList = JSON.parse(sessionStorage.getItem('RoomDetailJson'));
            }
            if (RoomList.length < 6) {
                insertRoom(HotelData, RoomData)
            } else {
                setRoomLimitError(true);
                document.getElementById('addToProposal' + RoomData.roomID).checked = false
            }
        } else {
            removeRoom(HotelData, RoomData)
        }
    }

    const justificationCodeValue = (justificationCode) => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.justificationCodeHotel = justificationCode;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    };

    const justificationTextValue = (justificationText) => {
        // if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        //     let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        //     travelRequestFieldsDataJson.justificationCodeFlight = justificationText;
        //     sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        // }
        setJustificationTextData(justificationText);
    };

    const justificationDetailsValue = (justificationDetails) => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.justificationDetailsHotel = justificationDetails;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    };

    const OutPolicyPopup = () => {
        setErrorOutPolicy({
            title: "Reason",
            message: <OutPolicy
                justificationCode={justificationCodeValue}
                justificationText={justificationTextValue}
                justificationDetails={justificationDetailsValue}
                keyCode={'SEVEN'}
            ></OutPolicy>,
        });
    }

    const errorHandlerOutPolicy = () => {
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        if ((justificationTextData === undefined || justificationTextData === '') && travelRequestFieldsDataJson.justificationDetailsHotel.length === undefined) {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "Reason is required.";
            return;
        }
        else {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "";
        }

        if ((justificationTextData === undefined || justificationTextData === '') && travelRequestFieldsDataJson.justificationDetailsHotel.length !== undefined) {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "Reason is required.";
            return;
        }
        else {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "";
        }

        if (justificationTextData === 'No other suitable option available' && travelRequestFieldsDataJson.justificationDetailsHotel.length === undefined) {
            if (document.querySelector('#policyRemarksValidatorMsg') !== null && document.querySelector('#policyRemarksValidatorMsg').innerHTML !== '') {
                document.querySelector('#policyRemarksErrorMsg').innerHTML = "";
                return;
            }
            else {
                document.querySelector('#policyRemarksErrorMsg').innerHTML = "Remarks is required.";
                return;
            }
        }
        else {
            document.querySelector('#policyRemarksErrorMsg').innerHTML = "";
        }

        if (justificationTextData !== undefined && justificationTextData !== '' && justificationTextData !== 'No other suitable option available' && travelRequestFieldsDataJson.justificationDetailsHotel.length === undefined) {
            if (document.querySelector('#policyRemarksValidatorMsg') !== null && document.querySelector('#policyRemarksValidatorMsg').innerHTML !== '') {
                document.querySelector('#policyRemarksErrorMsg').innerHTML = "";
                return;
            }
        }
        else {
            document.querySelector('#policyRemarksErrorMsg').innerHTML = "";
        }

        setErrorOutPolicy(null);
        GetHotelDetails(props.roomDetails);

        sessionStorage.removeItem("HotelIntialTotalAmount");
        history.push('/TravelRequest?return=1&service=hotel');
    };

    const errorHandlerOutPolicyClose = () => {
        setErrorOutPolicy(null);
    };

    let bar = "";
    if ((hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113') || (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined) || (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName !== undefined && hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName)) {
        bar = " |"
    }

    function GetHotelDetails(selectHotel) {
        let onlineHotelDetailsTemp = [];
        let onlineHotelLowestDetailsTemp = [];
        let justificationCodeHotel = "";
        let justificationDetailsHotel = "";

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            justificationCodeHotel = travelRequestFieldsDataJson.justificationCodeHotel;
            justificationDetailsHotel = travelRequestFieldsDataJson.justificationDetailsHotel;
        }

        if (sessionStorage.getItem('initialHotelResults') !== null && sessionStorage.getItem('initialHotelResults') !== '') {
            let hotelResults1 = JSON.parse(sessionStorage.getItem('initialHotelResults'));
            let hotelResultsTempDesc = sortHotelResult("2", hotelResults1, 'desc');

            let hotelResults2 = JSON.parse(sessionStorage.getItem('initialHotelResults'));
            let hotelResultsTempInPolicy = hotelResults2.filter(a => a.inPolicy === true);
            let hotelResultsTempInPolicyDesc = sortHotelResult("2", hotelResultsTempInPolicy, 'desc');
            let isInPolicy = false;
            if (hotelDetailsResponseTaxes && hotelDetailsResponseTaxes.length) {
                //handles all cases of inPolicy being boolean, empty string, undefined/null
                isInPolicy = hotelDetailsResponseTaxes[0].inPolicy ? true : false;
            }

            let hotelResults3 = JSON.parse(sessionStorage.getItem('initialHotelResults'));
            let hotelResultsTempAsc = sortHotelResult("2", hotelResults3, 'asc');

            let hotelCount = hotelResultsTempAsc.length;
            let lowestFareInPolicy = '0';

            for (let index = 0; index < hotelCount; index++) {
                if (lowestFareInPolicy === '0' && hotelResultsTempAsc[index].inPolicy) {
                    lowestFareInPolicy = hotelResultsTempAsc[index].price[0].offeredPrice.priceOffered;
                    break;
                }
            }

            hotelDetailsResponse.data.hotelResults.forEach(elementHotelResults => {

                let countryCode = '';
                if (elementHotelResults.address && elementHotelResults.address.country) {
                    countryCode = elementHotelResults.address.country.code;
                }

                onlineHotelDetailsTemp.push(
                    {
                        sequenceNumber: 1,
                        hotelCode: elementHotelResults.hotelCode,
                        cityCode: elementHotelResults.cityCode,
                        countryCode: countryCode,
                        hotelName: elementHotelResults.hotelName,
                        hotelDescription: elementHotelResults.hotelDescription,
                        image1: elementHotelResults.images !== undefined && elementHotelResults.images[0] !== undefined ? elementHotelResults.images[0].imagePath : '',
                        image2: elementHotelResults.images !== undefined && elementHotelResults.images[1] !== undefined ? elementHotelResults.images[1].imagePath : '',
                        refundable: elementHotelResults.roomRateDetails !== undefined && elementHotelResults.roomRateDetails[0].cancellationPolicies !== undefined && elementHotelResults.roomRateDetails[0].cancellationPolicies[0].nonRefundable === "true" ? false : true,
                        roomType: elementHotelResults.roomRateDetails !== undefined ? elementHotelResults.roomRateDetails[0].roomTypeName : '',
                        hotelPolicy: elementHotelResults.hotelPolicy !== undefined ? elementHotelResults.hotelPolicy : '',
                        chainCode: elementHotelResults.chainCode,
                        chainName: elementHotelResults.chainName,
                        brandCode: elementHotelResults.brandCode,
                        brandName: elementHotelResults.brandName,
                        rating: elementHotelResults.reviewRating !== undefined ? elementHotelResults.reviewRating : '',
                        checkInTime: elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].value,
                        checkOutTime: elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].value,
                        checkInDate: elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].date,
                        checkOutDate: elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].date,
                        numberOfDay: getDateDiffDays(elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].date, elementHotelResults.hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].date).toString(),
                        address: elementHotelResults.address !== undefined ? elementHotelResults.address.addressLine : '',
                        numberOfPax: hotelDetailsResponse.data.hotelResults[0].NoOfAdults,
                        commission: elementHotelResults.price[0].agentCommissionType !== undefined ? elementHotelResults.price[0].agentCommissionType === 'Percentage' ? elementHotelResults.price[0].agentCommission !== '' && elementHotelResults.price[0].agentCommission !== undefined ? Math.abs(elementHotelResults.price[0].agentCommission) + "%" : '' : elementHotelResults.price[0].agentCommissionType === 'Variable' ? 'Variable' : elementHotelResults.price[0].agentCommissionAmount !== undefined ? elementHotelResults.price[0].agentCommissionAmount : elementHotelResults.price[0].agentCommission : '',
                        currencyCode: elementHotelResults.price[0].currencyCode,
                        baseFare: elementHotelResults.roomRateDetails !== undefined && elementHotelResults.roomRateDetails[0].price !== undefined ? elementHotelResults.roomRateDetails[0].price.publishedPrice.pricePublished : '0',
                        taxes: (elementHotelResults.roomRateDetails !== undefined && elementHotelResults.roomRateDetails[0].price !== undefined && elementHotelResults.roomRateDetails[0].price.taxes !== undefined ? elementHotelResults.roomRateDetails[0].price.taxes.totalAmount : 0) + (elementHotelResults.roomRateDetails !== undefined && elementHotelResults.roomRateDetails[0].price !== undefined && elementHotelResults.roomRateDetails[0].price.fees !== undefined ? elementHotelResults.roomRateDetails[0].price.fees[0].amount : 0),
                        totalRate: elementHotelResults.price[0].offeredPrice.priceOffered,
                        lowestFare: hotelResultsTempAsc[0].price[0].offeredPrice.priceOffered,
                        lowestFareInPolicy: lowestFareInPolicy,
                        highestFare: hotelResultsTempDesc[0].price[0].offeredPrice.priceOffered,
                        highestFareInPolicy: hotelResultsTempInPolicyDesc.length > 0 ? hotelResultsTempInPolicyDesc[0].price[0].offeredPrice.priceOffered : "0",
                        inPolicy: isInPolicy,
                        inPolicyReason: hotelDetailsResponse.data.hotelResults[0].inPolicyReason,
                        international: true,
                        bookingSourceCode: elementHotelResults.sourceId,
                        bookingSourceName: elementHotelResults.sourceName
                    }
                );
            });

            if (!isInPolicy) {
                for (let index = 0; index < 3; index++) {

                    //+ sign before string is unary operator to convert string to number
                    if (+hotelResultsTempAsc[index].price[0].offeredPrice.priceOffered > +onlineHotelDetailsTemp[0].totalRate) {
                        continue;
                    }

                    onlineHotelLowestDetailsTemp.push(
                        {
                            sequenceNumber: 1, //hotelResultsTempAsc[index].sequenceId,
                            lowestID: index + 1,
                            hotelCode: hotelResultsTempAsc[index].hotelCode,
                            cityCode: hotelResultsTempAsc[index].cityCode,
                            hotelName: hotelResultsTempAsc[index].hotelName,
                            hotelDescription: hotelResultsTempAsc[index].hotelDescription !== undefined ? hotelResultsTempAsc[index].hotelDescription : '',
                            image1: hotelResultsTempAsc[index].hotelPicture,
                            image2: hotelResultsTempAsc[index].hotelPicture,
                            refundable: hotelResultsTempAsc[index].roomRateDetails !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].cancellationPolicies !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].cancellationPolicies[0].nonRefundable === true ? false : true,
                            roomType: hotelResultsTempAsc[index].roomRateDetails !== undefined ? hotelResultsTempAsc[index].roomRateDetails[0].roomTypeName : '',
                            hotelPolicy: hotelResultsTempAsc[index].hotelPolicy !== undefined ? hotelResultsTempAsc[index].hotelPolicy : '',
                            chainCode: hotelResultsTempAsc[index].chainCode,
                            chainName: hotelResultsTempAsc[index].chainName,
                            brandCode: hotelResultsTempAsc[index].brandCode,
                            brandName: hotelResultsTempAsc[index].brandName,
                            checkInTime: hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].value,
                            checkOutTime: hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].value,
                            checkInDate: hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].date,
                            checkOutDate: hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].date,
                            numberOfDay: getDateDiffDays(hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckIn')[0].date, hotelResultsTempAsc[index].hotelCheckInPolicy.filter(a => a.type === 'CheckOut')[0].date).toString(),
                            address: hotelResultsTempAsc[index].address !== undefined ? hotelResultsTempAsc[index].address.addressLine : '',
                            numberOfPax: hotelDetailsResponse.data.hotelResults[0].NoOfAdults,
                            rating: hotelResultsTempAsc[index].reviewRating !== undefined ? hotelResultsTempAsc[index].reviewRating : '',
                            commission: hotelResultsTempAsc[index].price[0].agentCommissionType !== undefined ? hotelResultsTempAsc[index].price[0].agentCommissionType === 'Percentage' ? hotelResultsTempAsc[index].price[0].agentCommission !== '' && hotelResultsTempAsc[index].price[0].agentCommission !== undefined ? Math.abs(hotelResultsTempAsc[index].price[0].agentCommission) + '%' : '' : hotelResultsTempAsc[index].price[0].agentCommissionType === 'Variable' ? 'Variable' : hotelResultsTempAsc[index].price[0].agentCommissionAmount !== undefined ? hotelResultsTempAsc[index].price[0].agentCommissionAmount : hotelResultsTempAsc[index].price[0].agentCommission : '',
                            currencyCode: hotelResultsTempAsc[index].price[0].currencyCode,
                            baseFare: hotelResultsTempAsc[index].price !== undefined ? hotelResultsTempAsc[index].price[0].publishedPrice.pricePublished : '0',
                            taxes: (hotelResultsTempAsc[index].roomRateDetails !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].price !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].price.taxes !== undefined ? hotelResultsTempAsc[index].roomRateDetails[0].price.taxes.totalAmount : 0) + (hotelResultsTempAsc[index].roomRateDetails !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].price !== undefined && hotelResultsTempAsc[index].roomRateDetails[0].price.fees !== undefined ? hotelResultsTempAsc[index].roomRateDetails[0].price.fees[0].amount : 0),
                            totalRate: hotelResultsTempAsc[index].price[0].offeredPrice.priceOffered,
                            highestFare: hotelResultsTempDesc[0].price[0].offeredPrice.priceOffered,
                            highestFareInPolicy: hotelResultsTempInPolicyDesc.length > 0 ? hotelResultsTempInPolicyDesc[0].price[0].offeredPrice.priceOffered : '0',
                            inPolicy: hotelResultsTempAsc[index].inPolicy,
                            inPolicyReason: hotelDetailsResponse.data.hotelResults[0].inPolicyReason,
                            international: true,
                            reasonID: justificationCodeHotel.length !== undefined ? justificationCodeHotel : '',
                            remarks: justificationDetailsHotel.length !== undefined ? justificationDetailsHotel : '',
                            bookingSourceCode: hotelResultsTempAsc[index].sourceId,
                            bookingSourceName: hotelResultsTempAsc[index].sourceName
                        }
                    );
                }
            }
        }

        let bookingJSON = CreateBookingJSON(selectHotel)

        let bookingData = [];
        bookingData.push(
            {
                "serviceCode": "HT",
                "bookingJSON": JSON.stringify(bookingJSON.data)
            }
        );

        let travelRequestJSON = JSON.parse(localStorage.getItem("TravelRequestInput"));

        travelRequestJSON.data.onlineHotelDetails = onlineHotelDetailsTemp;
        travelRequestJSON.data.onlineHotelLowestDetails = onlineHotelLowestDetailsTemp.length ? onlineHotelLowestDetailsTemp : null;
        travelRequestJSON.data.bookingData = bookingData;

        localStorage.setItem("TravelRequestInput", JSON.stringify(travelRequestJSON));
    }

    const updateBookingJSONB2CProfile = (bookingJSON) => {
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.NamePrefix = profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.GivenName = profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.MiddleName = profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.Surname = profileJSON.data.travelerInfo.traveler[0].PersonName.Surname;

        bookingJSON.data.TravelerInfo.GuestInfo[0].Gender = profileJSON.data.travelerInfo.traveler[0].Gender;
        bookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId = profileJSON.data.travelerInfo.traveler[0].email[0].emailId;
        bookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber = profileJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber;

        return bookingJSON;
    }

    return (<Fragment>
        <ReactTooltip
            anchorId={`PolicyIn${props.roomDetails.roomID}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"In Policy"}
        />
        <ReactTooltip
            anchorId={`PolicyOut${props.roomDetails.roomID}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"Out of Policy"}
        />
        {confirm && <ErrorModal
            title={confirm.title}
            message={confirm.message}
            onConfirm={okHandler}
            details={confirm.details}

        ></ErrorModal>
        }

        {confirmModel &&
            <ConfirmationModal
                title={confirmModel.title}
                message={confirmModel.message}
                onOk={okConfirmHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        }

        {roomError &&
            <RoomDetailsPopup
                title={roomError.title}
                message={roomError.message}
                onConfirm={errorHandler}>
            </RoomDetailsPopup>
        }

        {roomLimitError && <ErrorModal
            title="Message"
            message='You have added the maximum number of rooms allowed in the proposal.'
            onConfirm={errorHandler} />
        }

        {errorOutPolicy &&
            <OutPolicyPopupModal
                title={errorOutPolicy.title}
                message={errorOutPolicy.message}
                onConfirm={errorHandlerOutPolicy}
                onClose={errorHandlerOutPolicyClose}
            >
            </OutPolicyPopupModal>
        }

        <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
            <div className="card">
                <span className="btn btn-sm btn_theme more-detail-btnslider text-14px" onClick={roomDetails}>
                    <i className="fa-solid fa-up-right-from-square px-1" ></i>
                </span>
                <span className="btn btn-sm btn_theme card-images-btnslider text-14px" onClick={roomDetails}>

                    <i className="fas fa-images"></i> Photos
                </span>
                {/* User Story 7538: Change logic for display Hotel Image Room Wise */}
                {/* Show first image of room */}
                {(hotelDetailsResponseTaxes !== undefined && hotelDetailsResponseTaxes.length !== 0 &&
                    hotelDetailsResponseTaxes[0].roomDetails !== undefined && hotelDetailsResponseTaxes[0].roomDetails.images !== null &&
                    hotelDetailsResponseTaxes[0].roomDetails.images !== undefined && hotelDetailsResponseTaxes[0].roomDetails.images.length !== 0 &&
                    hotelDetailsResponseTaxes[0].roomDetails.images[0].imagePath !== null) &&
                    <img key={1} src={hotelDetailsResponseTaxes[0].roomDetails.images[0].imagePath} className="card-img-top  gt-h-240" alt="..." />
                }
                {
                    (hotelDetailsResponseTaxes !== undefined && hotelDetailsResponseTaxes.length !== 0 &&
                        hotelDetailsResponseTaxes[0].roomDetails !== undefined &&
                        (hotelDetailsResponseTaxes[0].roomDetails.images === null || hotelDetailsResponseTaxes[0].roomDetails.images === undefined ||
                            hotelDetailsResponseTaxes[0].roomDetails.images.length === 0)) &&
                    <img key={2} src={hotelLogoLink} className="card-img-top  gt-h-240" alt="..." />
                }
                <div className="card-body">
                    {/* <hr></hr> */}
                    <h5 className="card-title text-primary h5">
                        {props.roomDetails.roomTypeName !== undefined ? toTitleCase(props.roomDetails.roomTypeName) :
                            <span>&nbsp;</span>
                        }
                    </h5>

                    <h2 className="card-title h6 card-desc">{toTitleCase(props.roomDetails.roomDescription)}</h2>
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {/* User Story 3800: Remove space in Room details section */}
                    {props.roomDetails !== undefined && getServices(props.roomDetails)}

                    {props.roomDetails.roomDetails !== undefined && props.roomDetails.roomDetails !== null &&
                        props.roomDetails.roomDetails.meals !== undefined && props.roomDetails.roomDetails.meals !== null &&
                        getMealType(props.roomDetails.roomDetails.meals)
                    }

                    {maintainCardHeight(noOfSpaces)}


                    <br></br>
                    {
                        <div className="text-14px text-muted pb-1">
                            {/* Source: <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'CGN' : 'Sabre'} </span> */}
                            {/* | PCC: <span className="text-primary fw-bold">{hotelDetailsResponsePrice[0].pcc}</span>  */}

                            {getConfigurationByBoolen("ALLOW_COMISSION") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && hotelDetailsResponsePrice[0].agentCommissionType !== undefined && (hotelDetailsResponsePrice[0].agentCommissionType === "Variable" || hotelDetailsResponsePrice[0].agentCommissionType === "Amount") ? 'Comm.:  ' : hotelDetailsResponsePrice[0].agentCommission !== undefined && hotelDetailsResponsePrice[0].agentCommission !== '' ? 'Comm.:  ' : ''}<span
                                className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType !== '113' && hotelDetailsResponsePrice[0].agentCommissionType !== undefined ? hotelDetailsResponsePrice[0].agentCommissionType === 'Percentage' ? hotelDetailsResponsePrice[0].agentCommission !== '' && hotelDetailsResponsePrice[0].agentCommission !== undefined ? Math.abs(hotelDetailsResponsePrice[0].agentCommission) + "% " + bar : '' : hotelDetailsResponsePrice[0].agentCommissionType === 'Variable' ? 'Variable ' + bar :
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: hotelDetailsResponsePrice[0].currencyCode
                                    }).format(hotelDetailsResponsePrice[0].agentCommissionAmount !== undefined ? hotelDetailsResponsePrice[0].agentCommissionAmount : hotelDetailsResponsePrice[0].agentCommission) + bar : ''}</span></span>}

                            {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <span>{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? ' Rate Plan:' : hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined ? '  Rate Plan:' : ''} <span className="text-primary fw-bold">{hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].rateType === '113' ? 'Negotiated Net' : (hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName !== undefined && hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].infoSource !== undefined) ? hotelDetailsResponse.data.hotelResults[0].roomRateDetails[0].ratePlanName : ''}</span></span>}
                            {/* | Content:  <span className="text-primary fw-bold">Virtuoso</span> */}
                            {getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" && getConfigurationByBoolen("ALLOW_COMISSION")}
                            {getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" && !getConfigurationByBoolen("ALLOW_COMISSION")}
                            {getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" && getConfigurationByBoolen("ALLOW_COMISSION")}
                            <div className='row'>
                                {props.roomDetails.cancellationPolicies.filter(a => a.nonRefundable !== undefined).map(x => x.nonRefundable)[0] === 'true' ? <div className="text-14px text-danger mt-2 col-md-7 col-sm-7 col-7"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="text-14px text-sky-blue mt-2 col-md-7 col-sm-7 col-7"><i className="fas fa-money-check-alt"></i>  Refundable</div>}

                                {props.travelReq !== undefined && props.travelReq === 'true' ? hotelDetailsResponseTaxes[0].inPolicy !== undefined ? hotelDetailsResponseTaxes[0].inPolicy === true ? <div className='policy-in-out-hotel h3 col-md-5 text-end float-end col-sm-5 col-5'><i id={`PolicyIn${props.roomDetails.roomID}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='policy-in-out-hotel h3 col-md-5 text-end float-end col-sm-5 col-5'><i id={`PolicyOut${props.roomDetails.roomID}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='policy-in-out-hotel h3 col-md-5 text-end float-end col-sm-5 col-5'><i id={`PolicyOut${props.roomDetails.roomID}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
                            </div>

                        </div>
                    }
                    {/* <p className="card-text text-black"><i className="fa-solid fa-star"></i> Collect and Redeem</p> */}
                    {/* <p className="card-text text-black"><span className="btn gt-seat-btn"><a><i className="fas fa-hotel"></i> <span className="mouseHover">Room Details</span></a></span></p> */}

                </div>
                <hr className='my-1'></hr>
                <div className='col-md-12 mt-2 px-2'>
                    <div className="tour_details_boxed_inner fare-summary-pop">
                        <div >
                            <div className="tour_details_boxed_inner fare-summary">
                                <div className="accordion" >
                                    <div className="accordion_flex_area">
                                        <div className="accordion-item fare-toggle-btn pb-0">
                                            <h2 className="accordion-header ms-2" id='headingThree' >

                                                <button className="accordion-button collapsed text-16px" type="button" data-bs-toggle="collapse" data-bs-target={"#collapselast" + props.roomDetails.roomID} aria-expanded="false" aria-controls={"collapselast" + props.roomDetails.roomID}>

                                                    <div className='ms-4 col-md-7 h6 my-auto'>
                                                        <div className='h4 text-primary col-md-12 ms-2 fw-bold pt-1 mb-0'>
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: hotelDetailsResponsePrice[0].currencyCode
                                                            }).format(hotelDetailsResponsePrice[0].offeredPrice.priceOffered)}
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id={"collapselast" + props.roomDetails.roomID} className="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExampleRoom">
                                                <div className="accordion-body px-2">
                                                    <div className="accordion_itinerary_list">
                                                        <label className="form-check-label h6 " htmlFor="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Base Rate</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponseTaxes[0].price !== undefined && hotelDetailsResponseTaxes[0].price !== null ? hotelDetailsResponseTaxes[0].price.currencyCode : 'USD'
                                                                    }).format(totalBaseRate)}</span>
                                                            </span>
                                                        </label>
                                                        <label className="form-check-label h6 " htmlFor="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Taxes & Fees</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponseTaxes[0].price !== undefined && hotelDetailsResponseTaxes[0].price !== null ? hotelDetailsResponseTaxes[0].price.currencyCode : 'USD'
                                                                    }).format(totalTax)}
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <label className="form-check-label h6 " htmlFor="flexCheckDefaultf1">
                                                            <span className="area_flex_one ">
                                                                <span>Total Rate</span>
                                                                <span className='h6'>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: hotelDetailsResponsePrice[0].currencyCode
                                                                    }).format(hotelDetailsResponsePrice[0].offeredPrice.priceOffered)}
                                                                </span>
                                                            </span>
                                                        </label>
                                                        {
                                                            hotelDetailsResponsePrice[0].currencyCode !== undefined && hotelDetailsResponsePrice[0].currencyCode !== '' && hotelDetailsResponsePrice[0].currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                                            <label className="form-check-label h6 " htmlFor="flexCheckDefaultf1">
                                                                <span className="area_flex_one ">
                                                                    <span>Eq. Total Rate</span>
                                                                    <span className='h6'>
                                                                        {new Intl.NumberFormat('en-US', {
                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                            style: 'currency',
                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                        }).format(parseFloat(equivalentAmountValue))}
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-md-12'>
                    {/* <div className='row'>
                        <div className='col-md-12'>
                            <div className='text-8px ms-3'>
                                <p>Includes taxes</p>
                            </div>
                        </div>
                        <div className='col-md-12 float-end  mt-2 mb-4 ms-3'>
                            <button className="btn btn_theme btn_sm" onClick={() =>
                                onBookNow()}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : hotelSearchRequestJson.Data.NoOfRooms > 1 ? `Reserve ${hotelSearchRequestJson.Data.NoOfRooms} rooms` : 'Reserve'} </button>
                        </div>
                    </div> */}
                    <div className='row mb-4 px-3'>
                        <div className='col-md-4 mt-2'>
                            {checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") && <button className="btn btn_theme btn_sm" onClick={() =>
                                onBookNow()}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin"></i>
                                    : hotelSearchRequestJson.Data.NoOfRooms > 1 ? `Reserve ${hotelSearchRequestJson.Data.NoOfRooms} rooms` :
                                        props.travelReq !== undefined && props.travelReq === 'true' ? 'Select' : 'Reserve'} </button>}
                        </div>
                        {getConfigurationByBoolen('ALLOW_HOTEL_PROPOSAL') && <div className='col-md-8 float-end mt-2'>
                            <div className="fw-bold text-primary add-to-proposal float-end my-auto" >Add to Proposal &nbsp;<input
                                className="form-check-input tandc-options hideproposal" type="checkbox" id={`addToProposal${props.roomDetails.roomID}`} onChange={(e) => { addHotel(e, props.hotelDetails, props.roomDetails) }} />&nbsp; </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>


    </Fragment>)
}

export default Room;
import { Fragment } from 'react';
import { numberWithCommas } from '../../../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

const RevenueItem = (props) => {

    return <Fragment>
        <tr>
            <td>{props.reportJSON.service === 'FL' ? 'Flight' : props.reportJSON.service === 'HT' ? 'Hotel' : props.reportJSON.service === 'Total' ? 'Total' : ''}</td>
            <td scope="row" align={'center'}>{props.reportJSON.currencyCode}</td>
            {props.AllowB2B && <td scope="row" align={'right'}>{props.reportJSON.service === 'FL' || props.reportJSON.service === 'Total' ? props.reportJSON.booked !== '' ? numberWithCommas(parseFloat(props.reportJSON.booked).toFixed(parseFloat(props.reportJSON.booked) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A' : 'N/A'}</td>}
            {props.AllowB2B && <td scope="row" align={'right'}>{props.reportJSON.sendRequest !== '' ? numberWithCommas(parseFloat(props.reportJSON.sendRequest).toFixed(parseFloat(props.reportJSON.sendRequest) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>}
            <td scope="row" align={'right'}>{props.reportJSON.service === 'HT' ? numberWithCommas(parseFloat(props.reportJSON.booked).toFixed(parseFloat(props.reportJSON.booked) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : numberWithCommas(parseFloat(props.reportJSON.ticketed).toFixed(parseFloat(props.reportJSON.ticketed) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')))}</td>
            <td scope="row" align={'right'}>{props.reportJSON.cancelled !== '' ? numberWithCommas(parseFloat(props.reportJSON.cancelled).toFixed(parseFloat(props.reportJSON.cancelled) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>
            {props.AllowImportPNR && <td scope="row" align={'right'}>{props.reportJSON.modified !== '' && props.reportJSON.service !== 'HT' ? numberWithCommas(parseFloat(props.reportJSON.modified).toFixed(parseFloat(props.reportJSON.modified) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>}
            <td scope="row" align={'right'}>{props.reportJSON.markup !== '' ? numberWithCommas(parseFloat(props.reportJSON.markup).toFixed(parseFloat(props.reportJSON.markup) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>
            <td scope="row" align={'right'}>{props.reportJSON.net !== '' ? numberWithCommas(parseFloat(props.reportJSON.net).toFixed(parseFloat(props.reportJSON.net) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>
            {/* <td scope="row" align={'right'}>{props.reportJSON.equivalentNet !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivalentNet).toFixed(2)) : 'N/A'}</td> */}
            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row" align={'right'}>{props.reportJSON.serviceFee !== '' ? numberWithCommas(parseFloat(props.reportJSON.serviceFee).toFixed(parseFloat(props.reportJSON.serviceFee) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>}
            <td scope="row" align={'right'}>{props.reportJSON.airlineTransactionAmount !== '' ? numberWithCommas(parseFloat(props.reportJSON.airlineTransactionAmount).toFixed(parseFloat(props.reportJSON.airlineTransactionAmount) <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'))) : 'N/A'}</td>
            {/* <td scope="row" align={'right'}>{props.reportJSON.equivalentMarkup !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivalentMarkup).toFixed(2)) : 'N/A'}</td> */}

        </tr>
    </Fragment>

};

export default RevenueItem;
import { Fragment } from 'react';

const Ameneties = (props) => {
    let service = props.hotelDetails.data.hotelResults[0].hotelAmenities;
    let firstArray = [];
    let secondArray = [];
    if (service.length > 0) {
        if (service.length % 2 === 0) {
            firstArray = service.slice(0, service.length / 2);
            secondArray = service.slice(service.length / 2);

        } else {
            firstArray = service.slice(0, Math.floor(service.length / 2) + 1)
            secondArray = service.slice(Math.floor(service.length / 2) + 1);
        }
    }

    return (<Fragment>
        <div className="col-lg-12 margin-top-15 mt-2 ">
            <div className="tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                <div className="pb-1" style={{ background: "transparent", boxShadow: "none", paddingTop: "4px" }}>
                    <div className="tour_details_right_box_heading" style={{}}>
                        <h3>Amenities</h3>
                    </div>
                    <div className="tour_package_details_bar_list first_child_padding_none" style={{ fontSize: "16px", paddingTop: "0px" }}>
                        <div className='row'>
                            <div className='col-md-6'><table><tr><td className=''>
                                {service !== '' && firstArray.map((item, index) => {
                                    return <div className='py-1' style={{ fontSize: "16px" }} id={"service" + index}><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{item.name}</div>

                                })}
                            </td></tr></table></div>
                            <div className='col-md-6'><table><tr><td className=''>
                                {service !== '' && secondArray.map((item, index) => {
                                    return <div className='py-1' id={"services" + index} style={{ fontSize: "16px" }}><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{item.name}</div>

                                })}
                            </td></tr></table></div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>)
}

export default Ameneties;
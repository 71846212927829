
import { Fragment,useContext } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import AuthContext from '../../redux/contexts/auth-context';
const PrivacyPolicy = () => {
    useDocumentTitle('Privacy Policy')
    const authCtx = useContext(AuthContext);
    return <Fragment>

        {/* <section  className="sub-banner">
            <div  className="bg-parallax bg-1"></div>
            <div  className="logo-banner text-center">
              
            </div>
        </section>  */}
        {!authCtx.isLoggedIn && <div className='position-relative bg-sky-blue'>
            <a href='/Dashboard' className="">
                <img src="assets/img/globetrotterdark.png" alt="" className='log-in-head-logo-bg position-absolute' />
            </a>
        </div>}
        <section id="common_banner-WithoutAuth">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section id="theme_search_form_tour" className='mb-3'>
            <div  className="container">
                <div  className="row">
                    <div  className="col-lg-12">
                        <div  className="theme_search_form_area">
                            <div  className="row">
                                <div  className="col-lg-12">
                                    <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Privacy Policy </label>
                                    <div className='mb-2'>
                                        At Fareladder Consultancy Services, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website or services. 
                                    </div>
                                    <div className='policy-text'>
                                        <ol>
                                            <li>
                                            <span className='text-primary fw-bold'>Information we Collect:</span> We collect personal information when you book a service through our website. This information may include your name, address, email address, phone number, and credit card information. We may also collect information about your travel preferences and other personal information you provide to us. 
                                            </li>
                                            <li>
                                               <span className='text-primary fw-bold'> Use of Information:</span> We use your personal information to provide you with the travel package or service you have booked, to communicate with you about your booking, and to improve our website and services. We may also use your personal information for marketing purposes, such as sending you promotional emails about our travel packages and services.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Disclosure of Information: </span>We may disclose your personal information to third-party service providers, such as airlines, hotels, or tour operators, in order to provide you with the travel package or service you have booked. We may also disclose your personal information to law enforcement or other government authorities if required by law. 
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Security of Information: </span>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, we cannot guarantee the security of your personal information, as no method of transmission over the internet or electronic storage is completely secure.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Cookies: </span>We use cookies to improve your experience on our website. Cookies are small data files that are placed on your device when you visit our website. We use cookies to remember your preferences and to personalize your experience on our website. 
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Third-Party Links: </span>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these third-party websites. 
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Children's Privacy: </span>Our website and services are not intended for use by children under the age of 18. We do not knowingly collect personal information from children under the age of 18.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Changes to Privacy Policy: </span> We reserve the right to modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting on our website.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'>Contact Us: </span> If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:dpo@fareladder.com">dpo@fareladder.com</a>.
                                            </li>
                                           
                                            
                                        </ol>    
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
};

export default PrivacyPolicy;
import React from "react";
import ReactToPrint from "react-to-print";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PrintConfrimation from '../../../pages/BookingConfirmation/PrintConfrimation';

class ComponentToPrint extends React.Component {
    render() {
        return (

            <div style={{ getPageMargins }}>
                <PrintConfrimation page={this.props.page} myTripIssueTicket={this.props.myTripIssueTicket !== undefined && this.props.myTripIssueTicket !== null ? this.props.myTripIssueTicket : ''}></PrintConfrimation>
            </div>
        );
    }
}
const getPageMargins = () => {
    return `@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }`

};

class PrintConfirmation extends React.Component {
    render() {

        return (
            <div >
                <style >{getPageMargins()}

                </style>
                <ReactToPrint
                    trigger={() => <span id="Print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i></span>}
                    content={() => this.componentRef}
                />
                <ReactTooltip
                    anchorId="Print"
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"Print"}
                />
                <div style={{ display: "none" }}>
                    <ComponentToPrint ref={(el) => (this.componentRef = el)} page={this.props.page} myTripIssueTicket={this.props.myTripIssueTicket !== undefined && this.props.myTripIssueTicket !== null ? this.props.myTripIssueTicket : ''} />

                </div>

            </div>
        );
    }
}

export default PrintConfirmation;

import { Fragment, useState, useEffect } from "react";
import ContentForm from './Components/ContentForm';
import useHttpGet from '../../../services/use-http';
import useHttp from '../../../services/use-http'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { addContent, getSingleContent, updateContent } from "../../../services/Content-API";
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateContent = (props) => {
    useDocumentTitle(" Content");
    const [error, setError] = useState();
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const history = useHistory();
    const { Id, Action } = parms;
    const { t } = useTranslation(['translation.Content']);

    let varHttp;

    if (Id === undefined) {
        varHttp = addContent;
    } else {
        varHttp = updateContent;
    }

    const { sendRequest: sendRequestContent, data: loadedContentDetails, status: getContentStatus, error: getContentError } = useHttpGet(getSingleContent);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (!checkSecurityDetails("Settings", "Content", "Settings_Content_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Settings", "Content", "Settings_Content_View") && Action === "0")
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {

                if (loadedQuote['errors'].status === 'TRUE') {

                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecord')}`,
                        });
                    }
                    else if (loadedQuote['errors'].error['code'] === '1025') {

                        setError({
                            title: "Message",
                            message: `${t('errorHotelDuplicateRecord')}`,
                        });
                    }
                    else {

                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }

                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });

                }

                return;

            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequestContent(Id)
        }
    }, [sendRequestContent, Id])

    if (getContentStatus === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    if (getContentError) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }



    let loadedData = [];
    for (let i in loadedContentDetails) {
        if (loadedContentDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedContentDetails[i];
            }
        }
    }
    const addContentHandler = (requestData) => {
        sendRequest(requestData);

    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/searchcontent');
        }
    };
    const ContentFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p className="text-primary text-center h5">{t('contentDetailsError')}</p> :
        <ContentForm loadedContentDetails={loadedData} isLoading={status === 'pending'} onAddContent={addContentHandler}
            IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />
    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {ContentFormContent}
    </Fragment>
}
export default UpdateContent;
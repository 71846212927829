import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MdModal from '../../Flights/MdModal';
import ApproverDetails from "./SubComponents/ApproverDetails";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
//import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { getCustomizeDetails } from '../../../utils/CustomizeUtils'
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";

const ApprovalThroughMail = () => {
    const { t } = useTranslation(['translation.Approver']);
    useDocumentTitle(t('approvalThroughMailPageTitle'));
    const [queryJSON, setQueryJSON] = useState(JSON.parse(localStorage.getItem('emailQueryJSON')));
        
    const [alertModal, setAlertModal] = useState(null);

    const [showLoader, setShowLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState(queryJSON ? '' : t('errorGeneric'));
    const [successMsg, setSuccessMsg] = useState('');

    function handleApprovalStatusUpdate(isSuccessful) {
        setShowLoader(false);
        setQueryJSON(null);
        if (isSuccessful) {
            setSuccessMsg(t('msgStatusUpdateSuccess'));
        }
        else {
            setErrorMsg(t('msgStatusUpdateFailure'));
        }

        //Remove item once status has been updated.
        //This is to prevent a scenario where a user could repeatedly update status once/if page is manually refreshed after status update.
        localStorage.removeItem('emailQueryJSON');
    };

    function handleAlertModalConfirm() {
        setAlertModal(null);
    }

    function onFormSubmitStart() {
        setShowLoader(true);
    }
        
    return (
        <Fragment>

            {alertModal && (
                <MdModal
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={handleAlertModalConfirm}
                ></MdModal>
            )}

            <div className='position-relative main-responsive-menu'>
                <img src={getCustomizeDetails("companyLogo")}
                    alt=""
                    className='log-in-head-logo-bg position-absolute' />
            </div>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Both LoadingSpinnerWithCard and LoadingSpinner are used because initially there's no form to display which */}
            {/*will give background structure for the loading spinner.*/}

            {/*{decryptionRequestStatus === 'pending' ?*/}
            {/*    <LoadingSpinnerWithCard showBanner={false} />*/}
            {/*    :*/}                
            {/*}*/}

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {showLoader &&
                                    <LoadingSpinner />
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12">
                                                <i className="fas fa-globe-asia"></i>{' '}
                                                {t('formTitleStatusUpdate')}
                                            </label>
                                            {queryJSON &&
                                                <ApproverDetails
                                                    userID={queryJSON.UserID}
                                                    officeID={queryJSON.OfficeID}
                                                    shoppingID={queryJSON.ShoppingID}
                                                    approvalTypeID={queryJSON.ApprovalTypeID}
                                                    approvalMasterID={queryJSON.ApprovalMasterID}
                                                    confirmationNumber={queryJSON.ConfirmationNumber}
                                                    isSourceEmail={true}
                                                    actionApprovalStatusCode={queryJSON.ActionApprovalStatus}
                                                    onApprovalStatusUpdate={handleApprovalStatusUpdate}
                                                    onFormSubmitStart={onFormSubmitStart}
                                                    serviceTypeID={queryJSON.ServiceTypeID || ''}
                                                >
                                                </ApproverDetails>
                                            }

                                            {/*Message for error*/}
                                            {errorMsg.length > 0 &&
                                                <div className="h6 fw-bold">
                                                    {errorMsg}
                                                </div>
                                            }

                                            {/*Message for success*/}
                                            {successMsg.length > 0 &&
                                                <div className="h6 fw-bold">
                                                    {successMsg}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

        </Fragment>
    );
};

export default ApprovalThroughMail;
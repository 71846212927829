
import {
    ADD_TERMS_AND_CONDITION, GET_TERMS_AND_CONDITION, UPDATE_TERM_AND_CONDITION, SEARCH_TERM_AND_CONDITION, DELETE_STATIC_PAGE
} from "./CONSTANTS"
export async function updateTermsAndCondition(updateData) {
    const response = await fetch(UPDATE_TERM_AND_CONDITION(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update Page Builder.');
    }
    return data;

}
export async function addTermsAndConditions(addData) {
    const response = await fetch(ADD_TERMS_AND_CONDITION(), {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not Page Builder.');
    }
    return data;

}
export async function getTermsAndCondition(OfficeID, PageID) {
    const response = await fetch(GET_TERMS_AND_CONDITION(OfficeID, PageID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Page Builder');
    }
    return data;
}
export async function searchStaticPage(searchData) {
    const response = await fetch(SEARCH_TERM_AND_CONDITION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()

    if (!response.ok) {
        throw new Error(data.message || 'could not fetch Page Builder.');
    }

    return data;
}
export async function deleteSingleStaticPage(deleteData) {
    const response = await fetch(DELETE_STATIC_PAGE(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete Page Builder.');
    }

    return data;
}
import { getToken } from '../utils';
import {    
    SEARCH_CURRENCY
} from './CONSTANTS'

export async function searchCurrency(searchData) {
    const response = await fetch(SEARCH_CURRENCY(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch currency.');
    }
    
    return data;
}
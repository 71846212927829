import React from "react";



const Button = (props) => {

    let isForNonAuth = false;
    if (props.isForNonAuth !== undefined) {
        isForNonAuth = props.isForNonAuth;
    }

    let btnClassName = 'btn gt-btn_md text-white gt-main-btn';
    if (isForNonAuth) {
        btnClassName += ' gt-main-btn-WithoutAuth-MFA';
    }

    return (
        <button
            className={btnClassName}
            type={props.type || "button"}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default Button;

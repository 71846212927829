import React, { Fragment, useState, useEffect } from "react";
import { calculateAge, toPascalCase } from "../../../utils/CommonFunction";
import { getCustomizeDetails } from "../../../utils/CustomizeUtils";
import { getCrypticCommandWithCloseConnection } from "../../../services/CrypticCommand-API";
import useHttp from '../../../services/use-http';
import { Tooltip as ReactTooltip } from "react-tooltip";
import PopupMedium from "../../../components/Common/Flight/PopupMedium";
import RetrieveSabreGDSPNR from "../../MyTrips/Components/RetrieveSabreGDSPNR";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import { getConfigurationByBoolen, getConfigurationByValue } from "../../../utils/ConfigurationsFunction";

const TravellerInfo = (props) => {
    let bookingJSON = props.bookingJSON;
    let remark = bookingJSON.data.miscellaneousRemarkInfo !== undefined ? bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo : ""
    let remarkText = "";
    const { sendRequest: sendSabreTicketNumber, data: loadedOutput, status: sabreTicketStatus, } = useHttp(getCrypticCommandWithCloseConnection);
    const [sabrePnrPopup, setSabrePnrPopup] = useState();
    const [error, setError] = useState();

    if (remark.length !== 0 && remark !== '') {
        remark.forEach(elementRemark => {
            if (elementRemark.remarkType.toLowerCase() === 'general' && elementRemark.remark !== '' && elementRemark.text !== undefined && elementRemark.text !== null) {
                remarkText = elementRemark.text;
            }
        });
    }
    useEffect(() => {
        if (sabreTicketStatus === 'completed') {
            if (loadedOutput !== null) {
                if (loadedOutput.data.errors.status === 'FALSE') {
                    setSabrePnrPopup({
                        title: "Sabre Ticket Details",
                        message: <RetrieveSabreGDSPNR output={loadedOutput.data.commandResponse[0].text}></RetrieveSabreGDSPNR>

                    });

                }
                else {
                    setError({
                        title: "Message",
                        message: loadedOutput.data.errors.error.description,
                    });
                    return;
                }
            } else {
                setError({
                    title: "Message",
                    message: "Something went wrong.",
                });
            }


        }

    }, [sabreTicketStatus])
    function openSabreTicketNumnber(TicketNumber) {
        const inputJson = ({

            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "data": {
                "UserID": JSON.parse(localStorage.getItem('userID')),
                "TraceId": JSON.parse(localStorage.getItem('traceID')),
                "requestCommand": [
                    {
                        "text": 'WETR*T' + TicketNumber,
                    }
                ],
                "echoToken": (sessionStorage.getItem("SabreSessionToken") !== undefined && sessionStorage.getItem("SabreSessionToken") !== "") ? sessionStorage.getItem("SabreSessionToken") : null
            }
        });
        sendSabreTicketNumber(inputJson);

    }
    function getTicketDetails(ticketNo, id) {
        let isCanceled = sessionStorage.getItem("myTripIsCanceled") === 'true' ? true : false;
        if (bookingJSON.data.requestType === 'ON' && bookingJSON.data.importPNR !== 'M' && !isCanceled && checkSecurityDetails("My Trips", "ViewSabrePNR", "My Trips_ViewSabrePNR_Allow View Sabre PNR") && getConfigurationByBoolen("ALLOW_VIEW_SABRE_TICKET")) {
            return <span className=''>
                {ticketNo + " "}
                <span id={"ViewSabreTicket" + id} className=" ms-1  text-sky-blue ms-2  gt-yellow-btn-spinner cursor-pointer"
                    data bs-toggle="tooltip" data-bs-placement="top" onClick={() => openSabreTicketNumnber(ticketNo)} >
                    {(sabreTicketStatus === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : <i class="fas fa-external-link-alt line-height-22px"></i>}

                </span>
                <ReactTooltip
                    anchorId={"ViewSabreTicket" + id}
                    place="bottom"
                    variant="info"
                    multiline={true}
                    className="tooltip"
                    html={"View Sabre Ticket Number"}
                />
            </span>
        } else {
            return ticketNo;
        }

    }

    function getMealPreference(personNo) {
        let meals = '';
        if (bookingJSON.data.travelerInfo.specialReqDetails === undefined || bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests === undefined) {
            return "N/A";
        }

        if (bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest !== '') {
            bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.forEach(meal => {
                if (personNo === meal.travelerRefNumber) {
                    if (meal.ssrType === 'MEAL') {
                        if (meal.wayType === '0') {
                            meals += meal.ssrDescription + " (" + "All Flights" + ")" + ", "
                        } else {
                            meals += meal.ssrDescription + ", "
                        }

                    }
                }
            });

            meals = meals.trim().slice(0, -1);
        }

        if (meals === '') {
            return "N/A";
        }
        return meals;
    }
    function getSeatNo(personNo) {

        let seatNo = "";
        if (bookingJSON.data.travelerInfo.specialReqDetails === undefined || bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests === undefined || bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests === null) {
            if (bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests.seatRequest.length === 0) {
                return "N/A";
            }
            else {
                return "N/A";
            }
        }

        if (bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests.seatRequest !== undefined) {
            bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests.seatRequest.forEach(seat => {
                if (seat.travelerRefNumber === personNo) {
                    seatNo = seatNo + seat.seatNumber + ", ";
                }
            })
        }

        if (seatNo === '') {
            return "N/A";
        }

        return seatNo.trim().slice(0, -1);
    }

    function displayName(namePrefix, givenName, middleName, surname) {
        let dispName = '';
        if (namePrefix !== undefined && namePrefix !== 'Undefined') {
            dispName = namePrefix;
        }
        if (givenName !== undefined && givenName !== 'Undefined') {
            dispName = dispName + " " + givenName;
        }
        if (middleName !== undefined && middleName !== 'Undefined') {
            dispName = dispName + " " + middleName;
        }
        if (surname !== undefined && surname !== 'Undefined') {
            dispName = dispName + " " + surname;
        }

        return dispName;
    }

    function showTravellerInfo() {
        let personInfo = [];
        bookingJSON.data.travelerInfo.traveler.forEach(person => {

            personInfo.push(
                <tbody>
                    <tr>
                        <td>{person.travelerRefNumber}</td>
                        <td scope="row">{displayName(person.personName.namePrefix, person.personName.givenName, person.personName.middleName, person.personName.surname)}</td>
                        <td>{person.passengerTypeCode === "ADT" ? "Adult" : person.passengerTypeCode === "CNN" ? "Child" : "Infant"}</td>
                        <td>{person.dateOfBirth.date !== '' ? calculateAge(person.dateOfBirth.date) : ''}</td>
                        <td>{person.ticketInfo !== undefined && person.ticketInfo[0] !== undefined && person.ticketInfo[0].ticketNumber !== undefined && person.ticketInfo[0].ticketNumber !== '' ? getTicketDetails(person.ticketInfo[0].ticketNumber, person.travelerRefNumber) : 'N/A'}</td>
                        {/* <td>{getTicketDetails(person.ticketInfo[0].ticketNumber)}</td> */}
                        {/* changes to hide meal and seat if source is NDC */}
                        {
                            ((bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].sourceName !== undefined && bookingJSON.data.airItinerary[0].sourceName !== 'NDC') && (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns === undefined || bookingJSON.data.travelerInfo.specialReqDetails[0].addOns === null || (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.length === 0))) &&
                            <>
                                <td>{getMealPreference(person.travelerRefNumber)}</td>
                                <td>{getSeatNo(person.travelerRefNumber)}</td>
                            </>
                        }
                    </tr>
                </tbody>
            );

        });
        return personInfo;
    }

    function showAddOns() {
        let addOnInfo = [];
        if (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null) {
            bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.forEach((addOnService, index) => {
                addOnInfo.push(
                    <tbody>
                        <tr>
                            <td>{++index}</td>
                            <td scope="row">{displayName(bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === addOnService.travelerRefNumber)[0].personName.namePrefix, bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === addOnService.travelerRefNumber)[0].personName.givenName, bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === addOnService.travelerRefNumber)[0].personName.middleName, bookingJSON.data.travelerInfo.traveler.filter(a => a.travelerRefNumber === addOnService.travelerRefNumber)[0].personName.surname)}</td>
                            <td>{bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption !== undefined && bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === addOnService.flightRefNumber)[0] !== undefined ? bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === addOnService.flightRefNumber)[0].flightSegment[0].departureAirport.locationName + "(" + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === addOnService.flightRefNumber)[0].flightSegment[0].departureAirport.locationCode + ")" + " - " + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === addOnService.flightRefNumber)[0].flightSegment[0].arrivalAirport.locationName + "(" + bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.rph === addOnService.flightRefNumber)[0].flightSegment[0].arrivalAirport.locationCode + ")" : ''}</td>
                            <td>{addOnService.ssrType === 'SPECIAL' ? 'Special Service' : addOnService.ssrType === 'FFN' ? 'Frequent Flyer' : toPascalCase(addOnService.ssrType)}</td>
                            <td>{addOnService.ssrDescription}</td>
                            <td>{addOnService.status !== undefined && addOnService.status !== null ? addOnService.status : ''}</td>
                            <td align={'right'}>{addOnService.ssrCharge !== '0' && addOnService.ssrCharge !== '' ?
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: addOnService.ssrCurrency !== undefined && addOnService.ssrCurrency !== '' ? addOnService.ssrCurrency : bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].baseFare.currencyCode : 'USD'
                                }).format(addOnService.ssrCharge) : ''}</td>
                            <td>{addOnService.remarks}</td>
                        </tr>
                    </tbody>
                );
            });
        }
        return addOnInfo;
    }

    const errorHandler = () => {
        setError(null);
        setSabrePnrPopup(null);
    };

    return <Fragment>
        {sabrePnrPopup && (
            <PopupMedium
                title={sabrePnrPopup.title}
                message={sabrePnrPopup.message}
                onConfirm={errorHandler}
            ></PopupMedium>
        )}
        {props.hideData !== '1' && <div className="col-md-12" style={{ width: "100%" }}>
            <div className="accordion_flex_area">

                <div className="accordion-item pb-0">
                    <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                        Booking Contact
                    </h5>
                    <div id="collapsesix" className="accordion-collapse collapse show">
                        <div className='row my-2'>
                            <div className='col-md-12'>
                                {props.print === undefined ? <div className='row'>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12"><div className='h6 mb-2 text-primary'> Phone </div> </div>
                                    {props.hideData !== '1' && <div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className='h6 mb-2'>{getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? bookingJSON.data.travelerInfo.traveler[0].telephone[0].mobile : bookingJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber} </div> </div>}

                                </div> :
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12" >
                                        <div style={{ display: 'flex' }}><span className='h6 mb-2 text-primary'> Phone </span>
                                            {props.hideData !== '1' && <span className='h6 mb-2 ms-3'>{getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? bookingJSON.data.travelerInfo.traveler[0].telephone[0].mobile : bookingJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber} </span>}
                                        </div> </div>}


                            </div>
                            <div className='col-md-12'>
                                {props.print === undefined ? <div className='row'>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div className='h6 text-primary mb-1'> Email</div>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                        <div className='h6 mb-1'>{bookingJSON.data.travelerInfo.traveler[0].email[0].emailId}</div>
                                    </div>
                                </div> :
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div style={{ display: 'flex' }}>  <span className='h6 mb-1 text-primary'> Email</span>
                                            <span className='h6 mb-1 ms-4'>{bookingJSON.data.travelerInfo.traveler[0].email[0].emailId}</span></div>
                                    </div>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-2 mb-3"></hr>
            <div className="accordion_flex_area">
                <div className="accordion-item">
                    <h5 className="accordion-header text-sky-blue fw-bold mb-3" id="headingTwo">
                        {bookingJSON.data.travelerInfo.traveler.length === 1 ? "Traveler" : "Travelers"}
                    </h5>
                    <div id="collapseTwo" className="accordion-collapse collapse show"
                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='row'  >
                                <div className='col-md-12 table-responsive mb-0 ' >
                                    <table className="table table-bordered mb-0 mz-mb-50" >
                                        <thead style={{ background: getCustomizeDetails('buttonColor') }}>
                                            <tr >
                                                <th scope='col' className="gt-w-3">#</th>
                                                <th scope="col" className="col-md-2">Name</th>
                                                <th scope="col" className="col-md-1">Type</th>
                                                <th scope="col" className="col-md-1">Age</th>
                                                <th scope="col" className="gt-w-10">Ticket No.</th>
                                                {
                                                    ((bookingJSON.data !== undefined && bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary[0] !== undefined && bookingJSON.data.airItinerary[0].sourceName !== undefined && bookingJSON.data.airItinerary[0].sourceName !== 'NDC') && (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns === undefined || bookingJSON.data.travelerInfo.specialReqDetails[0].addOns === null || (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.length === 0))) &&
                                                    <>
                                                        <th scope="col" className="col-md-3">Meal Preference</th>
                                                        <th scope="col" className="gt-w-10">Seat No.</th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        {
                                            showTravellerInfo()
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.length > 0 &&
                <Fragment>
                    <hr></hr>
                    <div className="accordion_flex_area">
                        <div className="accordion-item">
                            <h5 className="accordion-header text-sky-blue fw-bold mb-3" id="headingTwo">
                                Add-Ons
                            </h5>
                            <div id="collapseTwo" className="accordion-collapse collapse show"
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className='row'>
                                        <div className='col-md-6'>

                                        </div>
                                        <div className='col-md-12 table-responsive mz-mb-50 position-relative'>
                                            <table className="table table-bordered" >
                                                <thead style={{ background: getCustomizeDetails('buttonColor') }}>
                                                    <tr >
                                                        <th scope='col'>#</th>
                                                        <th className="Test-print" scope='col'>Passenger</th>
                                                        <th scope='col' className="max-width-185">Sector</th>
                                                        <th scope='col'>Type</th>
                                                        <th scope="col">Sub Type</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Description</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    showAddOns()
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
            {
                (bookingJSON.data.travelerInfo.specialReqDetails !== undefined && ((bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests !== null || bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== null) && (bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0] !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].specialServiceRequests.specialServiceRequest.length > 0 || bookingJSON.data.travelerInfo.specialReqDetails !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0] !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests.seatRequest !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].seatRequests.seatRequest.length > 0)) || (bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== undefined && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns !== null && bookingJSON.data.travelerInfo.specialReqDetails[0].addOns.addOn.length > 0)) &&
                <div className="col-md-12 accordion-header text-black mt-1 fw-bold mb-2 mz-mt-50 ">Note : <span className="text-primary fw-500" >Special requests are at the discretion of the airline and are not guaranteed. Please check with the airline for confirmation.</span></div>
            }
            {bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name !== undefined && bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name !== '' && <hr></hr>}
            {
                bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name !== undefined && bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name !== '' &&
                <div className="accordion_flex_area">
                    <div className="accordion-item">
                        <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                            Payment
                        </h5>

                        <div id="collapseseven" className="accordion-collapse collapse show"
                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                            <div className='row'>
                                <div className='col-md-12'>

                                    {props.print === undefined ? <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div className="h6 mb-2 text-primary"> Payment Mode </div> </div>
                                        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                            <div className="h6 mb-2">{bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'CREDITCARD' ? "Credit Card" : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'PROFILE' ? "Profile Credit Card" : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'WALLET' ? 'Wallet' : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name}</div>
                                        </div>
                                    </div> : <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                            <div style={{ display: "flex" }}>
                                                <span className="h6 mb-2 text-primary"> Payment Mode </span>
                                                <span className="h6 mb-2 ms-3">{bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'CREDITCARD' ? "Credit Card" : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'PROFILE' ? "Profile Credit Card" : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === 'WALLET' ? 'Wallet' : bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name}</span>
                                            </div>
                                        </div>

                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* To fetch the FOP from GDSRetrieve remarkType */}
            {
                (bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === undefined || bookingJSON.data.airItinerary[0].paymentInfo.paymentMode[0].name === '') && bookingJSON.data.miscellaneousRemarkInfo !== undefined && bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo !== undefined && bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.map((node) => (
                    node.text !== '' && node.text.split('-').length > 1 && node.text.split('-')[0] === 'FOP' &&
                    <>
                        <hr></hr>
                        <div className="accordion_flex_area">
                            <div className="accordion-item">
                                <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                                    Payment
                                </h5>

                                <div id="collapseseven" className="accordion-collapse collapse show"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                                    <div className="h6 mb-2 text-primary"> Payment Mode </div> </div>
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                                    <div className="h6 mb-2">{node.text.split('-')[1].trim() === 'CREDITCARD' ? "Credit Card" : node.text.split('-')[1].trim() === 'PROFILE' ? "Profile Credit Card" : node.text.split('-')[1].trim() === 'WALLET' ? 'Wallet' : node.text.split('-')[1].trim()}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            }

            {remarkText.trim() !== '' && <hr></hr>}
            {remarkText.trim() !== '' && <div className="accordion_flex_area">
                <div className="accordion-item">
                    <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree">
                        Booking Remarks
                    </h5>

                    <div id="collapseseven" className="accordion-collapse collapse show"
                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="h6 mb-2 ">{remarkText} </div> </div>
                        </div>
                    </div>
                </div>
            </div>}

        </div>}

    </Fragment>

}
export default TravellerInfo;
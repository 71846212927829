import { useState } from 'react';
import Dropdown from 'react-select';
import { CustomToFixed, calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';

const AddMarkup = (props) => {
    const [markupLabel, setMarkupLabel] = useState('');
    const [intialBookingJSON, setIntialBookingJSON] = useState([]);

    const prefernce_list = [
        { value: '1', label: 'Amount' },
        { value: '2', label: 'Percentage' }
    ];

    function assignMarkup() {
        if (document.querySelector('#markup').value !== "" && markupLabel === '') {
            document.querySelector('#markupPrefernce').innerHTML = "Service Fee preference is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (document.querySelector('#markup').value === "" && markupLabel !== '') {
            document.querySelector('#markupValue').innerHTML = "Service Fee is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (markupLabel === 'Percentage') {
            if (document.querySelector('#markup').value > 100) {
                document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed 100%.";
                document.querySelector('#markup').focus();
                return;
            }
            else {
                document.querySelector('#markupValue').innerHTML = "";
                document.querySelector('#markupPrefernce').innerHTML = "";
                props.onContinueButton("5");
            }
        }
        else if (markupLabel === 'Amount') {
            let spnTotalAmount = 0;
            if (sessionStorage.getItem("HotelIntialTotalAmount") === null) {
                //User Story 1073: Booking Summary for Single PNR
                if (document.querySelector('#spnTotalAmountHotel').innerHTML.length > 1) {
                    if (document.querySelector('#spnTotalAmountHotel').innerHTML.indexOf('&nbsp;') !== -1) {
                        spnTotalAmount = document.querySelector('#spnTotalAmountHotel').innerHTML.split('&nbsp;')[1];
                    }
                    else {
                        spnTotalAmount = document.querySelector('#spnTotalAmountHotel').innerHTML.trim().substring(1, document.querySelector('#spnTotalAmountHotel').innerHTML.length);
                    }
                    spnTotalAmount = spnTotalAmount.replace(',', '');
                    sessionStorage.setItem("HotelIntialTotalAmount", spnTotalAmount);
                }
            }
            else {
                spnTotalAmount = sessionStorage.getItem("HotelIntialTotalAmount");
            }

            if (document.querySelector('#markup').value !== '') {
                if (parseFloat(document.querySelector('#markup').value) > parseFloat(spnTotalAmount)) {
                    document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed Total Rate.";
                    document.querySelector('#markup').focus();
                    return;
                }
                else {
                    document.querySelector('#markupValue').innerHTML = "";
                    document.querySelector('#markupPrefernce').innerHTML = "";
                    //props.onContinueButton("5");
                }
            }
        }
        else {
            document.querySelector('#markupValue').innerHTML = "";
            document.querySelector('#markupPrefernce').innerHTML = "";
            props.onContinueButton("5");
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            if (!document.querySelector('#chkTandcZeroDispute:checked')) {
                document.querySelector('#chkTandcZeroDispute').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else if (!document.querySelector('#chkTandcChargeName:checked')) {
                document.querySelector('#chkTandcChargeName').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else {
                document.querySelector('#spnTandCMessage').innerHTML = "";
            }
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
            var totalFareAmount = 0;
            let calculatedAmount = 0;

            if (intialBookingJSON.length === 0) {
                setIntialBookingJSON(JSON.parse(sessionStorage.getItem("HotelBookingData")));
            }

            if (intialBookingJSON.length === 0) {
                hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                    elementHotelResults.price.forEach(elementPrice => {
                        if (markupLabel === 'Amount') {
                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.roomPrice = elementPrice.roomPrice.toFixed(2);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.offeredPrice.priceOffered = elementPrice.offeredPrice.priceOffered.toFixed(2);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(2);

                            elementPrice.agentMarkUp = parseFloat(document.querySelector('#markup').value);
                            elementPrice.agentMarkUp = elementPrice.agentMarkUp.toFixed(2);
                            elementPrice.agentMarkUpType = "ServiceFee-Amount";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(document.querySelector('#markup').value);
                                element.price.agentMarkUp = element.price.agentMarkUp.toFixed(2);
                                element.price.agentMarkUpType = "ServiceFee-Amount";
                            });

                            totalFareAmount = elementPrice.offeredPrice.priceOffered;
                        }
                        else if (markupLabel === 'Percentage') {
                            calculatedAmount = (parseFloat(elementPrice.offeredPrice.priceOffered) * parseFloat(document.querySelector('#markup').value)) / 100;

                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(calculatedAmount);
                            elementPrice.roomPrice = CustomToFixed(elementPrice.roomPrice, 2);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(calculatedAmount);
                            elementPrice.offeredPrice.priceOffered = CustomToFixed(elementPrice.offeredPrice.priceOffered, 2);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(calculatedAmount);
                            elementPrice.offeredPrice.perNight = CustomToFixed(elementPrice.offeredPrice.perNight, 2);

                            elementPrice.agentMarkUp = parseFloat(calculatedAmount);
                            elementPrice.agentMarkUp = CustomToFixed(elementPrice.agentMarkUp, 2);
                            elementPrice.agentMarkUpType = "ServiceFee-Percentage";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(calculatedAmount);
                                element.price.agentMarkUp = CustomToFixed(element.price.agentMarkUp, 2);
                                element.price.agentMarkUpType = "ServiceFee-Percentage";
                            });

                            totalFareAmount = elementPrice.offeredPrice.priceOffered;
                        }
                    });
                });
            }
            else {
                hotelBookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
                hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                    elementHotelResults.price.forEach(elementPrice => {
                        if (markupLabel === 'Amount') {
                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.roomPrice = elementPrice.roomPrice.toFixed(2);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.offeredPrice.priceOffered = elementPrice.offeredPrice.priceOffered.toFixed(2);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(document.querySelector('#markup').value);
                            elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(2);

                            elementPrice.agentMarkUp = parseFloat(document.querySelector('#markup').value);
                            elementPrice.agentMarkUp = elementPrice.agentMarkUp.toFixed(2);
                            elementPrice.agentMarkUpType = "ServiceFee-Amount";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(document.querySelector('#markup').value);
                                element.price.agentMarkUp = element.price.agentMarkUp.toFixed(2);
                                element.price.agentMarkUpType = "ServiceFee-Amount";
                            });

                            totalFareAmount = elementPrice.offeredPrice.priceOffered;
                        }
                        else if (markupLabel === 'Percentage') {
                            calculatedAmount = (parseFloat(elementPrice.offeredPrice.priceOffered) * parseFloat(document.querySelector('#markup').value)) / 100;

                            elementPrice.roomPrice = parseFloat(elementPrice.roomPrice) + parseFloat(calculatedAmount);
                            elementPrice.roomPrice = CustomToFixed(elementPrice.roomPrice, 2);

                            elementPrice.offeredPrice.priceOffered = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(calculatedAmount);
                            elementPrice.offeredPrice.priceOffered = CustomToFixed(elementPrice.offeredPrice.priceOffered, 2);

                            elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(calculatedAmount);
                            elementPrice.offeredPrice.perNight = CustomToFixed(elementPrice.offeredPrice.perNight, 2);

                            elementPrice.agentMarkUp = parseFloat(calculatedAmount);
                            elementPrice.agentMarkUp = CustomToFixed(elementPrice.agentMarkUp, 2);
                            elementPrice.agentMarkUpType = "ServiceFee-Percentage";

                            elementHotelResults.roomRateDetails.forEach(element => {
                                element.price.agentMarkUp = parseFloat(calculatedAmount);
                                element.price.agentMarkUp = CustomToFixed(element.price.agentMarkUp, 2);
                                element.price.agentMarkUpType = "ServiceFee-Percentage";
                            });

                            totalFareAmount = elementPrice.offeredPrice.priceOffered;
                        }
                    });
                });
            }

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnMarkupAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, 2) : document.querySelector('#markup').value);

            document.querySelector('#spnServiceFeeHotelTop').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, 2) : document.querySelector('#markup').value);

            let otherServiceMarupAmount = 0;
            otherServiceMarupAmount = markupLabel === 'Percentage' ? CustomToFixed(calculatedAmount, 2) : document.querySelector('#markup').value;

            let otherServiceTotal = parseFloat(otherServiceMarupAmount);

            document.querySelector('#dvOtherServicesAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(CustomToFixed(otherServiceTotal, 2));

            document.querySelector('#spnTotalAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(2));

            if (document.querySelector('#spnEquivalentAmountHotel') !== null) {
                document.querySelector('#spnEquivalentAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(hotelBookingJSON.data.hotelResults[0].price[0].currencyCode, totalFareAmount, false)));
            }

            // reset total price of payment section
            if (document.querySelector('#spnTotalRateTop') !== null) {
                document.querySelector('#spnTotalRateTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                }).format(parseFloat(totalFareAmount).toFixed(2));
            }
            if (document.querySelector('#spnEqRateTop') !== null) {
                document.querySelector('#spnEqRateTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(hotelBookingJSON.data.hotelResults[0].price[0].currencyCode, totalFareAmount, false)));
            }

            sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
            //console.log(hotelBookingJSON);
        }
        else {
            let hotelBookingJSON = [];
            if (Object.keys(intialBookingJSON).length > 0) {
                hotelBookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
            }
            else {
                hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
            }

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnMarkupAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(0);

            document.querySelector('#spnServiceFeeHotelTop').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(0);

            let otherServiceMarupAmount = 0;
            let otherServiceTotal = parseFloat(otherServiceMarupAmount);

            document.querySelector('#dvOtherServicesAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(CustomToFixed(otherServiceTotal, 2));

            var totalFareAmount = 0;
            hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    totalFareAmount = parseFloat(elementPrice.offeredPrice.priceOffered) + parseFloat(otherServiceTotal);
                    elementPrice.offeredPrice.priceOffered = totalFareAmount.toFixed(2);

                    elementPrice.offeredPrice.perNight = parseFloat(elementPrice.offeredPrice.perNight) + parseFloat(otherServiceTotal);
                    elementPrice.offeredPrice.perNight = elementPrice.offeredPrice.perNight.toFixed(2);
                })
            })

            //User Story 1073: Booking Summary for Single PNR
            document.querySelector('#spnTotalAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
            }).format(parseFloat(totalFareAmount).toFixed(2));

            if (document.querySelector('#spnEquivalentAmountHotel') !== null) {
                document.querySelector('#spnEquivalentAmountHotel').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(hotelBookingJSON.data.hotelResults[0].price[0].currencyCode, totalFareAmount, false)));
            }

            // reset total price of payment section
            if (document.querySelector('#spnTotalRateTop') !== null) {
                document.querySelector('#spnTotalRateTop').innerHTML = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                }).format(parseFloat(totalFareAmount).toFixed(2));
            }
            if (document.querySelector('#spnEqRateTop') !== null) {
                document.querySelector('#spnEqRateTop').innerHTML = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                    style: 'currency',
                    currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD',
                }).format(parseFloat(calculateRoeAmountAdditional(hotelBookingJSON.data.hotelResults[0].price[0].currencyCode, totalFareAmount, false)));
            }

            hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    if (elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUpType !== undefined) {
                        elementPrice.agentMarkUp = "0"
                    }
                });
            });

            sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
        }

        props.onContinueButton("4");
        // window.scrollTo({
        //     top: 680,
        //     behavior: "smooth",
        // });
        if (document.querySelector("#headingFive") !== null) {
            const yOffset = -200;
            const element2 = document.getElementById("headingFive");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const onMarkupChangeHandler = (event) => {
        setMarkupLabel(event.label);
        document.querySelector('#markupPrefernce').innerHTML = "";
    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const onTandcChargeNameChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    const onTandcZeroDisputeChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    return (<div className="accordion_flex_area">
        <div className="accordion_left_side position-relative text-muted">
            <h5>4</h5>
            <span id="completeFour" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button id="headingFour" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseFour"
                    aria-expanded="false" aria-controls="collapseFour">Add Service Fee
                </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse"
                aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div className='row'>

                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='control'>
                            <input type="number" className="mb-0 form-control" id='markup' min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10"></input>
                            <span id="markupValue" className="error-text"></span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='control'>
                            <Dropdown
                                options={prefernce_list}
                                textField=""
                                dataItemKey=""
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                onChange={onMarkupChangeHandler}
                            />
                            <span id="markupPrefernce" className="error-text"></span>
                        </div>
                    </div>
                </div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcZeroDispute" onChange={(e) => onTandcZeroDisputeChangeHandler(e)}></input>&nbsp;
                    I agree that it is the agent's responsibility to ensure zero disputes on service fees and markups.</div>
                <div className='text-black my-3'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcChargeName" onChange={(e) => onTandcChargeNameChangeHandler(e)}></input>&nbsp;
                    I agree that this booking platform will not handle payments for service fees and markups. It is the agent's responsibility to charge and collect the payment.</div>
                <span id='spnTandCMessage' className="error-text"></span>
                <div className="col-md-12 my-2">
                    <div className="float-end">
                        <button type="button" onClick={assignMarkup} className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default AddMarkup
import { Fragment } from "react";
import ApprovalHistoryServiceWise from "./ApprovalHistoryServiceWise";

const ApprovalHistory = (props) => {

    //This will be a nested array of each service and their respective approval history
    let serviceWiseHistoryList = [];

    if (props.isServiceWiseApproval) {

        let sericesRequested = [];
        props.data.forEach((history) => {
            if (history.serviceTypeID !== 'ALL') {
                if (history.serviceTypeID === 'FL') {
                    if (sericesRequested.indexOf(history.serviceTypeID) === -1) {
                        sericesRequested.push(history.serviceTypeID);
                    }
                }
                else if (history.serviceTypeID === 'HT') {
                    if (sericesRequested.indexOf(history.serviceTypeID) === -1) {
                        sericesRequested.push(history.serviceTypeID);
                    }
                }
                else {
                    //SR
                    if (sericesRequested.indexOf(history.serviceTypeID) === -1) {
                        sericesRequested.push(history.serviceTypeID);
                    }
                }
            }            
        });

        sericesRequested.sort();

        sericesRequested.forEach(serviceID => {
            let historyList = [];
            props.data.forEach((history) => {
                //ALL will be a part of all the services because it signifies request submission
                if (history.serviceTypeID === 'ALL' || serviceID === history.serviceTypeID) {
                    historyList.push({
                        ...history,
                        key: (serviceID + history.serviceTypeID + history.approvalLevel + history.approvalUserID + history.serverUpdatedDate)
                            .replaceAll(' ', '')
                            .replaceAll(':', '')
                    });
                }
            });

            serviceWiseHistoryList.push({
                serviceID: serviceID,
                historyList: historyList
            });

        });

    }
    else {
        let historyList = [];
        historyList = props.data.map((history) => {
            return {
                ...history,
                key: (history.serviceTypeID + history.approvalLevel + history.approvalUserID + history.serverUpdatedDate)
                    .replaceAll(' ', '')
                    .replaceAll(':', '')
            };
        });

        serviceWiseHistoryList.push({
            serviceID: "ALL",
            historyList: historyList
        });
    }

    return <Fragment>
        {serviceWiseHistoryList && serviceWiseHistoryList.length > 0 &&
            serviceWiseHistoryList.map(serviceWiseHistory => {
                return <ApprovalHistoryServiceWise
                    key={serviceWiseHistory.serviceID}
                    serviceWiseHistory={serviceWiseHistory}
                    isServiceWiseApproval={props.isServiceWiseApproval}
                >
                </ApprovalHistoryServiceWise>;
            })
        }


    </Fragment>
};

export default ApprovalHistory;
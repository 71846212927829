import { Fragment, useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import DelegatedApproverListTable from "./components/DelegatedApproverListTable";
import SelectDelegatePopupDialog from "./components/SelectDelegatePopupDialog";
import useHttp from "../../../services/use-http";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import PopupModel from "../../OBT/ApprovalWorkflow/Components/PopupModel";
import moment from "moment";
import {
    getAllDelegateApprovers,
    saveDelegateApprover,
    updateDelegateApprover,
} from "../../../services/DelegateApprover-API";
import { DELEGATE_APPROVER } from "../../../navigation/CONSTANTS";

const SelectDelegate = (props) => {
    const { selectedUser } = props;
    const { t } = useTranslation(["translation.Delegate"]);
    const {
        sendRequest: sendRequestSaveDelegateApprover,
        status: statusService,
        data: approvalResponse,
    } = useHttp(saveDelegateApprover);

    const {
        sendRequest: sendRequestUpdateDelegateApprover,
        status: statusUpdate,
        data: updateApprovalResponse,
    } = useHttp(updateDelegateApprover);
    console.log("updateApprovalResponse..", updateApprovalResponse);

    const {
        sendRequest: sendRequestGetDelegateApprover,
        data: delegateApproverResponse,
        status: sendRequestGetDelegateApproverStatus
    } = useHttp(getAllDelegateApprovers);

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [minToDate, setMinToDate] = useState(new Date());
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [delegateApprover, setDelegateApprover] = useState(null);
    const [approver, setApprover] = useState("");
    const [errors, setErrors] = useState({});
    const [toDateInputHasError, setToDateInputHasError] = useState(false);
    const [toDateErrorMessage, setToDateErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const employeeName = selectedUser
        ? `${selectedUser.title ? selectedUser.title : ""} ${selectedUser.firstName
        } ${selectedUser.middleName} ${selectedUser.lastName}`
        : approver;
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    const approvalUserID = selectedUser ? selectedUser.id : loginData.userID;
    const [alertModal, setAlertModal] = useState(null);

    const fromDateChangeHandler = (inputDate) => {
        const formattedFromDate = moment(
            inputDate.target.value.trim(),
            "MM/DD/YYYY"
        ).format("D MMM YYYY");
        setFromDate(formattedFromDate);
        setMinToDate(moment(inputDate.target.value.trim(), "MM/DD/YYYY").toDate()); // Update minToDate
    };

    const toDateChangeHandler = (inputDate) => {
        const formattedToDate = moment(
            inputDate.target.value.trim(),
            "MM/DD/YYYY"
        ).format("D MMM YYYY");
        const inputToDate = moment(inputDate.target.value.trim(), "MM/DD/YYYY");
        const earlierfromDate = moment(fromDate).format("MM/DD/YYYY");

        // Check if toDate is earlier than fromDate
        if (inputToDate.isBefore(earlierfromDate)) {
            setToDateInputHasError(true);
            setToDateErrorMessage(`${t("earlierDateError")}`);
            setToDate(fromDate);
        } else {
            setToDateInputHasError(false);
            setToDateErrorMessage("");
            setToDate(formattedToDate);
        }
    };
    function approvalTypeChangeHandler() {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false); // Close the modal when needed
    };

    function onAddToSelectedApprovalWorkflow(approvers) {
        setDelegateApprover(approvers);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "fromDate":
                fromDateChangeHandler(e);
                break;
            case "toDate":
                toDateChangeHandler(e);
                break;
            case "fromTime":
                setFromTime(value);
                break;
            default:
                setToTime(value);
                break;
        }
        setErrors((prevError) => ({
            ...prevError,
            [name]: null,
        }));
    };

    const validFilled = () => {
        let formErrors = {};
        let isValid = true;

        if (!delegateApprover) {
            formErrors.delegateApprover = `${t("errorDelegateApprover")}`;
            isValid = false;
            setErrors(formErrors);
            return isValid;
        }
        if (!fromDate) {
            formErrors.fromDate = `${t("errorFromDate")}`;
            isValid = false;
            setErrors(formErrors);
            return isValid;
        }
        if (!toDate) {
            formErrors.toDate = `${t("errorToDate")}`;
            isValid = false;
            setErrors(formErrors);
            return isValid;
        }
        if (!fromTime) {
            formErrors.fromTime = `${t("errorFromTime")}`;
            isValid = false;
            setErrors(formErrors);
            return isValid;
        }
        if (!toTime) {
            formErrors.toTime = `${t("errorToTime")}`;
            isValid = false;
            setErrors(formErrors);
            return isValid;
        }
        if (fromDate === toDate) {
            if (fromTime >= toTime) {
                formErrors.toTime = `${t("timeErrormessage")}`;
                isValid = false;
                setErrors(formErrors);
                return isValid;
            }
        }

        setErrors(formErrors);
        return isValid;
    };
    const submitFormHandler = async (event) => {
        event.preventDefault();

        if (validFilled()) {
            const formattedFromDate = moment(fromDate).format("YYYYMMDD");
            const formattedToDate = moment(toDate).format("YYYYMMDD");
            const delegateResponse = delegateApproverResponse?.data;

            const data = {
                id: delegateResponse?.id,
                traceID: JSON.parse(localStorage.getItem("traceID")),
                userID: JSON.parse(localStorage.getItem("userID")),
                iPAddress: localStorage.getItem("IPAddress"),
                delegateUserID: delegateApprover.id || delegateApprover.delegateUserID,
                delegateUserName: delegateApprover.delegateUserName
                    ? delegateApprover.delegateUserName
                    : delegateApprover.title +
                    delegateApprover.firstName +
                    " " +
                    delegateApprover.lastName,
                approvalUserID: approvalUserID,
                approvalUserName: employeeName,
                fromDate: formattedFromDate,
                toDate: formattedToDate,
                fromTime,
                toTime,
                officeID: JSON.parse(localStorage.getItem("officeID")),
                departmentName: delegateApprover.corporateMasterName,
                departmentID: delegateApprover.departmentID,
            };

            if (delegateResponse?.id) {
                sendRequestUpdateDelegateApprover(data);
            } else {
                sendRequestSaveDelegateApprover(data);
            }
        }
    };

    useEffect(() => {
        if (statusService === "completed") {
            if (approvalResponse && approvalResponse.errors.status === "TRUE") {
                if (approvalResponse["errors"].error["code"] === "1009") {
                    setAlertModal({
                        title: "Message",
                        message: `${t("errorDuplicateRecord")}`,
                    });
                } else {
                    setAlertModal({
                        title: "Message",
                        message: `${t("errorRecordSave")}`,
                    });
                }
            } else {
                setAlertModal({
                    title: "Success",
                    message: `${t("saveMessage")}`,
                });
            }
        }
    }, [statusService, approvalResponse]);

    useEffect(() => {
        if (statusUpdate === "completed") {
            if (
                updateApprovalResponse &&
                updateApprovalResponse.errors.status === "TRUE"
            ) {
                if (updateApprovalResponse.errors.error["code"] === "1009") {
                    setAlertModal({
                        title: "Message",
                        message: `${t("errorDuplicateRecord")}`,
                    });
                } else {
                    setAlertModal({
                        title: "Message",
                        message: `${t("errorRecordSave")}`,
                    });
                }
            } else {
                setAlertModal({
                    title: "Success",
                    message: `${t("saveMessage")}`,
                });
            }
        }
    }, [statusUpdate, updateApprovalResponse]);
    const handleAlertModalConfirm = () => {
        // Logic to handle when the modal is confirmed (if needed)
        setAlertModal(null); // Close the modal
        window.location.reload();
    };

    const handleReset = () => {
        //setFromDate("");
        //setToDate("");
        //setFromTime("");
        //setToTime("");
        //setDelegateApprover(null);
        //setErrors(null);
        if (props.onReset) {
            props.onReset();
        }
    };

    useEffect(() => {
        if (delegateApprover) {
            setErrors((prevError) => ({
                ...prevError,
                delegateApprover: null,
            }));
        }
    }, [delegateApprover]);
    useEffect(() => {
        if (approvalUserID) {            
            sendRequestGetDelegateApprover(approvalUserID);            
        }
    }, [approvalUserID]);

    useEffect(() => {
        const delegateResponse = delegateApproverResponse?.data;
        if (delegateResponse) {
            const formattedFromDate = moment(delegateResponse.fromDate).format(
                "D MMM YYYY"
            );
            const formattedToDate = moment(delegateResponse.toDate).format(
                "D MMM YYYY"
            );

            setFromDate(formattedFromDate);
            setToDate(formattedToDate);
            setFromTime(delegateResponse.fromTime);
            setToTime(delegateResponse.toTime);
            const delegateData = {
                delegateUserName: delegateResponse.delegateUserName,
                delegateUserID: delegateResponse.delegateUserID,
                corporateMasterName: delegateResponse.departmentName,
                departmentID: delegateResponse.departmentID,
            };
            setDelegateApprover(delegateData);            
        }
    }, [delegateApproverResponse]);

    useEffect(() => {
        const logedinUser = JSON.parse(localStorage.getItem("displayName"));
        setApprover(logedinUser);
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, []);

    return (
        <Fragment>
            {alertModal && (
                <ErrorModal
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={handleAlertModalConfirm}
                />
            )}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12"></div>
                    </div>
                </div>
            </section>

            {/* Popup for selecting delegate approver */}
            {showModal && (
                <PopupModel
                    title={t("selectApprovalStandard")}
                    message={
                        <SelectDelegatePopupDialog
                            approvalUserID={approvalUserID}
                            delegateUserID={delegateApprover ? (delegateApprover.id || delegateApprover.delegateUserID) : ''}
                            onClose={handleCloseModal}
                            selectedApproversForAllApprovalTypes={props.workflowDetails}
                            onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
                        />
                    }
                    onConfirm={handleCloseModal}
                ></PopupModel>
            )}
            <form className="form">
                <section
                    id="theme_search_form_tour"
                    className="fligth_top_search_main_form_wrapper"
                >
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {(statusService === "pending" ||
                                            statusUpdate === "pending" ||
                                            sendRequestGetDelegateApproverStatus === "pending") &&
                                            <div className="loading">
                                                <LoadingSpinner />
                                            </div>
                                        }
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group">
                                                    <label
                                                        id="pageTitle"
                                                        htmlFor=""
                                                        className="h5 text-primary mb-3 col-lg-12"
                                                    >
                                                        <i className="fas fa-globe-asia"></i>{" "}
                                                        {t("delegateWorkflow")}
                                                    </label>

                                                    {/* Approver Details */}

                                                    <div className="control">
                                                        <label
                                                            htmlFor="name"
                                                            className="mb-2 h6 text-primary col-lg-12"
                                                        >
                                                            {t("employeeName")}
                                                            <span className="error-icon"> *</span>
                                                        </label>
                                                        <input
                                                            id="name"
                                                            className="form-control form-control-sm"
                                                            value={employeeName}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-md-12">
                                                            <div className="float-end">
                                                                <button
                                                                    type="button"
                                                                    onClick={approvalTypeChangeHandler}
                                                                    className="btn text-white gt-main-btn btn-sm mb-1 float-end"
                                                                >
                                                                    {t("selectDelegateWorkflow")}
                                                                </button>
                                                            </div>
                                                            {/* <div className="clearfix"></div> */}
                                                        </div>
                                                        {errors?.delegateApprover && (
                                                            <div className="col-md-12 mb-2">
                                                                <p className="error-text">
                                                                    {errors?.delegateApprover}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {delegateApprover && (
                                                        <DelegatedApproverListTable
                                                            delegateApprover={delegateApprover}
                                                            setDelegateApprover={setDelegateApprover}
                                                        ></DelegatedApproverListTable>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <div className="control">
                                                                <label
                                                                    htmlFor="fromDate"
                                                                    className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"
                                                                >
                                                                    {" "}
                                                                    {t("fromDate")}
                                                                    <span className="error-icon"> *</span>
                                                                </label>
                                                                <div className="position-relative ">
                                                                    <DateRangePicker
                                                                        onHide={(e) => handleChange(e)}
                                                                        initialSettings={{
                                                                            startDate: new Date(),
                                                                            singleDatePicker: true,
                                                                            minDate: moment().toDate(),
                                                                            showDropdowns: true,
                                                                            autoApply: true,
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="text"
                                                                            className="form-control col-lg-12 col-sm-12 col-12"
                                                                            id="fromDate"
                                                                            name="fromDate"
                                                                            value={fromDate}
                                                                        />
                                                                    </DateRangePicker>
                                                                    {errors?.fromDate && (
                                                                        <p className="error-text">
                                                                            {errors?.fromDate}
                                                                        </p>
                                                                    )}
                                                                    <i class="date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="control">
                                                                <label
                                                                    htmlFor="ToDate"
                                                                    className="mb-2 h6 text-primary col-lg-12 col-sm-12 col-12"
                                                                >
                                                                    {t("toDate")}
                                                                    <span className="error-icon"> *</span>
                                                                </label>
                                                                <div class="position-relative mb-3">
                                                                    <DateRangePicker
                                                                        onHide={(e) => handleChange(e)}
                                                                        initialSettings={{
                                                                            singleDatePicker: true,
                                                                            minDate: minToDate,
                                                                            endDate: toDate,
                                                                            showDropdowns: true,
                                                                            autoApply: true,
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="text"
                                                                            id="ToDate"
                                                                            name="toDate"
                                                                            value={toDate}
                                                                            className="form-control col-lg-12 col-sm-12 col-12"
                                                                        />
                                                                    </DateRangePicker>
                                                                    {(toDateInputHasError || errors?.toDate) && (
                                                                        <p className="error-text">
                                                                            {toDateErrorMessage} {errors?.toDate}
                                                                        </p>
                                                                    )}
                                                                    <i class=" date-icon-userform fas fa-calendar-alt text-primary"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="control">
                                                                <label className="h6 text-primary mb-2">
                                                                    {t("fromTime")}{" "}
                                                                    <span className="error-icon"> *</span>
                                                                </label>
                                                                <input
                                                                    type="time"
                                                                    name="fromTime"
                                                                    className="form-control bg_input"
                                                                    value={fromTime}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                {errors?.fromTime && (
                                                                    <p className="error-text">
                                                                        {errors?.fromTime}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="control">
                                                                <label className="h6 text-primary mb-2">
                                                                    {t("toTime")}{" "}
                                                                    <span className="error-icon"> *</span>
                                                                </label>
                                                                <input
                                                                    type="time"
                                                                    name="toTime"
                                                                    className="form-control bg_input"
                                                                    value={toTime}
                                                                    onChange={(e) => handleChange(e)}
                                                                />
                                                                {errors?.toTime && (
                                                                    <p className="error-text">{errors?.toTime}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="mt-3">
                                                        <span className="error-icon">*</span>
                                                        <span id="requiredIndicator">
                                                            {t("requiredFieldsIndicator")}
                                                        </span>
                                                    </p>
                                                    <div className="col-md-12 top_form_search_button mt-2">
                                                        <div
                                                            className={` d-flex  ${selectedUser
                                                                ? "justify-content-between"
                                                                : "justify-content-end"
                                                                }`}
                                                        >
                                                            {/*This back button will be shown in large screen*/}
                                                            {selectedUser && (
                                                                <a
                                                                    href={DELEGATE_APPROVER}
                                                                    className="btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block"
                                                                >
                                                                    <span>
                                                                        <i className="fa fa-arrow-left"></i>
                                                                    </span>
                                                                </a>
                                                            )}
                                                            {/*This back button will be shown in small/mobile screen*/}
                                                            {selectedUser && (
                                                                <a
                                                                    href={DELEGATE_APPROVER}
                                                                    className="btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none"
                                                                >
                                                                    <span>
                                                                        <i className="fa fa-arrow-left"></i>
                                                                    </span>
                                                                </a>
                                                            )}
                                                            <div>
                                                                {
                                                                    <button
                                                                        id="btnReset"
                                                                        type="button"
                                                                        onClick={handleReset}
                                                                        className="btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end"
                                                                    >
                                                                        {t("Reset")}
                                                                    </button>
                                                                }
                                                                {checkSecurityDetails(
                                                                    "Settings",
                                                                    "Delegate Workflow",
                                                                    "Settings_Delegate Workflow_Update"
                                                                ) ? (
                                                                    <button
                                                                        id="btnSave"
                                                                        type="button"
                                                                        onClick={submitFormHandler}
                                                                        className="btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end"
                                                                    >
                                                                        {t("Save")}
                                                                    </button>
                                                                ) : (
                                                                    <span id="btnSave"></span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>
    );
};

export default SelectDelegate;

import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
    return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {
    return (
        <div className='imagemodalcard imageModal2 modal-top'>
            <header className='header'>
                {/* <h4>{props.title}</h4> */}
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                        <h4 className="text-white">{props.title}</h4>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={props.onConfirm}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
                </div>
            </header>
            <div className='content gt-h-375px m-1'>
                {props.message}
                {/*<p>{props.message}</p>*/}
            </div>

        </div>
    );
};

const PopupModel = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClick={props.onConfirm}></Backdrop>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                    title={props.title}
                    message={props.message}
                    onConfirm={props.onConfirm}
                ></ModalOverlay>,
                document.getElementById("overlay-root")
            )}
        </React.Fragment>
    );
};
export default PopupModel;

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import { Fragment } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const PieChartRevenueFlight = (props) => {
    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    const data = {
        labels: props.labels,
        datasets: [
            {
                data: props.data,
                backgroundColor: props.BackgroundColor,
                borderColor: props.BorderColor,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            datalabels: {
                display: false
                // anchor: 'end',
                // align: 'start',
                // offset: 10,
                // display: false,
                // labels: {
                //     //padding: { top: 10 },
                //     title: {
                //         font: {
                //             weight: "bold"
                //         }
                //     },
                //     value: {
                //         color: "black"
                //     }
                // }
            },
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                //paddingLeft:20,
                onClick: null,
                labels: {
                    fullSize: true,
                    boxWidth: 30,
                    font: {
                        size: 15,
                        weight: 'bold'
                    },
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        if (props.graphType === 'revenue') {
                            return datasets[0].data.map((data, i) => ({
                                text: chart.data.labels[i] + " (" + new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: data <= 0 ? 2 : parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                    style: 'currency',
                                    currency: reportJSON.data[0].revenue[0].currencyCode
                                }).format(data) + ")",
                                fillStyle: datasets[0].backgroundColor[i],
                                index: i
                            }))
                        }
                        else {
                            return datasets[0].data.map((data, i) => ({
                                text: chart.data.labels[i] + " (" + data + ")  ",
                                fillStyle: datasets[0].backgroundColor[i],
                                index: i
                            }))
                        }
                    }
                }
            }
        }
    }

    return <Fragment>
        <Doughnut data={data} options={options} width={getConfigurationByBoolen("ALLOW_SERVICE_FEE") ? 300 : 280} height={300} />
    </Fragment>
};
export default PieChartRevenueFlight;

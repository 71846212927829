import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from "react";
import ConfirmationModal from "../../../../../components/Common/Confirmation/ConfirmationModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ApproverDialog from "../ApproverDialog";
import FareRulePopupModal from '../PopupModel';
import { getConfigurationByValue } from '../../../../../utils/ConfigurationsFunction'
import WorkflowTypeNote from "./WorkflowTypeNote";

const SelectedApproverItem = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const parms = useParams();
    const { Id, Action, Name } = parms;
    const [confirm, setConfirm] = useState(false);
    const [errorApproverDialog, setErrorApproverDialog] = useState();
    let labelName = getConfigurationByValue(getConfigurationByValue("APPROVER_TYPE").split("-")[0]);
    function deleteFormHandler() {
        props.onDelete({
            approvalUserID: props.approver.approvalUserID,
            approvalLevel: props.approver.approvalLevel,
            approvalTypeID: props.approver.approvalTypeID
        });
    }
    function onConfirm(event) {
        event.preventDefault();
        setConfirm(true);
    }
    function okHandler() {
        deleteFormHandler();
        setConfirm(false);
    };
    function cancelHandler() {
        setConfirm(false);
    };
    function openApproverPopup(event) {

        event.preventDefault();

        const approvalTypeInfo = {
            approvalTypeID: props.approver.approvalTypeID,
            approvalTypeName: props.approver.approvalTypeName,
            approvalLevel: props.approver.approvalLevel,            
            serviceTypeID: JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeID,
            serviceTypeName: JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeName
        };

        setErrorApproverDialog({
            title: "Select " + props.approver.approvalTypeName,
            message: <ApproverDialog
                approvalTypeInfo={approvalTypeInfo}
                errorHandlerApproverDialog={errorHandlerApproverDialog}
                selectedApproversForAllApprovalTypes={props.workflowDetails}
                onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
            >
            </ApproverDialog>,
        });
    }
    function errorHandlerApproverDialog() {
        setErrorApproverDialog(null);
    }

    function onAddToSelectedApprovalWorkflow(approvers) {
        setErrorApproverDialog(null);
        props.onAddToSelectedApprovalWorkflow(approvers);
    }

    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {errorApproverDialog && (
            <FareRulePopupModal
                title={errorApproverDialog.title}
                message={errorApproverDialog.message}
                onConfirm={errorHandlerApproverDialog}
            ></FareRulePopupModal>
        )}
        <div className="row">

            <div className="col-lg-12">
                {props.approver.isFirstNode &&
                    <div className="row">
                        <div className="col-lg-12 mt-4 mb-2" >
                            <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12">
                                <span className="gt-border-1px">
                                    <i class="fas fa-tasks"></i>
                                    Workflow : {props.approver.approvalTypeName}
                                </span>
                            </label>
                        </div>
                    </div>
                }
                <div className="card text-dark card-bg-blue mb-2">

                    <div className="card-body">

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="card-title fw-bold text-primary mt-1 gt-workflow">
                                        <i class="fas fa-user me-1"></i>
                                        {props.approver.approvalUserName}
                                    </h6>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-0">
                                        <strong>Employee Code </strong>
                                        {props.approver.approvalUserCode}
                                    </p>
                                </div>
                                {Action === "1" &&
                                    <div className="col-md-2 my-auto">
                                        <div className='float-lg-end float-md-end'>
                                            <a id={"Edit" + props.approver.sequenceNumber}
                                                href="/#"
                                                title="Edit"
                                                onClick={openApproverPopup}
                                                className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn" >
                                                <i className="fa-solid fa-pen"></i>
                                            </a>

                                            <span>
                                                <a id={"Delete" + props.approver.sequenceNumber}
                                                    href="/#"
                                                    title="Delete"
                                                    onClick={onConfirm}
                                                    className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" >
                                                    <i className="fas fa-trash-alt"></i>
                                                </a>
                                                <ReactTooltip
                                                    anchorId={"delete" + props.approver.corporateMasterID}
                                                    place="bottom"
                                                    variant="info"
                                                    multiline={true}
                                                    className="tooltip"
                                                    html={"Delete"}
                                                />
                                            </span>

                                        </div>
                                    </div>
                                }

                            </div>

                            <hr className="my-1" />
                        </div>
                        <div className="row">
                            {(props.approver.approvalTypeID === "2" || props.approver.approvalTypeID === "7") &&
                                <div className="col-md-6">
                                    <p className="card-text mb-0">
                                        <strong>Approval Level </strong>
                                        {"Level " + props.approver.approvalLevel}
                                    </p>
                                </div>
                            }
                            <div className="col-md-6">
                                <p className="card-text mb-0">
                                    <strong>Department </strong>
                                    {props.approver.corporateMasterName}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                {props.approver.isLastNode &&
                    <WorkflowTypeNote
                        approvalTypeID={props.approver.approvalTypeID}
                        name={Name}
                        labelName={labelName} />
                }

            </div>
        </div>
    </Fragment >
};
export default SelectedApproverItem;
import { useState, Fragment, useEffect } from 'react';
import { sortData } from '../../../../utils';
import WhoIsWhereItem from './WhoIsWhereItem';

const WhoIsWhereList = (props) => {

    return <Fragment>

        <div className="col-lg-12" >
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='text-end w-10 text-center'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='w-20'>Company Name</th>
                                            <th scope="col" className='min-width-210' align={'left'}>Traveler</th>
                                            <th scope="col" className='w-10 text-center'>Pax Count</th>
                                            <th scope="col" className='min-width-130'>Travel Date</th>
                                            <th scope="col" className='w-10'>Flight No.</th>                                            
                                            <th scope="col" className='w-15'>Destination</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.currentItems.map((currentItem, index) =>
                                                <WhoIsWhereItem key={currentItem.slNo}
                                                    whoIsWhereInfo={currentItem}></WhoIsWhereItem>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </Fragment>

};

export default WhoIsWhereList;
import { Fragment, useState, useEffect, useRef } from 'react';
import TravellerInfo from './Components/TravellerInfo';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import Dropdown from 'react-dropdown';
import FlightItem from '../../components/Common/Flight/FlightItem';
import FareSummary from '../../components/Common/Flight/FareSummary';
import PrintConfirmation from '../../components/Common/Flight/PrintConfirmation';
import SendEmail from './SendEmail';
import PopupModal from '../../components/Common/Flight/PrintPopupModal';
import { Tooltip as ReactTooltip } from "react-tooltip";
//Single Pnr change
import queryString from 'query-string';
import { useLocation } from 'react-router';

//Role rights Change
import { checkSecurityDetails } from '../../utils/RoleUtils';

const BookingConfirmation = (props) => {
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let bookingJSON = valueParam.issueTicket !== undefined && valueParam.issueTicket !== null && valueParam.issueTicket === 'y' ? JSON.parse(sessionStorage.getItem("BookingDataIssueTicketJson")) : localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(localStorage.getItem("BookingData"));
    //console.log(bookingJSON);
    let pnr = bookingJSON.data.bookingReferenceID !== undefined ? bookingJSON.data.bookingReferenceID[0].id : null;
    let issueTicket = pnr === null ? " Booking Details" : " Booking Confirmation";



    useDocumentTitle('Flight Confirmation');
    const [error1, setError] = useState();
    const [errorMail, setErrorMail] = useState();

    const onSendEmail = () => {
        setErrorMail({
            title: "Send Itinerary",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <SendEmail mailData={bookingJSON} hideData="0" errorHandler={errorHandler}></SendEmail>
                </div></div>,
        })


    }

    // useEffect(() => {

    //     if (sessionStorage.getItem('TripFlightSuccessMsg') !== null && sessionStorage.getItem('TripFlightSuccessMsg') !== '') {

    //         document.querySelector('#msg').innerHTML = sessionStorage.getItem('TripFlightSuccessMsg')
    //     }


    //     window.scrollTo({
    //         top: 100,
    //         behavior: "smooth",
    //     });
    // }, [])

    const errorHandler = () => {
        setError(null);
        setErrorMail(null);
    };

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {/*  {
            errorMail && (
                <MdModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </MdModal>
            )
        } */}
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        <div >
            {/*     <!-- Common Banner Area --> */}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>


            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    {props.hideData !== '1' && <div className='col-md-6'>
                                                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket} <span class={(bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S') ? "badge h6 rounded-pill mb-0 bg-primary" : pnr !== null ? bookingJSON.data.airItinerary[0].sourceName === 'LCC' ? bookingJSON.data.bookingReferenceID[0].bookingStatus !== undefined && bookingJSON.data.bookingReferenceID[0].bookingStatus === 'SUCCESS' ? 'badge h6 rounded-pill mb-0 bg-success' : 'badge h6 rounded-pill mb-0 bg-warning' : bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}>
                                                            <i class={bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S' ? "fa-solid fa-circle-check" : pnr !== null ? bookingJSON.data.airItinerary[0].sourceName === 'LCC' ? bookingJSON.data.bookingReferenceID[0].bookingStatus !== undefined && bookingJSON.data.bookingReferenceID[0].bookingStatus === 'SUCCESS' ? 'fa-solid fa-check-circle' : 'fa-solid fa-pause' : bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span>
                                                            {(bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S') ? "Booking Request" : pnr !== null ? bookingJSON.data.airItinerary[0].sourceName === 'LCC' ? bookingJSON.data.bookingReferenceID[0].bookingStatus !== undefined && bookingJSON.data.bookingReferenceID[0].bookingStatus === 'SUCCESS' ? 'Booked' : 'On Hold' : bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>

                                                    </div>}



                                                    <div className='col-md-6'>
                                                        {checkSecurityDetails("My Trips", "Email", "My Trips_Email_Allow Send Email") && <div className='float-lg-end'>

                                                            <span id="sendMail" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end' onClick={() => onSendEmail()}><i className="fas fa-envelope"></i>
                                                            </span>

                                                        </div>}
                                                        <ReactTooltip
                                                            anchorId={"sendMail"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Email"}
                                                        />
                                                        {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") && <div className='float-lg-end'>
                                                            <PrintConfirmation page="confirmation" myTripIssueTicket={valueParam.issueTicket !== undefined && valueParam.issueTicket !== null && valueParam.issueTicket === 'y' ? 'y' : ''}></PrintConfirmation>
                                                        </div>}



                                                    </div>

                                                    {/* <p id='msg' className="error-text fw-bold"></p> */}

                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            {/* <label for="" className="h6 text-primary mb-2">Round Trip</label> */}
                                                            {props.hideData !== '1' && <div className='col-md-12'>
                                                                <div className='text-muted h6 mb-2 mt-2'><b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                                                                    {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                                            </div>}
                                                        </div>




                                                    </div>
                                                    <div className='col-md-12'>
                                                        <FlightItem
                                                            key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                            id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                            airItinerary={bookingJSON.data.airItinerary[0]}
                                                            searchFlight={bookingJSON.data.airItinerary[0]}
                                                            bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                                            fareRuleData={bookingJSON.data.airItinerary[0]}
                                                            showData="3"
                                                            hideCommision="4"
                                                            title="Send Trip"
                                                        />
                                                        {/* <hr className=''></hr> */}
                                                        <div className="tour_details_boxed_inner">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion_flex_area">
                                                                    <div className="accordion-item">
                                                                        <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                                            Price
                                                                        </h5>

                                                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                                                            <div className='row my-2'>
                                                                                <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                                <TravellerInfo bookingJSON={bookingJSON}></TravellerInfo>

                                                                <div className="accordion_flex_area d-none">
                                                                    <div className="accordion_left_side">
                                                                        <h5>5</h5>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header" id="headingThree">
                                                                            <button className="accordion-button collapsed" type="button"
                                                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                                                aria-expanded="false" aria-controls="collapseThree">
                                                                                Internal Notes
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                                                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}

            </section>
        </div>
    </Fragment>




};

export default BookingConfirmation;
import { useRef, useState, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { ForgotUpdatePassword } from '../../../services/Authentication-API'
import { useTranslation } from 'react-i18next';
import { passwordValidation } from '../../../utils/Validation'
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import useHttp from '../../../services/use-http'
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
import {
  getLoginPageBackgroundImage,
  getLoginPageCompanyLogo,
  getLoginUrl,
  isValidHttpUrl,
  modifyUrlParams
} from '../../../utils/CommonFunction';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import { getCustomizationByPageName } from '../../../services/CustomizeSetting-API';
import { compress } from '@amoutonbrady/lz-string';

const UpdatePassword = () => {
  useDocumentTitle('Update Password');
  //const parms = useParams();
  const { t } = useTranslation(['translation.ForgotPassword']);
  const NewPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const history = useHistory();
  //const { Id } = parms;
  const { sendRequest, status, data: updatePasswordDetails, error } = useHttp(ForgotUpdatePassword);

  const location = useLocation();
  const params = queryString.parse(location.search);
  const token = params.Id;
  const [pageName] = useState(params.p || '');

  const [errors, setErrors] = useState();
  const [newPasswordInputInputHasError, setnewPasswordInputInputHasError] = useState(false);
  const [newPasswordValidInputInputHasError, setnewPasswordValidInputInputHasError] = useState(false);
  const [confirmPasswordInputInputHasError, setconfirmPasswordInputInputHasError] = useState(false);
  const [confirmPasswordValidInputInputHasError, setconfirmPasswordValidInputInputHasError] = useState(false);
  const [loadForm] = useState(pageName ? false : true);

  const { sendRequest: sendRequestForCustomization,
    status: getCustomizationStatus,
    data: customizeResponse
  } = useHttp(getCustomizationByPageName);

  const errorHandler = () => {
    if (errors.message === 'Password updated successfully.')
      history.push(getLoginUrl());
    else
      setErrors(null);
  };

  function customizePage() {
    if (getCustomizeDetails('forgotPwdLabelColor')) {
      document.documentElement.style.setProperty('--custom-heading', getCustomizeDetails('forgotPwdLabelColor'));
    }

    if (getCustomizeDetails('loginBtnGradientColor1')) {
      document.documentElement.style.setProperty('--login-custom-btn', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails('loginBtnGradientColor1')} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails('loginBtnGradientColor2')}`);
    }
  }

  function setCustomizationDetailsInStorage(customizationDetails) {
    const isUrl = isValidHttpUrl(pageName);
    localStorage.setItem('customizationClientKey', pageName);
    localStorage.setItem('customizationClientKeyLocation', isUrl ? 'host' : 'param');
    localStorage.setItem('customizeDetailsForNonAuth', compress(JSON.stringify(customizationDetails)));
    localStorage.setItem('customizationCleared', false);
  }

  function clearCustomizationStorage() {
    localStorage.removeItem('customizationClientKey');
    localStorage.removeItem('customizationClientKeyLocation');
    localStorage.removeItem('customizeDetailsForNonAuth');
    localStorage.setItem('customizationCleared', true);
  }

  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in updatePasswordDetails) {
        if (updatePasswordDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            loadedData = updatePasswordDetails[i];
            setErrors({
              title: "Message",
              message: `Password updated successfully.`,
            });
          }
        }
        else if (updatePasswordDetails['errors'].status === 'TRUE') {
          if (updatePasswordDetails.errors.error.code === "1019") {
            setErrors({
              title: "Message",
              message: 'You have already used that password, Please try another combination.',
            });
          } else {
            setErrors({
              title: "Message",
              message: 'Sorry, an unexpected error has occurred. Please try again.',
            });
          }
        }
      }
    }

    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, updatePasswordDetails, history]);

  const submitHandler = useCallback((event) => {
    event.preventDefault();
    const enteredNewPassword = NewPasswordInputRef.current.value;
    const enteredConfirmPassword = confirmPasswordInputRef.current.value;
    // optional: Could validate here
    if (enteredNewPassword === '') {
      NewPasswordInputRef.current.focus();
      setnewPasswordInputInputHasError(true);
      setnewPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredNewPassword)) {
      setnewPasswordInputInputHasError(false);
      setnewPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setnewPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword === '') {
      NewPasswordInputRef.current.focus();
      setconfirmPasswordInputInputHasError(true);
      setconfirmPasswordValidInputInputHasError(false);
      return;
    }
    if (!passwordValidation(enteredConfirmPassword)) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }
    if (enteredConfirmPassword !== enteredNewPassword) {
      setconfirmPasswordInputInputHasError(false);
      setconfirmPasswordValidInputInputHasError(true);
      return;
    }
    else {
      setconfirmPasswordValidInputInputHasError(false);
    }

    sendRequest({ loginEmailID: token, newPassword: enteredNewPassword });
    // optional: Add validation 
  }, [sendRequest]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);

  useEffect(() => {
    if (getCustomizationStatus === 'completed') {
      //console.log('customizeResponse', customizeResponse);
      if (customizeResponse && customizeResponse.errors.status === 'FALSE') {
        setCustomizationDetailsInStorage(customizeResponse.data);
      }
      //Remove pagename param and append param 'r' to signify that customization has been loaded and page is refreshed
      //Removing pagename param (p) ensures that customization values are not fetched again on page reload.
      const newUrl = modifyUrlParams(window.location.href, ['p'], [{ name: 'r', value: '1' }]);
      window.location.href = newUrl.href;
    }
  }, [getCustomizationStatus, customizeResponse]);

  useEffect(() => {
    //Fetch customization if pageName param (p) exists
    if (pageName) {
      sendRequestForCustomization({
        pageName: pageName.toLowerCase()
      });
    }
    else if (!params.r && !JSON.parse(localStorage.getItem('customizationCleared'))) {
      //Presence of param 'r' signifies that the page is reloaded after customization. Therefore, customization values
      //should not be cleared if the param is present.

      //Here, clearing customization and reloading is needed in below case:
      //1. User updates password with customization loaded (p param is present)
      //2. User again updates password without customization (p param is missing). In this case, it's important to clear the customization
      //values created in case 1. Reloading the page makes sure that all the customizations are cleared.
      clearCustomizationStorage();
      window.location.reload();
    }
    else {
      NewPasswordInputRef?.current?.focus();
      customizePage();
    }
  }, []);

  return (
    <Fragment>

      {errors &&
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      }

      {!loadForm &&
        <section id="">
          <div className="container">
            <div className="row">
              <div className="full-page-loader">
                <LoadingSpinner></LoadingSpinner>
              </div>
            </div>
          </div>
        </section>
      }

      {loadForm &&
        <Fragment>
          <section className='gt_login_banner' style={getLoginPageBackgroundImage()}>
            <div className='position-relative'>
              <a href={getLoginUrl()} className="">
                <img src={getLoginPageCompanyLogo()} alt="" className='log-in-head-logo-bg' />
              </a>
            </div>
          </section>

          <div className="container">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 mb-1 position-relative">
                <div className="gt-common_author_boxed position-absolute width-100 gt-login-banner-div">
                  <div className="card-body">
                    <div className="col-md-10 col-sm-12 mx-auto signin-log">
                      <div className='text-center'>
                        <label className="mb-2 gt-heading_theme h4 custom-heading-text mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Reset Password</label>
                      </div>
                      <div className="form-group col-md-12">
                        <form action="#" id="main_author_form">
                          <div className='control'>
                            <div className='input-group'>
                              {/* <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('loginid')}</label> */}
                              <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                              {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                              <input type='password'
                                className="form-control" tabIndex={1} id='NewPassword'
                                placeholder={t('NewPassword')}
                                /*  onChange={onloginIDChangeCheck} */
                                required ref={NewPasswordInputRef} />
                              <ReactTooltip
                                anchorId="NewPassword"
                                place="bottom"
                                variant="info"
                                multiline={true}
                                className="tooltip"
                                html={t('passwordHint')}
                              />
                              <span className="input-group-text w-50px h-50px">
                                <i className="fa fa-eye-slash text-primary" id="togglePassword2"></i>
                              </span>
                            </div>
                            <div>
                              {newPasswordInputInputHasError &&
                                <p className="error-text">
                                  {t('validationNewPassword')}
                                </p>
                              }
                              {newPasswordValidInputInputHasError &&
                                <p className="error-text">
                                  {t('validationValidNewpassword')}
                                </p>
                              }
                            </div>
                          </div>
                          <div className='input-group'>
                            {/* <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('loginid')}</label> */}
                            <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                            {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                            <input type='password'
                              className="form-control" tabIndex={1} id='confirmPassword'
                              placeholder={t('confirmPassword')}
                              /*  onChange={onloginIDChangeCheck} */
                              required ref={confirmPasswordInputRef} />
                            <ReactTooltip
                              anchorId="confirmPassword"
                              place="bottom"
                              variant="info"
                              multiline={true}
                              className="tooltip"
                              html={t('passwordHint')}
                            />
                            <span className="input-group-text w-50px h-50px">
                              <i className="fa fa-eye-slash text-primary" id="togglePassword3"></i>
                            </span>

                          </div>
                          <div>
                            {confirmPasswordInputInputHasError &&
                              <p className="error-text">
                                {t('validationConfirmpassword')}
                              </p>
                            }
                            {confirmPasswordValidInputInputHasError &&
                              <p className="error-text">
                                {t('validationMatchConfirmpassword')}
                              </p>
                            }
                          </div>
                          <div className="form-group control">
                          </div>
                          <div className="common_form_submit">

                            <button className="btn main-btn-width-auto text-white gt-main-btn-WithoutAuth float-lg-end float-md-end ms-1 mt-1"
                              onClick={submitHandler}
                              tabIndex={4}>
                              {status === 'pending' ?
                                <i className="fa fa-spinner fa-spin" ></i> :
                                'Submit'
                              }
                            </button>
                            <a href={getLoginUrl()}
                              className="btn main-btn-width-auto text-white gt-main-btn-WithoutAuth float-lg-end float-md-end ms-1 mt-1">
                              Back to Login
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </Fragment>
      }

    </Fragment>
  );
};

export default UpdatePassword;
import { Fragment, React ,useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../redux/contexts/auth-context';
import { LOGIN, SEARCHCONFIGURATION, SEARCHCONTENT, SEARCHRATEPLANALLOWED, SEARCHWALLET, UPDATEEMAILSERVER } from '../../navigation/CONSTANTS';
const SettingMenu = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    function onConfiguration() {
        history.push(SEARCHCONFIGURATION)     
    }
    function onContent() {
        history.push(SEARCHCONTENT)     
    }
    function onCustomize() {
        history.push(`/NewCustomize`)   
    }
    function onEmailServer() {
        history.push(UPDATEEMAILSERVER)   
    }
    function onRatePlanAllowed() {
        history.push(SEARCHRATEPLANALLOWED)   
    }
    function onWallet() {
        history.push(SEARCHWALLET)   
    }
    function onSignOut() {
        authCtx.logout();
        history.push(LOGIN);
    }

  return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8 mb-30">
                                            <div className="card card-primary card-outline">

                                                <div className="card-body">
                                                    <div className="col-md-12 ">
                                                        <div className="row position-relative">
                                                            <div className="col-lg-12">
                                                                <div className="tour_search_form">
                                                                    <form action="!#">
                                                                        <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Settings</label>

                                                                        <div className='mt-3 actions row'>
                                                                            {/* <div className="col-lg-3 col-md-3 col-sm-3 my-auto">


                                                                            </div> */}
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="row">

                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onConfiguration} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-cogs"></i></span><br></br> <span className='text-12px text-primary'>{t('Configuration')}</span></button></div>

                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onContent} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i className="fas fa-file"></i></span><br></br> <span className='text-12px text-primary'>{t('Content')}</span></button></div>


                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onEmailServer} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i className="fas fa-envelope"></i></span><br></br> <span className='text-12px text-primary'>{t('Email Server')}</span> </button></div>
                                                                                    
                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onCustomize} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-cog"></i></span><br></br> <span className='text-12px text-primary'>{t('Customize')}</span> </button></div>

                                                                                   

                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onRatePlanAllowed} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i className="fas fa-globe-asia"></i></span><br></br> <span className='text-12px text-primary'>{t('Rate Plan Allowed')}</span> </button></div>

                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onWallet} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-wallet"></i></span><br></br> <span className='text-12px text-primary'>{t('Wallet')}</span> </button></div>

                                                                                    <div className="col-6 text-center col-sm-6"><button onClick={onSignOut} className='default-btn-view col-sm-6'><span className='btn text-white mt-1 gt-main-btn'><i class="fas fa-sign-out-alt"></i></span><br></br> <span className='text-12px text-primary'>{t('Sign Out')}</span> </button></div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>)

};

export default SettingMenu;


import { Fragment, useEffect, useState } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import Dropdown from 'react-select';
import SearchFlight from '../TravelRequest/Components/SearchFlight';
import SearchHotel from '../TravelRequest/Components/SearchHotel';
import ServiceRequest from '../TravelRequest/Components/ServiceRequest';
import Approver from '../TravelRequest/Components/Approver';
import MeetingDetails from '../TravelRequest/Components/MeetingDetails';
import Document from '../TravelRequest/Components/Document';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import HotelDetails from '../TravelRequest/Components/HotelDetails';
import Booking from '../TravelRequest/Components/Booking';
import RateDetails from '../TravelRequest/Components/RateDetails';
import FlightItem from '../../../components/Common/Flight/FlightItem';
import FareSummary from '../../../components/Common/Flight/FareSummary';
import BookingLoadingSpinner from '../../Booking/Components/BookingLoadingSpinner';
import { decompress, compress } from '@amoutonbrady/lz-string';
import { getStops } from '../../../utils/SendRecommendation';
import useHttp from '../../../services/use-http';
import { sortFlightResult } from '../../../utils';
import { travelRequest } from '../../../services/Booking-API';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import { getApprovalID, getPolicyID } from '../../../utils/OBTFunction';
import { CustomToFixed } from '../../../../src/utils/CommonFunction';
import { BOOKING } from '../../../navigation/CONSTANTS';
import { priceCheck } from '../../../services/Flight-API';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { getRetrieveTravelRequest, updateServiceRequest } from '../../../services/Booking-API';
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { decryptWithErrorHandling } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useUploadedDocuments, deleteAllDocuments } from '../../../indexedDatabase/DocumentUpload';

const TravelRequest = () => {
    useDocumentTitle('Travel Request');
    const { t } = useTranslation(['translation.TravelRequest']);

    const [tripReasonValue, setTripReasonValue] = useState('');
    const [error1, setError] = useState();
    const history = useHistory();
    const location = useLocation();
    const [tripReasonInputHasError, setTripReasonInputHasError] = useState();
    const valueParam = queryString.parse(location.search);
    const [selectedTab, setSelectedTab] = useState('');
    //const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;
    const [confirm, setConfirm] = useState('');
    const [tripTypeDisabled, setTripTypeDisabled] = useState(false);

    const { sendRequest: sendRequestTravelRequest, status: statusTravelRequest, data: loadedTravelRequest } = useHttp(travelRequest);
    const { sendRequest, status, data: loadedPriceCheckResponse } = useHttp(priceCheck);
    const [hotelRequestKey, setHotelRequestKey] = useState(1);
    const [serviceRequestKey, setServiceRequestKey] = useState(1);
    //unlike service key where the component is refreshed on key change, meeting details key is being used to manually trigger and control the change
    const [meetingDetailsKey, setMeetingDetailsKey] = useState(1);

    let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
    let travelRequestJSON = JSON.parse(localStorage.getItem("TravelRequestInput"));
    let tripReasonOptions = [];
    let tripReasonList = [];

    const { sendRequest: sendRetrieveTravelRequest, data: retrieveTravelRequestResponse, status: retrieveTravelRequestStatus } = useHttp(getRetrieveTravelRequest);
    const { sendRequest: sendDecryptionRequest, data: decryptionResponse, status: decryptionRequestStatus } = useHttp(decryptWithErrorHandling);
    const { sendRequest: sendUpdateServiceRequest, data: updateServiceResponse, status: updateServiceStatus } = useHttp(updateServiceRequest);

    const [editedServiceInfo, setEditedServiceInfo] = useState({
        shoppingID: 0,
        referenceNumber: '',
        approvalMasterID: '',
        officeID: ''
    });

    const [travelRequestSubmitHasError, setTravelRequestSubmitHasError] = useState(false);

    const [isTravelRequestRetrievalInProgress, setIsTravelRequestRetrievalInProgress] = useState(valueParam.ref ? true : false);

    //useUploadedDocuments is reactive and will watch the changes done to the document table and will update the values
    //accordingly.
    const travelRequestDocuments = useUploadedDocuments();

    if (sessionStorage.getItem("travelRequestFieldsDataJson") === null) {
        const travelRequestFieldsDataJson = ({
            justificationCodeFlight: {},
            justificationDetailsFlight: {},
            justificationCodeHotel: {},
            justificationDetailsHotel: {},
            serviceRequestId: {},
            serviceRequestName: {},
            remarks: '',
            existingConfirmationNumber: {},
            totalRateOfService: {},
            travelRequestDocuments: {},
            travelRequestDocumentsRemarks: {},
            meetingCountry: '',
            meetingCity: '',
            meetingStartDate: '',
            meetingEndDate: '',
            meetingStartTime: '',
            meetingEndTime: '',
            meetingPurposeOfTravel: '',
            meetingPurposeOfTravelText: '',
            tripReason: '',
            tripPurposeRemarks: '',
            documentRemarks: ''
        });
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    if (localStorage.getItem("TravelRequestInput") === null) {

        const loginData = localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData"));
        const travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        let departmentID = "";
        let costCentreID = "";
        let positionID = "";
        let isFamilyBooking = false;

        let employeeCode = "";
        let employeeName = "";
        let approverEmployeeCode = "";
        let approverEmployeeName = "";

        let isAllowOutPolicyApprover = false;
        let isAllowHighRiskApprover = false;

        if (loginData) {
            departmentID = loginData.departmentID;
            costCentreID = loginData.costCentreID;
            positionID = loginData.positionID;

            employeeCode = loginData.employeeCode;
            employeeName = loginData.displayName;
            approverEmployeeCode = loginData.firstApproverEmployeeCode;
            approverEmployeeName = loginData.firstApprover;

            if (travelRequestFieldsDataJson) {
                const matchedTripType = loginData.tripReason.find(a => a.id === travelRequestFieldsDataJson.tripReason);
                if (matchedTripType) {
                    isFamilyBooking = matchedTripType.isFamilyBooking;
                }
            }

            loginData.configurationsDetails.configurationsMenuDetails.forEach((menu) => {
                if (menu.keyCode === 'ALLOW_OUT_OF_POLICY_WORKFLOW') {
                    isAllowOutPolicyApprover = menu.defaultBoolenValue;
                }
                else if (menu.keyCode === 'ALLOW_HIGH_RISK_WORKFLOWK') {
                    isAllowHighRiskApprover = menu.defaultBoolenValue;
                }
            });
        }

        const travelRequestInput = (
            {
                officeID: JSON.parse(localStorage.getItem('officeID')),
                data: {
                    travellerDetails: {
                        shoppingID: 0,
                        rqeuestType: 'TR',
                        switchLoginID: localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
                        totalCost: CustomToFixed(0, 2),
                        userID: JSON.parse(localStorage.getItem('userID')),
                        traceId: JSON.parse(localStorage.getItem('traceID')),
                        approvalMasterID: getApprovalID(),
                        approvalRequiredID: localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).approvalRequiredID,
                        policyMasterID: getPolicyID(),
                        arrangerUserID: JSON.parse(localStorage.getItem('userID')),
                        officeID: JSON.parse(localStorage.getItem('officeID')),
                        departmentID: departmentID,
                        CostCenterID: costCentreID,
                        tripReasonID: '',
                        positionID: positionID,
                        isFamilyBooking: isFamilyBooking,
                    },
                    approverDetails: {
                        employeeCode: employeeCode,
                        employeeName: employeeName,
                        apporverEmployeeCode: approverEmployeeCode,
                        apporverEmployeeName: approverEmployeeName
                    },
                    meetingDetails: null,
                    onlineFlightDetails: null,
                    onlineFlightFareDetails: null,
                    onlineFlightLowestDetails: null,
                    onlineFlightLowestFareDetails: null,
                    onlineHotelDetails: null,
                    onlineHotelLowestDetails: null,
                    serviceRequest: null,
                    travelRequestDocuments: [],
                    bookingData: [],
                    isAllowOutPolicyApprover: isAllowOutPolicyApprover,
                    isAllowHighRiskApprover: isAllowHighRiskApprover,

                }
            }
        );
        localStorage.setItem("TravelRequestInput", JSON.stringify(travelRequestInput));
    }

    const onTabChangeHandler = (tabOption) => {
        setSelectedTab(tabOption);

        if (document.querySelector('#tabButton_' + tabOption) !== null) {
            document.querySelector('#tabButton_' + tabOption).classList.add("active");
        }

        toggleSubmitButton(tabOption);

        if (tabOption === 'ServiceRequest' || tabOption === 'MeetingDetails' || tabOption === 'Document') {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.remove("d-none");
            }
        }
        else {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.add("d-none");
            }
        }

        if (tabOption === 'Hotel') {
            sessionStorage.setItem("HotelCBT", "1")
        }
        else {
            sessionStorage.removeItem("HotelCBT");
        }
    }

    const tripReasonChangeHandler = (event) => {
        clearSessionValues();
        setTripReasonValue(event.value);
        sessionStorage.removeItem("spnClass1");

        if (document.querySelector('#tabButton_Flight') !== null) {
            document.querySelector('#tabButton_Flight').classList.remove("d-none");
            document.querySelector('#tabButton_Flight_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Flight') !== null) {
            document.querySelector('#tabArea_Flight').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Hotel') !== null) {
            document.querySelector('#tabButton_Hotel').classList.remove("d-none");
            document.querySelector('#tabButton_Hotel_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Hotel') !== null) {
            document.querySelector('#tabArea_Hotel').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
            document.querySelector('#tabButton_ServiceRequest').classList.remove("d-none");
            document.querySelector('#tabButton_ServiceRequest_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
            document.querySelector('#tabArea_ServiceRequest').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Approver') !== null) {
            document.querySelector('#tabButton_Approver').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Approver') !== null) {
            document.querySelector('#tabArea_Approver').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Document') !== null) {
            document.querySelector('#tabButton_Document').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Document') !== null) {
            document.querySelector('#tabArea_Document').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
            document.querySelector('#tabButton_MeetingDetails').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
            document.querySelector('#tabArea_MeetingDetails').classList.remove("d-none");
        }

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.tripReason = event.value;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }

        let searchPolicyResponse = "";
        let tripReasonAndPolicyDataJson = "";

        if (localStorage.getItem("loginData") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === event.value);
        }

        if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === event.value);
        }

        let isFlight = false;
        let isHotel = false;
        let isServiceRequest = false;

        searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
            if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                isFlight = true;
            }
            if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                isHotel = true;
            }
            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                isServiceRequest = true;
            }
        });

        if (searchPolicyResponse.length > 0) {
            if (isFlight === false) {
                hideTab('Flight');
            }

            if (isHotel === false) {
                hideTab('Hotel');
            }

            if (isServiceRequest === false) {
                hideTab('ServiceRequest');
            }
        }
        else {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                hideTab('Flight');
            }

            if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                hideTab('Hotel');
            }

            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                hideTab('ServiceRequest');
            }
        }

        if (tripReasonList.length > 0) {
            if (tripReasonList[0].meetingID === "1") {
                hideTab('MeetingDetails');
            }

            toggleMeetingDetailsMandatoryIndicator();
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && !valueParam.ref) {
            let serviceTemp = valueParam.service === 'flight' ? 'Flight' : valueParam.service === 'hotel' ? 'Hotel' : 'Flight';
            onTabChangeHandler(serviceTemp);
            toggleControlsTabChangeDynamic(serviceTemp);
        }
        else {
            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true && !valueParam.ref) {
                    onTabChangeHandler('Flight');
                    toggleControlsOnFlightTabChange();
                }
                else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel === true && !valueParam.ref) {
                    onTabChangeHandler('Hotel');
                    toggleControlsOnHotelTabChange();
                }
                else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest === true) {
                    onTabChangeHandler('ServiceRequest');
                    toggleControlsOnServiceTabChange();
                }
                else {
                    if (selectedTab !== undefined && selectedTab !== '') {
                        if (selectedTab === 'Flight' && !valueParam.ref) {
                            onTabChangeHandler('Approver');
                            toggleControlsOnApproverTabChange();
                        }
                        else {
                            onTabChangeHandler(selectedTab);
                            toggleControlsTabChangeDynamic(selectedTab);
                        }
                    }
                    else {
                        onTabChangeHandler('Approver');
                        toggleControlsOnApproverTabChange();
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false && valueParam.ref) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false && valueParam.ref) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            history.push({
                pathname: location.pathname,
                search: '?return=1&service=' + valueParam.service
            });
        }
        else if (valueParam.ref) {
            history.push({
                pathname: location.pathname,
                search: '?ref=' + valueParam.ref
            });
        }
        else {
            history.push({
                pathname: location.pathname
            });
        }
    };

    function onSubmit() {
        setTravelRequestSubmitHasError(false);
        let validInputData = true;
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        let tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
        let tempTravelRequestDocuments = [];

        tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === tripReasonValue);

        if (tripReasonValue === '') {
            setTripReasonInputHasError(true);
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
            validInputData = false;
            return;
        }
        else {
            setTripReasonInputHasError(false);
        }

        const documentTab = document.getElementById('tabButton_Document');
        if (documentTab != null) {
            document.querySelector('#documentErrorMsg').innerHTML = "";
            document.querySelector('#docRemarksErrorMsg').innerHTML = "";

            if (travelRequestDocuments && travelRequestDocuments.length > 0) {

                travelRequestDocuments.forEach(doc => {
                    tempTravelRequestDocuments.push({
                        ...doc,
                        remarks: travelRequestFieldsDataJson.documentRemarks ? travelRequestFieldsDataJson.documentRemarks.trim() : ''
                    })
                });

                const docCount = tempTravelRequestDocuments.length;
                for (let loop = 0; loop < docCount; loop++) {
                    let doc = tempTravelRequestDocuments[loop];
                    if (doc.remarks.length === undefined || doc.remarks.length === 0) {
                        if (selectedTab !== 'Document') {
                            documentTab.click();
                        }
                        document.querySelector('#docRemarksErrorMsg').innerHTML = t('errorRemarksRequired');
                        validInputData = false;
                        break;
                    }
                    //else if (!doc.remarks.match('^[a-zA-Z . ( ) ! 0-9/,-/<>\n]+$')) {
                    //    if (selectedTab !== 'Document') {
                    //        documentTab.click();
                    //    }
                    //    document.querySelector('#docRemarksErrorMsg').innerHTML = t('errorInvalidData');
                    //    validInputData = false;
                    //    break;
                    //}
                }
            }
        }

        const meetingDetailsTab = document.querySelector('#tabButton_MeetingDetails');
        if (!valueParam.ref) {
            if (tripReasonList.length > 0 && tripReasonList[0].meetingID !== "1" && tripReasonList[0].meetingID !== "3") {
                if (validInputData && (travelRequestFieldsDataJson.meetingCountry.length === undefined || travelRequestFieldsDataJson.meetingCountry.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#countryErrorMsg').innerHTML = t('errorCountryRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#countryErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingCity.length === undefined || travelRequestFieldsDataJson.meetingCity.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#cityErrorMsg').innerHTML = t('errorCityRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#cityErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingStartDate.length === undefined || travelRequestFieldsDataJson.meetingStartDate.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#startDateErrorMsg').innerHTML = t('errorStartDateRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#startDateErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingEndDate.length === undefined || travelRequestFieldsDataJson.meetingEndDate.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#endDateErrorMsg').innerHTML = t('errorEndDateRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#endDateErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingStartTime.length === undefined || travelRequestFieldsDataJson.meetingStartTime.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#startTimeErrorMsg').innerHTML = t('errorStartTimeRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#startTimeErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingEndTime.length === undefined || travelRequestFieldsDataJson.meetingEndTime.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#endTimeErrorMsg').innerHTML = t('errorEndTimeRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#endTimeErrorMsg').innerHTML = "";
                }

                if (validInputData && (travelRequestFieldsDataJson.meetingPurposeOfTravel.length === undefined || travelRequestFieldsDataJson.meetingPurposeOfTravel.length === 0)) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        document.querySelector('#purposeOfTravelErrorMsg').innerHTML = t('errorTripPurposeRequired');
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
                else {
                    document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "";
                }

                if (validInputData && travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData) {
                    if (meetingDetailsTab !== null) {
                        meetingDetailsTab.click();
                        window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                        });
                    }
                    validInputData = false;
                    return;
                }
            }
        }

        if (validInputData === true) {
            let onlineFlightDetailsTemp = [];
            let onlineFlightLowestDetailsTemp = [];
            let onlineFlightFareDetailsTemp = [];
            let onlineFlightLowestFareDetailsTemp = [];

            let justificationCodeFlight = "";
            let justificationDetailsFlight = "";

            let meetingCountry = "";
            let meetingCity = "";
            let meetingStartDate = "";
            let meetingEndDate = "";
            let meetingStartTime = "";
            let meetingEndTime = "";
            let meetingPurposeOfTravel = "";
            let meetingPurposeOfTravelText = "";
            let tripPurposeRemarks = "";

            let totalCommoission = 0.0;

            let serviceRequests = [];

            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                justificationCodeFlight = travelRequestFieldsDataJson.justificationCodeFlight;
                justificationDetailsFlight = travelRequestFieldsDataJson.justificationDetailsFlight;

                serviceRequests = travelRequestFieldsDataJson.serviceRequests;

                meetingCountry = travelRequestFieldsDataJson.meetingCountry;
                meetingCity = travelRequestFieldsDataJson.meetingCity;
                meetingStartDate = travelRequestFieldsDataJson.meetingStartDate;
                meetingEndDate = travelRequestFieldsDataJson.meetingEndDate;
                meetingStartTime = travelRequestFieldsDataJson.meetingStartTime;
                meetingEndTime = travelRequestFieldsDataJson.meetingEndTime;
                meetingPurposeOfTravel = travelRequestFieldsDataJson.meetingPurposeOfTravel;
                meetingPurposeOfTravelText = travelRequestFieldsDataJson.meetingPurposeOfTravelText;
                tripPurposeRemarks = travelRequestFieldsDataJson.tripPurposeRemarks;
            }

            if (sessionStorage.getItem('initialFlightResults') !== null && sessionStorage.getItem('initialFlightResults') !== '' && localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let pricedItinerary1 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempDesc = sortFlightResult("1", pricedItinerary1, 'desc');

                let pricedItinerary2 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempInPolicy = pricedItinerary2.filter(a => a.inPolicy === true);
                let pricedItineraryTempInPolicyDesc = sortFlightResult("1", pricedItineraryTempInPolicy, 'desc');

                if (bookingJSON !== undefined && bookingJSON !== null) {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
                        if (element.commisionInfo[0] !== undefined && element.commisionInfo[0].earnedCommissionAmount !== undefined)
                            totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);
                    })
                }

                if (bookingJSON !== undefined && bookingJSON !== null) {
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(element => {
                        onlineFlightDetailsTemp.push(
                            {
                                sequenceNumber: 1, //bookingJSON.data.airItinerary[0].SequenceNumber,
                                segmentID: parseInt(element.flightSegment[0].flightID),
                                segmentGroupID: parseInt(element.group),
                                departureLocationCode: element.flightSegment[0].departureAirport.locationCode,
                                departureLocationName: element.flightSegment[0].departureAirport.locationName,
                                arrivalLocationCode: element.flightSegment[0].arrivalAirport.locationCode,
                                arrivalLocationName: element.flightSegment[0].arrivalAirport.locationName,
                                departureCityCode: element.flightSegment[0].departureAirport.cityCode,
                                departureCityName: element.flightSegment[0].departureAirport.cityName,
                                arrivalCityCode: element.flightSegment[0].arrivalAirport.cityCode,
                                arrivalCityName: element.flightSegment[0].arrivalAirport.cityName,
                                departureCountryCode: element.flightSegment[0].departureAirport.countryCode,
                                departureCountryName: element.flightSegment[0].departureAirport.countryName,
                                arrivalCountryCode: element.flightSegment[0].arrivalAirport.countryCode,
                                arrivalCountryName: element.flightSegment[0].arrivalAirport.countryName,
                                departureDate: element.flightSegment[0].departureDate,
                                arrivalDate: element.flightSegment[0].arrivalDate,
                                departureTime: element.flightSegment[0].departureTime,
                                arrivalTime: element.flightSegment[0].arrivalTime,
                                flightNumber: element.flightSegment[0].flightNumber,
                                marketingAirlineCode: element.flightSegment[0].marketingAirline.code,
                                operatingAirlineCode: element.flightSegment[0].operatingAirline.code,
                                directionIndicator: element.directionInd,
                                sectorInd: bookingJSON.data.airItinerary[0].SectorInd,
                                bookingType: sessionStorage.getItem("tripType") === "oneway" ? "O" : sessionStorage.getItem("tripType") === "roundtrip" ? "R" : "M",
                                fareType: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType : '',
                                international: bookingJSON.data.airItinerary[0].SectorInd === "I" ? true : false,
                                cabin: element.flightSegment[0].bookingClassAvails[0].cabinName,
                                departureTerminal: element.flightSegment[0].departureAirport.terminal,
                                arrivalTerminal: element.flightSegment[0].arrivalAirport.terminal,
                                checkInBaggage: element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight : element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces : '',
                                cabinBaggage: element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0] !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight : element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces : '',
                                airCraftName: element.flightSegment[0].equipment !== undefined && element.flightSegment[0].equipment[0].name !== undefined ? element.flightSegment[0].equipment[0].name : '',
                                airMiles: element.flightSegment[0].flownMileageQty,
                                stops: getStops(bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === element.directionInd)).split(" ")[0],
                                duration: element.flightSegment[0].duration !== undefined ? element.flightSegment[0].duration : '',
                                layoverTime: element.flightSegment[0].elapsedLayoverTime !== undefined ? element.flightSegment[0].elapsedLayoverTime : '',
                                dayChange: element.flightSegment[0].dateChangeNbr !== undefined ? element.flightSegment[0].dateChangeNbr === "1" ? true : false : false,
                                bookingSourceCode: bookingJSON.data.airItinerary[0].SourceId,
                                bookingSourceName: bookingJSON.data.airItinerary[0].SourceName
                            }
                        );
                    });
                }

                let pricedItinerary3 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempAsc = sortFlightResult("1", pricedItinerary3, 'asc');

                let itinCount = pricedItineraryTempAsc.length;
                let lowestFareInPolicy = '0';

                for (let index = 0; index < itinCount; index++) {
                    if (lowestFareInPolicy === '0' && pricedItineraryTempAsc[index].inPolicy) {
                        lowestFareInPolicy = pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount;
                        break;
                    }
                }

                onlineFlightFareDetailsTemp.push(
                    {
                        sequenceNumber: 1, //bookingJSON.data.airItinerary[0].SequenceNumber,
                        currencyCode: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                        commission: totalCommoission,
                        baseFare: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount,
                        taxes: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount,
                        totalFare: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount,
                        lowestFare: pricedItineraryTempAsc[0].airItineraryPricingInfo.totalFare.amount,
                        lowestFareInPolicy: lowestFareInPolicy,
                        highestFare: pricedItineraryTempDesc[0].airItineraryPricingInfo.totalFare.amount,
                        highestFareInPolicy: pricedItineraryTempInPolicyDesc.length > 0 ? pricedItineraryTempInPolicyDesc[0].airItineraryPricingInfo.totalFare.amount : 0,
                        inPolicy: bookingJSON.data.inPolicy,
                        inPolicyReason: bookingJSON.data.inPolicyReason,
                        totalOutBoundDuration: bookingJSON.data.airItinerary[0].totalOutBoundDuration,
                        totalInBoundDuration: bookingJSON.data.airItinerary[0].totalInBoundDuration,
                        totalItineraryDuration: bookingJSON.data.airItinerary[0].totalItineraryDuration,
                        fareRuleType: '',
                        refundableType: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].refundableType
                    }
                );                

                if (!bookingJSON.data.inPolicy) {                   

                    for (let index = 0; index < 3; index++) {

                        //+ sign before string is unary operator to convert string to number
                        if (+pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount > +onlineFlightFareDetailsTemp[0].totalFare) {
                            continue;
                        }

                        pricedItineraryTempAsc[index] !== undefined &&
                            pricedItineraryTempAsc[index].airItinerary !== undefined &&
                            pricedItineraryTempAsc[index].airItinerary.originDestinationOptions.originDestinationOption
                                .forEach(elementOriginDestinationOption => {
                                    onlineFlightLowestDetailsTemp.push(
                                        {
                                            lowestID: index + 1,
                                            sequenceNumber: 1, //pricedItineraryTempAsc[index].sequenceNumber,
                                            segmentID: parseInt(elementOriginDestinationOption.flightSegment[0].flightID),
                                            segmentGroupID: parseInt(elementOriginDestinationOption.group),
                                            departureLocationCode: elementOriginDestinationOption.flightSegment[0].departureAirport.locationCode,
                                            departureLocationName: elementOriginDestinationOption.flightSegment[0].departureAirport.locationName,
                                            arrivalLocationCode: elementOriginDestinationOption.flightSegment[0].arrivalAirport.locationCode,
                                            arrivalLocationName: elementOriginDestinationOption.flightSegment[0].arrivalAirport.locationName,
                                            departureCityCode: elementOriginDestinationOption.flightSegment[0].departureAirport.cityCode,
                                            departureCityName: elementOriginDestinationOption.flightSegment[0].departureAirport.cityName,
                                            arrivalCityCode: elementOriginDestinationOption.flightSegment[0].arrivalAirport.cityCode,
                                            arrivalCityName: elementOriginDestinationOption.flightSegment[0].arrivalAirport.cityName,
                                            departureCountryCode: elementOriginDestinationOption.flightSegment[0].departureAirport.countryCode,
                                            departureCountryName: elementOriginDestinationOption.flightSegment[0].departureAirport.countryName,
                                            arrivalCountryCode: elementOriginDestinationOption.flightSegment[0].arrivalAirport.countryCode,
                                            arrivalCountryName: elementOriginDestinationOption.flightSegment[0].arrivalAirport.countryName,
                                            departureDate: elementOriginDestinationOption.flightSegment[0].departureDate,
                                            arrivalDate: elementOriginDestinationOption.flightSegment[0].arrivalDate,
                                            departureTime: elementOriginDestinationOption.flightSegment[0].departureTime,
                                            arrivalTime: elementOriginDestinationOption.flightSegment[0].arrivalTime,
                                            flightNumber: elementOriginDestinationOption.flightSegment[0].flightNumber,
                                            marketingAirlineCode: elementOriginDestinationOption.flightSegment[0].marketingAirline.code,
                                            operatingAirlineCode: elementOriginDestinationOption.flightSegment[0].operatingAirline.code,
                                            directionIndicator: elementOriginDestinationOption.directionInd,
                                            sectorInd: pricedItineraryTempAsc[index].sectorInd,
                                            bookingType: sessionStorage.getItem("tripType") === "oneway" ? "O" : sessionStorage.getItem("tripType") === "roundtrip" ? "R" : "M",
                                            fareType: pricedItineraryTempAsc[index].airItinerary?.airItineraryPricingInfo?.passengerTypeQuantity[0]?.fareInfos !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0] !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType !== undefined ? pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType : '',
                                            international: true,
                                            cabin: elementOriginDestinationOption.flightSegment[0].bookingClassAvails[0].cabinName,
                                            departureTerminal: elementOriginDestinationOption.flightSegment[0].departureAirport.terminal,
                                            arrivalTerminal: elementOriginDestinationOption.flightSegment[0].arrivalAirport.terminal,
                                            checkInBaggage: elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight : elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces : '',
                                            cabinBaggage: elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0] !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight : elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces : '',
                                            airCraftName: elementOriginDestinationOption.flightSegment[0].equipment !== undefined && elementOriginDestinationOption.flightSegment[0].equipment[0].name !== undefined ? elementOriginDestinationOption.flightSegment[0].equipment[0].name : '',
                                            airMiles: elementOriginDestinationOption.flightSegment[0].flownMileageQty,
                                            stops: getStops(pricedItineraryTempAsc[index].airItinerary.originDestinationOptions.originDestinationOption.filter(a => a.directionInd === elementOriginDestinationOption.directionInd)).split(" ")[0],
                                            duration: elementOriginDestinationOption.flightSegment[0].duration !== undefined ? elementOriginDestinationOption.flightSegment[0].duration : '',
                                            layoverTime: elementOriginDestinationOption.flightSegment[0].elapsedLayoverTime !== undefined ? elementOriginDestinationOption.flightSegment[0].elapsedLayoverTime : '',
                                            dayChange: elementOriginDestinationOption.flightSegment[0].dateChangeNbr !== undefined ? elementOriginDestinationOption.flightSegment[0].dateChangeNbr === "1" ? true : false : false,
                                            bookingSourceCode: pricedItineraryTempAsc[index].airItinerary.SourceId,
                                            bookingSourceName: pricedItineraryTempAsc[index].airItinerary.SourceName
                                        }
                                    );
                                });
                    }

                    for (let index = 0; index < 3; index++) {

                        //+ sign before string is unary operator to convert string to number
                        if (+pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount > +onlineFlightFareDetailsTemp[0].totalFare) {
                            continue;
                        }

                        let totalCommoission = 0;
                        pricedItineraryTempAsc[index] !== undefined &&
                            pricedItineraryTempAsc[index].airItineraryPricingInfo !== undefined &&
                            pricedItineraryTempAsc[index].airItineraryPricingInfo.passengerTypeQuantity
                                .forEach(element => {
                                    if (element.commisionInfo[0] !== undefined && element.commisionInfo[0].earnedCommissionAmount !== undefined)
                                        totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);
                                });

                        pricedItineraryTempAsc[index] !== undefined && pricedItineraryTempAsc[index].airItineraryPricingInfo !== undefined &&
                            onlineFlightLowestFareDetailsTemp.push(
                                {
                                    lowestID: index + 1,
                                    sequenceNumber: 1, //pricedItineraryTempAsc[index].sequenceNumber,
                                    currencyCode: pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.currencyCode,
                                    commission: totalCommoission,
                                    baseFare: pricedItineraryTempAsc[index].airItineraryPricingInfo.totalBaseFare.amount,
                                    taxes: pricedItineraryTempAsc[index].airItineraryPricingInfo.totalTax.amount,
                                    totalFare: pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount,
                                    highestFare: pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount,
                                    highestFareInPolicy: pricedItineraryTempAsc.length > 0 ? pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount : "0",
                                    inPolicy: pricedItineraryTempAsc[index].inPolicy,
                                    inPolicyReason: pricedItineraryTempAsc[index].inPolicyReason !== undefined && pricedItineraryTempAsc[index].inPolicyReason !== null && pricedItineraryTempAsc[index].inPolicyReason !== '' ? pricedItineraryTempAsc[index].inPolicyReason : '',
                                    reasonID: justificationCodeFlight.length !== undefined ? justificationCodeFlight : '',
                                    remarks: justificationDetailsFlight.length !== undefined ? justificationDetailsFlight : '',
                                    totalOutBoundDuration: pricedItineraryTempAsc[index].airItinerary.totalOutBoundDuration,
                                    totalInBoundDuration: pricedItineraryTempAsc[index].airItinerary.totalInBoundDuration,
                                    totalItineraryDuration: pricedItineraryTempAsc[index].airItinerary.totalItineraryDuration,
                                    fareRuleType: '',
                                    refundableType: pricedItineraryTempAsc[index].airItineraryPricingInfo.passengerTypeQuantity[0].refundableType
                                }
                            );
                    }
                }
            }

            let totalCost = 0;
            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
                totalCost = parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
            }

            if (travelRequestJSON.data.onlineHotelDetails !== undefined && travelRequestJSON.data.onlineHotelDetails !== null && travelRequestJSON.data.onlineHotelDetails[0].totalRate !== undefined && travelRequestJSON.data.onlineHotelDetails[0].totalRate !== null) {
                totalCost = parseFloat(totalCost) + parseFloat(travelRequestJSON.data.onlineHotelDetails[0].totalRate !== '' ? travelRequestJSON.data.onlineHotelDetails[0].totalRate : 0);
            }

            travelRequestJSON.data.shoppingID = editedServiceInfo.shoppingID;
            travelRequestJSON.data.travellerDetails.totalCost = CustomToFixed(totalCost, 2);
            travelRequestJSON.data.travellerDetails.shoppingID = editedServiceInfo.shoppingID;
            travelRequestJSON.data.travellerDetails.tripReasonID = tripReasonValue;
            travelRequestJSON.data.meetingDetails = meetingCountry.length === undefined ? null : {
                shoppingID: 0,
                countryCode: meetingCountry,
                cityCode: meetingCity,
                meetingStartDate: meetingStartDate,
                meetingEndDate: meetingEndDate,
                meetingStartTime: meetingStartTime,
                meetingEndTime: meetingEndTime,
                purposeID: meetingPurposeOfTravel,
                purposeDetail: meetingPurposeOfTravelText,
                purposeDetails: tripPurposeRemarks,
            }
            travelRequestJSON.data.onlineFlightDetails = onlineFlightDetailsTemp.length !== undefined && onlineFlightDetailsTemp.length !== 0 ? onlineFlightDetailsTemp : null;
            travelRequestJSON.data.onlineFlightFareDetails = onlineFlightFareDetailsTemp.length !== undefined && onlineFlightFareDetailsTemp.length !== 0 ? onlineFlightFareDetailsTemp : null;
            travelRequestJSON.data.onlineFlightLowestDetails = onlineFlightLowestDetailsTemp.length !== undefined && onlineFlightLowestDetailsTemp.length !== 0 ? onlineFlightLowestDetailsTemp : null;
            travelRequestJSON.data.onlineFlightLowestFareDetails = onlineFlightLowestFareDetailsTemp && onlineFlightLowestFareDetailsTemp.length ? onlineFlightLowestFareDetailsTemp : null;
            travelRequestJSON.data.serviceRequest = (serviceRequests && serviceRequests.length) ? serviceRequests : null;

            travelRequestJSON.data.travelRequestDocuments = tempTravelRequestDocuments && tempTravelRequestDocuments.length ? tempTravelRequestDocuments : null
            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
                travelRequestJSON.data.bookingData.push(
                    {
                        serviceCode: 'FL',
                        bookingJSON: JSON.stringify(bookingJSON.data)
                    }
                );
            }

            //set the sceen location on almost page top when travel request will submit            
            if (valueParam.ref) {
                window.scrollTo({
                    top: 120,
                    behavior: "smooth",
                });
                sendUpdateServiceRequest(travelRequestJSON);
            }
            else {
                window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                });
                sendRequestTravelRequest(travelRequestJSON);
            }

            //console.log('Request Size in bytes', Buffer.from(JSON.stringify(travelRequestJSON.data.travelRequestDocuments)).length);

            //Empty the documents object before assigning it to local storage. (Hint: session/local storage has limited storage)
            travelRequestJSON.data.travelRequestDocuments = null;
            localStorage.setItem("TravelRequestInput", JSON.stringify(travelRequestJSON));
        }
    }

    function toggleSubmitButton(tabOption) {

        let shouldShow = isRequestPresent();
        if (tabOption && tabOption === 'Approver') {
            shouldShow = false;
        }

        let btnSubmit = document.getElementById('btnSubmit');
        let btnReset = document.getElementById('btnReset');

        if (shouldShow) {
            if (btnSubmit) {
                btnSubmit.classList.remove("d-none");
            }

            if (btnReset) {
                btnReset.classList.remove("d-none");
            }
        }
        else {
            if (btnSubmit) {
                btnSubmit.classList.add("d-none");
            }

            if (btnReset) {
                btnReset.classList.add("d-none");
            }
        }
    }

    function toggleControlsOnFlightTabChange() {
        if (document.getElementById('tabButton_Flight') !== null) {
            document.getElementById('tabButton_Flight').classList.add("active");
        }
        if (document.getElementById('tabArea_Flight') !== null) {
            document.getElementById('tabArea_Flight').classList.add("active");
            if (!document.getElementById('tabArea_Flight').classList.toString().includes('show')) {
                document.getElementById('tabArea_Flight').classList.add("show");
            }
        }

        if (document.getElementById('tabButton_Hotel') !== null) {
            document.getElementById('tabButton_Hotel').classList.remove("active");
        }
        if (document.getElementById('tabArea_Hotel') !== null) {
            document.getElementById('tabArea_Hotel').classList.remove("active");
        }

        if (document.getElementById('tabButton_ServiceRequest') !== null) {
            document.getElementById('tabButton_ServiceRequest').classList.remove("active");
        }
        if (document.getElementById('tabArea_ServiceRequest') !== null) {
            document.getElementById('tabArea_ServiceRequest').classList.remove("active");
        }

        if (document.getElementById('tabButton_Approver') !== null) {
            document.getElementById('tabButton_Approver').classList.remove("active");
        }
        if (document.getElementById('tabArea_Approver') !== null) {
            document.getElementById('tabArea_Approver').classList.remove("active");
        }

        if (document.getElementById('tabButton_Document') !== null) {
            document.getElementById('tabButton_Document').classList.remove("active");
        }
        if (document.getElementById('tabArea_Document') !== null) {
            document.getElementById('tabArea_Document').classList.remove("active");
        }

        if (document.getElementById('tabButton_MeetingDetails') !== null) {
            document.getElementById('tabButton_MeetingDetails').classList.remove("active");
        }
        if (document.getElementById('tabArea_MeetingDetails') !== null) {
            document.getElementById('tabArea_MeetingDetails').classList.remove("active");
        }
    }

    function toggleControlsOnHotelTabChange() {
        if (document.getElementById('tabButton_Flight') !== null) {
            document.getElementById('tabButton_Flight').classList.remove("active");
        }
        if (document.getElementById('tabArea_Flight') !== null) {
            document.getElementById('tabArea_Flight').classList.remove("active");
        }

        if (document.getElementById('tabButton_Hotel') !== null) {
            document.getElementById('tabButton_Hotel').classList.add("active");
        }
        if (document.getElementById('tabArea_Hotel') !== null) {
            document.getElementById('tabArea_Hotel').classList.add("active");
            if (!document.getElementById('tabArea_Hotel').classList.toString().includes('show')) {
                document.getElementById('tabArea_Hotel').classList.add("show");
            }
        }

        if (document.getElementById('tabButton_ServiceRequest') !== null) {
            document.getElementById('tabButton_ServiceRequest').classList.remove("active");
        }
        if (document.getElementById('tabArea_ServiceRequest') !== null) {
            document.getElementById('tabArea_ServiceRequest').classList.remove("active");
        }

        if (document.getElementById('tabButton_Approver') !== null) {
            document.getElementById('tabButton_Approver').classList.remove("active");
        }
        if (document.getElementById('tabArea_Approver') !== null) {
            document.getElementById('tabArea_Approver').classList.remove("active");
        }

        if (document.getElementById('tabButton_Document') !== null) {
            document.getElementById('tabButton_Document').classList.remove("active");
        }
        if (document.getElementById('tabArea_Document') !== null) {
            document.getElementById('tabArea_Document').classList.remove("active");
        }

        if (document.getElementById('tabButton_MeetingDetails') !== null) {
            document.getElementById('tabButton_MeetingDetails').classList.remove("active");
        }
        if (document.getElementById('tabArea_MeetingDetails') !== null) {
            document.getElementById('tabArea_MeetingDetails').classList.remove("active");
        }
    }

    function toggleControlsOnServiceTabChange() {
        if (document.getElementById('tabButton_Flight') !== null) {
            document.getElementById('tabButton_Flight').classList.remove("active");
        }
        if (document.getElementById('tabArea_Flight') !== null) {
            document.getElementById('tabArea_Flight').classList.remove("active");
        }

        if (document.getElementById('tabButton_Hotel') !== null) {
            document.getElementById('tabButton_Hotel').classList.remove("active");
        }
        if (document.getElementById('tabArea_Hotel') !== null) {
            document.getElementById('tabArea_Hotel').classList.remove("active");
        }

        if (document.getElementById('tabButton_ServiceRequest') !== null) {
            document.getElementById('tabButton_ServiceRequest').classList.add("active");
        }
        if (document.getElementById('tabArea_ServiceRequest') !== null) {
            document.getElementById('tabArea_ServiceRequest').classList.add("active");
            if (!document.getElementById('tabArea_ServiceRequest').classList.toString().includes('show')) {
                document.getElementById('tabArea_ServiceRequest').classList.add("show");
            }
        }

        if (document.getElementById('tabButton_Approver') !== null) {
            document.getElementById('tabButton_Approver').classList.remove("active");
        }
        if (document.getElementById('tabArea_Approver') !== null) {
            document.getElementById('tabArea_Approver').classList.remove("active");
        }

        if (document.getElementById('tabButton_Document') !== null) {
            document.getElementById('tabButton_Document').classList.remove("active");
        }
        if (document.getElementById('tabArea_Document') !== null) {
            document.getElementById('tabArea_Document').classList.remove("active");
        }

        if (document.getElementById('tabButton_MeetingDetails') !== null) {
            document.getElementById('tabButton_MeetingDetails').classList.remove("active");
        }
        if (document.getElementById('tabArea_MeetingDetails') !== null) {
            document.getElementById('tabArea_MeetingDetails').classList.remove("active");
        }
    }

    function toggleControlsOnApproverTabChange() {
        if (document.getElementById('tabButton_Flight') !== null) {
            document.getElementById('tabButton_Flight').classList.remove("active");
        }
        if (document.getElementById('tabArea_Flight') !== null) {
            document.getElementById('tabArea_Flight').classList.remove("active");
        }

        if (document.getElementById('tabButton_Hotel') !== null) {
            document.getElementById('tabButton_Hotel').classList.remove("active");
        }
        if (document.getElementById('tabArea_Hotel') !== null) {
            document.getElementById('tabArea_Hotel').classList.remove("active");
        }

        if (document.getElementById('tabButton_ServiceRequest') !== null) {
            document.getElementById('tabButton_ServiceRequest').classList.remove("active");
        }
        if (document.getElementById('tabArea_ServiceRequest') !== null) {
            document.getElementById('tabArea_ServiceRequest').classList.remove("active");
        }

        if (document.getElementById('tabButton_Approver') !== null) {
            document.getElementById('tabButton_Approver').classList.add("active");
        }
        if (document.getElementById('tabArea_Approver') !== null) {
            document.getElementById('tabArea_Approver').classList.add("active");
            if (!document.getElementById('tabArea_Approver').classList.toString().includes('show')) {
                document.getElementById('tabArea_Approver').classList.add("show");
            }
        }

        if (document.getElementById('tabButton_Document') !== null) {
            document.getElementById('tabButton_Document').classList.remove("active");
        }
        if (document.getElementById('tabArea_Document') !== null) {
            document.getElementById('tabArea_Document').classList.remove("active");
        }

        if (document.getElementById('tabButton_MeetingDetails') !== null) {
            document.getElementById('tabButton_MeetingDetails').classList.remove("active");
        }
        if (document.getElementById('tabArea_MeetingDetails') !== null) {
            document.getElementById('tabArea_MeetingDetails').classList.remove("active");
        }
    }

    function toggleControlsTabChangeDynamic(selectedTab) {
        if (document.getElementById('tabButton_Flight') !== null) {
            document.getElementById('tabButton_Flight').classList.remove("active");
        }
        if (document.getElementById('tabArea_Flight') !== null) {
            document.getElementById('tabArea_Flight').classList.remove("active");
        }

        if (document.getElementById('tabButton_Hotel') !== null) {
            document.getElementById('tabButton_Hotel').classList.remove("active");
        }
        if (document.getElementById('tabArea_Hotel') !== null) {
            document.getElementById('tabArea_Hotel').classList.remove("active");
        }

        if (document.getElementById('tabButton_ServiceRequest') !== null) {
            document.getElementById('tabButton_ServiceRequest').classList.remove("active");
        }
        if (document.getElementById('tabArea_ServiceRequest') !== null) {
            document.getElementById('tabArea_ServiceRequest').classList.remove("active");
        }

        if (document.getElementById('tabButton_Document') !== null) {
            document.getElementById('tabButton_Document').classList.remove("active");
        }
        if (document.getElementById('tabArea_Document') !== null) {
            document.getElementById('tabArea_Document').classList.remove("active");
        }

        if (document.getElementById('tabButton_MeetingDetails') !== null) {
            document.getElementById('tabButton_MeetingDetails').classList.remove("active");
        }
        if (document.getElementById('tabArea_MeetingDetails') !== null) {
            document.getElementById('tabArea_MeetingDetails').classList.remove("active");
        }

        if (document.getElementById('tabButton_' + selectedTab) !== null) {
            document.getElementById('tabButton_' + selectedTab).classList.add("active");
        }
        if (document.getElementById('tabArea_' + selectedTab) !== null) {
            document.getElementById('tabArea_' + selectedTab).classList.add("active");
            if (!document.getElementById('tabArea_' + selectedTab).classList.toString().includes('show')) {
                document.getElementById('tabArea_' + selectedTab).classList.add("show");
            }
        }
    }

    function hideTab(tab) {
        if (document.getElementById(`tabButton_${tab}`) !== null) {
            document.getElementById(`tabButton_${tab}`).classList.add("d-none");
            if (document.getElementById(`tabButton_${tab}_parent`) !== null) {
                document.getElementById(`tabButton_${tab}_parent`).classList.add("d-none");
            }
        }

        if (document.getElementById(`tabArea_${tab}`) !== null) {
            document.getElementById(`tabArea_${tab}`).classList.add("d-none");
        }
    }

    function showTab(tab) {
        if (document.getElementById(`tabButton_${tab}`) !== null) {
            document.getElementById(`tabButton_${tab}`).classList.add("active");
            if (document.getElementById(`tabButton_${tab}_parent`) !== null) {
                document.getElementById(`tabButton_${tab}_parent`).classList.remove("d-none");
            }
        }
        if (document.getElementById(`tabArea_${tab}`) !== null) {
            document.getElementById(`tabArea_${tab}`).classList.add("active");
        }
        if (document.getElementById(`tabArea_${tab}`) !== null) {
            if (!document.getElementById(`tabArea_${tab}`).classList.toString().includes('show')) {
                document.getElementById(`tabArea_${tab}`).classList.add("show");
            }
        }
    }

    function onReset() {
        sessionStorage.removeItem("travelRequestFieldsDataJson");
        sessionStorage.removeItem("travelreqflightsearch");
        sessionStorage.removeItem("travelreqhotelsearch");
        localStorage.removeItem("BookingDataTravelRequest");
        localStorage.removeItem("TravelRequestInput");
        history.push('/TravelRequest');
        history.go();
    }

    const errorHandler = () => {
        setError(null);

        if (travelRequestSubmitHasError) {
            return;
        }

        let targetUrl = '/MyTrips?target=t';

        if (localStorage.getItem("BookingDataTravelRequest") !== null) {
            if (loadedTravelRequest !== null && loadedTravelRequest.data !== null && loadedTravelRequest.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null && loadedPriceCheckResponse.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else if (valueParam.ref) {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else {
                toggleSubmitButton();
            }
        }
        else {
            if (loadedTravelRequest !== null && loadedTravelRequest.data !== null && loadedTravelRequest.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null && loadedPriceCheckResponse.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else if (valueParam.ref) {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push(targetUrl);
            }
            else {
                toggleSubmitButton();
            }
        }
    };

    function okHandler() {
        setConfirm(false);

        //Paul: Code likely redundant : START

        toggleSubmitButton(selectedTab);

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    setTripReasonValue(travelRequestFieldsDataJson.tripReason);
                    sessionStorage.removeItem("spnClass1");
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    hideTab('Flight');
                }

                if (isHotel === false) {
                    hideTab('Hotel');
                }

                if (isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    hideTab('MeetingDetails');
                }

                toggleMeetingDetailsMandatoryIndicator();
            }

            if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'flight') {
                onTabChangeHandler('Flight');
                toggleControlsOnFlightTabChange();
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'hotel') {
                onTabChangeHandler('Hotel');
                toggleControlsOnHotelTabChange();
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'servicerequest') {
                onTabChangeHandler('ServiceRequest');
                toggleControlsOnServiceTabChange();
            }
            else {
                if (selectedTab !== undefined && selectedTab !== '') {
                    if (selectedTab === 'Flight') {
                        onTabChangeHandler('Approver');
                        toggleControlsOnApproverTabChange();
                    }
                    else {
                        onTabChangeHandler(selectedTab);
                        toggleControlsTabChangeDynamic(selectedTab);
                    }
                }
                else {
                    onTabChangeHandler('Approver');
                    toggleControlsOnApproverTabChange();
                }
            }
        }
        else {
            let travelRequestFieldsDataJson = "";
            if (tripReasonOptions !== undefined && tripReasonOptions[0] !== undefined) {
                setTripReasonValue(tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
                sessionStorage.removeItem("spnClass1");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.tripReason = tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    hideTab('Flight');
                }

                if (isHotel === false) {
                    hideTab('Hotel');
                }

                if (isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    hideTab('MeetingDetails');
                }

                toggleMeetingDetailsMandatoryIndicator();
            }

            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight && !valueParam.ref) {
                    onTabChangeHandler('Flight');
                    toggleControlsOnFlightTabChange();
                } else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel && !valueParam.ref) {
                    onTabChangeHandler('Hotel');
                    toggleControlsOnHotelTabChange();
                } else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest) {
                    onTabChangeHandler('ServiceRequest');
                    toggleControlsOnServiceTabChange();
                }
                else {
                    onTabChangeHandler('Flight');
                    toggleControlsOnFlightTabChange();
                }
            }
            else {
                onTabChangeHandler('Flight');
                toggleControlsOnFlightTabChange();
            }
        }

        //Paul: Code likely redundant : END

        //Considering page will be redirected at the end, above codes are most likely redundant.
        //However, since it is triggered from change in fare, which is difficult to replicate, leaving the code as-is for now.        

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))

        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push('/SearchHotel?addhotel=1');
        }
        else {
            history.push(BOOKING);
        }
    };

    function cancelHandler() {
        setConfirm(false);
        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            history.push({
                pathname: location.pathname,
                search: '?return=1&service=' + valueParam.service
            });
        }
        else if (valueParam.ref) {
            history.push({
                pathname: location.pathname,
                search: '?ref=' + valueParam.ref
            });
        }
        else {
            history.push({
                pathname: location.pathname
            });
        }
        onTabChangeHandler('Flight');
    };

    function isRequestPresent() {
        let isFlightRequestPresent = false;
        let isHotelRequestPresent = false;
        let isServiceRequestPresent = false;

        if (sessionStorage.getItem("travelreqflightsearch")) {
            isFlightRequestPresent = true;
        }

        if (sessionStorage.getItem("travelreqhotelsearch")) {
            isHotelRequestPresent = true;
        }

        if (sessionStorage.getItem("travelRequestFieldsDataJson")) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length) {
                isServiceRequestPresent = true;
            }
        }

        return isFlightRequestPresent || isHotelRequestPresent || isServiceRequestPresent;
    }

    function toggleTripReason() {
        if (isRequestPresent()) {
            setTripTypeDisabled(true);
        }
        else {
            setTripTypeDisabled(false);
        }
    }

    function toggleMeetingDetailsMandatoryIndicator() {
        if (tripReasonList.length) {
            let inputs = document.getElementsByClassName('meeting');
            if (inputs.length) {
                let innerHtml = " *";

                //1=Hidden, 2=Mandatory, 3=Optional
                if (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3") {
                    innerHtml = "";
                }

                for (let indicator of inputs) {
                    indicator.innerHTML = innerHtml;
                }
            }
        }
    }

    let loadedData = [];
    if (localStorage.getItem("loginData") !== null) {

        let tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));

        loadedData = tripReasonAndPolicyDataJson.tripReason;
        if (loadedData !== null) {
            loadedData.forEach(element => {
                tripReasonOptions.push({ value: element.id, label: element.name });
            });
        }
    }

    function clearSessionValues() {
        sessionStorage.removeItem("travelRequestFieldsDataJson");
        sessionStorage.removeItem("travelreqflightsearch");
        sessionStorage.removeItem("travelreqhotelsearch");
        localStorage.removeItem("BookingDataTravelRequest");
        localStorage.removeItem("TravelRequestInput");
        sessionStorage.removeItem("hotelSessionDataJson");
        deleteAllDocuments();
        const travelRequestFieldsDataJson = ({
            justificationCodeFlight: {},
            justificationDetailsFlight: {},
            justificationCodeHotel: {},
            justificationDetailsHotel: {},
            serviceRequestId: {},
            serviceRequestName: {},
            remarks: '',
            existingConfirmationNumber: {},
            totalRateOfService: {},
            travelRequestDocuments: {},
            travelRequestDocumentsRemarks: {},
            meetingCountry: '',
            meetingCity: '',
            meetingStartDate: '',
            meetingEndDate: '',
            meetingStartTime: '',
            meetingEndTime: '',
            meetingPurposeOfTravel: '',
            meetingPurposeOfTravelText: '',
            tripReason: '',
            tripPurposeRemarks: '',
            documentRemarks: ''
        });
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        toggleTripReason();
        //refresh hotel, service & meeting details components
        setHotelRequestKey(key => key + 1);
        setServiceRequestKey(key => key + 1);
        setMeetingDetailsKey(key => key + 1);
    }

    function getMessageAction() {
        let action = 'import';
        const isFlightRequestPresent = JSON.parse(sessionStorage.getItem('travelreqflightsearch')) || false;
        if (!isFlightRequestPresent) {
            action = 'requestTypeOf';
        }

        return action;
    }

    useEffect(() => {
        if (valueParam.ref) {
            sendDecryptionRequest(valueParam.ref.replaceAll(' ', '+'));
        }

    }, [sendRetrieveTravelRequest, valueParam.ref]);

    useEffect(() => {
        if (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') {
            clearSessionValues();
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    setTripReasonValue(travelRequestFieldsDataJson.tripReason);
                    sessionStorage.removeItem("spnClass1");
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    hideTab('Flight');
                }

                if (isHotel === false) {
                    hideTab('Hotel');
                }

                if (isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    hideTab('MeetingDetails');
                }

                toggleMeetingDetailsMandatoryIndicator();
            }

            if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'flight' && !valueParam.ref) {
                onTabChangeHandler('Flight');
                toggleControlsOnFlightTabChange();
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'hotel' && !valueParam.ref) {
                onTabChangeHandler('Hotel');
                toggleControlsOnHotelTabChange();
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'servicerequest') {
                onTabChangeHandler('ServiceRequest');
                toggleControlsOnServiceTabChange();
            }
            else {
                if (selectedTab !== undefined && selectedTab !== '') {
                    if (selectedTab === 'Flight' && !valueParam.ref) {
                        onTabChangeHandler('Approver');
                        toggleControlsOnApproverTabChange();
                    }
                    else {
                        onTabChangeHandler(selectedTab);
                        toggleControlsTabChangeDynamic(selectedTab);
                    }
                }
                else {
                    onTabChangeHandler('Approver');
                    toggleControlsOnApproverTabChange();
                }
            }
        }
        else {
            let travelRequestFieldsDataJson = "";
            if (tripReasonOptions !== undefined && tripReasonOptions[0] !== undefined) {
                setTripReasonValue(tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
                sessionStorage.removeItem("spnClass1");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.tripReason = tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] : tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            if (searchPolicyResponseTemp?.policy !== null) {
                searchPolicyResponse = searchPolicyResponseTemp?.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    hideTab('Flight');
                }

                if (isHotel === false) {
                    hideTab('Hotel');
                }

                if (isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    hideTab('MeetingDetails');
                }

                toggleMeetingDetailsMandatoryIndicator();
            }

            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight && !valueParam.ref) {
                    onTabChangeHandler('Flight');
                    toggleControlsOnFlightTabChange();
                } else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel && !valueParam.ref) {
                    onTabChangeHandler('Hotel');
                    toggleControlsOnHotelTabChange();
                } else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest) {
                    onTabChangeHandler('ServiceRequest');
                    toggleControlsOnServiceTabChange()
                }
                else {
                    onTabChangeHandler('Flight');
                    toggleControlsOnFlightTabChange();
                }
            }
            else {
                onTabChangeHandler('Flight');
                toggleControlsOnFlightTabChange();
            }
        }

    }, [valueParam.return, valueParam.ref]);

    useEffect(() => {
        if (statusTravelRequest === 'completed' || updateServiceStatus === 'completed') {
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });

            const trSavingResponse = loadedTravelRequest || updateServiceResponse;

            if (trSavingResponse && trSavingResponse.data !== null) {

                if (trSavingResponse.data.errors.status === 'TRUE') {
                    setTravelRequestSubmitHasError(true);
                    setError({
                        title: t('labelMessage'),
                        message: trSavingResponse.data.errors.error?.description || t('errorRecordSave')
                    });
                }
                else {
                    // made travelRequestDocuments node is null in travel request out put json to reduce json weight
                    if (trSavingResponse.data.travelRequestDocuments !== undefined && trSavingResponse.data.travelRequestDocuments !== null) {
                        trSavingResponse.data.travelRequestDocuments = null;
                    }
                    localStorage.setItem("TravelRequestOutput", JSON.stringify(trSavingResponse));

                    if (localStorage.getItem("BookingDataTravelRequest") !== null) {
                        localStorage.removeItem("SeatMapData");
                        localStorage.removeItem("AdditionalServicesData");
                        sessionStorage.removeItem("hotelSessionDataJson");
                        sessionStorage.removeItem("singlePnrCheckOutMinvalue");

                        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
                        let soureceForPriceCheck = false;
                        let source = bookingJSON.data.airItinerary[0].SourceName !== undefined ? bookingJSON.data.airItinerary[0].SourceName : '';

                        // if (getConfigurationByBoolen("ALLOW_NDC_PRICE_REVALIDATE") && source.trim().toUpperCase() === "NDC") {
                        //     soureceForPriceCheck = true;
                        // } else if (getConfigurationByBoolen("ALLOW_SABRE_PRICE_REVALIDATE") && source.trim().toUpperCase() === "SABRE") {
                        //     soureceForPriceCheck = true;
                        // }

                        if (soureceForPriceCheck) {
                            sendRequest(bookingJSON);
                        }
                        else {
                            history.push(BOOKING);
                        }
                    }
                    else {

                        if (valueParam.ref) {
                            setError({
                                title: t('labelMessage'),
                                message: t('successRecordUpdateServiceRequest').replace('REF_NO', editedServiceInfo.referenceNumber)
                            });
                        }
                        else {
                            setError({
                                title: t('labelMessage'),
                                message: t('successRecordSaveTravelRequest').replace('REF_NO', loadedTravelRequest.data.referenceNumber)
                            });
                        }
                    }
                }
            }
            else {

                setTravelRequestSubmitHasError(true);

                setError({
                    title: t('labelMessage'),
                    message: t('errorRecordSave'),
                });
            }

            return;
        }

    }, [statusTravelRequest, loadedTravelRequest, updateServiceStatus, updateServiceResponse]);

    useEffect(() => {
        if (localStorage.getItem("loginData") !== null) {
            if (!valueParam.ref) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            let travelRequestFieldsDataJson = null;
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            }

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] :
                    tripReasonAndPolicyDataJson.tripReason
                        .filter(a => a.id === (tripReasonValue ? tripReasonValue :
                            travelRequestFieldsDataJson?.tripReason ? travelRequestFieldsDataJson.tripReason :
                                tripReasonOptions[0].value));
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));

                let tripReasonToFilter = "";
                if (travelRequestFieldsDataJson !== null) {
                    if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                        tripReasonToFilter = travelRequestFieldsDataJson.tripReason;
                    }
                    else {
                        tripReasonToFilter = tripReasonValue ? tripReasonValue : tripReasonOptions[0].value;
                    }
                }
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === tripReasonToFilter);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    hideTab('Flight');
                }

                if (isHotel === false) {
                    hideTab('Hotel');
                }

                if (isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    hideTab('Flight');
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    hideTab('Hotel');
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    hideTab('ServiceRequest');
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    hideTab('MeetingDetails');
                }

                toggleMeetingDetailsMandatoryIndicator();
            }

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                let serviceTemp = valueParam.service === 'flight' ? 'Flight' : valueParam.service === 'hotel' ? 'Hotel' : 'Flight';
                onTabChangeHandler(serviceTemp);
                toggleControlsTabChangeDynamic(serviceTemp);
            }
            else {
                if (searchPolicyResponse.length > 0) {
                    if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true && !valueParam.ref) {
                        onTabChangeHandler('Flight');
                        toggleControlsOnFlightTabChange();
                    }
                    else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel === true && !valueParam.ref) {
                        onTabChangeHandler('Hotel');
                        toggleControlsOnHotelTabChange();
                    }
                    else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest === true) {
                        onTabChangeHandler('ServiceRequest');
                        toggleControlsOnServiceTabChange();
                    }
                    else {
                        if (selectedTab !== undefined && selectedTab !== '') {
                            if (selectedTab === 'Flight' && !valueParam.ref) {
                                onTabChangeHandler('Approver');
                                toggleControlsOnApproverTabChange();
                            }
                            else {
                                onTabChangeHandler(selectedTab);
                                toggleControlsTabChangeDynamic(selectedTab);
                            }
                        }
                        else {
                            onTabChangeHandler('Approver');
                            toggleControlsOnApproverTabChange();
                        }
                    }
                }
                else {
                    if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false && valueParam.ref) {
                        hideTab('Flight');
                    }

                    if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false && valueParam.ref) {
                        hideTab('Hotel');
                    }

                    if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                        hideTab('ServiceRequest');
                    }
                }
            }

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                history.push({
                    pathname: location.pathname,
                    search: '?return=1&service=' + valueParam.service
                });
            }
            else if (valueParam.ref) {
                history.push({
                    pathname: location.pathname,
                    search: '?ref=' + valueParam.ref
                });
            }
            else {
                history.push({
                    pathname: location.pathname
                });
            }
        }

        setTimeout(() => {
            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                if (localStorage.getItem('BookingDataTravelRequest') !== null || localStorage.getItem('TravelRequestInput') !== null) {
                    toggleTripReason();
                    window.scrollTo({
                        top: 500,
                        behavior: "smooth",
                    });
                }
            }
        }, 1000);
    }, []);

    useEffect(() => {
        if (status === 'completed') {
            if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null) {

                if (loadedPriceCheckResponse.data.errors.status === 'TRUE') {
                    setError({
                        title: t('labelMessage'),
                        message: loadedPriceCheckResponse.data.errors.error.description,
                    });
                }
                else {
                    if (loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.priceChange) {
                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                        setConfirm({
                            title: t('labelMessage'),
                            message: t('errorPriceChange')
                                .replace('FROM_PRICE', new Intl.NumberFormat('en-US', {
                                    style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
                                }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount))
                                .replace('TO_PRICE', new Intl.NumberFormat('en-US', {
                                    style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
                                }).format(loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount))
                        });
                    } else {
                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
                        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
                        history.push(BOOKING);
                    }
                }
            }
            else {
                setError({
                    title: t('labelMessage'),
                    message: t('errorGeneric'),
                });
            }
            return;
        }
    }, [status, history, loadedPriceCheckResponse]);

    useEffect(() => {
        let searchPolicyResponse = "";
        let tripReasonAndPolicyDataJson = "";

        if (localStorage.getItem("loginData") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] :
                tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === ((tripReasonValue !== undefined &&
                    tripReasonValue !== null && tripReasonValue !== '') ? tripReasonValue : tripReasonOptions[0].value));
        }

        if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            let travelRequestFieldsDataJson = "";
            let tripReasonToFilter = "";

            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    tripReasonToFilter = travelRequestFieldsDataJson.tripReason;
                }
                else {
                    tripReasonToFilter = tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                }
            }
            searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === tripReasonToFilter);
        }

        let isFlight = false;
        let isHotel = false;
        let isServiceRequest = false;

        searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
            if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                isFlight = true;
            }
            if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                isHotel = true;
            }
            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                isServiceRequest = true;
            }
        });

        if (searchPolicyResponse.length > 0) {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true) {
                if (document.querySelector('#dvTravellers') === null) {
                    if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                        history.push({
                            pathname: location.pathname,
                            search: '?return=1&service=' + valueParam.service
                        });
                    }
                    else if (valueParam.ref) {
                        history.push({
                            pathname: location.pathname,
                            search: '?ref=' + valueParam.ref
                        });
                    }
                    else {
                        history.push({
                            pathname: location.pathname
                        });
                    }
                }
            }
        }
        else {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                hideTab('Flight');
            }

            if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                hideTab('Hotel');
            }

            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                hideTab('ServiceRequest');
            }
        }

    }, [tripReasonValue]);

    useEffect(() => {
        if (decryptionResponse) {
            if (decryptionResponse.data) {
                sendRetrieveTravelRequest(decryptionResponse.data);
            }
            else {
                setIsTravelRequestRetrievalInProgress(false);
                //error handling
                setError({
                    title: t('labelMessage'),
                    message: t('errorGeneric')
                });
            }
        }
    }, [decryptionResponse]);

    useEffect(() => {
        if (retrieveTravelRequestResponse) {

            setIsTravelRequestRetrievalInProgress(false);

            if (retrieveTravelRequestResponse.data && retrieveTravelRequestResponse.data.errors.status === 'FALSE') {
                if (retrieveTravelRequestResponse.data.serviceRequest && retrieveTravelRequestResponse.data.serviceRequest.length) {
                    onTabChangeHandler('ServiceRequest');
                    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                        travelRequestFieldsDataJson.serviceRequests = [];

                        if (retrieveTravelRequestResponse.data.serviceRequest && retrieveTravelRequestResponse.data.serviceRequest.length > 0) {
                            retrieveTravelRequestResponse.data.serviceRequest.forEach(request => {
                                travelRequestFieldsDataJson.serviceRequests.push({
                                    ...request,
                                    //Remove attachment data since it's not required, and also, it will overflow session storage limit
                                    servicesAttachment: null
                                });
                            });
                        }
                        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));

                        setServiceRequestKey(key => key + 1);
                        toggleTripReason();
                        toggleSubmitButton();
                    }
                }

                setEditedServiceInfo({
                    shoppingID: retrieveTravelRequestResponse.data.shoppingID,
                    referenceNumber: retrieveTravelRequestResponse.data.referenceNumber,
                    approvalMasterID: retrieveTravelRequestResponse.data.travellerDetails.approvalMasterID,
                    officeID: retrieveTravelRequestResponse.data.travellerDetails.officeID
                });
            }
            else {
                //error handling
                setError({
                    title: t('labelMessage'),
                    message: t('errorGeneric')
                });
            }
        }
    }, [retrieveTravelRequestResponse]);

    return (
        <Fragment>
            {error1 && (
                <ErrorModal
                    title={error1.title}
                    message={error1.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            {confirm && (
                <ConfirmationModal
                    title={confirm.title}
                    message={confirm.message}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}

            {/*     <!-- Common Banner Area --> */}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>
            {(statusTravelRequest === 'pending' || status === 'pending') &&
                <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mt-5">
                                    <div className="row  position-relative gt-h-255 my-auto">
                                        <BookingLoadingSpinner action={getMessageAction()}></BookingLoadingSpinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*   <!-- Form Area --> */}
            {/*Status based rendering not used because it causes all the child states to be lost which is creating problems*/}
            <section id="theme_search_form_tour"
                className={(statusTravelRequest !== 'pending' && status !== 'pending') ?
                    "fligth_top_search_main_form_wrapper" : "fligth_top_search_main_form_wrapper d-none"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area position-relative my-2">
                                <div className="row position-relative">
                                    {isTravelRequestRetrievalInProgress &&
                                        <LoadingSpinner></LoadingSpinner>
                                    }
                                    {updateServiceStatus === 'pending' &&
                                        <BookingLoadingSpinner action="service"></BookingLoadingSpinner>
                                    }
                                    <div className="col-lg-12">
                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Travel Request</label>
                                        <div className="tour_search_form">
                                            <form action="!#">
                                                <div className='row mb-4'>
                                                    <div className='col-md-4 col-sm-12 col-12'>
                                                        {tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' &&
                                                            <>
                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor="tripReason">{t('labelTripType')}<span className="error-icon"> *</span></label>
                                                                <div className="dropdown pb-2 col-md-12 position-relative">
                                                                    <Dropdown onChange={tripReasonChangeHandler}
                                                                        value={tripReasonOptions.filter(option => option.value === tripReasonValue)}
                                                                        options={tripReasonOptions}
                                                                        textField="value"
                                                                        dataItemKey="label"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                        isDisabled={tripTypeDisabled}
                                                                    />
                                                                    {tripReasonInputHasError &&
                                                                        <p className="error-text">{t('errorTripTypeRequired')}</p>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 pop-ups-tab-border-parent">
                                                        <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                            {!valueParam.ref &&
                                                                <Fragment>
                                                                    <li className="nav-item pb-0" role="presentation" id='tabButton_Flight_parent'>
                                                                        <button className="tabs-btn nav-link tab-width-150 active text-center" id='tabButton_Flight' data-bs-toggle="tab"
                                                                            data-bs-target='#tabArea_Flight' type="button" role="tab" aria-controls='#tabArea_Flight'
                                                                            aria-selected="true" onClick={(e) => onTabChangeHandler('Flight')}><i className="fas fa-plane-departure"></i><br></br> {t('labelFlight')}</button>
                                                                    </li>
                                                                    <li className="nav-item pb-0" role="presentation" id='tabButton_Hotel_parent'>
                                                                        <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Hotel' data-bs-toggle="tab"
                                                                            data-bs-target='#tabArea_Hotel' type="button" role="tab" aria-controls='#tabArea_Hotel'
                                                                            aria-selected="true" onClick={(e) => onTabChangeHandler('Hotel')}><i className="fas fa-hotel"></i><br></br> {t('labelHotel')}</button>
                                                                    </li>
                                                                </Fragment>
                                                            }
                                                            <li className="nav-item pb-0" role="presentation" id='tabButton_ServiceRequest_parent'>
                                                                <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_ServiceRequest' data-bs-toggle="tab"
                                                                    data-bs-target='#tabArea_ServiceRequest' type="button" role="tab" aria-controls='#tabArea_ServiceRequest'
                                                                    aria-selected="true" onClick={(e) => onTabChangeHandler('ServiceRequest')}><i className="fas fa-cog"></i><br></br> {t('labelServiceRequest')} </button>
                                                            </li>

                                                            {!valueParam.ref &&
                                                                <Fragment>
                                                                    <li className="nav-item pb-0" role="presentation">
                                                                        <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Approver' data-bs-toggle="tab"
                                                                            data-bs-target='#tabArea_Approver' type="button" role="tab" aria-controls='#tabArea_Approver'
                                                                            aria-selected="true" onClick={(e) => onTabChangeHandler('Approver')}><i className="fas fa-user"></i><br></br> {t('labelApprover')}</button>
                                                                    </li>
                                                                    <li className="nav-item pb-0" role="presentation">
                                                                        <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Document' data-bs-toggle="tab"
                                                                            data-bs-target='#tabArea_Document' type="button" role="tab" aria-controls='#tabArea_Document'
                                                                            aria-selected="true" onClick={(e) => onTabChangeHandler('Document')}><i className="fas fa-file"></i><br></br> {t('labelDocuments')}</button>
                                                                    </li>
                                                                    <li className="nav-item pb-0" role="presentation">
                                                                        <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_MeetingDetails' data-bs-toggle="tab"
                                                                            data-bs-target='#tabArea_MeetingDetails' type="button" role="tab" aria-controls='#tabArea_MeetingDetails'
                                                                            aria-selected="true" onClick={(e) => onTabChangeHandler('MeetingDetails')}><i className="fas fa-handshake"></i><br></br> {t('labelMeetingDetail')}</button>
                                                                    </li>
                                                                </Fragment>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <div className="px-3 tab-content include-exclude-tab">
                                                            <div className="tab-pane fade show active" id='tabArea_Flight' role="tabpanel" aria-labelledby="departure">
                                                                <div className=''>
                                                                    <div className=' position-relative'>
                                                                        <div className='row mt-3'>
                                                                            <SearchFlight></SearchFlight>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade show" id='tabArea_Hotel' role="tabpanel" aria-labelledby="departure">
                                                                <div className=''>
                                                                    <div className=' position-relative'>
                                                                        <div className='row mt-3'>
                                                                            <SearchHotel refreshKey={hotelRequestKey}>
                                                                            </SearchHotel>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade show" id='tabArea_ServiceRequest' role="tabpanel" aria-labelledby="departure">
                                                                <div className=''>
                                                                    <div className=' position-relative'>
                                                                        <div className='row mt-3'>
                                                                            <ServiceRequest key={serviceRequestKey}
                                                                                editedServiceInfo={editedServiceInfo}
                                                                                toggleTripReason={toggleTripReason}
                                                                            >
                                                                            </ServiceRequest>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!valueParam.ref &&
                                                                <Fragment>
                                                                    <div className="tab-pane fade show" id='tabArea_Approver' role="tabpanel" aria-labelledby="departure">
                                                                        <div className=''>
                                                                            <div className=' position-relative'>
                                                                                <div className='row mt-3'>
                                                                                    <Approver></Approver>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade show" id='tabArea_MeetingDetails' role="tabpanel" aria-labelledby="departure">
                                                                        <div className=''>
                                                                            <div className=' position-relative'>
                                                                                <div className='row mt-3'>
                                                                                    <MeetingDetails
                                                                                        prop={1}
                                                                                        refreshKey={meetingDetailsKey}
                                                                                    >
                                                                                    </MeetingDetails>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade show" id='tabArea_Document' role="tabpanel" aria-labelledby="departure">
                                                                        <div className=''>
                                                                            <div className=' position-relative'>
                                                                                <div className='row mt-3'>
                                                                                    <Document></Document>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <p id="requiredIndicator" className='d-none'><span className="error-icon">*</span><span>{t('noteRequiredFields')}</span></p>
                                                <div className="top_form_search_button my-3">
                                                    <button id='btnReset' onClick={onReset} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >{t('labelReset')}</button>
                                                    <button id='btnSubmit' onClick={onSubmit} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >{t('labelSubmit')}</button>
                                                </div>

                                                {selectedTab === 'Flight' && valueParam.return !== undefined &&
                                                    valueParam.return !== '' && valueParam.return === '1' &&
                                                    sessionStorage.getItem("travelreqflightsearch") !== null &&
                                                    sessionStorage.getItem("travelreqflightsearch") === 'true' &&
                                                    localStorage.getItem("BookingDataTravelRequest") !== null &&
                                                    <div><br></br>
                                                        <label htmlFor="" className="h5 text-primary mt-5"> <span className='gt-border-1px'> <i className="fab fa-avianex"></i> {t('labelSelectedFlightDetails')}</span> </label>
                                                        <div className="text-muted h6 mb-2 mt-2 d-none"><b className="text-primary">PNR </b> <span className="text-black">MJCTDX</span> </div>
                                                        <div className='col-md-12 mt-1'>
                                                            <FlightItem
                                                                key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                airItinerary={bookingJSON.data.airItinerary[0]}
                                                                searchFlight={bookingJSON.data.airItinerary[0]}
                                                                bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                                                fareRuleData={bookingJSON.data.airItinerary[0]}
                                                                showData="0"
                                                                hideIcon="0"
                                                                hideCommision="4"
                                                            />

                                                            <div className="tour_details_boxed_inner">
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className="accordion_flex_area">
                                                                        <div className="accordion-item xyz pb-0">
                                                                            <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                                                Price
                                                                            </h5>

                                                                            <div id="collapseOne" className="accordion-collapse collapse show"
                                                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div className='row my-2'>
                                                                                    <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {selectedTab === 'Hotel' && valueParam.return !== undefined &&
                                                    valueParam.return !== '' && valueParam.return === '1' &&
                                                    sessionStorage.getItem("travelreqhotelsearch") !== null &&
                                                    sessionStorage.getItem("travelreqhotelsearch") === 'true' &&
                                                    localStorage.getItem("TravelRequestInput") !== null &&
                                                    <div><br></br>
                                                        <label htmlFor="" className="h5 text-primary mt-5"> <span className="gt-border-1px"> <i className="fas fa-hotel"></i> {t('labelSelectedHotelDetails')}</span> </label>
                                                        <div>
                                                            <div className="hotel-list mt-1 row">
                                                                <HotelDetails hotelBookingJSON={travelRequestJSON}></HotelDetails>
                                                            </div>
                                                            <div className="tour_details_boxed_inner">
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className="accordion_flex_area">
                                                                        <Booking hotelBookingJSON={travelRequestJSON}></Booking>
                                                                    </div>
                                                                    <hr></hr>
                                                                    <div className="accordion_flex_area">
                                                                        <RateDetails hotelBookingJSON={travelRequestJSON} show=""></RateDetails>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>)
};

export default TravelRequest;
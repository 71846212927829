import { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MdModal from '../Flights/MdModal';
import PrintFlightItem from '../../components/Common/Flight/PrintFlightItem';
import FareSummary from '../../components/Common/Flight/FareSummary';
import TravellerInfo from './Components/TravellerInfo';
import FlightItemGds from '../../components/Common/Flight/FlightItemGds';

const BookingConfirmation = (props) => {
    let bookingJSON;
    if (props.page !== undefined && props.page === 'retrieve') {
        bookingJSON = sessionStorage.getItem('isHotelFailed') !== null && sessionStorage.getItem('isHotelFailed') === 'true' ? JSON.parse(localStorage.getItem("BookingData")) : localStorage.getItem("BookingData") !== null ? JSON.parse(localStorage.getItem("BookingData")) : JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
    }
    else if (props.page === 'flightRetrieve') {
        bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"))
    }
    else if (props.page === 'retrieveGds') {
        bookingJSON = JSON.parse(localStorage.getItem("BookingData"))
    }
    else {
        bookingJSON = props.myTripIssueTicket !== undefined && props.myTripIssueTicket !== null && props.myTripIssueTicket === 'y' ? JSON.parse(sessionStorage.getItem("BookingDataIssueTicketJson")) : localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(localStorage.getItem("BookingData"));
    }

    let pnr = bookingJSON.data.airItinerary[0].bookingReferenceID !== undefined ? bookingJSON.data.airItinerary[0].bookingReferenceID[0].id : bookingJSON.data.bookingReferenceID === undefined ? null : bookingJSON.data.bookingReferenceID[0].id;
    let issueTicket = pnr === null ? " Booking Details" : " Booking Confirmation";
    let sendRequest = bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo !== undefined ? bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket : '';

    const [error1, setError] = useState();
    const [isCanceled, setIsCanceled] = useState('');
    const location = useLocation();

    useEffect(() => {
        if (location.mytrip !== undefined) {
            setIsCanceled(location.mytrip.isCanceled);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsCanceled") === 'true' ? true : false)
        }

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])

    const errorHandler = () => {
        setError(null);
    };

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        <div >

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-3">

                                <div className='row'>

                                    {props.page === 'confirmation' && props.hideData !== '1' && <div className='col-md-12'>
                                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket} <span class={(bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S') ? "badge h6 rounded-pill mb-0 bg-primary" : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}>
                                            <i class={bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S' ? "fa-solid fa-circle-check" : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span>
                                            {(bookingJSON.data.requestType === "OF" || bookingJSON.data.airItinerary[0].paymentInfo.ticketingInfo.issueTicket === 'S') ? "Booking Request" : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>

                                    </div>}
                                    {(props.page === 'retrieve' || props.page === 'flightRetrieve') && props.hideData !== '1' && <div className='col-md-8'>
                                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> {issueTicket} <span class={bookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'badge h6 rounded-pill mb-0 bg-success' : "badge h6 rounded-pill mb-0 bg-warning" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={bookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'fa-solid fa-check-circle' : "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {bookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ? 'Canceled' : pnr !== null ? bookingJSON.data.travelerInfo.traveler[0].ticketInfo !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== undefined && bookingJSON.data.travelerInfo.traveler[0].ticketInfo[0].ticketNumber !== '' ? 'Booked' : "On Hold" : "Failed"}</span></label>
                                    </div>}
                                    {(props.page === 'retrieveGds') && props.hideData !== '1' && <div className='col-md-8'>
                                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Booking Details </label>
                                    </div>}
                                </div>


                                <div className='row'>
                                    <div className='col-md-12'>
                                        {props.hideData !== '1' && props.page !== 'retrieveGds' && <div className='col-md-12'>
                                            <div className='text-muted h6 mb-2 mt-2'><b className='text-primary'>Confirmation No. </b> <span className='text-black'>{bookingJSON.data.bookingReferenceNumber}</span>
                                                {pnr !== null && <span> | <b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                        </div>}

                                        {props.hideData !== '1' && props.page === 'retrieveGds' && <div className='col-md-12'>
                                            <div className='text-muted h6 mb-2 mt-2'>
                                                {pnr !== null && <span><b className='text-primary'>PNR </b> <span className='text-black'>{pnr}</span></span>} </div>
                                        </div>}

                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    {
                                        props.page !== 'retrieveGds' &&
                                        <PrintFlightItem
                                            key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            airItinerary={bookingJSON.data.airItinerary[0]}
                                            searchFlight={bookingJSON.data.airItinerary[0]}
                                            fareRuleData={bookingJSON.data.airItinerary[0]}
                                            bookingdata={bookingJSON}
                                        />
                                    }
                                    {
                                        props.page === 'retrieveGds' &&
                                        <FlightItemGds
                                            key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                            airItinerary={bookingJSON.data.airItinerary[0]}
                                            searchFlight={bookingJSON.data.airItinerary[0]}
                                            fareRuleData={bookingJSON.data.airItinerary[0]}
                                            bookingdata={bookingJSON}
                                            showData="3"
                                            hideCommision="4"
                                            printclass="d-flex"
                                        />
                                    }
                                    <div className="tour_details_boxed_inner">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion_flex_area">
                                                <div className="accordion-item">
                                                    <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                        Price
                                                    </h5>

                                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                                        <div className='row my-2'>
                                                            <FareSummary FlightData={bookingJSON.data.airItinerary[0]} hideOtherSevices="1" hideIcon="1"></FareSummary>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                            <TravellerInfo bookingJSON={bookingJSON} print="1"></TravellerInfo>


                                            <div className="accordion_flex_area d-none">
                                                <div className="accordion_left_side">
                                                    <h5>5</h5>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                            aria-expanded="false" aria-controls="collapseThree">
                                                            Internal Notes
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse show"
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className='col-md-3 d-none'>

                                    <div className="left_side_search_area">
                                        <div className="left_side_search_boxed">
                                            <div className="left_side_search_heading mb-3">
                                                <h5>Fare Summary</h5>
                                            </div>
                                            <div className="tour_details_boxed_inner fare-summary">
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion_flex_area ">
                                                        <div className="accordion-item fare-toggle-btn ">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">

                                                                    <div className='ms-4 col-md-7'>Base Fare</div>
                                                                    <div className='right-0 position-absolute'>$40,000</div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne1" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">

                                                                    <div className="accordion_itinerary_list">

                                                                        <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                            <span className="area_flex_one text-muted">
                                                                                <span>Adults(2 * $10,000)</span>
                                                                                <span>$20,000</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="form-check-label text-12px text-muted" for="flexCheckDefaultf1">
                                                                            <span className="area_flex_one">
                                                                                <span>Children(1 * $20,000)</span>
                                                                                <span>$20,000</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion-item fare-toggle-btn">
                                                            <h2 className="accordion-header" id="headingtwo">
                                                                <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">

                                                                    <div className='ms-4 col-md-7'> Taxes and Surcharges</div>
                                                                    <div className='right-0 position-absolute'>$20,000</div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo2" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="accordion_itinerary_list">
                                                                        <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                            <span className="area_flex_one text-muted">
                                                                                <span>Airline Taxes and Surcharges</span>
                                                                                <span>$10,000</span>
                                                                            </span>
                                                                        </label>
                                                                        <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                            <span className="area_flex_one text-muted">
                                                                                <span>Service Fee</span>
                                                                                <span>$10,000</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion_flex_area">
                                                        <div className="accordion-item fare-toggle-btn">
                                                            <h2 className="accordion-header" id="headingthreee">
                                                                <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree3">
                                                                    <div className='ms-4 col-md-7'> Others  </div>
                                                                    <div className='right-0 position-absolute'>$350</div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapsethree3" className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="accordion_itinerary_list">
                                                                        <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                            <span className="area_flex_one text-muted">
                                                                                <span>Seats</span>
                                                                                <span>$350</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='my-2'></hr>
                                            <div className="">
                                                <label className="form-check-label" for="flexCheckDefaultf1">
                                                    <span className="area_flex_one">
                                                        <span className='fw-bold'>Total Amount</span>
                                                        <span className='fw-bold'>$60,350</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>




};

export default BookingConfirmation;
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import ErrorModal from '../../../../../components/Common/Error/ErrorModal';
import { Tooltip as ReactTooltip } from "react-tooltip";

const DialogItem = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const [error, setError] = useState();

    function handleAddApprover(event) {
        event.preventDefault();
        props.onAddApproverToSelectedList(props.userID);
    }

    function handleError() {
        setError(null);
    }

    return <Fragment>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={handleError}
            ></ErrorModal>
        )}
        <div className="row">
            <div className="col-lg-12 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3 d-lg-flex d-md-flex">
                                <span>
                                    <h6 className="card-title fw-bold text-primary mt-1 gt-workflow row">
                                        <span className="col-md-2 col-1 col-sm-1">
                                            <i class="fas fa-user me-1"></i>
                                        </span>
                                        <span className="col-md-10 col-10 col-sm-10 gt-ms-5px my-auto">
                                            {props?.title === null ? props.firstName + " " + props.middleName +
                                                " " + props.lastName : props?.title + " " + props.firstName +
                                                " " + props.middleName + " " + props.lastName}
                                        </span>
                                    </h6>
                                </span>
                            </div>
                            <div className="col-md-2">
                                <p className="card-text mb-0"><strong>Department </strong><br />{props.corporateMasterName}</p>
                            </div>
                            <div className="col-md-3">
                                <p className="card-text mb-0"><strong>Employee Code </strong><br />{props.employeeCode}</p>
                            </div>
                            <div className="col-md-3">
                                <p className="card-text mb-0"><strong>Role </strong><br />{props.approvalTypeName}</p>
                            </div>
                            <div className="col-md-1 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    <button type="button"
                                        id={"btnAddSelectedApprovalWorkflow" + props.userID}
                                        onClick={handleAddApprover}
                                        className="btn text-white gt-main-btn ms-1 btn-sm">
                                        {t('Select')}
                                    </button>
                                    <ReactTooltip
                                        anchorId={"btnAddSelectedApprovalWorkflow" + props.userID}
                                        place="bottom"
                                        variant="info"
                                        multiline={true}
                                        className="tooltip"
                                        children={t('msgAfterSelectMoveTop')}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Fragment >
};
export default DialogItem;
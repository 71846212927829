import { Fragment, useEffect, useState, useCallback } from 'react';
import { UPDATECONTENT, UPDATEFEES } from '../../../navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-select';
import useHttp from "../../../services/use-http";
import FeeList from '../Fees/Components/FeesList'
import { geteCommonListtByName, serachContent, serachRegion } from '../../../services/Content-API';
import { searchContentWiseRegion } from '../../../services/Fees-API';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { searchCommonList } from '../../../services/Fee-API';

import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { searchFees } from '../../../services/Fees-API';
import { searchOfficeList } from '../../../services/Office-API'
const statusOption = [
    { value: true, label: "Active" },
    { value: undefined, label: "Both" },
    { value: false, label: "Inactive" },
];
const SearchFees = (props) => {
    useDocumentTitle('Search Fee');
    const [providerValue, setProviderValue] = useState("");
    const [serviceValue, setServiceValue] = useState("");
    const [providerLabel, setProviderLabel] = useState("");
    const [serviceLabel, setServiceLabel] = useState("");
    const [isFirstTime, setFirstTime] = useState("");
    const [toRegionValue, setToRegionValue] = useState('');
    const [fromRegionValue, setFromRegionValue] = useState('');
    const [UserStatus, setUserStatus] = useState(statusOption[0].value);
    const [error, setError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameLabel, setCompanyNameLabel] = useState();
    let ToRegionOptions = [];
    let FromRegionOptions = [];
    const { sendRequest: sendServiceList, data: loadedServiceList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendProviderList, data: loadedProviderList } = useHttp(searchCommonList)
    const { sendRequest: sendFromRegionList, data: loadedFromRegionList, status: fromRegionStatus } = useHttp(searchContentWiseRegion)
    const { sendRequest: sendToRegionList, data: loadedToRegionList, status: toRegionStatus } = useHttp(searchContentWiseRegion)
    const { t } = useTranslation(['translation.Fees']);
    const history = useHistory();
    const isTravelAgent = JSON.parse(localStorage.getItem('isTravelAgent'));
    //Code for get company List
    const [companyNameInput, setCompanyNameInput] = useState();
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }
    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
        setCompanyNameLabel(event.label);
    }
    //End
    function getDataArray(json) {
        const dataArray = [];
        if (json !== null && json.errors && json.errors.status === "FALSE" && json.data && json.data.length > 0) {
            json.data.forEach(item => {
                const { regionID, regionName } = item;
                dataArray.push({
                    label: regionName,
                    value: regionID
                });
            });
        }

        return dataArray;
    }
    function providerChangeHandler(event) {
        setProviderValue(event.value)
        setProviderLabel(event.label);
    }
    function serviceChangeHandler(event) {
        setServiceValue(event.value);
        setServiceLabel(event.label);
        setProviderValue('');
        if (event.label === 'Hotel') {

            const HotelProviderList = ({
                "methodName": "FeeProviderHotel",
                "methodCode": "FEEPROHOTEL",
                "listID": "",
                "listValue": "",
                "subListID": "20",
                "officeID": ""
            });
            sendProviderList(HotelProviderList)

        }
        if (event.label === 'Flight') {
            const FlightProviderList = ({
                "methodName": "FeeProvider",
                "methodCode": "FEEPRO",
                "listID": "",
                "listValue": "",
                "subListID": "21",
                "officeID": ""
            });
            sendProviderList(FlightProviderList)

        }
        const onToRegionSearch = (
            {

                serviceID: event.value,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                regionType: "to"
            }
        );
        const onFromRegionSearch = (
            {

                serviceID: event.value,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                "regionType": "from"
            }
        );
        sendToRegionList(onToRegionSearch);
        sendFromRegionList(onFromRegionSearch);
    }
    const statusChangeHandler = (event) => {
        setUserStatus(event.value);
    }
    function toRegionChangeHandler(event) {
        setToRegionValue(event.value);


    }
    function fromRegionChangeHandler(event) {
        setFromRegionValue(event.value);

    }
    const { sendRequest, status, data: loadedFees, error: feesError } = useHttp(searchFees);

    let statusFlag;
    if (UserStatus) {
        statusFlag = 2;
    } else if (UserStatus === false) {
        statusFlag = 3
    } else {
        statusFlag = 1;
    }



    useEffect(() => {

        if (status === 'completed') {

            if (loadedFees['errors'].error['code'] === '1008' || loadedFees['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedFees])

    function onResetHandler(event) {
        history.go('/serachContent');
    }


    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", "Fee", "Master_Fee_Search")) {
            return false;
        }

        if (isTravelAgent === "1") {
            if (companyNameInput === '' || companyNameInput === undefined) {
                setCompanyNameError(true);
                return;
            }
            else {
                setCompanyNameError(false);
            }
        }

        setFirstTime(true);
        const onSearchFees = (
            {
                id: "",
                traceID: "",
                userID: "",
                // "createdDate": "2023-06-02T13:14:28.567Z",
                // "ipAddress": "string",
                serviceID: serviceValue,
                serviceName: serviceLabel,
                providerID: providerValue,
                providerName: providerLabel,
                officeID: isTravelAgent === "1" ? companyNameInput : JSON.parse(localStorage.getItem('officeID')),
                fromRegion: fromRegionValue,
                toRegion: toRegionValue,
                markupType: "",
                markup: "",
                fareTypeID: "",
                fareValue: "",
                isActive: UserStatus,
                status: statusFlag,
            }
        );
        sendRequest(onSearchFees);

    }, [sendRequest, serviceValue, serviceLabel, providerLabel,
        providerValue, UserStatus, statusFlag, fromRegionValue, toRegionValue, companyNameInput])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    useEffect(() => {
        sendServiceList('service');
    }, [sendServiceList,]);
    if (toRegionStatus === 'completed') {
        ToRegionOptions = getDataArray(loadedToRegionList);
    }
    if (fromRegionStatus === 'completed') {
        FromRegionOptions = getDataArray(loadedFromRegionList);
    }


    if (feesError) {
        history.push('/Error')
        //  history.go();
        // return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }

    let loadedData = [];
    for (let i in loadedFees) {
        if (loadedFees['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedFees[i];
            }
        }
    }
    function okayHandler() {
        setError(false);
        history.go('/serachFees');

    }
    const errorHandler = () => {

        history.go('/serachFees');
    };
    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const feeList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <FeeList companyName={companyNameLabel} fees={loadedData} /> : ErrorShow


    //function to covert Object into Array
    function objectToArray(data, resultArray) {
        let arrayData = [];
        for (let i in data) {
            if (data['errors'].status === 'FALSE') {
                if (i === 'data') {
                    arrayData = data[i];
                }
            }
        }
        for (let index = 0; index < arrayData.length; index++) {
            resultArray = [...resultArray, { value: arrayData[index].listID, label: arrayData[index].listValue }]
        }
        return resultArray;

    }

    let serviceOption = [];
    serviceOption = objectToArray(loadedServiceList, serviceOption);
    let providerOption = [];
    providerOption = objectToArray(loadedProviderList, providerOption);


    return <Fragment>
        {error && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            {status === 'pending' &&
                                <div className=''>
                                    {/* <section id='common_banner'><div className='container'></div></section> */}
                                    <div className=''>
                                        <LoadingSpinner></LoadingSpinner>
                                    </div>
                                </div>
                            }

                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="form-group col-md-12">
                                        <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Fee</label>
                                        <form>
                                            {isTravelAgent === "1" && <div className='row'>
                                                <div className='col-md-12 col-sm-12 control'>
                                                    <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}<span className="error-icon"> *</span></label>
                                                    <Dropdown onChange={comapnyNameChaneHandler}
                                                        value={companyOptions.filter(option => option.value === companyNameInput)}
                                                        options={companyOptions}
                                                        textField="label"
                                                        dataItemKey="value"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />}
                                                        controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        autoFocus
                                                    />
                                                </div>
                                                {companyNameError && <p className="error-text">{t('companyNameBlankValidation')}</p>}
                                            </div>}
                                            <div className='row'>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='loginemailid'>Service</label>
                                                        <Dropdown
                                                            value={serviceOption.filter(option => option.value === serviceValue)}
                                                            options={serviceOption}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            onChange={serviceChangeHandler}
                                                            autoFocus={isTravelAgent !== "1" && true} />

                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='contactpersonemailid'>Provider</label>
                                                        <Dropdown
                                                            onChange={providerChangeHandler}
                                                            value={providerOption.filter(option => option.value === providerValue)}
                                                            options={providerOption}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select" />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='control'>
                                                        <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('fromRegion')}</label>
                                                        <Dropdown
                                                            onChange={fromRegionChangeHandler}
                                                            value={FromRegionOptions.filter(option => option.value === fromRegionValue)}
                                                            options={FromRegionOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="All"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='control'>
                                                        <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('toRegion')}</label>
                                                        <Dropdown
                                                            onChange={toRegionChangeHandler}
                                                            value={ToRegionOptions.filter(option => option.value === toRegionValue)}
                                                            options={ToRegionOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="All"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='mt-3 col-md-12 actions'>
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                    {t('reset')}
                                                </button>

                                                <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={UPDATEFEES}>
                                                    {t('new')}
                                                </NavLink>
                                                {checkSecurityDetails("Master", "Fee", "Master_Fee_Search") &&
                                                    <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
        {feeList}
    </Fragment>


};
export default SearchFees;

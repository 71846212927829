import secureLocalStorage from 'react-secure-storage';

export const getConfigurationByBoolen = (keyName) => {

    const loadedConfigurationsDetails = localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).configurationsDetails;
    let configValue = false;
    if (loadedConfigurationsDetails !== null) {
        loadedConfigurationsDetails.configurationsMenuDetails.forEach(element => {
            if (element.keyCode === keyName) {
                configValue = element.defaultBoolenValue;
            }
        });
    }
    return configValue;
}


export const getConfigurationByValue = (keyName) => {
    const loadedConfigurationsDetails = localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).configurationsDetails;
    let configValue = "";
    if (loadedConfigurationsDetails !== null) {
        loadedConfigurationsDetails.configurationsMenuDetails.forEach(element => {
            if (element.keyCode === keyName) {
                configValue = element.defaultStringValue;
            }
        });
    }
    return configValue;
}


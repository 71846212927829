import { Fragment } from "react";
import RotatingPlan from "../../../Flights/Components/RotatingPlan";
import WorldMap from "../../../Flights/Components/WorldMap";

const BookingLoadingSpinner = (props) => {
  return (
    <Fragment>
      {/* <div className='centered'>
        <div className={classes.spinner}></div>
      </div> */}

      <div className="loading">
        <div className="loader flight-loader-width">
          <div className="plane">
            {/* <img src="assets/img/loader5.gif" className="plane-img" /> */}
            <RotatingPlan />
          </div>
          <div className="postion-relative">
            <div className="earth-wrapper cstm">
              {/* <div className="earth"></div> */}
              <div className="worldMap">
                <div className="worldMap__inner">
                  <WorldMap className={"img1"} />
                  <WorldMap className={"img2"} />
                  <WorldMap className={"img3"} />
                  <WorldMap className={"img4"} />
                </div>
              </div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {props.action === "shopping" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait while the airline and hotel reservation systems
              confirm your bookings.
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          )}
          {props.action === "flight" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait while the airline reservation system confirms your
              booking.
              <br></br>
              Please do <b>not</b> close or refresh this page.
            </div>
          )}
          {props.action === "requestTypeOf" && (
            <div className="position-absolute gt-bottom-15 text-bottom w-100">
              Please wait while your booking request is submitted.
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BookingLoadingSpinner;

import { Fragment } from 'react';
import { breakTeaxtOnFullStop, parseHotelPolicy } from '../../../utils/CommonFunction';

const Policies = (props) => {
  let cancellationPolicies = "";
  let hotelPolicy = "";
  let depositPolicy = "";
  let guarnteePolicy = "";
  if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
    props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
      if (result.text.type === 'CancellationPolicy') {
        cancellationPolicies = result.text.value;
      }
    });

    props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
      if (result.text.type === 'Policies') {
        hotelPolicy = result.text.value;
      }
      if (result.text.type === 'GuaranteePolicy') {
        guarnteePolicy = result.text.value;
      }
      if (result.text.type === 'DepositPolicy') {
        depositPolicy = result.text.value;
      }
    });

  }

  return (<Fragment>
    <div className="xyz mt-2" style={{ paddingTop: "4px" }} >
      <h3 className="heading_theme mb-0" style={{ marginBottom: "4px", }}>Hotel Policies</h3>
      <div className="tour_details_boxed_inner">
        <div className="tour_package_details_bar_list first_child_padding_none" style={{ fontSize: "16px" }}>
          <div className=''>
            <table><tr><td>{parseHotelPolicy(hotelPolicy)}</td></tr></table>
            {cancellationPolicies !== '' && < div className='fw-bold text-primary mt-1'> Cancellation Policy</div>}
            <table><tr><td>  {breakTeaxtOnFullStop(cancellationPolicies)}</td></tr></table>
            {depositPolicy !== '' && < div className='fw-bold text-primary my-2 '> Deposit Policy</div>}
            <table><tr><td>   {breakTeaxtOnFullStop(depositPolicy)}</td></tr></table>
            {guarnteePolicy !== '' && < div className='fw-bold text-primary my-2'> Guarantee Policy</div>}
            <table><tr><td> {breakTeaxtOnFullStop(guarnteePolicy)}</td></tr></table>

          </div>
        </div>

      </div>
    </div>
  </Fragment >)
}

export default Policies;

import { Fragment } from 'react';
import RotatingPlan from '../../../pages/Flights/Components/RotatingPlan';
import WorldMap from '../../../pages/Flights/Components/WorldMap';

const LoadingSpinner = () => {
  return (
    <Fragment>
      {/* <div className='centered'>
        <div className={classes.spinner}></div>
      </div> */}


        <div className='loading'>
        {/* <div className='loading-main-page'> */}
          <div className="loader">
            <div className="plane">
              {/* <img src="assets/img/loader5.gif" className="plane-img" /> */}
            <RotatingPlan/>

            </div>
            <div className='postion-relative'>
              <div className="earth-wrapper cstm">
                {/* <div className="earth"></div> */}
                <div className="worldMap">
                <div className="worldMap__inner">
                  <WorldMap className={'img1'}/>
                  <WorldMap className={'img2'}/>
                  <WorldMap className={'img3'}/>
                  <WorldMap className={'img4'}/>
                  </div>
              </div>
              </div> 
            </div> 
            <div className="iata_code arrival_city">Globetrotter</div>
          </div>
        </div>
    </Fragment>)
}

export default LoadingSpinner;

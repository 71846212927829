import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { setSanitizedHtml } from '../../../../utils';

const ApprovalServiceHistory = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const isFilledByBooker = props.approvalStatusCode === 'UT' || props.approvalStatusCode === 'UA';

    function shouldShowPrice(history) {
        let price = history.totalRateOfService;
        if (isFilledByBooker) {
            price = history.updateAmount;
        }

        return price && price.length > 0;
    }

    function getPriceString(history) {
        let price = history.totalRateOfService;
        let currencyCode = history.currencyCode;
        if (isFilledByBooker) {
            price = history.updateAmount;
            currencyCode = history.updateCurrencyCode;
        }

        if (price && price.length) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
            }).format(price)
        }

        return '';
    }

    return <Fragment>

        <div id={"DTL_" + props.historyKey}
            className="row Accordion-toggle-icon accordion-collapse collapse"
            data-bs-parent={"#MST_" + props.historyKey}>
            {/* <div className='col-md-12 mt-3'>
                <div className="col-md-12 fw-bold h6"><i className="fa-solid fa-cog"></i> {t('labelServiceRequests')}</div>
            </div> */}
            <div className='col-md-12 mt-3'>
                <div className="fw-bold h6 text-primary">
                    {isFilledByBooker ? t('labelFilledByBooker') : t('labelFilledByTraveler')}
                </div>
            </div>

            {props.serviceHistory.map((history) =>
                <Fragment key={history.serviceName}>
                    <div className="col-md-12">
                        <hr className="my-1"></hr>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className="card-text mb-0 pb-1">
                                <strong> {t('labelService')}  </strong>
                                {history.serviceName}
                            </p>
                        </div>
                        
                        <div className="col-md-4">
                            {history.existingConfirmationNumber !== undefined && history.existingConfirmationNumber.length > 0 &&
                                <p className="card-text mb-0 pb-1">
                                    <strong>{t('labelConfirmationNo')}  </strong>
                                    {history.existingConfirmationNumber}
                                </p>
                            }
                        </div>
                       
                        <div className="col-md-4">
                            {shouldShowPrice(history) &&
                                <p className="card-text mb-0 pb-1">
                                    <strong>{t('labelPrice')}  </strong>
                                    {getPriceString(history)}
                                </p>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            {history.remarks !== undefined && history.remarks.length > 0 &&
                                <p className="card-text mb-0 pb-1">
                                    <strong> {t('labelRemarks')}  </strong>
                                    {setSanitizedHtml(history.remarks)}
                                </p>
                            }
                        </div>
                        <div className='col-md-4'>
                            {isFilledByBooker && history.servicesAttachment && history.servicesAttachment.length > 0 &&
                                <p className="card-text mb-0 pb-1">
                                    <div className="">
                                    <strong> {t('labelAttachedDocuments')}  </strong>
                                    </div>
                                    {history.servicesAttachment.map((file) =>
                                        <span key={file.serviceID + file.name}>
                                            <a href={file.documentBinaryContent}
                                                download={file.name}>
                                                {file.name} <i class="fas fa-download"></i>
                                            </a> <br></br>
                                        </span>
                                    )}
                                </p>
                            }
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    </Fragment>;
};

export default ApprovalServiceHistory;
import { useState, Fragment, useEffect } from 'react';
import LoadingSightseeing from '../Sightseeing/Components/LoadingSightseeing';
import { getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../utils/RoleUtils';

const SightseeingDetails = () => {
    const [sightseeingValue, setSightseeingValue] = useState();

    let sightseeingData_ = localStorage.getItem("sightseeingData") !== null ? localStorage.getItem("sightseeingData") : '';
    //<div data-vi-partner-id='U00414779' data-vi-widget-ref='W-d0b96caa-cd7e-4d0d-ad77-58c53aa53efb' ></div>

    useEffect(() => {
        setSightseeingValue(<div data-vi-partner-id={sightseeingData_.split(',')[0]} data-vi-widget-ref={sightseeingData_.split(',')[1]} ></div>);
    }, [])

    setTimeout(function () {

        if ((getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).parentOfficeID
            || getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).officeID) && document.getElementsByTagName('iframe').length < 1) {
            if (document.getElementById('sightseeingIframe') !== null) {
                document.getElementById('sightseeingIframe').src = document.getElementById('sightseeingIframe').src
            }
        }
        else if ((getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).parentOfficeID
            || getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).officeID) && document.getElementsByTagName('iframe').length < 1) {
            if (document.getElementById('sightseeingIframe') !== null) {
                document.getElementById('sightseeingIframe').src = document.getElementById('sightseeingIframe').src
            }
        }
        else {
            if (document.getElementById("divSightseeingValue") !== null) {
                document.getElementById("divSightseeingValue").style.display = "none";
            }
        }

        // if (document.getElementById("divSightseeingValue") !== null) {
        //     document.getElementById("divSightseeingValue").style.display = "none";
        // }
        // if (document.getElementById('sightseeingIframe') !== null) {
        //     document.getElementById('sightseeingIframe').contentDocument.location.reload(true);
        // }

    }, 3000);

    return <Fragment>
        <div>{sightseeingValue}</div>
        <div id="divSightseeingValue"><LoadingSightseeing action='search' ></LoadingSightseeing></div>
    </Fragment>
};

export default SightseeingDetails;
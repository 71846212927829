import { Fragment, useState, useEffect, useRef } from 'react';
import Dropdown from 'react-select';
import { GET_AIRPORT_LIST } from '../../../services/CONSTANTS';
//import './App.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from 'react-i18next';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from 'moment';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import queryString from 'query-string';
import { useLocation } from 'react-router';


const FlightSearchControlMainPanel = ({ controlId, tripType, travelReq }) => {
    const { t } = useTranslation(['translation.SearchFlight']);
    const [items, setItems] = useState([]);
    const [timeSlotFromValue, setTimeSlotFromValue] = useState('');
    const [timeSlotFromLabel, setTimeSlotFromLabel] = useState('');
    const [timeSlotToValue, setTimeSlotToValue] = useState('');
    const [timeSlotToLabel, setTimeSlotToLabel] = useState('');
    const [minToDateChange, setMinToDateChange] = useState('');

    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let cabinList = [];
    let defaultCabin = "";
    let defaultCabinValue = "";
    let tripReasonAndPolicyDataJson = "";
    let travelRequestFieldsDataJson = "";

    if ((travelReq === true && localStorage.getItem("loginData") !== null) || (localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true')) {
        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            let searchPolicyResponse = searchPolicyResponseTemp.policy === null ? null : searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason && a.isFlight === true);

            if (searchPolicyResponseTemp?.policy !== null && searchPolicyResponse[0] !== undefined && searchPolicyResponse[0].highestClassCabin !== undefined && searchPolicyResponse[0].highestClassCabin !== null && searchPolicyResponse[0].highestClassCabin !== '') {
                let cabinListTemp = [];
                cabinListTemp.push(
                    { value: 'First', label: 4 },
                    { value: 'Business', label: 3 },
                    { value: 'Premium Eco', label: 2 },
                    { value: 'Economy', label: 1 }
                );

                let highestClassCabinText = cabinListTemp.filter(a => a.label === parseInt(searchPolicyResponse[0].highestClassCabin))[0].value;
                let highestClassCabinId = parseInt(searchPolicyResponse[0].highestClassCabin);

                for (let index = highestClassCabinId; index > 0; index--) {
                    cabinList.push(
                        { value: cabinListTemp.filter(a => a.label === index)[0].value, label: index }
                    );
                }

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && localStorage.getItem("BookingDataTravelRequest") !== null) {
                    let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                    let cabinName = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName;
                    cabinName = cabinName.trim().toLowerCase() === 'premium economy' ? 'Premium Eco' : cabinName;

                    if (cabinList.filter(a => a.value === cabinName).length > 0) {
                        defaultCabin = cabinName.toString().toLowerCase().replace(' ', '');
                        defaultCabinValue = cabinName;
                    }
                    else {
                        defaultCabin = cabinList[0].value.toString().toLowerCase().replace(' ', '');
                        defaultCabinValue = cabinList[0].value;
                    }
                }
                else {
                    defaultCabin = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1').toString().toLowerCase().replace(' ', '') : highestClassCabinText.toString().toLowerCase().replace(' ', '');
                    defaultCabinValue = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1') : highestClassCabinText;
                }
            }
            else {
                cabinList.push(
                    { value: 'First', label: 4 },
                    { value: 'Business', label: 3 },
                    { value: 'Premium Eco', label: 2 },
                    { value: 'Economy', label: 1 }
                );

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && localStorage.getItem("BookingDataTravelRequest") !== null) {
                    let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                    let cabinName = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName;
                    cabinName = cabinName.trim().toLowerCase() === 'premium economy' ? 'Premium Eco' : cabinName;

                    if (cabinList.filter(a => a.value === cabinName).length > 0) {
                        defaultCabin = cabinName.toString().toLowerCase().replace(' ', '');
                        defaultCabinValue = cabinName;
                    }
                    else {
                        defaultCabin = cabinList[0].value.toString().toLowerCase().replace(' ', '');
                        defaultCabinValue = cabinList[0].value;
                    }
                }
                else {
                    defaultCabin = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1').toString().toLowerCase().replace(' ', '') : 'Economy'.toString().toLowerCase().replace(' ', '');
                    defaultCabinValue = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1') : 'Economy';
                }
            }
        }
        else {
            cabinList.push(
                { value: 'First', label: 4 },
                { value: 'Business', label: 3 },
                { value: 'Premium Eco', label: 2 },
                { value: 'Economy', label: 1 }
            );

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && localStorage.getItem("BookingDataTravelRequest") !== null) {
                let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let cabinName = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName;
                cabinName = cabinName.trim().toLowerCase() === 'premium economy' ? 'Premium Eco' : cabinName;


                if (cabinList.filter(a => a.value === cabinName).length > 0) {
                    defaultCabin = cabinName.toString().toLowerCase().replace(' ', '');
                    defaultCabinValue = cabinName;
                }
                else {
                    defaultCabin = cabinList[0].value.toString().toLowerCase().replace(' ', '');
                    defaultCabinValue = cabinList[0].value;
                }
            }
            else {
                defaultCabin = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1').toString().toLowerCase().replace(' ', '') : 'Economy'.toString().toLowerCase().replace(' ', '');
                defaultCabinValue = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1') : 'Economy';
            }
        }
    }
    else {
        cabinList.push(
            { value: 'First', label: 4 },
            { value: 'Business', label: 3 },
            { value: 'Premium Eco', label: 2 },
            { value: 'Economy', label: 1 }
        );

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && localStorage.getItem("BookingDataTravelRequest") !== null) {
            let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
            let cabinName = bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName;
            cabinName = cabinName.trim().toLowerCase() === 'premium economy' ? 'Premium Eco' : cabinName;

            if (cabinList.filter(a => a.value === cabinName).length > 0) {
                defaultCabin = cabinName.toString().toLowerCase().replace(' ', '');
                defaultCabinValue = cabinName;
            }
            else {
                defaultCabin = cabinList[0].value.toString().toLowerCase().replace(' ', '');
                defaultCabinValue = cabinList[0].value;
            }
        }
        else {
            defaultCabin = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1').toString().toLowerCase().replace(' ', '') : 'Economy'.toString().toLowerCase().replace(' ', '');
            defaultCabinValue = sessionStorage.getItem('spnClass1') !== null && sessionStorage.getItem('spnClass1') !== '' ? sessionStorage.getItem('spnClass1') : 'Economy';
        }
    }

    const refFrom = useRef(null);
    useOutsideAlerter(refFrom);

    const refTo = useRef(null);
    useOutsideAlerter(refTo);

    var fromTodayDate = new Date();
    fromTodayDate.setDate(fromTodayDate.getDate() + 14);

    const maxPassengerCount = 9;

    if (tripType === 'multi_city') {
        let newIndex = controlId - 1;
        if (sessionStorage.getItem("calFrom1") !== null) {
            let dateFromSessionValue = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
            fromTodayDate.setDate(dateFromSessionValue.getDate() + newIndex);
        }
        else {
            fromTodayDate.setDate(fromTodayDate.getDate() + newIndex);
        }
    }

    var toTodayDate = new Date();
    toTodayDate.setDate(toTodayDate.getDate() + 15);

    const handleOnFromSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
            }
        }
    }

    const handleOnToSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.airportCode} - {item.airportName}</span>
            </>
        )
    }

    const handleOnSelectFrom = (item) => {
        sessionStorage.setItem("txtFrom" + controlId, item.cityName);
        sessionStorage.setItem("spnFromAirportDetails" + controlId, item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode);
        sessionStorage.setItem("fromCityCode" + controlId, item.cityCode);

        document.querySelector("#txtFrom" + controlId).style.display = "";
        document.querySelector('#txtFrom' + controlId).value = item.cityName;
        document.querySelector("#dvFrom" + controlId).style.display = "none";
        document.querySelector('#spnFromAirportDetails' + controlId).style.display = "";
        document.querySelector('#spnFromAirportDetails' + controlId).innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        document.querySelector('#spnFromCityCode' + controlId).innerHTML = item.cityCode;
    }

    const handleOnSelectTo = (item) => {
        sessionStorage.setItem("txtTo" + controlId, item.cityName);
        sessionStorage.setItem("spnToAirportDetails" + controlId, item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode);
        sessionStorage.setItem("toCityCode" + controlId, item.cityCode);

        document.querySelector("#txtTo" + controlId).style.display = "";
        document.querySelector('#txtTo' + controlId).value = item.cityName;
        document.querySelector("#dvTo" + controlId).style.display = "none";
        document.querySelector('#spnToAirportDetails' + controlId).style.display = "";
        document.querySelector('#spnToAirportDetails' + controlId).innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        document.querySelector('#spnToCityCode' + controlId).innerHTML = item.cityCode;
    }

    const handleOnClickFrom = () => {
        document.querySelector("#txtFrom" + controlId).style.display = "none";
        document.querySelector("#dvFrom" + controlId).style.display = "";
        document.querySelector('#spnFromAirportDetails' + controlId).style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount.forEach(element => {
                element.focus();
            });
        }
    }

    const handleOnClickTo = () => {
        document.querySelector("#txtTo" + controlId).style.display = "none";
        document.querySelector("#dvTo" + controlId).style.display = "";
        document.querySelector('#spnToAirportDetails' + controlId).style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount.forEach(element => {
                element.focus();
            });
        }
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }
            // if (tripType !== '' && tripType !== 'oneway') {
            //     if (document.querySelector('[class="rmdp-input"]') !== null) {
            //         var controlCount = document.querySelectorAll('[class="rmdp-input"]');
            //         controlCount[1].style.display = "none";
            //     }
            // }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    //ref.current.style.display = "none";                    
                    if (ref.current.innerText === "From") {
                        document.querySelector('#dvFrom' + controlId).style.display = "none";
                        document.querySelector('#txtFrom' + controlId).style.display = "";
                        document.querySelector('#spnFromAirportDetails' + controlId).style.display = "";
                    }
                    if (ref.current.innerText === "To") {
                        document.querySelector('#dvTo' + controlId).style.display = "none";
                        document.querySelector('#txtTo' + controlId).style.display = "";
                        document.querySelector('#spnToAirportDetails' + controlId).style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const switchRoute = (event) => {
        let targetControlId = event.target.id.split('-')[1];

        let txtFrom = document.querySelector('#txtFrom' + targetControlId).value;
        let spnFromAirportDetails = document.querySelector('#spnFromAirportDetails' + targetControlId).innerHTML;

        document.querySelector('#txtFrom' + targetControlId).value = document.querySelector('#txtTo' + targetControlId).value;
        document.querySelector('#spnFromAirportDetails' + targetControlId).innerHTML = document.querySelector('#spnToAirportDetails' + targetControlId).innerHTML;

        sessionStorage.setItem("txtFrom" + targetControlId, document.querySelector('#txtFrom' + targetControlId).value);
        sessionStorage.setItem('spnFromAirportDetails' + targetControlId, document.querySelector('#spnFromAirportDetails' + targetControlId).innerHTML);
        let fromCityCodeTemp = sessionStorage.getItem("fromCityCode" + targetControlId);
        sessionStorage.setItem("fromCityCode" + targetControlId, sessionStorage.getItem("toCityCode" + targetControlId));
        document.querySelector('#spnFromCityCode' + targetControlId).innerHTML = document.querySelector('#spnToCityCode' + targetControlId).innerHTML;

        document.querySelector('#txtTo' + targetControlId).value = txtFrom;
        document.querySelector('#spnToAirportDetails' + targetControlId).innerHTML = spnFromAirportDetails;
        document.querySelector('#spnToCityCode' + targetControlId).innerHTML = fromCityCodeTemp;

        sessionStorage.setItem("txtTo" + targetControlId, txtFrom);
        sessionStorage.setItem('spnToAirportDetails' + targetControlId, spnFromAirportDetails);
        sessionStorage.setItem("toCityCode" + targetControlId, fromCityCodeTemp);
    }

    const calGetDateFrom = (inputDate) => {
        let sourceDate = "";
        let destinationDate = "";

        if (tripType !== '' && tripType === 'roundtrip') {
            sourceDate = inputDate.target.value.trim();
            destinationDate = sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' && new Date(sessionStorage.getItem("calTo1").replace("'", "")) > new Date(inputDate.target.value.trim()) ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : inputDate.target.value.trim();
        }
        else {
            sourceDate = inputDate.target.value.trim();
        }

        if (tripType !== '' && tripType === 'roundtrip') {
            sessionStorage.setItem("calFrom" + controlId, moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY"));
            document.querySelector('#calFrom' + controlId).value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
            const fromDate = new Date(sourceDate);
            var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calFromDateDB" + controlId, fromDateDB);

            sessionStorage.setItem("calTo" + controlId, moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY"));
            document.querySelector('#calTo' + controlId).value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
            const toDate = new Date(destinationDate);
            var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calToDateDB" + controlId, toDateDB);
        }
        else {
            sessionStorage.setItem("calFrom" + controlId, moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY"));
            document.querySelector('#calFrom' + controlId).value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
            const fromDate = new Date(sourceDate);
            var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calFromDateDB" + controlId, fromDateDB);
        }

        setMinToDateChange(new Date(sessionStorage.getItem("calFrom1").replace("'", "")));
    }

    const calGetDateTo = (inputDate) => {
        let sourceDate = "";
        let destinationDate = "";

        if (tripType !== '' && tripType === 'roundtrip') {
            sourceDate = inputDate.target.value.trim();
            destinationDate = inputDate.target.value.trim();
        }
        else {
            sourceDate = inputDate.target.value.trim();
        }

        if (tripType !== '' && tripType === 'roundtrip') {
            // sessionStorage.setItem("calFrom" + controlId, moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY"));
            // document.querySelector('#calFrom' + controlId).value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
            // const fromDate = new Date(sourceDate);
            // var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            // sessionStorage.setItem("calFromDateDB" + controlId, fromDateDB);

            sessionStorage.setItem("calTo" + controlId, moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY"));
            document.querySelector('#calTo' + controlId).value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
            const toDate = new Date(destinationDate);
            var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calToDateDB" + controlId, toDateDB);
        }
        else {
            sessionStorage.setItem("calTo" + controlId, moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY"));
            document.querySelector('#calTo' + controlId).value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
            const toDate = new Date(sourceDate);
            var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calToDateDB" + controlId, toDateDB);
        }
    }

    const timeSlotFromChangeHandler = (event) => {
        sessionStorage.setItem("timeSlotFrom" + controlId, event.value);
        setTimeSlotFromValue(event.value);
        setTimeSlotFromLabel(event.label);
    };

    let timeSlotOptions = [
        { value: '', label: 'Anytime' },
        { value: '0600-1200', label: 'Morning' },
        { value: '1200-1800', label: 'Afternoon' },
        { value: '1800-0000', label: 'Evening' },
        { value: '0000-0600', label: 'Night' }
    ];

    const timeSlotToChangeHandler = (event) => {
        sessionStorage.setItem("timeSlotTo" + controlId, event.value);
        setTimeSlotToValue(event.value);
        setTimeSlotToLabel(event.label);
    };

    window.addEventListener('click', function (e) {
        if (document.querySelector('#dvPassengers1') !== null) {
            if (document.querySelector('#dvPassengers1').contains(e.target)) {
                if (e.target.id === "ancHidePassengerDropDown") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
            } else {
                if ((e.target.parentNode !== null && e.target.parentNode.id === "btnPassengerDropDown1") || (e.target !== null && e.target.id === "btnPassengerDropDown1")) {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
            }
        }

        if (document.querySelector('#dvClass1') !== null) {
            if (document.querySelector('#dvClass1').contains(e.target)) {
                if (e.target.id === "ancHideClassDropDown") {
                    document.querySelector('#btnClassDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnClassDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvClass1').className = "dropdown-menu dropdown_passenger_info";
                }
                else {
                    document.querySelector('#btnClassDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnClassDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvClass1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvClass1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
            } else {
                if ((e.target.parentNode !== null && e.target.parentNode.id === "btnClassDropDown1") || (e.target !== null && e.target.id === "btnClassDropDown1")) {
                    document.querySelector('#btnClassDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnClassDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvClass1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvClass1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
                else {
                    document.querySelector('#btnClassDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnClassDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvClass1').className = "dropdown-menu dropdown_passenger_info";
                }
            }
        }
    });

    const addPassenger = (event) => {
        var passengerCountAdt = 0;
        var passengerCountChd = 0;
        var passengerCountLapInf = 0;
        var passengerCountSeatInf = 0;

        if (document.querySelector('#spnPassengerAdt1') !== null) {
            passengerCountAdt = parseInt(document.querySelector('#spnPassengerAdt1').innerHTML);
        }

        if (document.querySelector('#spnPassengerChd1') !== null) {
            passengerCountChd = parseInt(document.querySelector('#spnPassengerChd1').innerHTML);
        }

        if (document.querySelector('#spnPassengerLapInf1') !== null) {
            passengerCountLapInf = parseInt(document.querySelector('#spnPassengerLapInf1').innerHTML);
        }

        if (document.querySelector('#spnPassengerSeatInf1') !== null) {
            passengerCountSeatInf = parseInt(document.querySelector('#spnPassengerSeatInf1').innerHTML);
        }

        var totalPassengerTemp = passengerCountAdt + passengerCountChd + passengerCountLapInf + passengerCountSeatInf;
        var totalPassengerInfTemp = passengerCountLapInf + passengerCountSeatInf;

        if (event.target.id === "addPassengerAdt1") {
            if (document.querySelector('#spnPassengerAdt1') !== null) {
                if (totalPassengerTemp < maxPassengerCount) {
                    passengerCountAdt = passengerCountAdt + 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerAdt1').innerHTML = passengerCountAdt;
                sessionStorage.setItem('spnPassengerAdt1', passengerCountAdt);
            }
        }

        if (event.target.id === "addPassengerChd1") {
            if (document.querySelector('#spnPassengerChd1') !== null) {
                if (totalPassengerTemp < maxPassengerCount) {
                    passengerCountChd = passengerCountChd + 1;
                }
                document.querySelector('#spnPassengerChd1').innerHTML = passengerCountChd;
                sessionStorage.setItem('spnPassengerChd1', passengerCountChd);
                document.querySelector('#spnInfValidationMsg').innerHTML = "";
            }
        }

        if (event.target.id === "addPassengerLapInf1") {
            if (document.querySelector('#spnPassengerLapInf1') !== null) {
                if (totalPassengerTemp < maxPassengerCount && totalPassengerInfTemp < passengerCountAdt) {
                    passengerCountLapInf = passengerCountLapInf + 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerLapInf1').innerHTML = passengerCountLapInf;
                sessionStorage.setItem('spnPassengerLapInf1', passengerCountLapInf);
            }
        }

        if (event.target.id === "addPassengerSeatInf1") {
            if (document.querySelector('#spnPassengerSeatInf1') !== null) {
                if (totalPassengerTemp < maxPassengerCount && totalPassengerInfTemp < passengerCountAdt) {
                    passengerCountSeatInf = passengerCountSeatInf + 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerSeatInf1').innerHTML = passengerCountSeatInf;
                sessionStorage.setItem('spnPassengerSeatInf1', passengerCountSeatInf);
            }
        }

        var totalPassenger = passengerCountAdt + passengerCountChd + passengerCountLapInf + passengerCountSeatInf;

        if (document.querySelector('#btnPassengerDropDown1') !== null) {
            document.querySelector('#btnPassengerDropDown1').innerHTML = totalPassenger === 1 ? `${totalPassenger} ${t('pax')} <i className='fas fa-caret-down text-primary'></i>` : `${totalPassenger} ${t('paxmulti')} <i className='fas fa-caret-down text-primary'></i>`;
            sessionStorage.setItem('btnPassengerDropDown1', totalPassenger);
        }
    }

    const subtractPassenger = (event) => {
        var passengerCountAdt = 0;
        var passengerCountChd = 0;
        var passengerCountLapInf = 0;
        var passengerCountSeatInf = 0;

        if (document.querySelector('#spnPassengerAdt1') !== null) {
            passengerCountAdt = parseInt(document.querySelector('#spnPassengerAdt1').innerHTML);
        }

        if (document.querySelector('#spnPassengerChd1') !== null) {
            passengerCountChd = parseInt(document.querySelector('#spnPassengerChd1').innerHTML);
        }

        if (document.querySelector('#spnPassengerLapInf1') !== null) {
            passengerCountLapInf = parseInt(document.querySelector('#spnPassengerLapInf1').innerHTML);
        }

        if (document.querySelector('#spnPassengerSeatInf1') !== null) {
            passengerCountSeatInf = parseInt(document.querySelector('#spnPassengerSeatInf1').innerHTML);
        }

        //var totalPassengerTemp = passengerCountAdt + passengerCountChd + passengerCountLapInf + passengerCountSeatInf;
        var totalPassengerInfTemp = passengerCountLapInf + passengerCountSeatInf;

        if (event.target.id === "subtractPassengerAdt1") {
            if (document.querySelector('#spnPassengerAdt1') !== null) {
                if (passengerCountAdt > 1 && passengerCountAdt > totalPassengerInfTemp) {
                    passengerCountAdt = passengerCountAdt - 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerAdt1').innerHTML = passengerCountAdt;
                sessionStorage.setItem('spnPassengerAdt1', passengerCountAdt);
            }
        }

        if (event.target.id === "subtractPassengerChd1") {
            if (document.querySelector('#spnPassengerChd1') !== null) {
                if (passengerCountChd > 0) {
                    passengerCountChd = passengerCountChd - 1;
                }
                document.querySelector('#spnPassengerChd1').innerHTML = passengerCountChd;
                sessionStorage.setItem('spnPassengerChd1', passengerCountChd);
                document.querySelector('#spnInfValidationMsg').innerHTML = "";
            }
        }

        if (event.target.id === "subtractPassengerLapInf1") {
            if (document.querySelector('#spnPassengerLapInf1') !== null) {
                if (passengerCountLapInf > 0) {
                    passengerCountLapInf = passengerCountLapInf - 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerLapInf1').innerHTML = passengerCountLapInf;
                sessionStorage.setItem('spnPassengerLapInf1', passengerCountLapInf);
            }
        }

        if (event.target.id === "subtractPassengerSeatInf1") {
            if (document.querySelector('#spnPassengerSeatInf1') !== null) {
                if (passengerCountSeatInf > 0) {
                    passengerCountSeatInf = passengerCountSeatInf - 1;
                    document.querySelector('#spnInfValidationMsg').innerHTML = "";
                }
                else {
                    if (passengerCountAdt === totalPassengerInfTemp) {
                        document.querySelector('#spnInfValidationMsg').innerHTML = "Infants cannot be more than adults.";
                    }
                }
                document.querySelector('#spnPassengerSeatInf1').innerHTML = passengerCountSeatInf;
                sessionStorage.setItem('spnPassengerSeatInf1', passengerCountSeatInf);
            }
        }

        var totalPassenger = passengerCountAdt + passengerCountChd + passengerCountLapInf + passengerCountSeatInf;

        if (document.querySelector('#btnPassengerDropDown1') !== null) {
            document.querySelector('#btnPassengerDropDown1').innerHTML = totalPassenger === 1 ? `${totalPassenger} ${t('pax')} <i className='fas fa-caret-down text-primary'></i>` : `${totalPassenger} ${t('paxmulti')} <i className='fas fa-caret-down text-primary'></i>`;
            sessionStorage.setItem('btnPassengerDropDown1', totalPassenger);
        }
    }

    const classHandler = (event) => {
        if (document.querySelector('#spnClass1') !== null) {
            document.querySelector('#spnClass1').innerText = event.target.innerText;
            sessionStorage.setItem('spnClass1', event.target.innerText);
        }

        if (document.querySelector('#btnClassDropDown1') !== null) {
            document.querySelector('#btnClassDropDown1').innerHTML = `${event.target.innerText} <i className='fas fa-caret-down text-primary'></i>`;
            sessionStorage.setItem('btnClassDropDown1', event.target.innerText);
            sessionStorage.setItem('spnClass1', event.target.innerText);
        }

        if (event.target.id === 'spnEconomy1' || event.target.id === 'btnEconomy1') {
            if (document.querySelector('#btnEconomy1') !== null) {
                document.querySelector('#btnEconomy1').className = "label-select-btn active";
            }
            if (document.querySelector('#btnPremiumEco1') !== null) {
                document.querySelector('#btnPremiumEco1').className = "label-select-btn";
            }
            if (document.querySelector('#btnBusiness1') !== null) {
                document.querySelector('#btnBusiness1').className = "label-select-btn";
            }
            if (document.querySelector('#btnFirst1') !== null) {
                document.querySelector('#btnFirst1').className = "label-select-btn";
            }
        }
        else if (event.target.id === 'spnPremiumEco1' || event.target.id === 'btnPremiumEco1') {
            if (document.querySelector('#btnEconomy1') !== null) {
                document.querySelector('#btnEconomy1').className = "label-select-btn";
            }
            if (document.querySelector('#btnPremiumEco1') !== null) {
                document.querySelector('#btnPremiumEco1').className = "label-select-btn active";
            }
            if (document.querySelector('#btnBusiness1') !== null) {
                document.querySelector('#btnBusiness1').className = "label-select-btn";
            }
            if (document.querySelector('#btnFirst1') !== null) {
                document.querySelector('#btnFirst1').className = "label-select-btn";
            }
        }
        else if (event.target.id === 'spnBusiness1' || event.target.id === 'btnBusiness1') {
            if (document.querySelector('#btnEconomy1') !== null) {
                document.querySelector('#btnEconomy1').className = "label-select-btn";
            }
            if (document.querySelector('#btnPremiumEco1') !== null) {
                document.querySelector('#btnPremiumEco1').className = "label-select-btn";
            }
            if (document.querySelector('#btnBusiness1') !== null) {
                document.querySelector('#btnBusiness1').className = "label-select-btn active";
            }
            if (document.querySelector('#btnFirst1') !== null) {
                document.querySelector('#btnFirst1').className = "label-select-btn";
            }
        }
        else if (event.target.id === 'spnFirst1' || event.target.id === 'btnFirst1') {
            if (document.querySelector('#btnEconomy1') !== null) {
                document.querySelector('#btnEconomy1').className = "label-select-btn";
            }
            if (document.querySelector('#btnPremiumEco1') !== null) {
                document.querySelector('#btnPremiumEco1').className = "label-select-btn";
            }
            if (document.querySelector('#btnBusiness1') !== null) {
                document.querySelector('#btnBusiness1').className = "label-select-btn";
            }
            if (document.querySelector('#btnFirst1') !== null) {
                document.querySelector('#btnFirst1').className = "label-select-btn active";
            }
        }
    }

    const showPassengerDropDown = (event) => {
        document.querySelector('#dvPassengers1').style.display = "";
        document.querySelector('#dvClass1').style.display = "none";
    }

    const showClassDropDown = (event) => {
        document.querySelector('#dvPassengers1').style.display = "none";
        document.querySelector('#dvClass1').style.display = "";
    }

    useEffect(() => {
        let newControlId = controlId - 1;
        document.querySelector('#dvPassengers1').style.display = "none";
        document.querySelector('#dvClass1').style.display = "none";
        document.querySelector('#dvFromSpinner' + controlId).style.display = "none";
        document.querySelector('#dvToSpinner' + controlId).style.display = "none";

        if (localStorage.getItem("airportSuggestion") != null) {
            setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
        }

        document.querySelector("#dvFrom" + controlId).style.display = "none";
        document.querySelector("#dvTo" + controlId).style.display = "none";
        if (sessionStorage.getItem("txtFrom" + controlId) !== null) {
            document.querySelector("#txtFrom" + controlId).value = sessionStorage.getItem("txtFrom" + controlId);
        }
        else {
            if (newControlId > 0) {
                document.querySelector("#txtFrom" + controlId).value = document.querySelector("#txtTo" + newControlId).value;
                sessionStorage.setItem("txtFrom" + controlId, document.querySelector("#txtTo" + newControlId).value);
            }
            else {
                var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
                document.querySelector("#txtFrom" + controlId).value = strSightData.airportName;// "New York";
                sessionStorage.setItem("txtFrom" + controlId, strSightData.airportName);//"New York");
            }
        }

        if (sessionStorage.getItem("txtTo" + controlId) !== null) {
            document.querySelector("#txtTo" + controlId).value = sessionStorage.getItem("txtTo" + controlId);
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "TO");
            document.querySelector("#txtTo" + controlId).value = strSightData.airportName;// "San Francisco";
            sessionStorage.setItem("txtTo" + controlId, strSightData.airportName);//"San Francisco");
        }

        if (sessionStorage.getItem("spnFromAirportDetails" + controlId) !== null) {
            document.querySelector('#spnFromAirportDetails' + controlId).innerHTML = sessionStorage.getItem("spnFromAirportDetails" + controlId);
            document.querySelector('#spnFromCityCode' + controlId).innerHTML = sessionStorage.getItem("fromCityCode" + controlId);
        }
        else {
            if (newControlId > 0) {
                document.querySelector('#spnFromAirportDetails' + controlId).innerHTML = document.querySelector('#spnToAirportDetails' + newControlId).innerHTML;
                sessionStorage.setItem("spnFromAirportDetails" + controlId, document.querySelector('#spnToAirportDetails' + newControlId).innerHTML);
                document.querySelector('#spnFromCityCode' + controlId).innerHTML = document.querySelector('#spnToCityCode' + newControlId).innerHTML;
                sessionStorage.setItem("fromCityCode" + controlId, document.querySelector('#spnToCityCode' + newControlId).innerHTML);
            }
            else {
                var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
                document.querySelector('#spnFromAirportDetails' + controlId).innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"JFK - John F Kennedy, United States of America, US";
                sessionStorage.setItem("spnFromAirportDetails" + controlId, strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode);//"JFK - John F Kennedy, United States of America, US");                
                document.querySelector('#spnFromCityCode' + controlId).innerHTML = strSightData.cityCode;
                sessionStorage.setItem("fromCityCode" + controlId, strSightData.cityCode);
            }

        }

        if (sessionStorage.getItem("spnToAirportDetails" + controlId) !== null) {
            document.querySelector('#spnToAirportDetails' + controlId).innerHTML = sessionStorage.getItem("spnToAirportDetails" + controlId);
            document.querySelector('#spnToCityCode' + controlId).innerHTML = sessionStorage.getItem("toCityCode" + controlId);
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "TO");
            document.querySelector('#spnToAirportDetails' + controlId).innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;// "SFO - San Francisco Intl, United States of America, US";
            sessionStorage.setItem("spnToAirportDetails" + controlId, strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode);//"SFO - San Francisco Intl, United States of America, US");
            document.querySelector('#spnToCityCode' + controlId).innerHTML = strSightData.cityCode;
            sessionStorage.setItem("toCityCode" + controlId, strSightData.cityCode);
        }

        if (sessionStorage.getItem("calFrom" + controlId) !== null) {
            document.querySelector('#calFrom' + controlId).value = sessionStorage.getItem("calFrom" + controlId);
        }
        else {
            var currentDate = fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
            var currentDateTemp = currentDate.split(',')[2];
            document.querySelector('#calFrom' + controlId).value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
            const fromDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            sessionStorage.setItem("calFromDateDB" + controlId, fromDateDB);
            //User Story 1070: Change in Search for Single PNR
            sessionStorage.setItem("calFrom" + controlId, currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim());
        }

        if (tripType !== '' && tripType !== 'oneway' && tripType !== 'multi_city') {
            if (sessionStorage.getItem("calTo" + controlId) !== null) {
                document.querySelector('#calTo' + controlId).value = sessionStorage.getItem("calTo" + controlId);
            }
            else {
                var currentDate = toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var currentDateTemp = currentDate.split(',')[2];
                document.querySelector('#calTo' + controlId).value = currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim();
                const toDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                sessionStorage.setItem("calToDateDB" + controlId, toDateDB);
                //User Story 1070: Change in Search for Single PNR
                sessionStorage.setItem("calTo" + controlId, currentDate.substring(0, currentDate.length - 2) + "'" + currentDateTemp.trim());
            }
        }

        if (sessionStorage.getItem("timeSlotFrom" + controlId) !== null) {
            setTimeSlotFromValue(sessionStorage.getItem("timeSlotFrom" + controlId));
            //setTimeSlotFromLabel('Anytime');
        }
        else {
            sessionStorage.setItem("timeSlotFrom" + controlId, "");
            setTimeSlotFromValue('');
            //setTimeSlotFromLabel('Anytime');
        }

        if (sessionStorage.getItem("timeSlotTo" + controlId) !== null) {
            setTimeSlotToValue(sessionStorage.getItem("timeSlotTo" + controlId));
            //setTimeSlotToLabel('Anytime');
        }
        else {
            sessionStorage.setItem("timeSlotTo" + controlId, "");
            setTimeSlotToValue('');
            //setTimeSlotToLabel('Anytime');
        }

        if (minToDateChange !== undefined && minToDateChange !== '' && document.querySelector('#calTo' + controlId) !== null) {
            document.querySelector('#calTo' + controlId).focus();
        }

        if (document.querySelector('#meetingTime') !== null && sessionStorage.getItem("meetingTime") !== null && sessionStorage.getItem("meetingTime") !== '') {
            document.querySelector('#meetingTime').value = sessionStorage.getItem("meetingTime").substring(0, 2) + ":" + sessionStorage.getItem("meetingTime").substring(2, 4);
        }

    }, [minToDateChange])

    if (document.querySelector('#dvTravellers') !== null) {
        let tripReasonList = [];
        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            if (tripReasonAndPolicyDataJson.tripReason.length > 0) {
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }
        }

        if ((travelReq !== undefined && travelReq !== '' && travelReq === true) || (localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true')) {
            if (tripReasonList.length > 0 && tripReasonList[0] !== undefined && tripReasonList[0].isFamilyBooking !== undefined && tripReasonList[0].isFamilyBooking === false) {
                if (document.querySelector('#dvTravellers') !== null) {
                    document.querySelector('#dvTravellers').classList.add('d-none');
                }
                let totalPassenger = 1;

                if (document.querySelector('#btnPassengerDropDown1') !== null) {
                    document.querySelector('#btnPassengerDropDown1').innerHTML = totalPassenger === 1 ? `${totalPassenger} ${t('pax')} <i className='fas fa-caret-down text-primary'></i>` : `${totalPassenger} ${t('paxmulti')} <i className='fas fa-caret-down text-primary'></i>`;
                }

                if (document.querySelector('#spnPassengerAdt1') !== null) {
                    document.querySelector('#spnPassengerAdt1').innerHTML = 1;
                    document.querySelector('#spnPassengerChd1').innerHTML = 0;
                    document.querySelector('#spnPassengerLapInf1').innerHTML = 0;
                    document.querySelector('#spnPassengerSeatInf1').innerHTML = 0;
                }

                sessionStorage.setItem('btnPassengerDropDown1', totalPassenger);
                sessionStorage.setItem('spnPassengerAdt1', 1);
                sessionStorage.setItem('spnPassengerChd1', 0);
                sessionStorage.setItem('spnPassengerLapInf1', 0);
                sessionStorage.setItem('spnPassengerSeatInf1', 0);
            }
            else {
                if (document.querySelector('#dvTravellers') !== null) {
                    document.querySelector('#dvTravellers').classList.remove('d-none');
                }
            }

            sessionStorage.setItem('spnClass1', defaultCabinValue);
            sessionStorage.setItem('btnClassDropDown1', defaultCabinValue);
        }
        else {
            if (document.querySelector('#dvTravellers') !== null) {
                document.querySelector('#dvTravellers').classList.remove('d-none');
            }

            sessionStorage.setItem('spnClass1', defaultCabinValue);
            sessionStorage.setItem('btnClassDropDown1', defaultCabinValue);

            // if (document.querySelector('#btnFirst1') !== null) {
            //     document.querySelector('#btnFirst1').classList.remove('d-none');
            // }

            //old comment before policy based changes
            // document.querySelector('#btnEconomy1').className = "label-select-btn active";
            // sessionStorage.setItem('spnClass1', "Economy");
            // sessionStorage.setItem('btnClassDropDown1', "Economy");
        }
    }

    if (sessionStorage.getItem("spnPassengerAdt1") !== null && document.querySelector('#spnPassengerAdt1') !== null) {
        document.querySelector('#spnPassengerAdt1').innerHTML = sessionStorage.getItem("spnPassengerAdt1");
    }
    else {
        sessionStorage.setItem('spnPassengerAdt1', sessionStorage.getItem("spnPassengerAdt1") !== null ? sessionStorage.getItem("spnPassengerAdt1") : 1);
    }

    if (sessionStorage.getItem("spnPassengerChd1") !== null && document.querySelector('#spnPassengerChd1') !== null) {
        document.querySelector('#spnPassengerChd1').innerHTML = sessionStorage.getItem("spnPassengerChd1");
    }
    else {
        sessionStorage.setItem('spnPassengerChd1', sessionStorage.getItem("spnPassengerChd1") !== null ? sessionStorage.getItem("spnPassengerChd1") : 0);
    }

    if (sessionStorage.getItem("spnPassengerLapInf1") !== null && document.querySelector('#spnPassengerLapInf1') !== null) {
        document.querySelector('#spnPassengerLapInf1').innerHTML = sessionStorage.getItem("spnPassengerLapInf1");
    }
    else {
        sessionStorage.setItem('spnPassengerLapInf1', sessionStorage.getItem("spnPassengerLapInf1") !== null ? sessionStorage.getItem("spnPassengerLapInf1") : 0);
    }

    if (sessionStorage.getItem("spnPassengerSeatInf1") !== null && document.querySelector('#spnPassengerSeatInf1') !== null) {
        document.querySelector('#spnPassengerSeatInf1').innerHTML = sessionStorage.getItem("spnPassengerSeatInf1");
    }
    else {
        sessionStorage.setItem('spnPassengerSeatInf1', sessionStorage.getItem("spnPassengerSeatInf1") !== null ? sessionStorage.getItem("spnPassengerSeatInf1") : 0);
    }

    if (sessionStorage.getItem("btnPassengerDropDown1") !== null && document.querySelector('#btnPassengerDropDown1') !== null) {
        document.querySelector('#btnPassengerDropDown1').innerHTML = sessionStorage.getItem("btnPassengerDropDown1") === "1" ? `${sessionStorage.getItem("btnPassengerDropDown1")} ${t('pax')} <i className='fas fa-caret-down text-primary'></i>` : `${sessionStorage.getItem("btnPassengerDropDown1")} ${t('paxmulti')} <i className='fas fa-caret-down text-primary'></i>`;
    }

    if (sessionStorage.getItem("btnClassDropDown1") !== null && document.querySelector('#btnClassDropDown1') !== null) {
        document.querySelector('#btnClassDropDown1').innerHTML = `${sessionStorage.getItem("btnClassDropDown1")} <i className='fas fa-caret-down text-primary'></i>`;
    }

    useEffect(() => {
        if (defaultCabinValue === '') {
            if (sessionStorage.getItem("spnClass1") !== null) {
                if (document.querySelector('#spnClass1') !== null) {
                    document.querySelector('#spnClass1').innerText = sessionStorage.getItem("spnClass1");
                }
                if (document.querySelector('#btn' + sessionStorage.getItem("spnClass1").replace(' ', '').replace('\n', '') + "1") !== null) {
                    document.querySelector('#btn' + sessionStorage.getItem("spnClass1").replace(' ', '').replace('\n', '') + "1").className = "label-select-btn active";
                }
            }
            else {
                if (document.querySelector('#btnEconomy1') !== null) {
                    document.querySelector('#btnEconomy1').className = "label-select-btn active";
                }
                sessionStorage.setItem('spnClass1', defaultCabinValue);
            }
        }
        else {
            if (document.querySelector('#spnClass1') !== null) {
                document.querySelector('#spnClass1').innerText = defaultCabinValue;
            }
            if (document.querySelector('#btn' + defaultCabinValue.replace(' ', '').replace('\n', '') + "1") !== null) {
                if (document.querySelector('#btnEconomy1') !== null) {
                    document.querySelector('#btnEconomy1').className = "label-select-btn";
                }
                if (document.querySelector('#btnPremiumEco1') !== null) {
                    document.querySelector('#btnPremiumEco1').className = "label-select-btn";
                }
                if (document.querySelector('#btnBusiness1') !== null) {
                    document.querySelector('#btnBusiness1').className = "label-select-btn";
                }
                if (document.querySelector('#btnFirst1') !== null) {
                    document.querySelector('#btnFirst1').className = "label-select-btn";
                }

                document.querySelector('#btn' + defaultCabinValue.replace(' ', '').replace('\n', '') + "1").className = "label-select-btn active";
            }
        }

        if (defaultCabinValue === '') {
            if (sessionStorage.getItem("btnClassDropDown1") !== null && document.querySelector('#btnClassDropDown1') !== null) {
                document.querySelector('#btnClassDropDown1').innerHTML = sessionStorage.getItem("btnClassDropDown1") + " <i className='fas fa-caret-down text-primary'></i>";
                if (sessionStorage.getItem("btnClassDropDown1").replace(' ', '').replace('\n', '') !== null && document.querySelector('#btn' + sessionStorage.getItem("btnClassDropDown1").replace(' ', '').replace('\n', '') + "1") !== null) {
                    document.querySelector('#btn' + sessionStorage.getItem("btnClassDropDown1").replace(' ', '').replace('\n', '') + "1").className = "label-select-btn active";
                }
            }
            else {
                if (document.querySelector('#btnEconomy1') !== null) {
                    document.querySelector('#btnEconomy1').className = "label-select-btn active";
                }
                sessionStorage.setItem('btnClassDropDown1', defaultCabinValue);
            }
        }
        else {
            if (document.querySelector('#btnClassDropDown1') !== null) {
                document.querySelector('#btnClassDropDown1').innerHTML = defaultCabinValue + " <i className='fas fa-caret-down text-primary'></i>";
                if (document.querySelector('#btn' + defaultCabinValue.replace(' ', '').replace('\n', '') + "1") !== null) {
                    if (document.querySelector('#btnEconomy1') !== null) {
                        document.querySelector('#btnEconomy1').className = "label-select-btn";
                    }
                    if (document.querySelector('#btnPremiumEco1') !== null) {
                        document.querySelector('#btnPremiumEco1').className = "label-select-btn";
                    }
                    if (document.querySelector('#btnBusiness1') !== null) {
                        document.querySelector('#btnBusiness1').className = "label-select-btn";
                    }
                    if (document.querySelector('#btnFirst1') !== null) {
                        document.querySelector('#btnFirst1').className = "label-select-btn";
                    }

                    document.querySelector('#btn' + defaultCabinValue.replace(' ', '').replace('\n', '') + "1").className = "label-select-btn active";
                }
            }
            else {
                if (document.querySelector('#btnEconomy1') !== null) {
                    document.querySelector('#btnEconomy1').className = "label-select-btn active";
                }
                sessionStorage.setItem('btnClassDropDown1', defaultCabinValue);
            }
        }

    }, [defaultCabinValue])

    return <Fragment>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="flight_Search_boxed padding-20-15">
                <div ref={refFrom} className="position-relative" >
                    <p onClick={handleOnClickFrom}>{t('from')}</p>
                    <input id={'txtFrom' + controlId} type="text" onClick={handleOnClickFrom} />
                    <div id={'dvFromSpinner' + controlId} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                    <div id={'dvFrom' + controlId} >
                        <ReactSearchAutocomplete
                            onSearch={handleOnFromSearch}
                            items={items}
                            formatResult={formatResult}
                            autoFocus
                            onSelect={handleOnSelectFrom}
                            showNoResults={false}
                            maxResults={15}
                            //showNoResultsText={'Loading...'}
                            //inputDebounce={50}
                            fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                            //inputSearchString=""
                            resultStringKeyName="cityName"
                        />
                    </div>
                </div>
                <span id={'spnFromAirportDetails' + controlId}></span>
                <span className='d-none' id={'spnFromCityCode' + controlId}></span>
                <div className="plan_icon_posation">
                    <i className="fas fa-plane-departure"></i>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="flight_Search_boxed padding-20-15">
                <div ref={refTo}>
                    <p onClick={handleOnClickTo}>{t('to')}</p>
                    <input id={'txtTo' + controlId} type="text" onClick={handleOnClickTo} />
                    <div id={'dvToSpinner' + controlId} className="input-spineer-to"><i className="fa fa-spinner fa-spin"></i></div>
                    <div id={'dvTo' + controlId}>
                        <ReactSearchAutocomplete
                            onSearch={handleOnToSearch}
                            items={items}
                            formatResult={formatResult}
                            autoFocus
                            onSelect={handleOnSelectTo}
                            showNoResults={false}
                            maxResults={15}
                            //showNoResultsText={'Loading...'}
                            //inputDebounce={200}
                            fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                            //inputSearchString=""
                            resultStringKeyName="cityName"
                        />
                    </div>
                </div>
                <span id={'spnToAirportDetails' + controlId}></span>
                <span className='d-none' id={'spnToCityCode' + controlId}></span>
                <div className="plan_icon_posation">
                    <i className="fas fa-plane-departure"></i>
                </div>
                <div className="range_plan">
                    <span>
                        <i style={{ cursor: 'pointer' }} id={'routeSwitch-' + controlId} onClick={switchRoute} className="fas fa-exchange-alt"></i>
                        <ReactTooltip
                            anchorId={'routeSwitch-' + controlId}
                            place="bottom"
                            variant="info"
                            multiline={true}
                            className="tooltip"
                            html={"Swap"}
                        />
                    </span>


                </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="form_search_date flight_Search_boxed">
                <div className="date_flex_area">
                    <div className="Journey_date">
                        <p>{t('departuredate')}</p>
                        {
                            (tripType !== '' && tripType === 'oneway') &&
                            <DateRangePicker
                                //onApply={calGetDateFrom}
                                onHide={calGetDateFrom}
                                initialSettings={{
                                    startDate: sessionStorage.getItem("calFrom1") === null ? fromTodayDate : new Date(sessionStorage.getItem("calFrom1").replace("'", "")), endDate: '', singleDatePicker: true,
                                    showDropdowns: true, autoApply: true, minDate: new Date()
                                }} >
                                <input id={'calFrom' + controlId} type="text" autoComplete='off' />
                            </DateRangePicker>
                        }
                        {
                            (tripType !== '' && tripType !== 'oneway' && tripType !== 'multi_city') &&
                            <DateRangePicker
                                //onApply={calGetDateFrom}
                                onHide={calGetDateFrom}
                                initialSettings={{
                                    startDate: sessionStorage.getItem("calFrom1") === null ? fromTodayDate : new Date(sessionStorage.getItem("calFrom1").replace("'", "")), endDate: '', singleDatePicker: true,
                                    showDropdowns: true, autoApply: true, minDate: new Date()
                                }} >
                                <input id={'calFrom' + controlId} type="text" autoComplete='off' />
                            </DateRangePicker>
                        }
                        {
                            (tripType !== '' && tripType === 'multi_city') &&
                            <DateRangePicker
                                //onApply={calGetDateFrom}
                                onHide={calGetDateFrom}
                                initialSettings={{
                                    startDate: sessionStorage.getItem("calFrom" + controlId) === null ? fromTodayDate : new Date(sessionStorage.getItem("calFrom" + controlId).replace("'", "")), endDate: '', singleDatePicker: true,
                                    showDropdowns: true, autoApply: true, minDate: new Date()
                                }} >
                                <input id={'calFrom' + controlId} type="text" autoComplete='off' />
                            </DateRangePicker>
                        }
                        {
                            localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' &&
                            <div className='col-lg-11 col-sm-12 dropdown-search-text'>
                                {/* <div className='col-lg-12 text-muted my-1 fw-bold text-12px'>Preferred Airlines </div> */}
                                <Dropdown onChange={timeSlotFromChangeHandler}
                                    value={timeSlotOptions.filter(option => option.value === timeSlotFromValue)}
                                    options={timeSlotOptions}
                                    textField="value"
                                    dataItemKey="label"
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control dropdown-search form-select bg_input mb-0'
                                />
                            </div>
                        }
                    </div>
                    {
                        (tripType !== '' && tripType !== 'oneway' && tripType !== 'multi_city') &&
                        <div className="Journey_date">
                            <p>{t('returndate')}</p>
                            <DateRangePicker key={minToDateChange}
                                //onApply={calGetDateTo}
                                onHide={calGetDateTo}
                                initialSettings={{
                                    startDate: sessionStorage.getItem("calTo1") === null ? toTodayDate : new Date(sessionStorage.getItem("calTo1").replace("'", "")), endDate: '', singleDatePicker: true,
                                    showDropdowns: true, autoApply: true, minDate: minToDateChange !== undefined && minToDateChange !== '' ? new Date(minToDateChange) : sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : fromTodayDate
                                }} >
                                <input id={'calTo' + controlId} type="text" autoComplete='off' />
                            </DateRangePicker>

                            {
                                localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' &&
                                <div className='col-lg-11 col-sm-12 dropdown-search-text'>
                                    {/* <div className='col-lg-12 text-muted my-1 fw-bold text-12px'>Preferred Airlines </div> */}
                                    <Dropdown onChange={timeSlotToChangeHandler}
                                        value={timeSlotOptions.filter(option => option.value === timeSlotToValue)}
                                        options={timeSlotOptions}
                                        textField="value"
                                        dataItemKey="label"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control dropdown-search form-select bg_input mb-0'
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    (tripType !== '' && tripType !== 'oneway' && tripType !== 'multi_city') && getConfigurationByBoolen("ALLOW_TIME_WiNDOW_POLICY") && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' &&
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <p className='mt-1'>Meeting Time</p>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <input type="time" id='meetingTime' className="form-control form-control-sm" />
                        </div>
                    </div>
                }
                {
                    (tripType !== '' && tripType !== 'roundtrip') && getConfigurationByBoolen("ALLOW_TIME_WiNDOW_POLICY") && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' && (controlId < 2) &&
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <p className='mt-1'>Meeting Time</p>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <input type="time" id='meetingTime' className="form-control form-control-sm" />
                        </div>
                    </div>
                }
            </div>
        </div>
        {
            (controlId < 2) &&
            <div className='col-lg-2 col-md-6 col-sm-12 col-12'>
                <div id='dvTravellers' className="col-md-12 col-lg-12 col-sm-12">
                    {/* <div className='col-md-12'> */}
                    <div className="flight_Search_boxed dropdown_passenger_area px-2 py-1 mb-1">
                        <p>{t('travellers')} </p>
                        <div className="dropdown">
                            <button onClick={showPassengerDropDown} className="dropdown-toggle final-count"
                                data-toggle="dropdown" type="button"
                                id={'btnPassengerDropDown1'}
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                1 {t('pax')} <i className="fas fa-caret-down text-primary"></i>
                            </button>
                            <div id='dvPassengers1' className="dropdown-menu dropdown_passenger_info"
                                aria-labelledby="btnPassengerDropDown">
                                <div className="traveller-calulate-persons">
                                    <div className="passengers">
                                        <h6>{t('passengers')}</h6>
                                        <div className="passengers-types">
                                            <div className="passengers-type">
                                                <div className="text"><span id={'spnPassengerAdt1'}
                                                    className="count pcount">1</span>
                                                    <div className="type-label">
                                                        <p>{t('adult')}</p>
                                                        <span>{t('adultage')}</span>
                                                    </div>
                                                </div>
                                                <div className="button-set">
                                                    <button type="button" onClick={addPassenger}
                                                        className="btn-add">
                                                        <i id={'addPassengerAdt1'}
                                                            className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" paxType='ADT' onClick={subtractPassenger}
                                                        className="btn-subtract">
                                                        <i id={'subtractPassengerAdt1'}
                                                            className="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="passengers-type">
                                                <div className="text"><span id={'spnPassengerChd1'}
                                                    className="count ccount">0</span>
                                                    <div className="type-label">
                                                        <p
                                                            className="fz14 mb-xs-0">
                                                            {t('child')}
                                                        </p><span>{t('childage')}</span>
                                                    </div>
                                                </div>
                                                <div className="button-set">
                                                    <button type="button" onClick={addPassenger}
                                                        className="btn-add-c">
                                                        <i id={'addPassengerChd1'}
                                                            className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" onClick={subtractPassenger}
                                                        className="btn-subtract-c">
                                                        <i id={'subtractPassengerChd1'}
                                                            className="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="passengers-type">
                                                <div className="text"><span id={'spnPassengerLapInf1'}
                                                    className="count incount">0</span>
                                                    <div className="type-label">
                                                        <p
                                                            className="fz14 mb-xs-0">
                                                            {t('lapinfant')}
                                                        </p><span>{t('lapinfantage')}</span>
                                                    </div>
                                                </div>
                                                <div className="button-set">
                                                    <button type="button" onClick={addPassenger}
                                                        className="btn-add-in">
                                                        <i id={'addPassengerLapInf1'}
                                                            className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" onClick={subtractPassenger}
                                                        className="btn-subtract-in">
                                                        <i id={'subtractPassengerLapInf1'}
                                                            className="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="passengers-type">
                                                <div className="text"><span id={'spnPassengerSeatInf1'}
                                                    className="count incount">0</span>
                                                    <div className="type-label">
                                                        <p
                                                            className="fz14 mb-xs-0">
                                                            {t('seatinfant')}
                                                        </p>
                                                        <span>{t('seatinfantage')}</span>
                                                    </div>
                                                </div>
                                                <div className="button-set">
                                                    <button type="button" onClick={addPassenger}
                                                        className="btn-add-in">
                                                        <i id={'addPassengerSeatInf1'}
                                                            className="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" onClick={subtractPassenger}
                                                        className="btn-subtract-in">
                                                        <i id={'subtractPassengerSeatInf1'}
                                                            className="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className='float-start text-danger mt-2' id='spnInfValidationMsg'></span>
                                        <span className='float-end'><a id='ancHidePassengerDropDown' className="btn btn_theme btn_sm ">{t('apply')}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12 ">
                    {/* <div className='col-md-12'> */}
                    <div className="flight_Search_boxed dropdown_passenger_area px-2 py-1">
                        <p>{t('class')} </p>
                        <div className="dropdown">
                            <button onClick={showClassDropDown} className="dropdown-toggle final-count"
                                data-toggle="dropdown" type="button"
                                id={'btnClassDropDown1'}
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                {t(defaultCabin)} <i className="fas fa-caret-down text-primary"></i>
                            </button>
                            <div id='dvClass1' className="dropdown-menu dropdown_passenger_info show"
                                aria-labelledby="btnClassDropDown">
                                <div className="traveller-calulate-persons">
                                    <div className="cabin-selection">
                                        <h6>{t('class')}</h6>
                                        <div className="cabin-list">
                                            {
                                                cabinList.map((cabinName) =>
                                                    <button key={cabinName.value} id={'btn' + cabinName.value.toString().replace(' ', '') + '1'} type="button" onClick={classHandler}
                                                        className="label-select-btn">
                                                        <span id={'spn' + cabinName.value.toString().replace(' ', '') + '1'}
                                                            className="muiButton-label">{t(cabinName.value.toString().toLowerCase().replace(' ', ''))}
                                                        </span>
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='float-end'>
                                        <span><a id='ancHideClassDropDown' className="btn btn_theme btn_sm ">{t('apply')}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <span id={'spnClass1'}></span> */}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        }
    </Fragment>
};

export default FlightSearchControlMainPanel;
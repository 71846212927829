import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import { v4 as uuidv4 } from 'uuid';
import useHttp from '../../services/use-http';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingSightseeing from './Components/LoadingSightseeing';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GET_ALL_SIGHTSEEING_LIST } from '../../services/CONSTANTS';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { getSingleSightseeing } from '../../services/Sightseeing-API';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const SearchSightseeing = () => {
    const { sendRequest, status, data: loadedSightseeingSearch, error } = useHttp(getSingleSightseeing);
    const { t } = useTranslation(['translation.Sightseeing']);
    const history = useHistory();

    useDocumentTitle('Sightseeing Search');
    const [error1, setError] = useState('');
    const [items, setItems] = useState([]);
    const { detect } = require('detect-browser');
    const browser = detect();
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    const refDestination = useRef(null);
    useOutsideAlerter(refDestination);

    if (sessionStorage.getItem("sightseeingSessionDataJson") === null) {
        const sightseeingSessionDataJson = ({
            "txtDestination": {},
            "spnDestinationCityDetails": {}
        });
        sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionDataJson));
    }

    useEffect(() => {
        if (localStorage.getItem("sightseeingSuggestion") === null || localStorage.getItem("sightseeingSuggestion").length < 3) {
            let sightseeingSuggestionResutTemp = [];
            fetch(GET_ALL_SIGHTSEEING_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            sightseeingSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
                });
        }
        localStorage.removeItem("sightseeingData");
        if (document.querySelector('#theme_search_form_tour2') !== null) {
            document.querySelector('#theme_search_form_tour2').classList.add('d-none');
        }

    }, [])

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        if (document.querySelector("#lblFlights") !== null) {
            document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights").classList.add("text-primary");
        }

        if (document.querySelector("#lblFlightPlusHotel") !== null) {
            document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights").classList.add("text-primary");
            }

            document?.querySelector("#lblHotel")?.classList?.remove("text-primary");
            document?.querySelector("#lblHotel")?.classList?.add("flight-active-tab");
        }

        if (document.querySelector("#lblHotel") !== null) {
            document?.querySelector("#lblHotel")?.classList?.remove("flight-active-tab");
            document?.querySelector("#lblHotel")?.classList?.add("text-primary");
        }

        if (document.querySelector("#lblSightseeing") !== null) {
            document.querySelector("#lblSightseeing").classList.remove("text-primary");
            document.querySelector("#lblSightseeing").classList.add("flight-active-tab");
        }

        if (document.querySelector("#lblHolidays") !== null) {
            document.querySelector("#lblHolidays").classList.add("text-primary");
            document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
        }
        if (document.querySelector("#lblVisa") !== null) {
            document.querySelector("#lblVisa").classList.add("text-primary");
            document.querySelector("#lblVisa").classList.remove("flight-active-tab");
        }

        if (valueParam.sightseeing === "partial") {
            localStorage.setItem('traceID', JSON.stringify(uuidv4()));
            if (document.getElementById('sightseeingIframe') !== null) {
                document.getElementById('sightseeingIframe').classList.add("d-none");
            }

            window.scrollTo({
                top: 310,
                behavior: "smooth",
            });

            sendRequest(valueParam.req);
        }

    }, [])

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref.current.innerText === "Destination") {
                        document.querySelector('#dvDestination').style.display = "none";
                        document.querySelector('#txtDestination').style.display = "";
                        document.querySelector('#spnDestinationCityDetails').style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleOnClickDestination = () => {
        document.querySelector("#txtDestination").style.display = "none";
        document.querySelector("#dvDestination").style.display = "";
        document.querySelector('#spnDestinationCityDetails').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[0] !== undefined) {
                controlCount[0].focus();
            }
        }
    }

    const handleOnDestinationSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("sightseeingSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("sightseeingSuggestion")));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.cityCode} - {item.countryCode}</span>
            </>
        )
    }

    const handleOnSelectDestination = (item) => {
        let sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
        sightseeingSessionJson.txtDestination = item.cityName;
        sightseeingSessionJson.spnDestinationCityDetails = item.cityCode + " - " + item.cityName + ", " + item.countryName + ", " + item.countryCode;

        sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));


        document.querySelector("#txtDestination").style.display = "";
        document.querySelector('#txtDestination').value = item.cityName;
        document.querySelector("#dvDestination").style.display = "none";
        document.querySelector('#spnDestinationCityDetails').style.display = "";
        document.querySelector('#spnDestinationCityDetails').innerHTML = item.cityCode + " - " + item.cityName + ", " + item.countryName + ", " + item.countryCode;

    }

    const onSearchHandler = useCallback((event) => {
        localStorage.setItem('traceID', JSON.stringify(uuidv4()));
        if (document.getElementById('sightseeingIframe') !== null) {
            document.getElementById('sightseeingIframe').classList.add("d-none");
        }

        window.scrollTo({
            top: 310,
            behavior: "smooth",
        });

        event.preventDefault();

        sendRequest(document.querySelector('#spnDestinationCityDetails').innerHTML.split('-')[0].trim());

    }, [sendRequest])

    const errorHandler = () => {
        setError(false);

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (status === 'completed') {
            if (loadedSightseeingSearch !== null && loadedSightseeingSearch !== '') {
                if (loadedSightseeingSearch.errors.status === 'FALSE') {

                    if (loadedSightseeingSearch.data.length > 0) {
                        localStorage.setItem("sightseeingData", loadedSightseeingSearch.data[0].partnerID.trim() + "," + loadedSightseeingSearch.data[0].widgetRef.trim());
                    }

                    if (localStorage.getItem("browserBase") !== null) {
                        if (browser) {
                            if (browser.name === 'firefox') {
                                window.open('SightseeingDetailsTab?partnerId=' + loadedSightseeingSearch.data[0].partnerID + '&widgetRef=' + loadedSightseeingSearch.data[0].widgetRef);
                            }
                            else {
                                if (document.getElementById('sightseeingIframe') !== null) {
                                    document.getElementById('sightseeingIframe').classList.remove("d-none");
                                    document.getElementById('sightseeingIframe').src = document.getElementById('sightseeingIframe').src
                                    //document.getElementById('sightseeingIframe').contentDocument.location.reload(true);
                                }

                                if (document.querySelector('#theme_search_form_tour2') !== null) {
                                    document.querySelector('#theme_search_form_tour2').classList.remove('d-none');
                                }
                            }
                        }
                        else {
                            if (document.getElementById('sightseeingIframe') !== null) {
                                document.getElementById('sightseeingIframe').classList.remove("d-none");
                                document.getElementById('sightseeingIframe').src = document.getElementById('sightseeingIframe').src
                                //document.getElementById('sightseeingIframe').contentDocument.location.reload(true);
                            }

                            if (document.querySelector('#theme_search_form_tour2') !== null) {
                                document.querySelector('#theme_search_form_tour2').classList.remove('d-none');
                            }
                        }
                    }
                    else {
                        if (document.getElementById('sightseeingIframe') !== null) {
                            document.getElementById('sightseeingIframe').classList.remove("d-none");
                            document.getElementById('sightseeingIframe').src = document.getElementById('sightseeingIframe').src
                            //document.getElementById('sightseeingIframe').contentDocument.location.reload(true);
                        }

                        if (document.querySelector('#theme_search_form_tour2') !== null) {
                            document.querySelector('#theme_search_form_tour2').classList.remove('d-none');
                        }
                    }
                }
                else {
                    localStorage.removeItem("sightseeingData");
                    if (document.querySelector('#theme_search_form_tour2') !== null) {
                        document.querySelector('#theme_search_form_tour2').classList.add('d-none');
                    }

                    if (loadedSightseeingSearch.errors.status === 'TRUE') {
                        setError({
                            title: "Message",
                            message: `${t('ErrorMessage')}`,
                        });
                    }
                }
            }
            else {
                setError({
                    title: "Message",
                    message: 'Some thing went wrong.',
                });
                return;
            }
        }

    }, [status, loadedSightseeingSearch])


    let sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));

    const ErrorShow = (error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    useEffect(() => {
        document.querySelector('#dvDestinationSpinner').style.display = "none";
        if (localStorage.getItem("sightseeingSuggestion") != null) {
            setItems(JSON.parse(localStorage.getItem("sightseeingSuggestion")));
        }
        document.querySelector("#dvDestination").style.display = "none";


        if (Object.keys(sightseeingSessionJson.txtDestination).length > 0) {
            document.querySelector("#txtDestination").value = sightseeingSessionJson.txtDestination;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight.fromToListResponse.find(x => x.service == "SS");
            document.querySelector("#txtDestination").value = strSightData.cityName;//"New York City";
            sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
            sightseeingSessionJson.txtDestination = strSightData.cityName;//"New York City";
            sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));
        }
        var strSight = JSON.parse(localStorage.getItem("fromToListResponse"))

        if (Object.keys(sightseeingSessionJson.spnDestinationCityDetails).length > 0) {
            document.querySelector('#spnDestinationCityDetails').innerHTML = sightseeingSessionJson.spnDestinationCityDetails;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight.fromToListResponse.find(x => x.service == "SS");
            document.querySelector('#spnDestinationCityDetails').innerHTML = strSightData.cityCode + " - " + strSightData.cityName + ", " + strSightData.countryName + ", " + strSightData.countryCode; // "NYC - New York City, United States of America, US";

            sightseeingSessionJson = JSON.parse(sessionStorage.getItem("sightseeingSessionDataJson"));
            sightseeingSessionJson.spnDestinationCityDetails = strSightData.cityCode + " - " + strSightData.cityName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            sessionStorage.setItem("sightseeingSessionDataJson", JSON.stringify(sightseeingSessionJson));
        }

    }, [])

    const controlHandlerFightSearch = (event) => {
        if (event.target.id === 'lblFlights') {
            history.push('/FlightSearch?Flights=1');
        }
        else if (event.target.id === 'lblHotel') {
            history.push('/SearchHotel');
        }
        else if (event.target.id === 'lblFlightPlusHotel') {
            history.push('/FlightSearch?FlightPlusHotel=1');
        }
        else if (event.target.id === 'lblSightseeing') {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.add("text-primary");
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel") !== null) {
                document.querySelector("#lblHotel").classList.add("text-primary");
                document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing") !== null) {
                document.querySelector("#lblSightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblSightseeing").classList.remove("text-primary");
            }

            if (document.querySelector("#lblHolidays") !== null) {
                document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays").classList.add("text-primary");
            }
        }
        else if (event.target.id === 'lblHolidays') {
            //history.push('/SearchHolidays');
            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
        else if (event.target.id === 'lblVisa') {
            history.push('/SearchVisa')

        }
    }
  
    return <Fragment>
        {
            error1 && <ErrorModal
                title={error1.title}
                message={t(error1.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }
        <section className="sub-banner">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">
            </div>
        </section>

        <section id="theme_search_form_tour" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative my-2">
                            <div className="row position-relative">
                                {/* {status === 'pending' &&
                                    <LoadingSightseeing action='search' ></LoadingSightseeing>
                                } */}
                                <div className="col-lg-12">
                                    <div className="tour_search_form">
                                        <form action="!#">
                                            <div className="row">
                                                <label for="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                                                <div className='text-sm-center1 mb-2'>
                                                    <span>
                                                        {
                                                            (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                                            <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                                        }
                                                        {
                                                            (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                                                getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                                                && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) ?
                                                                <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                                                <label id='lblFlightPlusHotel' ></label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("ALLOW_VISA") === true && checkSecurityDetails("Book", "e-Visa") && checkSecurityDetails("Book", "e-Visa", "Book_e-Visa_Allow e-Visa") &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblVisa' onClick={controlHandlerFightSearch}><i class="fas fa-passport"></i> e-Visa</label>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="flight_Search_boxed py-2">
                                                        <div className='pb-1 h5 mb-0' id='dvFrom5'>
                                                            <div ref={refDestination} className="position-relative" >
                                                                <p onClick={handleOnClickDestination}>{t('Destination')} </p>
                                                                <div className=''>
                                                                    <input id={'txtDestination'} type="text" onClick={handleOnClickDestination} />
                                                                    <div id='spnDestinationCityDetails' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                                                </div>
                                                                <div id={'dvDestinationSpinner'} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                                                <div id={'dvDestination'} >
                                                                    <ReactSearchAutocomplete
                                                                        onSearch={handleOnDestinationSearch}
                                                                        items={items}
                                                                        formatResult={formatResult}
                                                                        autoFocus
                                                                        onSelect={handleOnSelectDestination}
                                                                        showNoResults={false}
                                                                        maxResults={15}
                                                                        fuseOptions={{ keys: ["cityCode", "cityName"] }}
                                                                        resultStringKeyName="cityName"

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="top_form_search_button my-3">
                                                    {/* {status === 'pending' ? <span className="btn btn_theme btn_md mt-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-spinner fa-spin" ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>} */}
                                                    <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour2" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row position-relative">
                    {
                        isMobile &&
                        <div className="col-lg-12 my-4">
                            <iframe id='sightseeingIframe' src="SightseeingDetails" height={3000} allow="camera; microphone" ></iframe>
                        </div>
                    }
                    {
                        !isMobile &&
                        <div className="col-lg-12 my-4">
                            <iframe id='sightseeingIframe' src="SightseeingDetails" height={3000} scrolling='no' allow="camera; microphone" ></iframe>
                        </div>
                    }
                </div>
            </div>
        </section>
        {/*    <!-- End Main --> */}

    </Fragment>
};

export default SearchSightseeing;
import { getConfigurationByValue } from './ConfigurationsFunction'

export const getApprovalID = () => {

    const approvalType = getConfigurationByValue("APPROVER_TYPE").split("-")[0];
    if (approvalType === "FIRST")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).costCentreID;
    else if (approvalType === "SECOND")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).departmentID;
    else if (approvalType === "THIRD")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).positionID;
}


export const getPolicyID = () => {

    const policyType = getConfigurationByValue("POLICY_TYPE");
    
    if (policyType === "FIRST")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).costCentreID;
    else if (policyType === "SECOND")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).departmentID;
    else if (policyType === "THIRD")
        return localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).positionID;
}

import { Fragment, useEffect, useState, useMemo } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PopupModal from "../../../components/Common/Flight/PrintPopupModal";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import ApproverDetails from "../Components/SubComponents/ApproverDetails"
import Flight from "../../OBT/RetreiveTravelRequest/Flight/Flight";
import HotelDetails from "../../OBT/RetreiveTravelRequest/Hotel/HotelDetails";
import ServiceRequest from "../../OBT/RetreiveTravelRequest/ServiceRequest";
import { useLocation } from 'react-router-dom';
import Document from "../../OBT/RetreiveTravelRequest/Document";
import PopupMedium from "../../../components/Common/Flight/PopupMedium";
import MdModal from "../../Flights/MdModal";
import useHttp from "../../../services/use-http";
import { getApprovalHistory } from '../../../services/Approver-API';
import ApprovalHistory from "./SubComponents/ApprovalHistory";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import PrintModule from "../../OBT/RetreiveTravelRequest/PrintModule"
import BookingDetails from "../../OBT/RetreiveTravelRequest/BookingDetails";
import MeetingDetails from "../../OBT/RetreiveTravelRequest/MeetingDetails";
import { getTravelRequestJsonById } from "../../../indexedDatabase/ViewTravelRequest_indexDb";
import { useParams } from 'react-router-dom';


const RetrieveTravelRequest = (props) => {
    useDocumentTitle("Travel Request View");
    const [errorMail, setErrorMail] = useState();
    const [approveRejectModal, setApproveRejectModal] = useState(null);
    const [error1, setError] = useState();
    const [travelRequestData, setTravelRequestData] = useState(null);
    //If the source is email, this represents the querystring value in JSON format
    const [queryJSON] = useState(JSON.parse(localStorage.getItem('emailQueryJSON')));
    let myTripData = sessionStorage.getItem("myTripData") !== null ? JSON.parse(sessionStorage.getItem("myTripData")) : null;
    const userID = props.isThroughMail ? queryJSON?.UserID : JSON.parse(localStorage.getItem('userID'));
    const isCorporate = props.isThroughMail ? true : JSON.parse(localStorage.getItem("isCorporate"));
    const { sendRequest: sendGetApprovalHistory, data: approvalHistoryResponse, status: approvalHistoryRequestStatus } = useHttp(getApprovalHistory);
    const [popupMediumModal, setPopupMediumModal] = useState(null);
    const parms = useParams();

    const { Id } = parms;
    const isMyQueue = sessionStorage.getItem("isMyQueue") !== null ? JSON.parse(sessionStorage.getItem("isMyQueue")) : null;
    let onlineFlightLowestDetails = travelRequestData !== null && travelRequestData.data !== undefined && travelRequestData.data.onlineFlightLowestDetails !== undefined ? travelRequestData.data.onlineFlightLowestDetails : "";
    const lowestIdMap = {};

    const isServiceWiseApproval = useMemo(() => {
        let computedResult = false;
        if (props.isThroughMail) {
            if (queryJSON.ServiceTypeID &&
                queryJSON.ServiceTypeID.length &&
                queryJSON.ServiceTypeID !== 'ALL') {
                computedResult = true;
            }
        }
        else {
            computedResult = sessionStorage.getItem("isServiceWiseApproval") ?
                JSON.parse(sessionStorage.getItem("isServiceWiseApproval")) : false;
        }
        return computedResult;
    }, []);

    if (onlineFlightLowestDetails !== undefined && onlineFlightLowestDetails.length !== 0) {
        onlineFlightLowestDetails.forEach(detail => {
            if (!lowestIdMap[detail.lowestID]) {
                lowestIdMap[detail.lowestID] = [];
            }
            lowestIdMap[detail.lowestID].push(detail);
        });
    }

    const moduleName = useMemo(() => {
        let module = 'My Trips';
        if (isMyQueue) {
            module = 'My Queue';
        }
        return module;
    }, [isMyQueue]);

    const moduleNameWithoutSpace = useMemo(() => {
        return moduleName.replace(/ /g, "");
    }, [moduleName]);

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        if (props.isThroughMail) {
            setTravelRequestData(props.travelRequestData)
        } else if (Id !== undefined) {

            getTravelRequestJsonById(Id).then((data) => {
                if (data) {
                    setTravelRequestData(data)
                } else {
                    console.log("no data found")
                }
            }).catch((error) => {
                console.log(error);
            })
        }

    }, [Id, props.isThroughMail])

    function showApproveRejectLink(contextInfo) {
        let showLink = false;
        if (isCorporate &&
            isMyQueue &&
            contextInfo.approvalStatusCode &&
            checkSecurityDetails("My Queue", "MyQueueApproveRejectRequest", "My Queue_MyQueueApproveRejectRequest_Allow Approve/Reject Request")) {
            if (contextInfo.approvalStatusCode !== 'AP') {
                if (contextInfo.approvalStatusCode === 'PD') {

                    if (contextInfo.historyApprovalStatusCode && contextInfo.historyApprovalStatusCode.length) {
                        if (contextInfo.historyApprovalStatusCode === 'PD' ||
                            contextInfo.historyApprovalStatusCode === 'UT') {
                            showLink = true;
                        }
                        else if ((contextInfo.historyApprovalStatusCode === 'AP' ||
                            contextInfo.historyApprovalStatusCode === 'UA') &&
                            contextInfo.historyApprovalUserID !== userID) {
                            showLink = true;
                        }
                    }
                    else {
                        //first approval stage
                        showLink = contextInfo.approvalUserID === userID;
                    }

                }
            }
        }
        return showLink;
    }
    function approvalStatusHtml() {
        let statusHtml = "";
        let statusCode = travelRequestData !== null && travelRequestData.data.approvalWorkflowQueue[0].approvalStatusCode;
        switch (statusCode) {
            case "PD":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-warning">
                        <i className="fa-solid fa-pause"></i> Pending
                    </span>
                );
                break;
            case "AP":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-success">
                        <i className="fa-solid fa-circle-check"></i> Approved
                    </span>
                );
                break;
            case "RJ":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-danger">
                        <i className="fa-solid fa-circle-xmark"></i> Rejected
                    </span>
                );
                break;
            case "UT":
                statusHtml = (
                    <span className="badge rounded-pill text-16px  bg-primary">
                        <i className="fa-solid fa-check-circle"></i> Updated for Traveler
                    </span>
                );
                break;
            default:
                statusHtml = travelRequestData !== null && travelRequestData.data.approvalWorkflowQueue[0].approvalStatusCode;
                break;
        }
        return statusHtml;
    };



    useEffect(() => {
        if (approvalHistoryResponse) {
            if (approvalHistoryResponse.errors.status === 'TRUE') {
                setError(() => {
                    return {
                        title: "Message",
                        message: "Something went wrong.",
                    }
                });
            }
            else {
                setPopupMediumModal(() => {
                    return {
                        title: "History",
                        message: <ApprovalHistory
                            approvalTypeName={approvalHistoryResponse.approvalTypeName}
                            data={approvalHistoryResponse.data}
                            isServiceWiseApproval={isServiceWiseApproval}
                        >

                        </ApprovalHistory>
                    }
                });
            }
        }
    }, [approvalHistoryResponse]);

    const errorHandler = () => {
        setErrorMail(null);
        setApproveRejectModal(null);
        setError(null);
        setPopupMediumModal(null);
    };


    const onApprovalStatusUpdate = (isSuccessful) => {
        setApproveRejectModal(null);
        if (isSuccessful) {
            setError({
                title: "Message",
                message: "Status updated successfully.",
                resetPage: true
            });
        }
        else {
            setError({
                title: "Message",
                message: "There was a problem updating status.",
                resetPage: false
            });
        }
    };

    function handleApproveRejectClick(contextInfo, serviceTypeID) {
        setApproveRejectModal(() => {
            return {
                title: "Status Update",
                message: <ApproverDetails
                    userID={userID}
                    officeID={JSON.parse(localStorage.getItem('officeID'))}
                    shoppingID={travelRequestData.data.shoppingID}
                    approvalTypeID={contextInfo.approvalTypeID}
                    approvalMasterID={contextInfo.approvalMasterID}
                    confirmationNumber={travelRequestData.data.referenceNumber}
                    isSourceEmail={false}
                    onApprovalStatusUpdate={onApprovalStatusUpdate}
                    serviceTypeID={serviceTypeID}
                    errorHandler={() => errorHandler(false)}>
                </ApproverDetails>
            }
        });
    }
    function handleViewApprovalHistoryClick() {
        const request = {
            officeID: travelRequestData.officeID,
            approvalMasterID: sessionStorage.getItem("approvalMasterID") !== null ? JSON.parse(sessionStorage.getItem("approvalMasterID")) : "",
            shoppingID: travelRequestData.data.shoppingID
        };

        sendGetApprovalHistory(request);
    }


    return (<Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        {approveRejectModal && (
            <PopupMedium
                title={approveRejectModal.title}
                message={approveRejectModal.message}
                onConfirm={() => errorHandler(false)}
                showOKButton={false}
                showLoader={false}
            ></PopupMedium>
        )}
        {popupMediumModal && (
            <PopupMedium
                title={popupMediumModal.title}
                message={popupMediumModal.message}
                onConfirm={() => errorHandler(false)}
            ></PopupMedium>
        )}
        <div >

            {!props.isThroughMail && <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>}
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row theme_search_form_area mb-3">
                                <div className='col-md-6'>
                                    <label htmlFor="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Travel Request View   {approvalStatusHtml()}</label>
                                </div>
                                <div className='col-md-6'>
                                    {checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print") &&
                                        <span className='float-lg-end'>
                                            <PrintModule isThroughMail={true} travelRequestData={travelRequestData} />
                                        </span>
                                    }

                                    {!isServiceWiseApproval && showApproveRejectLink(myTripData) &&
                                        <div className='float-lg-end'>
                                            <span className=''>
                                                <button id={"updateApprovalStatus" + myTripData.id}
                                                    className="btn btn-sm  text-white mt-1 ms-1 gt-btn-primary  gt-yellow-btn-spinner"
                                                    data bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    onClick={() => handleApproveRejectClick(myTripData, '')}>
                                                    <i className="fa-sharp fa-solid fa-user-plus"></i>
                                                </button>
                                                <ReactTooltip
                                                    anchorId={"updateApprovalStatus" + myTripData.id}
                                                    place="bottom"
                                                    variant="info"
                                                    multiline={true}
                                                    className="tooltip"
                                                    html={myTripData.approvalTypeID === '6' ? "Update Service Request" : "Approve | Reject"}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {isCorporate && travelRequestData !== null && travelRequestData.data.approvalWorkflowQueue[0].approvalStatusCode &&
                                        checkSecurityDetails(moduleName, `${moduleNameWithoutSpace}ViewApprovalHistory`, `${moduleName}_${moduleNameWithoutSpace}ViewApprovalHistory_Allow View Approval History`) &&
                                        <div className='float-lg-end'>
                                            <span className=''>
                                                <button id={"viewApprovalHistory"}
                                                    className="btn btn-sm  text-white mt-1 ms-1  btn-secondary"
                                                    data bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    onClick={handleViewApprovalHistoryClick}>
                                                    {approvalHistoryRequestStatus === 'pending' ?
                                                        <i className="fa fa-spinner fa-spin" ></i> :
                                                        <i className="fas fa-history"></i>
                                                    }
                                                </button>
                                                <ReactTooltip
                                                    anchorId={"viewApprovalHistory"}
                                                    place="bottom"
                                                    variant="info"
                                                    multiline={true}
                                                    className="tooltip"
                                                    html={"View History"}
                                                />
                                            </span>
                                        </div>
                                    }

                                    {/*{myTripData.product.flightDetails !== undefined && showApproveRejectLink(myTripData.product.flightDetails) &&*/}
                                    {/*    <span className=''>*/}
                                    {/*        <button id={"updateApprovalStatusFL" + myTripData.id}*/}
                                    {/*            className="btn btn-sm  text-white ms-1 gt-border-radius-50  gt-btn-primary  gt-yellow-btn-spinner"*/}
                                    {/*            data bs-toggle="tooltip"*/}
                                    {/*            data-bs-placement="top"*/}
                                    {/*            onClick={() => handleApproveRejectClick(myTripData.product.flightDetails, 'FL')}>*/}
                                    {/*            <i className="fa-sharp fa-solid fa-user-plus"></i>*/}
                                    {/*        </button>*/}
                                    {/*        <ReactTooltip*/}
                                    {/*            anchorId={"updateApprovalStatusFL" + myTripData.id}*/}
                                    {/*            place="bottom"*/}
                                    {/*            variant="info"*/}
                                    {/*            multiline={true}*/}
                                    {/*            className="tooltip"*/}
                                    {/*            html={"Approve | Reject"}*/}
                                    {/*        />*/}
                                    {/*    </span>*/}
                                    {/*}*/}
                                    {/*{myTripData.product.hotelDetails !== undefined && showApproveRejectLink(myTripData.product.hotelDetails) &&*/}
                                    {/*    <span className=''>*/}
                                    {/*        <button id={"updateApprovalStatusHT" + myTripData.id}*/}
                                    {/*            className="btn btn-sm  text-white ms-1 gt-border-radius-50  gt-btn-primary  gt-yellow-btn-spinner"*/}
                                    {/*            data bs-toggle="tooltip"*/}
                                    {/*            data-bs-placement="top"*/}
                                    {/*            onClick={() => handleApproveRejectClick(myTripData.product.hotelDetails, 'HT')}>*/}
                                    {/*            <i className="fa-sharp fa-solid fa-user-plus"></i>*/}
                                    {/*        </button>*/}
                                    {/*        <ReactTooltip*/}
                                    {/*            anchorId={"updateApprovalStatusHT" + myTripData.id}*/}
                                    {/*            place="bottom"*/}
                                    {/*            variant="info"*/}
                                    {/*            multiline={true}*/}
                                    {/*            className="tooltip"*/}
                                    {/*            html={"Approve | Reject"}*/}
                                    {/*        />*/}
                                    {/*    </span>*/}
                                    {/*}*/}
                                    {/*{myTripData.product.serviceRequest !== undefined && showApproveRejectLink(myTripData.product.serviceRequest) &&*/}
                                    {/*    <span className="float-md-end float-lg-end">*/}
                                    {/*        <button id={"updateApprovalStatusSR" + myTripData.id}*/}
                                    {/*            className="btn btn-sm  text-white ms-1 gt-border-radius-50  gt-btn-primary  gt-yellow-btn-spinner"*/}
                                    {/*            data bs-toggle="tooltip"*/}
                                    {/*            data-bs-placement="top"*/}
                                    {/*            onClick={() => handleApproveRejectClick(myTripData.product.serviceRequest, 'SR')}>*/}
                                    {/*            <i className="fa-sharp fa-solid fa-user-plus"></i>*/}
                                    {/*        </button>*/}
                                    {/*        <ReactTooltip*/}
                                    {/*            anchorId={"updateApprovalStatusSR" + myTripData.id}*/}
                                    {/*            place="bottom"*/}
                                    {/*            variant="info"*/}
                                    {/*            multiline={true}*/}
                                    {/*            className="tooltip"*/}
                                    {/*            html={"Update Service Request"}*/}
                                    {/*        />*/}
                                    {/*    </span>*/}
                                    {/*}*/}

                                </div>

                                {travelRequestData !== null &&
                                    <div className="col-lg-12 ">
                                        <BookingDetails travelRequestData={travelRequestData} page={"retreive"} />
                                        <MeetingDetails
                                            meetingDetails={travelRequestData.data.meetingDetails}
                                            page={"retreive"} />
                                        {travelRequestData.data.onlineFlightDetails !== undefined &&
                                            <div>
                                                <label htmlFor="" className="h5 text-primary mt-5">
                                                    <span className='gt-border-1px'> <i className="fab fa-avianex"></i> Selected Flight Details</span>
                                                </label>
                                                <Flight flightDetails={travelRequestData !== null && travelRequestData.data.onlineFlightDetails}
                                                    id={"onlineFlightDetails"}
                                                    fareDetails={travelRequestData !== null && travelRequestData.data.onlineFlightFareDetails}
                                                    id1={"onlineFlightDetails"}
                                                    rerefundableType={travelRequestData !== null && travelRequestData.data.onlineFlightFareDetails[0].refundableType}
                                                    page={"retreive"}></Flight>
                                            </div>
                                        }

                                        <div className='card'>
                                            <div className=" text-dark card-bg-blue mb-2">
                                                <div className="card-body">
                                                    <div className="col-md-12">
                                                    {isMyQueue && travelRequestData !== null && travelRequestData.data.onlineFlightLowestDetails !== undefined &&
                                                    travelRequestData !== null && travelRequestData.data.onlineFlightFareDetails !== undefined &&
                                                    travelRequestData.data.onlineFlightFareDetails[0] !== undefined &&
                                                    <>
                                                        {/* <hr className="my-3"></hr> */}

                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <p class="card-text mb-0 mt-1"><strong>Policy </strong>{travelRequestData.data.onlineFlightFareDetails[0].inPolicy === true ? 'In Policy' : 'Out of Policy'}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }


                                                {isMyQueue && travelRequestData !== null && travelRequestData.data.onlineFlightLowestDetails !== undefined &&
                                                    <div>
                                                        <p className="h6 mt-3 text-primary fw-bold">Reason for selecting Out of Policy Flight</p>
                                                        {travelRequestData.data.onlineFlightLowestFareDetails[0].reasonName !== undefined &&
                                                            travelRequestData.data.onlineFlightLowestFareDetails[0].reasonName !== '' &&
                                                            <div className="row">
                                                                <div class="col-md-12">
                                                                    <p class="card-text mb-0 "><strong>Reason </strong></p>
                                                                    <p class="card-text mb-0">{travelRequestData.data.onlineFlightLowestFareDetails[0].reasonName}</p>
                                                                </div>
                                                            </div>
                                                        }

                                                        {travelRequestData.data.onlineFlightLowestFareDetails[0].remarks !== undefined &&
                                                            travelRequestData.data.onlineFlightLowestFareDetails[0].remarks !== '' &&
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <hr className="my-2"></hr>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <p class="card-text mb-0 mt-1"><strong>Remarks </strong></p>
                                                                    <p class="card-text mb-0">{travelRequestData.data.onlineFlightLowestFareDetails[0].remarks}</p>
                                                                </div>
                                                                {/* <div className='control col-lg-12'>
                                                                    <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Remarks</label>
                                                                    <input className="mb-2 form-control form-control-sm col-lg-12"
                                                                        type='text' readOnly
                                                                        value={travelRequestData.data.onlineFlightLowestFareDetails[0].remarks}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        }

                                                        <label htmlFor="" className="h5 text-primary mt-5">
                                                            <span className='gt-border-1px'> <i className="fab fa-avianex"></i> Lowest Flight Details</span>
                                                        </label>
                                                        {Object.keys(lowestIdMap).map(lowestId =>
                                                            <Flight key={"onlineFlightLowestDetails" + lowestId}
                                                                flightDetails={lowestIdMap[lowestId]}
                                                                id={"onlineFlightLowestDetails" + lowestId}
                                                                fareDetails={travelRequestData.data.onlineFlightLowestFareDetails}
                                                                id1={lowestId} page={"retreive"}></Flight>
                                                        )}
                                                    </div>
                                                }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>        

                                        {travelRequestData !== null && travelRequestData.data.onlineHotelDetails !== undefined &&
                                            <>
                                                <div className="gt-hotel-title">
                                                    <label htmlFor="" className="h5 text-primary mt-5">
                                                        <span className="gt-border-1px"> <i className="fas fa-hotel"></i> Selected Hotel Details</span>
                                                    </label>
                                                    <HotelDetails hotelDetails={travelRequestData.data.onlineHotelDetails[0]}
                                                        hotelType={"onlineHotelDetails"} page={"retreive"}>
                                                    </HotelDetails>
                                                    {/* {isMyQueue &&
                                                        <hr className="my-2"></hr>
                                                    } */}
                                                </div>

                                               
                                            </>
                                        }

                                      

                                        {isMyQueue && travelRequestData !== null && travelRequestData.data.onlineHotelLowestDetails !== undefined &&
                                        <div className='card gt-hotel-title'>
                                            <div className=" text-dark card-bg-blue mb-2">
                                                <div className="card-body">
                                                    <div className="col-md-12">
                                                        <div>
                                                            {isMyQueue &&
                                                                <div className="row">

                                                                    <div class="col-md-12">
                                                                        <p class="card-text mb-0 "><strong>Policy </strong></p>
                                                                        <p class="card-text mb-0">{travelRequestData.data.onlineHotelDetails[0].inPolicy ? 'In Policy' : 'Out of Policy'}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <p className="h6 mt-3 text-primary fw-bold">Reason for selecting Out of Policy Hotel</p>
                                                            {travelRequestData.data.onlineHotelLowestDetails[0].reasonName !== undefined &&
                                                                travelRequestData.data.onlineHotelLowestDetails[0].reasonName !== '' &&
                                                                <div className="row">
                                                                    <div class="col-md-12">
                                                                        <p class="card-text mb-0 "><strong>Reason </strong></p>
                                                                        <p class="card-text mb-0">{travelRequestData.data.onlineHotelLowestDetails[0].reasonName}</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {travelRequestData.data.onlineHotelLowestDetails[0].remarks !== undefined &&
                                                                travelRequestData.data.onlineHotelLowestDetails[0].remarks !== '' &&
                                                                <div className="row">

                                                                    <div className="col-md-12">
                                                                        <hr className="my-2"></hr>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <p class="card-text mb-0 mt-1"><strong>Remarks </strong></p>
                                                                        <p class="card-text mb-0">{travelRequestData.data.onlineHotelLowestDetails[0].remarks}</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                            <label htmlFor="" className="h5 text-primary mt-5">
                                                                <span className="gt-border-1px"> <i className="fas fa-hotel"></i> Lowest Hotel Details</span>
                                                            </label>
                                                            {travelRequestData.data.onlineHotelLowestDetails.map(hotel => (
                                                                <HotelDetails hotelDetails={hotel}
                                                                    key={"lowestHotel" + hotel.lowestID}
                                                                    hotelType={'onlineHotelLowestDetails'}
                                                                    id={hotel.lowestID} page={"retreive"}>
                                                                </HotelDetails>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                
                                        }
                                       

                                        {travelRequestData !== null && travelRequestData.data.serviceRequest !== undefined &&
                                            <ServiceRequest serviceRequest={travelRequestData.data.serviceRequest} page={"retreive"}></ServiceRequest>

                                        }

                                        {travelRequestData !== null && travelRequestData.data.travelRequestDocuments !== undefined &&
                                            <Document documents={travelRequestData.data.travelRequestDocuments} page={'retreive'}></Document>
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </div >
    </Fragment >);
}

export default RetrieveTravelRequest;
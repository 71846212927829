import { Fragment } from "react";
import { getFormatDate, getFormatTime,  } from "../../../../utils/CommonFunction";
const Booking = (props) => {
    let noOfNights = props.hotelDetails.numberOfDay > 1 ? props.hotelDetails.numberOfDay + " nights" : props.hotelDetails.numberOfDay + " night";
    return <Fragment>
        <div className="">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix">
                Booking
            </h5>
            <div id="collapsesix" className="accordion-collapse collapse show">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4"><div className='h6 mb-2 text-primary'>Check-in</div> </div>
                            <div className="col-lg-9 col-md-9  col-sm-8 col-8 "><div className='h6 mb-2'>{getFormatDate(props.hotelDetails.checkInDate) + ", " + getFormatTime(props.hotelDetails.checkInTime)} </div> </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 text-primary'> Check-out</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>{getFormatDate(props.hotelDetails.checkOutDate) + ", " + getFormatTime(props.hotelDetails.checkOutTime)} </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 text-primary'>Total length of stay</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>
                                    {noOfNights}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>
}

export default Booking;
// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const SYSTEM_PAGE_LOST = "You have not saved your changes. Are you sure you want to leave this page?";

export const SYSTEM_KEY = "12269adb-1cf8-4d50-9237-98b9f6a7bc7e";

export const DEFAULT_TITLE = process.env.REACT_APP_DEFAULT_TITLE;
export const TITLE = process.env.REACT_APP_TITLE;
export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
export const GOOGLE_TAG = process.env.REACT_APP_GOOGLE_TAG;
export const DOMAIN_IGNORE_CUSTOMIZATION = process.env.REACT_APP_DOMAIN_IGNORE_CUSTOMIZATION;
export const CUSTOMIZE_IMAGE_URL = process.env.REACT_APP_CUSTOMIZE_IMAGE_URL;
export const APP_URL = process.env.REACT_APP_URL;

export const PAGE_MODE = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    VIEW: 'VIEW'
};
import { Fragment } from 'react';
import { removeHtmlTags } from '../../../utils/CommonFunction';
import Map from '../Components/Map'
const Overview = (props) => {

    let address = props.hotelDetails.data.hotelResults[0].address.addressLine + " " + props.hotelDetails.data.hotelResults[0].address.city.cityName + " " + props.hotelDetails.data.hotelResults[0].address.country.countryName;
    let latitude = props.hotelDetails.data.hotelResults[0].latitude;
    let longitude = props.hotelDetails.data.hotelResults[0].longitude
    let attaractios = "";

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
    }

    let show;
    if (props.hotelDetails.data.hotelResults[0].hotelDescription !== undefined && props.hotelDetails.data.hotelResults[0].hotelDescription !== null) {
        // Remove '\n' from the string
        const removedNewlines = props.hotelDetails.data.hotelResults[0].hotelDescription.replace(/\n/g, ' ');

        // Split the string based on full stops exclude sq.ft
        const sentences = removedNewlines.split(/(?<!sq|ft|0)\.(?![a-z]| ft(?= [a-z]))/i);
        if (sentences.length !== 0) {
            let desc = sentences.map((item, index) => {

                if (item.trim() !== '') {
                    const sentenceWithFullStop = index !== sentences.length - 1 ? item + '.' : item;
                    if (index === sentences.length - 1) {
                        return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}</li>;
                    }
                    return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}<br /><br /></li>;
                }

            });

            show = <ul>{desc}</ul>;
        }
        else {
            show = removeHtmlTags(props.hotelDetails.data.hotelResults[0].hotelDescription);
        }
    }
    const showMore = () => {
        var textElement = document.querySelector(".text");
        var buttonElement = document.getElementById('showMore123');
        if (textElement.classList.contains("show-more-height")) {
            buttonElement.innerHTML = '<span>Show Less <i className="fas fa-angle-double-left"></i></span>'; // Up double angle
        } else {
            buttonElement.innerHTML = '<span>Show More <i className="fas fa-angle-double-right"></i></span>'; // Down double angle
        }

        textElement.classList.toggle("show-more-height");
    }




    return (<Fragment>
        <div className="col-lg-8">
            <div className="tour_details_leftside_wrapper" id='overview_section'>
                <div className="tour_details_heading_wrapper">
                    <div className="tour_details_top_heading">
                        <h2>{props.hotelDetails.data.hotelResults[0].hotelName !== undefined && removeHtmlTags(props.hotelDetails.data.hotelResults[0].hotelName)}</h2>
                        <h5><i className="fas fa-map-marker-alt rrr"></i> {props.hotelDetails.data.hotelResults[0].address.addressLine.replace(//g, "")}, {props.hotelDetails.data.hotelResults[0].address.city.cityName}, {props.hotelDetails.data.hotelResults[0].address.country.countryName}</h5>
                    </div>
                    <div className="tour_details_top_heading_right">

                    </div>
                </div>

                < div className="tour_details_boxed">
                    <h3 className="heading_theme">Overview</h3>
                    {show !== undefined && <div className='col-md-12 mb-2 display-line'>
                        <div id="profile-description" className='col-md-12'>
                            <div className="text show-more-height" >
                                {show}
                            </div>
                        </div>

                    </div>}
                    <br></br>
                    <div className="tour_details_boxed_inner">

                        <div className='row'>
                            {longitude !== undefined && latitude !== undefined && < div className='col-md-12 mt-3'>
                                <div className="map_area">
                                    <Map longitude={longitude} latitude={latitude} hotelName={props.hotelDetails.data.hotelResults[0].hotelName} address={props.hotelDetails.data.hotelResults[0].address.addressLine + "," + props.hotelDetails.data.hotelResults[0].address.city.cityName + "," + props.hotelDetails.data.hotelResults[0].address.country.countryName} />
                                </div>
                            </div>}



                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Fragment >)
}

export default Overview;
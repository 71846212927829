import {
    ADD_CUSTOMER,
    VIEW_CUSTOMER
} from './CONSTANTS'


export async function viewCustomer(viewData) {
    const response = await fetch(VIEW_CUSTOMER(), {
        method: 'POST',
        body: JSON.stringify(viewData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not find customer');
    }
    return data;
}

export async function addCustomer(addData) {
    const response = await fetch(ADD_CUSTOMER(), {
        method: 'PUT',
        body: JSON.stringify(addData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not create customer');
    }
    return data;
}
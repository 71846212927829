// import { Fragment } from 'react';
import { Fragment, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { Tooltip as ReactTooltip } from "react-tooltip";
import PrintSales from './PrintSales'
import SalesList from './Components/SalesList'
import ReactToPdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import ExportExcel from './Components/ExportExcel';
import * as React from "react";
import { getFormatDate } from './../../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from './../../../utils/ConfigurationsFunction';

const DisplaySales = (props) => {
    useDocumentTitle('Dashboard')
    const ref = React.createRef();
    const options = {
        orientation: 'landscape',
        unit: 'in',
        //format: [4, 2]
    };
    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));
    const componentRef = useRef();

    if (reportJSON.data[0].segments.length > 0) {
        reportJSON.data[0].segments.push({
            "service": "Total",
            "booked": reportJSON.data[0].segments.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "FL").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0,
            "sendRequest": reportJSON.data[0].segments.map(a => a.sendRequest).reduce((sendRequest1, sendRequest2) => sendRequest1 + sendRequest2),
            "ticketed": parseInt(reportJSON.data[0].segments.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "FL").map(a => a.ticketed).reduce((ticketed1, ticketed2) => ticketed1 + ticketed2) : 0) + parseInt(reportJSON.data[0].segments.filter(x => x.service === "HT").length > 0 ? reportJSON.data[0].segments.filter(x => x.service === "HT").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2) : 0),
            "cancelled": reportJSON.data[0].segments.map(a => a.cancelled).reduce((cancelled1, cancelled2) => cancelled1 + cancelled2),
            "modified": reportJSON.data[0].segments.map(a => a.modified).reduce((modified1, modified2) => modified1 + modified2),
            "total": reportJSON.data[0].segments.map(a => a.total).reduce((total1, total2) => total1 + total2)
        })
    }

    if (reportJSON.data[0].revenue.length > 0) {
        reportJSON.data[0].revenue.push({
            "service": "Total",
            "currencyCode": "",
            "booked": reportJSON.data[0].revenue.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "FL").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')) : 0.00,
            "sendRequest": reportJSON.data[0].revenue.map(a => a.sendRequest).reduce((sendRequest1, sendRequest2) => sendRequest1 + sendRequest2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "ticketed": parseFloat(reportJSON.data[0].revenue.filter(x => x.service === "FL").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "FL").map(a => a.ticketed).reduce((ticketed1, ticketed2) => ticketed1 + ticketed2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')) : 0) + parseFloat(reportJSON.data[0].revenue.filter(x => x.service === "HT").length > 0 ? reportJSON.data[0].revenue.filter(x => x.service === "HT").map(a => a.booked).reduce((booked1, booked2) => booked1 + booked2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')) : 0),
            "cancelled": reportJSON.data[0].revenue.map(a => a.cancelled).reduce((cancelled1, cancelled2) => cancelled1 + cancelled2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "modified": reportJSON.data[0].revenue.map(a => a.modified).reduce((modified1, modified2) => modified1 + modified2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "net": reportJSON.data[0].revenue.map(a => a.net).reduce((net1, net2) => net1 + net2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "equivalentNet": reportJSON.data[0].revenue.map(a => a.equivalentNet).reduce((equivalentNet1, equivalentNet2) => equivalentNet1 + equivalentNet2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "serviceFee": reportJSON.data[0].revenue.map(a => a.serviceFee).reduce((serviceFee1, serviceFee2) => serviceFee1 + serviceFee2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "markup": reportJSON.data[0].revenue.map(a => a.markup).reduce((markup1, markup2) => markup1 + markup2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "airlineTransactionAmount": reportJSON.data[0].revenue.map(a => a.airlineTransactionAmount).reduce((airlineTransactionAmount1, airlineTransactionAmount2) => airlineTransactionAmount1 + airlineTransactionAmount2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "equivalentServiceFee": reportJSON.data[0].revenue.map(a => a.equivalentServiceFee).reduce((equivalentServiceFee1, equivalentServiceFee2) => equivalentServiceFee1 + equivalentServiceFee2).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')),
            "commission": '',
            "equivalentCommission": ''
        })
    }
    //console.log(reportJSON);

    const marginTop = "50px"
    const marginRight = "0px"
    const marginBottom = "100px"
    const marginLeft = "0px"
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };
    const tableRef = useRef(null);
    const ComponentToPrint = React.forwardRef((props, ref) => {
        const { value } = props;


        return (
            <div style={{ display: "none" }}>
                <style>{getPageMargins()}</style>
                <div className="print-source" ref={ref}>
                    <PrintSales>

                    </PrintSales>

                </div> </div>
        );
    });
    /*     const MyDocument = () => (
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  <Text>Section #1</Text>
                </View>
                <View style={styles.section}>
                  <Text>Section #2</Text>
                </View>
              </Page>
            </Document>
          ); */

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container" >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className='row position-relative'>

                                                <div className='col-md-12 btn-position-print'>
                                                    <div className='float-end d-none'>
                                                        <ReactToPrint
                                                            trigger={() => <div id="print" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end' title='Take a Print'><i className="fa-solid fa-print"></i></div>}
                                                            content={() => componentRef.current}
                                                        />
                                                        <ComponentToPrint ref={componentRef}
                                                        /*    key={props.key}
                                                               id={props.id}
                                                               airItinerary={props.airItinerary}
                                                               searchFlight={props.searchFlight} */
                                                        />
                                                    </div>
                                                    <div className='float-end'>
                                                        <span id="excel" className='float-end'>
                                                            <ExportExcel reportJSON={reportJSON.data} ></ExportExcel>
                                                        </span>
                                                    </div>
                                                    <div className='float-end'>
                                                        <span id="pdf" className='float-end' >
                                                            <ReactToPdf targetRef={ref} filename="Dashboard.pdf" options={options} x={0.5} y={0.2} scale={0.7}
                                                            /*    options={options} */
                                                            >
                                                                {({ toPdf }) => <span className='btn btn-sm text-white mt-1 ms-1 btn-danger' onClick={toPdf}><i className="fas fa-file-pdf" ></i></span>}
                                                            </ReactToPdf>
                                                        </span>
                                                    </div>
                                                    <div className='float-end'>
                                                        <a id='back' href="/SearchSales" className='btn text-white gt-main-btn btn-sm ms-1 mt-1 float-end'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>

                                                    <ReactTooltip
                                                        anchorId={"print"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Take a print"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"pdf"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export PDF"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"excel"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export Excel"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"back"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Back to Search"}
                                                    />

                                                </div>
                                                <div className="col-lg-12" ref={ref}>
                                                    <div className='col-md-6'>
                                                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Dashboard </label>
                                                    </div>
                                                    <div
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="">
                                                            <div className='row'>
                                                                <div className='col-md-6'>

                                                                </div>
                                                                {/* <div className='col-md-12'> */}
                                                                <div className="table-responsive my-2">
                                                                    <table className="table table-bordered" ref={tableRef}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='w-25 fw-bold'>Date From</td>
                                                                                <td className='w-25'>{getFormatDate(reportJSON.data[0].dateFrom.replaceAll('-', ''))}</td>
                                                                                <td className='w-25 fw-bold'>Date To</td>
                                                                                <td className='w-25'>{getFormatDate(reportJSON.data[0].dateTo.replaceAll('-', ''))}</td>


                                                                            </tr>
                                                                            <tr>
                                                                                <td className='w-25 fw-bold'>Company Name</td>
                                                                                <td colSpan={3} className='w-25'>{reportJSON.data[0].companyNameInput}</td>
                                                                                {/* <td className='w-25 fw-bold'>Status</td>
                                                                                <td className='w-25'>{reportJSON.data[0].statusInput}</td> */}


                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* <div className='col-md-12'>
                                                                    <a href="/SearchSales" className='btn gt-btn_md text-white my-2 gt-main-btn d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div> */}
                                                                {/* </div> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 pop-ups-tab-border-parent">
                                                        <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                            <li className="nav-item pb-0" role="presentation">
                                                                <button className="tabs-btn nav-link active" data-bs-toggle="tab"
                                                                    data-bs-target="#salesChartTab" type="button" role="tab" aria-controls="salesChartTab"
                                                                    id='btnSalesChart' aria-selected="true">Visual</button>
                                                            </li>
                                                            <li className="nav-item pb-0" role="presentation">
                                                                <button className="tabs-btn nav-link" data-bs-toggle="tab"
                                                                    data-bs-target="#salesTableTab" type="button" role="tab" aria-controls="salesTableTab"
                                                                    id='btnSalesTable' aria-selected="true">Data</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-12" >
                                                        <div className="p-2 tab-content include-exclude-tab" id="myTabContent">
                                                            <div className="tab-pane show active" id="salesChartTab" role="tabpanel" aria-labelledby="salesChart">
                                                                <SalesList reportType={'chart'} reportJSON={reportJSON.data} />
                                                            </div>
                                                            <div className="tab-pane show " id="salesTableTab" role="tabpanel" aria-labelledby="salesTable">
                                                                <SalesList reportType={'table'} reportJSON={reportJSON.data} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default DisplaySales;
import { Fragment } from "react";
import BookingDetails from "./BookingDetails";
import Flight from "./Flight/Flight";
import HotelDetails from "./Hotel/HotelDetails";
import ServiceRequest from "./ServiceRequest";
import Document from "./Document";
import MeetingDetails from "./MeetingDetails";
import { useLocation } from 'react-router-dom';

const PrintTravelRequest = (props) => {
    const location = useLocation();
    let travelRequestData = props.travelRequestData !== undefined ? props.travelRequestData : null;
    let onlineFlightLowestDetails = travelRequestData !== null && travelRequestData.data !== undefined && travelRequestData.data.onlineFlightLowestDetails !== undefined ? travelRequestData.data.onlineFlightLowestDetails : "";
    const isMyQueue = sessionStorage.getItem("isMyQueue") !== null ? JSON.parse(sessionStorage.getItem("isMyQueue")) : null;
    const lowestIdMap = {};
    if (onlineFlightLowestDetails !== undefined && onlineFlightLowestDetails.length !== 0) {
        onlineFlightLowestDetails.forEach(detail => {
            if (!lowestIdMap[detail.lowestID]) {
                lowestIdMap[detail.lowestID] = [];
            }
            lowestIdMap[detail.lowestID].push(detail);
        });
    }


    function approvalStatusHtml() {
        let statusHtml = "";
        let statusCode = travelRequestData !== null && travelRequestData.data.approvalWorkflowQueue[0].approvalStatusCode;
        switch (statusCode) {
            case "PD":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-warning">
                        <i className="fa-solid fa-pause"></i> Pending
                    </span>
                );
                break;
            case "AP":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-success">
                        <i className="fa-solid fa-circle-check"></i> Approved
                    </span>
                );
                break;
            case "RJ":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-danger">
                        <i className="fa-solid fa-circle-xmark"></i> Rejected
                    </span>
                );
                break;
            case "UT":
                statusHtml = (
                    <span className="badge rounded-pill text-16px  bg-primary">
                        <i className="fa-solid fa-check-circle"></i> Updated for Traveler
                    </span>
                );
                break;
            default:
                statusHtml = travelRequestData !== null && travelRequestData.data.approvalWorkflowQueue[0].approvalStatusCode;
                break;
        }
        return statusHtml;
    };

    return <Fragment>
        <section id="theme_search_form_tour " className="fligth_top_search_main_form_wrapper mt-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="col-lg-12 ">
                                <div className='col-md-6'>
                                    <label htmlFor="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Travel Request View   {approvalStatusHtml()}</label>
                                </div>
                                {travelRequestData !== null && <BookingDetails travelRequestData={travelRequestData} />}
                                {travelRequestData !== null &&
                                    <MeetingDetails meetingDetails={travelRequestData.data.meetingDetails} />}
                                {travelRequestData !== null && travelRequestData.data.onlineFlightDetails !== undefined && <div>
                                    <label htmlFor="" className="h5 text-primary mt-5"> <span className='gt-border-1px'> <i className="fab fa-avianex"></i> Selected Flight Details</span> </label>
                                    <Flight flightDetails={travelRequestData.data.onlineFlightDetails} id={"onlineFlightDetails"} fareDetails={travelRequestData.data.onlineFlightFareDetails} id1={"onlineFlightDetails"} page={"print"} rerefundableType={travelRequestData.data.onlineFlightFareDetails[0].refundableType}></Flight>
                                </div>}
                                {isMyQueue && travelRequestData !== null && travelRequestData.data.onlineFlightLowestDetails !== undefined && <div>
                                    <label htmlFor="" className="h5 text-primary mt-5"> <span className='gt-border-1px'> <i className="fab fa-avianex"></i> Lowest Flight Details</span> </label>
                                    {Object.keys(lowestIdMap).map(lowestId =>
                                        <Flight key = {"onlineFlightLowestDetails" + lowestId}
                                            flightDetails={lowestIdMap[lowestId]}
                                            id={"onlineFlightLowestDetails" + lowestId}
                                            fareDetails={travelRequestData.data.onlineFlightLowestFareDetails}
                                            id1={lowestId} page={"print"}></Flight>
                                    )}

                                </div>}
                                {travelRequestData !== null && travelRequestData.data.onlineHotelDetails !== undefined && <div>
                                    <label htmlFor="" className="h5 text-primary mt-5"> <span className="gt-border-1px"> <i className="fas fa-hotel"></i> Selected Hotel Details</span> </label>
                                    <HotelDetails hotelDetails={travelRequestData.data.onlineHotelDetails[0]} hotelType={"onlineHotelDetails"} page={"print"}></HotelDetails>
                                    {isMyQueue && <hr className="my-2"></hr>}
                                </div>}
                                {isMyQueue && travelRequestData !== null && travelRequestData.data.onlineHotelLowestDetails !== undefined && <div>
                                    <label htmlFor="" className="h5 text-primary mt-5"> <span className="gt-border-1px"> <i className="fas fa-hotel"></i> Lowest Hotel Details</span> </label>
                                    {travelRequestData.data.onlineHotelLowestDetails.map(hotel => (
                                        <HotelDetails hotelDetails={hotel} key={"lowestHotel" + hotel.lowestID} hotelType={'onlineHotelLowestDetails'} id={hotel.lowestID} page={"print"}></HotelDetails>
                                    ))}

                                </div>}

                                {travelRequestData !== null && travelRequestData.data.serviceRequest !== undefined &&
                                    <ServiceRequest serviceRequest={travelRequestData.data.serviceRequest} page={"print"}></ServiceRequest>

                                }
                                {travelRequestData !== null && travelRequestData.data.travelRequestDocuments !== undefined &&
                                    <Document documents={travelRequestData.data.travelRequestDocuments} page={'print'}></Document>

                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    </Fragment>
}

export default PrintTravelRequest;
import { useState, useEffect } from 'react';
import Dropdown from 'react-select';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RETRIEVETRIP } from '../../../../navigation/CONSTANTS';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';
import useHttp from '../../../../services/use-http';
import { reviewBooking } from '../../../../services/Booking-API';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";


const AddPayment = (props) => {
    const { t } = useTranslation(['translation.Booking']);

    const [paymentModeInputHasError, setPaymentModeInputHasError] = useState(false);
    const [paymentMode, SetPaymentMode] = useState();
    const history = useHistory();
    const [isPageRedirected, setIsPageRedirected] = useState(false);
    const [confirm, setConfirm] = useState('');
    const paymentmode_list = [];
    //paymentmode_list.push({ value: '', label: 'Select' })    

    const { sendRequest, status, data: loadedReviewBookingResponse } = useHttp(reviewBooking);

    if (getConfigurationByBoolen("FLIGHT_CREDIT_CARD") !== null && getConfigurationByBoolen("FLIGHT_CREDIT_CARD") !== '') {
        if (getConfigurationByBoolen("FLIGHT_CREDIT_CARD") === true) {
            paymentmode_list.push({ value: 'CREDITCARD', label: 'Credit Card' })
        }
    }
    if (getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") !== null && getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") !== '') {
        if (getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") === true) {
            paymentmode_list.push({ value: 'N/A', label: 'On Hold' })
        }
    }
    if (getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") !== null && getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") !== '') {
        if (getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") === true) {
            paymentmode_list.push({ value: 'PROFILE', label: 'Profile' })
        }
    }
    if (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") !== null && getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") !== '') {
        if (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") === true) {
            paymentmode_list.push({ value: 'WALLET', label: 'Wallet' })
        }
    }

    const handleChangePaymentMode = (e) => {
        SetPaymentMode(e.value);
    };

    function onAddPaymet() {

        if (paymentMode === undefined || paymentMode === '') {
            setPaymentModeInputHasError(true);
            return;
        }
        else {
            setPaymentModeInputHasError(false);
        }


        if (document.querySelector('#btnPayment') !== null) {
            document.querySelector('#btnPayment').style.display = "none";
            setIsPageRedirected(true);
        }

        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
        let markupAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee[0].amount !== undefined ? parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee[0].amount) : 0;

        if (bookingJSON.data.airItinerary !== undefined) {
            bookingJSON.data.airItinerary.forEach(element => {
                element.paymentInfo.paymentMode.forEach(elementPaymentMode => {
                    elementPaymentMode.name = paymentMode;
                    elementPaymentMode.amount = Object.keys(element.airItineraryPricingInfo.totalFare.amount.toString()).length > 0 ? parseFloat(element.airItineraryPricingInfo.totalFare.amount) > 0 ? (element.airItineraryPricingInfo.totalFare.amount - markupAmount).toString() : element.airItineraryPricingInfo.totalFare.amount : '0.00';
                });

            });
        }

        sendRequest(bookingJSON);
    }

    function okHandler() {
        setConfirm('');
    };

    useEffect(() => {
        let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

        if (bookingJSON !== null) {
            if (bookingJSON.data.airItinerary !== undefined) {
                bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                    if (elementAirItinerary.paymentInfo !== undefined) {
                        elementAirItinerary.paymentInfo.paymentMode.forEach(elementPaymentMode => {
                            if (elementPaymentMode.name !== undefined && elementPaymentMode.name !== '') {
                                SetPaymentMode(elementPaymentMode.name);
                            }
                            else {
                                //To fetch the FOP from GDSRetrieve remarkType
                                if (bookingJSON.data.miscellaneousRemarkInfo !== undefined && bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo !== undefined) {
                                    bookingJSON.data.miscellaneousRemarkInfo.miscellaneousInfo.forEach(node => {
                                        if (node.text !== '' && node.text.split('-').length > 1 && node.text.split('-')[0] === 'FOP') {
                                            SetPaymentMode(node.text.split('-')[1].trim());
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }

    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedReviewBookingResponse !== null && loadedReviewBookingResponse.data !== null) {

                if (loadedReviewBookingResponse.data.errors !== undefined && loadedReviewBookingResponse.data.errors.status !== undefined && loadedReviewBookingResponse.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnPayment') !== null) {
                        document.querySelector('#btnPayment').style.display = "";
                    }

                    setConfirm({
                        title: "Message",
                        message: loadedReviewBookingResponse.data.errors.error.description,
                        //details: loadedBookingResponse.data.errors.error.description
                    });
                }
                else {
                    sessionStorage.setItem("BookingData", JSON.stringify(loadedReviewBookingResponse));

                    setTimeout(function () {
                        history.push(RETRIEVETRIP);
                        history.go();

                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }, 1000)
                }
            }
            else {
                if (document.querySelector('#btnPayment') !== null) {
                    document.querySelector('#btnPayment').style.display = "";
                }

                setConfirm({
                    title: "Message",
                    message: 'Some thing went wrong.',
                });
            }
            return;
        }
    }, [status, loadedReviewBookingResponse])


    return (
        <Fragment>
            {confirm && (
                <ErrorModal
                    title={confirm.title}
                    message={confirm.message}
                    onConfirm={okHandler}
                    details={confirm.details}

                ></ErrorModal>
            )}
            {(isPageRedirected === true) &&
                <div className="accordion_flex_area">
                    <div className="accordion_left_side position-relative text-muted">
                        <h5>8</h5>
                        <span id="completeEight" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button id="headingEight" className="text-muted accordion-button collapsed" type="button"
                                data-bs-target="#collapseEight" aria-expanded="false"
                                aria-controls="collapseEight">
                                Payment
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse"
                            aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className="control col-md-4">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12"> Payment Mode <span className="error-icon"> * </span></label>
                                        <Dropdown
                                            options={paymentmode_list} onChange={handleChangePaymentMode}
                                            value={paymentmode_list.filter(option => option.value === paymentMode)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        {paymentModeInputHasError &&
                                            <p className="error-text">{t('validationPaymentMode')}</p>
                                        }
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                        <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="float-end">
                                            <button type="button" id='btnPayment' className="btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end" onClick={() => onAddPaymet()}>Review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="float-end text-end">
                                    <span className="my-2 btn btn_theme btn_md mt-2"><i className="fas fa-spinner fa-spin"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (isPageRedirected === false) &&
                <div className="accordion_flex_area">
                    <div className="accordion_left_side position-relative text-muted">
                        <h5>8</h5>
                        <span id="completeEight" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button id="headingEight" className="text-muted accordion-button collapsed" type="button"
                                data-bs-target="#collapseEight" aria-expanded="false"
                                aria-controls="collapseEight">
                                Payment
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse"
                            aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className="control col-md-4">
                                        <label htmlFor="bottomText" className="mb-2 h6 text-primary col-lg-12"> Payment Mode <span className="error-icon"> * </span></label>
                                        <Dropdown
                                            options={paymentmode_list} onChange={handleChangePaymentMode}
                                            value={paymentmode_list.filter(option => option.value === paymentMode)}
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        />
                                        {paymentModeInputHasError &&
                                            <p className="error-text">{t('validationPaymentMode')}</p>
                                        }
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                        <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="float-end text-end">
                                            <button type="button" id='btnPayment' className="btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end" onClick={() => onAddPaymet()}>Review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}
export default AddPayment
import { useState, Fragment, useEffect, useCallback } from "react";
import { searchApprover } from '../../../services/Approver-API'
import { UPDATEAPPROVER } from "../../../navigation/CONSTANTS";
import useHttp from "../../../services/use-http";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { NavLink, useHistory } from "react-router-dom";
import ApproverList from "./Components/ApproverList";
import { useTranslation } from "react-i18next";
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
import secureLocalStorage from "react-secure-storage";
const SearchApprover = () => {
    useDocumentTitle('Search Approval Workflow')
    const [enteredName, setEnterName] = useState("");
   
    const [isFirtTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);
    const history = useHistory();

    const { t } = useTranslation(['translation.Approver']);
    let varHttp = searchApprover;

    const namechangehandler = (event) => {
        setEnterName(event.target.value);
    };
   
    const { sendRequest, status, data: loadedApprover, error } = useHttp(varHttp);

    let labelName = getConfigurationByValue(getConfigurationByValue("APPROVER_TYPE").split("-")[0]);

    const onResetHandler = (event) => {
        event.preventDefault();
        // setEnterName('');
        // setEnteredCode('');
        history.replace('/SearchApprover');
        history.go();

    }

    sessionStorage.removeItem("ApprovalRequestData");
    sessionStorage.setItem("isFirstTime", JSON.stringify(true));
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Settings", "Approval Workflow", "Settings_Workflow_Search")) {
            return false;
        }

        setFirstTime(true);
        const onSearchApprover = ({
            "officeID": JSON.parse(localStorage.getItem("officeID")),
            "userID": JSON.parse(localStorage.getItem('userID')),
            "corporateMasterName": enteredName,
            "keyCode": getConfigurationByValue("APPROVER_TYPE")
        });
        sendRequest(onSearchApprover);

    }, [sendRequest, enteredName])



    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);


    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>

    }

    let loadedData = [];
    for (let i in loadedApprover) {
        if (loadedApprover['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedApprover[i];
            }
        }
    }

    function okayHandler() {
        setError1(false);
        history.go('/SearchApprover');

    }
    const errorHandler = () => {
        history.go('/SearchApprover');
    };

    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''
    const approverList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <ApproverList labelNameDetails={labelName} approver={loadedData} /> : ErrorShow

    return <Fragment>
        {error1 && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('searchApprover')}</label>
                                            <form>
                                                <div className="row">
                                                    <div className='control col-lg-12'>
                                                        <label htmlFor='name' className="h6 text-primary my-2 col-lg-12">{labelName}</label>
                                                        <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' value={enteredName} maxLength={100} onChange={namechangehandler} autoFocus />
                                                    </div>
                                                    
                                                </div>
                                                <div className="mt-3">
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Settings", "Approval Workflow", "Settings_Workflow_Insert") && <NavLink className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1 d-none' to={UPDATEAPPROVER}>
                                                        {t('new')}
                                                    </NavLink>}
                                                    {checkSecurityDetails("Settings", "Approval Workflow", "Settings_Workflow_Search") && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {approverList}
    </Fragment>
};

export default SearchApprover;
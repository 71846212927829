import { Fragment, useState } from 'react';
import { toTitleCase } from '../../utils/CommonFunction';
import { removeHtmlTags } from '../../utils/CommonFunction';


const RoomRecommendation = () => {
    let loadedRoomDetails;
    let hotelDetailsResponse;
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "defaultHotel.png"
    if (sessionStorage.getItem('RoomDetailJson') !== null) {
        loadedRoomDetails = JSON.parse(sessionStorage.getItem('RoomDetailJson'));
    }
    if (sessionStorage.getItem('HotelDetailsResponse') !== null) {
        hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
    }
    const getFormatedDateAndTime = (dt) => {
        const inputDate = new Date(dt);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate;

    }
    function getAmemtiesPart1(Array) {
        let Array1 = [];
        let AmenetiesPart1 = [];
        if (Array.length > 0) {
            if (Array.length % 2 === 0) {
                Array1 = Array.slice(0, Array.length / 2)
            } else {
                Array1 = Array.slice(0, Math.floor(Array.length / 2) + 1)
            }
        }
        Array1.forEach(element => {
            AmenetiesPart1.push(<li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black"> {element.name}</span></li>
            )
        });
        return AmenetiesPart1;
    }
    function getAmemtiesPart2(Array) {
        let AmenetiesPart2 = [];
        let Array2 = [];
        if (Array.length > 0) {
            if (Array.length % 2 === 0) {
                Array2 = Array.slice(Array.length / 2)
            } else {
                Array2 = Array.slice(Math.floor(Array.length / 2) + 1)
            }
        }
        Array2.forEach(element => {
            AmenetiesPart2.push(<li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black"> {element.name}</span></li>
            )
        });
        return AmenetiesPart2;
    }

    function getTotalPrice(rateKey, isTax) {
        let totalBaseRate = 0;
        let totalTax = 0;
        let hotelDetailsResponseTaxes = hotelDetailsResponse.data.hotelResults[0].roomRateDetails.filter(a => a.rateKey === rateKey);
        hotelDetailsResponseTaxes.forEach(item => {
            if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
                totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
                totalBaseRate += item.price.publishedPrice.pricePublished;
            }
            else {
                totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
                totalBaseRate += item.price !== undefined && item.price !== null ? item.price.publishedPrice.pricePublished : 0;
            }
        });
        if (isTax) {
            return totalTax;
        }
        return totalBaseRate + totalTax


    }
    function getRoomCancellationPolicy(text) {
        let show;
        if (text !== "" && text !== undefined) {
            // Remove '\n' from the string

            const removedNewlines = text.replace(/\n/g, ' ');
            const sentences = removedNewlines.split(/(?<=(?<!\d\.)\s*(?<!i\.e\.)\.\s*)(?!\S)/);
            if (sentences.length !== 0) {
                let desc = sentences.map(item => {
                    if (item.trim() !== '.' && item !== '') {
                        return <li className='display-line'><i className="fas fa-circle text-8px text-black py-1"></i><span className="ms-1 text-black"> {removeHtmlTags(item)}</span></li>;
                    }

                });

                show = desc;
            } else {
                show = removeHtmlTags(text);
            }

        }
        return show;
    }

    function getMealType(Meals) {
        let showMeal = [];
        if (Meals !== null && Meals.length !== 0) {
            if (Meals[0].mealPlan !== undefined && Meals[0].mealPlan && Meals[0].mealPlanDescription !== undefined && Meals[0].mealPlanDescription !== '') {
                showMeal.push(<p className="card-text text-black">  <span className='fw-bold'>Meal Plan:</span> {Meals[0].mealPlanDescription}</p>)
            }
            if (Meals[0].breakfastIncluded !== undefined && Meals[0].breakfastIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>)
            }
            if (Meals[0].dinnerIncluded !== undefined && Meals[0].dinnerIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Dinner Included</p>)
            }

            if (Meals[0].lunchIncluded !== undefined && Meals[0].lunchIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Lunch Included</p>)
            }

        }
        // User Story 3800: Remove space in Room details section

        return showMeal

    }

    function getServices(RoomData) {
        let showServices = [];
        if (RoomData !== undefined && RoomData !== null && RoomData.inclusions !== undefined && RoomData.inclusions !== null && (RoomData.inclusions.filter(a => a.name.toString().includes('Internet')) || RoomData.inclusions.filter(a => a.name.toString().includes('internet')))) {
            showServices.push(<p className="card-text text-black"><i className="fa fa-solid fa-wifi"></i> Free WiFi</p>)
        }
        if (RoomData.roomViewDescription !== undefined && RoomData.roomViewDescription !== undefined && RoomData.roomViewDescription !== null) {
            showServices.push(<p className="card-text text-black"><i className="fas fa-tree-city"></i> {RoomData.roomViewDescription}</p>)
        }
        if (RoomData !== undefined && RoomData !== null && RoomData.bedTypes !== undefined && RoomData.bedTypes !== null) {
            showServices.push(<p className="card-text text-black"><i className="fa fa-solid fa-bed"></i> {RoomData.bedTypes[0].bedTypeDescription}</p>)
        }

        return showServices;

    }
    function showRoom(room) {
        let showRoom = [];
        room.forEach(element => {
            showRoom.push(<div class="row mb-4" style={{ background: "#fff", border: "1px solid #9a9a9a" }}><div className="position-relative perk-border light-yellow-bg" style={{ width: "100%", border: "1px solid #9a9a9a" }} >
                <div className="hotel-img">

                    <div class="row">
                        <div class="col-lg-5 col-md-7 col-sm-12 col-12" style={{ height: "300px", paddingLeft: "0px" }}>
                            {
                                (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length > 0) &&
                                <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room')[0].imagePath} className="card-img-top  gt-h-240" style={{ height: "100%" }} alt="..." />
                            }
                            {
                                (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length > 0) &&
                                <img src={hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite')[0].imagePath} className="card-img-top  gt-h-240" style={{ height: "100%" }} alt="..." />
                            }
                            {
                                (hotelDetailsResponse.data.hotelResults[0].images !== undefined && hotelDetailsResponse.data.hotelResults[0].images !== null && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Guest room').length <= 0 && hotelDetailsResponse.data.hotelResults[0].images.filter(a => a.category === 'Suite').length <= 0) &&

                                <img src={hotelDetailsResponse.data.hotelResults[0].images[0].imagePath} className="card-img-top  gt-h-240" style={{ height: "100%" }} alt="..." />

                            }
                            {
                                (hotelDetailsResponse.data.hotelResults[0].images === undefined) &&
                                <img src={hotelLogoLink} className="card-img-top  gt-h-240" alt="..." />
                            }
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 col-12" style={{ paddingLeft: "10px" }}>
                            <div className="hotel-text mt-2 ml-2 pl-2" style={{ marginLeft: "0px", paddingLeft: "0px" }}>
                                <div className="hotel-name h4 text-primary">
                                    <a href="hotel-detail.html" title="" className='h4' style={{ color: "#12688B", fontSize: "1.5rem" }}>
                                        {element.roomTypeName !== undefined ? toTitleCase(element.roomTypeName) : <span>&nbsp;</span>}
                                    </a>
                                </div>
                                <div className="hotel-star-address">
                                    <address className="hotel-address" style={{ height: "50px", overflow: "hidden" }}>
                                        {toTitleCase(element.roomDescription)}
                                        <br></br>
                                    </address>
                                </div>

                            </div>
                            <div className='row' style={{ height: "95px" }}>
                                <div className='col-md-6'>
                                    <div className='text-12px ms-2 text-muted'>
                                        {element.roomDetails !== undefined && getServices(element.roomDetails)}
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    {
                                        element.roomDetails !== undefined && element.roomDetails !== null && element.roomDetails.meals !== undefined && element.roomDetails.meals !== null && getMealType(element.roomDetails.meals)
                                    }

                                </div>
                            </div>

                            <div className="col-md-12 text-14px text-muted mt-2">
                                {
                                    element.cancellationPolicies.filter(a => a.nonRefundable !== undefined).map(x => x.nonRefundable)[0] === 'true' ? <span className="h6 text-danger ms-1"><i className="fas fa-money-check-alt"></i> Non Refundable</span> : <span className="h6 text-sky-blue ms-1"><i className="fas fa-money-check-alt"></i>  Refundable</span>
                                }

                            </div>
                            <div className="col-md-12 hotel-service my-2">
                                <hr className='my-2'></hr>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <span class=" text-primary h4 fw-bold mb-0 ms-2" style={{ fontSize: "1.5rem" }}>Total Rate</span>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="price special-price text-primary fw-bold h4 mb-0 text-end" style={{ paddingRight: "10px", fontSize: "1.5rem" }}> {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: element.price.currencyCode
                                        }).format(getTotalPrice(element.rateKey, false))}</div>
                                        <div class="text-end text-muted h6" style={{ paddingRight: "10px" }} > Includes <span className="text-primary fw-bold">{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: element.price.currencyCode
                                        }).format(getTotalPrice(element.rateKey, true))}</span> Taxes & Fees</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
                <div className='col-md-12' style={{ width: "100%", borderLeft: "1px solid #9a9a9a", borderLeft: "1px solid #9a9a9a", borderRight: "1px solid #9a9a9a",  borderBottom: "1px solid #9a9a9a" }}>
                    {/* <hr className='my-2'></hr> */}
                    <div className='row px-3 mt-3'>
                        <div className='col-md-6'>

                            {element.guarantee !== undefined && element.guarantee.guaranteesAccepted !== undefined && element.guarantee.guaranteesAccepted.guaranteeAccepted !== undefined && element.guarantee.guaranteesAccepted.guaranteeAccepted !== null && element.guarantee.guaranteesAccepted.guaranteeAccepted.length !== 0 && element.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards !== undefined && element.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard !== undefined && element.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard.length !== 0 && <div>
                                <div class="card-title text-primary h5 mb-2"> Accepted Cards</div>
                                {element.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard.map(element1 => {
                                    if (element1.cardCode !== undefined && element1.value !== undefined) {
                                        return <div className="text-black">  <span><i class="far fa-credit-card"></i> {element1.value + " (" + element1.cardCode + ")"}</span></div>
                                    }

                                })}

                            </div>}

                        </div>


                        <div className='col-md-6'>
                            <div>
                                {element.cancellationPolicies !== undefined && element.cancellationPolicies.length !== 0 && (element.cancellationPolicies[0].absoluteDeadline !== undefined || element.cancellationPolicies[0].description !== undefined || element.cancellationPolicies[0].deadlineDropTime !== undefined) && <div><div className="card-title text-primary h5 mb-2"> Cancellation Policy</div>

                                    <ul>
                                        {element.cancellationPolicies[0].absoluteDeadline !== undefined && <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black">
                                            Deadline -  {getFormatedDateAndTime(element.cancellationPolicies[0].absoluteDeadline) + "."}
                                        </span></li>}
                                        {element.cancellationPolicies[0].absoluteDeadline === undefined && element.cancellationPolicies[0].deadlineDropTime !== undefined && element.cancellationPolicies[0].duration !== undefined && element.cancellationPolicies[0].durationType !== undefined && <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black">
                                            Deadline -  {element.cancellationPolicies[0].duration + " " + element.cancellationPolicies[0].durationType + " " + element.cancellationPolicies[0].deadlineDropTime}
                                        </span></li>}


                                        {element.cancellationPolicies[0].description !== undefined && getRoomCancellationPolicy(element.cancellationPolicies[0].description)}</ul>
                                </div>}
                            </div>
                        </div>

                        {element.roomDetails.amenities !== undefined && element.roomDetails.amenities !== null && <div className='col-md-12 mt-4'>
                            <div>
                                <div class=" card-title text-primary h5 ">Amenities</div>
                                <div className='row my-3'>
                                    <div className='col-md-6'>

                                        <ul>
                                            {getAmemtiesPart1(element.roomDetails.amenities)}
                                        </ul>
                                    </div>
                                    <div className='col-md-6'>
                                        <ul>
                                            {getAmemtiesPart2(element.roomDetails.amenities)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>);

        });
        return showRoom;

    }


    return <Fragment>
        {/*     <!-- Common Banner Area --> */}
        {/* <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">

                    </div>
                </div>
            </div>
        </section> */}

        <section id="theme_search_form_tour" className="my-5">
            <div className="container">
                <div className="col-md-12">
                    <div className="tour_details_top_heading mb-2">
                        <h2>{hotelDetailsResponse.data.hotelResults[0].hotelName !== undefined && removeHtmlTags(hotelDetailsResponse.data.hotelResults[0].hotelName)}</h2>
                        <h5 className='mb-3'><i className="fas fa-map-marker-alt"></i> {hotelDetailsResponse.data.hotelResults[0].address.addressLine.replace(//g, "")}, {hotelDetailsResponse.data.hotelResults[0].address.city.cityName}, {hotelDetailsResponse.data.hotelResults[0].address.country.countryName}</h5>
                    </div>
                    <div className="main-cn hotel-page clearfix">
                        <div className="row">

                            <div className="col-md-12 col-md-push-3">
                                <section className="hotel-list">
                                    <div className="sort-view clearfix">
                                    </div>


                                    <div className="hotel-list-cn clearfix">
                                        {loadedRoomDetails !== null && loadedRoomDetails.length > 0 && showRoom(loadedRoomDetails)}

                                    </div>

                                </section>
                                {/*   <!-- End Hotel List --> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>





    </Fragment>




};

export default RoomRecommendation;
import { Fragment, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
//import Dropdown from 'react-dropdown';
import Dropdown from 'react-select';
import BookingLoadingSpinner from './Components/BookingLoadingSpinner'
import FlightItem from '../../components/Common/Flight/FlightItem'
import FareSummery from './Components/FareSummery'
import TravellerDetails from './Components/TravellerDetails';
import ContactPerson from './Components/ContactPerson';
import SeatSelection from './Components/SeatSelection';
import AdditionalServices from './Components/AdditionalServices';
import AddMarkup from './Components/AddMarkup';
import { CONFIRMATION } from '../../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import CountryDropDownList from '../../components/Common/DropDownList/CountryDropDownList';
import StateDropDownList from '../../components/Common/DropDownList/StateDropDownList';
import CityDropDownList from '../../components/Common/DropDownList/CityDropDownList';
import { useTranslation } from 'react-i18next';
import { booking, blockTicket } from '../../services/Booking-API';

import ErrorModal from "../../components/Common/Error/ErrorModal";
import useHttp from '../../services/use-http';
import { getCreditCardDetails } from '../../services/CommonListData-API';
import { dateList, monthList, expiryYearList } from '../../utils/DropdownData';
import { validateByLuhn, calculateRoeAmountMain, calculateRoeAmountAdditional, dateDiff } from '../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import TermsConditionsPopup from '../CompanyPolicy/TermsConditionsPopup';
import { encrypted } from '../../utils';
import { searchRoe } from '../../services/ROE-API';
import secureLocalStorage from 'react-secure-storage';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { decompress } from '@amoutonbrady/lz-string';
import { searchStaticPage } from '../../services/StaticPage-API';
import moment from 'moment';

const FlightBook = () => {
    const { t } = useTranslation(['translation.Booking']);
    useDocumentTitle('Flight Book');
    const { sendRequest, status, data: loadedBookingResponse } = useHttp(booking);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const [confirm, setConfirm] = useState('');
    const [termsCondition, setTermsCondition] = useState('');
    const history = useHistory();
    const [cardType, setCardType] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const postalCodeInputRef = useRef('');
    const cardNumberInputRef = useRef('');
    const cardHolderNameInputRef = useRef('');
    const cityNameInputRef = useRef('');
    const dateOfExpiryInputRef = useRef('');
    const addressInputRef = useRef('');
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [cardTypeInputHasError, setCardTypeInputHasError] = useState(false);
    const [expiryMonthInputHasError, setExpiryMonthInputHasError] = useState(false);
    const [expiryYearInputHasError, setExpiryYearInputHasError] = useState(false);
    // const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
    const [stateNameInputHasError, setStateNameInputHasError] = useState(false);
    const [postalCodeInputHasError, setPostalCodeInputHasError] = useState(false);
    const [postalCodeInvalidInputError, setPostalCodeInvalidInputError] = useState(false);
    const [cityNameInputHasError, setCityNameInputHasError] = useState(false);
    const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);

    const [dateofExpiryInputHasError, setDateofExpiryInputHasError] = useState(false);
    const [dateofExpiryInvalidInputError, setDateofExpiryInvalidInputError] = useState(false);
    const [cardHolderNameInputHasError, setCardHolderNameInputHasError] = useState(false);
    const [cardHolderNameInvalidInputError, setCardHolderNameInvalidInputError] = useState(false);
    const [cardNumberInputHasError, setCardNumberInputHasError] = useState(false);
    const [cardNumberInvalidInputError, setCardNumberInvalidInputError] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    // const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [stateCodeInputHasError, setStateCodeInputHasError] = useState(false);
    const [stateNameInvalidInputError, setStateNameInvalidInputError] = useState(false);
    const [controlType, setControlType] = useState('');
    const [addressInvalidInputError, setAddressInvalidInputError] = useState(false);
    const [addressInputHasError, setAddressInputHasError] = useState(false);
    const [address, setAddress] = useState('');
    const [profileLocaterNumber, setProfileLocaterNumber] = useState(null);
    const [cardName, setCardName] = useState('');
    const [error1, setError] = useState();
    const [refreshServiceFee, setRefreshServiceFee] = useState(false);
    const [refreshStateNameText, setRefreshStateNameText] = useState(false);
    const [refreshTermConditions, setRefreshTermConditions] = useState(false);

    //USER STORY 2334: Wallet changes
    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let currentWalletAmount = sessionStorage.getItem("currentWalletAmount") !== null ? sessionStorage.getItem("currentWalletAmount") !== "" ? sessionStorage.getItem("currentWalletAmount") : 0 : 0;
    let isWalletActive = sessionStorage.getItem("activeWallet") !== null ? "" + sessionStorage.getItem("activeWallet") : 'false';
    let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
    let fareCurrecy = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode
    let isWalletAmountIsSufficient = 'false';
    let isWalletRadiobuttonChecked = 'false';
    var valid = require("card-validator");
    let isHoldAllowed = true;
    let sourceId = bookingJSON.data.airItinerary[0].SourceId !== undefined ? bookingJSON.data.airItinerary[0].SourceId : '';

    // let totalCommoission = 0.0;
    // let totalMarkup = 0.0;
    // bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
    //     if (element.commisionInfo[0].earnedCommissionAmount != undefined)
    //         totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);

    //     element.taxes !== undefined && element.taxes.tax !== undefined && element.taxes.tax.length !== 0 && element.taxes.tax.forEach(item => {
    //         if (item.taxCode === 'P0') {
    //             totalMarkup = totalMarkup + parseInt(element.quantity) * parseFloat(item.amount)
    //         }
    //     })
    // })

    if ((parseFloat(currentWalletAmount) < parseFloat(calculateRoeAmountMain(fareCurrecy, bookingJSON, false, false, false)))) {
        isWalletAmountIsSufficient = 'true';
    }

    let RemainingWalletAmount = currentWalletAmount - calculateRoeAmountMain(fareCurrecy, bookingJSON, false, false, false);
    const countryCodeList = ['PR', 'US', 'VI'];

    if (bookingJSON.data.airItinerary[0].conditions !== undefined && bookingJSON.data.airItinerary[0].conditions !== null && bookingJSON.data.airItinerary[0].conditions.isHold !== undefined && bookingJSON.data.airItinerary[0].conditions.isHold !== null) {
        if (!bookingJSON.data.airItinerary[0].conditions.isHold) {
            isHoldAllowed = false;
        }
    }

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
        setIsCountryChanged(true);
        // setCityCode('');
        setCityName('');
        setCityList(cityList);
        setStateCode('');
        setStateList(stateList);
        setStateName('');
        setCountryCodeInputHasError(false);
    };
    //Code for Bind Airline Program Data
    const { sendRequest: sendRequestCreditCardData, data: loadedCreditCardData } = useHttp(getCreditCardDetails);
    const { sendRequest: sendRequestBlockTicket, status: statusBlockTicket, data: loadedBlockTicket, error: errorBlockTicket } = useHttp(blockTicket);

    let isFamilyBooking = false;
    if (localStorage.getItem('isCorporate') === 'true') {
        let tripReasonList = [];
        let tripReasonAndPolicyDataJson = "";
        let travelRequestFieldsDataJson = "";
        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));

        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            if (tripReasonAndPolicyDataJson.tripReason.length > 0) {
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }
        }

        //fetched the isFamilyBooking property value, which will be further use to take decisions like traveler detail will visible or not
        if (tripReasonList.length > 0 && tripReasonList[0] !== undefined && tripReasonList[0].isFamilyBooking !== undefined) {
            isFamilyBooking = tripReasonList[0].isFamilyBooking
        }

        if (localStorage.getItem("TravelRequestOutput") !== null) {
            let travelRequestOutputJSON = JSON.parse(localStorage.getItem("TravelRequestOutput"));
            bookingJSON.data.shoppingID = travelRequestOutputJSON.data.shoppingID.toString();
        }

        let profileDataJson = "";
        if (localStorage.getItem("loginData") !== null) {
            profileDataJson = JSON.parse(localStorage.getItem("loginData"));
        }

        if (isFamilyBooking === false && localStorage.getItem('isCorporate') === 'true') {
            bookingJSON.data.travelerInfo.traveler.forEach(element => {
                element.email[0].emailId = JSON.parse(localStorage.getItem('email'));
                element.telephone[0].phoneNumber = JSON.parse(localStorage.getItem('phoneCode')) + " " + JSON.parse(localStorage.getItem('phoneNumber'));
                element.telephone[0].mobile = JSON.parse(localStorage.getItem('phoneCode')) + " " + JSON.parse(localStorage.getItem('phoneNumber'));
                element.Gender = profileDataJson.gender.trim() === "Male" ? "M" : "F";
            });
        }

        if (isFamilyBooking === true && localStorage.getItem('isCorporate') === 'true') {
            bookingJSON.data.travelerInfo.traveler.forEach(element => {
                element.email[0].emailId = JSON.parse(localStorage.getItem('email'));
                element.telephone[0].phoneNumber = JSON.parse(localStorage.getItem('phoneCode')) + " " + JSON.parse(localStorage.getItem('phoneNumber'));
                element.telephone[0].mobile = JSON.parse(localStorage.getItem('phoneCode')) + " " + JSON.parse(localStorage.getItem('phoneNumber'));
            });
        }

        if (isFamilyBooking === false && localStorage.getItem('isCorporate') === 'true') {
            bookingJSON.data.travelerInfo.traveler.forEach(element => {
                let passportIsRequired = false;

                if (getConfigurationByBoolen('PASSPORT_MANDATORY')) {
                    let base_country = getConfigurationByValue("BASE_COUNTRY_CODE")
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(element => {
                        if (element.flightSegment[0].departureAirport.countryCode.trim() !== base_country || element.flightSegment[0].arrivalAirport.countryCode !== base_country) {
                            passportIsRequired = true;
                        }
                    });
                }
                if (getConfigurationByValue("PASSPORT_REQUIRED") !== null && getConfigurationByValue("PASSPORT_REQUIRED") !== '') {
                    let validatingCarrierArray = getConfigurationByValue("PASSPORT_REQUIRED").split(',');
                    if (validatingCarrierArray.length > 0) {
                        bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                            elementAirItinerary.originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                                elementOriginDestinationOption.flightSegment.forEach(elementFlightSegment => {
                                    if (validatingCarrierArray.indexOf(elementFlightSegment.marketingAirline.code) !== -1) {
                                        passportIsRequired = true;
                                    }
                                });
                            });
                        });
                    }
                }

                element.PersonName.NamePrefix = profileDataJson.title.trim();
                element.PersonName.GivenName = profileDataJson.firstName.trim();
                element.PersonName.Surname = profileDataJson.lastName.trim();
                element.PersonName.MiddleName = profileDataJson.middleName.trim();
                element.dateOfBirth.date = profileDataJson.dob.trim().split(/[/ -]/)[2] + profileDataJson.dob.trim().split(/[/ -]/)[0] + profileDataJson.dob.trim().split(/[/ -]/)[1];

                if (profileDataJson.documentTypes !== undefined && profileDataJson.documentTypes[0] !== undefined) {
                    element.Document[0].DocID = profileDataJson.documentTypes[0].documentNumber.trim();
                    element.Document[0].ExpireDate = profileDataJson.documentTypes[0].expiryDate.split(/[/ -]/)[2] + profileDataJson.documentTypes[0].expiryDate.split(/[/ -]/)[0] + profileDataJson.documentTypes[0].expiryDate.split(/[/ -]/)[1];
                    element.Document[0].DocIssueCountry = profileDataJson.documentTypes[0].placeOfIssue.trim();
                    element.Document[0].DocHolderNationality = profileDataJson.documentTypes[0].nationality.trim()
                }

                if (element.TravelerRefNumber !== undefined) {
                    if (document.querySelector('#passengerName' + element.TravelerRefNumber) !== null) {
                        document.querySelector('#passengerName' + element.TravelerRefNumber).innerHTML = element.PersonName.NamePrefix + " " + element.PersonName.GivenName + " " + element.PersonName.Surname;
                    }
                }
                else if (element.travelerRefNumber !== undefined) {
                    if (document.querySelector('#passengerName' + element.travelerRefNumber) !== null) {
                        document.querySelector('#passengerName' + element.travelerRefNumber).innerHTML = element.PersonName.NamePrefix + " " + element.PersonName.GivenName + " " + element.PersonName.Surname;
                    }
                }
            });
        }
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
    }

    // User Story 9768: Flight  : Display Dynamic Term and condition
    const { sendRequest: getTermsAndConditions, status: termsAndConditionStatus, data: loadedTermsAndConditions, error: termsAndConditionErroor } = useHttp(searchStaticPage);
    const [termsAndConditionData, setTermsAndConditionData] = useState(null);
    useEffect(() => {
        const searchInput = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "ipAddress": "",
            "termAndCondition": "",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "pageID": "1",
            "pageTitle": ""


        });
        getTermsAndConditions(searchInput);

    }, [])
    useEffect(() => {
        if (termsAndConditionStatus === 'completed') {
            if (loadedTermsAndConditions['errors'].status === 'FALSE' && loadedTermsAndConditions.data[0].isActive) {

                setTermsAndConditionData(loadedTermsAndConditions.data[0]);
            }
        }
    }, [termsAndConditionStatus]);

    useEffect(() => {
        sendRequestCreditCardData();
    }, [sendRequestCreditCardData])

    let creditCardOptions = []
    let creditCardArrayData = [];

    if (loadedCreditCardData !== null && loadedCreditCardData.errors.status === 'FALSE') {
        creditCardArrayData = loadedCreditCardData.data;
        for (let index = 0; index < creditCardArrayData.length; index++) {
            creditCardOptions = [...creditCardOptions, { value: creditCardArrayData[index].code, label: creditCardArrayData[index].name }]
        }
    }

    const stateCodeValue = (stateCode) => {
        setStateCode(stateCode);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        setStateNameInputHasError(false);
        setIsStateChanged(true);
    };

    const stateNameText = (stateName) => {
        if (stateName.length > 0) {
            if (!stateName.match('^[a-zA-Z ]+$')) {
                setStateNameInvalidInputError(true);
                //return;
            }
            else {
                setStateNameInvalidInputError(false);
            }
        }
        else {
            setRefreshStateNameText(true);
        }
        setStateName(stateName);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        if (stateName.trim() === '') {
            //setStateNameInputHasError(true);
        }
        else {
            setStateNameInputHasError(false);
        }
        setIsStateChanged(true);
    };
    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };


    // const cityCodeValue = (cityCode) => {
    //     setCityCode(cityCode);
    //     setIsCountryChanged(false);
    //     setCityCodeInputHasError(false);
    //     if (stateCode === '' && stateName === '') {
    //         setIsStateChanged(true);
    //     }
    //     else {
    //         setIsStateChanged(false);
    //     }

    //     if (postalCodeInputRef.current.value.trim() === '') {
    //         setPostalCodeInputHasError(true);
    //         setPostalCodeInvalidInputError(false);
    //         return;
    //     }
    //     else {
    //         setPostalCodeInputHasError(false);
    //     }
    // };

    useEffect(() => {
        if (profileLocaterNumber === '' || profileLocaterNumber === null) {
            setProfileLocaterNumber(JSON.parse(localStorage.getItem("profileLocaterNumber")));
        }

        if (getConfigurationByBoolen("FLIGHT_CREDIT_CARD") === true && sourceId !== 'TJ') {
            onChangeCardType("1");
        }
        else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") === true && sourceId !== 'TJ') {
            onChangeCardType("2");
        }
        else if (getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") === true && isHoldAllowed) {
            onChangeCardType("3");
        }
        else if (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") === true) {
            onChangeCardType("4");
        }

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }

    }, [profileLocaterNumber])

    const errorHandler = () => {
        setError(null);

        if (localStorage.getItem('isCorporate') === 'true' && loadedBookingResponse !== null && loadedBookingResponse.data !== null) {
            sessionStorage.removeItem("travelRequestFieldsDataJson");
            history.push('/MyTrips?target=t');
        }
    };

    function onContinueButton(tabID) {

        if (tabID === "4" && (!getConfigurationByBoolen("ALLOW_SERVICE_FEE") || localStorage.getItem('isCorporate') === 'true')) {
            tabID = "5";
        }

        if (tabID === "1" && localStorage.getItem('isCorporate') === 'true') {
            tabID = "2";
        }

        for (let index = 1; index < 4; index++) {
            if (document.querySelector('#itineraryDetails' + index) !== null) {
                var controls = document.querySelectorAll('[data="collapseExampleCommon"]');
                if (controls[1] !== undefined) {
                    if (controls[1].className === 'flight_policy_refund collapse show') {
                        document.querySelector('#itineraryDetails' + index).click();
                    }
                }
                else {
                    if (controls[0].className === 'flight_policy_refund collapse show') {
                        document.querySelector('#itineraryDetails' + index).click();
                    }
                }
            }
        }

        // code to hide fare summary on booking page
        if (document.querySelector('#FareSummaryCollapse') !== null) {
            if (document.querySelector('#FareSummaryCollapse').classList.contains('show')) {
                document.querySelector('#btnFareSummaryCollapse').click();
            }
        }

        if (tabID === "1") {
            document.getElementById("completeOne").style.display = 'block';
            document.getElementById("headingTravelers").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            if (document.getElementById("headingTwo") !== null) {
                document.getElementById("headingTwo").setAttribute("class", "accordion-button");
                document.getElementById("headingTwo").setAttribute("data-bs-toggle", "collapse");
                document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse show");
            }
            document.getElementById("headingSeatMap").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSeatMap").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingMarkup") !== null) {
                document.getElementById("headingMarkup").setAttribute("class", "text-muted accordion-button collapsed");
                document.getElementById("collapseMarkup").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "2") {
            if (document.getElementById("completeOne") !== null && localStorage.getItem('isCorporate') === 'true') {
                document.getElementById("completeOne").style.display = 'block';
            }
            if (document.getElementById("completeTwo") !== null) {
                document.getElementById("completeTwo").style.display = 'block';
            }
            document.getElementById("headingTravelers").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            if (document.getElementById("headingTwo") !== null) {
                document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSeatMap").setAttribute("class", "accordion-button ");
            document.getElementById("headingSeatMap").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseSeatMap").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingMarkup") !== null) {
                document.getElementById("headingMarkup").setAttribute("class", "text-muted accordion-button collapsed");
                document.getElementById("collapseMarkup").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "3") {
            document.getElementById("completeSeatMap").style.display = 'block';
            if (document.getElementById("headingTravelers") !== null) {
                document.getElementById("headingTravelers").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            }
            if (document.getElementById("headingTwo") !== null) {
                document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSeatMap").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSeatMap").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button ");
            document.getElementById("headingFour").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse show");
            if (document.getElementById("headingMarkup") !== null) {
                document.getElementById("headingMarkup").setAttribute("class", "text-muted accordion-button collapsed");
                document.getElementById("collapseMarkup").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "4") {
            document.getElementById("completeFour").style.display = 'block';
            if (document.getElementById("headingTravelers") !== null) {
                document.getElementById("headingTravelers").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            }
            if (document.getElementById("headingTwo") !== null) {
                document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSeatMap").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSeatMap").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingMarkup") !== null) {
                document.getElementById("headingMarkup").setAttribute("class", "accordion-button ");
                document.getElementById("headingMarkup").setAttribute("data-bs-toggle", "collapse");
                document.getElementById("collapseMarkup").setAttribute("class", "accordion-collapse collapse show");
            }
            document.getElementById("headingSix").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "5") {
            if (document.getElementById("completeFour") !== null && (!getConfigurationByBoolen("ALLOW_SERVICE_FEE") || localStorage.getItem('isCorporate') === 'true')) {
                document.getElementById("completeFour").style.display = 'block';
            }
            if (document.getElementById("completeFive") !== null) {
                document.getElementById("completeFive").style.display = 'block';
            }
            if (document.getElementById("headingTravelers") !== null) {
                document.getElementById("headingTravelers").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            }
            if (document.getElementById("headingTwo") !== null) {
                document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSeatMap").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseSeatMap").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingMarkup") !== null) {
                document.getElementById("headingMarkup").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseMarkup").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingSix").setAttribute("class", "accordion-button ");
            document.getElementById("headingSix").setAttribute("data-bs-toggle", "collapse");
            //document.getElementById("collapseSix").setAttribute("class", "accordion-collapse collapse show");
            document.querySelector('#headingSix').click();
        }

        // refresh fare summary pop up on add/changes in service fee
        if (tabID === "5") {
            setRefreshServiceFee(true);
        }
    }
    function SendRequest(event) {
        event.preventDefault();
        onBookPayment("1")
    }
    function OnHold(event) {
        event.preventDefault();
        onBookPayment("2")
    }
    function IssueTicket(event) {
        event.preventDefault();
        onBookPayment("3")
    }
    //   User Story 2338: Add remarks to the Sabre PNR
    function passHiddenRemarksToBookingJson(text) {
        const hiddenRemarks = ({

            "code": "",
            "text": text,
            "remarkType": "Hidden"

        });
        return hiddenRemarks;
    }

    async function onBookPayment(id) {

        if (document.getElementById("checkCreditCard") !== null && document.getElementById("checkCreditCard").checked) {
            if (cardType === '') {
                setCardTypeInputHasError(true);
                return;
            } else {
                setCardTypeInputHasError(false);
            }

            if (cardNumberInputRef.current.value.trim() === '') {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(true);
                setCardNumberInvalidInputError(false);
                return;
            }
            else {
                setCardNumberInputHasError(false);
            }

            // let isValid =
            //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
            //         cardNumberInputRef.current.value.trim().length == 15 &&
            //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
            //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
            //     (cardNumberInputRef.current.value.trim().length == 16 &&
            //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
            //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

            // if (!isValid) {
            //     cardNumberInputRef.current.focus();
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(true);
            //     return;
            // }
            // else {
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(false);
            // }

            var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
            if (!numberValidation.isValid) {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }

            if (numberValidation.isValid) {
                if (numberValidation.card.niceType !== cardName) {
                    cardNumberInputRef.current.focus();
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(true);
                    return;
                }
                else {
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(false);
                }
            }

            if (cardHolderNameInputRef.current.value.trim() === '') {
                cardHolderNameInputRef.current.focus();
                setCardHolderNameInputHasError(true);
                setCardHolderNameInvalidInputError(false);
                return;
            }
            else {
                setCardHolderNameInputHasError(false);
            }

            if (cardHolderNameInputRef.current.value.trim() !== '') {
                if (!cardHolderNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                    cardHolderNameInputRef.current.focus();
                    setCardHolderNameInvalidInputError(true);
                    setCardHolderNameInputHasError(false);
                    return;
                }
                else {
                    setCardHolderNameInvalidInputError(false);
                }
            }

            if (expiryMonth === '') {
                setExpiryMonthInputHasError(true);
                return;
            }
            else {
                setExpiryMonthInputHasError(false);
            }

            if (expiryYear === '') {
                setExpiryYearInputHasError(true);
                return;
            }
            else {
                setExpiryYearInputHasError(false);
            }

            if (expiryMonth !== '' && expiryYear !== '') {
                setExpiryMonthInputHasError(false);
                setExpiryYearInputHasError(false);

                var formattedExpiryDate = new Date(expiryYear, expiryMonth - 1, new Date().getDate());
                var dateDiffValue = dateDiff(new Date(), formattedExpiryDate);
                let isExpiryDateValid = false;

                if (dateDiffValue.years < 0) {
                    isExpiryDateValid = true;
                }
                else if (dateDiffValue.months < 0) {
                    isExpiryDateValid = true;
                }
                else if (dateDiffValue.days < 0) {
                    isExpiryDateValid = true;
                }

                if (moment(`${expiryYear}-${expiryMonth}-${new Date().getDate()}`).isValid() && !isExpiryDateValid) {
                    setDateofExpiryInvalidInputError(true);
                    return;
                }
                else {
                    setDateofExpiryInvalidInputError(false);
                }
            }
            else {
                setDateofExpiryInvalidInputError(false);
            }

            if (address.trim() === '') {
                addressInputRef.current.focus();
                setAddressInputHasError(true);
                setAddressInvalidInputError(false);
                return;
            }
            else {
                setAddressInputHasError(false);
            }

            if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
                addressInputRef.current.focus();
                setAddressInvalidInputError(true);
                setAddressInputHasError(false);
                return;
            }
            else {
                setAddressInvalidInputError(false);
            }

            if (countryCode === '') {
                setCountryCodeInputHasError(true);
                return;
            }
            else {
                setCountryCodeInputHasError(false);
            }

            if (controlType !== '') {
                if (controlType === 'txt') {
                    setStateCode('');
                    setStateCodeInputHasError(false);
                    if (stateName.trim() === '') {
                        setStateNameInputHasError(true);
                        return;
                    }
                    else {
                        setStateNameInputHasError(false);
                    }
                }
                else {
                    setStateName('');
                    setStateNameInputHasError(false);
                    if (stateCode === '') {
                        setStateCodeInputHasError(true);
                        return;
                    }
                    else {
                        setStateCodeInputHasError(false);
                    }
                }
            }

            if (isCountryChanged === true || (stateCode === '' && stateName.trim() === '')) {
                setStateCodeInputHasError(true);
                return
            }

            // if (isCountryChanged === true || (isCountryChanged === false && address.trim() !== '')) {
            //     if (cityCode === '' && stateCodeInputHasError === false && stateNameInputHasError === false && stateNameInvalidInputError === false) {
            //         setCityCodeInputHasError(true);
            //         return
            //     }
            // }

            if (stateName.length > 0) {
                if (!stateName.match('^[a-zA-Z ]+$')) {
                    setStateNameInvalidInputError(true);
                    return;
                }
                else {
                    setStateNameInvalidInputError(false);
                }
            }

            const enteredCityName = cityNameInputRef.current.value;
            if (enteredCityName.trim() === '') {
                cityNameInputRef.current.focus();
                setCityNameInputHasError(true);
                setCityNameInvalidInputError(false);
                return;
            }
            else {
                setCityNameInputHasError(false);
            }

            if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
                cityNameInputRef.current.focus();
                setCityNameInvalidInputError(true);
                setCityNameInputHasError(false);
                return;
            }
            else {
                setCityNameInvalidInputError(false);
            }
            if (postalCodeInputRef.current.value.trim() === '') {
                postalCodeInputRef.current.focus();
                setPostalCodeInputHasError(true);
                setPostalCodeInvalidInputError(false);
                return;
            }
            else {
                setPostalCodeInputHasError(false);
            }

            if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
                postalCodeInputRef.current.focus();
                setPostalCodeInvalidInputError(true);
                setPostalCodeInputHasError(false);
                return;
            }
            else {
                if (countryCodeList.includes(countryCode)) {
                    if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                        setPostalCodeInvalidInputError(false);
                    }
                    else {
                        postalCodeInputRef.current.focus();
                        setPostalCodeInvalidInputError(true);
                        setPostalCodeInputHasError(false);
                        return;
                    }
                }
                else {
                    setPostalCodeInvalidInputError(false);
                }
            }

            let newStateCode = '';
            let newStateName = '';

            if (controlType === '') {
                if (stateCode !== '') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }
            else {
                if (controlType === 'ddl') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }

            if (postalCodeInputRef.current.value !== '') {
                var inputs = document.querySelectorAll('.currencyconfirm-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnCurrencyConfirmMessage').innerHTML = "Please agree to the flight booking currency.";
                        return;
                    }
                }

                var inputs = document.querySelectorAll('.ccfee-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnCcFeeMessage').innerHTML = "Please agree with charge for payment made using a credit card.";
                        return;
                    }
                }

                var inputs = document.querySelectorAll('.tandc-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the terms and conditions to proceed.";
                        return;
                    }
                }
            }
        }
        else if ((document.querySelector('#checkSabreCreditCard') !== null && document.querySelector('#checkSabreCreditCard').checked) || (document.querySelector('#checkOnHold') !== null && document.querySelector('#checkOnHold').checked) || (document.querySelector('#checkWallet') !== null && document.querySelector('#checkWallet').checked)) {
            var inputs = document.querySelectorAll('.currencyconfirm-payment-option');
            for (var i = 0; i < inputs.length; i++) {
                if (!inputs[i].checked) {
                    inputs[i].focus();
                    document.querySelector('#spnCurrencyConfirmMessage').innerHTML = "Please agree to the flight booking currency.";
                    return;
                }
            }

            if ((document.querySelector('#checkSabreCreditCard') !== null && document.querySelector('#checkSabreCreditCard').checked) || (document.querySelector('#checkWallet') !== null && document.querySelector('#checkWallet').checked)) {
                var inputs = document.querySelectorAll('.ccfee-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnCcFeeMessage').innerHTML = "Please agree with charge for payment made using a credit card.";
                        return;
                    }
                }
            }

            var inputs = document.querySelectorAll('.tandc-payment-option');
            for (var i = 0; i < inputs.length; i++) {
                if (!inputs[i].checked) {
                    inputs[i].focus();
                    document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the terms and conditions to proceed.";
                    return;
                }
            }
        }

        if (document.querySelector('#btnSendRequest') !== null) {
            document.querySelector('#btnSendRequest').style.display = "none";
        }
        if (document.querySelector('#btnOnHold') !== null) {
            document.querySelector('#btnOnHold').style.display = "none";
        }
        if (document.querySelector('#btnIssueTicket') !== null) {
            document.querySelector('#btnIssueTicket').style.display = "none";
        }

        //User Story 1073: Booking Summary for Single PNR
        let markupAmount = 0;
        if (document.querySelector('#spnMarkupAmountFlight') !== null) {
            if (document.querySelector('#spnMarkupAmountFlight').innerHTML.length > 1) {
                if (document.querySelector('#spnMarkupAmountFlight').innerHTML.indexOf('&nbsp;') !== -1) {
                    markupAmount = parseFloat(document.querySelector('#spnMarkupAmountFlight').innerHTML.split('&nbsp;')[1]);
                }
                else {
                    let currencySymbolLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: fareCurrecy }).format(0).toString().split('.')[0].length <= 2 ? 1 : 3;
                    markupAmount = parseFloat(document.querySelector('#spnMarkupAmountFlight').innerHTML.replaceAll('&nbsp;', '').replaceAll(' ', '').substring(currencySymbolLength, document.querySelector('#spnMarkupAmountFlight').innerHTML.length));
                }
            }
        }

        let cardNumber = "";
        if (cardNumberInputRef.current.value.trim() !== '') {
            cardNumber = await encrypted(cardNumberInputRef.current.value.trim());
        }

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        bookingJSON.data.airItinerary.forEach(element => {
            element.PaymentInfo.TicketingInfo.IssueTicket = id === '1' ? 'S' : id === '3' ? 'Y' : 'N';
            element.PaymentInfo.TicketingInfo.CancelPnrIfTicketFailed = id === '3' && getConfigurationByValue("ACCESS_GLOBETROTTER") !== null && getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? 'Y' : 'N';

            element.PaymentInfo.PaymentMode.forEach(elementPaymentMode => {

                if (document.getElementById("checkCreditCard") !== null && document.getElementById("checkCreditCard").checked === true) {
                    elementPaymentMode.Name = "CREDITCARD";
                }


                else if (document.getElementById("checkSabreCreditCard") !== null && document.getElementById("checkSabreCreditCard").checked === true) {
                    elementPaymentMode.Name = "PROFILE";
                }

                else if (document.getElementById("checkOnHold") !== null && document.getElementById("checkOnHold").checked === true) {
                    elementPaymentMode.Name = "N/A";
                }

                else if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked === true) {
                    elementPaymentMode.Name = "WALLET";
                }

                elementPaymentMode.Amount = element.airItineraryPricingInfo.totalFare.amount.length > 0 ? parseFloat(element.airItineraryPricingInfo.totalFare.amount) > 0 ? (element.airItineraryPricingInfo.totalFare.amount - markupAmount).toString() : element.airItineraryPricingInfo.totalFare.amount : '0.00';

                elementPaymentMode.PaymentModeInfo.forEach(elementPaymentModeInfo => {
                    if (elementPaymentModeInfo.FieldName === "CARDTYPE")
                        elementPaymentModeInfo.FieldValue = cardType;
                    if (elementPaymentModeInfo.FieldName === "CARDNO")
                        elementPaymentModeInfo.FieldValue = cardNumber;
                    if (elementPaymentModeInfo.FieldName === "NAMEONCARD")
                        elementPaymentModeInfo.FieldValue = cardHolderNameInputRef.current.value.trim();
                    if (elementPaymentModeInfo.FieldName === "EXPIRYEAR") {

                        elementPaymentModeInfo.FieldValue = expiryYear;
                    }
                    if (elementPaymentModeInfo.FieldName === "EXPIRMONTH") {

                        elementPaymentModeInfo.FieldValue = expiryMonth;
                    }
                    if (elementPaymentModeInfo.FieldName === "ADDRESS")
                        elementPaymentModeInfo.FieldValue = address.trim();
                    if (elementPaymentModeInfo.FieldName === "COUNTRY")
                        elementPaymentModeInfo.FieldValue = countryCode;
                    if (elementPaymentModeInfo.FieldName === "STATE")
                        elementPaymentModeInfo.FieldValue = stateCode !== '' ? stateCode : stateName.trim();
                    if (elementPaymentModeInfo.FieldName === "CITY")
                        elementPaymentModeInfo.FieldValue = cityNameInputRef.current.value.trim();
                    if (elementPaymentModeInfo.FieldName === "POSTALCODE")
                        elementPaymentModeInfo.FieldValue = postalCodeInputRef.current.value.trim();

                });
            });

        });

        bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.forEach(element => {
            if (element.RemarkType === "General") {
                element.Text = sourceId !== 'TJ' && document.querySelector('#remarksGeneral').value !== undefined && document.querySelector('#remarksGeneral').value !== '' ? document.querySelector('#remarksGeneral').value : '';
            }
            else if (element.RemarkType === "InternalRemarks") {
                element.Text = document.querySelector('#remarksInternal') !== null && document.querySelector('#remarksInternal') !== undefined && document.querySelector('#remarksInternal').value !== undefined && document.querySelector('#remarksInternal').value !== '' ? document.querySelector('#remarksInternal').value : '';
            }
        });

        if (localStorage.getItem('profileLocaterNumber') !== null && JSON.parse(localStorage.getItem('profileLocaterNumber')) !== "") {
            let text = "Sabre Profile Locator- " + JSON.parse(localStorage.getItem('profileLocaterNumber'))
            bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('companyName') !== null && localStorage.getItem('companyName') !== '') {
            let text = "Office- " + JSON.parse(localStorage.getItem('companyName'));
            bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('loginEmailID') !== null && localStorage.getItem('loginEmailID') !== '') {
            let text = "Login Email ID- " + JSON.parse(localStorage.getItem('loginEmailID'))
            bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('displayName') !== null && localStorage.getItem('displayName') !== '') {
            let text = "Created By- " + JSON.parse(localStorage.getItem('displayName'))
            bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        let text = bookingJSON.data.airItinerary[0].PaymentInfo.PaymentMode[0].Name;
        if (text !== '' || text !== undefined) {

            bookingJSON.data.MiscellaneousRemarkInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson("FOP- " + text));
        }

        bookingJSON.data.travelerInfo.SpecialReqDetails.forEach(specialReqDetailsElement => {
            if (specialReqDetailsElement.SpecialServiceRequests !== null && specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest !== undefined) {
                let filteredSsr1 = specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType !== 'MEAL');

                bookingJSON.data.travelerInfo.traveler.forEach(airTravelerElement => {
                    if (specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.TravelerRefNumber === airTravelerElement.TravelerRefNumber && a.WayType === '0').length > 1) {
                        filteredSsr1.push(specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest.filter(a => a.SSRType === 'MEAL' && a.TravelerRefNumber === airTravelerElement.TravelerRefNumber && a.WayType === '0')[0]);
                    }
                    else {
                        specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest.forEach(specialServiceRequestElement => {
                            if (specialServiceRequestElement.SSRType === 'MEAL' && specialServiceRequestElement.TravelerRefNumber === airTravelerElement.TravelerRefNumber) {
                                filteredSsr1.push(specialServiceRequestElement);
                            }
                        });
                    }
                });
                specialReqDetailsElement.SpecialServiceRequests.SpecialServiceRequest = filteredSsr1;


            }
        });

        bookingJSON.data.roundingValue = document.querySelector('#spnTotalAmountTop') !== null ? document.querySelector('#spnTotalAmountTop').innerHTML.includes('.') ? document.querySelector('#spnTotalAmountTop').innerHTML.split('.')[1].length.toString() : "2" : "2";

        if (id === '1') {
            bookingJSON.data.RequestType = "OF";
        }
        else {
            bookingJSON.data.RequestType = "ON";
        }

        if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked === true) {
            let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentFare = calculateRoeAmountMain(fareCurrecy, bookingJSON, true, false, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentCurrencyCode = walletCurrency;
            bookingJSON.data.airItinerary[0].PaymentInfo.PaymentMode.filter(a => a.Name === 'WALLET')[0].EquivalentAmount = calculateRoeAmountMain(fareCurrecy, bookingJSON, false, false, false);
            bookingJSON.data.airItinerary[0].PaymentInfo.PaymentMode.filter(a => a.Name === 'WALLET')[0].Amount = calculateRoeAmountMain(fareCurrecy, bookingJSON, false, false, true);
            bookingJSON.data.airItinerary[0].PaymentInfo.PaymentMode.filter(a => a.Name === 'WALLET')[0].ConversionRate = calculateRoeAmountMain(fareCurrecy, bookingJSON, false, true, false).toString();

            let totalBaseFareAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount;
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.equivalentFare = calculateRoeAmountAdditional(fareCurrecy, totalBaseFareAmount, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.equivalentCurrencyCode = walletCurrency;

            let totalTaxAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount;
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.equivalentFare = calculateRoeAmountAdditional(fareCurrecy, totalTaxAmount, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.equivalentCurrencyCode = walletCurrency;

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                if (elementPassengerTypeQuantity.commisionInfo !== undefined && elementPassengerTypeQuantity.commisionInfo !== null && elementPassengerTypeQuantity.commisionInfo[0] !== undefined && elementPassengerTypeQuantity.commisionInfo[0] !== null) {
                    elementPassengerTypeQuantity.commisionInfo.forEach(elementCommisionInfo => {
                        if (elementCommisionInfo.commissionAmount !== undefined && elementCommisionInfo.commissionAmount !== null && elementCommisionInfo.commissionAmount !== '') {

                            let commissionAmount = elementCommisionInfo.commissionAmount;
                            elementCommisionInfo.equivalentCommissionAmount = calculateRoeAmountAdditional(fareCurrecy, commissionAmount, false);
                            elementCommisionInfo.equivalentCurrencyCode = walletCurrency;
                        }
                    });
                }

                elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees.fee !== undefined && elementPassengerTypeQuantity.fees.fee.length !== 0 && elementPassengerTypeQuantity.fees.fee.forEach(elementFees => {
                    if (elementFees.text === 'ServiceFee-Amount') {
                        let serviceFeeAmount = elementFees.amount
                        elementFees.equivalentAmount = calculateRoeAmountAdditional(fareCurrecy, serviceFeeAmount, false);
                        elementFees.equivalentCurrencyCode = walletCurrency;
                    }
                    else if (elementFees.text === 'ServiceFee-Percentage') {
                        let serviceFeeAmount = elementFees.amount
                        elementFees.equivalentAmount = calculateRoeAmountAdditional(fareCurrecy, serviceFeeAmount, false);
                        elementFees.equivalentCurrencyCode = walletCurrency;
                    }
                })
            });
        }
        else {
            let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentFare = calculateRoeAmountMain(fareCurrecy, bookingJSON, true, false, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentCurrencyCode = walletCurrency;
            bookingJSON.data.airItinerary[0].PaymentInfo.PaymentMode[0].ConversionRate = calculateRoeAmountMain(fareCurrecy, bookingJSON, false, true, false).toString();

            let totalBaseFareAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount;
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.equivalentFare = calculateRoeAmountAdditional(fareCurrecy, totalBaseFareAmount, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.equivalentCurrencyCode = walletCurrency;

            let totalTaxAmount = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount;
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.equivalentFare = calculateRoeAmountAdditional(fareCurrecy, totalTaxAmount, false);
            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.equivalentCurrencyCode = walletCurrency;

            bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                if (elementPassengerTypeQuantity.commisionInfo !== undefined && elementPassengerTypeQuantity.commisionInfo !== null && elementPassengerTypeQuantity.commisionInfo[0] !== undefined && elementPassengerTypeQuantity.commisionInfo[0] !== null) {
                    elementPassengerTypeQuantity.commisionInfo.forEach(elementCommisionInfo => {
                        if (elementCommisionInfo.commissionAmount !== undefined && elementCommisionInfo.commissionAmount !== null && elementCommisionInfo.commissionAmount !== '') {

                            let commissionAmount = elementCommisionInfo.commissionAmount;
                            elementCommisionInfo.equivalentCommissionAmount = calculateRoeAmountAdditional(fareCurrecy, commissionAmount, false);
                            elementCommisionInfo.equivalentCurrencyCode = walletCurrency;
                        }
                    });
                }

                elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees.fee !== undefined && elementPassengerTypeQuantity.fees.fee.length !== 0 && elementPassengerTypeQuantity.fees.fee.forEach(elementFees => {
                    if (elementFees.text === 'ServiceFee-Amount') {
                        let serviceFeeAmount = elementFees.amount
                        elementFees.equivalentAmount = calculateRoeAmountAdditional(fareCurrecy, serviceFeeAmount, false);
                        elementFees.equivalentCurrencyCode = walletCurrency;
                    }
                    else if (elementFees.text === 'ServiceFee-Percentage') {
                        let serviceFeeAmount = elementFees.amount
                        elementFees.equivalentAmount = calculateRoeAmountAdditional(fareCurrecy, serviceFeeAmount, false);
                        elementFees.equivalentCurrencyCode = walletCurrency;
                    }
                })
            });
        }

        sendRequest(bookingJSON);
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        //document.querySelector('#theme_search_form_tour').style.display = "none";
    }

    const [allowTicketing, SetAllowTicketing] = useState(true);

    useEffect(() => {
        let requestData = {
            "officeID": JSON.parse(localStorage.getItem('officeID'))
        };
        sendRequestBlockTicket(requestData);
    }, sendRequestBlockTicket);


    useEffect(() => {
        if (loadedBlockTicket !== null && loadedBlockTicket.errors.status === 'FALSE') {
            let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));

            let validatingCarrier = bookingJSON.data.airItinerary[0].ValidatingCarrier;
            const found = loadedBlockTicket.data.find(el => el.airlineCode === validatingCarrier);
            if (found) {
                SetAllowTicketing(false);
            }
        }
    }, loadedBlockTicket)

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
        if (status === 'completed') {
            sessionStorage.setItem("TripFlightSuccessMsg", '');
            if (loadedBookingResponse !== null && loadedBookingResponse.data !== null) {

                if (loadedBookingResponse.data.errors.status === 'TRUE') {
                    if (document.getElementById("checkOnHold") !== null && document.getElementById("checkOnHold").checked) {
                        if (document.querySelector('#btnSendRequest') !== null) {
                            document.querySelector('#btnSendRequest').style.display = "";
                        }
                        if (document.querySelector('#btnOnHold') !== null) {
                            document.querySelector('#btnOnHold').style.display = "";
                        }
                    }
                    else {
                        if (document.querySelector('#btnSendRequest') !== null) {
                            document.querySelector('#btnSendRequest').style.display = "";
                        }
                        if (document.querySelector('#btnOnHold') !== null) {
                            document.querySelector('#btnOnHold').style.display = "";
                        }
                        if (document.querySelector('#btnIssueTicket') !== null) {
                            document.querySelector('#btnIssueTicket').style.display = "";
                        }
                    }

                    if (getConfigurationByBoolen("FLIGHT_CREDIT_CARD") === true && sourceId !== 'TJ') {
                        onChangeCardType("1");
                    }
                    else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") === true && sourceId !== 'TJ') {
                        onChangeCardType("2");
                    }
                    else if (getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") === true && isHoldAllowed) {
                        onChangeCardType("3");
                    }
                    else if (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") === true) {
                        onChangeCardType("4");
                    }

                    setConfirm({
                        title: "Message",
                        message: loadedBookingResponse.data.errors.error.description,
                        //details: loadedBookingResponse.data.errors.error.description
                    });
                }
                else {

                    if (localStorage.getItem('isCorporate') === 'true') {
                        let travelRequestOutputJSON = JSON.parse(localStorage.getItem("TravelRequestOutput"));
                        setError({
                            title: "Message",
                            message: 'Success! Your Travel Request has been submitted with Confirmation No. ' + travelRequestOutputJSON.data.referenceNumber + '.',
                        });
                    }
                    else {
                        localStorage.setItem("BookingData", JSON.stringify(loadedBookingResponse));
                        localStorage.removeItem("SeatMapData");
                        localStorage.removeItem("AdditionalServicesData");
                        localStorage.removeItem("FlightIntialTotalAmount");

                        if (loadedBookingResponse.data.requestType === "ON") {
                            if ((loadedBookingResponse.data.bookingReferenceID !== undefined && loadedBookingResponse.data.bookingReferenceID[0].id !== undefined)) {
                                sessionStorage.setItem("TripFlightSuccessMsg", loadedBookingResponse.data.errors.error.description);
                                window.location.pathname = `${CONFIRMATION}`;
                            }
                        }
                        else {
                            window.location.pathname = `${CONFIRMATION}`;
                        }
                    }
                }
            }
            else {
                if (document.getElementById("checkOnHold") !== null && document.getElementById("checkOnHold").checked) {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnHold') !== null) {
                        document.querySelector('#btnOnHold').style.display = "";
                    }
                }
                else {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnHold') !== null) {
                        document.querySelector('#btnOnHold').style.display = "";
                    }
                    if (document.querySelector('#btnIssueTicket') !== null) {
                        document.querySelector('#btnIssueTicket').style.display = "";
                    }
                }

                if (getConfigurationByBoolen("FLIGHT_CREDIT_CARD") === true && sourceId !== 'TJ') {
                    onChangeCardType("1");
                }
                else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") === true && sourceId !== 'TJ') {
                    onChangeCardType("2");
                }
                else if (getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") === true && isHoldAllowed) {
                    onChangeCardType("3");
                }
                else if (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") === true) {
                    onChangeCardType("4");
                }

                setConfirm({
                    title: "Message",
                    message: `Sorry, your booking was unsuccessful since it could not be confirmed with the airline.`,
                });
            }
            return;
        }
    }, [status, history, loadedBookingResponse])

    function cancelHandler() {
        setConfirm(false);
    };

    function onBlurCheck() {
        const enteredCityName = cityNameInputRef.current.value;
        if (cardType === '') {
            setCardTypeInputHasError(true);
            return;
        } else {
            setCardTypeInputHasError(false);
        }

        if (cardNumberInputRef.current.value.trim() === '') {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(true);
            setCardNumberInvalidInputError(false);
            return;
        }
        else {
            setCardNumberInputHasError(false);
        }

        // let isValid =
        //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
        //         cardNumberInputRef.current.value.trim().length == 15 &&
        //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
        //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
        //     (cardNumberInputRef.current.value.trim().length == 16 &&
        //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
        //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

        // if (!isValid) {
        //     //cardNumberInputRef.current.focus();
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(true);
        //     return;
        // }
        // else {
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(false);
        // }

        var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
        if (!numberValidation.isValid) {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(true);
            return;
        }
        else {
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(false);
        }

        if (numberValidation.isValid) {
            if (numberValidation.card.niceType !== cardName) {
                //cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }
        }

        if (cardHolderNameInputRef.current.value.trim() === '') {
            //cardHolderNameInputRef.current.focus();
            setCardHolderNameInputHasError(true);
            setCardHolderNameInvalidInputError(false);
            return;
        }
        else {
            setCardHolderNameInputHasError(false);
        }

        if (cardHolderNameInputRef.current.value.trim() !== '') {
            if (!cardHolderNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                //cardHolderNameInputRef.current.focus();
                setCardHolderNameInvalidInputError(true);
                setCardHolderNameInputHasError(false);
                return;
            }
            else {
                setCardHolderNameInvalidInputError(false);
            }
        }

        if (expiryMonth === '') {
            setExpiryMonthInputHasError(true);
            return;
        }
        else {
            setExpiryMonthInputHasError(false);
        }

        if (expiryYear === '') {
            setExpiryYearInputHasError(true);
            return;
        }
        else {
            setExpiryYearInputHasError(false);
        }

        if (address.trim() === '') {
            setAddressInputHasError(true);
            setAddressInvalidInputError(false);
            return;
        }
        else {
            setAddressInputHasError(false);
        }

        if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
            setAddressInvalidInputError(true);
            setAddressInputHasError(false);
            return;
        }
        else {
            setAddressInvalidInputError(false);
        }

        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (controlType !== '') {
            if (controlType === 'txt') {
                setStateCode('');
                setStateCodeInputHasError(false);
                if (stateName.trim() === '') {
                    //setStateNameInputHasError(true);
                    //return;
                }
                else {
                    setStateNameInputHasError(false);
                }
            }
            else {
                setStateName('');
                setStateNameInputHasError(false);
                if (stateCode === '') {
                    //setStateCodeInputHasError(true);
                    //return;
                }
                else {
                    setStateCodeInputHasError(false);
                }
            }
        }
        if (enteredCityName.trim() === '') {
            setCityNameInputHasError(true);
            setCityNameInvalidInputError(false);
            return;
        }
        else {
            setCityNameInputHasError(false);
        }

        if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
            setCityNameInvalidInputError(true);
            setCityNameInputHasError(false);
            return;
        }
        else {
            setCityNameInvalidInputError(false);
        }

        if (postalCodeInputRef.current.value.trim() === '') {
            //postalCodeInputRef.current.focus();
            setPostalCodeInputHasError(true);
            setPostalCodeInvalidInputError(false);
            return;
        }
        else {
            setPostalCodeInputHasError(false);
        }

        if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
            //postalCodeInputRef.current.focus();
            setPostalCodeInvalidInputError(true);
            setPostalCodeInputHasError(false);
            return;
        }
        else {
            if (countryCodeList.includes(countryCode)) {
                if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                    setPostalCodeInvalidInputError(false);
                }
                else {
                    //postalCodeInputRef.current.focus();
                    setPostalCodeInvalidInputError(true);
                    setPostalCodeInputHasError(false);
                    return;
                }
            }
            else {
                setPostalCodeInvalidInputError(false);
            }
        }
    }
    function onChangeCardType(id) {
        if (document.getElementById("dvTotalPriceTop") !== null) {
            document.getElementById("dvTotalPriceTop").style.display = "block";
        }

        if (document.querySelector('#dvCcFee') !== null) {
            document.querySelector('#dvCcFee').style.display = "";
        }

        if (document.querySelector('#spnCcFeeMessage') !== null) {
            document.querySelector('#spnCcFeeMessage').innerHTML = "";
        }

        if (document.querySelector('#spnTandCPaymentMessage') !== null) {
            document.querySelector('#spnTandCPaymentMessage').innerHTML = "";
        }

        if (id === "1") {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "block";
            }
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = true;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkOnHold") !== null) {
                document.getElementById("checkOnHold").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnHold') !== null) {
                document.querySelector('#btnOnHold').style.display = "";
            }
            if (document.querySelector('#btnIssueTicket') !== null) {
                document.querySelector('#btnIssueTicket').style.display = "";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "";
            }
            if (document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }

            if (getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING") && document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "";
            }
        }
        else if (id === "2") {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = true;
            }
            if (document.getElementById("checkOnHold") !== null) {
                document.getElementById("checkOnHold").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnHold') !== null) {
                document.querySelector('#btnOnHold').style.display = "";
            }
            if (document.querySelector('#btnIssueTicket') !== null) {
                document.querySelector('#btnIssueTicket').style.display = "";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            if (document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }

            if (getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING") && document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "";
            }
        }
        else if (id === "3") {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }

            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkOnHold") !== null) {
                document.getElementById("checkOnHold").checked = true;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnHold') !== null) {
                document.querySelector('#btnOnHold').style.display = "";
            }
            if (document.querySelector('#btnIssueTicket') !== null) {
                document.querySelector('#btnIssueTicket').style.display = "none";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }

            if (getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING") && document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "";
            }

            if (document.querySelector('#dvCcFee') !== null) {
                document.querySelector('#dvCcFee').style.display = "none";
            }
        }
        else if (id === "4") {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }

            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkOnHold") !== null) {
                document.getElementById("checkOnHold").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = true;
            }

            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            if (document.querySelector('#onHoldBookingNote') !== null) {
                document.querySelector('#onHoldBookingNote').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWalletEror") !== null) {

                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWalletEror").style.display = "block";
                    isWalletRadiobuttonChecked = 'true';
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "none";
                    }
                    if (document.querySelector('#btnOnHold') !== null) {
                        document.querySelector('#btnOnHold').style.display = "none";
                    }
                    if (document.querySelector('#btnIssueTicket') !== null) {
                        document.querySelector('#btnIssueTicket').style.display = "none";
                    }
                } else {
                    document.getElementById("divWalletEror").style.display = "none";
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnHold') !== null) {
                        document.querySelector('#btnOnHold').style.display = "";
                    }
                    if (document.querySelector('#btnIssueTicket') !== null) {
                        document.querySelector('#btnIssueTicket').style.display = "";
                    }
                }
            }


            if (document.getElementById("divWallet") !== null) {
                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWallet").style.display = "block";
                    if (document.getElementById("dvTotalPriceTop") !== null) {
                        document.getElementById("dvTotalPriceTop").style.display = "none";
                    }
                } else {
                    document.getElementById("divWallet").style.display = "block";
                    if (document.getElementById("dvTotalPriceTop") !== null) {
                        document.getElementById("dvTotalPriceTop").style.display = "none";
                    }
                }
            }

            if (getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING") && document.querySelector('#onHoldBookingNote') !== null && isHoldAllowed) {
                document.querySelector('#onHoldBookingNote').style.display = "";
            }
        }
    }
    const exposedControlType = (controlType) => {
        setControlType(controlType);
    };
    // const cityNameText = (cityName) => {
    //     setCityName(cityName.split('(')[0].trim());
    // };
    const cardTypeChangeHandler = (event) => {
        setCardType(event.value);
        setCardName(event.label);
        setCardTypeInputHasError(false);
    };
    const expireYearChangeHandler = (event) => {
        setExpiryYear(event.value);
        setExpiryYearInputHasError(false);
        setDateofExpiryInvalidInputError(false);
    };
    const expireMonthChangeHandler = (event) => {
        setExpiryMonth(event.value);
        setExpiryMonthInputHasError(false);
        setDateofExpiryInvalidInputError(false);
    };
    function okHandler() {
        setConfirm('');
    };

    useEffect(() => {
        if (refreshServiceFee) { setRefreshServiceFee(false); }

        const yOffset = -150;
        const element2 = document.getElementById("cardNumber");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

    }, [cardType, expiryMonth, expiryYear, cardHolderNameInputRef,
        cardNumberInputHasError, cardNumberInvalidInputError,
        cardHolderNameInputHasError, cardHolderNameInvalidInputError,
        expiryMonthInputHasError, expiryYearInputHasError, refreshServiceFee, dateofExpiryInvalidInputError])

    useEffect(() => {
        const yOffset = -300;
        const element2 = document.getElementById("address");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

    }, [countryCode, countryCodeInputHasError, stateCode, stateName,
        postalCodeInputHasError, postalCodeInvalidInputError,
        stateCodeInputHasError, stateNameInputHasError,
        stateNameInvalidInputError, cityNameInputRef, cityNameInputHasError, cityNameInvalidInputError,
        address, addressInputRef, addressInputHasError, addressInvalidInputError, refreshStateNameText])

    useEffect(() => {
        const yOffset = -150;
        const element2 = document.getElementById("chkTandcPayment");
        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });

    }, [refreshTermConditions])

    const onTandcPaymentChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-payment-option:checked');
        if (inputs.length === 1) {
            document.querySelector('#spnTandCPaymentMessage').innerHTML = "";
        }
    }

    const onCcFeePaymentChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.ccfee-payment-option:checked');
        if (inputs.length === 1) {
            document.querySelector('#spnCcFeeMessage').innerHTML = "";
        }
    }

    function openTermAndCondtion() {
        setTermsCondition({
            title: "Message",
            message: <TermsConditionsPopup data={termsAndConditionData}></TermsConditionsPopup>
        });

    };

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    const onCurrencyConfirmChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.currencyconfirm-payment-option:checked');
        if (inputs.length === 1) {
            document.querySelector('#spnCurrencyConfirmMessage').innerHTML = "";
        }
    }

    const errorHandlerTermsCondition = () => {
        setTermsCondition(null);
        setRefreshTermConditions(true);
    };

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        {termsCondition && (
            <TermsConditionsPopup
                title={termsCondition.title}
                message={termsCondition.message}
                onConfirm={errorHandlerTermsCondition}

            ></TermsConditionsPopup>
        )}
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        {(status === 'pending') &&
            <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mt-5">
                                <div className="row  position-relative gt-h-255 my-auto">
                                    {
                                        bookingJSON.data.RequestType === "OF" &&
                                        <BookingLoadingSpinner action='requestTypeOf'></BookingLoadingSpinner>
                                    }
                                    {
                                        bookingJSON.data.RequestType === "ON" &&
                                        <BookingLoadingSpinner action='flight'></BookingLoadingSpinner>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        {/*   <!-- Form Area --> */}
        {
            (status !== 'pending') &&
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-4">

                                <div className="row">
                                    <div className="col-lg-12 position-relative">
                                        {/* {status === 'pending' &&

                                        <BookingLoadingSpinner></BookingLoadingSpinner>

                                    } */}
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Flight Summary</label>
                                                    </div>

                                                </div>
                                                <div className='row  aaa'>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                        <FlightItem
                                                            key={bookingJSON.data.airItinerary[0].sequenceNumber}
                                                            id={bookingJSON.data.airItinerary[0].sequenceNumber}
                                                            airItinerary={bookingJSON.data.airItinerary[0]}
                                                            searchFlight={bookingJSON.data.airItinerary[0]}
                                                            fareRuleData={bookingJSON.data.airItinerary[0]}
                                                            bookingData={bookingJSON}
                                                            showData="2"
                                                            hideCommision="5"
                                                            sourceId={sourceId}
                                                        />

                                                    </div>
                                                    {/* <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                        <hr className=''></hr>
                                                    </div> */}
                                                </div>
                                                <div className='row'>
                                                    <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                        <div className="accordion" id="accordionExampleNine">
                                                            <div className="accordion_flex_area  mt-2  ">

                                                                <div className="accordion-item col-md-12">
                                                                    <h2 className="accordion-header" id="headingNine">
                                                                        <button id='btnFareSummaryCollapse' className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                            data-bs-target="#FareSummaryCollapse" aria-expanded="true"
                                                                            aria-controls="FareSummaryCollapse"> <span><i class="fas fa-money-check-alt me-2"></i>

                                                                                {/* User Story 1073: Booking Summary for Single PNR */}
                                                                                {
                                                                                    localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" &&
                                                                                    <span>Flight Fare Summary</span>
                                                                                }
                                                                                {
                                                                                    localStorage.getItem("AddHotel") === null &&
                                                                                    <span>Fare Summary</span>
                                                                                }

                                                                            </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="FareSummaryCollapse" className="accordion-collapse collapse "
                                                                        aria-labelledby="headingNine" data-bs-parent="#accordionExampleNine">
                                                                        <div className="col-md-12">
                                                                            <FareSummery selectedFlight={bookingJSON.data.airItinerary[0]} ></FareSummery>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>

                                                    </div>
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                        <div className="tour_details_boxed_inner">
                                                            <div className="accordion" id="accordionExample">
                                                                {
                                                                    (isFamilyBooking === true || localStorage.getItem('isCorporate') !== 'true') &&
                                                                    <TravellerDetails traveler={bookingJSON.data} onContinueButton={onContinueButton}></TravellerDetails>
                                                                }
                                                                {
                                                                    (localStorage.getItem('isCorporate') !== 'true') &&
                                                                    <ContactPerson traveler={bookingJSON.data} onContinueButton={onContinueButton}></ContactPerson>
                                                                }
                                                                <SeatSelection seatMap={bookingJSON.data} isReadOnlyMode={false} onContinueButton={onContinueButton}></SeatSelection>
                                                                <AdditionalServices traveler={bookingJSON.data} onContinueButton={onContinueButton}></AdditionalServices>
                                                                {
                                                                    getConfigurationByBoolen("ALLOW_SERVICE_FEE") && (localStorage.getItem('isCorporate') !== 'true') &&
                                                                    <AddMarkup onContinueButton={onContinueButton}></AddMarkup>
                                                                }
                                                            </div>

                                                            <div className="accordion_flex_area">
                                                                <div className="accordion_left_side text-muted">
                                                                    <h5>{getConfigurationByBoolen("ALLOW_SERVICE_FEE") === true && localStorage.getItem('isCorporate') !== 'true' ? '6' : document.querySelector('#headingTravelers') === null && localStorage.getItem('isCorporate') === 'true' ? '3' : document.querySelector('#headingTravelers') !== null && localStorage.getItem('isCorporate') === 'true' ? '4' : '5'}</h5>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" >
                                                                        <button id="headingSix" className="text-muted accordion-button collapsed" type="button"
                                                                            data-bs-target="#collapseSix"
                                                                            aria-expanded="false" aria-controls="collapseSix">
                                                                            Payment
                                                                        </button>
                                                                    </h2>

                                                                    <div id="collapseSix" className="accordion-collapse collapse"
                                                                        aria-labelledby="headingfour" data-bs-parent="#accordionExample">

                                                                        <div className="payment_filed_wrapper">
                                                                            <div className='row my-2'>
                                                                                {/* <div className='col-lg-2 text-muted mb-1 fw-bold h6'>Fare Type </div> */}
                                                                                {getConfigurationByBoolen("FLIGHT_CREDIT_CARD") === true && sourceId !== 'TJ' &&
                                                                                    <div className="col-lg-3 col-md-6 col-sm-12  text-muted">
                                                                                        <div className="form-check" onClick={() =>
                                                                                            onChangeCardType("1")}>
                                                                                            <input className="form-check-input" type="radio" onClick={() =>
                                                                                                onChangeCardType("1")} name="flexRadioDefault" id="checkCreditCard" value="white" />
                                                                                            <label className="form-check-label" for="checkCreditCard">
                                                                                                Credit Card
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>}
                                                                                {profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("FLIGHT_SABRE_PROFILE_CREDIT_CARD") === true && sourceId !== 'TJ' ? <div className="col-lg-4 col-md-6 col-sm-12  text-muted" >
                                                                                    <div className="form-check" onClick={() =>
                                                                                        onChangeCardType("2")}>
                                                                                        <input className="form-check-input" type="radio" onClick={() =>
                                                                                            onChangeCardType("2")} name="flexRadioDefault" id="checkSabreCreditCard" value="white" />
                                                                                        <label className="form-check-label" for="checkSabreCreditCard">
                                                                                            Sabre Profile Credit Card
                                                                                        </label>
                                                                                    </div>
                                                                                </div> : <div className="col-lg-2 col-md-6 col-sm-12 text-muted d-none" ><span id="checkSabreCreditCard"></span></div>}

                                                                                {getConfigurationByBoolen("FLIGHT_ON_HOLD_PAYMENT") === true && isHoldAllowed &&
                                                                                    <div className="col-lg-3 col-md-6 col-sm-12  text-muted">
                                                                                        <div className="form-check" onClick={() =>
                                                                                            onChangeCardType("3")}>
                                                                                            <input className="form-check-input" type="radio" onClick={() =>
                                                                                                onChangeCardType("3")} name="flexRadioDefault" id="checkOnHold" value="white" />
                                                                                            <label className="form-check-label" for="checkOnHold">
                                                                                                {/* Wallet */}
                                                                                                On Hold
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>}



                                                                                {profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") === true && isWalletActive === 'true' &&
                                                                                    <div className="col-lg-2 col-md-6 col-sm-12  text-muted">
                                                                                        <div className="form-check" onClick={() =>
                                                                                            onChangeCardType("4")}>
                                                                                            <input className="form-check-input" type="radio" onClick={() =>
                                                                                                onChangeCardType("4")} name="flexRadioDefault" id="checkWallet" value="white" />
                                                                                            <label className="form-check-label" for="checkWallet">
                                                                                                {/* Wallet */}
                                                                                                Wallet
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>}
                                                                            </div>
                                                                            <div id='dvTotalPriceTop' className="col-lg-12 my-3" >
                                                                                <div className="col-md-12 h5 text-muted"> Fare Summary</div>
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Base Fare:</div>
                                                                                    <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnBaseFareTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.currencyCode,
                                                                                        }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount)}
                                                                                    </span></div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Taxes: </div>
                                                                                    <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnTaxesTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.currencyCode,
                                                                                        }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount)}
                                                                                    </span></div>
                                                                                </div>
                                                                                {
                                                                                    getConfigurationByBoolen("ALLOW_SERVICE_FEE") && localStorage.getItem('isCorporate') !== 'true' &&
                                                                                    <div className="row">
                                                                                        <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Service Fee: </div>
                                                                                        <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnServiceFeeTop" className="text-black">
                                                                                            {new Intl.NumberFormat('en-US', {
                                                                                                style: 'currency',
                                                                                                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                                                                                            }).format(0.00)}
                                                                                        </span></div>
                                                                                    </div>
                                                                                }

                                                                                <div className="row">
                                                                                    <div className="col-md-7">
                                                                                        <hr className="my-1">
                                                                                        </hr>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Total Amount: </div>
                                                                                    <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnTotalAmountTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                                                                                        }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)}
                                                                                    </span></div>
                                                                                </div>
                                                                                {
                                                                                    !getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                                                                    <div className="row">
                                                                                        <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Eq. Amount: </div>
                                                                                        <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnTotalEqAmountTop" className="text-black">
                                                                                            {new Intl.NumberFormat('en-US', {
                                                                                                minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                                style: 'currency',
                                                                                                currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                            }).format(calculateRoeAmountAdditional(fareCurrecy, bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount, false))}
                                                                                        </span></div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal !== null &&
                                                                                    <div className="row">
                                                                                        <div className="h6 text-primary mb-2 col-md-3">  Original Fare: </div>
                                                                                        <div className="col-md-4 text-end h6"><span id="spnOriginalFareTop" className="text-black">
                                                                                            {new Intl.NumberFormat('en-US', {
                                                                                                style: 'currency',
                                                                                                currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal.totalFare.currencyCode,
                                                                                            }).format(parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal.totalFare.amount))}
                                                                                        </span></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div style={{ display: "block" }} id="divCreditCard">
                                                                                <div className="row"  >
                                                                                    <div className="col-lg-6">
                                                                                        <div className='control'>
                                                                                            <div className="form-group">
                                                                                                <label className='h6 text-primary mb-2' htmlFor='cardType'> Card Type<span className="error-icon"> *</span></label>
                                                                                                <Dropdown
                                                                                                    placeholder="Please Select"
                                                                                                    onChange={cardTypeChangeHandler}
                                                                                                    options={creditCardOptions}
                                                                                                    //value={cardType!==""?card_list.filter(option => option.value === cardType):''}

                                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                                    arrowOpen={<span className="arrow-open" />}
                                                                                                    controlClassName='form-control control form-select bg_input mb-0'
                                                                                                />
                                                                                                {cardTypeInputHasError &&
                                                                                                    <p className="error-text">{t('validationCardType')}</p>
                                                                                                }

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className='control'>
                                                                                            <div className="form-group">
                                                                                                <label className='h6 text-primary mb-2' htmlFor='Card Number'> Card Number<span className="error-icon"> *</span></label>

                                                                                                <input maxLength={16} className="form-control bg_input" type='password' autoComplete='new-password' required
                                                                                                    id='cardNumber'
                                                                                                    ref={cardNumberInputRef}
                                                                                                    onBlur={onBlurCheck}
                                                                                                />
                                                                                                {cardNumberInputHasError &&
                                                                                                    <p className="error-text">{t('validationCardNumber')}</p>
                                                                                                }
                                                                                                {cardNumberInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationCardNumberInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className='control'>
                                                                                            <div className="form-group">
                                                                                                <label className='h6 text-primary mb-2' htmlFor='cardHolderName'>Cardholder Name<span className="error-icon"> *</span></label>


                                                                                                <input maxLength={30} className="form-control bg_input" type='text' required
                                                                                                    id='cardHolderName'
                                                                                                    ref={cardHolderNameInputRef}
                                                                                                    onBlur={onBlurCheck}
                                                                                                />
                                                                                                {cardHolderNameInputHasError &&
                                                                                                    <p className="error-text">{t('validationCardHolderName')}</p>
                                                                                                }
                                                                                                {cardHolderNameInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationCardHolderNameInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className='control'>
                                                                                            <div className="form-group">
                                                                                                <label className='h6 text-primary mb-2' htmlFor='dateOfExpiry'> Date of Expiry<span className="error-icon"> *</span></label>
                                                                                                <div className='row'>

                                                                                                    <div className='col-md-6 '>
                                                                                                        <Dropdown
                                                                                                            placeholder="Month"
                                                                                                            onChange={expireMonthChangeHandler}
                                                                                                            options={monthList}
                                                                                                            //value={expiryMonth!==''?monthList.filter(option => option.value === expiryMonth):''}

                                                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                                                            arrowOpen={<span className="arrow-open" />}
                                                                                                            controlClassName='form-control control form-select bg_input mb-0'
                                                                                                        />
                                                                                                        {expiryMonthInputHasError &&
                                                                                                            <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='col-md-6 margin-top-15'>
                                                                                                        <Dropdown
                                                                                                            placeholder="Year"
                                                                                                            onChange={expireYearChangeHandler}
                                                                                                            options={expiryYearList}
                                                                                                            //value={expiryYearList.filter(option => option.value === expiryYear)}

                                                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                                                            arrowOpen={<span className="arrow-open" />}
                                                                                                            controlClassName='form-control control form-select bg_input mb-0'
                                                                                                        />
                                                                                                        {expiryYearInputHasError &&
                                                                                                            <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                        }
                                                                                                    </div>

                                                                                                </div>

                                                                                                {dateofExpiryInputHasError &&
                                                                                                    <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                }
                                                                                                {dateofExpiryInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationDateofExpiryInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className='control input-placeholder'>
                                                                                        <label htmlFor='address' className='h6 text-primary mb-2'>Street Address<span className="error-icon"> *</span></label>
                                                                                        <textarea id='address'
                                                                                            value={address}
                                                                                            className="form-control form-control-sm"
                                                                                            onChange={(e) => setAddress(e.target.value)}
                                                                                            rows={2} cols={100} maxLength={300}
                                                                                            onBlur={onBlurCheck}
                                                                                            ref={addressInputRef}
                                                                                        />
                                                                                    </div>
                                                                                    {addressInputHasError &&
                                                                                        <span className="error-text col-lg-12 mt-3">{t('validationAddress')}</span>
                                                                                    }
                                                                                    {addressInvalidInputError &&
                                                                                        <span className="error-text col-lg-12 mt-3">{t('validationAddressInvalidData')}</span>
                                                                                    }
                                                                                </div>
                                                                                <div className='row mt-4'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='control'>
                                                                                            <label id='lblCountry ' className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}<span className="error-icon"> *</span></label>
                                                                                            <CountryDropDownList countryCodeValue={countryCodeValue}
                                                                                                countryNameText={countryNameText}
                                                                                                exposedControlType={exposedControlType}
                                                                                                currentInputValue={countryCode} />
                                                                                            {countryCodeInputHasError &&
                                                                                                <p className="error-text">{t('validationCountryCode')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>{t('state')}<span className="error-icon"> *</span></label>
                                                                                            <StateDropDownList stateCodeValue={stateCodeValue}
                                                                                                stateNameText={stateNameText}
                                                                                                currentInputValue={stateCode}
                                                                                                currentInputText={stateName}
                                                                                                exposedControlType={exposedControlType}
                                                                                                countryCode={countryCode} />
                                                                                            {stateCodeInputHasError &&
                                                                                                <p className="error-text">{t('validationStateCode')}</p>
                                                                                            }
                                                                                            {stateNameInputHasError &&
                                                                                                <p className="error-text">{t('validationStateName')}</p>
                                                                                            }
                                                                                            {stateNameInvalidInputError &&
                                                                                                <p className="error-text">{t('validationStateNameInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*Task 4949: Change city dropdown and textbox on flight, single PNR and hotel booking.*/}
                                                                                    <div className='col-md-6'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}<span className="error-icon"> *</span></label>
                                                                                            <input maxLength={100} className="form-control form-control-sm" type='text'
                                                                                                id='cityName'
                                                                                                ref={cityNameInputRef}
                                                                                                onBlur={onBlurCheck}

                                                                                            />
                                                                                            {cityNameInputHasError &&
                                                                                                <p className="error-text">{t('validationCityName')}</p>
                                                                                            }
                                                                                            {cityNameInvalidInputError &&
                                                                                                <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6 col-sm-12'>
                                                                                        <div className='control input-placeholder'>
                                                                                            <label htmlFor='postalCode' className='h6 text-primary mb-2'>{t('postalCode')}<span className="error-icon"> *</span></label>
                                                                                            <input maxLength={12} className="form-control form-control-sm" type='text' required
                                                                                                id='postalCode'
                                                                                                ref={postalCodeInputRef}
                                                                                                onChange={onBlurCheck}
                                                                                            />
                                                                                            {postalCodeInputHasError &&
                                                                                                <p className="error-text">{t('validationPostalCode')}</p>
                                                                                            }
                                                                                            {postalCodeInvalidInputError &&
                                                                                                <p className="error-text">{t('validationPostalCodeInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: "block" }} id="divWallet" className='mt-4'>
                                                                                <div className="col-lg-12">
                                                                                    <div className='h6 text-primary ' ><i className="fa-solid fa-circle text-12px"></i> Current Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                        minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                        style: 'currency',
                                                                                        currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                    }).format(currentWalletAmount !== undefined && currentWalletAmount !== null && currentWalletAmount !== '' ? currentWalletAmount : '0')}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <div className='h6 text-primary mb-2' > <i className="fa-solid fa-circle text-12px"></i> Total Price: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                        minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                        style: 'currency',
                                                                                        currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                    }).format(calculateRoeAmountMain(fareCurrecy, bookingJSON, false, false))}</span></div>
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <div className='h6 text-primary mb-2' ><i className="fa-solid fa-circle text-12px"></i>  Remaining Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                        minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                        style: 'currency',
                                                                                        currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                    }).format(parseFloat(RemainingWalletAmount))}</span></div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: "block" }} id="divWalletEror" className='mt-4'>
                                                                                <div className="col-lg-12">
                                                                                    <p className="error-text">{t('inSufficientWalletBalance')}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-lg-5 col-md-8 col-sm-8 my-auto">

                                                                                    <p id='requiredIndicatorPaymentSection'><span className="error-icon">*</span><span>Indicates required fields.</span></p>
                                                                                </div>
                                                                                <div className="row my-2">
                                                                                    {
                                                                                        sourceId !== 'TJ' &&
                                                                                        <div id="onHoldBookingNote" className='text-primary h6'>
                                                                                            <span className="h6 text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span>
                                                                                            <span className="ms-1">On Hold bookings will be auto-canceled in {getConfigurationByValue("TICKET_TIME_LIMIT") - 1} hours. Please contact us to pay and issue tickets.</span>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal !== null &&
                                                                                    <>
                                                                                        <div className='d-inline-flex text-black'>
                                                                                            <div className="margin-sm-10" style={{ width: "3%" }}>
                                                                                                <input className="form-check-input currencyconfirm-payment-option" type="checkbox" id="chkCurrencyConfirm" onChange={(e) => onCurrencyConfirmChangeHandler(e)}></input>

                                                                                            </div>
                                                                                            <div style={{ width: "97%" }}>
                                                                                                <span>I agree that this flight will be booked in {bookingJSON.data.airItinerary[0].airItineraryPricingInfoOrignal.totalFare.currencyCode}.</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='error-text mt-2' id='spnCurrencyConfirmMessage'></div>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee.amount !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee.amount !== '' && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee.amount !== '0' &&

                                                                                    <>
                                                                                        <div className='d-inline-flex' id='dvCcFee' >
                                                                                            <div class="text-black margin-sm-10" style={{ width: "3%" }}>
                                                                                                <input className="form-check-input ccfee-payment-option" type="checkbox" id="chkCcFeePayment" onChange={(e) => onCcFeePaymentChangeHandler(e)}></input>
                                                                                            </div>
                                                                                            <div style={{ width: "97%" }}>
                                                                                                <span>I agree that {bookingJSON.data.airItinerary[0].ValidatingCarrierName} may charge up to <b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee.currencyCode }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFee.amount)}</b> for payments made using a credit card.</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='error-text mt-2 col-md-12' id='spnCcFeeMessage'></div>

                                                                                    </>
                                                                                }

                                                                                <div className='d-inline-flex'>
                                                                                    <div class="text-black margin-sm-10" style={{ width: "3%" }}>
                                                                                        <input className="form-check-input tandc-payment-option" type="checkbox" id="chkTandcPayment" onChange={(e) => onTandcPaymentChangeHandler(e)}></input>
                                                                                    </div>
                                                                                    <div style={{ width: "97%" }}>
                                                                                        <span>I have read and agree to the fare rules and the</span>
                                                                                        <span><a className="footer_link_area cursor-pointer text-sky-blue" onClick={openTermAndCondtion}> terms and conditions.</a></span>
                                                                                    </div>
                                                                                </div>
                                                                                <span className='error-text mt-2' id='spnTandCPaymentMessage'></span>

                                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                    <div className="float-end text-end">
                                                                                        <br></br>
                                                                                        {checkSecurityDetails("Book", "Flight", "Book_Flight_Book") && getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING_ITINERARY") && isWalletRadiobuttonChecked === 'false' ? <button type="button" className="btn margin-top-15 text-white gt-main-btn ms-1 btn-sm" id='btnSendRequest' onClick={SendRequest} >
                                                                                            Send Request
                                                                                        </button> : <span id='btnSendRequest'></span>}
                                                                                        {checkSecurityDetails("Book", "Flight", "Book_Flight_Book") && getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING") && isWalletRadiobuttonChecked === 'false' && isHoldAllowed ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15 " id='btnOnHold' onClick={OnHold}>
                                                                                            On Hold
                                                                                        </button> : <span id='btnOnHold'></span>}

                                                                                        {checkSecurityDetails("Book", "Flight", "Book_Flight_Book") && getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING_TICKETING") && allowTicketing && isWalletRadiobuttonChecked === 'false' ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15 " id='btnIssueTicket' onClick={IssueTicket}>
                                                                                            Issue Ticket
                                                                                        </button> : <span id='btnIssueTicket'></span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

    </Fragment>
};

export default FlightBook;


import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addUserFriendly, getSingleUserFriendly, updateUserFriendly } from '../../../services/UserFriendly-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import UserFriendlyForm from './Components/UserFriendlyForm';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const UpdateUserFriendly = (props) => {
    useDocumentTitle('Message')
    const { t } = useTranslation(['translation.UserFriendly']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addUserFriendly
    } else {
        varHttp = updateUserFriendly
    }
    const [confirm, setConfirm] = useState();
    const { sendRequest: sendRequest1, data: loadedCreditLimitDetails, error } = useHttpGet(getSingleUserFriendly);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();

    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (!checkSecurityDetails("Master", "Message", "Master_Message_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "Message", "Master_Message_View") && Action === "0")
        history.push('/Unauthorized')
    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        })
    }, []);


    useEffect(() => {
        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id,])
    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote && loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecord')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }

    let loadedData = [];
    for (let index in loadedCreditLimitDetails) {
        if (loadedCreditLimitDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedCreditLimitDetails[index];
            }
        }
    }
    const addCreditLimitHandler = (requestData) => {
        sendRequest(requestData);
    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/SearchUserFriendly');
        }
    };



    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }


    const CreditLimitFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <UserFriendlyForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddCreditLimit={addCreditLimitHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}


        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {CreditLimitFormContent}
    </Fragment>
};
export default UpdateUserFriendly

import { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Wrapper from '../../../../components/Common/Helpers/Wrapper'
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import useHttp from '../../../../services/use-http'
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { searchCommonList } from '../../../../services/Office-API'
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction'
const CorporateMasterForm = (props) => {
    const { t } = useTranslation(['translation.CorporateMaster']);
    const parms = useParams();
    const { KeyCode } = parms;
    const nameInputRef = useRef('');
    const codeInputRef = useRef('');
    const activeInputRef = useRef('');
    const [nameInputHasError, setNameInputHasError] = useState(false);
    const [nameInputInvalidHasError, setNameInvalidInputHasError] = useState(false);
    const [codeInputInvalidHasError, setCodeInvalidInputHasError] = useState(false);
    const [iSDisabled, setiSDisabledDDL] = useState(false);
    const [active, setActive] = useState(true);
    const [activeUserAction, setActiveUserAction] = useState(false);
    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        if (props.loadedCorproateMaster.length !== 0) {
            nameInputRef.current.value = props.loadedCorproateMaster.name;
            codeInputRef.current.value = props.loadedCorproateMaster.code;
            setActive(props.loadedCorproateMaster.isActive);
            setiSDisabledDDL(false);
        }
        if (isEditMode === 0) {
            document.getElementById('pageTitle').innerHTML = ' View ' + getConfigurationByValue(KeyCode);

            document.getElementById("name").readOnly = true;
            document.getElementById("code").readOnly = true;
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.querySelector("#active").disabled = true;
            setiSDisabledDDL(true);
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
        }
        else if (isEditMode === 1) {
            document.getElementById('pageTitle').innerHTML = ' Edit ' + getConfigurationByValue(KeyCode);;

            document.getElementById("name").readOnly = false;
            document.getElementById("code").readOnly = false;
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            setiSDisabledDDL(false);
            document.getElementsByClassName('error-icon')[2].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
        }
        else if (props.loadedCorproateMaster.length === 0) {

            document.getElementById("name").readOnly = false;
            document.getElementById("code").readOnly = false;
            document.getElementById("btnNew").style.display = "none";
            document.getElementById('pageTitle').innerHTML = ' New ' + getConfigurationByValue(KeyCode);;
            sessionStorage.setItem('isEditable', '100');
            setiSDisabledDDL(false);
        }

    }, [props.loadedCorproateMaster.length,
    props.loadedCorproateMaster.name
    ]);

    const activeChangeHandler = () => {
        setActiveUserAction(true);
        if (document.querySelector('#active').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }

    function onBlurCheck(event) {
        event.preventDefault();

        if (nameInputRef.current.value.trim() === '') {
            nameInputRef.current.focus();
            setNameInputHasError(true);
            setNameInvalidInputHasError(false);
            return;
        }
        else {
            setNameInvalidInputHasError(false);
        }
        if (!nameInputRef.current.value.match('^[a-zA-Z0-9,-. \(\)]+$')) {
            setNameInvalidInputHasError(true);
            setNameInputHasError(false);
            return;
        }
        else {
            setNameInvalidInputHasError(false);
        }
        if (codeInputRef.current.value.trim() !== '' && !codeInputRef.current.value.match('^[a-zA-Z0-9.]+$')) {
            setCodeInvalidInputHasError(true);
            return;
        }
        else {
            setCodeInvalidInputHasError(false);
        }

    }
    function submitFormHandler(event) {
        event.preventDefault();
        const enterName = nameInputRef.current.value;
        const enterCode = codeInputRef.current.value;
        if (nameInputRef.current.value.trim() === '') {
            nameInputRef.current.focus();
            setNameInputHasError(true);
            setNameInvalidInputHasError(false);
            return;
        }
        else {
            setNameInvalidInputHasError(false);
        }
        if (!nameInputRef.current.value.match('^[a-zA-Z0-9,-. \(\)]+$')) {
            setNameInvalidInputHasError(true);
            setNameInputHasError(false);
            return;
        }
        else {
            setNameInvalidInputHasError(false);
        }
        if (codeInputRef.current.value.trim() !== '' && !codeInputRef.current.value.match('^[a-zA-Z0-9.]+$')) {
            setCodeInvalidInputHasError(true);
            return;
        }
        else {
            setCodeInvalidInputHasError(false);
        }
        let ID;

        if (props.loadedCorproateMaster === null || props.loadedCorproateMaster.length === 0) {
            ID = "";
        }
        else {
            ID = props.loadedCorproateMaster.id;
        }

        props.onAddCorporateMaster({
            id: ID,
            name: enterName.trim(),
            code: enterCode.trim(),
            keyCode: KeyCode,
            isActive: active,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            iPAddress: localStorage.getItem("IPAddress")
        });

    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push(`/UpdateCorporateMaster/${KeyCode}`)
            history.go()
        }
        else if (event.target.id === 'btnReset') {
            if (props.loadedCorproateMaster.length === 0) {
                history.go(`/UpdateCorporateMaster/${KeyCode}`)
            }
            else {
                history.go(`/UpdateCorporateMaster/${KeyCode}/${props.loadedCorproateMaster.id}`)
            }
        }
    }
    const history = useHistory();
    return (
        <Wrapper>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>

                                                        <form className='form'>
                                                            <div className='row'>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='error'>Name<span className="error-icon"> *</span></label>
                                                                        <input maxLength="100" type='text' className="form-control form-control-sm" id='name' ref={nameInputRef}
                                                                            onChange={onBlurCheck} />

                                                                        {nameInputHasError &&
                                                                            <p className="error-text">{t('validationName')}</p>
                                                                        }
                                                                        {nameInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidName')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12 col-sm-12'>
                                                                    <div className='control'>
                                                                        <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Code'>Code<span className="error-icon"></span></label>
                                                                        <input maxLength="3" type='text' className="form-control form-control-sm" id='code' ref={codeInputRef}
                                                                            onBlur={onBlurCheck} />

                                                                        {codeInputInvalidHasError &&
                                                                            <p className="error-text">{t('validationValidCode')}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className='control'>
                                                                    <div className="form-switch form-switch-sm padding-left-0">
                                                                        <label htmlFor="active" className="mb-2 h6 text-primary">   {t('active')}</label>
                                                                        <input id="active" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} value={active} ref={activeInputRef} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href={"/SearchCorporateMaster/" + KeyCode} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <a href={"/SearchCorporateMaster/" + KeyCode} className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                        {(checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Insert") || checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Update")) ?
                                                                            <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                                            : <span id="btnSave"></span>}
                                                                        {checkSecurityDetails("Master", KeyCode, "Master_" + KeyCode + "_Insert") ?
                                                                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                                {t('new')}
                                                                            </button>
                                                                            : <span id="btnNew"></span>}
                                                                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3 col-md-12 actions'>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default CorporateMasterForm

import { Fragment } from 'react';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import { useLocation } from 'react-router-dom';

const RateDetails = (props) => {
    const location = useLocation();
    let hidePlusIcon = props.show === 'show' ? "tour_details_boxed_inner fare-summary-pop plus-icon-hide ms-0" : "tour_details_boxed_inner fare-summary-pop";
    let hidems4 = props.show === 'show' ? "col-md-7 h6 my-auto " : "ms-4 col-md-7 h6 my-auto "
    let totalTax = 0;

    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let equivalentAmountValue = calculateRoeAmountAdditional(props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode, props.hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered, false);

    props.hotelBookingJSON.data.hotelResults[0].roomRateDetails.forEach(item => {
        if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
            totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
        }
        else {
            totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
        }
    });

    if (location.pathname === '/RetrieveTrip' || location.pathname === 'RetrieveTrip' || location.pathname === '/TripDetails' || location.pathname === 'TripDetails') {
        equivalentAmountValue = props.hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.equivalentPriceOffered !== undefined ? props.hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.equivalentPriceOffered : 0;
    }

    return <Fragment>
        <div className="accordion-item pb-0">
            {props.hidePrice === undefined && <h5 className="accordion-header text-sky-blue fw-bold mb-2" id="headingOne">
                Price
            </h5>}
            <div className="mt-0">
                {props.hidePrice !== undefined && <p className="card-text mb-0 mt-1 text-primary"><strong><i className="fas fa-hotel"></i> Hotel </strong></p>}
                <label className="form-check-label text-14px " for="flexCheckDefaultf1">
                    <span className="area_flex_one text-muted">
                        <span className="fw-bold h6">{props.hotelBookingJSON.data.hotelResults[0].roomRateDetails.length} X {props.hotelBookingJSON.data.hotelResults[0].roomRateDetails[0].roomTypeName !== undefined ? props.hotelBookingJSON.data.hotelResults[0].roomRateDetails[0].roomTypeName : 'Room'}</span>

                    </span>
                </label>

                <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-8 h6'>Base Rate</div>
                    <div className='col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
                        }).format(props.hotelBookingJSON.data.hotelResults[0].roomRateDetails.length * props.hotelBookingJSON.data.hotelResults[0].roomRateDetails[0].price.publishedPrice.pricePublished)}</div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-8 h6'>Taxes & Fees</div>
                    <div className='col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
                        }).format(totalTax)}
                    </div>
                </div>



                {props.hotelBookingJSON.data.hotelResults[0].price[0].agentMarkUp !== undefined && props.hotelBookingJSON.data.hotelResults[0].price[0].agentMarkUp !== '' && props.hotelBookingJSON.data.hotelResults[0].price[0].agentMarkUp !== 0 && <div className='row mt-2'>
                    <div className={hidePlusIcon}>
                        <div className="accordion" id="accordionExample">

                            <div className="tour_details_boxed_inner fare-summary">
                                <div className="accordion" id="accordionExampleRateDetails">
                                    <div className="accordion_flex_area ">
                                        <div className="accordion-item fare-toggle-btn">
                                            <h2 className="accordion-header" id="headingthreee">
                                                <button className="accordion-button collapsed text-16px  " type="button" data-bs-toggle="collapse" data-bs-target="#collapselast" aria-expanded="false" aria-controls="collapselast">
                                                    <div className={hidems4}> Others  </div>
                                                    <div className='right-0 position-absolute h6'>
                                                        {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
                                                        }).format(props.hotelBookingJSON.data.hotelResults[0].price[0].agentMarkUp)}
                                                    </div>
                                                </button>

                                            </h2>
                                            <div id="collapselast" class={"accordion-collapse collapse" + props.show}
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExampleRateDetails">
                                                <div className="accordion-body">
                                                    <div className="accordion_itinerary_list">
                                                        <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <span className="area_flex_one ">
                                                                <span>Service Fee</span>
                                                                <span className='h6'>    {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
                                                                }).format(props.hotelBookingJSON.data.hotelResults[0].price[0].agentMarkUp)}</span>
                                                            </span>}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <hr className='my-2'></hr>
                <div className="row"><div className="col-lg-3 col-md-3 col-sm-3 col-3"><div className="h5 mb-1 fw-bold text-primary">Total Rate </div></div><div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode
                    }).format(props.hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered)}</div></div></div>

                {
                    props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode !== undefined && props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode !== '' && props.hotelBookingJSON.data.hotelResults[0].price[0].currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                    <div className="row"><div className="col-lg-3 col-md-3 col-sm-3 col-3"><div className="h5 mb-1 fw-bold text-primary">Eq. Total Rate </div></div><div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                        {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                            style: 'currency',
                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                        }).format(parseFloat(equivalentAmountValue))}</div></div></div>
                }
            </div>

        </div>
    </Fragment>

};

export default RateDetails;
import { useState, useRef, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import LoadingSpinnerWithCard from '../../../../components/Common/Loading/LoadingSpinnerWithCard';
import { useTranslation } from 'react-i18next';
import RegionCheckBoxTree from '../../../../components/Common/CheckBoxTree/RegionCheckBoxTree';
import Dropdown from 'react-select';
import { searchCommonList } from '../../../../services/Office-API';
import useHttp from '../../../../services/use-http';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import { getAllcontinent } from '../../../../services/Continent-API';
import useHttpGet from '../../../../services/use-http';
import CityDropDownList from '../../../../components/Common/DropDownList/CityDropDownList';
import { addRegion, getSingleRegion, removeRegionApi } from '../../../../services/Region-API';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";

const RegionForm = (props) => {
  const { t } = useTranslation(['translation.Region']);
  /*  */
  const regionInputRef = useRef('');

  const [regionInputHasError, setRegionInputHasError] = useState(false);
  const [serviceCodeInputHasError, setServiceCodeInputHasError] = useState(false);
  const [applicableToCodeInputHasError, setApplicableToCodeInputHasError] = useState(false);
  const [continentCodeInputHasError, setContinentCodeInputHasError] = useState(false);
  const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
  const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
  const [regionInvalidInputError, setRegionInvalidInputError] = useState(false);

  const [serviceCode, setServiceCode] = useState('');
  const [isServiceDisabled, setIsServiceDisabledDDL] = useState('false');
  const [applicableToCode, setApplicableToCode] = useState([]);
  const [isDisabled, setIsDisabledDDL] = useState('false');
  const [continentCode, setContinentCode] = useState('');
  const [continentName, setContinentName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryName, setCountryName] = useState('');
  const [cityCode, setCityCode] = useState('');
  const [includeRegionJson, setIncludeRegionJson] = useState([]);
  const [regionJson, setRegionJson] = useState([]);
  const parms = useParams();
  const [error1, setError] = useState();
  const [regionSavedId, setRegionSavedId] = useState('');
  const [confirm, setConfirm] = useState();

  const history = useHistory();
  let selectedRegions = [];
  let editModefocus = false;

  const { sendRequest: sendRequestCommonListService, data: loadedService } = useHttp(searchCommonList);
  const { sendRequest: sendRequestCommonListContinent, data: loadedContinent } = useHttpGet(getAllcontinent);

  const { Id } = parms;
  let varHttp;

  if (Id === undefined) {
    varHttp = addRegion
  } else {
    varHttp = addRegion
  }

  const { sendRequest, status, data: loadedRegion } = useHttp(varHttp);
  const { sendRequest: sendRequest1, data: loadedRegionDetails, status: status1 } = useHttpGet(getSingleRegion);
  const { sendRequest: sendRequestRemove, status: statusRemove, data: loadedRegionRemove } = useHttp(removeRegionApi);

  if (regionJson.length === 0 && Object.keys(props.loadedProfileDetailsJson).length > 0) {
    sessionStorage.setItem('regionsJson', JSON.stringify([props.loadedProfileDetailsJson][0].data.includeExcludeRegion.continentlst));
    editModefocus = true;
  }

  let serviceValue = '';

  useEffect(() => {
    let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });

    if (props.loadedProfileDetails.length !== 0) {
      regionInputRef.current.value = `${regionInputRef.current.value === '' ? props.loadedProfileDetails.regionName : regionInputRef.current.value}`;

      if (serviceCode === '') {
        setServiceCode(props.loadedProfileDetails.serviceID);
      }

      if (applicableToCode.length === 0) {
        let applicableTo = [];
        props.loadedProfileDetails.applicableTo.forEach(element => {
          applicableTo.push({
            "value": element.applicableID,
            "label": applicableToOptions.filter(function (option) {
              return option.value === element.applicableID;
            }).map(function (applicableToOptions) { return applicableToOptions.label })
          });
        });
        setApplicableToCode(applicableTo);
      }

      // if (regionJson.length === 0) {
      //   setRegionJson([props.loadedProfileDetailsJson]);
      // }
    }

    if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
      isEditMode = parseInt(sessionStorage.getItem('isEditable'));
    }

    setIsServiceDisabledDDL('false');
    setIsDisabledDDL('false');

    if (isEditMode === 0) {
      document.getElementById('pageTitle').innerHTML = ' View Region';
      document.getElementById("region").readOnly = true;
      document.getElementById("btnNew").style.display = "none";
      document.getElementById("btnReset").style.display = "none";
      document.getElementById("btnAdd").style.display = "none";
      document.getElementById("btnRemove").style.display = "none";
      [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
        el.style.visibility = 'hidden';
      });

      document.getElementById("requiredIndicator").style.display = "none";
      setIsServiceDisabledDDL('true');
      setIsDisabledDDL('true');
    }
    else if (isEditMode === 1) {
      document.getElementById('pageTitle').innerHTML = ' Edit Region';
      document.getElementById("region").readOnly = true;
      document.getElementById("btnNew").style.display = "";
      document.getElementById("btnReset").style.display = "";
      document.getElementById("btnAdd").style.display = "";
      document.getElementById("btnRemove").style.display = "";
      document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
      document.getElementById("requiredIndicator").style.display = "";
      setIsServiceDisabledDDL('true');
      setIsDisabledDDL('false');
    }

    if (props.loadedProfileDetails.length === 0) {
      document.getElementById("btnNew").style.display = "none";
      document.getElementById('pageTitle').innerHTML = ' New Region';

    }

    const onSearchCommonListService = ({
      methodName: "Service",
      methodCode: "SER",
      listID: "",
      listValue: "",
      subListID: ""
    });

    sendRequestCommonListService(onSearchCommonListService);

    const onSearchCommonListContinent = ({
      methodName: "Continent",
      methodCode: "CON",
      listID: "",
      listValue: "",
      subListID: ""
    });

    sendRequestCommonListContinent(onSearchCommonListContinent);

    if (Object.keys(regionJson).length > 0) {
      document.querySelector('#dvRegionTreeBlankMessage').style.display = "none";
      if (isEditMode !== 0) {
        document.querySelector('#btnRemove').style.display = "";
      }
    }
    else if (Object.keys(props.loadedProfileDetailsJson).length > 0) {
      if (props.loadedProfileDetailsJson.data.includeExcludeRegion.continentlst.length > 0) {
        document.querySelector('#dvRegionTreeBlankMessage').style.display = "none";
        if (isEditMode !== 0) {
          document.querySelector('#btnRemove').style.display = "";
        }
      }
      else {
        document.querySelector('#dvRegionTreeBlankMessage').style.display = "";
        document.querySelector('#btnRemove').style.display = "none";
      }
    }
    else {
      document.querySelector('#dvRegionTreeBlankMessage').style.display = "";
      document.querySelector('#btnRemove').style.display = "none";
    }

    document.querySelector('#rdIncludeRegion').checked = true;

  }, [props.loadedProfileDetails.length,
  props.loadedProfileDetails.regionName,
    sendRequestCommonListService,
    sendRequestCommonListContinent, serviceCode,
  //applicableToCode.length,
  props.loadedProfileDetails.applicableTo,
  props.loadedProfileDetails.serviceID]);

  useEffect(() => {
    if (Id === undefined || Id !== undefined) {
      if (status === 'completed') {
        let loadedDataSave = [];
        for (let index in loadedRegion) {
          if (index === 'data') {
            loadedDataSave = loadedRegion[index];
          }
        }

        if (loadedRegion['errors'].status === 'TRUE') {
          if (loadedRegion['errors'].error['code'] === '1009') {
            if (document.querySelector('#rdIncludeRegion').checked) {
              setError({
                title: "Message",
                message: `${t('errorDuplicateRecordSaveIncluded')}`,
              });
            } else if (document.querySelector('#rdExcludeRegion').checked) {
              setError({
                title: "Message",
                message: `${t('errorDuplicateRecordSaveExcluded')}`,
              });
            }
          }
          else if (loadedRegion['errors'].error['code'] === '1018') {

            // let Continent = loadedDataSave.regionDetails.continent;
            // let Country = loadedDataSave.regionDetails.country;
            // let City = loadedDataSave.regionDetails.city;
            // let Region = loadedDataSave.regionDetails.region;

            let Continent = loadedRegion['errors'].error['description'].split(',')[0];
            let Country = loadedRegion['errors'].error['description'].split(',')[1];
            let City = loadedRegion['errors'].error['description'].split(',')[2];
            let Region = loadedRegion['errors'].error['description'].split(',')[3];

            let messageText = t('errorDuplicateRecordSaveRegion');

            messageText = messageText.replace('<<ContinentName>>', Continent);
            messageText = messageText.replace('<<CountryName>>', Country);
            messageText = messageText.replace('<<CityName>>', City);
            messageText = messageText.replace('<<RegionName>>', Region);

            if (Country === '') {
              messageText = messageText.replace(',', '');
            }
            if (City === '') {
              messageText = messageText.replace('and', '');
            }

            setError({
              title: "Message",
              message: messageText,
            });
          }
          else {
            setError({
              title: "Message",
              message: `${t('errorRecordSave')}`,
            });
          }
        }
        else {
          //get hit  
          sessionStorage.removeItem('regionsJson');
          setRegionSavedId(loadedDataSave.id);
          sendRequest1(loadedDataSave.id)

          setError({
            title: "Message",
            message: `${t('recordSaveUpdateMessage')}`,
          });
        }
      }
    }
  }, [status, Id, t, loadedRegion,
    sendRequest1]);

  useEffect(() => {
    if (statusRemove === 'completed') {
      if (loadedRegionRemove['errors'].status === 'TRUE') {
        if (loadedRegionRemove['errors'].error['code'] === '1009') {
          setError({
            title: "Message",
            message: `${t('errorDuplicateRecordSave')}`,
          });
        }
        else {
          setError({
            title: "Message",
            message: `${t('errorRecordSave')}`,
          });
        }
      }
      else {
        //get hit          
        let regionRemoveId = props.loadedProfileDetails.id === undefined || props.loadedProfileDetails.id === '' ? regionSavedId : props.loadedProfileDetails.id;
        sendRequest1(regionRemoveId)

        setError({
          title: "Message",
          message: `${t('recordDeletedMessage')}`,
        });
      }
    }
  }, [t, statusRemove, loadedRegionRemove, props.loadedProfileDetails.id
    , regionSavedId, sendRequest1]);

  useEffect(() => {

    if ((!loadedRegionDetails || loadedRegionDetails.length === 0)) {
      <Fragment>
        <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
      </Fragment>
    }

    if (Id === undefined || Id !== undefined) {
      if (status1 === 'completed') {
        //bind api result

        // let loadedData = [];
        // for (let index in loadedRegionDetails) {
        //   if (loadedRegionDetails['errors'].status === 'FALSE') {
        //     if (index === 'data') {
        //       loadedData = loadedRegionDetails[index];
        //     }
        //   }
        // }

        if (Object.keys(loadedRegionDetails).length > 0) {
          if (loadedRegionDetails.data.includeExcludeRegion.continentlst.length > 0) {
            document.querySelector('#dvRegionTreeBlankMessage').style.display = "none";
            document.querySelector('#btnRemove').style.display = "";
          }
          else {
            document.querySelector('#dvRegionTreeBlankMessage').style.display = "";
            document.querySelector('#btnRemove').style.display = "none";
          }
        }

        sessionStorage.setItem('regionsJson', JSON.stringify([loadedRegionDetails][0].data.includeExcludeRegion.continentlst))
        setRegionJson([loadedRegionDetails])
      }
    }
  }, [status1, loadedRegionDetails, Id]);


  function onBlurCheck() {
    if (serviceCode === '') {
      setServiceCodeInputHasError(true);
      return;
    }
    else {
      setServiceCodeInputHasError(false);
    }

    if (regionInputRef.current.value.trim() === '') {
      setRegionInputHasError(true);
      setRegionInvalidInputError(false);
      return;
    }
    else {
      setRegionInputHasError(false);
    }

    if (!regionInputRef.current.value.match('^[a-zA-Z0-9- ]+$')) {
      setRegionInvalidInputError(true);
      setRegionInputHasError(false);
      return;
    }
    else {
      setRegionInvalidInputError(false);
    }

    // if (applicableToCode.length === 0) {
    //   setApplicableToCodeInputHasError(true);
    //   return;
    // }
    // else {
    //   setApplicableToCodeInputHasError(false);
    // }

    if (continentCode === '') {
      setContinentCodeInputHasError(true);
      return;
    }
    else {
      setContinentCodeInputHasError(false);
    }
  }

  function changeLocation(event) {
    if (event.target.id === 'btnNew') {
      history.push('/UpdateRegion')
      history.go()
    }

    if (event.target.id === 'btnReset') {
      if (props.loadedProfileDetails.length === 0) {
        history.go('/UpdateRegion')
      }
      else {
        history.go(`/UpdateRegion/${props.loadedProfileDetails.id}`)
      }
    }
  }

  const fetchSelectedItem = (value) => {
    if (value.length > 0) {
      selectedRegions = value;
      sessionStorage.setItem("selectedRegionsTemp", JSON.stringify(selectedRegions));
    }
  }

  if (sessionStorage.getItem("selectedRegionsTemp") !== null) {
    selectedRegions = JSON.parse(sessionStorage.getItem("selectedRegionsTemp"));
  }

  let serviceOptions = []
  let serviceArrayData = [];
  for (let i in loadedService) {
    if (loadedService['errors'].status === 'FALSE') {
      if (i === 'data') {
        serviceArrayData = loadedService[i];
      }
    }
  }

  serviceValue = serviceArrayData.filter(function (value, index, arr) {
    return value.listValue === String('Flight')
  }).map(filteredService => filteredService.listID)[0];

  for (let index = 0; index < serviceArrayData.length; index++) {
    serviceOptions = [...serviceOptions, { value: serviceArrayData[index].listID, label: serviceArrayData[index].listValue }]
  }

  let applicableToOptions = []
  let applicableToArrayData = [];
  for (let i in props.loadedApplicableTo) {
    if (props.loadedApplicableTo['errors'].status === 'FALSE') {
      if (i === 'data') {
        applicableToArrayData = props.loadedApplicableTo[i];
      }
    }
  }

  for (let index = 0; index < applicableToArrayData.length; index++) {
    applicableToOptions = [...applicableToOptions, { value: applicableToArrayData[index].listID, label: applicableToArrayData[index].listValue }]
  }

  let continentOptions = []
  let continentArrayData = [];

  for (let i in loadedContinent) {
    if (i === 'data') { }
    continentArrayData = loadedContinent;
  }

  for (let index = 0; index < continentArrayData.length; index++) {
    continentOptions = [...continentOptions, { value: continentArrayData[index].id, label: continentArrayData[index].name }]
  }

  const serviceChangeHandler = (event) => {
    setServiceCode(event.value);
    setServiceCodeInputHasError(false);
  };

  const applicableToChangeHandler = (event) => {
    setApplicableToCode(event);
    setApplicableToCodeInputHasError(false);
  };

  const continentChangeHandler = (event) => {
    setContinentCode(event.value);
    setContinentName(event.label);
    setContinentCodeInputHasError(false);
    setCountryCode('');
    setCityCode('');
  };

  const countryCodeValue = (country_Code) => {
    setCountryCode(country_Code);
    setCountryCodeInputHasError(false);
    setCityCode('');
  };
  const countryNameText = (country_Name) => {
    setCountryName(country_Name);
  };

  const cityCodeValue = (cityCode) => {
    setCityCode(cityCode);
    //setIsCountryChanged(false);
    setCityCodeInputHasError(false);
  };

  const cityNameText = (cityName) => {
    //setCityName(cityName);        
  };

  function includeRegion(event) {
    event.preventDefault();
    setCountryCodeInputHasError(false);

    if (serviceCode === '') {
      setServiceCodeInputHasError(true);
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
      return;
    }
    else {
      setServiceCodeInputHasError(false);
    }

    if (regionInputRef.current.value.trim() === '') {
      regionInputRef.current.focus();
      setRegionInputHasError(true);
      setRegionInvalidInputError(false);
      return;
    }
    else {
      setRegionInputHasError(false);
    }

    if (!regionInputRef.current.value.match('^[a-zA-Z0-9- ]+$')) {
      regionInputRef.current.focus();
      setRegionInvalidInputError(true);
      setRegionInputHasError(false);
      return;
    }
    else {
      setRegionInvalidInputError(false);
    }

    if (applicableToCode.length === 0) {
      // setApplicableToCodeInputHasError(true);
      // window.scrollTo({
      //   top: 100,
      //   behavior: "smooth",
      // });
      // return;
    }
    else {
      setApplicableToCodeInputHasError(false);
    }

    if (continentCode === '') {
      setContinentCodeInputHasError(true);
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
      return;
    }
    else {
      setContinentCodeInputHasError(false);
    }

    let includeRegionJsonTemp = [];
    let continentListTemp = [];
    let cityList = [];
    let arrCityList = [];

    let isContinentCodeMatch = false;
    let countryListPartial = [];
    let applicableToTemp = [];

    if (cityCode.length > 0) {
      cityCode.forEach(cityCode => {

        if (includeRegionJson !== undefined) {
          if (includeRegionJson.length > 0) {
            includeRegionJson[0].data[0].includeExcludeRegion[0].continentlst.forEach(element => {
              if (element.value === continentCode) {
              }
              element.children.forEach(element => {
                if (element.value === countryCode) {
                }
                element.children.forEach(element => {
                  arrCityList.push(element.value);
                });
              });
            });
          }
        }
        const found = arrCityList.find(element => element === cityCode.value);
        if (found === undefined) {
          cityList.push({
            "value": cityCode.value,
            "label": cityCode.label,
            "status": true
          })
        }
      });
    }
    else {
      if (includeRegionJson !== undefined) {
        if (includeRegionJson.length > 0) {
          includeRegionJson[0].data[0].includeExcludeRegion[0].continentlst.forEach(element => {
            if (element.value === continentCode) {
            }
            element.children.forEach(element => {
              if (element.value === countryCode) {
              }
              element.children.forEach(element => {
                arrCityList.push(element.value);
              });
            });
          });
        }
      }
    }

    if (isContinentCodeMatch === false) {
      if (countryCode !== '') {
        countryListPartial = {
          "value": countryCode,
          "label": countryName,
          "status": true,
          "allCity": cityList.length === 0 ? true : false,
          "children": cityList
        }
      }

      continentListTemp.push({
        "value": continentCode,
        "label": continentName,
        "status": true,
        "allCity": countryCode === '' ? true : false,
        "children": countryCode === '' ? countryListPartial : [countryListPartial]
      });
    }

    applicableToCode.forEach(element => {
      applicableToTemp.push({
        "applicableID": element.value
      });
    });

    //include    
    includeRegionJsonTemp = {
      "serviceID": serviceCode,
      "regionName": regionInputRef.current.value,
      "applicableTo": applicableToTemp,
      "includeExcludeRegion": {
        "continentlst": continentListTemp
      },
      "officeID": JSON.parse(localStorage.getItem('officeID')),
      "id": props.loadedProfileDetails.id === undefined || props.loadedProfileDetails.id === '' ? regionSavedId : props.loadedProfileDetails.id,
      "traceID": JSON.parse(localStorage.getItem('traceID')),
      "userID": JSON.parse(localStorage.getItem('userID'))
    }

    setIncludeRegionJson(includeRegionJsonTemp);

    setContinentCode('');
    setCountryCode('');
    setCityCode('');

    setIsServiceDisabledDDL('true');
    document.getElementById("region").readOnly = true;

    //save hit
    sendRequest(includeRegionJsonTemp);
  }

  function excludeRegion(event) {
    event.preventDefault();

    if (serviceCode === '') {
      setServiceCodeInputHasError(true);
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
      return;
    }
    else {
      setServiceCodeInputHasError(false);
    }

    if (regionInputRef.current.value.trim() === '') {
      regionInputRef.current.focus();
      setRegionInputHasError(true);
      setRegionInvalidInputError(false);
      return;
    }
    else {
      setRegionInputHasError(false);
    }

    if (!regionInputRef.current.value.match('^[a-zA-Z0-9- ]+$')) {
      regionInputRef.current.focus();
      setRegionInvalidInputError(true);
      setRegionInputHasError(false);
      return;
    }
    else {
      setRegionInvalidInputError(false);
    }

    if (applicableToCode.length === 0) {
      // setApplicableToCodeInputHasError(true);
      // window.scrollTo({
      //   top: 100,
      //   behavior: "smooth",
      // });
      // return;
    }
    else {
      setApplicableToCodeInputHasError(false);
    }

    if (continentCode === '') {
      setContinentCodeInputHasError(true);
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
      return;
    }
    else {
      setContinentCodeInputHasError(false);
    }

    if (continentCode !== '' && countryCode === '' && cityCode.length === 0) {
      setCountryCodeInputHasError(true);
      return;
    }
    else {
      setCountryCodeInputHasError(false);
    }

    if (continentCode !== '' && countryCode !== '' && cityCode.length !== 0) {
      //setCityCodeInputHasError(true);
      //return;
    }
    else {
      //setCityCodeInputHasError(false);
    }

    let includeRegionJsonTemp = [];
    let continentListTemp = [];
    let cityList = [];
    let arrCityList = [];

    let isContinentCodeMatch = false;
    let countryListPartial = [];
    let applicableToTemp = [];

    if (cityCode.length > 0) {
      cityCode.forEach(cityCode => {

        if (includeRegionJson !== undefined) {
          if (includeRegionJson.length > 0) {
            includeRegionJson[0].data[0].includeExcludeRegion[0].continentlst.forEach(element => {
              if (element.value === continentCode) {
              }
              element.children.forEach(element => {
                if (element.value === countryCode) {
                }
                element.children.forEach(element => {
                  arrCityList.push(element.value);
                });
              });
            });
          }
        }
        const found = arrCityList.find(element => element === cityCode.value);
        if (found === undefined) {
          cityList.push({
            "value": cityCode.value,
            "label": cityCode.label,
            "status": false
          })
        }
      });
    }
    else {
      if (includeRegionJson !== undefined) {
        if (includeRegionJson.length > 0) {
          includeRegionJson[0].data[0].includeExcludeRegion[0].continentlst.forEach(element => {
            if (element.value === continentCode) {
            }
            element.children.forEach(element => {
              if (element.value === countryCode) {
              }
              element.children.forEach(element => {
                arrCityList.push(element.value);
              });
            });
          });
        }
      }
    }

    if (isContinentCodeMatch === false) {
      if (countryCode !== '') {
        countryListPartial = {
          "value": countryCode,
          "label": countryName,
          "status": cityList.length !== 0 ? true : false,
          "allCity": cityList.length === 0 ? true : false,
          "children": cityList
        }
      }

      continentListTemp.push({
        "value": continentCode,
        "label": continentName,
        "status": countryCode !== '' ? true : false,
        "allCity": countryCode === '' ? true : false,
        "children": countryCode === '' ? countryListPartial : [countryListPartial]
      });
    }

    applicableToCode.forEach(element => {
      applicableToTemp.push({
        "applicableID": element.value
      });
    });

    //exclude    
    includeRegionJsonTemp = {
      "serviceID": serviceCode,
      "regionName": regionInputRef.current.value,
      "applicableTo": applicableToTemp,
      "includeExcludeRegion": {
        "continentlst": continentListTemp
      },
      "officeID": JSON.parse(localStorage.getItem('officeID')),
      "id": props.loadedProfileDetails.id === undefined || props.loadedProfileDetails.id === '' ? regionSavedId : props.loadedProfileDetails.id,
      "traceID": JSON.parse(localStorage.getItem('traceID')),
      "userID": JSON.parse(localStorage.getItem('userID'))
    }

    setIncludeRegionJson(includeRegionJsonTemp);

    setContinentCode('');
    setCountryCode('');
    setCityCode('');

    setIsServiceDisabledDDL('true');
    document.getElementById("region").readOnly = true;

    //save hit
    sendRequest(includeRegionJsonTemp);
  }

  function removeRegion(event) {
    //event.preventDefault();
    if (selectedRegions.length > 0) {
      let removeRegionJson = {
        "id": props.loadedProfileDetails.id === undefined || props.loadedProfileDetails.id === '' ? regionSavedId : props.loadedProfileDetails.id,
        "traceID": "",
        "userID": JSON.parse(localStorage.getItem('userID')),
        "value": selectedRegions
      };

      setContinentCode('');
      setCountryCode('');
      setCityCode('');

      setIsServiceDisabledDDL('true');
      document.getElementById("region").readOnly = true;

      //remove hit    
      sendRequestRemove(removeRegionJson);
    }
    else {
      setError({
        title: "Message",
        message: `${t('errorSelectLeastOption')}`,
      });
    }
  }

  const addRegionHandler = (event) => {
    if (document.querySelector('#rdIncludeRegion').checked) {
      includeRegion(event);
    } else if (document.querySelector('#rdExcludeRegion').checked) {
      excludeRegion(event);
    }
  }

  const errorHandler = () => {
    setError(null);
  };

  useEffect(() => {
    if (Object.keys(props.loadedProfileDetails).length === 0) {
      if (serviceValue !== undefined) {
        if (serviceCode === '' || serviceCode === undefined) {
          setServiceCode(serviceValue);
        }
      }
    }
  }, [serviceValue, serviceCode])

  function onConfirm2() {
    if (selectedRegions.length === 0) {
      setError({
        title: "Message",
        message: `${t('errorSelectLeastOption')}`,
      });
    }
    else {
      setConfirm(true);
    }
  }

  function okHandler() {
    removeRegion()
    setConfirm(false);
  };

  function cancelHandler() {
    sessionStorage.removeItem('selectedRegionsTemp');
    setConfirm(false);
  };

  return (
    <Fragment>
      {error1 && (
        <ErrorModal
          title={error1.title}
          message={error1.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      {confirm && (
        <ConfirmationModal
          title={t('confirmationMessageTitle')}
          message={t('confirmationMessage')}
          onOk={okHandler}
          onCancel={cancelHandler}
        ></ConfirmationModal>
      )}
      <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-30">
              <div className="card card-primary card-outline">
                {props.isLoading && (
                  <div className='loading'>
                    <LoadingSpinner />
                  </div>
                )}
                <div className="card-body">
                  <div className="col-md-12 ">
                    <div className="form-group col-md-12">
                      <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'></span></label>
                      <form className='form'>

                        <div className='row'>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control'>
                              <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>{t('service')}<span className="error-icon"> *</span></label>
                              <Dropdown onChange={serviceChangeHandler}
                                value={serviceOptions.filter(option => option.value === serviceCode)}
                                options={serviceOptions}
                                textField="listValue"
                                dataItemKey="listID"
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                isDisabled={isServiceDisabled !== undefined ? isServiceDisabled === "true" ? true : false : false}
                                autoFocus
                              />
                              {serviceCodeInputHasError &&
                                <p className="error-text">{t('validationServiceCode')}</p>
                              }
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control input-placeholder'>
                              <label htmlFor='region' className='h6 text-primary mb-2'>{t('region')}<span className="error-icon"> *</span></label>
                              <input maxLength={100} className="form-control form-control-sm" type='text' required
                                id='region'
                                ref={regionInputRef}
                                onBlur={onBlurCheck}
                              />
                              {regionInputHasError &&
                                <p className="error-text">{t('validationRegion')}</p>
                              }
                              {regionInvalidInputError &&
                                <p className="error-text">{t('validationRegionInvalidData')}</p>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='row d-none'>
                          <div className='col-md-12 col-sm-12'>
                            <div className='control'>
                              <label className="h6 text-primary mb-2 col-lg-12" htmlFor='applicableTo'>{t('applicableTo')}<span className="error-icon"> *</span></label>
                              <Dropdown onChange={applicableToChangeHandler}
                                value={applicableToCode}
                                options={applicableToOptions}
                                textField="listValue"
                                dataItemKey="listID"
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                isDisabled={isServiceDisabled !== undefined ? isServiceDisabled === "true" ? true : false : false}
                                isMulti={true}
                              />
                              {applicableToCodeInputHasError &&
                                <p className="error-text">{t('validationApplicableToCode')}</p>
                              }
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-12'>
                          </div>
                        </div>
                        {/* <hr className='my-1'></hr> */}
                        <div className='text-primary my-3 fw-bold '><span className="text-black "><i className="fas fa-info-circle"></i> Note : </span>  {t('regionDetails')} - {t('regionSpecification')}</div>
                        <div className='row'>
                          <div className='col-md-6 col-sm-12'>
                            <div className='control'>
                              <label className="h6 text-primary mb-2 col-lg-12" htmlFor='continent'>{t('continent')}<span className="error-icon"> *</span></label>
                              <Dropdown onChange={continentChangeHandler}
                                value={continentOptions.filter(option => option.value === continentCode)}
                                options={continentOptions}
                                textField="name"
                                dataItemKey="id"
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                isDisabled={isDisabled !== undefined ? isDisabled === "true" ? true : false : false}
                                autoFocus={editModefocus}
                              />
                              {continentCodeInputHasError &&
                                <p className="error-text">{t('validationContinentCode')}</p>
                              }
                            </div>
                            <div className='control input-placeholder'>
                              <label className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}</label>
                              <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={isDisabled} continentCode={continentCode === '' ? '-' : continentCode} />
                              {countryCodeInputHasError &&
                                <p className="error-text">{t('validationCountryCode')}</p>
                              }
                            </div>
                            <div className='control input-placeholder'>
                              <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}</label>
                              <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} iSDisabled={isDisabled} countryCode={countryCode} isMulti={true} />
                              {cityCodeInputHasError &&
                                <p className="error-text">{t('validationCityCode')}</p>
                              }
                            </div>
                            <div className="row">
                              <div className='col-md-4 my-auto'>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="rdIncludeRegion" value="Include"></input>
                                  <label className="form-check-label" for="rdIncludeRegion">
                                    {t('include')}
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-4 my-auto'>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="rdExcludeRegion" value="Exclude"></input>
                                  <label className="form-check-label" for="rdExcludeRegion">
                                    {t('exclude')}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-12'>
                            <div className="row">
                              <div className="col-md-12 pop-ups-tab-border-parent">
                                <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                  <li className="nav-item " role="presentation">
                                    <button className="tabs-btn nav-link active" id="master" data-bs-toggle="tab"
                                      data-bs-target="#master-tb" type="button" role="tab" aria-controls="master-tb"
                                      aria-selected="true">{t('includetabheading')}</button>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-12 ">
                                <div className="tab-content include-exclude-tab" id="myTabContent">
                                  <div className="tab-pane fade show active" id="master-tb" role="tabpanel"
                                    aria-labelledby="master">
                                    <div className='gt-region-tree-section'>
                                      <div id='dvRegionTreeBlankMessage' className='text-primary text-center mt-25per'>
                                        {t('regiontreeblankmessage')}
                                      </div>
                                      <RegionCheckBoxTree key={Math.random()} fetchSelectedItem={fetchSelectedItem} allRegions={regionJson.length === 0 ? [props.loadedProfileDetailsJson] : regionJson} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                      </form>
                      <div className='mt-3 actions row'>
                        <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                          <a href="/SearchRegion" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                          <div className="float-md-end float-lg-end">
                            <a href="/SearchRegion" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                            {checkSecurityDetails("Master", "Region", "Master_Region_Insert") ?
                              <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                {t('new')}
                              </button>
                              : <span id="btnNew"></span>}

                            {(checkSecurityDetails("Master", "Region", "Master_Region_Insert") || checkSecurityDetails("Master", "Region", "Master_Region_Update")) ?
                              <span><button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnAdd" onClick={addRegionHandler} >
                                {(status === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : t('save')}
                              </button></span>
                              : <span id="btnAdd"></span>}
                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnRemove" onClick={() => { onConfirm2() }}>
                              {(statusRemove === 'pending' || confirm === true) ? <i className="fa fa-spinner fa-spin" ></i> : t('Delete')}
                            </button>
                            <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                              {t('reset')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RegionForm;
import { Fragment, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import Files from 'react-files';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { generateUUID } from '../../../../utils';

import {
    useUploadedDocuments,
    addDocument,
    updateDocumentRemarks,
    deleteDocument
} from '../../../../indexedDatabase/DocumentUpload';

const Document = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [showUploadProgress, setShowUploadProgress] = useState(false);
    const maxTotalDocSizeinMegaBytes = 15; //15 MB
    const bufferSizeForBase64Conversion = (maxTotalDocSizeinMegaBytes * 33) / 100
    const maxTotalDocSizeInBytes = (maxTotalDocSizeinMegaBytes + bufferSizeForBase64Conversion) * 1024 * 1024;

    //useUploadedDocuments is reactive and will watch the changes done to the document table and will update the values
    //accordingly.
    const files = useUploadedDocuments();

    //console.log(files);

    //if (files && files.length) {
    //    console.log('Total uploaded documents size in MB', Buffer.from(JSON.stringify(files)).length / 1048576);
    //    console.log('Last uploaded document size in KB', Buffer.from(files.at(-1).documentBinaryContent).length / 1024);        
    //}

    const elemDocumentName = document.getElementById('documentName');
    const elemDocumentNameErrorMsg = document.getElementById('documentNameErrorMsg');
    const elemDocumentErrorMsg = document.getElementById('documentErrorMsg');
    const elemRemarks = document.getElementById('docRemarks');
    const elemDocRemarksErrorMsg = document.getElementById('docRemarksErrorMsg');

    function handleNameOnBlur() {
        const docName = elemDocumentName.value;
        elemDocumentNameErrorMsg.innerHTML = '';
        if (docName !== '') {
            if (!docName.match('^[a-zA-Z0-9][a-zA-Z0-9. _-]*$')) {
                elemDocumentNameErrorMsg.innerHTML = t('errorInvalidData');
            }
        }
    }

    function handleFileUpload(newFiles) {

        if (newFiles && newFiles.length) {
            if (elemDocumentName.value === '') {
                elemDocumentNameErrorMsg.innerHTML = t('errorNameRequired');
                return;
            }
            else {

                if (files && files.length) {
                    if (files.some(file => file.documentName.toUpperCase() === elemDocumentName.value.toUpperCase())) {
                        elemDocumentErrorMsg.innerHTML = t('errorDuplicateFileUpload');
                        return;
                    }
                }

                setShowUploadProgress(true);

                let documentToUpload = null;
                                
                //Note: When files are converted to base64, there's approx 33% increase in size
                blobToBase64(new Blob([newFiles[0]])).then(res => {
                    documentToUpload = {
                        documentID: generateUUID(10),
                        documentType: newFiles[0].type,
                        documentOriginalName: newFiles[0].name,
                        documentName: elemDocumentName.value.trim() + '.' + newFiles[0].type.split('/').at(-1),
                        documentBinaryContent: res,
                        remarks: elemRemarks.value,
                        documentSortKey: new Date().getTime() //this is used for indexedDB sorting during display
                    };
                }).then(() => {

                    const totalBytes = Buffer.from(JSON.stringify(files)).length +
                        Buffer.from(JSON.stringify(documentToUpload)).length;

                    if (totalBytes > maxTotalDocSizeInBytes) {
                        elemDocumentErrorMsg.innerHTML = t('errorTotalFileSizeExceed');
                        setShowUploadProgress(false);
                        return;
                    }
                    else {
                        addDocument(documentToUpload).then(() => {
                            elemDocumentName.value = '';
                            setShowUploadProgress(false);
                        });
                    }                    
                });

                elemDocumentNameErrorMsg.innerHTML = "";
                elemDocumentErrorMsg.innerHTML = "";
            }
        }
    }

    function handleFileUploadError(error) {
        if (error) {
            if (error.code === 1) {
                elemDocumentErrorMsg.innerHTML = t('errorInvalidDocumentType');
            }
            else if (error.code === 2) {
                elemDocumentErrorMsg.innerHTML = t('errorTotalFileSizeExceed');
            }
            else {
                elemDocumentErrorMsg.innerHTML = error.message;
            }
        }
    }

    function handleFileRemove(fileId) {
        deleteDocument(fileId);
        //deleteAllDocuments();
    }

    //Despite handleRemarksOnChange and handleRemarksOnBlur looking similar, 
    //be careful not to merge them since there are mninor/important differences
    function handleRemarksOnChange() {
        //trim not applied since onChange is fired for every key stroke
        const remarks = elemRemarks.value;       

        if (remarks !== '') {            
            elemDocRemarksErrorMsg.innerHTML = '';
        }

        //update all uploaded documents with the new remarks
        //Commented for now, refer comment inside handleRemarksOnBlur function
        //updateDocumentRemarks(remarks);
    }

    function handleRemarksOnBlur() {
        const remarks = elemRemarks.value.trim();

        elemDocRemarksErrorMsg.innerHTML = '';

        if (remarks === '') {
            //Show remarks required error message if document is attached.
            //If not, no message will be shown because document as a whole is not mandatory.
            if (files && files.length) {
                elemDocRemarksErrorMsg.innerHTML = t('errorRemarksRequired');
            }
        }

        //Updating remarks to indexed db onChange or onBlur is too expensive in terms of responsiveness. 
        //Therefore, remarks is moved to session storage and value is set during travel request submission.
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.documentRemarks = remarks;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }
        
    function blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }

    return (
        <Fragment>
            <ReactTooltip
                anchorId='Upload'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Upload File"}
            />
            <ReactTooltip
                anchorId='Remove'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Remove File"}
            />
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="Service">
                                        <i className="fas fa-file"></i>
                                        &nbsp;{t('labelDocuments')}
                                    </label>
                                    <div className='col-md-12 mt-2'>
                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor="division">
                                            {t('labelName')}<span className="error-icon"> *</span>
                                        </label>
                                        <input maxLength={100}
                                            className="form-control bg_input col-md-6" type='text'
                                            onBlur={handleNameOnBlur}
                                            id='documentName' />
                                        <p className="error-text" id='documentNameErrorMsg'></p>
                                    </div>
                                    <div className="dropdown pb-2 col-md-12 mt-4">
                                        <div className="files position-relative">
                                            <Files
                                                className='files-dropzone text-center gt-file-dropzone col-md-12 col-sm-12 col-12'
                                                onChange={handleFileUpload}
                                                onError={handleFileUploadError}
                                                accepts={['image/png', 'image/jpg', 'image/jpeg', '.pdf']}
                                                multiple={false}
                                                maxFileSize={maxTotalDocSizeInBytes}
                                                minFileSize={0}
                                                clickable>
                                                <span id='Upload' className='file-upload text-primary'>
                                                    <i className="fas fa-plus-circle"></i>
                                                    <span> {t('labelUploadInstruction')}</span>
                                                    <br />
                                                    <span className="note">({t('noteSupportedFiles')})</span>
                                                </span>
                                                {showUploadProgress &&
                                                    <span id='loadingSpin' className='d-none'>
                                                        <i className="fa fa-spinner fa-spin text-primary ms-3" ></i>
                                                    </span>
                                                }
                                            </Files>
                                            <p className="error-text" id='documentErrorMsg'></p>

                                            {files && files.length > 0 &&
                                                <div className="files-list mt-4">
                                                    <strong>{t('labelFile')} </strong>
                                                    {files.map((file, index) =>
                                                        <span key={file.documentID}>
                                                            <span class="btn h6 text-white gt-yellow-btn ms-1 btn-sm">
                                                                {file.documentName}
                                                                <span class="text-white"
                                                                    onClick={() => handleFileRemove(file.documentID)}>
                                                                    <i class="fas fa-times ms-3"></i>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    )}                                                    
                                                </div>
                                            }
                                            <div className="col-md-12 mt-1">
                                                <span className="h6 text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span>
                                                <span className="ms-1 text-primary h6">
                                                    {t('noteDocumentSizeLimit').replace('{MB}', maxTotalDocSizeinMegaBytes)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12 dropdown-search-text'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="Remarks">
                                        {t('labelRemarks')}<span className="error-icon"> *</span>
                                    </label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='docRemarks'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            onChange={handleRemarksOnChange}
                                            onBlur={handleRemarksOnBlur}
                                        />
                                    </div>
                                    <p className="error-text" id='docRemarksErrorMsg'></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Document;


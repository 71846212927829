const FareSummary = (props) => {
    return <>
        <div class="tour_details_boxed_inner">
            <div class="accordion" id="accordionExample">
                <div class="accordion_flex_area">
                    <div class="accordion-item pb-2">
                        <h5 class="accordion-header text-sky-blue fw-bold" id="headingOne">Price</h5>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <div class="col-md-12 accordion" id="accordionFareSummary">
                                        <div class="print-mt-15">
                                            <label class="form-check-label h6 mt-2" for="flexCheckDefaultf1">
                                                <span class="area_flex_one text-muted">
                                                    <span class="h6 ms-2">Base Fare (1 * {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.fareDetails.currencyCode
                                                    }).format(1 * props.fareDetails.baseFare)})

                                                    </span>
                                                    <span class="h6">{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.fareDetails.currencyCode
                                                    }).format(1 * props.fareDetails.baseFare)}</span>
                                                </span>
                                            </label>
                                            <label class="form-check-label " for="flexCheckDefaultf1">
                                                <span class="area_flex_one text-muted">
                                                    <span class="h6  ms-2">Taxes (1 * {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.fareDetails.currencyCode
                                                    }).format(1 * props.fareDetails.taxes)})</span>
                                                    <span> {new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.fareDetails.currencyCode
                                                    }).format(1 * props.fareDetails.taxes)}</span>
                                                </span>
                                            </label>
                                        </div>
                                        <hr className="my-2"></hr>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                <div class="h5 mb-1 fw-bold text-primary">Total Fare </div>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                <div class="h5 mb-2 fw-bold text-primary text-end float-lg-end">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.fareDetails.currencyCode
                                                }).format(1 * props.fareDetails.totalFare)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
}

export default FareSummary;
import { Fragment } from 'react';
import LoadingSightseeing from '../Sightseeing/Components/LoadingSightseeing';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../utils/RoleUtils';

const SightseeingDetailsTab = () => {
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    setTimeout(function () {
       
        if ((getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).parentOfficeID
            || getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).officeID) && document.getElementsByTagName('iframe').length < 2) {
            window.location.reload(true);
        }
        else if ((getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).parentOfficeID
            || getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).officeID) && document.getElementsByTagName('iframe').length < 1) {
            window.location.reload(true);
        }
        else {
            if (document.getElementById("divSightseeingValue") !== null) {
                document.getElementById("divSightseeingValue").style.display = "none";
            }
        }

    }, 3000);

    return <Fragment>
        <section className="sub-banner">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">
            </div>
        </section>
        <section id="theme_search_form_tour" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative my-2">
                            <div className="row position-relative">
                                <div className="col-lg-12">
                                    <div data-vi-partner-id={valueParam.partnerId.toString()} data-vi-widget-ref={valueParam.widgetRef.toString()} ></div>
                                    <div id="divSightseeingValue"><LoadingSightseeing action='search' ></LoadingSightseeing></div>
                                    {/* <div data-vi-partner-id='U00414779' data-vi-widget-ref='W-d0b96caa-cd7e-4d0d-ad77-58c53aa53efb' ></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
};

export default SightseeingDetailsTab;
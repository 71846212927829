const locaterNumber = localStorage.getItem("profileLocaterNumber") !== 'undefined' ? localStorage.getItem("profileLocaterNumber") : "\"\""
const dkNumber = localStorage.getItem("dkNumber") !== 'undefined' ? localStorage.getItem("dkNumber") : "\"\""

let paxInformation = JSON.stringify({
    "ProfileRef": {
        "UniqueID": {
            "CompanyName": {
                "LocaterNumber": JSON.parse(locaterNumber),
            }
        }
    },
    "CustomerIdentifierNumber": JSON.parse(dkNumber),
    "PersonName": {
        "NamePrefix": "",
        "GivenName": "",
        "Surname": "",
        "MiddleName": ""
    },
    "Gender": "",
    "telephone": [
        {
            "phoneNumber": "",
            "mobile": "",
            "fax": ""
        }
    ],
    "email": [
        {
            "emailId": "",
            "IsPrimary": "TRUE"
        }
    ],
    "dateOfBirth": {
        "date": "00000000"
    },
    "CustLoyalty": [
        {
            "MembershipID": "",
            "ProgramID": ""
        }
    ],
    "Document": [
        {
            "DocID": "",
            "DocType": "Passport",
            "DocHolderName": "",
            "DocIssueAuthority": "",
            "DocIssueLocation": "",
            "Gender": "M",
            "BirthDate": "",
            "EffectiveDate": "",
            "ExpireDate": "00000000",
            "DocIssueCountry": "",
            "DocHolderNationality": "",
            "DocIssueDate": "00000000"
        },
        {
            "DocID": "",
            "DocType": "",
            "DocIssueCountry": ""
        }
    ],
    "PaxConvenienceCharges": "",
    "PassengerTypeCode": "",
    "TravelerRefNumber": "",
    "AccompaniedByInfantInd": null,
    "Operation": null,
    "CouponCode": null
})

export const CreateBookingJSON = (selectFlight) => {

    let createPaxInformation = [];
    let intTravelRefNumber = 0;

    selectFlight.airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {


        for (var i = 0; i < parseInt(element.quantity); i++) {
            let paxInfromationDetails = JSON.parse(paxInformation);
            intTravelRefNumber = intTravelRefNumber + 1;

            paxInfromationDetails.TravelerRefNumber = "" + intTravelRefNumber + "";
            paxInfromationDetails.PassengerTypeCode = element.code;
            createPaxInformation.push(paxInfromationDetails);
        }
    });

    let isIncludeBagOnlyFares = false;
    let isIncludeCabinBagOnlyFares = false;

    if (sessionStorage.getItem('preferenceOptions') !== null) {
        JSON.parse(sessionStorage.getItem('preferenceOptions')).forEach(element => {
            if (element.preferenceOptionId === 'includeBagOnlyFares') {
                isIncludeBagOnlyFares = true;
            }
            if (element.preferenceOptionId === 'includeCabinBagOnlyFares') {
                isIncludeCabinBagOnlyFares = true;
            }
        });
    }
    selectFlight.airItinerary.originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
        elementOriginDestinationOption.segmentRefDetail.forEach(elementSegmentRefDetail => {
            elementSegmentRefDetail.cabinBaggageOnly = isIncludeCabinBagOnlyFares;
            elementSegmentRefDetail.checkInBaggageOnly = isIncludeBagOnlyFares;
        });
    });

    const BookingJSON = ({

        "OfficeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "bookingReferenceNumber": "",
            "travelAgentName": localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null ? JSON.parse(localStorage.getItem('displayName')) : "",
            "POS": null,
            "travelerInfo": {
                "SpecialReqDetails": [
                    {
                        "SeatRequests": null,
                        "SpecialServiceRequests": null,
                        "OtherServiceInformations": null
                    }
                ],
                "traveler": createPaxInformation
            },
            "airItinerary": [
                {
                    "originDestinationOptions": selectFlight.airItinerary.originDestinationOptions,
                    "airItineraryPricingInfo": selectFlight.airItineraryPricingInfo,
                    "airItineraryPricingInfoOrignal": selectFlight.airItineraryPricingInfoOrignal !== undefined && selectFlight.airItineraryPricingInfoOrignal !== null && selectFlight.airItineraryPricingInfoOrignal !== "" ? selectFlight.airItineraryPricingInfoOrignal : null,
                    "AirItineraryRemarks": null,
                    "SequenceNumber": "1",
                    "SourceId": selectFlight.sourceId,
                    "SourceName": selectFlight.sourceName,
                    "DirectionInd": "O",
                    "SectorInd": "I",
                    "SecurityKey": {
                        "securityGUID": selectFlight.securityKey !== undefined && selectFlight.securityKey !== '' ? selectFlight.securityKey.securityGUID : '',
                        "key": selectFlight.securityKey !== undefined && selectFlight.securityKey !== '' ? selectFlight.securityKey.key : ''
                    },
                    "ResultIndex": "",
                    "CouponFare": "0",
                    "ChannelType": null,
                    "ChannelCode": "",
                    "IsLCC": "0",
                    "IsAPISMandatory": null,
                    "IsGSTMandatory": null,
                    "SelectedPricingSource": "Published",
                    "ReturnGroupId": "1",
                    "IsReturn": "",
                    "CancelPNR": null,
                    "PaymentInfo": {
                        "PaymentMode": [
                            {
                                "Name": "CREDITCARD",
                                "Amount": "0",
                                "Type": null,
                                "CardIsEncrypt": true,
                                "PaymentModeInfo": [
                                    {
                                        "FieldName": "CARDTYPE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CARDNO",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "NAMEONCARD",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRYEAR",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRMONTH",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "ADDRESS",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "COUNTRY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "STATE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CITY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "POSTALCODE",
                                        "FieldValue": ""
                                    },
                                ]
                            }
                        ],
                        "TicketingInfo": {
                            "TicketType": "E",
                            "TicketTimeLimit": "",
                            "IssueTicket": "N",
                            "DirectionInd": null,
                            "IssueTicketWithUpdateFare": null,
                            "TktArrangement": "TKTL",
                            "Operation": null
                        }
                    },
                    "PNRRetention": null,
                    "TPA_Extensions": null,
                    "PNRAmount": null,
                    "PriceType": null,
                    "NUC_Rate": null,
                    "ExchangeRate": null,
                    "BookingReferenceID": null,
                    "UpdatedAirItineraryPricingInfo": null,
                    "ValidatingCarrier": selectFlight.airItinerary.validatingCarrier,
                    "ValidatingCarrierName": selectFlight.airItinerary.validatingCarrierName,
                    "totalOutBoundDuration": selectFlight.airItinerary.totalOutBoundDuration,
                    "totalInBoundDuration": selectFlight.airItinerary.totalInBoundDuration,
                    "totalItineraryDuration": selectFlight.airItinerary.totalItineraryDuration,
                    "groupDurations": selectFlight.airItinerary.groupDurations
                }
            ],

            "MiscellaneousRemarkInfo": {
                "MiscellaneousInfo": [
                    {
                        "Code": "",
                        "Text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "RemarkType": "General",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    },
                    {
                        "Code": "",
                        "Text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "RemarkType": "InternalRemarks",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    }
                ]
            },

            "TPA_Extensions": null,
            "Action": 0,
            "UserID": JSON.parse(localStorage.getItem('userID')),
            "TraceId": JSON.parse(localStorage.getItem('traceID')),
            "SwitchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
            "IPAddress": localStorage.getItem("IPAddress"),
            "TimeStamp": null,
            "remarks": "",
            "inPolicy": selectFlight.inPolicy !== undefined && selectFlight.inPolicy !== null && selectFlight.inPolicy !== '' ? selectFlight.inPolicy : '',
            "inPolicyReason": selectFlight.inPolicyReason !== undefined && selectFlight.inPolicyReason !== null && selectFlight.inPolicyReason !== '' ? selectFlight.inPolicyReason : ''
        }
    })

    return BookingJSON;
}

export const CreateImportBookingJSON = () => {

    let createPaxInformation = [];
    let intTravelRefNumber = 0;
    const BookingJSON = ({

        "OfficeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "bookingReferenceNumber": "",
            "POS": null,
            "importPNR": "",
            "travelerInfo": {
                "specialReqDetails": [
                    {
                        "seatRequests": null,
                        "specialServiceRequests": null,
                        "otherServiceInformations": null
                    }
                ],
                "traveler": createPaxInformation
            },
            "airItinerary": [
                {
                    "originDestinationOptions": "",
                    "airItineraryPricingInfo": "",
                    "airItineraryPricingInfoOrignal": null,
                    "AirItineraryRemarks": null,
                    "SequenceNumber": "1",
                    "SourceId": "1S",
                    "SourceName": 'Sabre',
                    "DirectionInd": "O",
                    "SectorInd": "I",
                    "SecurityKey": {
                        "securityGUID": "",
                        "key": ""
                    },
                    "ResultIndex": "",
                    "CouponFare": "0",
                    "ChannelType": null,
                    "ChannelCode": "",
                    "IsLCC": "0",
                    "IsAPISMandatory": null,
                    "IsGSTMandatory": null,
                    "SelectedPricingSource": "Published",
                    "ReturnGroupId": "1",
                    "IsReturn": "",
                    "CancelPNR": null,
                    "paymentInfo": {
                        "paymentMode": [
                            {
                                "name": "",
                                "amount": "0",
                                "Type": null,
                                "paymentModeInfo": [
                                    {
                                        "FieldName": "CARDTYPE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CARDNO",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "NAMEONCARD",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRYEAR",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRMONTH",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "ADDRESS",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "COUNTRY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "STATE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CITY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "POSTALCODE",
                                        "FieldValue": ""
                                    },
                                ]
                            }
                        ],
                        "TicketingInfo": {
                            "TicketType": "E",
                            "TicketTimeLimit": "",
                            "IssueTicket": "N",
                            "DirectionInd": null,
                            "IssueTicketWithUpdateFare": null,
                            "TktArrangement": "TKTL",
                            "Operation": null
                        }
                    },
                    "PNRRetention": null,
                    "TPA_Extensions": null,
                    "PNRAmount": null,
                    "PriceType": null,
                    "NUC_Rate": null,
                    "ExchangeRate": null,
                    "BookingReferenceID": null,
                    "UpdatedAirItineraryPricingInfo": null,
                    "ValidatingCarrier": "",
                    "totalOutBoundDuration": "",
                    "totalInBoundDuration": "",
                    "totalItineraryDuration": "",
                    "groupDurations": [
                        {
                            "id": "1",
                            "duration": ""
                        }
                    ]
                }
            ],

            "miscellaneousRemarkInfo": {
                "miscellaneousInfo": [
                    {
                        "code": "",
                        "text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "remarkType": "General",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    },
                    {
                        "code": "",
                        "text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "remarkType": "InternalRemarks",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    }
                ]
            },
            "bookingReferenceID": [
                {
                    "type": "PNRNO",
                    "id": ""
                }
            ],
            "TPA_Extensions": null,
            "Action": 0,
            "UserID": JSON.parse(localStorage.getItem('userID')),
            "TraceId": JSON.parse(localStorage.getItem('traceID')),
            "SwitchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
            "IPAddress": localStorage.getItem("IPAddress"),
            "TimeStamp": null,
            "remarks": "",
        }
    })

    return BookingJSON;
}

export const CreateImportAirItineraryBookingJSON = () => {

    const AirItineraryBookingJSON = (
        [{
            "originDestinationOptions": "",
            "airItineraryPricingInfo": "",
            "airItineraryPricingInfoOrignal": null,
            "AirItineraryRemarks": null,
            "SequenceNumber": "1",
            "SourceId": "1S",
            "SourceName": 'Sabre',
            "DirectionInd": "O",
            "SectorInd": "I",
            "SecurityKey": {
                "securityGUID": "",
                "key": ""
            },
            "ResultIndex": "",
            "CouponFare": "0",
            "ChannelType": null,
            "ChannelCode": "",
            "IsLCC": "0",
            "IsAPISMandatory": null,
            "IsGSTMandatory": null,
            "SelectedPricingSource": "Published",
            "ReturnGroupId": "1",
            "IsReturn": "",
            "CancelPNR": null,
            "paymentInfo": {
                "paymentMode": [
                    {
                        "name": "",
                        "amount": "0",
                        "Type": null,
                        "paymentModeInfo": [
                            {
                                "FieldName": "CARDTYPE",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "CARDNO",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "NAMEONCARD",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "EXPIRYEAR",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "EXPIRMONTH",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "ADDRESS",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "COUNTRY",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "STATE",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "CITY",
                                "FieldValue": ""
                            },
                            {
                                "FieldName": "POSTALCODE",
                                "FieldValue": ""
                            },
                        ]
                    }
                ],
                "TicketingInfo": {
                    "TicketType": "E",
                    "TicketTimeLimit": "",
                    "IssueTicket": "N",
                    "DirectionInd": null,
                    "IssueTicketWithUpdateFare": null,
                    "TktArrangement": "TKTL",
                    "Operation": null
                }
            },
            "PNRRetention": null,
            "TPA_Extensions": null,
            "PNRAmount": null,
            "PriceType": null,
            "NUC_Rate": null,
            "ExchangeRate": null,
            "BookingReferenceID": null,
            "UpdatedAirItineraryPricingInfo": null,
            "ValidatingCarrier": "",
            "totalOutBoundDuration": "",
            "totalInBoundDuration": "",
            "totalItineraryDuration": "",
            "groupDurations": [
                {
                    "id": "1",
                    "duration": ""
                }
            ]
        }]
    )

    return AirItineraryBookingJSON;
}

export const CreateProfileJSON = () => {

    let createPaxInformation = [];
    let intTravelRefNumber = 0;

    let paxInfromationDetails = JSON.parse(paxInformation);
    intTravelRefNumber = intTravelRefNumber + 1;

    paxInfromationDetails.TravelerRefNumber = "" + intTravelRefNumber + "";
    paxInfromationDetails.PassengerTypeCode = "ADT";
    createPaxInformation.push(paxInfromationDetails);

    const ProfileJSON = ({
        "OfficeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "travelerInfo": {
                "traveler": createPaxInformation
            },
            "UserID": JSON.parse(localStorage.getItem('userID')),
            "TraceId": JSON.parse(localStorage.getItem('traceID'))
        }
    })

    return ProfileJSON;
}
import FlightItem from "../../../components/Common/Flight/FlightItem";
import HotelDetails from '../../HotelBookingConfirmation/Components/HotelDetails';
import RateDetails from "../../HotelBookingConfirmation/Components/RateDetails";
import TravellerInfo from "../../BookingConfirmation/Components/TravellerInfo";
import HotelPolicy from "../../HotelBookingConfirmation/Components/HotelPolicy";
import FareSummary from "../../../components/Common/Flight/FareSummary";
import Booking from "../../HotelBookingConfirmation/Components/Booking";
import { Fragment } from "react";
import PrintBookingDetails from "./Components/PrintBookingDetails";
import HotelStatus from "./Components/HotelStatus";
import PrintFlightItem from "../../../components/Common/Flight/PrintFlightItem";
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import { useLocation } from 'react-router-dom';

const PrintConfirmation = (props) => {
    const location = useLocation();
    let bookingJSON = JSON.parse(sessionStorage.getItem("BookingDataShoppingCart"));
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "default-confirm-hotel.png" //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    let hotelFirstImage = bookingJSON.data.hotelResults[0].images !== undefined && bookingJSON.data.hotelResults[0].images.length > 0 ? bookingJSON.data.hotelResults[0].images[0].imagePath : hotelLogoLink;
    let hotelSecondImage = bookingJSON.data.hotelResults[0].images !== undefined && bookingJSON.data.hotelResults[0].images.length > 0 ? bookingJSON.data.hotelResults[0].images[1] !== undefined ? bookingJSON.data.hotelResults[0].images[1].imagePath : "" : "";

    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let equivalentAmountValue = calculateRoeAmountAdditional(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode, parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount) + parseFloat(bookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered), false);

    if (location.pathname === '/RetrieveTrip' || location.pathname === 'RetrieveTrip' || location.pathname === '/TripDetails' || location.pathname === 'TripDetails') {
        equivalentAmountValue = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentFare !== undefined && bookingJSON.data.hotelResults[0].price[0].offeredPrice.equivalentPriceOffered !== undefined ? parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.equivalentFare) + parseFloat(bookingJSON.data.hotelResults[0].price[0].offeredPrice.equivalentPriceOffered) : 0;
    }

    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="">
                                <PrintBookingDetails bookingJSON={bookingJSON} hideData={props.hideData} />

                                <div className='col-md-12'>
                                    <PrintFlightItem
                                        key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                        id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                        airItinerary={bookingJSON.data.airItinerary[0]}
                                        searchFlight={bookingJSON.data.airItinerary[0]}
                                        bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                        fareRuleData={bookingJSON.data.airItinerary[0]}
                                    />

                                </div>

                                <HotelStatus bookingJSON={bookingJSON}></HotelStatus>
                                {hotelSecondImage !== '' &&
                                    <div className="hotel-list row">
                                        <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12' style={{ width: "50%" }}>
                                            <img src={hotelFirstImage} className='w-100' />
                                        </div>
                                        <div className='hotel-confirmation-img col-lg-6 col-md-6 col-sm-12' style={{ width: "50%" }}>
                                            <img src={hotelSecondImage} className='w-100' />
                                        </div>
                                        <HotelDetails hotelBookingJSON={bookingJSON}></HotelDetails>
                                    </div>}
                                {hotelSecondImage === '' &&
                                    <div className="hotel-list row">
                                        <div className='hotel-confirmation-single-img col md-12'>
                                            <img src={hotelFirstImage} className='w-100' />
                                        </div>

                                        <HotelDetails hotelBookingJSON={bookingJSON}></HotelDetails>
                                    </div>}

                                <div className="tour_details_boxed_inner">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion_flex_area">
                                            <Booking hotelBookingJSON={bookingJSON}></Booking>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <div className="accordion-item">
                                                <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                    Price
                                                </h5>
                                                <p className="card-text mb-0 mt-2 pb-0 text-primary"><strong><i className="fas fa-plane-departure"></i> Flight </strong></p>
                                                <div id="collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                                    <div className='row my-2'>
                                                        <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="1" hideCommision="4"></FareSummary>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion_flex_area">
                                            <RateDetails hotelBookingJSON={bookingJSON} show="show" hidePrice ></RateDetails>
                                            <hr className='my-2'></hr>

                                        </div>
                                        <hr></hr>
                                        <div className="row"><div className="col-lg-3 col-md-3 col-sm-3 col-3"><div className="h5 mb-1 fw-bold text-primary">Trip Cost </div></div><div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: bookingJSON.data.hotelResults[0].price[0].currencyCode
                                            }).format(parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount) + parseFloat(bookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered))}
                                            {/* {(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)} */}
                                        </div></div></div>
                                        {
                                            bookingJSON.data.hotelResults[0].price[0].currencyCode !== undefined && bookingJSON.data.hotelResults[0].price[0].currencyCode !== '' && bookingJSON.data.hotelResults[0].price[0].currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                            <div className="row"><div className="col-lg-3 col-md-3 col-sm-3 col-3"><div className="h5 mb-1 fw-bold text-primary">Eq. Trip Cost </div></div><div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                                                {new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                    style: 'currency',
                                                    currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                }).format(equivalentAmountValue)}
                                            </div></div></div>
                                        }

                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <TravellerInfo bookingJSON={bookingJSON} serviceCode="FLHT" print="1"></TravellerInfo>
                                        </div>
                                        <hr></hr>
                                        <div className="accordion_flex_area">
                                            <HotelPolicy hotelBookingJSON={bookingJSON}></HotelPolicy>
                                        </div>


                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
}
export default PrintConfirmation;
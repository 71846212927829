import { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortData } from "../../../../utils";
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import StaticPageItem from "../Components/StaticPageItem"
const StaticPageList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.StaticPage']);
    const queryParams = new URLSearchParams(location.search);
    const isSortingAscending = queryParams.get('sort') === 'asc';
    const sortedStaticPageName = sortData(props.staticPage, 'pageID', isSortingAscending);
    const [error1, setError] = useState();
    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };

    const errorHandler = () => {
        setError(null);
        history.push({
            pathname: '/SearchPageBuilder'
        })

    };
    return (<Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <div className="container-fluid mb-3 list-view">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6"></div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">{t('sortBy')}</div>
                        </div>
                        <div className="col-md-4 form-group">
                            <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                                <option>{t('sortPageNameDsc')}</option>
                                <option>{t('sortPageNameAsc')}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {sortedStaticPageName.map((item) => (
                <StaticPageItem
                    key={item.id}
                    id={item.id}
                    pageTitle={item.pageTitle}
                    pageID={item.pageID}
                    isActive={item.isActive}

                />
            ))}
        </div>
    </Fragment>);
}

export default StaticPageList;
import { Fragment, } from "react";
import { Link } from "react-router-dom";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { useTranslation } from 'react-i18next';
const pageNameOptions = [{ value: "1", label: "Terms and Conditions" },

]
const StaticPageItem = (props) => {
    const { t } = useTranslation(['translation.StaticPage']);
    let pageName;
    pageNameOptions.forEach(element => {
        if (element.value === props.pageID) {
            pageName = element.label;
            return;
        }

    });
    return <Fragment>
        <div className="row" key={props.id}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {pageName}
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="col-md-8">
                                <p className="card-text mb-0"><strong>{t('pageTitle')}:</strong>{" " + props.pageTitle} </p>
                                <p className="card-text mb-0"><strong>{t('active')}:</strong>{props.isActive === true ? " Yes" : " No"} </p>
                            </div>

                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_View") && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdatePageBuilder/0/${props.id}/${props.pageID}`, edit: 0, }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                    {checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdatePageBuilder/1/${props.id}/${props.pageID}`, edit: 1, }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default StaticPageItem;
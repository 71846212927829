import { Fragment } from 'react';
import RotatingPlan from '../../Flights/Components/RotatingPlan';
import WorldMap from '../../Flights/Components/WorldMap';
const LoadingSightseeing = (props) => {

  return (
    <Fragment>
      <div className='loading'>

        <div className="loader flight-loader-width">
          <div className="plane">
            {/* <img src="assets/img/loader5.gif" className="plane-img" /> */}
            <RotatingPlan/>
          </div>
          <div className='postion-relative flight-loaders'>
            <div className="earth-wrapper cstm">
              {/* <div className="earth"></div> */}
              <div className="worldMap">
                                <div className="worldMap__inner">
                                <WorldMap className={'img1'}/>
                                <WorldMap className={'img2'}/>
                                <WorldMap className={'img3'}/>
                                <WorldMap className={'img4'}/>
                                </div>
                            </div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          {
            props.action === 'search' &&
            <div className='position-absolute bottom-0 text-center w-100'>
              <div className='mb-4'>
                Please wait for a few seconds…                
              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>)
}
export default LoadingSightseeing;

import { Fragment, useState, useEffect } from "react";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import TermAndConditionForm from "./Components/StaticPageForm";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { searchStaticPage, addTermsAndConditions, updateTermsAndCondition } from "../../../services/StaticPage-API"
import useHttpGet from "../../../services/use-http"
import useHttp from "../../../services/use-http"
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
const UpdateStaticPage = () => {
    useDocumentTitle(" Page Builder")
    const [error, setError] = useState(null);
    const { t } = useTranslation(['translation.StaticPage']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const history = useHistory();
    const { Action, Id, PageId } = parms;

    let varHttp;
    if (Id === undefined) {
        varHttp = addTermsAndConditions
    } else {
        varHttp = updateTermsAndCondition
    }
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const { sendRequest: sendRequestTermAndCondition, data: loadedTermAndCondition, status: getTermAndConditionStatus, error: termAndConditionError } = useHttpGet(searchStaticPage);
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')
    if (!checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Update") && Action === '1')
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_View") && Action === '0')
        history.push('/Unauthorized')

    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {
        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])
    const officeID = JSON.parse(localStorage.getItem('officeID'));

    useEffect(() => {
        const searchInput = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "ipAddress": "",
            "termAndCondition": "",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "pageID": PageId,
            "pageTitle": ""


        });
        if (officeID !== undefined && PageId !== undefined) {

            sendRequestTermAndCondition(searchInput);
        }
    }, [sendRequestTermAndCondition, PageId])

    if (getTermAndConditionStatus === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }
    if (termAndConditionError) {
        history.push('/Error')
        //return <p className='centered focused'>{t('error')}</p>
    }


    let loadedData = [];
    for (let i in loadedTermAndCondition) {
        if (loadedTermAndCondition['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedTermAndCondition[i];
            }
        }
    }
    const addStaticPageHandler = (requestData) => {
        sendRequest(requestData);

    };
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/SearchPageBuilder');
        }
    };
    const termAndConditionContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <TermAndConditionForm loadedStaticPageDetails={loadedData} isLoading={status === 'pending'} onAddStaticPage={addStaticPageHandler}
        />

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {termAndConditionContent}
    </Fragment>
}

export default UpdateStaticPage;
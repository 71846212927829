import { Fragment, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHttp from "../../../services/use-http";
import { getAllUser, searchUser } from "../../../services/User-API";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import UserList from "./components/UserList";

const SearchDelegateApprover = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState();
    const { t } = useTranslation(["translation.Delegate"]);
    const [isFirstTime, setFirstTime] = useState("");
    const [inputError, setInputError] = useState(false);
    const history = useHistory();

    let varHttp;
    if (
        firstName.trim().length === 0 &&
        lastName.trim().length === 0 &&
        role === ""
    ) {
        varHttp = getAllUser;
    } else {
        varHttp = searchUser;
    }

    const firstNameChangehandler = (event) => {
        setFirstName(event.target.value);
    };

    const lastNamechangehandler = (event) => {
        setLastName(event.target.value);
    };

    const { sendRequest, status, data: loadedUser, error } = useHttp(varHttp);

    useEffect(() => {
        if (status === "completed") {
            if (
                loadedUser["errors"].error["code"] === "1008" ||
                loadedUser["errors"].error["code"] === "9001"
            ) {
                setInputError(true);
                return;
            }
        }
    }, [status, loadedUser]);

    function onResetHandler() {
        setFirstName("");
        setLastName("");
        setRole("");
        window.location.reload();
    }

    const onSearchHandler = useCallback(
        (event) => {
            event.preventDefault();

            if (
                !checkSecurityDetails(
                    "Settings",
                    "Delegate Workflow",
                    "Settings_Delegate Workflow_Search"
                )
            ) {
                return false;
            }

            setFirstTime(true);
            const onSearchUser = {
                firstName: firstName,
                lastName: lastName,
                roleID: "",
                status: "",
                isActive: true,
                officeID: JSON.parse(localStorage.getItem("officeID")),
            };
            sendRequest(onSearchUser);
        },
        [sendRequest, firstName, lastName]
    );

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [onSearchHandler]);

    if (error) {
        history.push("/Error");
        return (
            <div className="centered focused  col-lg-5 col-md-8 col-sm-12 col-12">
                {t("errorMessage")}
            </div>
        );
    }

    function okayHandler() {
        setInputError(false);
        onResetHandler();
    }

    let loadedData = [];
    for (let i in loadedUser) {
        if (loadedUser["errors"].status === "FALSE") {
            if (i === "data") {
                loadedData = loadedUser[i];
            }
        }
    }

    const userList = !isFirstTime ? (
        ""
    ) : loadedData && loadedData.length !== 0 ? (
        <UserList
            user={loadedData}
            setSelectedUser={props.setSelectedUser}
            setIsDelegateActualApprover={props.setIsDelegateActualApprover}
        />
    ) : (
        ""
    );

    return (
        <Fragment>
            {inputError && (
                <ErrorModal
                    title="Message"
                    message={t("errorMessage")}
                    onConfirm={okayHandler}
                />
            )}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12"></div>
                    </div>
                </div>
            </section>

            <section
                id="theme_search_form_tour"
                className="fligth_top_search_main_form_wrapper"
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {status === "pending" && (
                                                <div className="">
                                                    <LoadingSpinner></LoadingSpinner>
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 gt-country-details-form">
                                                    <div className="form-group col-md-12">
                                                        <label
                                                            htmlFor=""
                                                            className="mb-2 h5 text-primary mb-3 col-lg-12"
                                                        >
                                                            <i className="fas fa-globe-asia"></i>{" "}
                                                            {t("searchDelegateApprover")}
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label
                                                                    htmlFor="firstName"
                                                                    className="mb-2 h6 text-primary col-lg-12"
                                                                >
                                                                    {t("firstName")}
                                                                </label>

                                                                <input
                                                                    type="text"
                                                                    id="firstName"
                                                                    maxLength={50}
                                                                    className="mb-2 form-control form-control-sm col-lg-12"
                                                                    value={firstName}
                                                                    onChange={firstNameChangehandler}
                                                                    placeholder="First Name"
                                                                    autoFocus
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label
                                                                    htmlFor="lastName"
                                                                    className="mb-2 h6 text-primary col-lg-12"
                                                                >
                                                                    {t("lastName")}
                                                                </label>

                                                                <input
                                                                    type="text"
                                                                    id="lastName"
                                                                    maxLength={50}
                                                                    className="mb-2 form-control form-control-sm col-lg-12"
                                                                    value={lastName}
                                                                    onChange={lastNamechangehandler}
                                                                    placeholder="Last Name"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 col-md-12">
                                                            <button
                                                                className="btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1"
                                                                onClick={onResetHandler}
                                                            >
                                                                {t("reset")}
                                                            </button>
                                                            {checkSecurityDetails(
                                                                "Settings",
                                                                "Delegate Workflow",
                                                                "Settings_Delegate Workflow_Search"
                                                            ) && (
                                                                    <button
                                                                        className="btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1"
                                                                        onClick={onSearchHandler}
                                                                    >
                                                                        {t("search")}
                                                                    </button>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid my-3"></div>
            {userList}
        </Fragment>
    );
};

export default SearchDelegateApprover;

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import {
    HIGHRISKDESTINATION_UPDATE,
    HIGHRISKDESTINATION_VIEW
} from '../../../../navigation/CONSTANTS';

const HighRiskDestinationItem = ({ destination, onDestinationDelete }) => {
    const { t } = useTranslation(['translation.HighRiskDestination']);

    function handleDelete(event) {
        event.preventDefault();
        onDestinationDelete(destination.id);
    }

    return (
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {destination.country}
                                </h6>
                                <hr className="my-1" />
                            </div>
                            <div className="col-md-8">
                                {destination.cities.length > 0 &&
                                    <p className="card-text mb-0"><strong>{t('city')}: </strong>{destination.cities}</p>
                                }
                            </div>                            
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>

                                    {
                                        checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_View") &&
                                        <Link
                                            className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title={t('view')}
                                            to={`${HIGHRISKDESTINATION_VIEW.replace(':Id', destination.id)}`}>
                                            <i className="fa-sharp fa-solid fa-tv"></i>
                                        </Link>
                                    }

                                    {
                                        checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Update") &&
                                        <Link
                                            className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn"
                                            title={t('edit')}
                                            to={`${HIGHRISKDESTINATION_UPDATE.replace(':Id', destination.id)}`}>
                                            <i className="fa-solid fa-pen"></i>
                                        </Link>
                                    }

                                    {
                                        checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Delete") &&
                                        <button
                                            className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white"
                                            title={t('delete')}
                                            onClick={handleDelete}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default HighRiskDestinationItem;
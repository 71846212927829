import { Fragment, useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import { regNumber } from '../../../../config/RegularExpression';
import { LOGIN } from '../../../../navigation/CONSTANTS.js';
import secureLocalStorage from 'react-secure-storage';
import { authenticationResendOTP } from '../../../../services/Authentication-API';
import useHttp from '../../../../services/use-http';

const ValidateForm = (props) => {
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(authenticationResendOTP);

    const InputOTPRef = useRef('');
    const [oTPInputHasError, setOTPInputHasError] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);

    useEffect(() => {
        InputOTPRef.current.focus();
    }, [])
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredInputOTP = InputOTPRef.current.value;

        // optional: Could validate here
        if (enteredInputOTP === '') {
            InputOTPRef.current.focus();
            setOTPInputHasError(true);
            return;
        }

        props.checkOTP({
            id: "",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: secureLocalStorage.getItem("CustomerLoginEmailID"),
            oTP: enteredInputOTP,
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });
    }
    function onBlurCheck(event) {
        const enteredInputsecuritycode = InputOTPRef.current.value;
        // optional: Could validate here
        if (enteredInputsecuritycode === '') {
            setOTPInputHasError(true); return;
        }
        else {
            setOTPInputHasError(false);
        }
        // if value is not blank, then test the regex        
        if (!event.target.value.match(regNumber)) {
            event.target.value = '';
            return;
        }

        if (enteredInputsecuritycode.length === 6)
            submitFormHandler(event);
    }

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowButton(true);
    //     }, 60000);
    //     //return () => clearTimeout(timer);
    // }, []);

    useEffect(() => {
        if (timeLeft > 0) {
            const interval = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setShowButton(true);
        }
    }, [timeLeft]);

    function onResendOTP() {
        const requestData = ({
            id: "",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: secureLocalStorage.getItem("CustomerLoginEmailID"),
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });

        sendRequest(requestData);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    secureLocalStorage.setItem("CustomerLoginEmailID", loadedAuthenticationDetails['data'].emailLoginID)
                    localStorage.setItem('displayName', JSON.stringify(loadedAuthenticationDetails['data'].customerDisplayName));
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') { }
            }
        }

    }, [status, loadedAuthenticationDetails])

    return (
        <Fragment>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    <div className="card-body my-3">
                                        <div className="col-md-9 mx-auto gt-country-details-form my-5 signin-log">
                                            <div className="col-md-12 position-relative">

                                                {props.isLoading && (
                                                    <div className='loading'>
                                                        <LoadingSpinner />
                                                    </div>
                                                )}
                                                <label className="mb-2 h5 text-primary col-lg-12"><i className="fas fa-globe-asia"></i> Confirm your Email ID</label>
                                                <div className="">
                                                    <label className="h6 col-lg-12 text-primary" htmlFor='country'> To continue, please enter the OTP sent to <strong className='text-black'>{secureLocalStorage.getItem("CustomerLoginEmailID")}</strong></label>
                                                    {/*  {props.loadedProfileDetails.setupCode} */}
                                                </div>
                                                {/* <div className="">
                                                    <label className="h6 text-primary col-lg-12" htmlFor='country'>OTP</label>                                                    
                                                </div> */}

                                                <div className="control">
                                                    <input
                                                        maxLength="6" type='text'
                                                        id='InputOTP'
                                                        ref={InputOTPRef}
                                                        onChange={onBlurCheck}
                                                        className="mb-2 form-control form-control-sm col-lg-12"
                                                        placeholder='6-digit code'
                                                    />
                                                    {oTPInputHasError && <p className="error-text">Please specify your OTP.</p>}
                                                    <label className="mb-2 h6 col-lg-12" htmlFor='country'><i className="fa fa-info-circle text-primary"></i> Please check junk mail if it’s not in your inbox.</label>
                                                </div>
                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                        <a href="/Authentication" className='btn gt-btn_md text-white mt-1 gt-main-btn-WithoutAuth-MFA d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">
                                                            <a href="/Authentication" className='btn back-btn-sm text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            {
                                                                showButton === false &&
                                                                <button type="button" className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA mx-1 disabled" style={{ width: "170px" }}> Resend OTP ({timeLeft})</button>
                                                            }
                                                            {
                                                                showButton === true &&
                                                                <button type="button" className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA mx-1" onClick={status === 'pending' ? '' : onResendOTP}> {(status === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : 'Resend OTP'}</button>
                                                            }
                                                            <button type="button" className="btn gt-btn_md text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1" onClick={submitFormHandler}>Login</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3 col-md-12">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>
    );
};
export default ValidateForm;
import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHttp from "../../../../services/use-http";
import { searchUser } from "../../../../services/User-API";
import { getConfigurationByValue } from "../../../../utils/ConfigurationsFunction";
import { sortData } from "../../../../utils";
import DelegatedDilogList from "./DelegatedDilogList";

const SelectDelegatePopupDialog = (props) => {
  const { t } = useTranslation(["translation.Delegate"]);
  const history = useHistory();
  const location = useLocation();
  const [hasRecord, setHasRecord] = useState(true);
  const {
    sendRequest,
    status,
    data: masterApproversResponse,
  } = useHttp(searchUser);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [departmentName, setDearptmentName] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const [masterApprovers, setMasterApprovers] = useState([]);
  const [filteredMasterApprovers, setFilteredMasterApprovers] = useState();
  const [selectedApprovers, setSelectedApprovers] = useState();

  function nameChangeHandler(event) {
    setName(event.target.value.trim());
  }

  function firstNameChangehandler(event) {
    setFirstName(event.target.value.trim());
  }

  function departmentchangehandler(event) {
    setDearptmentName(event.target.value.trim());
  }

  function searchMasterApprovers() {
    const requestInput = {
      firstName: firstName,
      lastName: name,
      roleID: "",
      status: "",
      isActive: true,
      isCorporate: true,
      ApprovalType: "1",
      officeID: JSON.parse(localStorage.getItem("officeID")),
      KeyCode: getConfigurationByValue("APPROVER_TYPE").split("-")[0],
      CorporateMasterName: departmentName,
    };

    sendRequest(requestInput);
  }

  function onSearchHandler(event) {
    event.preventDefault();
    setMasterApprovers(null);
    setFilteredMasterApprovers(null);
    searchMasterApprovers();
  }

  function addApproverToSelectedList(approverUserID) {
    const matchingApprover = masterApprovers.find(
      (mApprover) => mApprover.id === approverUserID
    );

    if (matchingApprover) {
      setSelectedApprovers(matchingApprover);
      props.onAddToSelectedApprovalWorkflow(matchingApprover);
    }
  }

  function filterAndSetMasterApprovers(filterCriteria) {
    let tempMasterApprovers = filterCriteria.masterApprovers || masterApprovers;

    if (tempMasterApprovers && tempMasterApprovers.length) {
        const tempApprovers = tempMasterApprovers
            .filter(approver => approver.id !== props.approvalUserID &&
                (props.delegateUserID ? approver.id !== props.delegateUserID : true));

      if (tempApprovers?.length > 0) {
        sortAndSetFilteredMasterApprovers(tempApprovers);
        setHasRecord(true); // We have records
      } else {
        setFilteredMasterApprovers(null);
        setHasRecord(false); // No records found
      }
    } else {
      setFilteredMasterApprovers(null);
      if (filterCriteria.isFromSearch) {
        setHasRecord(false);
      }
    }
  }

  function sortAndSetFilteredMasterApprovers(tempFilteredMasterApprovers) {
    if (!tempFilteredMasterApprovers) {
      tempFilteredMasterApprovers = [...masterApprovers];
    }

    const sortKey = queryParams.get("sort");
    let isSortingAscending = sortKey ? sortKey === "asc" : true;

    tempFilteredMasterApprovers = sortData(
      tempFilteredMasterApprovers,
      "firstName",
      isSortingAscending
    );
    setFilteredMasterApprovers(tempFilteredMasterApprovers);
  }

  function onSortChange(event) {
    history.push({
      pathname: location.pathname,
      search: `?sort=${event.target.value}`,
    });
    sortAndSetFilteredMasterApprovers();
  }

  useEffect(() => {
    searchMasterApprovers();
  }, []);

  useEffect(() => {
    if (masterApproversResponse) {
      if (
        masterApproversResponse.errors.status === "FALSE" &&
        masterApproversResponse.data &&
        masterApproversResponse.data.length
      ) {
        setMasterApprovers(() => masterApproversResponse.data);
        filterAndSetMasterApprovers({
          masterApprovers: masterApproversResponse.data,
          tempSelectedApprovers: null,
          isFromSearch: true,
        });
      } else {
        setMasterApprovers(null);
        setHasRecord(false);
      }
    }
  }, [masterApproversResponse]);

  return (
    <Fragment>
      <div className="row position-relative">
        <div className="card-body">
          <div className="col-md-12 ">
            <div className="form-group col-md-12">
              {/* Approver Details */}
              <form onSubmit={onSearchHandler}>
                <div className="row">
                  <div className="control col-md-3">
                    <label
                      htmlFor="firstName"
                      className="mb-2 h6 text-primary col-lg-12"
                    >
                      {t("firstName")}
                    </label>
                    <input
                      id="firstName"
                      value={firstName}
                      onChange={firstNameChangehandler}
                      minLength="1"
                      maxLength="100"
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="control col-md-3">
                    <label
                      htmlFor="lastName"
                      className="mb-2 h6 text-primary col-lg-12"
                    >
                      {t("lastName")}
                    </label>
                    <input
                      id="lastName"
                      value={name}
                      onChange={nameChangeHandler}
                      minLength="1"
                      maxLength="100"
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="control col-md-4">
                    <label
                      htmlFor="department"
                      className="mb-2 h6 text-primary col-lg-12"
                    >
                      {t("department")}
                    </label>
                    <input
                      id="department"
                      value={departmentName}
                      onChange={departmentchangehandler}
                      minLength="1"
                      maxLength="100"
                      className="form-control form-control-sm"
                    />
                  </div>

                  <div className="control col-md-2">
                    <label className="mb-2 h6 text-primary col-lg-12"></label>
                    <button
                      id="btnSearch"
                      type="submit"
                      className="btn gt-btn_md text-white gt-main-btn mt-1"
                    >
                      {status === "pending" ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        t("search")
                      )}
                    </button>
                  </div>
                </div>
              </form>

              <div className="row">
                <div className="control mt-2 col-md-12">
                  {!hasRecord && (
                    <p className="error-text">{t("errorMessage")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {((filteredMasterApprovers && filteredMasterApprovers.length > 0) ||
          (selectedApprovers && selectedApprovers.length > 0)) && (
          <DelegatedDilogList
            filteredMasterApprovers={filteredMasterApprovers}
            onAddApproverToSelectedList={addApproverToSelectedList}
            onSortChange={onSortChange}
            handleCloseModal={props.onClose}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SelectDelegatePopupDialog;

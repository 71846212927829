import { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { UPDATETRIPREASON } from '../../../navigation/CONSTANTS';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import useHttp from '../../../services/use-http';
import { searchTripReason } from '../../../services/TripReason-API';
import TripReasonList from './Components/TripReasonList'
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from '../../../components/Common/Error/ErrorModal';

const SearchTripReason = () => {
    useDocumentTitle('Search Trip Type')
    const { t } = useTranslation(['translation.TripReason']);
    const history = useHistory();
    const nameInputRef = useRef('');
    const [isFirstTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);

    const { sendRequest, status, data: loadedTripReason, error: tripReasonError } = useHttp(searchTripReason);
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", "TripReason", "Master_TripReason_Search")) {
            return false;
        }

        setFirstTime(true);

        const onSerachTripReason = ({
            id: "",
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            name: nameInputRef.current.value.trim(),
            code: "",
            meetingID: "",
            isFamilyBooking: true,
            officeID: JSON.parse(localStorage.getItem("officeID")),

        });
        sendRequest(onSerachTripReason)


    }, [sendRequest])
    useEffect(() => {
        if (status === 'completed') {
            if (loadedTripReason['errors'].error['code'] === '1008' || loadedTripReason['errors'].error['code'] === '9001') {
                setError1(true);
                return;
            }
        }
    }, [status, loadedTripReason])
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    if (tripReasonError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }
    let loadedData = [];
    for (let i in loadedTripReason) {
        if (loadedTripReason['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedTripReason[i];
            }
        }
    }
    function onResetHandler(event) {
        history.go('/SearchTripReason');
    }
    function okayHandler() {
        setError1(false);
        history.go('/SearchTripReason');

    }
    const errorHandler = () => {
        history.go('/SearchTripReason');
    };
    const ErrorShow = (status !== 'pending' && (loadedData && loadedData.length === 0)) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''
    const tripReasonList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <TripReasonList tripReason={loadedData} /> : ErrorShow

    return <Fragment>
        {error1 && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Search Trip Type</label>
                                                </div>
                                                <div className='col-md-6'>
                                                </div>
                                            </div>

                                            <div className='row '>
                                                <div className='col-md-12'>
                                                    <div className='control'>
                                                        <div className="form-group">
                                                            <label className='h6 text-primary mb-2'> {t('name')}</label>
                                                            <input type="text" className="form-control bg_input" ref={nameInputRef} autoFocus maxLength={50}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 top_form_search_button mt-3">
                                                <div>
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "TripReason", "Master_TripReason_Insert")
                                                        && <NavLink className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' to={UPDATETRIPREASON}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Master", "TripReason", "Master_TripReason_Search")
                                                        && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {tripReasonList}
    </Fragment>

};

export default SearchTripReason;
import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortData } from "../../../../utils";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import useHttp from "../../../../services/use-http";
import { deleteSinglePolicy } from "../../../../services/Rules-API";
import RulesItem from "./RulesItem"
const RulesList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['translation.Rules']);
    const queryParams = new URLSearchParams(location.search);
    const isSortingAscending = queryParams.get('sort') === 'asc';
    const sortedPolicyWise = sortData(props.policy, 'tripReasonName', isSortingAscending);
    const [error1, setError] = useState();
    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };
    //Code for delete
    const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSinglePolicy, true);
    const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 200,
            behavior: "smooth",
        });

        history.push({
            pathname: '/SearchPolicy',
            search: `?sort=${('asc')}`
        })
        if (status === 'pending') {
            <Fragment>
                <section id='common_banner'><div className='container'></div></section>
                <div className='centered'>
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </Fragment>
        }
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'TRUE') {
                if (loadedQuote['errors'].error['code'] === '1010') {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordInUseDelete')}`,
                    });

                }
                else {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordDelete')}`,
                    });
                }
                setIsErrorOnDelete(true);
            } else {
                setError({
                    title: "Message",
                    message: `${t('recordDelete')}`,
                });


            }
        }

    }, [status, history, t, loadedQuote])
    const deleteItemsHandler = (props) => {

        const onDeletePolicy = (

            {
                "id": props.id,
                "traceID": "",
                "userID": "",
                "createdDate": "2024-04-25T07:49:34.408Z",
                "ipAddress": "",
                "position": [
                    {
                        "positionID": ""
                    }
                ],
                "tripReasonID": "",
                "isFlight": true,
                "isHotel": true,
                "isServiceRequest": true,
                "recommendations": "",
                "highestClassCabin": "",
                "fromRegion": "",
                "toRegion": "",
                "rbdDetails": [
                    {
                        "rbdName": ""
                    }
                ],
                "preferredAirline": [
                    {
                        "preferredAirlineCode": ""
                    }
                ],
                "maxEmployeesAllowedFlight": "",
                "lowestFareRule": "",
                "marginRule": true,
                "marginRuleType": "",
                "cheapestFlightWithLowestFare": "",
                "allowTravelerToUpgradeClass": "",
                "allowToUpgradeClass": "",
                "fastestFilghtType": "",
                "allowFastFlightWithMargin": "",
                "timeBeforeMeetingStartTime": "",
                "timeRange": "",
                "hotelRegion": "",
                "rating": "",
                "lowestRateRule": "",
                "hotelMarginRule": true,
                "hotelMarginRuleType": "",
                "cheapestHotelWithLowestRate": "",
                "preferredHotel": [
                    {
                        "preferredHotelCode": ""
                    }
                ],
                "tripReasonApprovalProcess": "",
                "officeID": ""
            });
        sendRequest(onDeletePolicy);

        if (error) {
            return <p className='centered focused'>{error}</p>
        }
        if ((!loadedQuote || loadedQuote.length === 0)) {
            return <p>{t('errorRecordDelete')}</p>
        }
    };
    const errorHandler = () => {
        setError(null);
        if (!isErrorOnDelete) {
            history.go({
                pathname: '/SearchPolicy'
            })
        }
        else {
            history.push({
                pathname: '/SearchPolicy'
            })
        }
    };
    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <div className="container-fluid mb-3 list-view">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6"></div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">{t('sortBy')}</div>
                        </div>
                        <div className="col-md-4 form-group">
                            <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                                <option>{t('sortTripReasonDesc')}</option>
                                <option>{t('sortTripReasonAsc')}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {sortedPolicyWise.map((item) => (
                <RulesItem
                    onDelete={deleteItemsHandler}
                    key={item.id}
                    id={item.id}
                    positionArray={item.position}
                    tripReasonName={item.tripReasonName}
                    fromRegion={item.fromRegion}
                    toRegion={item.toRegion}
                    isFlight={item.isFlight}
                    isHotel={item.isHotel}
                    isServiceRequest={item.isServiceRequest}
                    hotelRegion={item.hotelRegion}

                />
            ))}
        </div>
    </Fragment>
}

export default RulesList;
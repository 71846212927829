import { useParams } from "react-router-dom";
import { useRef, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import ApproverDialog from '../Components/ApproverDialog';
import SelectedApproverList from '../Components/Component/SelectedApproverList'
import FareRulePopupModal from './PopupModel';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction'
import { listApporverWorkflowType } from '../../../../services/Approver-API'
import useHttp from "../../../../services/use-http";
import ErrorModal from '../../../../components/Common/Error/ErrorModal';
import Dropdown from 'react-select';

const ApproverForm = (props) => {
    const nameInputRef = useRef('');
    const [serviceName, setServiceName] = useState('');
    const parms = useParams();
    const { Id, Action, Name } = parms;
    const pageTitle = "Workflows";
    const { t } = useTranslation(['translation.Approver']);
    const [errorApproverDialog, setErrorApproverDialog] = useState();
    const [error, setError] = useState();
    const [approvalTypeCode, setApprovalTypeCode] = useState();
    const [approvalTypeOptions, setApprovalTypeOptions] = useState([]);
    const { sendRequest: sendRequestApproverWorkflow, status: statusService, data: approvalTypeResponse } = useHttp(listApporverWorkflowType);

    const approverType = getConfigurationByValue("APPROVER_TYPE").split("-")[0];

    let labelName = getConfigurationByValue(approverType);

    function changeLocation(event) {
        window.location.href = `/UpdateApprover/${Id}/1/${Name}`;
    }

    function submitFormHandler(event) {
        event.preventDefault();

        let hasError = false;
        let errorMessage = "";
                
        let costCenterApproverCount = 0;
        let highRiskApproverCount = 0;
        let outOfPolicyApproverCount = 0;
        let standardApproverCount = 0;
        let travelBookerApproverCount = 0;

        //const shouldValidateCostCenterApproval = (approverType === 'FIRST') && getConfigurationByBoolen('ALLOW_COST_CENTER_WORKFLOW');
        //const shouldValidateHighRiskApproval = getConfigurationByBoolen('ALLOW_HIGH_RISK_WORKFLOWK');
        //const shouldValidateOutOfPolicyApproval = getConfigurationByBoolen('ALLOW_OUT_OF_POLICY_WORKFLOW');
        //const shouldValidateTravelBookerApproval = getConfigurationByBoolen('ALLOW_SERVICE_REQUEST');

        let shouldValidateCostCenterApproval = false;
        let shouldValidateHighRiskApproval = false;
        let shouldValidateOutOfPolicyApproval = false;
        let shouldValidateStandardApproval = false;
        let shouldValidateTravelBookerApproval = false;

        approvalTypeOptions.forEach(option => {
            switch (option.value) {
                case '2':
                    shouldValidateStandardApproval = true;
                    break;
                case '3':
                    shouldValidateCostCenterApproval = true;
                    break;
                case '5':
                    shouldValidateHighRiskApproval = true;
                    break;
                case '6':
                    shouldValidateTravelBookerApproval = true;
                    break;
                case '7':
                    shouldValidateOutOfPolicyApproval = true;
                    break;
                default:
                    break;
            }
        });

        if (props.workflowDetails && props.workflowDetails.length) {
            props.workflowDetails.forEach(wfItem => {
                switch (wfItem.approvalTypeID) {
                    case '2':
                        standardApproverCount++;
                        break;
                    case '3':
                        costCenterApproverCount++;
                        break;
                    case '5':
                        highRiskApproverCount++;
                        break;
                    case '6':
                        travelBookerApproverCount++;
                        break;
                    case '7':
                        outOfPolicyApproverCount++;
                        break;
                    default:
                        break;
                }
            });
        }        

        if (shouldValidateCostCenterApproval && costCenterApproverCount === 0) {
            errorMessage = t('errorSpecifyApprovalCostCenter');
            hasError = true;
        }
        else if (shouldValidateHighRiskApproval && highRiskApproverCount === 0) {
            errorMessage = t('errorSpecifyApprovalHighRisk');
            hasError = true;
        }
        else if (shouldValidateOutOfPolicyApproval && outOfPolicyApproverCount === 0) {
            errorMessage = t('errorSpecifyApprovalOutOfPolicy');
            hasError = true;
        }
        else if (shouldValidateStandardApproval && standardApproverCount === 0) {
            errorMessage = t('errorSpecifyApprovalStandard');
            hasError = true;
        }
        else if (shouldValidateTravelBookerApproval && travelBookerApproverCount === 0) {
            errorMessage = t('errorSpecifyApprovalTravelBooker');
            hasError = true;
        }

        if (hasError) {
            setError({
                title: "Message",
                message: errorMessage
            });
        }
        else {
            props.onAddApprover(props.workflowDetails);
        }
    }

    function openApproverPopup(id, name) {

        let approvalLevel = 1;
        if (id === "2" || id === "7") {
            if (props.workflowDetails && props.workflowDetails.length) {
                const matchingWfApprovers = props.workflowDetails.filter(wfItem => wfItem.approvalTypeID === id);
                if (matchingWfApprovers && matchingWfApprovers.length) {
                    approvalLevel = Math.max(...(matchingWfApprovers.map((wfItem) => wfItem.approvalLevel))) + 1;
                }
            }
        }

        const approvalTypeInfo = {
            approvalTypeID: id,
            approvalTypeName: name,
            approvalLevel: approvalLevel,
            serviceTypeID: JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeID,
            serviceTypeName: JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeName
        };

        setErrorApproverDialog({
            title: "Select " + name,
            message: <ApproverDialog
                approvalTypeInfo={approvalTypeInfo}
                errorHandlerApproverDialog={errorHandlerApproverDialog}
                selectedApproversForAllApprovalTypes={props.workflowDetails}
                onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
            >
            </ApproverDialog>,
        });
    }

    function onAddToSelectedApprovalWorkflow(approvers) {
        setErrorApproverDialog(null);
        setApprovalTypeCode('');
        props.onAddToSelectedApprovalWorkflow(approvers);
    }

    function errorHandlerApproverDialog() {
        setErrorApproverDialog(null);
        setApprovalTypeCode('');
    }

    function approvalTypeChangeHandler(event) {
        setApprovalTypeCode(event.value);
        if (event.value !== "") {
            openApproverPopup(event.value, event.label);
        }
    };

    function errorHandler() {
        setError(null);
    }

    function onApproverDelete(approverInfo) {
        props.onApproverDelete(approverInfo);
    }

    useEffect(() => {

        if (approvalTypeResponse) {
            if (approvalTypeResponse.errors.status === 'FALSE') {
                let tempApprovalTypeOptions = [];
                tempApprovalTypeOptions.push(
                    {
                        value: '',
                        label: 'Please Select'
                    }
                );

                if (approvalTypeResponse.data) {
                    approvalTypeResponse.data.forEach((approvalType) => {
                        tempApprovalTypeOptions.push({
                            value: approvalType.approvalTypeID,
                            label: approvalType.approvalType
                        });
                    });
                }

                setApprovalTypeOptions(() => tempApprovalTypeOptions);
            }
        }

    }, [approvalTypeResponse]);

    useEffect(() => {
        
        nameInputRef.current.value = Name;

        if (JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeName !== null) {
            setServiceName(() => JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeName);
        }

        let serviceTypeID = JSON.parse(sessionStorage.getItem("EditApprover")).serviceTypeID;
        if (serviceTypeID === 'ALL') {
            serviceTypeID = '';
        }

        const request = {
            id: "string",
            officeID: JSON.parse(localStorage.getItem("officeID")),
            Type: "1",
            ServiceTypeID: serviceTypeID
        };

        sendRequestApproverWorkflow(request);

    }, []);

    return <Fragment>
        {errorApproverDialog && (
            <FareRulePopupModal
                title={errorApproverDialog.title}
                message={errorApproverDialog.message}
                onConfirm={errorHandlerApproverDialog}
            ></FareRulePopupModal>
        )}
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <form className='form'>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {props.isLoading && (
                                                <div className='loading'>
                                                    <LoadingSpinner />
                                                </div>
                                            )}
                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label id="pageTitle" htmlFor=""
                                                            className="h5 text-primary mb-3 col-lg-12">
                                                            <i className="fas fa-globe-asia"></i> {pageTitle}
                                                        </label>

                                                        {/* Approver Details */}

                                                        <div className='control'>
                                                            <label htmlFor="name"
                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                {labelName}
                                                                <span className="error-icon"> *</span>
                                                            </label>
                                                            <input id="name"
                                                                ref={nameInputRef}
                                                                minLength="1"
                                                                maxLength="100"

                                                                className="form-control form-control-sm"
                                                                readOnly={true}
                                                            />

                                                        </div>
                                                        {serviceName !== '' && <div className='control'>
                                                            <label htmlFor="name"
                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                Service
                                                                <span className="error-icon"> *</span>
                                                            </label>
                                                            <input id="name"
                                                                value={serviceName}
                                                                minLength="1"
                                                                maxLength="100"

                                                                className="form-control form-control-sm"
                                                                readOnly={true}
                                                            />

                                                        </div>}

                                                        {Action === "1" && <div className='control'>
                                                            <label htmlFor="approvalType"
                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                Workflow Type
                                                                <span className="error-icon"> *</span>
                                                            </label>
                                                            <Dropdown onChange={approvalTypeChangeHandler}
                                                                id='approvalType'
                                                                value={approvalTypeOptions.filter(option => option.value === approvalTypeCode)}
                                                                options={approvalTypeOptions}
                                                                textField="currName"
                                                                dataItemKey="currCode"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select"
                                                                isLoading={statusService === 'pending'}
                                                            />


                                                        </div>}

                                                        <div className="row">
                                                            <div className='control col-md-12'>
                                                                {props.workflowDetails && props.workflowDetails.length > 0 &&
                                                                    <SelectedApproverList
                                                                        workflowDetails={props.workflowDetails}
                                                                        onApproverDelete={onApproverDelete}
                                                                        onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow} />
                                                                }
                                                            </div>
                                                        </div>

                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">

                                                                <a href="/SearchApprover"
                                                                    className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'>
                                                                    <span>
                                                                        <i className="fa fa-arrow-left"></i>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchApprover"
                                                                        className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'>
                                                                        <span>
                                                                            <i className="fa fa-arrow-left"></i>
                                                                        </span>
                                                                    </a>

                                                                    {Action === "1" && (checkSecurityDetails("Settings", "Workflow", "Settings_Workflow_Update")) ?
                                                                        <button id='btnSave'
                                                                            onClick={submitFormHandler}
                                                                            className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>
                                                                            {t('Save')}
                                                                        </button> : <span id="btnSave"></span>
                                                                    }

                                                                    {Action === "1" &&
                                                                        <button id='btnReset'
                                                                            onClick={changeLocation}
                                                                            className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>
                                                                            {t('Reset')}
                                                                        </button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </Fragment>
};
export default ApproverForm;
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { sortData } from "../../../../utils";

const UserList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(["translation.Delegate"]);

  const queryParams = new URLSearchParams(location.search);

  const isSortingAscending = queryParams.get("sort") === "asc";

  const sortedUser = sortData(props.user, "firstName", isSortingAscending);
  const changeSortingHandler = () => {
    history.push({
      pathname: location.pathname,
      search: `?sort=${isSortingAscending ? "desc" : "asc"}`,
    });
  };

  useEffect(() => {
    changeSortingHandler();
  }, []);

  const onSelect = (user) => {
    props.setSelectedUser(user);
    props.setIsDelegateActualApprover(false);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div className="container-fluid mb-3 list-view">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
            <div className="row mb-3">
              <div className="col-md-6"></div>
              <div className="col-md-2 my-auto text-muted text-left">
                <div className="float-lg-end float-md-end">{t("sortBy")}</div>
              </div>
              <div className="col-md-4 form-group">
                <select
                  id="sortBy"
                  onChange={changeSortingHandler}
                  className="form-control form-select bg_input"
                >
                  <option>{t("firstNameAsc")}</option>
                  <option>{t("firstNameDsc")}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {sortedUser.map((user) => (
          <div className="row" key={user.id}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
              <div className="card text-dark card-bg-blue mb-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="XXX fw-bold text-primary mt-1">
                        {`${user.title ? user.title : ""} ${user.firstName} ${
                          user.middleName
                        } ${user.lastName} `}
                      </h6>
                      <hr className="my-1" />
                    </div>
                    <div className="col-md-8">
                      <p className="card-text mb-0">
                        <strong>{t("loginEmailID")}: </strong>
                        {user.loginEmailID}
                      </p>
                      <p className="card-text mb-0">
                        <strong>{t("role")}: </strong>
                        {user.roleName}
                      </p>
                    </div>
                    <div className="col-md-4 my-auto">
                      <div className="float-lg-end float-md-end">
                        <button
                          onClick={() => onSelect(user)}
                          className="btn text-white gt-main-btn ms-1 btn-sm mb-2 float-end"
                        >
                          {t("select")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default UserList;

import { Fragment, useState, useEffect } from "react";
import { toTitleCase } from "../../../utils/CommonFunction";
import { hotelPriceCheck } from "../../../services/Hotel-API";
import useHttp from "../../../services/use-http";
import { useHistory } from "react-router-dom";
import { CreateBookingJSON } from "../../../utils/HotelBookingFunction";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { SINGLEPNRBOOKING } from '../../../navigation/CONSTANTS';
import { CreateBookingJSONShoppingCart } from './../../../utils/ShoppingCartFunction';
import { removeHtmlTags } from "../../../utils/CommonFunction";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { calculateRoeAmountAdditional } from '../../../utils/CommonFunction';
import { useLocation } from 'react-router-dom';

const RoomPopup = (props) => {
    const location = useLocation();
    const { sendRequest, status, data: loadedHotelPriceCheck, error } = useHttp(hotelPriceCheck);
    const history = useHistory();
    const [confirmModel, setConfirmModel] = useState();
    const [confirm, setConfirm] = useState('');
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "defaultHotel.png"
    //'https://uat.fareladder.com/assets/logos/hotels/defaultHotel.png'
    let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'));
    let hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));

    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let equivalentAmountValue = calculateRoeAmountAdditional(props.hotelDetailsResponsePrice[0].currencyCode, props.hotelDetailsResponsePrice[0].offeredPrice.priceOffered, false);
    let showRoomImage = [];
    if (props.hotelDetailsResponseTaxes !== undefined && props.hotelDetailsResponseTaxes.length !== 0 && props.hotelDetailsResponseTaxes[0].roomDetails !== undefined && props.hotelDetailsResponseTaxes[0].roomDetails.images !== null && props.hotelDetailsResponseTaxes[0].roomDetails.images !== undefined && props.hotelDetailsResponseTaxes[0].roomDetails.images.length !== 0) {
        props.hotelDetailsResponseTaxes[0].roomDetails.images.forEach(function (item, index) {
            if (index === 0) {
                showRoomImage.push(
                    <div className="carousel-item active">
                        <img src={item.imagePath} className="d-block w-100 slider-height-230" alt="slider-images" />
                        <div className="my-2 text-black h6 text-center">{item.category}</div>

                    </div>
                )
            } else {
                showRoomImage.push(
                    <div className="carousel-item">
                        <img src={item.imagePath} className="d-block w-100 slider-height-230" alt="slider-images" />
                        <div className="my-2 text-black h6 text-center">{item.category}</div>

                    </div>
                )
            }

        })
    }


    const onBookNow = (id) => {
        if (props.roomPriceIssue) {
            setConfirm({
                title: "Message",
                message: "The hotel is unable to confirm room prices. Please select another hotel.",
            });
        }
        else {
            //User Story 1070: Change in Search for Single PNR
            let bookingJSON = "";
            if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                bookingJSON = CreateBookingJSONShoppingCart(props.roomDetails, "HT")
                sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(bookingJSON));
                sessionStorage.removeItem("HotelIntialTotalAmount");
            }
            else {
                bookingJSON = CreateBookingJSON(props.roomDetails)
                if (getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" && localStorage.getItem("ProfileData") !== null) {
                    bookingJSON = updateBookingJSONB2CProfile(bookingJSON);
                }
                sessionStorage.setItem("HotelBookingData", JSON.stringify(bookingJSON));
                sessionStorage.removeItem("HotelIntialTotalAmount");
            }
            sendRequest(bookingJSON);
        }
    }

    useEffect(() => {
        if (status === 'completed') {
            if (loadedHotelPriceCheck !== null && loadedHotelPriceCheck.data !== null) {

                if (loadedHotelPriceCheck.data.errors.status === 'TRUE') {
                    setConfirm({
                        title: "Message",
                        message: loadedHotelPriceCheck.data.errors.error.description,
                    });
                }
                else {
                    let hotelBookingJSON = localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(sessionStorage.getItem("HotelBookingData"));
                    hotelBookingJSON.data.bookingKey = loadedHotelPriceCheck.data.bookingKey;

                    if (loadedHotelPriceCheck.data.hotelResults[0].isPriceChange) {
                        hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                            elementHotelResults.price.forEach(elementPrice => {
                                elementPrice.roomPrice = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.roomPrice;
                                elementPrice.publishedPrice.pricePublished = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished;
                                elementPrice.publishedPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.perNight;
                                elementPrice.offeredPrice.priceOffered = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.priceOffered;
                                elementPrice.offeredPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.perNight;
                            });
                        });

                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
                        setConfirmModel({
                            title: "Message",
                            message: `The room price has changed from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: props.hotelDetailsResponsePrice[0].currencyCode }).format(loadedHotelPriceCheck.data.hotelResults[0].price[0].publishedPrice.pricePublished)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: props.hotelDetailsResponsePrice[0].currencyCode }).format(loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(hotelBookingJSON)) : sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
                        //User Story 1073: Booking Summary for Single PNR
                        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                            history.push(SINGLEPNRBOOKING);
                        }
                        else {
                            history.push(`HotelBookings`);
                        }
                    }

                    //history.go();
                }
            }
            else {
                setConfirm({
                    title: "Message",
                    message: `Sorry, we have an encountered an issue. Please try again.`,
                });
            }
            return;
        }
    }, [status, history, loadedHotelPriceCheck])
    function okHandler() {
        setConfirm('');
    };
    function okConfirmHandler() {
        setConfirmModel(true);
        //User Story 1073: Booking Summary for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push(SINGLEPNRBOOKING);
        }
        else {
            history.push(`HotelBookings`);
        }
    };
    function cancelHandler() {
        setConfirm(false);
        setConfirmModel(false);
    };
    function getMealType(Meals) {
        let showMeal = [];
        if (Meals !== null && Meals.length !== 0) {
            if (Meals[0].mealPlan !== undefined && Meals[0].mealPlan && Meals[0].mealPlanDescription !== undefined && Meals[0].mealPlanDescription !== '') {
                showMeal.push(<p className="card-text text-black">  <span className='fw-bold'>Meal Plan:</span> {Meals[0].mealPlanDescription}</p>)
            }
            if (Meals[0].breakfastIncluded !== undefined && Meals[0].breakfastIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Breakfast Included</p>)
            }
            if (Meals[0].dinnerIncluded !== undefined && Meals[0].dinnerIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Dinner Included</p>)
            }

            if (Meals[0].lunchIncluded !== undefined && Meals[0].lunchIncluded) {
                showMeal.push(<p className="card-text text-black"><i class="fas fa-utensils"></i> Lunch Included</p>)
            }

        }
        return showMeal

    }
    const getFormatedDateAndTime = (dt) => {
        const inputDate = new Date(dt);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate;

    }
    function getRoomCancellationPolicy(text) {
        let show;
        if (text !== "" && text !== undefined) {
            // Remove '\n' from the string

            const removedNewlines = text.replace(/\n/g, ' ');
            const sentences = removedNewlines.split(/(?<=(?<!\d\.)\s*(?<!i\.e\.)\.\s*)(?!\S)/);
            if (sentences.length !== 0) {
                let desc = sentences.map(item => {
                    if (item.trim() !== '.' && item !== '') {
                        return <li className='display-line'><i className="fas fa-circle text-8px text-black py-1"></i><span className="ms-1 text-black"> {removeHtmlTags(item)}</span></li>;
                    }

                });

                show = desc;
            } else {
                show = removeHtmlTags(text);
            }

        }
        return show;
    }

    if (location.pathname === '/RetrieveTrip' || location.pathname === 'RetrieveTrip' || location.pathname === '/TripDetails' || location.pathname === 'TripDetails') {
        equivalentAmountValue = props.hotelDetailsResponsePrice[0].offeredPrice.priceOffered !== undefined ? props.hotelDetailsResponsePrice[0].offeredPrice.equivalentPriceOffered : 0;
    }

    const updateBookingJSONB2CProfile = (bookingJSON) => {
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.NamePrefix = profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.GivenName = profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.MiddleName = profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName;
        bookingJSON.data.TravelerInfo.GuestInfo[0].PersonName.Surname = profileJSON.data.travelerInfo.traveler[0].PersonName.Surname;

        bookingJSON.data.TravelerInfo.GuestInfo[0].Gender = profileJSON.data.travelerInfo.traveler[0].Gender;
        bookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId = profileJSON.data.travelerInfo.traveler[0].email[0].emailId;
        bookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber = profileJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber;

        return bookingJSON;
    }

    return <Fragment>
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        {confirmModel && (
            <ConfirmationModal
                title={confirmModel.title}
                message={confirmModel.message}
                onOk={okConfirmHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {/* User Story 7172: Display room wise images of hotel */}
        <div className="content p-0"><div className='col-lg-12 col-md-12 col-sm-12 mb-3'>

            <div id="carouselExampleFade" className="carousel slide carousel-fade slider-icon-next" data-bs-ride="carousel" data-bs-interval="false">
                <div className="carousel-inner">
                    {showRoomImage.length !== 0 && showRoomImage}
                    {showRoomImage.length === 0 && <img src={hotelLogoLink} className="card-img-top" alt="..." />}
                </div>
                {
                    showRoomImage.length !== 0 && <>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </>
                }

            </div>


            <div className="">
                <div className="">

                    <h5 className="card-title text-primary h5">{props.roomDetails.roomTypeName !== undefined ? toTitleCase(props.roomDetails.roomTypeName) : <span>&nbsp;</span>}</h5>
                    <div className="card-title h6 text-black">{toTitleCase(props.roomDetails.roomDescription)}</div>
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {
                        (props.roomDetails.roomDetails.inclusions !== undefined && props.roomDetails.roomDetails.inclusions !== null && (props.roomDetails.roomDetails.inclusions.filter(a => a.name.toString().includes('Internet')) || props.roomDetails.roomDetails.inclusions.filter(a => a.name.toString().includes('internet')))) &&
                        <p className="card-text text-black"><i className="fa-solid fa-wifi"></i> Free WiFi</p>
                    }
                    {
                        props.roomDetails.roomViewDescription !== undefined &&
                        <p className="card-text text-black"><i className="fa-solid fa-tree-city"></i> {props.roomDetails.roomViewDescription !== undefined ? props.roomDetails.roomViewDescription : ''}</p>
                    }
                    {
                        props.roomDetails.roomDetails.bedTypes !== undefined &&
                        <p className="card-text text-black"><i className="fa-solid fa-bed"></i> {props.roomDetails.roomDetails.bedTypes !== undefined ? props.roomDetails.roomDetails.bedTypes[0].bedTypeDescription : ''}</p>
                    }
                    {
                        props.roomDetails.roomDetails !== undefined && props.roomDetails.roomDetails !== null && props.roomDetails.roomDetails.meals !== undefined && props.roomDetails.roomDetails.meals !== null && getMealType(props.roomDetails.roomDetails.meals)
                    }
                    <br></br>
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {
                        props.roomDetails.roomDetails.amenities !== undefined && props.roomDetails.roomDetails.amenities !== null &&
                        <div>
                            <div className=" card-title text-primary h5 ">Amenities</div>
                            <ul>
                                {props.roomDetails.roomDetails.amenities.map(result => {
                                    return <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black"> {result.name}</span></li>

                                })}
                            </ul>
                        </div>

                    }


                </div>

            </div>
            {/*User Story 3234: Sabre Hotel values that are coming which we are not displaying
*/}
            {props.roomDetails.cancellationPolicies !== undefined && props.roomDetails.cancellationPolicies.length !== 0 && (props.roomDetails.cancellationPolicies[0].absoluteDeadline !== undefined || props.roomDetails.cancellationPolicies[0].description !== undefined || props.roomDetails.cancellationPolicies[0].deadlineDropTime !== undefined) && <div><div className="card-title text-primary h5 mt-3 mb-2"> Cancellation Policy</div>

                <ul>
                    {props.roomDetails.cancellationPolicies[0].absoluteDeadline !== undefined && <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black">
                        Deadline -  {getFormatedDateAndTime(props.roomDetails.cancellationPolicies[0].absoluteDeadline) + "."}
                    </span></li>}
                    {props.roomDetails.cancellationPolicies[0].absoluteDeadline === undefined && props.roomDetails.cancellationPolicies[0].deadlineDropTime !== undefined && props.roomDetails.cancellationPolicies[0].duration !== undefined && props.roomDetails.cancellationPolicies[0].durationType !== undefined && <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black">
                        Deadline -  {props.roomDetails.cancellationPolicies[0].duration + " " + props.roomDetails.cancellationPolicies[0].durationType + " " + props.roomDetails.cancellationPolicies[0].deadlineDropTime}
                    </span></li>}
                    {/* {props.roomDetails.cancellationPolicies[0].duration !== undefined && props.roomDetails.cancellationPolicies[0].durationType !== undefined && <li><i className="fas fa-circle text-8px text-black py-1"></i> <span className="ms-1 text-black">
                        Duration -  {props.roomDetails.cancellationPolicies[0].duration + " " + props.roomDetails.cancellationPolicies[0].durationType}
                    </span></li>} */}

                    {props.roomDetails.cancellationPolicies[0].description !== undefined && getRoomCancellationPolicy(props.roomDetails.cancellationPolicies[0].description)}</ul>
            </div>}


            {props.roomDetails.guarantee !== undefined && props.roomDetails.guarantee.guaranteesAccepted !== undefined && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted !== undefined && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted !== null && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted.length !== 0 && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards !== undefined && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard !== undefined && props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard.length !== 0 && <div>
                <div className="card-title text-primary h5 mt-3 mb-2"> Accepted Cards</div>
                {props.roomDetails.guarantee.guaranteesAccepted.guaranteeAccepted[0].paymentCards.paymentCard.map(element => {


                    if (element.cardCode !== undefined && element.value !== undefined) {
                        return <div className="text-black">  <span><i class="far fa-credit-card"></i> {element.value + " (" + element.cardCode + ")"}</span></div>
                    }

                })}

            </div>}

            <hr className='my-1'></hr>
            <div className="card-title text-primary h5 mt-3 mb-0"> Price</div>
            <div className='col-md-12 '>
                <div className="tour_details_boxed_inner fare-summary-pop">
                    <div className="accordion" id="accordionExample">

                        <div className="tour_details_boxed_inner fare-summary">
                            <div className="" id="accordionExample">
                                <div className="accordion_flex_area">
                                    <div className="accordion-item fare-toggle-btn pb-0">

                                        <div id={"collapselast" + props.roomDetails.roomID} className="accordion-collapse collapse show"
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion_itinerary_list">
                                                    <label className="form-check-label h6 mb-0 " for="flexCheckDefaultf1">
                                                        <span className="area_flex_one ">
                                                            <span>Base Rate</span>
                                                            <span className='h6'>
                                                                {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: props.hotelDetailsResponseTaxes[0].price.currencyCode
                                                                }).format(props.totalBaseRate)}</span>
                                                        </span>
                                                    </label>
                                                    <label className="form-check-label h6 mb-0 " for="flexCheckDefaultf1">
                                                        <span className="area_flex_one ">
                                                            <span>Taxes & Fees</span>
                                                            <span className='h6'>
                                                                {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: props.hotelDetailsResponseTaxes[0].price.currencyCode
                                                                }).format(props.totalTax)}
                                                            </span>
                                                        </span>
                                                    </label>

                                                    <hr className="my-2"></hr>
                                                    <label className="form-check-label text-primary h4 fw-bold " for="flexCheckDefaultf1">
                                                        <span className="area_flex_one ">
                                                            <span className=" text-primary h4 fw-bold mb-0">Total Rate</span>
                                                            <span className='h4 text-primary fw-bold mb-0'>
                                                                {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: props.hotelDetailsResponsePrice[0].currencyCode
                                                                }).format(props.hotelDetailsResponsePrice[0].offeredPrice.priceOffered)}
                                                            </span>
                                                        </span>
                                                    </label>
                                                    {
                                                        props.hotelDetailsResponsePrice[0].currencyCode !== undefined && props.hotelDetailsResponsePrice[0].currencyCode !== '' && props.hotelDetailsResponsePrice[0].currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                                        <div className="row"><div className="col-lg-4 col-md-4 col-sm-4 col-4"><div className="h5 mb-1 fw-bold text-primary">Eq. Total Rate </div></div><div className="col-lg-8 col-md-8 col-sm-8 col-8"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                                                            {new Intl.NumberFormat('en-US', {
                                                                minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                style: 'currency',
                                                                currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                            }).format(parseFloat(equivalentAmountValue))}</div></div></div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='text-8px ms-3 text-end'>
                            <p>Includes taxes</p>
                        </div>
                    </div>
                    <div className='col-md-12 my-2 '>
                        {checkSecurityDetails("Book", "Hotel", "Book_Hotel_Select") && <button className=" float-end btn btn_theme btn_sm" onClick={() =>
                            onBookNow()}>
                            {status === 'pending' ?
                                <i className="fas fa-spinner fa-spin" ></i>
                                : hotelSearchRequestJson.Data.NoOfRooms > 1 ? `Reserve ${hotelSearchRequestJson.Data.NoOfRooms} rooms` : 'Reserve'} </button>}
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Fragment>

};
export default RoomPopup;

import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchAirport } from '../../../services/Airport-API'
import AirportList from './Components/AirportList'
import { NavLink, useHistory } from 'react-router-dom';
import { NEWAIRPORT } from '../../../navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../components/Common/DropDownList/CountryDropDownList';
import Dropdown from 'react-select';
import { SEARCH_CITY } from '../../../services/CONSTANTS'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
const AllAirport = () => {
    useDocumentTitle('Search Airport')

    const [enteredAirportCode, setEnteredAirportCode] = useState("");
    const [enteredAirportName, setEnteredAirportName] = useState("");
    const [country_Code, setCountry_Code] = useState('');
    const [isFirtTime, setFirstTime] = useState("");
    const [cityCode, setCityCode] = useState('');
    const [cityList, setCityList] = useState([]);

    const { t } = useTranslation(['translation.Airport']);
    const history = useHistory();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    let cityOptions = [];

    const airportCodeChangeHandler = (event) => {
        setEnteredAirportCode(event.target.value);
    };
    const airportNameChangeHandler = (event) => {
        setEnteredAirportName(event.target.value);
    };

    const countryCodeValue = (country_Code) => {
        setCountry_Code(country_Code);
        setCityCode('');
        setCityList(cityOptions);

        const onSearchCity = ({
            countryCode: country_Code,
            cityCode: "",
            cityName: ""
        });

        fetch(SEARCH_CITY(), {
            method: 'POST',
            body: JSON.stringify(onSearchCity),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.data.length > 0) {
                    let loadedDataCity = [];
                    for (let i in responseJson) {
                        if (responseJson['errors'].status === 'FALSE') {
                            if (i === 'data') {
                                loadedDataCity = responseJson[i];
                            }
                        }
                    }

                    for (let index = 0; index < loadedDataCity.length; index++) {
                        cityOptions = [...cityOptions, { value: loadedDataCity[index].cityCode, label: loadedDataCity[index].cityName + "  (" + loadedDataCity[index].cityCode + ")" }]
                    }

                    setCityList(cityOptions);
                }
                else {
                    setCityCode('');
                    setCityList(cityOptions);
                }
            });
    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };
    const cityChangeHandler = (event) => {
        setCityCode(event.value);
    };

    const { sendRequest, status, data: loadedAirport, error } = useHttp(searchAirport);
    const onResetHandler = (event) => {
        // event.preventDefault();
        // setEnterCode('');
        // setEnterName('');
        // setCountry_Code('');
        history.push('/allairport');
        history.go();
    }

    const enteredAirportCodeInputRef = useRef('');
    const [enteredAirportCodeLengthInputHasError, setEnteredAirportCodeLengthInputHasError] = useState(false);
    const [enteredAirportCodeInvalidInputError, setEnteredAirportCodeInvalidInputError] = useState(false);

    const enteredAirportNameInputRef = useRef('');
    const [enteredAirportNameInvalidInputError, setEnteredAirportNameInvalidInputError] = useState(false);

    function onBlurCheck() {
        if (enteredAirportCode.length !== 0) {
            if (enteredAirportCode.length !== 3) {
                setEnteredAirportCodeLengthInputHasError(true);
                setEnteredAirportCodeInvalidInputError(false);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredAirportCodeLengthInputHasError(false);
            }

            if (!enteredAirportCode.match('^[a-zA-Z]+$')) {
                setEnteredAirportCodeInvalidInputError(true);
                setEnteredAirportCodeLengthInputHasError(false);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredAirportCodeInvalidInputError(false);
            }
        }
        else {
            setEnteredAirportCodeLengthInputHasError(false);
            setEnteredAirportCodeInvalidInputError(false);
        }

        if (enteredAirportName.length !== 0) {
            if (!enteredAirportName.match(/^[a-zA-Z-\-\\ .']+$/)) {
                setEnteredAirportNameInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredAirportNameInvalidInputError(false);
            }
        }
        else {
            setEnteredAirportNameInvalidInputError(false);
        }
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", "Airport", "Master_Airport_Search")) {
            return false;
        }

        setFirstTime(true);

        if (enteredAirportCode.length !== 0) {
            if (enteredAirportCode.length !== 3) {
                enteredAirportCodeInputRef.current.focus();
                setEnteredAirportCodeLengthInputHasError(true);
                return false;
            }
            else {
                setEnteredAirportCodeLengthInputHasError(false);
            }

            if (!enteredAirportCode.match('^[a-zA-Z]+$')) {
                enteredAirportCodeInputRef.current.focus();
                setEnteredAirportCodeInvalidInputError(true);
                return;
            }
            else {
                setEnteredAirportCodeInvalidInputError(false);
            }
        }
        else {
            setEnteredAirportCodeLengthInputHasError(false);
            setEnteredAirportCodeInvalidInputError(false);
        }

        if (enteredAirportName.length !== 0) {
            if (!enteredAirportName.match(/^[a-zA-Z-\-\\ .']+$/)) {
                enteredAirportNameInputRef.current.focus();
                setEnteredAirportNameInvalidInputError(true);
                return;
            }
            else {
                setEnteredAirportNameInvalidInputError(false);
            }
        }
        else {
            setEnteredAirportNameInvalidInputError(false);
        }

        const onSearchAirport = ({
            countryCode: country_Code,
            cityCode: cityCode,
            airportCode: enteredAirportCode,
            airportName: enteredAirportName
        });

        if (enteredAirportCodeLengthInputHasError === false && enteredAirportCodeInvalidInputError === false && enteredAirportNameInvalidInputError === false) {
            setIsSubmitClicked(true);
            sendRequest(onSearchAirport);
        }

    }, [cityCode, country_Code, enteredAirportCode, enteredAirportName, sendRequest,
        enteredAirportCodeLengthInputHasError,
        enteredAirportCodeInvalidInputError,
        enteredAirportNameInvalidInputError]);

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        // setEnteredAirportCodeLengthInputHasError(false);
        // setEnteredAirportCodeInvalidInputError(false);
        // setEnteredAirportNameInvalidInputError(false);
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }
    if (error) {
        history.push('/Error')
        //return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    for (let i in loadedAirport) {
        if (loadedAirport['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedAirport[i];
            }
        }
    }

    function changeToUpperCase(event) {
        event.target.value = event.target.value.toUpperCase();
    }

    // const airportList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
    //     <AirportList airport={loadedData} /> : <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>

    const errorHandler = () => {

        history.go('/allairport');
    };

    const ErrorShow = (!enteredAirportCodeLengthInputHasError && !enteredAirportCodeInvalidInputError && isSubmitClicked && enteredAirportNameInvalidInputError === false) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const airportList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <AirportList airport={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <LoadingSpinner></LoadingSpinner>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Airport</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='Country'>{t('country')}</label>
                                                            <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={country_Code} iSDisabled={false} focus={true} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>City</label>
                                                            <Dropdown onChange={cityChangeHandler}
                                                                value={cityList.filter(option => option.value === cityCode)}
                                                                options={cityList}
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='airportcode'>{t('airportCode')}</label>
                                                            <input maxLength="3" type='text' className="mb-0 form-control form-control-sm col-lg-12" id='airportcode' value={enteredAirportCode} onChange={airportCodeChangeHandler} onInput={changeToUpperCase} ref={enteredAirportCodeInputRef} onBlur={onBlurCheck} />
                                                            {enteredAirportCodeLengthInputHasError &&
                                                                <p className="error-text">{t('validationAirportCodeLength')}</p>
                                                            }
                                                            {enteredAirportCodeInvalidInputError &&
                                                                <p className="error-text">{t('validationAirportCodeInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-9 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='airportname'>{t('airportName')}</label>
                                                            <input maxLength="100" type='text' className="mb-0 form-control form-control-sm col-lg-12" id='airportname' value={enteredAirportName} onChange={airportNameChangeHandler} ref={enteredAirportNameInputRef} onBlur={onBlurCheck} />
                                                            {enteredAirportNameInvalidInputError &&
                                                                <p className="error-text">{t('validationAirportCodeInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='mt-3 col-md-12 actions'>

                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>

                                                    {checkSecurityDetails("Master", "Airport", "Master_Airport_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={NEWAIRPORT}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Master", "Airport", "Master_Airport_Search") &&
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>}

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {airportList}
        </Fragment>)
};

export default AllAirport

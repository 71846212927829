import { Fragment, useEffect, useState, useContext } from 'react';
import EnableTwoFactorForm from '../Components/EnableTwoFactorForm.js'
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'

import { generateBarCode, checkAuthentication } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
// import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useHistory } from 'react-router-dom';
import { LOADING } from '../../../navigation/CONSTANTS';
import AuthContext from '../../../redux/contexts/auth-context'
import { getCustomizeDetails } from '../../../utils/CustomizeUtils'

const EnableTwoFactor = () => {
    useDocumentTitle('MFA')
    const { sendRequest: sendRequest1, data: loadedProfileDetails, error } = useHttp(generateBarCode);
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(checkAuthentication);
    const history = useHistory();
    const [errors, setErrors] = useState();
    const authCtx = useContext(AuthContext);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];

                        const expirationTime = new Date(
                            new Date().getTime() + +loadedData.expireIn * 1000
                        );
                        sessionStorage.removeItem("walletAmount");

                        authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                            loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                            loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                            loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                            loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                            loadedData.defaultCity, loadedData.isCorporate, loadedData.isTravelAgent,
                            loadedData.email, loadedData.phoneCode, loadedData.phoneNumber, loadedData);
                        sessionStorage.setItem("LoaderHeader", "1");
                        window.location.pathname = `${LOADING}`;
                        //window.location.pathname = `${DASHBOARD}`; 
                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct authenticator code.',
                    });
                    document.getElementById('Inputsecuritycode').value = '';
                }
            }
        }
    }, [status, loadedAuthenticationDetails, authCtx, history])

    useEffect(() => {
        const onEnableTwoFactor = ({
            loginEmailID: localStorage.getItem('loginEmailIDLogin')
        });


        if (localStorage.getItem('loginEmailIDLogin') !== undefined) {
            sendRequest1(onEnableTwoFactor)
        }
    }, [sendRequest1])

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{error}</div>
    }

    let loadedData = [];
    for (let i in loadedProfileDetails) {
        if (loadedProfileDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedProfileDetails[i];
            }
        }
    }

    const onValidated2FAHandler = (requestData) => {

        sendRequest(requestData);
    };

    const errorHandler = () => {
        setErrors(null);
    };
    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            <div className='position-relative bg-sky-blue'>

                <img src={getCustomizeDetails("companyLogo")} alt="" className='log-in-head-logo-bg position-absolute' />

            </div>
            <section id="external_common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <EnableTwoFactorForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onValidated2FA={onValidated2FAHandler} />

        </Fragment>)
};
export default EnableTwoFactor;

import { Fragment} from 'react';

const HomePageHotel = () => {
    return <Fragment>
        {/* /* <!-- Hotel Offer Area -->     /* <!-- imagination Area --> */ } 
            <section id="offer_area" className="section_padding_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="offer_area_box  img_animation">
                                <img src="assets/img/offer/hotel33.png" alt="img"/>
                                <div className="offer_area_content">
                                    <h2 className="">Exclusive Hotel Benefits and Rates for Employees!</h2>
                                    <p>Enjoy exclusive access to special hotel rates and benefits, available only to employees. Choose from over 60 million rooms across the globe, including top-rated hotels, resorts, and boutique properties. Whether you are traveling for business or leisure, you can book your stay at the best rates and enjoy additional perks along the way.</p>
                                    {/* <a href="#" className="btn btn_theme btn_md">Explore More <i className="fas fa-long-arrow-alt-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 

            <section id="promotional_tours" class="section_padding_top pb-5 ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="section_heading_center">
                                <h2>Go beyond your <span>imagination</span></h2>
                                <h5 className='mt-2'>Discover your ideal experience with us</h5>
                            </div>
                            <div className='row gt-landing-boxes'>
                            <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/99.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i>London</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Maybourne Hotel </a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 4.6/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/88.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i>Dubai</p>
                                          
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);"> Atlantis, The Palm</a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 5/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/11.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i> Thailand</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Rosewood Phuket</a></h4>
                                            <p className='pb-0'><span class="review_rating">Rating 4.4/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/22.png" alt="img"/></a>
                                            <p className="pb-0"><i class="fas fa-map-marker-alt"></i>Kualalampur</p>
                                          
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Hotel Paradise International</a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 4.5/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/33.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i>New Zealand</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Grand Suit </a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 4.8/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/44.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i>Long island</p>
                                          
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Zefi resort and spa</a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 4.5/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/55.png" alt="img"/></a>
                                            <p><i class="fas fa-map-marker-alt"></i> Paris</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">The Peninsula Paris</a></h4>
                                            <p className='pb-0'><span class="review_rating">Rating 5/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <a href="javascript:void(0);"><img src="assets/img/tab-img/66.png" alt="img"/></a>
                                            <p className="pb-0"><i class="fas fa-map-marker-alt"></i>London</p>
                                          
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><a href="javascript:void(0);">Athena</a></h4>
                                            <p className="pb-0"><span class="review_rating">Rating 4.4/5 </span> <span class="review_count"></span></p>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="row d-none">
                        <div class="col-lg-12">
                            <div class="promotional_tour_slider owl-theme owl-carousel dot_style ">
                               <div class="theme_common_box_two img_hover">
                                <div class="theme_two_box_img">
                                    <a href="javascript:void(0);"><img src="assets/img/tab-img/44.png" alt="img"/></a>
                                    <p><i class="fas fa-map-marker-alt"></i>Mariana Island</p>
                                    
                                </div>
                                <div class="theme_two_box_content">
                                    <h4><a href="javascript:void(0);">Luxury Villas</a></h4>
                                    <p className="pb-0"><span class="review_rating">Rating 4.8/5 </span> <span class="review_count"></span></p>
                                    <h3></h3>
                                </div>
                            </div>
                                <div class="theme_common_box_two img_hover">
                                    <div class="theme_two_box_img">
                                        <a href="javascript:void(0);"><img src="assets/img/tab-img/55.png" alt="img"/></a>
                                        <p><i class="fas fa-map-marker-alt"></i>Beach view</p>
                                    </div>
                                    <div class="theme_two_box_content">
                                        <h4><a href="javascript:void(0);"> Grand suit</a></h4>
                                        <p><span class="review_rating">Rating 4.8/5 </span> <span class="review_count">(1214
                                                reviewes)</span></p>
                                        <h3></h3>
                                    </div>
                                </div>
                                <div class="theme_common_box_two img_hover">
                                    <div class="theme_two_box_img">
                                        <a href="javascript:void(0);"><img src="assets/img/tab-img/66.png" alt="img"/></a>
                                        <p><i class="fas fa-map-marker-alt"></i>Long island</p>
                                    </div>
                                    <div class="theme_two_box_content">
                                        <h4><a href="javascript:void(0);">Zefi resort and spa</a></h4>
                                        <p><span class="review_rating">Rating 4.8/5 </span> <span class="review_count">(1214
                                                reviewes)</span></p>
                                        <h3></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="go_beyond_area" className="section_padding_top d-none">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="heading_left_area">
                                <h2>Go beyond your <span>imagination</span></h2>
                                <h5>Discover your ideal experience with us</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <a href="top-destinations.html">
                                    <img src="assets/img/imagination/2.png" alt="img"/>
                                </a>
                                    <h3 className='pb-2'><a href="top-destinations.html">Delux resorts<span></span></a></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <a href="top-destinations.html">
                                    <img src="assets/img/imagination/1.png" alt="img"/>
                                </a>
                                    <h3 className='pb-2'><a href="#!">Travel around<span> the world</span></a></h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="imagination_boxed">
                                <a href="top-destinations.html">
                                    <img src="assets/img/imagination/4.png" alt="img"/>
                                </a>
                                    <h3 className='pb-2'><a href="top-destinations.html">Luxury resorts<span> top deals</span></a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
         
        

    </Fragment>

};

export default HomePageHotel;
import { Fragment, useEffect } from 'react';
import { getFormatDate } from '../../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';

const WhoIsWhereItem = (props) => {
    //const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    function openRetrieveBooking(event, id) {
        event.preventDefault();
        //sendRequest(id);
    }

    //useEffect(() => {
    //    if (status === 'completed') {

    //        if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
    //            let currentStatus = loadedRetrieveTripDetails.data.airItinerary[0] !== undefined &&
    //                loadedRetrieveTripDetails.data.airItinerary[0].isCancelled !== undefined ?
    //                loadedRetrieveTripDetails.data.airItinerary[0].isCancelled : false;

    //            localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
    //            sessionStorage.setItem("myTripIsCanceled", currentStatus);

    //            window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.whoIsWhereInfo.confirmationNumber);
    //        }
    //    }
    //}, [status, loadedRetrieveTripDetails])

    return <Fragment>
        <tr>
            <td align={'center'}>{props.whoIsWhereInfo.slNo}</td>
            <td>
                <a href="/$"
                    className='cursor-pointer d-flex'
                    onClick={(event) => openRetrieveBooking(event, props.whoIsWhereInfo.confirmationNumber)}>
                    <div className='min-width-75'>{props.whoIsWhereInfo.confirmationNumber} &nbsp;</div>
                    <i className="fas fa-external-link-alt line-height-22px"></i>
                </a>
            </td>
            <td>{props.whoIsWhereInfo.companyName}</td>
            <td>{props.whoIsWhereInfo.passengerName}</td>
            <td align={'center'}>{props.whoIsWhereInfo.passengerCount}</td>
            <td>{getFormatDate(props.whoIsWhereInfo.departureDate)}</td>
            <td>{props.whoIsWhereInfo.airline}</td>
            <td>{props.whoIsWhereInfo.toName}</td>
        </tr>    

    </Fragment>

};

export default WhoIsWhereItem;
import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from './MdModal';
import { getFormatDate, getFormatTime, getMinutetoHrsMin } from './../../utils/CommonFunction';
import { getAirlineName, getBaggageAllowance, getStops } from '../../utils/SendRecommendation';

const FlightRecommendations = () => {
    useDocumentTitle('Flight Recommendation');
    const [error1, setError] = useState();
    let flightList = [];
    let firstList = [];
    let secondList = [];

    if (sessionStorage.getItem("flightListJson") !== null) {
        flightList = JSON.parse(sessionStorage.getItem("flightListJson"));
    }
    if (flightList.length !== 0) {
        if (flightList[0].directionInd === 'R') {
            flightList.forEach((element, index) => {
                if (index < 3) {
                    firstList.push(element);
                } else {
                    secondList.push(element);
                }

            });

        } else {
            firstList = flightList;
        }

    }

    const getRefundableType = (element) => {
        let showRefundType = [];
        let refundType = element.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType !== undefined ? element.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType : "";
        if (refundType !== undefined && refundType !== '') {
            if (refundType === 1) {
                showRefundType.push(
                    <div className="text-12px  ms-3 mt-1" style={{ fontSize: '14px', color: '#5ddb1e' }}><i className="fas fa-money-check-alt"></i> Fully<br></br>Refundable</div>
                );
            }
            if (refundType === 2) {
                showRefundType.push(<div className="text-12px text-sky-blue ms-3 mt-1" style={{ fontSize: '14px', color: '#38c1f5' }}><i className="fas fa-money-check-alt"></i> Partially<br></br>Refundable</div>);
            }
            if (refundType === 0) {
                showRefundType.push(
                    <div className="text-12px text-danger ms-3 mt-1" style={{ fontSize: '14px' }}><i className="fas fa-money-check-alt"></i> Non<br></br>Refundable</div>
                );
            }

        }
        return showRefundType.length !== 0 ? showRefundType : "";
    }


    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;
    //const airlineLogoLink = "https://dev.fareladder.com/assets/logos/flights/"

    const errorHandler = () => {
        setError(null);
    };



    const showFlightOptions = (item, i) => {
        const showOptions = [];
        if (item.length === 0)
            return;

        item.forEach((element, index) => {
            let sourceId = element.sourceId !== undefined ? element.sourceId : "";
            const outBound = [];
            const inBound = [];
            element.airItinerary.originDestinationOptions.originDestinationOption.forEach((element1, index1) => {

                if (element1.directionInd === 'O')
                    outBound.push(element1);
                if (element1.directionInd === 'I')
                    inBound.push(element1)
            });
            let bottomMargin = '0rem';
            if (element.directionInd !== 'R') {
                if (item.length > 3) {
                    bottomMargin = '1rem';
                }
                if (i > 2) {
                    bottomMargin = '0rem'
                }

            }

            showOptions.push(
                <td class='col-lg-4 col-md-6 col-sm-4 px-1 mb-1' id={'card' + i}>
                    <div class="card avc" style={{ border: '2px solid #cdcdcd' }}>
                        <div class="card-header bg-main-color text-light fw-bold" style={{ background: '#38c1f5' }}>
                            {"Option " + parseInt(i + 1)}
                        </div>
                        <div class="card-body">

                            <div class='row'>
                                <div class='col-md-12'>
                                    <div class="flight_logo">

                                        {getAirlineName(outBound) !== "Multi Airlines" ? <img src={airlineLogoLink + outBound[0].flightSegment[0].marketingAirline.airlineLogo} alt="img" class=" p-1 gt-h30" style={{ height: '30px' }} /> : <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" class=" p-1 gt-h30" style={{ height: '30px' }} />}
                                        <span class="text-14px text-black fw-bold gt-w-75px" style={{ fontSize: '14px' }}>{getAirlineName(outBound)} </span>
                                        <span class="text-muted text-14px ms-2" style={{ fontSize: '14px' }}> {outBound[0].flightSegment[0].marketingAirline.code + " " + outBound[0].flightSegment[0].marketingAirline.flightNumber}<br />{outBound[0].flightSegment[0].equipment[0].name === undefined ? <div><br></br></div> : outBound[0].flightSegment[0].equipment[0].name}</span>

                                    </div>
                                </div>
                                <hr class='my-1'></hr>
                                <div class='col-md-12'>
                                    <div class='row'>
                                        <div class='col-md-12 text-center'>
                                            <div class=' text-primary h5 my-1' style={{ color: "#12688B !important" }}>
                                                {outBound[0].flightSegment[0].departureAirport.cityName}
                                            </div>
                                            <div class='text-14px' style={{ fontSize: '16px' }}>{outBound[0].flightSegment[0].departureAirport.locationCode + "- " + outBound[0].flightSegment[0].departureAirport.locationName + ", " + outBound[0].flightSegment[0].departureAirport.countryName + ", " + outBound[0].flightSegment[0].departureAirport.countryCode}</div>
                                            <div class='h6 my-1 fw-bold'>{getFormatDate(outBound[0].flightSegment[0].departureDate) + ", " + getFormatTime(outBound[0].flightSegment[0].departureTime)}</div>
                                        </div>
                                        <div class='col-md-12 px-0 my-2'>
                                            <div class="text-center text-12px" >
                                                <div class="recomendation-flight-icon"></div>
                                                <div class='mt-2 fw-bold ' style={{ fontSize: '14px', color: '#590048' }}>{getMinutetoHrsMin(element.airItinerary.totalOutBoundDuration)}{" (" + getStops(outBound) + ")"}</div>

                                            </div>
                                        </div>

                                        <div class='col-md-12 text-center'>
                                            <div class=' text-primary h5 my-1' style={{ color: "#12688B !important" }}>
                                                {outBound[outBound.length - 1].flightSegment[0].arrivalAirport.cityName}
                                            </div>
                                            <div class='text-14px' style={{ fontSize: '16px' }}>{outBound[outBound.length - 1].flightSegment[0].arrivalAirport.locationCode + " - " + outBound[outBound.length - 1].flightSegment[0].arrivalAirport.locationName + ", " + outBound[outBound.length - 1].flightSegment[0].arrivalAirport.countryName + ", " + outBound[outBound.length - 1].flightSegment[0].arrivalAirport.countryCode}</div>
                                            <div class='h6 my-1 fw-bold'>{getFormatDate(outBound[outBound.length - 1].flightSegment[0].arrivalDate) + ", " + getFormatTime(outBound[outBound.length - 1].flightSegment[0].arrivalTime)}</div>
                                        </div>
                                    </div>

                                    {outBound[0].segmentRefDetail[0].pax !== undefined && getBaggageAllowance(outBound[0].segmentRefDetail[0].pax,sourceId) !== '' && <div class='col-md-12 text-14px mt-2 text-center' style={{ fontSize: '14px', height: '40px' }}><span class='fw-bold text-primary' style={{ color: "#12688B !important" }}>Check-in Baggage <br></br></span> <span style={{ fontSize: '15px' }}>{outBound[0].segmentRefDetail[0].pax !== undefined && getBaggageAllowance(outBound[0].segmentRefDetail[0].pax,sourceId)}</span></div>}
                                    <div class='text-center mb-3'>
                                        <span class="text-14px badge rounded-pill class-purple-bg  mt-3" style={{ fontSize: '14px', background: '#590248' }}>{outBound[0].flightSegment[0].bookingClassAvails[0].cabinName}</span>
                                    </div>
                                    {inBound.length !== 0 && <div>
                                        <hr class='my-1'></hr>
                                        <div class='col-md-12'>
                                            <div class="flight_logo">
                                                {getAirlineName(inBound) !== "Multi Airlines" ? <img src={airlineLogoLink + inBound[0].flightSegment[0].marketingAirline.airlineLogo} alt="img" class=" p-1 gt-h30" style={{ height: '30px' }} /> : <img src="https://dev.fareladder.com/assets/logos/flights/default-MultAirline.png" alt="img" class=" p-1 gt-h30" style={{ height: '30px' }} />}
                                                <span class="text-14px text-black fw-bold gt-w-75px" style={{ fontSize: '14px' }}>{getAirlineName(inBound)} </span>
                                                <span class="text-muted text-14px ms-2" style={{ fontSize: '14px' }}> {inBound[0].flightSegment[0].marketingAirline.code + " " + inBound[0].flightSegment[0].marketingAirline.flightNumber}<br />{inBound[0].flightSegment[0].equipment[0].name !== undefined ? inBound[0].flightSegment[0].equipment[0].name : <div><br></br></div>}</span>
                                            </div>
                                        </div>

                                        <hr class='my-1'></hr>
                                        <div class='row'>
                                            <div class='col-md-12 text-center'>
                                                <div class=' text-primary h5 my-1' style={{ color: "#12688B !important" }}>
                                                    {inBound[0].flightSegment[0].departureAirport.cityName}
                                                </div>
                                                <div class='text-14px' style={{ fontSize: '16px' }}>{inBound[0].flightSegment[0].departureAirport.locationCode + " - " + inBound[0].flightSegment[0].departureAirport.locationName + ", " + inBound[0].flightSegment[0].departureAirport.countryName + ", " + inBound[0].flightSegment[0].departureAirport.countryCode}</div>
                                                <div class='h6 my-1 fw-bold'>{getFormatDate(inBound[0].flightSegment[0].departureDate) + ", " + getFormatTime(inBound[0].flightSegment[0].departureTime)}</div>
                                            </div>
                                            <div class='col-md-12 px-0 my-2'>
                                                <div class="text-center text-12px">
                                                    <div class="recomendation-flight-icon gt-return-flight"></div>
                                                    <div class='mt-2 fw-bold ' style={{ fontSize: '14px', color: '#590048' }}>{getMinutetoHrsMin(element.airItinerary.totalInBoundDuration)}{" (" + getStops(inBound) + ")"}</div>

                                                </div>
                                            </div>

                                            <div class='col-md-12 text-center'>
                                                <div class=' text-primary h5 my-1' style={{ color: "#12688B !important" }}>
                                                    {inBound[inBound.length - 1].flightSegment[0].arrivalAirport.cityName}
                                                </div>
                                                <div class='text-14px' style={{ fontSize: '16px' }}>{inBound[inBound.length - 1].flightSegment[0].arrivalAirport.locationCode + " - " + inBound[inBound.length - 1].flightSegment[0].arrivalAirport.locationName + ", " + inBound[inBound.length - 1].flightSegment[0].arrivalAirport.countryName + ", " + inBound[inBound.length - 1].flightSegment[0].arrivalAirport.countryCode}</div>
                                                <div class='h6 my-1 fw-bold'>{getFormatDate(inBound[inBound.length - 1].flightSegment[0].arrivalDate) + ", " + getFormatTime(inBound[inBound.length - 1].flightSegment[0].arrivalTime)}</div>
                                            </div>
                                        </div>
                                        {inBound[0].segmentRefDetail[0].pax !== undefined && getBaggageAllowance(inBound[0].segmentRefDetail[0].pax,sourceId) !== '' && <div class='col-md-12 text-14px mt-2 text-center' style={{ fontSize: '14px' }}><span class='fw-bold text-primary' style={{ color: "#12688B !important" }}>Check-in Baggage <br></br></span> <span style={{ fontSize: '15px' }}>{inBound[0].segmentRefDetail[0].pax !== undefined && getBaggageAllowance(inBound[0].segmentRefDetail[0].pax,sourceId)}</span></div>}
                                        <div class='text-center'>

                                            <span class="text-14px badge rounded-pill class-purple-bg  mt-2" style={{ fontSize: '14px', background: '#590248' }}>{inBound[0].flightSegment[0].bookingClassAvails[0].cabinName}</span>

                                        </div>
                                    </div>}


                                </div>
                            </div>
                        </div>
                        <hr class='my-1'></hr>
                        <div class=''>
                            <div class='row  mb-2'>
                                <div class='col-md-4 '>
                                    {getRefundableType(element)}
                                </div>
                                <div class='col-md-8 '>
                                    <div class='text-primary text-end fw-bold pt-1 mb-0 me-3' style={{ color: "#12688B !important", fontSize: '20px' }} >
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: element.airItineraryPricingInfo.totalFare.currencyCode
                                        }).format(element.airItineraryPricingInfo.totalFare.amount)}
                                    </div>
                                    <p class='text-14px text-end me-3  ' style={{ fontSize: '14px' }}>Includes taxes</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </td>
            )
            i++;
        });
        return showOptions;

    }
    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}

        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">

                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" class="my-2">
            <div class="container">
                <div class="col-md-12 px-1">
                    {firstList.length !== 0 && <table align="center" width="100%" bgcolor='' >
                        {/* This case will run when trip type isround trip and flight count is less than 3 */}

                        {firstList[0].directionInd === 'R' && (flightList.length <= 3) &&
                            <tr className='row' >
                                {showFlightOptions(firstList, 0)}
                            </tr>
                        }
                        {/* This case will run when trip type isround trip and flight count is greaten than 3 */}
                        {firstList[0].directionInd === 'R' && flightList.length >= 4 &&
                            <tr className='row' style={{ height: '1400px' }}>
                                {showFlightOptions(firstList, 0)}
                            </tr>
                        }
                        {/* this case will run when trip type is oneway */}
                        {firstList[0].directionInd === 'O' &&
                            <tr className='row'>
                                {showFlightOptions(firstList, 0)}
                            </tr>
                        }
                    </table>}
                    {secondList.length !== 0 && <table align="center" width="100%" bgcolor='' >
                        <tr className='row' >
                            {showFlightOptions(secondList, 3)}
                        </tr>
                    </table>}

                </div>
            </div>

        </section>





    </Fragment>




};

export default FlightRecommendations;

import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';
import HotelItem from './HotelItem';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useHistory, useLocation } from 'react-router-dom';
import { sortHotelResult } from '../../../utils/HotelSort';
import { filterHotelResults } from '../../../utils/HotelFilter';
import { useTranslation } from 'react-i18next';
import RangeSlider from 'react-range-slider-input';
import sorter from "sort-nested-json";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';


const HotelList = (props) => {
    const { t } = useTranslation(['translation.SearchFlight']);
    const [sortValue, setSortValue] = useState('');
    const history = useHistory();
    const location = useLocation();
    const [sortBy, setSortBy] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [chainCode, setChainCode] = useState('');
    const [roomPrice, setRoomPrice] = useState([]);
    const [minRoomPrice, setMinRoomPrice] = useState();
    const [maxRoomPrice, setMaxRoomPrice] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [items, setItems] = useState([]);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [roomName, SetRoomName] = useState('');
    const [brandName, SetBrandName] = useState('');
    const [commissionList, setCommissionList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [exclusiveDealsList, setExclusiveDealsList] = useState([]);
    const [ratePlanCode, setRatePlanCode] = useState('');
    const [ratePlanName, setRatePlanName] = useState('');
    const [contentList, setContentList] = useState([]);
    const [contentCode, setContentCode] = useState('');
    const [contentName, setContentName] = useState('');
    const [refundableTypeList, setRefundableTypeList] = useState([]);

    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Hotel Name' },
        { value: '2', label: 'Price' },
        // { value: '3', label: 'Featured' }
    ];

    let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

    const sortAscChangeHandler = () => {
        //setSortValue(event.value);
        setSortBy('asc');
        let hotelResultsTemp = [];
        if (sortValue === '1') {
            hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'asc');
            //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'asc');
        }
        else if (sortValue === '2') {
            hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'asc');
            //hotelResultsTemp = sortHotelResult("1", hotelResultsTemp, 'asc');
        }
        // else if (sortValue === '3') {
        //     hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'asc');
        //     //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'asc');
        // }

        props.searchHotel.data.hotelResults = hotelResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const sortChangeHandler = (event) => {
        setSortValue(event.value);
        setSortBy('asc');
        let hotelResultsTemp = [];
        if (event.value === '1') {
            hotelResultsTemp = sortHotelResult(event.value, props.searchHotel.data.hotelResults, 'asc');
            //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'asc');
        }
        else if (event.value === '2') {
            hotelResultsTemp = sortHotelResult(event.value, props.searchHotel.data.hotelResults, 'asc');
            //hotelResultsTemp = sortHotelResult("1", hotelResultsTemp, 'asc');
        }
        // else if (event.value === '3') {
        //     hotelResultsTemp = sortHotelResult(event.value, props.searchHotel.data.hotelResults, 'asc');
        //     //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'asc');
        // }

        props.searchHotel.data.hotelResults = hotelResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const sortDescChangeHandler = (event) => {
        //setSortValue(event.value);
        setSortBy('desc');
        let hotelResultsTemp = [];
        if (sortValue === '1') {
            hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'desc');
            //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'desc');
        }
        else if (sortValue === '2') {
            hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'desc');
            //hotelResultsTemp = sortHotelResult("1", hotelResultsTemp, 'desc');
        }
        // else if (sortValue === '3') {
        //     hotelResultsTemp = sortHotelResult(sortValue, props.searchHotel.data.hotelResults, 'desc');
        //     //hotelResultsTemp = sortHotelResult("2", hotelResultsTemp, 'desc');
        // }

        props.searchHotel.data.hotelResults = hotelResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    useEffect(() => {
        // setTimeout(function () {
        // }, 1000);

        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });

        sessionStorage.setItem("initialHotelResults", JSON.stringify(props.searchHotel.data.hotelResults));
        setSortBy('asc');
        setSortValue("2");
        let hotelResultsTemp = sortHotelResult("2", props.searchHotel.data.hotelResults, 'asc');
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        if (location.search !== undefined && location.search !== '') {
            history.push({
                pathname: location.pathname,
                search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }

        var hotelNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = hotelNameList.find(el => el.hotelName === element1.hotelName);
            if (!found) {
                hotelNameList.push({
                    hotelName: element1.hotelName
                });
            }
        });
        hotelNameList = sorter.sort(hotelNameList).asc("hotelName");
        setItems(hotelNameList);

        var propertyTypeListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            if (element1.propertyTypeInfo !== undefined && element1.propertyTypeInfo !== null) {
                element1.propertyTypeInfo.forEach(element2 => {
                    const found = propertyTypeListTemp.find(el => el.description === element2.description);
                    if (!found) {
                        propertyTypeListTemp.push({
                            id: `chk${element2.description.replace(' ', '')}`,
                            description: element2.description
                        });
                    }
                });
            }
        });
        propertyTypeListTemp = sorter.sort(propertyTypeListTemp).asc("description");
        setPropertyTypeList(propertyTypeListTemp);

        var commissionListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            element1.price.forEach(element2 => {
                // if (element2.agentCommission !== undefined) {
                const found = commissionListTemp.find(el => el.id === 'chkAgentCommission');
                if (!found) {
                    commissionListTemp.push({
                        id: `chkAgentCommission`
                    });
                }
                // }
            });
        });
        setCommissionList(commissionListTemp);

        var brandNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = brandNameList.find(el => el.chainName === element1.chainName);
            if (!found) {
                brandNameList.push({
                    chainCode: element1.chainCode,
                    chainName: element1.chainName
                });
            }
        });

        brandNameList = sorter.sort(brandNameList).asc("chainName");

        let chainCodeOptions = [];
        for (let index = 0; index < brandNameList.length; index++) {
            if (brandNameList[index].chainName !== 'undefined' && brandNameList[index].chainName !== undefined && brandNameList[index].chainName !== '' && brandNameList[index].chainCode !== 'undefined' && brandNameList[index].chainCode !== undefined && brandNameList[index].chainCode !== '') {
                chainCodeOptions = [...chainCodeOptions, { value: brandNameList[index].chainCode, label: brandNameList[index].chainName + " (" + brandNameList[index].chainCode + ")" }]
            }
        }
        setBrandList(chainCodeOptions);

        var exclusiveDealsNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            element1.roomRateDetails.forEach(element2 => {
                if (Object.keys(hotelSessionJson.ratePlanCodeData).length > 0) {
                    if (hotelSessionJson.ratePlanCodeData.filter(a => a.label === element2.ratePlanName).length > 0) {
                        const found = exclusiveDealsNameList.find(el => el.ratePlanName === element2.ratePlanName);
                        if (!found) {
                            exclusiveDealsNameList.push({
                                ratePlan: element2.ratePlan,
                                ratePlanName: element2.ratePlanName
                            });
                        }
                    }
                }
            });
        });

        exclusiveDealsNameList = sorter.sort(exclusiveDealsNameList).asc("ratePlanName");

        let exclusiveDealsOptions = [];
        for (let index = 0; index < exclusiveDealsNameList.length; index++) {
            exclusiveDealsOptions = [...exclusiveDealsOptions, { value: exclusiveDealsNameList[index].ratePlan, label: exclusiveDealsNameList[index].ratePlanName }]
        }
        setExclusiveDealsList(exclusiveDealsOptions);

        var contentCodeList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = contentCodeList.find(el => el.providerSourceCode === element1.providerSourceCode);
            if (!found) {
                contentCodeList.push({
                    providerSourceCode: element1.providerSourceCode,
                    providerSourceName: 'Show ' + `${element1.sourceName}` + ' Only'

                });
            }
        });
        contentCodeList = sorter.sort(contentCodeList).asc("providerSourceName");

        let contentOptions = [];

        for (let index = 0; index < contentCodeList.length; index++) {
            contentOptions = [...contentOptions, { value: contentCodeList[index].providerSourceCode, label: contentCodeList[index].providerSourceName }]
        }
        setContentList(contentOptions);

        var refundableTypeListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true') {
                const found = refundableTypeListTemp.find(el => el.refundableType === 'Non Refundable');
                if (!found) {
                    refundableTypeListTemp.push({
                        id: `chkNonRefundable`,
                        refundableType: 'Non Refundable'
                    });
                }
            }
            else if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'false') {
                const found = refundableTypeListTemp.find(el => el.refundableType === 'Refundable');
                if (!found) {
                    refundableTypeListTemp.push({
                        id: `chkRefundable`,
                        refundableType: 'Refundable'
                    });
                }
            }
        });
        setRefundableTypeList(refundableTypeListTemp);

    }, [])

    useEffect(() => {
        if (document.querySelector('#selectedPerPersonPrice') !== null) {
            document.querySelector('#selectedPerPersonPrice').classList.add('d-none');
        }

        if (document.querySelector('.clear-icon') !== null) {
            var controlCount = document.querySelectorAll('.clear-icon');
            if (controlCount[1] !== undefined) {
                controlCount[1].click();
            }
        }

        setChainCode('');
        SetBrandName('');

        var inputs = document.querySelectorAll('.propertytypeoption');
        inputs.forEach(element => {
            element.checked = false;
        });

        setRatePlanCode('');
        setRatePlanName('');

        setContentCode('');
        setContentName('');

        var inputs = document.querySelectorAll('.refundabletypeoption');
        inputs.forEach(element => {
            element.checked = false;
        });

        var inputs = document.querySelectorAll('.commissionoption');
        inputs.forEach(element => {
            element.checked = false;
        });

        // window.scrollTo({
        //     top: 3000,
        //     behavior: "smooth",
        // });

        let hotelResultsTemp = filterHotelResults('', '', '', '', '', '', sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })

        var roomPriceList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = roomPriceList.find(el => el.roomPrice === element1.price[0].offeredPrice.perNight);
            if (!found) {
                roomPriceList.push({
                    roomPrice: parseFloat(element1.price[0].offeredPrice.perNight)
                });
                setCurrencyCode(element1.price[0].currencyCode);
            }
        });

        let roomPriceListSorted = sorter.sort(roomPriceList).asc("roomPrice");
        setMinRoomPrice(parseFloat(roomPriceListSorted[0].roomPrice));
        setMaxRoomPrice(Math.ceil(roomPriceListSorted[roomPriceListSorted.length - 1].roomPrice));
        setRoomPrice([parseFloat(roomPriceListSorted[0].roomPrice), Math.ceil(roomPriceListSorted[roomPriceListSorted.length - 1].roomPrice)]);

        var hotelNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = hotelNameList.find(el => el.hotelName === element1.hotelName);
            if (!found) {
                hotelNameList.push({
                    hotelName: element1.hotelName
                });
            }
        });
        hotelNameList = sorter.sort(hotelNameList).asc("hotelName");
        setItems(hotelNameList);

        var propertyTypeListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            if (element1.propertyTypeInfo !== undefined && element1.propertyTypeInfo !== null) {
                element1.propertyTypeInfo.forEach(element2 => {
                    const found = propertyTypeListTemp.find(el => el.description === element2.description);
                    if (!found) {
                        propertyTypeListTemp.push({
                            id: `chk${element2.description.replace(' ', '')}`,
                            description: element2.description
                        });
                    }
                });
            }
        });
        propertyTypeListTemp = sorter.sort(propertyTypeListTemp).asc("description");
        setPropertyTypeList(propertyTypeListTemp);

        var commissionListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            element1.price.forEach(element2 => {
                // if (element2.agentCommission !== undefined) {
                const found = commissionListTemp.find(el => el.id === 'chkAgentCommission');
                if (!found) {
                    commissionListTemp.push({
                        id: `chkAgentCommission`
                    });
                }
                // }
            });
        });
        setCommissionList(commissionListTemp);

        var brandNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = brandNameList.find(el => el.chainName === element1.chainName);
            if (!found) {
                brandNameList.push({
                    chainCode: element1.chainCode,
                    chainName: element1.chainName
                });
            }
        });

        brandNameList = sorter.sort(brandNameList).asc("chainName");

        let chainCodeOptions = [];
        for (let index = 0; index < brandNameList.length; index++) {
            if (brandNameList[index].chainName !== 'undefined' && brandNameList[index].chainName !== undefined && brandNameList[index].chainName !== '' && brandNameList[index].chainCode !== 'undefined' && brandNameList[index].chainCode !== undefined && brandNameList[index].chainCode !== '') {
                chainCodeOptions = [...chainCodeOptions, { value: brandNameList[index].chainCode, label: brandNameList[index].chainName + " (" + brandNameList[index].chainCode + ")" }]
            }
        }
        setBrandList(chainCodeOptions);

        var exclusiveDealsNameList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            element1.roomRateDetails.forEach(element2 => {
                if (Object.keys(hotelSessionJson.ratePlanCodeData).length > 0) {
                    if (hotelSessionJson.ratePlanCodeData.filter(a => a.label === element2.ratePlanName).length > 0) {
                        const found = exclusiveDealsNameList.find(el => el.ratePlanName === element2.ratePlanName);
                        if (!found) {
                            exclusiveDealsNameList.push({
                                ratePlan: element2.ratePlan,
                                ratePlanName: element2.ratePlanName
                            });
                        }
                    }
                }
            });
        });

        exclusiveDealsNameList = sorter.sort(exclusiveDealsNameList).asc("ratePlanName");

        let exclusiveDealsOptions = [];
        for (let index = 0; index < exclusiveDealsNameList.length; index++) {
            exclusiveDealsOptions = [...exclusiveDealsOptions, { value: exclusiveDealsNameList[index].ratePlan, label: exclusiveDealsNameList[index].ratePlanName }]
        }
        setExclusiveDealsList(exclusiveDealsOptions);

        var contentCodeList = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            const found = contentCodeList.find(el => el.providerSourceCode === element1.providerSourceCode);
            if (!found) {
                contentCodeList.push({
                    providerSourceCode: element1.providerSourceCode,
                    providerSourceName: 'Show ' + `${element1.sourceName}` + ' Only'
                });
            }
        });

        contentCodeList = sorter.sort(contentCodeList).asc("providerSourceName");

        let contentOptions = [];
        for (let index = 0; index < contentCodeList.length; index++) {
            contentOptions = [...contentOptions, { value: contentCodeList[index].providerSourceCode, label: contentCodeList[index].providerSourceName }]
        }
        setContentList(contentOptions);

        var refundableTypeListTemp = [];
        props.searchHotel.data.hotelResults.forEach(element1 => {
            if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true') {
                const found = refundableTypeListTemp.find(el => el.refundableType === 'Non Refundable');
                if (!found) {
                    refundableTypeListTemp.push({
                        id: `chkNonRefundable`,
                        refundableType: 'Non Refundable'
                    });
                }
            }
            else if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'false') {
                const found = refundableTypeListTemp.find(el => el.refundableType === 'Refundable');
                if (!found) {
                    refundableTypeListTemp.push({
                        id: `chkRefundable`,
                        refundableType: 'Refundable'
                    });
                }
            }
        });
        setRefundableTypeList(refundableTypeListTemp);

        // var minRange = document.querySelectorAll('input[type="range"]');
        // if (minRange[0] !== undefined) {
        //     minRange[0].setAttribute("value", minRange[0].getAttribute("min"));
        // }

        // var minRangeSlider = document.querySelectorAll('.range-slider__thumb');
        // if (minRangeSlider[0] !== undefined) {
        //     minRangeSlider[0].setAttribute("aria-valuenow", minRange[0].getAttribute("min"));
        //     minRangeSlider[0].setAttribute("aria-valuetext", minRange[0].getAttribute("min"));
        // }

        // var maxRange = document.querySelectorAll('input[type="range"]');
        // if (maxRange[1] !== undefined) {
        //     maxRange[1].setAttribute("value", maxRange[1].getAttribute("min"));
        // }

        // var maxRangeSlider = document.querySelectorAll('.range-slider__thumb');
        // if (maxRangeSlider[1] !== undefined) {
        //     maxRangeSlider[1].setAttribute("aria-valuenow", maxRange[1].getAttribute("min"));
        //     maxRangeSlider[1].setAttribute("aria-valuetext", maxRange[1].getAttribute("min"));
        // }

    }, [props.loadMore])

    //User Story 1070: Change in Search for Single PNR
    const toggleFilter = () => {
        if (showFilter === false) {
            setShowFilter(true);
            document.querySelector('#filterBoxDivHotel').style.display = "";
            document.querySelector('#itemCardDivHotel').classList.add('col-md-9');
            document.querySelector('#itemCardDivHotel').classList.remove('col-md-12');
        }
        else {
            setShowFilter(false);
            document.querySelector('#filterBoxDivHotel').style.display = "none";
            document.querySelector('#itemCardDivHotel').classList.add('col-md-12');
            document.querySelector('#itemCardDivHotel').classList.remove('col-md-9');
        }
    }

    const brandsChangeHandler = (event) => {
        if (event !== null) {
            setChainCode(event);
            SetBrandName(event.label);
        }
        else {
            setChainCode('');
            SetBrandName('');
        }

        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, event !== null ? event.label : '', ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    };

    const exclusiveDealsChangeHandler = (event) => {
        if (event !== null) {
            setRatePlanCode(event);
            setRatePlanName(event.label);
        }
        else {
            setRatePlanCode('');
            setRatePlanName('');
        }

        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, event !== null ? event.label : '', contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    };

    const contentChangeHandler = (event) => {
        if (event !== null) {
            setContentCode(event.value);
            setContentName(event.label);
        }
        else {
            setContentCode('');
            setContentName('');
        }

        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, ratePlanName, event !== null ? event.value : '', sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    };

    const onApplyPriceChangeHandler = (event) => {
        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const onPriceChangeHandler = (event) => {
        document.querySelector('#selectedPerPersonPrice').classList.remove('d-none');
        setRoomPrice(event);
    }

    const handleOnHotelSearch = (input) => {
        if (input.length === 0) {
            SetRoomName('');
            let hotelResultsTemp = filterHotelResults('', roomPrice, '', brandName, ratePlanName, contentCode, sortValue, sortBy);
            props.searchHotel.data.hotelResults = hotelResultsTemp;

            history.push({
                pathname: location.pathname,
                search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
            })
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.hotelName}</span>
            </>
        )
    }

    const handleOnSelectHotel = (item) => {
        SetRoomName(item.hotelName);
        let hotelResultsTemp = filterHotelResults('', roomPrice, item.hotelName, brandName, ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const onPropertyTypeChangeHandler = (event) => {
        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const onCommissionChangeHandler = (event) => {
        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    const onRefundableTypeChangeHandler = (event) => {
        let hotelResultsTemp = filterHotelResults('', roomPrice, roomName, brandName, ratePlanName, contentCode, sortValue, sortBy);
        props.searchHotel.data.hotelResults = hotelResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?hotel=partial&travelreq=true&src=hotel' : ''
        })
    }

    return <Fragment>
        <div className="main mt-5">
            <div className="container">
                {/* User Story 1070: Change in Search for Single PNR */}
                <div className='row'>
                    <div className='col-lg-2'>
                        <div><label className="h6 text-primary" for="city">Filter On</label></div>
                        <button id='btnShowFilterHotel' onClick={toggleFilter} className="btn gt-filter-btn-padding text-white gt-main-btn" ><i className="fa-solid fa-filter"></i></button>
                        <ReactTooltip
                            anchorId="btnShowFilterHotel"
                            place="bottom"
                            variant="info"
                            className="tooltip"
                            html={t('Show Filter')}
                        />
                    </div>

                    <div className='col-lg-7 my-auto text-center'>
                        {/* <div className='h5 text-muted'>We found <span className='text-primary'>640 properties</span> availability.</div> */}
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-3">

                        <div className='row'>
                            <div className='col-md-9 text-end'>
                                <label className="h6 text-primary mb-2" for="city">Sort By</label>
                            </div>
                            <div className='col-md-3'>
                                <div className='text-primary float-lg-end text-end'>
                                    <span style={{ cursor: 'pointer' }} id='spnSortAsc' onClick={sortAscChangeHandler}><i className="fas fa-arrow-circle-down h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortAsc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('Low to High')}
                                    // content={t('Low to High')}
                                    />
                                    <span style={{ cursor: 'pointer' }} id='spnSortDesc' onClick={sortDescChangeHandler}><i className="fas fa-arrow-circle-up ms-1 h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortDesc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('High to Low')}
                                    // content={t('High to Low')}
                                    />
                                </div>
                            </div>
                        </div>
                        <Dropdown onChange={sortChangeHandler}
                            value={sortOptions.filter(option => option.value === sortValue)}
                            options={sortOptions}
                            textField="value"
                            dataItemKey="label"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                            placeholder=""
                        //autoFocus={props.pageFocus}
                        />
                    </div>
                    {/* User Story 1070: Change in Search for Single PNR */}
                </div>
                <div className='row' style={{ overflow: 'hidden' }}>
                    <div className="col-md-3" id='filterBoxDivHotel' style={{ display: 'none' }}>
                        <div className="left_side_search_area">
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5 className='my-3'>Price</h5>
                                </div>
                                <br></br>
                                {
                                    (roomPrice[0] !== undefined) &&
                                    <span id='selectedPerPersonPrice' className='d-none'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(roomPrice[0]).split('.')[0]}-{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(roomPrice[1]).split('.')[0]}</span>
                                }
                                <div className="filter-price">
                                    <div id="price-slider">
                                        {
                                            (minRoomPrice !== undefined && maxRoomPrice !== undefined) &&
                                            <RangeSlider id={"sliderPerPersonPrice"}
                                                rangeSlideDisabled={false}
                                                thumbsDisabled={[false, false]}
                                                min={minRoomPrice} max={maxRoomPrice}
                                                step={1}
                                                defaultValue={[minRoomPrice, maxRoomPrice]}
                                                disabled={false}
                                                onInput={onPriceChangeHandler}
                                                key={props.loadMore}
                                            />
                                        }
                                        <br></br>
                                        {
                                            (minRoomPrice !== undefined) &&
                                            <span id='minRoomPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(minRoomPrice).split('.')[0]}</span>
                                        }

                                        {
                                            (maxRoomPrice !== undefined) &&
                                            <span id='maxRoomPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(maxRoomPrice).split('.')[0]}</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-12 text-end'>
                                    <button className="apply text-end" type="button" id='applyPerPersonPrice'
                                        onClick={onApplyPriceChangeHandler}>Apply</button>
                                </div>
                            </div>
                            <div className="left_side_search_boxed hotelNamedropdownHeight dropdown-overflow ">
                                <div className="left_side_search_heading">
                                    <h5 className='mb-3'>Hotel Name</h5>
                                </div>
                                <ReactSearchAutocomplete
                                    onSearch={handleOnHotelSearch}
                                    items={items}
                                    formatResult={formatResult}
                                    autoFocus
                                    onSelect={handleOnSelectHotel}
                                    showNoResults={false}
                                    maxResults={10}
                                    fuseOptions={{ keys: ["hotelName"] }}
                                    resultStringKeyName="hotelName"
                                />
                            </div>
                            <div id='brandsFilter' className="left_side_search_boxed">
                                <div className="left_side_search_heading mb-3">
                                    <h5>Brands</h5>
                                </div>
                                <Dropdown onChange={brandsChangeHandler}
                                    value={chainCode}
                                    options={brandList}
                                    textField="label"
                                    dataItemKey="value"
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                    isClearable={true}
                                />
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Property Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        propertyTypeList.map(element => (
                                            <div key={element.id} className="form-check">
                                                <input className="form-check-input propertytypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onPropertyTypeChangeHandler(e)} data={element.description} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.description} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3" && Object.keys(hotelSessionJson.ratePlanCodeData).length > 0 &&
                                <div id='exclusiveDealsFilter' className="left_side_search_boxed">
                                    <div className="left_side_search_heading mb-3">
                                        <h5>Exclusive Deals</h5>
                                    </div>
                                    <Dropdown onChange={exclusiveDealsChangeHandler}
                                        value={ratePlanCode}
                                        options={exclusiveDealsList}
                                        textField="label"
                                        dataItemKey="value"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        isClearable={true}
                                    />
                                </div>
                            }
                            {
                                getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                <div id='contentFilter' className="left_side_search_boxed">
                                    <div className="left_side_search_heading mb-3">
                                        <h5>Content</h5>
                                    </div>
                                    <Dropdown onChange={contentChangeHandler}
                                        value={contentList.filter(option => option.value === contentCode)}
                                        options={contentList}
                                        textField="label"
                                        dataItemKey="value"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                        isClearable={true}
                                    />
                                </div>
                            }
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Refundable</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        refundableTypeList.map(element => (
                                            <div key={element.id} className="form-check">
                                                <input className="form-check-input refundabletypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onRefundableTypeChangeHandler(e)} data={element.refundableType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.refundableType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                getConfigurationByBoolen("ALLOW_COMISSION") &&
                                <div className="left_side_search_boxed">
                                    {/* <div className="left_side_search_heading">
                                        <h5>Commissionable</h5>
                                        </div> */}
                                    <div className="tour_search_type">
                                        {
                                            commissionList.map(element => (
                                                <div key={element.id} className="form-check">
                                                    <input className="form-check-input commissionoption" type="checkbox" id={element.id}
                                                        onChange={(e) => onCommissionChangeHandler(e)} data={'agentCommission'} />
                                                    <label className="form-check-label" htmlFor={element.id}>
                                                        <span className="area_flex_one">
                                                            <span>Commissionable </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div id='itemCardDivHotel' className='col-md-12 full-width'>
                        <div className="main-cn hotel-page clearfix">
                            <div className="row">
                                <div className="col-md-12 col-md-push-3">
                                    <section className="hotel-list">
                                        <div className="sort-view clearfix">
                                        </div>

                                        {props.searchHotel.data.hotelResults.map((searchHotel) => (
                                            <HotelItem
                                                key={searchHotel.sequenceId}
                                                id={searchHotel.sequenceId}
                                                echoToken={props.searchHotel.data.requestDetails.echoToken}
                                                hotelResults={searchHotel}
                                                showData="1"
                                                sourceErrors={props.searchHotel.data.sourceErrors}
                                                inPolicy={searchHotel.inPolicy}
                                                travelReq={props.travelReq}
                                            />
                                        ))}

                                        {/* <div className='row'>
                                                <div className="load_more_flight">
                                                    <button className="btn btn-sm btn_theme"><i className="fas fa-spinner fa-spin"></i> Load more..</button>
                                                </div>
                                            </div> */}

                                    </section>
                                    {/*   <!-- End Hotel List --> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default HotelList;

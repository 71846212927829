import { Fragment } from 'react';
import Nearby from './NearBy';
import { removeFirstAndLastSpecialChar } from '../../../utils/CommonFunction';
import Map from './Map';
import { containsOnlyDots } from '../../../utils/CommonFunction';

const AboutArea = (props) => {
    let latitude = props.hotelDetails.data.hotelResults[0].latitude;
    let longitude = props.hotelDetails.data.hotelResults[0].longitude
    let attaractios = "";
    let transport;

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.forEach(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
        props.hotelDetails.data.hotelResults[0].descriptions.forEach(result => {
            if (result.text.type === 'TransportationInfo') {
                transport = result.text.value;
            }
        });
    }


    let transportInfo = "";
    let showTransport = [];
    if (transport !== undefined) {
        transportInfo = transport.split(/\r?\n/);

        let transportInfoList = [];

        transportInfo.forEach((element, index) => {
            transportInfoList.push({
                key: index + 1,
                info: element
            });
        });

        transportInfoList.forEach(element => {
            console.log('aboutarea', element);
            if (element.info !== '' && !containsOnlyDots(element.info) && !element.info.includes("-AIRPORT TRANSPORTATION-")) {
                let str = removeFirstAndLastSpecialChar(element.info);

                showTransport.push(<div key={element.key} className='row'>
                    <div className='col-md-12'>
                        <div className='my-1'>
                            <i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{(str)}</span>
                        </div>
                    </div>
                </div>);
            }

        });
    }





    // const showMoreGettingAround = () => {

    //     var textElement = document.querySelector(".text-getting-around");
    //     var buttonElement = document.getElementById('showMoreGettingAround11');
    //     if (textElement.classList.contains("show-more-getting-around-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down double angle
    //     }

    //     textElement.classList.toggle("show-more-getting-around-height");

    // }
    return <Fragment>
        <div className="tour_details_boxed" >
            <h3 className="heading_theme">About this Area</h3>

            <div className="map_area1">
                <Map longitude={longitude} latitude={latitude} hotelName={props.hotelDetails.data.hotelResults[0].hotelName} address={props.hotelDetails.data.hotelResults[0].address.addressLine + "," + props.hotelDetails.data.hotelResults[0].address.city.cityName + "," + props.hotelDetails.data.hotelResults[0].address.country.countryName} />
            </div>
            <hr className='my-3'></hr>
            <div className='row'>

                {attaractios !== '' && <div className='col-md-6'>
                    <Nearby hotelDetails={props.hotelDetails} />
                </div>}

                {showTransport.length !== 0 &&
                    <div className='col-md-6'>
                        <h4 className="h5 mt-1 text-primary"><i className="fas fa-car aa"></i> Getting Around</h4>
                        <div className='text-getting-around show-more-getting-around-height'>
                            {showTransport}
                        </div>
                    </div>
                }
                <div id='policies_section'></div>
                <div className='row mt-1'>
                    &nbsp;&nbsp;   &nbsp;&nbsp;<br></br>
                </div>


            </div>
        </div>
    </Fragment>
}

export default AboutArea;
import AuthContext from '../../../redux/contexts/auth-context';
import React, { Fragment, useState, Suspense, useContext, useEffect } from 'react';
import FooterNavigation from './FooterNavigation';
import WithoutAuthFooterNavigation from './WithoutAuthFooterNavigation';
import PrintPopupModal from '../Flight/PrintPopupModal';
import MainNavigation from "./MainNavigation";
import RecommendationEmail from '../Flight/RecommendationEmail'
import {  getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { useLocation } from 'react-router';
import HotelEmail from '../../../pages/HotelRecommendation/HotelEmail.js'
import { clearHotelRecommendation } from '../../../utils/SendRecommendation.js';



const Layout = (props) => {
    const [zoomLevel, setZoomLevel] = useState(100);
    const [errorPrint, setErrorPrint] = useState();
    const location = useLocation();

    const handleZoomIn = () => {
        if (zoomLevel < 111) {
            setZoomLevel(zoomLevel + 5);
        };
    }
    const setToDefault = () => {
        setZoomLevel(100);
    }

    const handleZoomOut = () => {
        if (zoomLevel > 85) {
            setZoomLevel(zoomLevel - 5);
        }

    };

    const containerStyles = {
        width: '100%',
        height: '100%',
        //overflow: 'hidden',
    };

    const contentStyles = {
        //  transform: `scale(${zoomLevel / 100})`,
       
        scale: `${zoomLevel}%`,
        transformOrigin: 'top',
        transition: 'transform 0.2s ease-in-out',
    };
    const errorHandler = () => {

        setErrorPrint(null);

    };
    const authCtx = useContext(AuthContext);
    //function to clear alll flight data
    function clearAllFlightList() {
        let flightList = [];
        if (sessionStorage.getItem("flightListJson") !== null) {
            flightList = JSON.parse(sessionStorage.getItem("flightListJson"));
            flightList.length !== 0 && flightList.forEach(element => {
                let el = document.getElementById('addToProposal' + element.sequenceNumber)
                if (el !== null) {
                    el.checked = false;
                }
            });
            sessionStorage.removeItem("flightListJson");
            document.getElementById('emailRecommendation').style.display = 'none'

        }
    };

    function onSendRecommendation() {
        setErrorPrint({
            title: "Send Proposed Flights",
            message: <div className='col-md-12'>
                <div className=''>
                    <RecommendationEmail mailData={""} hideData="1" errorHandler={errorHandler}></RecommendationEmail>
                </div></div>,
        })
    }
    const loadChatwootScript = (status) => {
        const BASE_URL = 'https://app.chatlyn.com';
        const script = document.createElement('script');
        script.src = `${BASE_URL}/packs/js/sdk.js`;
        script.defer = true;
        script.async = true;
        script.id = "whatsup";
        if (status) {
            document.getElementById("whatsapp").appendChild(script);

            script.onload = () => {
                window.chatwootSDK.run({
                    websiteToken: 'taT33by6mcQfeTR9uHt2UXHj',
                    baseUrl: BASE_URL,
                    whatsApp: '+12129339336'

                });
            };
        } else {
            if (document.getElementById("whatsup") != null)
                window.location.reload();

            document.getElementById("whatsapp").innerHTML = "";
        }
    };
    function onSendHotelRecommendation() {
        setErrorPrint({
            title: "Send Proposed Hotel",
            message: <div className='col-md-12'>
                <div className=''>
                    <HotelEmail mailData={""} hideData="1" errorHandler={errorHandler}></HotelEmail>
                </div></div>,
        })
    }

    function shouldShowNavigation() {
        let showNav = true;

        if (location.pathname === 'LandingPage' || location.pathname === '/LandingPage' ||
            location.pathname === 'ApprovalthroughMail' || location.pathname === '/ApprovalthroughMail' ||
            location.pathname === 'ViewTravelRequestThroughMail' || location.pathname === '/ViewTravelRequestThroughMail') {
            showNav = false;
        }

        return showNav;
    }

    function getFooterForNonAuth() {
        const path = location.pathname.toLowerCase();
        if ((path === '/' ||
            path.indexOf('/login') !== -1 ||
            path.indexOf('/signup') !== -1 ||
            path.indexOf('/forgot-password') !== -1 ||
            path.indexOf('/session') !== -1 ||
            path.indexOf('/updatepassword') !== -1) &&
            localStorage.getItem("customizeDetailsForNonAuth") !== null) {

            //Non authenticated pages but footer needs to be shown as per the company's customization
            return <FooterNavigation></FooterNavigation>;
        }
        else if (path !== '/landingpage' &&
            path !== '/launching') {
            return <WithoutAuthFooterNavigation></WithoutAuthFooterNavigation>;
        }
        return <></>;
    }

    useEffect(() => {

        if (authCtx.isLoggedIn && (getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).parentOfficeID
            || getConfigurationByValue('ENABLE_WHATSUP') === JSON.parse(localStorage.getItem('loginData')).officeID)) {
            if (location.pathname !== 'SightseeingDetails' && location.pathname !== '/SightseeingDetails') {
                loadChatwootScript(true);
            }
        } else {
            loadChatwootScript(false);
        }

    }, [authCtx.isLoggedIn])

    // function onWhatsappClick() {
    //    // window.open('https://api.whatsapp.com/send/?phone=%2B12129339336&text&type=phone_number&app_absent=0');
    //    loadChatwootScript();
    // }

    
    return (

        <Fragment >
            {errorPrint && (
                <PrintPopupModal
                    title={errorPrint.title}
                    message={errorPrint.message}
                    onConfirm={errorHandler}
                ></PrintPopupModal>
            )}
            <div id="whatsapp"></div>


            {(location.pathname !== 'homepageTemp' && location.pathname !== '/homepageTemp' && location.pathname !== 'homepage' && location.pathname !== '/homepage' && location.pathname !== 'SightseeingDetails' && location.pathname !== '/SightseeingDetails') &&
                <div>

                    {/* <div className=' whatappiconOuter ' style={{ display: "block" }}> <a className='whatappicon text-success' ><i class="fa-brands fa-square-whatsapp" ></i></a>
                        </div> */}
                    <div id="emailRecommendation" style={{ display: "none" }}>
                        <div className=' email-pro '>
                            <div style={{ position: "fixed", right: "100px", top: "45%", zIndex: "3" }}>
                                {/* <iframe src="https://giphy.com/embed/PWBCZGqdulucLMDy5f" width="80" height="80" class="giphy-embed" allowFullScreen></iframe> */}
                                {/* <iframe src="https://giphy.com/embed/O1782Dz7UIITFApqZJ" width="80" height="80"  class="giphy-embed" allowFullScreen></iframe> */}
                            </div>
                            <button id="btn" className="eemail text-end btn-email-pulse" onClick={onSendRecommendation}>
                                <i className="fas fa-envelope fs-1"></i> <i className='badge bg-warning ft-style' id="flightCount"></i>
                                <span>&nbsp; Email  Proposal</span>
                            </button>
                            <button className='email-cross' onClick={clearAllFlightList}><i className="fas fa-times" ></i>&nbsp;Clear All
                            </button>
                        </div>
                    </div>
                    <div id="HotelEmailRecommendation" style={{ display: "none" }}>
                        <div className=' email-pro '>
                            <div style={{ position: "fixed", right: "100px", top: "45%", zIndex: "3" }}>
                            </div>
                            <button id="btn" className="eemail text-end btn-email-pulse" onClick={onSendHotelRecommendation}>
                                <i className="fas fa-envelope fs-1"></i> <i className='badge bg-warning ft-style' id="hotelCount"></i>
                                <span>&nbsp; Email  Proposal</span>
                            </button>
                            <button className='email-cross' onClick={clearHotelRecommendation}><i className="fas fa-times" ></i>&nbsp;Clear All
                            </button>
                        </div>
                    </div>

                    <div style={containerStyles}>
                        {shouldShowNavigation() &&
                            <MainNavigation handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut}
                                setToDefault={setToDefault}>
                            </MainNavigation>
                        }
                        {(authCtx.isLoggedIn ||
                            (localStorage.getItem("loginData") !== null && JSON.parse(localStorage.getItem("loginData"))?.customizeDetails !== null) ||
                            localStorage.getItem("customizeDetails") !== null) ?
                            /*<div style={contentStyles}>*/
                           <div style={document.querySelector('#zoom-out') !== null ? contentStyles : {}}>
                                <main >
                                    {props.children}
                                </main>
                                {/*Footer not required for landing page*/}
                                {(location.pathname !== '/LandingPage' && location.pathname !== '/launching') &&
                                    <FooterNavigation></FooterNavigation>
                                }
                            </div> :
                            <div>
                                <main>
                                    {props.children}
                                </main>
                                {/*Footer not required for landing page*/}
                                {getFooterForNonAuth()}
                            </div>
                        }
                    </div>
                </div>
            }

            {(location.pathname === 'homepageTemp' || location.pathname === '/homepageTemp' || location.pathname === 'homepage' || location.pathname === '/homepage' || location.pathname === 'SightseeingDetails' || location.pathname === '/SightseeingDetails') &&
                <div>
                    <div style={containerStyles}>
                        <div >
                            {authCtx.isLoggedIn ?
                                <div style={contentStyles}>
                                    <main >
                                        {props.children}
                                    </main>
                                </div> :
                                <div>
                                    <main >
                                        {props.children}
                                    </main>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }


        </Fragment>
    )
};

export default Layout;
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import { getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';
const WalletItem = (props) => {
    const { t } = useTranslation(['translation.Wallet']);
    return <Fragment>

        <div className="row" key={props.key}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.companyName}
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="col-md-10">
                                <p className="card-text mb-0"><strong>Wallet Amount: </strong>{new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: parseInt(parseFloat(props.amount) > 0 && props.amount.toString().includes('.') && props.amount.toString().split('.').length > 1 ? props.amount.toString().split('.')[1].length : '2'),
                                    style: 'currency',
                                    currency: props.currencyCode,
                                }).format(props.amount)}</p>
                                <p className="card-text mb-0"><strong>Active: </strong>{props.isActive.toString() === 'false' ? 'No' : "Yes"}</p>
                            </div>
                            <div className="col-md-2 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_View") && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateWallet/${props.officeID}/0/${props.id}`, edit: 0, officeID: props.officeID }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}


                                    {checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateWallet/${props.officeID}/1/${props.id}`, edit: 1, officeID: props.officeID }}>
                                        <i className="fa-solid fa-pen"></i>
                                    </Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default WalletItem;
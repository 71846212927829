import { Fragment, useState, useRef, useEffect } from "react";
import Dropdown from 'react-select';
import useHttp from '../../../../services/use-http';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import { searchWorkflowStatus, updateTravelRequestApproval } from '../../../../services/Approver-API';
import { searchCurrency } from '../../../../services/Currency-API';
import { searchCorporateMaster } from '../../../../services/CorporateMaster-API';
import { getServiceRequests } from '../../../../services/Booking-API';
import Files from 'react-files';
import { generateUUID } from '../../../../utils';
import { useTranslation } from 'react-i18next';

//This component may be called from 2 locations:
// 1. Directly as a pop - up when approval is done from My Queue
// 2. From a standalone page when approval is done through email
// Props.isSourceEmail is true if component is called from case 2.

const ApproverDetails = (props) => {
    const { t } = useTranslation(['translation.Approver']);

    const isTravelBookerApproval = (props.approvalTypeID === '6');

    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const remarksRef = useRef(null);

    const [statusErrorMsg, setStatusErrorMsg] = useState('');
    const [rejectionReasonErrorMsg, setRejectionReasonErrorMsg] = useState('');
    const [remarksErrorMsg, setRemarksErrorMsg] = useState('');

    const { sendRequest: sendSearchWorkflowStatus, data: workflowStatusResponse, status: searchWorkflowRequestStatus } = useHttp(searchWorkflowStatus);
    const { sendRequest: sendUpdateTravelRequestApproval, data: updateTravelRequestApprovalResponse, status: sendUpdateTravelRequestApprovalStatus } = useHttp(updateTravelRequestApproval);

    const [isRemarksMandatory, setIsRemarksMandatory] = useState(false);

    const { sendRequest: sendSearchCurrency, data: currencyResponse, status: searchCurrencyStatus } = useHttp(searchCurrency);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const { sendRequest: sendRejectionReasonsRequest, data: rejectionReasonsResponse, status: getRejectionReasonsStatus } = useHttp(searchCorporateMaster);
    const [rejectionOptions, setRejectionOptions] = useState([]);
    const [selectedRejectionReason, setSelectedRejectionReason] = useState('');
    const [isRejectionReasonRequired, setIsRejectionReasonRequired] = useState(false);

    const { sendRequest: callGetServiceRequests, data: serviceRequestsResponse, status: serviceRequestsStatus } = useHttp(getServiceRequests);

    const [serviceRequests, setServiceRequests] = useState();
    const [isServiceRequestsRequired, setIsServiceRequestsRequired] = useState(false);
    const [isServiceAmountRequired, setIsServiceAmountRequired] = useState(false);

    const [isStatusEnabled, setIsStatusEnabled] = useState(true);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);

    const maxTotalDocSizeinMegaBytes = 15; //15 MB
    const bufferSizeForBase64Conversion = (maxTotalDocSizeinMegaBytes * 33) / 100
    const maxTotalDocSizeInBytes = (maxTotalDocSizeinMegaBytes + bufferSizeForBase64Conversion) * 1024 * 1024;

    function handleStatusChange(selectedOption) {
        setSelectedStatus(selectedOption.value);
        if (selectedOption.value === 'RJ') {
            setIsRejectionReasonRequired(true);
            setIsServiceRequestsRequired(false);
        }
        else if (selectedOption.value === 'UT') {
            setIsRejectionReasonRequired(false);
            setIsRemarksMandatory(true);
            setIsServiceRequestsRequired(true);
            setIsServiceAmountRequired(false);
        }
        else {
            setIsRejectionReasonRequired(false);
            setIsRemarksMandatory(false);
            if (selectedOption.value === 'UA') {
                setIsServiceRequestsRequired(true);
                setIsServiceAmountRequired(true);
            } else {
                setIsServiceRequestsRequired(false);
            }
        }
        setStatusErrorMsg('');
    };

    function handleRejectionReasonChange(option) {
        setSelectedRejectionReason(option.value);

        if (option.label.toUpperCase() === 'OTHERS') {
            setIsRemarksMandatory(true);
        }
        else {
            setIsRemarksMandatory(false);
            setRemarksErrorMsg('');
        }

        setRejectionReasonErrorMsg('');
    }

    function handleCurrencyChange(event, serviceID) {

        let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
        let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

        if (matchingRequest) {
            matchingRequest.approvedCurrencyCode = event.value;
            matchingRequest.approvedCurrencyCodeErrorMsg = '';

            setServiceRequests(tempServiceRequests);
        }
    };

    function handlePriceChange(event, serviceID) {

        let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
        let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

        if (matchingRequest) {
            let errorMsg = '';
            matchingRequest.approvedPrice = event.target.value.trim();

            if (matchingRequest.approvedPrice !== '') {
                if (isNaN(matchingRequest.approvedPrice)) {
                    matchingRequest.approvedPrice = '';
                    errorMsg = t('invalidInput');
                }
            }
            else {
                errorMsg = t('errorAmountRequired');
            }

            matchingRequest.approvedPriceErrorMsg = errorMsg;

            setServiceRequests(tempServiceRequests);
        }
    }

    function handleDocumentNameChange(event, serviceID) {

        let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
        let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

        if (matchingRequest) {
            let errorMsg = '';
            matchingRequest.documentName = event.target.value.trim();

            if (matchingRequest.documentName !== '') {
                if (!matchingRequest.documentName.match("^[a-zA-Z0-9][a-zA-Z0-9. _-]*$")) {
                    errorMsg = t('invalidInput');
                }
            }

            matchingRequest.documentNameErrorMsg = errorMsg;

            setServiceRequests(tempServiceRequests);
        }
    }

    function handleFileUpload(files, serviceID) {

        if (files && files.length) {

            let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
            let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

            let hasError = false;
            if (matchingRequest.documentName.length === 0) {
                matchingRequest.documentNameErrorMsg = t('errorDocumentNameRequired');
                hasError = true;
            }
            else {
                if (!matchingRequest.documentName.match("^[a-zA-Z0-9][a-zA-Z0-9. _-]*$")) {
                    matchingRequest.documentNameErrorMsg = t('invalidInput');
                    hasError = true;
                }
            }

            if (hasError) {
                setServiceRequests(tempServiceRequests);
                return;
            }

            matchingRequest.showUploadProgress = true;

            let documentToUpload = null;

            //Note: When files are converted to base64, there's approx 33% increase in size
            blobToBase64(new Blob([files[0]])).then(res => {
                documentToUpload = {
                    documentID: generateUUID(10),
                    documentType: files[0].type,
                    documentOriginalName: files[0].name,
                    documentName: matchingRequest.documentName + '.' + files[0].type.split('/').at(-1),
                    documentBinaryContent: res,
                    remarks: ''
                };
            }).then(() => {

                const totalBytes = Buffer.from(JSON.stringify(tempServiceRequests)).length +
                    Buffer.from(JSON.stringify(documentToUpload)).length;

                if (totalBytes > maxTotalDocSizeInBytes) {
                    matchingRequest.fileUploadErrorMsg = t('errorTotalFileSizeExceed');
                }
                else {
                    matchingRequest.documents.push(documentToUpload);                    
                    matchingRequest.fileUploadErrorMsg = '';
                    matchingRequest.documentName = '';
                    document.getElementById(matchingRequest.documentNameControlId).value = '';                    
                }
                matchingRequest.showUploadProgress = false;
                setServiceRequests(tempServiceRequests);
            });
        }
    }

    function handleFileUploadError(error, serviceID) {

        if (error) {

            let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
            let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

            matchingRequest.fileUploadErrorMsg = '';

            if (error.code === 1) {
                matchingRequest.fileUploadErrorMsg = t('errorInvalidDocumentType');
            }
            else if (error.code === 2) {
                matchingRequest.fileUploadErrorMsg = t('errorTotalFileSizeExceed');
            }
            else {
                matchingRequest.fileUploadErrorMsg = error.message;
            }

            setServiceRequests(tempServiceRequests);
        }
    }

    function handleFileDelete(event, serviceID, documentID) {
        event.preventDefault();

        let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
        let matchingRequest = tempServiceRequests.find(request => request.serviceID === serviceID);

        matchingRequest.documents = matchingRequest.documents.filter(doc => doc.documentID !== documentID);

        setServiceRequests(tempServiceRequests);
    }

    function validateInputs() {
        let isValid = true;

        if (selectedStatus.length === 0) {
            setStatusErrorMsg(t('errorStatusRequired'));
            scrollToErrorElement('status');
            isValid = false;
        }

        if (isValid && isRejectionReasonRequired) {
            if (selectedRejectionReason === '') {
                setRejectionReasonErrorMsg(t('errorRejectionReasonRequired'));
                scrollToErrorElement('rejectionReason');
                isValid = false;
            }
        }

        if (isValid && isServiceRequestsRequired && isServiceAmountRequired && serviceRequests && serviceRequests.length) {

            let tempServiceRequests = JSON.parse(JSON.stringify(serviceRequests));
            const serviceRequestCount = tempServiceRequests.length;
            let requestLoop = 0;

            for (; requestLoop < serviceRequestCount; requestLoop++) {
                let request = tempServiceRequests[requestLoop];

                if (request.approvedCurrencyCode === '') {
                    request.approvedCurrencyCodeErrorMsg = t('errorCurrencyRequired');
                    scrollToErrorElement(request.approvedCurrencyCodeControlId);
                    isValid = false;
                    break;
                }

                if (request.approvedPrice === '') {
                    request.approvedPriceErrorMsg = t('errorAmountRequired');
                    scrollToErrorElement(request.approvedPriceControlId);
                    isValid = false;
                    break;
                }
            }

            if (!isValid) {
                setServiceRequests(tempServiceRequests);
            }
        }

        if (isValid && !validateRemarks()) {
            scrollToErrorElement('remarks');
            isValid = false;
        }

        return isValid;
    }

    function validateRemarks() {
        let isValid = true;
        const remarksText = remarksRef.current.value.trim();
        setRemarksErrorMsg('');

        if (isRemarksMandatory && remarksText.length === 0) {
            setRemarksErrorMsg(t('errorRemarksRequired'));
            isValid = false;
        }

        //if (remarksText.length && !remarksText.match("^[a-zA-Z .()0-9,\\'\\-\\n]+$")) {
        //    setRemarksErrorMsg(t('invalidInput'));
        //    isValid = false;
        //}

        return isValid;
    }

    function handleFormSubmit() {
        if (validateInputs()) {

            const approvalUserID = props.userID;

            let filledServices = null;
            if (isServiceRequestsRequired && serviceRequests && serviceRequests.length) {
                filledServices = [];
                serviceRequests.forEach(request => {

                    let attachedDocuments = null;
                    if (request.documents && request.documents.length) {
                        attachedDocuments = [];
                        request.documents.forEach(document => {
                            attachedDocuments.push({
                                shoppingID: props.shoppingID,
                                approvalUserID: approvalUserID,
                                serviceID: request.serviceID,
                                name: document.documentName,
                                documentBinaryContent: document.documentBinaryContent
                            });
                        });
                    }

                    filledServices.push({
                        shoppingID: props.shoppingID,
                        approvalUserID: approvalUserID,
                        serviceID: request.serviceID,
                        currencyCode: request.approvedCurrencyCode,
                        amount: request.approvedPrice,
                        approvalWorkflowQueueServicesAttachment: attachedDocuments
                    });
                });
            }

            const updateStatusInput = {
                shoppingID: props.shoppingID,
                approvalMasterID: props.approvalMasterID,
                approvalUserID: approvalUserID,
                approvalStatusCode: selectedStatus,
                remarks: remarksRef.current.value.trim(),
                approvalWorkflowQueueServices: filledServices,
                rejectionID: selectedRejectionReason,
                serviceTypeID: props.serviceTypeID
            };

            if (props.onFormSubmitStart) {
                props.onFormSubmitStart();
            }

            sendUpdateTravelRequestApproval(updateStatusInput);
        }
    }

    function handlePopupCancel() {
        props.errorHandler();
    }

    function blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }

    function scrollToErrorElement(elementId) {
        if (props.isSourceEmail) {
            return;
        }

        let scrollToElement = document.getElementById(elementId).closest('.gt-scroll-marker');

        if (!scrollToElement) {
            scrollToElement = document.getElementById(elementId);
        }

        scrollToElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    useEffect(() => {

        sendSearchWorkflowStatus({ workflowTypeID: isTravelBookerApproval ? '3' : '1' });

        if (isTravelBookerApproval) {
            sendSearchCurrency({
                currCode: '',
                currName: ''
            });

            callGetServiceRequests(props.confirmationNumber);
        }

        sendRejectionReasonsRequest({
            id: '',
            userID: props.userID,
            name: '',
            code: '',
            keyCode: 'EIGHT',
            status: '2',
            officeID: props.officeID
        });

    }, []);

    useEffect(() => {
        if (workflowStatusResponse) {

            if (workflowStatusResponse.errors.status === 'FALSE') {
                setStatusOptions(() => workflowStatusResponse.data.map((wfStatus) => {
                    return {
                        label: wfStatus.statusName,
                        value: wfStatus.statusCode
                    }
                }));
            }

        }
    }, [workflowStatusResponse]);

    useEffect(() => {
        if (updateTravelRequestApprovalResponse) {
            if (props.onApprovalStatusUpdate) {
                if (updateTravelRequestApprovalResponse.errors.status === 'FALSE') {
                    props.onApprovalStatusUpdate(true);
                    setIsConfirmButtonDisabled(true);
                }
                else {
                    props.onApprovalStatusUpdate(false);
                }
            }
        }
    }, [updateTravelRequestApprovalResponse]);

    useEffect(() => {
        if (currencyResponse) {
            if (currencyResponse.errors.status === 'FALSE' && currencyResponse.data.length) {
                setCurrencyOptions(() =>
                    currencyResponse.data.map((currency) => {
                        return {
                            label: `${currency.currName} (${currency.currCode})`,
                            value: currency.currCode
                        }
                    })
                );
            }
        }
    }, [currencyResponse]);

    useEffect(() => {
        if (rejectionReasonsResponse) {
            if (rejectionReasonsResponse.errors.status === 'FALSE') {
                if (rejectionReasonsResponse.data && rejectionReasonsResponse.data.length) {
                    let rejectionReasons = [];
                    rejectionReasonsResponse.data.forEach(reason => {
                        rejectionReasons.push({
                            label: reason.name,
                            value: reason.id
                        });
                    });
                    //rejectionReasons.push({
                    //    label: 'Others',
                    //    value: '0'
                    //});

                    setRejectionOptions(() => rejectionReasons);
                }
            }
        }
    }, [rejectionReasonsResponse]);

    useEffect(() => {
        if (serviceRequestsResponse) {
            if (serviceRequestsResponse.data.errors.status === 'FALSE') {
                if (serviceRequestsResponse.data.serviceRequest && serviceRequestsResponse.data.serviceRequest.length) {
                    setServiceRequests(() => serviceRequestsResponse.data.serviceRequest.map((request, index) => {
                        return {
                            serviceID: request.serviceID,
                            serviceName: request.serviceName,
                            remarks: request.remarks,
                            existingConfirmationNumber: request.existingConfirmationNumber,
                            currencyCode: request.currencyCode,
                            price: request.totalRateOfService,
                            approvedCurrencyCode: '',
                            approvedCurrencyCodeErrorMsg: '',
                            approvedCurrencyCodeControlId: 'currencyCode' + index,
                            approvedPrice: '',
                            approvedPriceErrorMsg: '',
                            approvedPriceControlId: 'approvedPrice' + index,
                            documentNameControlId: 'documentName' + index,
                            documentName: '',
                            documentNameErrorMsg: '',
                            fileUploadErrorMsg: '',
                            documents: []
                        };
                    }));
                }
            }
        }
    }, [serviceRequestsResponse]);

    useEffect(() => {
        if (workflowStatusResponse && props.actionApprovalStatusCode) {
            handleStatusChange({
                value: props.actionApprovalStatusCode
            });
            setIsStatusEnabled(false);
        }
    }, [workflowStatusResponse, props.actionApprovalStatusCode]);

    return <Fragment>
        {/*{(searchWorkflowRequestStatus === 'pending' ||*/}
        {/*    getRejectionReasonsStatus === 'pending' ||*/}
        {/*    serviceRequestsStatus === 'pending' ||*/}
        {/*    searchCurrencyStatus === 'pending' ||*/}
        {/*    sendUpdateTravelRequestApprovalStatus === 'pending') &&*/}
        {/*    <LoadingSpinner></LoadingSpinner>*/}
        {/*}*/}
        <div className="row">
            <div className="control">
                <div className='col-md-12 col-sm-12 control gt-scroll-marker'>
                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor="category"> {t('labelStatus')} <span className="error-icon">*</span>  </label>
                    <Dropdown
                        id="status"
                        onChange={handleStatusChange}
                        value={statusOptions.find(option => option.value === selectedStatus)}
                        options={statusOptions}
                        arrowClosed={<span className="arrow-closed" />}
                        arrowOpen={<span className="arrow-open" />}
                        controlClassName='form-control control form-select bg_input mb-0'
                        placeholder="Please Select"
                        isDisabled={!isStatusEnabled}
                        isLoading={searchWorkflowRequestStatus === 'pending'}
                    />
                    {statusErrorMsg.length > 0 &&
                        <p className="error-text">{statusErrorMsg}</p>
                    }
                </div>
                {isRejectionReasonRequired &&
                    <div className='col-md-12 col-sm-12 control gt-scroll-marker'>
                        <label className="h6 text-primary mb-2 col-lg-12"
                            htmlFor="category"> {t('labelRejectionReason')} <span className="error-icon">*</span>  </label>
                        <Dropdown
                            id="rejectionReason"
                            onChange={handleRejectionReasonChange}
                            value={rejectionOptions.find(option => option.value === selectedRejectionReason)}
                            options={rejectionOptions}
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />}
                            controlClassName='form-control control form-select bg_input mb-0'
                            placeholder="Please Select"
                            isLoading={getRejectionReasonsStatus === 'pending'}
                        />
                        {rejectionReasonErrorMsg.length > 0 &&
                            <p className="error-text">{rejectionReasonErrorMsg}</p>
                        }
                    </div>
                }

                {isServiceRequestsRequired && serviceRequests && serviceRequests.length > 0 &&
                    <>
                        <div className='col-lg-12 mt-4'>
                            <label class="h6 text-primary mb-2 col-lg-12" htmlFor="Service">{t('labelServices')} </label>
                            {serviceRequests.map((request) => (
                                <div className="card text-dark card-bg-blue mb-2" key={request.serviceID}>
                                    <div className="card-body">
                                        <div className="row">

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <p className="card-text mb-0 mt-1"><strong>Service </strong>{request.serviceName} </p>
                                                    </div>
                                                    {request.existingConfirmationNumber &&
                                                        <div className="col-md-4">
                                                            <p className="card-text mb-0 mt-1"><strong>{t('labelConfirmationNo')} </strong>
                                                                {request.existingConfirmationNumber}
                                                            </p>
                                                        </div>
                                                    }
                                                    {request.price &&
                                                        <div className="col-md-4">
                                                            <p className="card-text mb-0 mt-1"><strong>{t('labelPrice')} </strong>
                                                                {request.price.length > 0 &&
                                                                    new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: request.currencyCode,
                                                                    }).format(request.price)
                                                                }
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <hr></hr>
                                                <div className='row gt-scroll-marker'>
                                                    {isServiceAmountRequired &&
                                                        <Fragment>
                                                            <div className="col-md-3">
                                                                <label class="h6 text-primary mb-2 col-lg-12" htmlFor=""> {t('labelCurrency')} <span class="error-icon"> *</span></label>

                                                                <Dropdown
                                                                    id={request.approvedCurrencyCodeControlId}
                                                                    onChange={(event) => handleCurrencyChange(event, request.serviceID)}
                                                                    value={currencyOptions.filter(option => option.value === request.approvedCurrencyCode)}
                                                                    options={currencyOptions}
                                                                    textField="listValue"
                                                                    dataItemKey="listID"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                    placeholder="Please Select"
                                                                    isLoading={searchCurrencyStatus === 'pending'}
                                                                />

                                                                {request.approvedCurrencyCodeErrorMsg.length > 0 &&
                                                                    <p className="error-text">{request.approvedCurrencyCodeErrorMsg}</p>
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label class="h6 text-primary mb-2 col-lg-12" htmlFor="Service"> {t('labelAmount')} <span class="error-icon"> *</span></label>

                                                                <input type='text'
                                                                    id={request.approvedPriceControlId}
                                                                    className="form-control bg_input col-md-6"
                                                                    maxLength={20}
                                                                    onBlur={(event) => handlePriceChange(event, request.serviceID)}
                                                                />

                                                                {request.approvedPriceErrorMsg.length > 0 &&
                                                                    <p className="error-text">{request.approvedPriceErrorMsg}</p>
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    <div className={isServiceAmountRequired ? "col-md-3" : "col-md-4"}>
                                                        <label class="h6 text-primary mb-2 col-lg-12" htmlFor="Service"> {t('labelDocumentName')}</label>
                                                        <input maxLength={100}
                                                            id={request.documentNameControlId}
                                                            className="form-control bg_input col-md-6" type='text'
                                                            onBlur={(event) => handleDocumentNameChange(event, request.serviceID)}
                                                        />

                                                        {request.documentNameErrorMsg.length > 0 &&
                                                            <p className="error-text">{request.documentNameErrorMsg}</p>
                                                        }
                                                    </div>
                                                    <div className={isServiceAmountRequired ? "col-md-3" : "col-md-8"}>
                                                        <label class="h6 text-primary mb-2 col-lg-12" htmlFor="Service">     {t('labelSelectDocument')}</label>
                                                        <div className="dropdown pb-2 col-md-12">
                                                            <div className="files position-relative">
                                                                <Files
                                                                    className='files-dropzone text-center gt-file-dropzone-doc col-md-12 col-sm-12 col-12'
                                                                    onChange={(files) => handleFileUpload(files, request.serviceID)}
                                                                    onError={(error) => handleFileUploadError(error, request.serviceID)}
                                                                    accepts={['image/png', 'image/jpg', 'image/jpeg', '.pdf']}
                                                                    multiple={false}
                                                                    maxFileSize={maxTotalDocSizeInBytes}
                                                                    minFileSize={0}
                                                                    clickable>

                                                                    <span id='Upload' className='file-upload text-primary'>
                                                                        <i class="fas fa-plus-circle"></i>
                                                                        <span>  {t('labelClickToUpload')}</span>
                                                                    </span>

                                                                    {request.showUploadProgress &&
                                                                        <span id='loadingSpin'>
                                                                            <i className="fa fa-spinner fa-spin text-primary ms-3" ></i>
                                                                        </span>
                                                                    }
                                                                </Files>
                                                            </div>
                                                            <span class="note"> {t('noteSupportedFiles')}</span>

                                                            {request.fileUploadErrorMsg.length > 0 &&
                                                                <p className="error-text">{request.fileUploadErrorMsg}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {request.documents && request.documents.length > 0 &&
                                                    <div className='row my-2'>
                                                        <span>
                                                            <strong>{t('labelAttachedDocuments')} </strong>
                                                            {request.documents.map(document =>
                                                                <span key={document.documentID}>
                                                                    <span class="btn h6 text-white gt-yellow-btn ms-1 btn-sm">
                                                                        {document.documentName}
                                                                        <span class="text-white"
                                                                            onClick={(e) => handleFileDelete(e, request.serviceID, document.documentID)}>
                                                                            <i class="fas fa-times ms-3"></i>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-12 mt-1 mb-4">
                            <span className="h6 text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span>
                            <span className="ms-1 text-primary h6">
                                {t('noteDocumentSizeLimit').replace('{MB}', maxTotalDocSizeinMegaBytes)}
                            </span>
                        </div>
                    </>
                }

                <div className='col-md-12 col-sm-12 control gt-scroll-marker'>
                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='thresholdAlertEmail'>
                        {t('labelRemarks') + ' '}
                        {isRemarksMandatory &&
                            <span className="error-icon">*</span>
                        }
                    </label>
                    <div>
                        <textarea id="remarks"
                            type="text" rows="2"
                            className="mb-2 form-control form-control-sm col-lg-12"
                            maxLength={300}
                            ref={remarksRef}
                            onBlur={validateRemarks}>
                        </textarea>
                    </div>
                    {remarksErrorMsg.length > 0 &&
                        <p className="error-text">{remarksErrorMsg}</p>
                    }

                </div>

            </div>
        </div>
        <p><span className="error-icon">*</span><span>{t('noteRequiredFields')}</span></p>
        <div className="col-md-12 my-auto align-right">
            <button type="button"
                className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1'
                id='btnSend'
                disabled={isConfirmButtonDisabled}
                onClick={handleFormSubmit} >
                {(!props.isSourceEmail && sendUpdateTravelRequestApprovalStatus === 'pending') ?
                    <i className="fa fa-spinner fa-spin" ></i> : t('labelConfirm')
                }
            </button>
            {!props.isSourceEmail &&
                <button type="button"
                    className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1'
                    id='btnSend'
                    onClick={handlePopupCancel} >
                    {t('labelCancel')}
                </button>
            }
        </div>

    </Fragment>
};
export default ApproverDetails;
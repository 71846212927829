import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from './../../../../components/Common/Loading/LoadingSpinner'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
const RatePlanForm = (props) => {
    const { t } = useTranslation(['translation.RatePlan']);
    const [pageTitle, setPageTitle] = useState(" Rate Plan ");
    const history = useHistory();


    //Variables
    const rateplanCodeinputRef = useRef('');
    const gtCodeinputRef = useRef('');
    const rateplanNameinputRef = useRef('');
    const activeInputRef = useRef()
    const [active, setActive] = useState(false);
    const [rateplanCodeInputHasError, setRatePlanCodeInputHasError] = useState(false);
    const [rateplanCodeInvalidInput, setRatePlanCodeInvalid] = useState(false);
    const [gtCodeInputHasError, setGtCodeInputHasError] = useState(false);
    const [gtCodeInvalidInput, setGtCodeInvalidInput] = useState(false);
    const [rateplanNameInputHasError, setRatePlanNameInputHasError] = useState(false);
    const [rateplanNameInvalidInput, setRatePlanNameInvalidInput] = useState(false);



    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
        if (props.loadedProfileDetails.length !== 0) {
            rateplanCodeinputRef.current.value = props.loadedProfileDetails.code !== undefined ? props.loadedProfileDetails.code : "";
            gtCodeinputRef.current.value = props.loadedProfileDetails.gtCode !== undefined ? props.loadedProfileDetails.gtCode : "";
            rateplanNameinputRef.current.value = props.loadedProfileDetails.gtName !== undefined ? props.loadedProfileDetails.gtName : "";
            if (props.loadedProfileDetails.default !== undefined) {
                setActive(props.loadedProfileDetails.default);
            }
        }
        if (props.loadedProfileDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            setPageTitle(" View Rate Plan ")
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("ratePlanCode").readOnly = true;
            document.getElementById("gtCode").readOnly = true;
            document.querySelector("#default").disabled = true;
            document.getElementById("ratePlanName").readOnly = true;
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";

        }
        else if (isEditMode === 1) {
            setPageTitle(" Edit Rate Plan ")
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementById("ratePlanCode").readOnly = true;
            document.getElementById("gtCode").readOnly = true;
            document.getElementById("ratePlanName").focus();
            document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";

        }

        if (props.loadedProfileDetails.length === 0) {
            setPageTitle(" New Rate Plan ")
            document.getElementById("btnNew").style.display = "none";
            sessionStorage.setItem('isEditable', '100');


        }

    }, [props.loadedProfileDetails.length, props.loadedProfileDetails.code, props.loadedProfileDetails.gtName]);
    useEffect(() => {
        if (props.loadedProfileDetails.length === 0) {
            document.getElementById("btnSave").click();
        }
    }, [props.loadedProfileDetails.length]);
    const activeChangeHandler = (event) => {

        setActive(!active)

    }
    function onBlurCheck(event) {
        event.preventDefault();
        const enteredRatePlanCode = rateplanCodeinputRef.current.value.trim();
        const enteredGtCode = gtCodeinputRef.current.value.trim();
        const enteredRateName = rateplanNameinputRef.current.value.trim();
        if (enteredRatePlanCode === '') {
            setRatePlanCodeInputHasError(true);
            setRatePlanCodeInvalid(false)
            return;
        } else {
            setRatePlanCodeInputHasError(false);
        }
        if (!enteredRatePlanCode.match('^[a-zA-Z0-9]+$')) {
            setRatePlanCodeInvalid(true);
            setRatePlanCodeInputHasError(false);
            return;
        } else {
            setRatePlanCodeInvalid(false);
        }
        if (enteredGtCode === '') {
            setGtCodeInputHasError(true);
            setGtCodeInvalidInput(false);
            return;
        } else {
            setGtCodeInputHasError(false);
        }
        if (!enteredGtCode.match('^[a-zA-Z0-9]+$')) {
            setGtCodeInvalidInput(true);
            setGtCodeInputHasError(false);
            return;
        } else {
            setGtCodeInvalidInput(false);

        }
        if (enteredRateName === '') {
            setRatePlanNameInputHasError(true);
            setRatePlanNameInvalidInput(false);
            return;

        } else {
            setRatePlanNameInputHasError(false);
        }
        if (!enteredRateName.match(/^[a-zA-Z\s\-'.\d]+$/)) {
            setRatePlanNameInvalidInput(true);
            setRatePlanNameInputHasError(false);
            return;
        } else {
            setRatePlanNameInvalidInput(false);
        }

    }
    function submitFormHandler(event) {
        event.preventDefault();
        event.preventDefault();
        const enteredRatePlanCode = rateplanCodeinputRef.current.value.trim();
        const enteredGtCode = gtCodeinputRef.current.value.trim();
        const enteredRateName = rateplanNameinputRef.current.value.trim();
        if (enteredRatePlanCode === '') {
            setRatePlanCodeInputHasError(true);
            rateplanCodeinputRef.current.focus();
            return;
        } else {
            setRatePlanCodeInputHasError(false);
        }
        if (!enteredRatePlanCode.match('^[a-zA-Z0-9]+$')) {
            setRatePlanCodeInvalid(true);
            rateplanCodeinputRef.current.focus();
            return;
        } else {
            setRatePlanCodeInvalid(false);
        }
        if (enteredGtCode === '') {
            setGtCodeInputHasError(true);
            gtCodeinputRef.current.focus();
            return;
        } else {
            setGtCodeInputHasError(false);
        }
        if (!enteredGtCode.match('^[a-zA-Z0-9]+$')) {
            setGtCodeInvalidInput(true);
            gtCodeinputRef.current.focus();
            return;
        } else {
            setGtCodeInvalidInput(false);

        }
        if (enteredRateName === '') {
            setRatePlanNameInputHasError(true);
            rateplanNameinputRef.current.focus();
            return;

        } else {
            setRatePlanNameInputHasError(false);
        }
        if (!enteredRateName.match(/^[a-zA-Z\s\-'.\d]+$/)) {
            setRatePlanNameInvalidInput(true);
            rateplanNameinputRef.current.focus();
            return;
        } else {
            setRatePlanNameInvalidInput(false);
        }
        let ID = '';
        if (props.loadedProfileDetails.length !== 0) {
            ID = props.loadedProfileDetails.id
        }
        props.onAddRatePlan({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            code: rateplanCodeinputRef.current.value,
            gtCode: gtCodeinputRef.current.value,
            gtName: rateplanNameinputRef.current.value,
            default: active
        });





    }
    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdateRatePlan')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedProfileDetails.length === 0) {
                history.go('/UpdateRatePlan')
            }
            else {
                history.go(`/UpdateRatePlan/${props.loadedProfileDetails.id}`)
            }
        }
    }

    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading && (
                                            <div className='loading'>
                                                <LoadingSpinner />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>
                                                    <form className='form'>

                                                        <div className="row">
                                                            <div className='control col-lg-6'>
                                                                <label htmlFor='ratePlanCode' className="h6 text-primary mb-2 col-lg-12">{t('ratePlanCode')}<span className="error-icon"> *</span></label>
                                                                <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='ratePlanCode' ref={rateplanCodeinputRef} maxLength={10}
                                                                    onBlur={onBlurCheck} />
                                                                {rateplanCodeInputHasError && <p className="error-text">{t('ratePlanCodeValidation')}</p>
                                                                }
                                                                {rateplanCodeInvalidInput && <p className="error-text">{t('ratePlanCodeInputValidation')}</p>
                                                                }
                                                            </div>
                                                            <div className='control col-lg-6'>
                                                                <label htmlFor='gtCode' className="h6 text-primary mb-2 col-lg-12">{t('gtCode')}<span className="error-icon"> *</span></label>
                                                                <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='gtCode' ref={gtCodeinputRef}
                                                                    onBlur={onBlurCheck}
                                                                    maxLength={10}
                                                                />
                                                                {gtCodeInputHasError && <p className="error-text">{t('gtCodeValidation')}</p>
                                                                }
                                                                {gtCodeInvalidInput && <p className="error-text">{t('gtInputValidation')}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className='control col-lg-12'>
                                                            <label htmlFor='ratePlanName' className="h6 text-primary mb-2 col-lg-12">{t('ratePlanName')}<span className="error-icon"> *</span></label>
                                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='ratePlanName' ref={rateplanNameinputRef} maxLength={50}
                                                                onBlur={onBlurCheck}
                                                            />
                                                            {rateplanNameInputHasError && <p className="error-text">{t('ratePlanNameValidation')}</p>
                                                            }
                                                            {rateplanNameInvalidInput && <p className="error-text">{t('ratePlanNameInputValidation')}</p>
                                                            }

                                                        </div>
                                                        <div className='control'>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label htmlFor="default" className="mb-2 h6 text-primary">   {t('default')}</label>
                                                                <input id="default" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} />
                                                            </div>
                                                        </div>


                                                        <div className="text-black fw-bold mb-2"><i className="fas fa-info-circle"></i> Note : </div> 
                                                        <div className="h6 text-primary mb-2 col-lg-12">1. If the <em>Default Rate Plan</em> is switched on, this rate plan will be allowed for all companies.
                                                        </div>
                                                        <div className="h6 text-primary mb-2 col-lg-12">
                                                        2. To restrict rate plan codes for a company, please go to <em>Settings <i className="fas fa-long-arrow-alt-right mx-1"></i> Rate Plan Allowed.</em>
                                                        </div>

                                                        <p className="mt-2"><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/SearchRatePlan" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchRatePlan" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                                    {(checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Insert") || checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                            {t('save')}
                                                                        </button>
                                                                        : <span id="btnSave"></span>
                                                                    }
                                                                    {checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button>
                                                                        : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>
};
export default RatePlanForm;
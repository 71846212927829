
import { Fragment, useEffect, useState } from 'react';
const TermsCondition = (props) => {
    const [content, setContent] = useState(null);
    useEffect(() => {
        if (props.termsAndCondition !== undefined && props.termsAndCondition !== null) {
            setContent(props.termsAndCondition)
        }
    }, [props.termsAndCondition])
    useEffect(() => {
        if (content !== null) {
            if (document.getElementById("TermsAndConditionID") !== null) {
                document.getElementById("TermsAndConditionID").innerHTML = content.content;
            }

        }
    }, [content])




    return <Fragment>
        <section id="theme_search_form_tour" className='mb-3'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row table-ui-list">
                                <div id="TermsAndConditionID"></div>
                                {(props.termsAndCondition === undefined || props.termsAndCondition === null) && <div className="col-lg-12">
                                    <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Terms and Conditions</label>
                                    <div className='mb-2'>
                                        Welcome to Globetrotter. Please read these terms and conditions carefully before using our website or services.
                                    </div>
                                    <div className='policy-text'>
                                        <ol>
                                            <li>
                                                <span className='text-primary fw-bold'> Agreement to Terms and Conditions:</span> By using our website or services, you agree to these terms and conditions. If you do not agree with these terms and conditions, you must not use our website or services.
                                            </li>

                                            <li>
                                                <span className='text-primary fw-bold'> Eligibility:</span>	 Our website and services are intended for use by individuals who are at least 18 years old. If you are under 18 years old, you may only use our website and services with the involvement of a parent or legal guardian.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> User Conduct:</span> You may use our website and services only for lawful purposes and in accordance with these terms and conditions. You may not use our website or services for any illegal or unauthorized purpose.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Booking and Payment:</span> When you book a flight, hotel, or other service through our website, you agree to pay the full price for that service. Payment is due at the time of booking, unless otherwise agreed to by us. We accept payment by credit card or other payment methods specified on our website.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Cancelation and Refunds:</span> If you need to cancel a booking, please contact us as soon as possible. Cancelation policies vary depending on the service you have booked. Some services may be non-refundable.
                                            </li>
                                            <li> <span className='text-primary fw-bold'> Changes to Bookings: </span>If you need to make changes to a booking, please contact us as soon as possible. Changes may be subject to additional fees or charges.</li>
                                            <li>
                                                <span className='text-primary fw-bold'> Liability:</span> We are not responsible for any loss, damage, or injury that may occur during your travel or use of our services. We are not responsible for any acts or omissions of third-party service providers, such as airlines, hotels, or tour operators.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Disclaimer of Warranties: </span> We do not make any warranties, express or implied, about the accuracy, reliability, completeness, or timeliness of the information on our website or the services we provide.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'>Termination:</span> Fareladder Consultancy Services reserves the right to terminate your use of the Portal at any time, without prior notice to you, for any reason or no reason.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Intellectual Property:  </span> All content on our website, including text, graphics, logos, images, and software, is the property of Fareladder Consultancy Services or its content suppliers and is protected by United States and international copyright laws.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> 	Governing Law:   </span> These terms and conditions shall be governed by and construed in accordance with the laws of the United States.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'> Modifications:  </span> We reserve the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website.
                                            </li>
                                            <li>
                                                <span className='text-primary fw-bold'>Contact Us: </span> If you have any questions or concerns about these terms and conditions, please contact us at 
                                                <a href="mailto:info@fareladder.us"> info@fareladder.us</a>.
                                            </li>


                                        </ol>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
};

export default TermsCondition;
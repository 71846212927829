import moment from 'moment';
import { setSanitizedHtml } from '../../../utils';

const MeetingDetails = (props) => {
    console.log(props);
    return <>
        <div className="col-md-12">
        <label for="" class="h5 text-primary my-4"><span class="gt-border-1px"> <i class="fas fa-users"></i> Meeting Details</span></label>

           <div className='card'>
                <div className=" text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>Country </strong>
                                        {props.meetingDetails.countryName}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>City </strong>
                                        {props.meetingDetails.cityName}
                                    </p>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-12">
                                    <hr className="my-2"></hr>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>Start Date </strong>
                                        {moment(props.meetingDetails.meetingStartDate, "YYYYMMDD")
                                            .format("D MMM YYYY")}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>End Date </strong>
                                        {moment(props.meetingDetails.meetingEndDate, "YYYYMMDD")
                                            .format("D MMM YYYY")}
                                    </p>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-12 d-none">
                                    <hr className="my-2"></hr>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>Start Time </strong>
                                        {moment(props.meetingDetails.meetingStartTime, "HHmm")
                                            .format("hh:mm A")}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="card-text mb-0 mt-1">
                                        <strong>End Time </strong>
                                        {moment(props.meetingDetails.meetingEndTime, "HHmm")
                                            .format("hh:mm A")}
                                    </p>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-12">
                                    <hr className="my-2"></hr>
                                </div>
                                <div className="">
                                    <p className="card-text mb-0 pb-1">
                                        <strong>Trip Purpose </strong>
                                    </p>
                                    <div>
                                        <p>{props.meetingDetails.purposeDetail}</p>
                                    </div>
                                </div>

                                {props.meetingDetails?.purposeDetails?.length > 0 &&
                                    <div className="">
                                        <div className="col-md-12">
                                            <hr className="my-2"></hr>
                                        </div>
                                        <p className="card-text mb-0 pb-1">
                                            <strong>Trip Purpose Remarks </strong>
                                        </p>
                                        <div>
                                            <p>{setSanitizedHtml(props.meetingDetails.purposeDetails)}</p>
                                        </div>
                                    </div>
                                }
                                <p></p>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </>;
}

export default MeetingDetails;
const BookingDetails = (props) => {
    return (<>
        <div className='row'>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    <div className='text-muted h6 mb-2 mt-2'>
                        <b className='text-primary'>Confirmation No. </b> <span className='text-black'>{props.travelRequestData.data.referenceNumber}</span>
                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default BookingDetails;
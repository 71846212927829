import { useState, useEffect } from 'react'; 
import Dropdown from 'react-select';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import {
    HIGHRISKDESTINATION_ADD
} from '../../../../navigation/CONSTANTS';

import useHttp from '../../../../services/use-http';

import { getAllCountry } from '../../../../services/Country-API';
import { searchCity } from '../../../../services/City-API';


const HighRiskDestinationForm = ({ onSearch, onReset, showLoadingSpinner }) => {
    const { t } = useTranslation(['translation.HighRiskDestination']);

    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { sendRequest: getCountryOptions, data: countryMaster, status: getCountryOptionsStatus } = useHttp(getAllCountry);

    const [selectedCity, setSelectedCity] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);
    const { sendRequest: getCityOptions, data: cityMaster, status: getCityOptionsStatus } = useHttp(searchCity);
    
    function initCities(countryCode) {
        getCityOptions({
            countryCode: countryCode,
            cityCode: '',
            cityName: ''
        });
    };

    function handleCountrySelect(selectedOption) {
        if (selectedOption) {
            setSelectedCountry(selectedOption);
            setSelectedCity(null);
            setCityOptions([]);
            initCities(selectedOption.value);
        } else {
            //clear triggered
            setSelectedCountry(null);
            setSelectedCity(null);
            setCityOptions([]);
        }
    };

    function handleCitySelect(selectedOption) {
        if (selectedOption) {
            setSelectedCity(selectedOption);
        } else {
            //clear triggered
            setSelectedCity(null);
        }
    }

    function handleSearchClick(event) {
        event.preventDefault();
        
        const countryCode = selectedCountry ? selectedCountry.value : '';
        const cityCode = selectedCity ? selectedCity.value : '';
        
        onSearch(countryCode, cityCode);
    }

    useEffect(() => {
        getCountryOptions();
    }, [getCountryOptions]);

    //handle after getting country master data from api
    useEffect(() => {
        if (countryMaster
            && countryMaster.errors.status === 'FALSE'
            && countryMaster.data) {

            setCountryOptions(countryMaster.data.map(country => {
                return {
                    value: country.countryCode,
                    label: country.countryName
                };
            }));

        }
    }, [countryMaster]);

    //handle after getting city data from api
    useEffect(() => {
        if (cityMaster
            && cityMaster.errors.status === 'FALSE'
            && cityMaster.data) {

            //This condition is added in order to handle a case where user selects a country and immediately deselects/removes selection.
            //In this case, city options will not be populated
            if (selectedCountry) {
                setCityOptions(cityMaster.data.map(city => {
                    return {
                        value: city.cityCode,
                        label: `${city.cityName} (${city.cityCode})`
                    };
                }));
            }
        }
    }, [cityMaster]);

    return (
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            { showLoadingSpinner && <LoadingSpinner></LoadingSpinner> }                            
                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="form-group col-md-12">
                                        <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12">
                                            <i className="fas fa-globe-asia"></i>{' '}
                                            {t('searchFormTitle')}
                                        </label>
                                        <form onSubmit={handleSearchClick}>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2" htmlFor='country'>{t('country')}</label>
                                                        
                                                        <Dropdown
                                                            value={selectedCountry}
                                                            options={countryOptions || []}
                                                            onChange={handleCountrySelect}
                                                            isLoading={getCountryOptionsStatus === 'pending'}
                                                            isClearable
                                                            controlClassName="form-control control form-select bg_input mb-0"
                                                            placeholder="Please Select"
                                                        />

                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2" htmlFor='city'>City</label>
                                                        
                                                        <Dropdown
                                                            value={selectedCity}
                                                            options={cityOptions || []}
                                                            onChange={handleCitySelect}
                                                            isLoading={getCityOptionsStatus === 'pending'}
                                                            isClearable
                                                            controlClassName="form-control control form-select bg_input mb-0"
                                                            placeholder="Please Select"
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mt-3 col-md-12 actions'>

                                                <a href="/"
                                                    className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                    onClick={onReset}>
                                                    {t('reset')}
                                                </a>

                                                {
                                                    checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Insert") &&
                                                    <NavLink
                                                        className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                        to={HIGHRISKDESTINATION_ADD}>
                                                        {t('new')}
                                                    </NavLink>    
                                                }

                                                {
                                                    checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Search") &&
                                                    <button
                                                        className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                        type="submit">
                                                        {t('search')}
                                                    </button>
                                                }

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
    );
};


export default HighRiskDestinationForm;
import {
    Fragment,
    useState,
    useRef,
    useEffect,
    useCallback
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import useHttp from '../../../services/use-http';

import {
    searchHighRiskDestinations,
    deleteHighRiskDestinations
} from '../../../services/HighRiskDestination-API'

import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { sortData } from '../../../utils';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";

import HighRiskDestinationBanner from './Components/HighRiskDestinationBanner';
import HighRiskDestinationForm from './Components/HighRiskDestinationForm';
import HighRiskDestinationList from './Components/HighRiskDestinationList';

function SearchHighRiskDestinationPanel({ onReset }) {
    const { t } = useTranslation(['translation.HighRiskDestination']);
    const history = useHistory();
    const location = useLocation();

    const [destinations, setDestinations] = useState([]);
    const [alertModal, setAlertModal] = useState(null);
    const [showdDeletePrompt, setShowDeletePrompt] = useState(false);
    const [idToDelete, setIdToDelete] = useState();

    const { sendRequest: sendSearchRequest, data: searchResponse, status: getHighRiskDestinationsStatus } = useHttp(searchHighRiskDestinations);
    const { sendRequest: sendDeleteRequest, data: deleteResponse } = useHttp(deleteHighRiskDestinations);

    const sortCtrlRef = useRef(null);

    function handleSearch(countryCode, cityCode) {
        let cityList = null;

        setDestinations([]);

        if (cityCode !== '') {
            cityList = [];
            cityList.push({
                cityCode: cityCode
            });
        }

        sendSearchRequest({
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            countryCode: countryCode,
            cityList: cityList
        });
    }

    function handleSort() {
        const clonedDestinations = structuredClone(destinations);
        sortByCountry(clonedDestinations);
    }

    const sortByCountry = useCallback((clonedDestinations) => {
        let isAscendingOrder = true;
        if (sortCtrlRef && sortCtrlRef.current) {
            isAscendingOrder = sortCtrlRef.current.value === "ASC";
        }
        sortData(clonedDestinations, "country", isAscendingOrder);
        setDestinations(clonedDestinations);

        // Below section is done for the sake of making it behave similar to other masters. 
        // However, it doesn't have any impact on overall functionality.
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isAscendingOrder ? 'asc' : 'desc')}`
        });
    }, [history, location.pathname]);

    function handleAlertModalConfirm() {
            setAlertModal(null);
        }

    function handleDelete(idToDelete) {
            setShowDeletePrompt(true);
            setIdToDelete(idToDelete);
        }

    function handleDeleteConfirm() {
            const userId = JSON.parse(localStorage.getItem('userID'));

            sendDeleteRequest({
                id: idToDelete,
                userID: userId
            });

            setShowDeletePrompt(false);
        }

    function handleDeleteCancel() {
            setShowDeletePrompt(false);
        }

    //effect to run once search response is received
    useEffect(() => {
            if (searchResponse) {
                if (searchResponse.errors.status === 'FALSE') {

                    let tempDestinations = [];

                    tempDestinations = searchResponse.data.map((dest) => {
                        let cities = [];
                        if (dest.cityList && dest.cityList.length) {
                            cities = dest.cityList.map((city) => {
                                return `${city.cityName} (${city.cityCode})`;
                            });
                        }

                        return {
                            country: dest.countryName,
                            cities: cities.join(', '),
                            id: dest.id
                        };
                    });

                    sortByCountry(tempDestinations);
                }
                else {
                    let errorMsg = '';
                    if (searchResponse.errors.error.code === '1008') {
                        errorMsg = t('msgNoRecordFound');
                    }
                    else {
                        errorMsg = t('msgRecordCannotBeDisplayed');
                    }

                    if (errorMsg.length) {
                        setAlertModal({
                            title: t('alertTitle'),
                            message: errorMsg
                        });
                    }
                }
            }

            if (window.scrollY > 100) {
                window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                });
            }

        }, [searchResponse, t, sortByCountry]);

    //effect to run post delete from server
    useEffect(() => {

        if (deleteResponse) {
            if (deleteResponse.errors.status === 'FALSE') {
                let clonedDestinations = structuredClone(destinations);

                clonedDestinations = clonedDestinations.filter((dest) => {
                    return dest.id !== idToDelete;
                });

                setDestinations(clonedDestinations);

                setAlertModal({
                    title: t('alertTitle'),
                    message: t('msgRecordDeleted')
                });
            }
            else {
                setAlertModal({
                    title: t('alertTitle'),
                    message: t('msgRecordNotDeleted')
                });
            }
        }

    }, [deleteResponse]);

    return <Fragment>

        {alertModal &&
            <ErrorModal
                title={alertModal.title}
                message={alertModal.message}
                onConfirm={handleAlertModalConfirm}>
            </ErrorModal>}

        {showdDeletePrompt &&
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('msgDeletePrompt')}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}>
            </ConfirmationModal>}

        <HighRiskDestinationBanner></HighRiskDestinationBanner>

        <HighRiskDestinationForm
            onReset={onReset}
            onSearch={handleSearch}
            showLoadingSpinner={(getHighRiskDestinationsStatus === 'pending')}
        >
        </HighRiskDestinationForm>

        {(destinations && destinations.length > 0) &&
            <HighRiskDestinationList
                destinations={destinations}
                sortCtrlRef={sortCtrlRef}
                onSort={handleSort}
                onDestinationDelete={handleDelete}
            >
            </HighRiskDestinationList>}

    </Fragment>;
}


const SearchHighRiskDestination = () => {
    const { t } = useTranslation(['translation.HighRiskDestination']);
    useDocumentTitle(t('pageTitle'))

    const [allComponentsKey, setAllComponentsKey] = useState(1);
    function handleReset(event) {
        event.preventDefault();
        //updating key will reset the whole component
        setAllComponentsKey(s => s + 1);
    }

    return (
        <SearchHighRiskDestinationPanel
            key={allComponentsKey}
            onReset={handleReset}>
        </SearchHighRiskDestinationPanel>
    );
}


export default SearchHighRiskDestination;
import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import Dropdown from 'react-dropdown';

// const company_name = [
//     { value: 'Fareladder', label: 'Fareladder' },
//     { value: 'Amazon Travel Agency', label: 'Amazon Travel Agency' },
//     { value: 'Delta travels', label: 'Delta travels' }
//   ];
const fare_rule = [
    { value: 'Rule Application and other conditions ', label: 'Rule Application and other conditions ' },
    { value: 'Flight Application', label: 'Flight Application' },
    { value: 'Advance Reservations/Ticketing ', label: 'Advance Reservations/Ticketing ' },
    { value: 'Accompanied Travel ', label: 'Accompanied Travel ' },
    { value: 'Travel Restrictions ', label: 'Travel Restrictions ' },
    { value: 'Ticket Endorsements', label: 'Ticket Endorsements' },
    { value: 'Rule Application and other condition', label: 'Rule Application and other condition' }
];
//   const time_slot = [
//     { value: 'Any time', label: 'Any time' },
//     { value: 'Morning', label: 'Morning' },
//     { value: 'Afternoon', label: 'Afternoon' },
//     { value: 'Evening', label: 'Evening' },
//     { value: 'Night', label: 'Night' }
//   ];
    const titles_name = [
   
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Master', label: 'Master' },
    { value: 'Miss', label: 'Miss' },

  ];
  const titles_name_child = [
    { value: 'Master', label: 'Master' },
    { value: 'Miss', label: 'Miss' }

  ];
  const gender_list = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];
  const card_list = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' },
  ];
  const wheel_list = [
    { value: 'Required', label: 'Required' },
    { value: 'Not Required', label: 'Not Required' },
  ];
  const prefernce_list = [
    { value: 'No Preference', label: 'No Preference' }
  ];
  const airline_provider_list = [
    { value: 'Air Miles', label: 'Air Miles' },
    { value: 'Air New Zealand Air Points', label: 'Air New Zealand Air Points' },
    { value: 'Asiana Club', label: 'Asiana Club' },
    { value: 'Life Miles', label: 'Life Miles' },
    { value: 'Delta Skymiles', label: 'Delta Skymiles' },

  ];
const priceList = [
    { value: 'Price', label: 'Price' },
    { value: 'Duration', label: 'Duration' },
    { value: 'Price + Duration ', label: 'Price + Duration ' },
    { value: 'Outbound Departure Time', label: 'Outbound Departure Time' },
    { value: 'Outbound Arrival Time', label: 'Outbound Arrival Time' },
    { value: 'Outbound Duration', label: 'Outbound Duration' }, 
    { value: 'Outbound Airline', label: 'Outbound Airline' },
    { value: 'Inbound Arrival Time', label: 'Inbound Arrival Time' },
    { value: 'Inbound Duration', label: 'Inbound Duration' },
  ];
//   const sortList = [
//     { value: 'Ascending A-Z', label: 'Ascending A-Z' },
//     { value: 'Decending Z-A', label: 'Decending Z-A' },
//   ];  

// let isClicked = false;

const FlightBook = () => {
useDocumentTitle('Flight Book');
const [error1, setError] = useState();

const test = () => {
    setError({
        title: "Flight Details",
        message: 
        <div className='col-md-12'>
            <div className='gt-h-400px'>
            <div className='row'>
                <div className='col-md-6 h6 text-muted my-auto'><i className="fa-solid fa-clock"></i> Flight Duration</div>
                <div className='col-md-6 h6 text-muted my-auto'> 24h 30m</div>
            </div>
            <hr className='my-1'></hr>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Flight Number</div>
                <div className='col-md-6 text-12px my-auto'> BA 108 - AIRBUS INDUSTRIE A330-300 JET</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Journey Duration</div>
                <div className='col-md-6 text-12px my-auto'> 24h 05m</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Flight Status</div>
                <div className='col-md-6 text-12px my-auto'> On Schedule</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Airport Location Code</div>
                <div className='col-md-6 text-12px my-auto'> LGW</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Airport</div>
                <div className='col-md-6 text-12px my-auto'> LGW - London Gadwick, GB</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport Location Code</div>
                <div className='col-md-6 text-12px my-auto'> CDG</div>
            </div> */}
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport Diversion</div>
                <div className='col-md-6 text-12px my-auto'> False</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Airport</div>
                <div className='col-md-6 text-12px my-auto'> CDG - Paris Charles de Gaulle, FR</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Validating Airline Code</div>
                <div className='col-md-6 text-12px my-auto'> U2</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Validating Airline </div>
                <div className='col-md-6 text-12px my-auto'>U2 - Easyjet</div>
            </div>
            {/* <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Equipment</div>
                <div className='col-md-6 text-12px my-auto'></div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Equipment Airline Type</div>
                <div className='col-md-6 text-12px my-auto'>319</div>
            </div> */}
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Departure Date Time Scheduled</div>
                <div className='col-md-6 text-12px my-auto'>7th Jun 2023, 12:00 AM</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Arrival Date Time Scheduled</div>
                <div className='col-md-6 text-12px my-auto'>9th Jun 2023, 12:00 AM</div>
            </div>
            <div className='row'>
                <div className='col-md-6 text-12px my-auto'>Comments</div>
                <div className='col-md-6 text-12px my-auto'>LGW CDG-9/NON-SMOKING</div>
            </div>
            </div>
        </div>
        
        
        
        ,
        // onConfirm={errorHandler}
    });
}  

const fareSummery = () => {
    setError({
        title: "Fare Summary",
        message: <div className='col-md-12'>
            <div className='gt-h-400px'>
                <div className='row'>
                    <div className='col-md-6 h5 text-muted my-auto'>Fare Breakup</div>
                    <div className='col-md-h5 h6 text-muted my-auto'></div>
                </div>
                <hr className=''></hr>
                <div className='row'>
                    <div className='col-md-4 h6 my-auto fw-600'>Total</div>
                    <div className='col-md-4 h6 my-auto fw-600'>$ 7560.05</div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-12px my-auto'>Base Fare</div>
                    <div className='col-md-4 text-12px my-auto'>$ 7000</div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-12px my-auto'>Surcharges</div>
                    <div className='col-md-4 text-12px my-auto'>$ 500</div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-12px text-primary my-auto'>Instant discount applied</div>
                    <div className='col-md-4 text-12px text-primary my-auto'>$ 60.05</div>
                </div>
                {/* <div className='row mt-4'>
                    <div className='col-md-6 h5 text-muted my-auto'>Cancellation Policy</div>
                    <div className='col-md-h5 h6 text-muted my-auto'></div>
                </div>
                <hr className=''></hr>
                <div className='row'>
                    <div className='col-md-4 h6 my-auto fw-600 mb-2'>Time frame</div>
                    <div className='col-md-4 h6 my-auto fw-600 mb-2'>Airline Fee + MMT Fee</div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-12px my-auto'>0 hours to 2 hours*</div>
                    <div className='col-md-4 text-12px my-auto'>ADULT : <span className='fw-bold'>Non Refundable</span></div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-12px my-auto'>2 hours to 365 days*</div>
                    <div className='col-md-4 text-12px my-auto'>ADULT : ₹ 8,400 + ₹ 300</div>
                </div> */}
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
}  

const viewSeat = () => {
    setError({
        title: "View Seats",
        message: <div className='col-md-12'>
            <div className='gt-h-400px position-relative'>
                <div className='row'>
                    <div className='col-md-5 position relative'>
                        <div className='position-fixed'>
                            <div className='col-md-12'>
                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                            </div>
                            <div className='col-md-12 mt-3'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                        <hr></hr>
                                        <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                        <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div className="plane-seats">
                            <div className="cockpit">
                                <h5 className='plane-text'>Please select a seat</h5>
                            </div>
                            <div className="exit exit--front fuselage">
                                
                            </div>
                            <ol className="cabin fuselage">
                                <li className="row row--1">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="1A" checked />
                                    <label for="1A">1A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="1B" />
                                    <label for="1B">1B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="1C" />
                                    <label for="1C">1C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" disabled id="1D" />
                                    <label for="1D">Occupied</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="1E" checked/>
                                    <label for="1E">1E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="1F" />
                                    <label for="1F">1F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--2">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="2A" />
                                    <label for="2A">2A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="2B" checked />
                                    <label for="2B">2B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="2C" />
                                    <label for="2C">2C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="2D" />
                                    <label for="2D">2D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="2E" />
                                    <label for="2E">2E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="2F" />
                                    <label for="2F">2F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--3">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="3A" />
                                    <label for="3A">3A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="3B" />
                                    <label for="3B">3B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="3C" />
                                    <label for="3C">3C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="3D" />
                                    <label for="3D">3D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="3E" />
                                    <label for="3E">3E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="3F" />
                                    <label for="3F">3F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--4">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="4A" />
                                    <label for="4A">4A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="4B" />
                                    <label for="4B">4B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="4C" />
                                    <label for="4C">4C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="4D" />
                                    <label for="4D">4D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="4E" />
                                    <label for="4E">4E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="4F" />
                                    <label for="4F">4F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--5">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="5A" />
                                    <label for="5A">5A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="5B" />
                                    <label for="5B">5B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="5C" />
                                    <label for="5C">5C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="5D" />
                                    <label for="5D">5D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="5E" />
                                    <label for="5E">5E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="5F" />
                                    <label for="5F">5F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--6">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="6A" />
                                    <label for="6A">6A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="6B" />
                                    <label for="6B">6B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="6C" />
                                    <label for="6C">6C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="6D" />
                                    <label for="6D">6D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="6E" />
                                    <label for="6E">6E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="6F" />
                                    <label for="6F">6F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--7">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="7A" />
                                    <label for="7A">7A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="7B" />
                                    <label for="7B">7B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="7C" />
                                    <label for="7C">7C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="7D" />
                                    <label for="7D">7D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="7E" />
                                    <label for="7E">7E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="7F" />
                                    <label for="7F">7F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--8">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="8A" />
                                    <label for="8A">8A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="8B" />
                                    <label for="8B">8B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="8C" />
                                    <label for="8C">8C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="8D" />
                                    <label for="8D">8D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="8E" />
                                    <label for="8E">8E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="8F" />
                                    <label for="8F">8F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--9">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="9A" />
                                    <label for="9A">9A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="9B" />
                                    <label for="9B">9B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="9C" />
                                    <label for="9C">9C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="9D" />
                                    <label for="9D">9D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="9E" />
                                    <label for="9E">9E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="9F" />
                                    <label for="9F">9F</label>
                                    </li>
                                </ol>
                                </li>
                                <li className="row row--10">
                                <ol className="seats" type="A">
                                    <li className="seat">
                                    <input type="checkbox" id="10A" />
                                    <label for="10A">10A</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="10B" />
                                    <label for="10B">10B</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="10C" />
                                    <label for="10C">10C</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="10D" />
                                    <label for="10D">10D</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="10E" />
                                    <label for="10E">10E</label>
                                    </li>
                                    <li className="seat">
                                    <input type="checkbox" id="10F" />
                                    <label for="10F">10F</label>
                                    </li>
                                </ol>
                                </li>
                            </ol>
                            <div className="exit exit--back fuselage">
                                
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <div className='col-md-12'>
                            <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                            <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                            <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                        </div>
                    </div> */}
                </div>
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
} 
const fareRule = () => {
    setError({
        title: "Fare Rules",
        message: <div className='col-md-12'>
            <div className='gt-h-400px'>
                <div className='row'>
                    <div className='col-md-12  my-auto'>
                        <Dropdown                
                        options={fare_rule}
                        textField=""
                        dataItemKey=""
                        arrowClosed={<span className="arrow-closed" />}
                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Rule Application and other condition"  />
                    </div>
                    <hr className='my-3'></hr>
                    <div className='text-12px'>
                    note - the following text is informational and not validated for autopricing.
air canada restricted fare //-a-// application area these fares apply from
area 2area 3 to area 1. class of service these fares apply for economy class
service. capacity limitations the carrier shall limit the number of passengers
carried on any one flight at fares governed by this rule and such fares will
not necessarily be available on all fights. the number of seats, which the
cartier shall make available on a given flight, will be determined by the
carrier's best judgement. other conditions sequential use of flight coupons
~ this fare is only valid if the flights are taken in the booked sequence.
‘otherwise the fare will be recalculated based on the actual flight routing.

                    </div>
                    
                </div>
               
            </div>
       
    </div>,
        // onConfirm={errorHandler}
    });
}  

const errorHandler = () => {
    setError(null);
};
return <Fragment>
{error1 && (
    <MdModal
        title={error1.title}
        message={error1.message}
        onConfirm={errorHandler}
    ></MdModal>
)}
        
{/*     <!-- Common Banner Area --> */}
    <section id="common_banner">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                    {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
     {/*   <!-- Form Area --> */}
    <section id="theme_search_form_tour"  className="fligth_top_search_main_form_wrapper">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                    <div  className="theme_search_form_area">
                        <div  className="row">
                            <div  className="col-lg-12">
                                <div  className="row">
                                    <div  className="col-lg-12">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Flight Summary</label>
                                            </div>
                                            <div className='col-md-9'>

                                                {/* <div className="accordion" id="accordionExampledetail">
                                                    <div className="row">
                                                        <div className="accordion_left_side">
                                                            <div className='row mt-2'>
                                                                <div className='col-md-3'>
                                                                    <div className='row'>
                                                                        <div className='col-md-2  flight-details'>
                                                                            <div  className="arrow_right"></div>
                                                                        </div>
                                                                        <div className='col-md-10'>
                                                                            <div className=''>Dubai - New york</div>
                                                                            <div className=''>06 Feb, 2023</div>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <div className='row'>
                                                                        <div className='col-md-2  flight-details'>
                                                                            <div  className="arrow_right gt-return-flight"></div>
                                                                        </div>
                                                                        <div className='col-md-10'>
                                                                            <div className=''>New york - Dubai</div>
                                                                            <div className=''>13 Feb, 2023</div>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className='col-md-2'> 
                                                                    <div className='h6 text-primary'>Ticket Type</div>
                                                                    <div className=''>Economy</div>
                                                                
                                                                </div>
                                                                <div className='col-md-2'> 
                                                                    <div className='h6 text-primary'>Travellers</div>
                                                                    <div className=''>1</div>
                                                                
                                                                </div>
                                                                <div className='col-md-2'>
                                                                
                                                                    <div className="accordion-item border-not">
                                                                        <h2 className="accordion-header" id="headingThree">
                                                                            <button className="p-0 border-0 h6 text-primary accordion-button collapsed" type="button"
                                                                                data-bs-toggle="collapse" data-bs-target="#flightsummery"
                                                                                aria-expanded="false" aria-controls="flightsummery">
                                                                                Summary Details
                                                                            </button>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="flightsummery" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExampledetail">
                                                    <div className="flight_policy_refund">
                                                        <div className='row py-2'>
                                                            <div className='col-md-3 border-right-dotted'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <div> <img src="assets/img/common/Airline-logo.svg" alt="img" className=' gt-h40'/></div>
                                                                        <div className='text-12px'>EK-31</div>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <div className='text-12px'>Emirates Airlines<br></br>EK31 Airbus industries A380-800 JET</div>
                                                                        <div className='text-12px text-primary'>Economy(L)</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-9'>
                                                                <div className='row'>
                                                                    <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                        <div>DXB</div>
                                                                        <div>12:10 PM</div>
                                                                        <div >Mon, 06 Feb 2023</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-12px'>
                                                                        <div>Dubai</div>
                                                                        <div >Terminal: 3</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                        <div>LHR</div>
                                                                        <div>04:10 PM</div>
                                                                        <div >Mon, 06 Feb 2023</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-12px'>
                                                                        <div>London</div>
                                                                        <div >Terminal: 3</div>
                                                                    </div>
                                                                </div>
                                                                <hr className='my-1'></hr>
                                                                <div className='row'>
                                                                    <div className='col-md-6 text-12px'>Baggage Allowed : 15kg</div>
                                                                    <div className='col-md-6'>
                                                                            <div className='text-primary text-12px fw-bold float-lg-end'><i className="fas fa-tachometer-alt"></i> 1131 Air miles</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <div className='row my-2'>
                                                            <div className='col-md-12 text-center gt-time-btn-flight'>Time between flight: 02 hour(s) 05 minute(s)</div>
                                                        </div>
                                                        <div className='row py-2'>
                                                            <div className='col-md-3 border-right-dotted'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'>
                                                                        <div> <img src="assets/img/common/Airline-logo.svg" alt="img" className=' gt-h40'/></div>
                                                                        <div className='text-12px'>EK-31</div>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <div className='text-12px'>Emirates Airlines<br></br>EK31 Airbus industries A380-800 JET</div>
                                                                        <div className='text-12px text-primary'>Economy(L)</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-9'>
                                                                <div className='row'>
                                                                    <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                        <div>LHR</div>
                                                                        <div>12:10 PM</div>
                                                                        <div >Mon, 06 Feb 2023</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-12px'>
                                                                        <div>London</div>
                                                                        <div >Terminal: 3</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                        <div>JFK</div>
                                                                        <div>04:10 PM</div>
                                                                        <div >Mon, 06 Feb 2023</div>
                                                                    </div>
                                                                    <div className='col-md-3 text-12px'>
                                                                        <div>New York</div>
                                                                        <div >Terminal: 3</div>
                                                                    </div>
                                                                </div>
                                                                <hr className='my-1'></hr>
                                                                <div className='row'>
                                                                    <div className='col-md-6 text-12px'>Baggage Allowed : 15kg</div>
                                                                    <div className='col-md-6'>
                                                                            <div className='text-primary text-12px fw-bold float-lg-end'><i className="fas fa-tachometer-alt"></i> 3452 Air miles</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>   */}



                                                <div  className="flight_search_item_wrappper">
                                                    <div  className="flight_search_items">
                                                        <div  className="col-md-9 multi_city_flight_lists">
                                                            <div  className="flight_multis_area_wrapper">
                                                                
                                                                <div  className="flight_search_left col-md-5">
                                                                    <div  className="flight_logo">
                                                                        {/* <img src="assets/img/common/biman_bangla.png" alt="img"/> */}
                                                                        <img src="assets/img/common/Airline-logo.svg" alt="img" className=' p-1 gt-h40'/>
                                                                        <div  className="text-12px text-black fw-bold">British Airways</div>
                                                                        <div  className="text-muted text-8px">BA 108</div>
                                                                    </div>
                                                                    <div  className="flight_search_destination">
                                                                        <p>From</p>
                                                                        <h3>Dubai</h3>
                                                                        <h6>7th Jun 2023, 12:00 AM</h6>
                                                                    </div>
                                                                </div>
                                                                <div  className="flight_search_middel col-md-7">
                                                                    <div  className="flight_right_arrow col-md-5">
                                                                        <img src="assets/img/icon/right_arrow.png" alt="icon"/>
                                                                        <h6>24h  05m</h6>
                                                                        <p>1 stop </p>
                                                                    </div>
                                                                    <div  className="flight_search_destination col-md-7">
                                                                        <p>To</p>
                                                                        <h3>London </h3>
                                                                        <h6>9th Jun 2023, 10:30 PM</h6>
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                            <hr className='my-1'></hr>
                                                            <div  className="flight_multis_area_wrapper">
                                                                
                                                                <div  className="flight_search_left col-md-5">
                                                                    <div  className="flight_logo">
                                                                        {/* <img src="assets/img/common/biman_bangla.png" alt="img"/> */}
                                                                        <img src="assets/img/common/Airline-logo.svg" alt="img" className=' p-1 gt-h40'/>
                                                                        <div  className="text-12px text-black fw-bold">British Airways</div>
                                                                        <div  className="text-muted text-8px">BA 108</div>
                                                                    </div>
                                                                    <div  className="flight_search_destination">
                                                                        <p>From</p>
                                                                        <h3>London</h3>
                                                                        <h6>9th Jun 2023, 12:00 AM</h6>
                                                                    </div>
                                                                </div>
                                                                <div  className="flight_search_middel col-md-7">
                                                                    <div  className="flight_right_arrow col-md-5">
                                                                        <img src="assets/img/icon/right_arrow.png" alt="icon"/>
                                                                        <h6>24h  05m</h6>
                                                                        <p>1 stop </p>
                                                                    </div>
                                                                    <div  className="flight_search_destination col-md-7">
                                                                        <p>To</p>
                                                                        <h3>Dubai </h3>
                                                                        <h6>11th Jun 2023, 12:00 AM</h6>
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                            
                                                            <hr className='my-1'></hr>

                                                            <div className='px-3'>
                                                                <div className='col-md-12 text-14px text-muted pb-3'>Source: <span className='text-primary fw-bold'>Sabre</span> | PCC: <span className='text-primary fw-bold'>U29J</span>| Fare Type: <span className='text-primary fw-bold'>Published Fares</span> | Markup: <span className='text-primary fw-bold'>10%</span> </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    {/* <div className='position-relative'>
                                                                        <span>
                                                                        <div className='btn btn_theme btn-sm px-2 text-14px email-btn' title='Send a Mail'><i className="fas fa-envelope"></i></div>
                                                                        <div className='btn btn_theme btn-sm px-2 text-14px print-btn ms-1' title='Take a Print'><i className="fas fa-print"></i></div>
                                                                        </span>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div  className="flight_search_right col-md-3">
                                                            
                                                            {/* <div className='text-12px text-danger'><i className="fas fa-circle text-8px"></i> Non Refundable</div> */}
                                                            {/* <h2 className=''>$7,560.05</h2> */}
                                                            {/* <a href="flight-booking-submission.html"  className="btn btn_theme btn_sm">Book
                                                                now</a> */}
                                                            <p className='text-primary fw-bold gt-lh-20'><i className="fas fa-walking"></i> Only 4 seat Available. <span className='ms-2' title="Your arrival airport LON is different  from your choosen destination PAR. Your depature airport LON is different from your choosen destination PAR."><i className="fas fa-info-circle text-danger fa-2x"></i></span></p>    
                                                            <p><span className=''><a  href='javascript:void(0);' className='text-14px' onClick={fareRule}><i className="fas fa-clipboard"></i> Fare Rules</a></span>
                                                            <span className='ms-2'>
                                                                {/* <a href=''><i className="fas fa-suitcase-rolling"></i> Baggage</a> */}
                                                                </span></p>
                                                            <h6 className='text-primary' data-bs-toggle="collapse" data-bs-target="#collapseExample"
                                                                aria-controls="collapseExample">Show Details <i
                                                                    className="fas fa-chevron-down"></i></h6>
                                                        </div>
                                                    </div>
                                                    <div  className="flight_policy_refund collapse" id="collapseExample">
                                                        <div className='row'>
                                                            <div className='col-md-5'>
                                                                <div className='departure-text'>
                                                                <span><i className="fas fa-plane-departure"></i></span> Departure
                                                                </div>
                                                            </div>
                                                            <div className='col-md-7'>
                                                                <div className='pt-10 float-end'>
                                                                    <span className='gt-seat-btn'><a href='javascript:void(0);' onClick={test} ><i className="fas fa-plane"></i> Flight Details</a></span>
                                                                    {/* <span className='gt-seat-btn ms-1'><a  href='javascript:void(0);' onClick={fareSummery}><i className="fas fa-history"></i> Fare Summary</a></span> */}
                                                                    {/* <span className='gt-seat-btn ms-1'><a  href='javascript:void(0);' onClick={viewSeat}><i className="fas fa-chair"></i> View Seats</a></span> */}
                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div  className="flight_show_down_wrapper">
                                                        
                                                            <div  className="flight-shoe_dow_item">
                                                                <div  className="airline-details">
                                                                    <div  className="img"><img src="assets/img/common/Airline-logo.svg" alt="img"/></div>
                                                                    <span  className="airlineName fw-500">British Airways &nbsp;
                                                                        AC6799</span> <span className='text-primary airlineName fw-500'> (Economy)</span>
                                                                    <span  className="flightNumber">AIRBUS INDUSTRIE A330-300 JET</span>
                                                                </div>
                                                                <div  className="flight_inner_show_component">
                                                                    <div  className="flight_det_wrapper">
                                                                        <div  className="flight_det">
                                                                            <div  className="code_time">
                                                                                <span  className="code">Dubai</span>
                                                                            </div>
                                                                            <div  className="code_time">
                                                                                <span  className="time">12:00 AM</span>
                                                                            </div>
                                                                            <p  className="date">7th Jun 2023</p>
                                                                            <p  className="airport">DXB - Dubai, United Arab Emirates, AE
                                                                            </p>
                                                                            <p className="airport mb-2">Terminal 1</p>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div  className="flight_duration">
                                                                        <div  className="arrow_right"></div>
                                                                        <span>23h 43m</span>
                                                                        <p> 1 stop</p>
                                                                    </div>
                                                                    <div  className="flight_det_wrapper">
                                                                        <div  className="flight_det">
                                                                            <div  className="code_time">
                                                                                <span  className="code">London</span>
                                                                            </div>
                                                                            <div  className="code_time">
                                                                                <span  className="time">14:00</span>
                                                                            </div>
                                                                            <p  className="date">9th Jun 2023</p>
                                                                            <p  className="airport">LON - London, Gatwick Apt, United Kingdom, GB 
                                                                            </p>
                                                                            <p className="airport mb-2">Terminal 2</p>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-2'>
                                                                <div className='col-md-6'>
                                                                        <div className='text-primary fw-bold text-12px'><i className="fas fa-info-circle"></i> This flight arrives the next day.</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                        <div className='text-primary text-12px fw-bold'><i className="fas fa-tachometer-alt"></i> 1131 Air miles</div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div  className="flight_refund_policy row">
                                                        
                                                                <div  className="TabPanelInner col-sm-8">
                                                                <h4 className='col-md-12'><span className='gt-border-bottom'>Baggage Allowed</span></h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p>Per Adult</p>
                                                                    </div>
                                                                </div>
                                                                <div  className="TabPanelInner col-sm-4">
                                                                    <h4 className='col-md-12'>  &nbsp; &nbsp;</h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p><span>64 Kgs &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                                                    </div>
                                                                </div>
                                                                {/* <div  className="TabPanelInner col-sm-4">
                                                                    <h4 className='col-md-12'>Class</h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p><span>Economy <br></br> &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12 text-center gt-time-btn-flight'>Time between flight: 00 hour(s) 20 minute(s)</div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-5'>
                                                                <div className='departure-text'>
                                                                <span className=''> <i  className="fas fa-plane-departure gt-return-flight"></i> </span> Return
                                                                </div>
                                                            </div>
                                                            <div className='col-md-7'>
                                                                <div className='pt-10 float-end'>
                                                                <span className='gt-seat-btn'><a href='javascript:void(0);' onClick={test} ><i className="fas fa-plane"></i> Flight Details</a></span>
                                                                {/* <span className='gt-seat-btn ms-1'><a  href='javascript:void(0);' onClick={fareSummery}><i className="fas fa-history"></i> Fare Summary</a></span> */}
                                                                {/* <span className='gt-seat-btn ms-1'><a  href='javascript:void(0);' onClick={viewSeat}><i className="fas fa-chair"></i> View Seats</a></span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div  className="flight_show_down_wrapper">
                                                        
                                                            <div  className="flight-shoe_dow_item">
                                                                <div  className="airline-details">
                                                                    <div  className="img"><img src="assets/img/common/Airline-logo.svg" alt="img"/></div>
                                                                    <span  className="airlineName fw-500">British Airways &nbsp;
                                                                        AC6799</span> <span className='text-primary airlineName fw-500'> (Economy)</span>
                                                                    <span  className="flightNumber"> BOEING 737-800 - 738</span>
                                                                </div>
                                                                <div  className="flight_inner_show_component">
                                                                    <div  className="flight_det_wrapper">
                                                                        <div  className="flight_det">
                                                                            <div  className="code_time">
                                                                                <span  className="code">London</span>
                                                                            </div>
                                                                            <div  className="code_time">
                                                                                <span  className="time">15:00</span>
                                                                            </div>
                                                                            <p  className="date">9th Jun 2023</p>
                                                                            <p  className="airport">LON - London, Gatwick Apt, United Kingdom, GB 
                                                                            </p>
                                                                            <p className="airport mb-2">Terminal 2</p>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div  className="flight_duration">
                                                                        <div  className="arrow_right gt-return-flight"></div>
                                                                        <span>24h 01m</span>
                                                                        <p> 1 stop</p>
                                                                    </div>
                                                                    <div  className="flight_det_wrapper">
                                                                        <div  className="flight_det">
                                                                            <div  className="code_time">
                                                                                <span  className="code">Dubai</span>
                                                                            </div>
                                                                            <div  className="code_time">
                                                                                <span  className="time">15:00</span>
                                                                            </div>
                                                                            <p  className="date">11th Jun 2023</p>
                                                                            <p  className="airport">DXB - Dubai, United Arab Emirates, AE
                                                                            </p>
                                                                            <p className="airport mb-2">Terminal 1</p>
                                                                            
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className='row mb-2'>
                                                                    <div className='text-primary text-12px fw-bold'><i className="fas fa-tachometer-alt"></i> 1131 Air miles</div>
                                                                </div>
                                                            </div>
                                                            <div  className="flight_refund_policy row">
                                                                <div  className="TabPanelInner col-sm-8">
                                                                    <h4 className='col-md-12'><span className='gt-border-bottom'>Baggage Allowed</span></h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p>Per Adult</p>
                                                                    </div>
                                                                </div>
                                                                <div  className="TabPanelInner col-sm-4">
                                                                    <h4 className='col-md-12'>  &nbsp; &nbsp;</h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p><span>64 Kgs &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                                                    </div>
                                                                </div>
                                                                {/* <div  className="TabPanelInner col-sm-4">
                                                                    <h4 className='col-md-12'>Class</h4>
                                                                    <div  className="flight_info_taable">
                                                                        <p><span>Economy <br></br> &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <hr className=''></hr>



                                                {/* <div className="flight_policy_refund collapse mb-2" id="collapseExample">
                                                    <div className='row py-2'>
                                                        <div className='col-md-3 border-right-dotted'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <div> <img src="assets/img/common/Airline-logo.svg" alt="img" className=' gt-h40'/></div>
                                                                    <div className='text-12px'>EK-31</div>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <div className='text-12px'>Emirates Airlines<br></br>EK31 Airbus industries A380-800 JET</div>
                                                                    <div className='text-12px text-primary'>Economy(L)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <div className='row'>
                                                                <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                    <div>DXB</div>
                                                                    <div>12:10 PM</div>
                                                                    <div >Mon, 06 Feb 2023</div>
                                                                </div>
                                                                <div className='col-md-3 text-12px'>
                                                                    <div>Dubai</div>
                                                                    <div >Terminal: 3</div>
                                                                </div>
                                                                <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                    <div>LHR</div>
                                                                    <div>04:10 PM</div>
                                                                    <div >Mon, 06 Feb 2023</div>
                                                                </div>
                                                                <div className='col-md-3 text-12px'>
                                                                    <div>London</div>
                                                                    <div >Terminal: 3</div>
                                                                </div>
                                                            </div>
                                                            <hr className='my-1'></hr>
                                                            <div className='row'>
                                                                <div className='col-md-6 text-12px'>Baggage Allowed : 15kg</div>
                                                                <div className='col-md-6'>
                                                                        <div className='text-primary text-12px fw-bold float-lg-end'><i className="fas fa-tachometer-alt"></i> 1131 Air miles</div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-md-12 text-center gt-time-btn-flight'>Time between flight: 02 hour(s) 05 minute(s)</div>
                                                    </div>
                                                    <div className='row py-2'>
                                                        <div className='col-md-3 border-right-dotted'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <div> <img src="assets/img/common/Airline-logo.svg" alt="img" className=' gt-h40'/></div>
                                                                    <div className='text-12px'>EK-31</div>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <div className='text-12px'>Emirates Airlines<br></br>EK31 Airbus industries A380-800 JET</div>
                                                                    <div className='text-12px text-primary'>Economy(L)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <div className='row'>
                                                                <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                    <div>LHR</div>
                                                                    <div>12:10 PM</div>
                                                                    <div >Mon, 06 Feb 2023</div>
                                                                </div>
                                                                <div className='col-md-3 text-12px'>
                                                                    <div>London</div>
                                                                    <div >Terminal: 3</div>
                                                                </div>
                                                                <div className='col-md-3 text-primary text-12px fw-bold'>
                                                                    <div>JFK</div>
                                                                    <div>04:10 PM</div>
                                                                    <div >Mon, 06 Feb 2023</div>
                                                                </div>
                                                                <div className='col-md-3 text-12px'>
                                                                    <div>New York</div>
                                                                    <div >Terminal: 3</div>
                                                                </div>
                                                            </div>
                                                            <hr className='my-1'></hr>
                                                            <div className='row'>
                                                                <div className='col-md-6 text-12px'>Baggage Allowed : 15kg</div>
                                                                <div className='col-md-6'>
                                                                        <div className='text-primary text-12px fw-bold float-lg-end'><i className="fas fa-tachometer-alt"></i> 3452 Air miles</div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="tour_details_boxed_inner">
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>1</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseOne" aria-expanded="true"
                                                                        aria-controls="collapseOne">
                                                                        Traveler Details
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne" className="accordion-collapse collapse show"
                                                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className=''>
                                                                        <div className='row my-2'>
                                                                            <div className='col-md-6 text-primary h6'><i className='fa fa-user text-12px'></i> Traveler 1 (Adult 12yrs+)</div>
                                                                            <div className='col-md-6'>

                                                                                <div className='float-lg-end'>
                                                                                    <a className=' ms-1 text-12px text-skyblue'><i className='fa fa-plus'></i> Add Adult</a>
                                                                                    {/* <a className='btn btn-sm ms-1  text-12px btn-primary'><i className='fa fa-plus'></i> Add Child</a> */}
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-md-2'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={titles_name}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-10'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Middle Name</label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Gender<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={gender_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Nationality</label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={wheel_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Date of Birth <i title=
                                                                                    "Date of Birth(Age of Adult should be 12 or above on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        
                                                                        <div className='row'>
                                                                            {/* <div className='col-md-12'>
                                                                                <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Passport Details -</label>
                                                                            </div> */}
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport No. </label>
                                                                                    <input  type='text' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Issuing Country </label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={wheel_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Expiry <i title=
                                                                                    "Passport Expiry should be more than 6 months from date of travel." className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className=''></hr>
                                                                    <div className=''>
                                                                        <div className='row my-2'>
                                                                            <div className='col-md-6 text-primary h6'><i className='fa fa-user text-12px'></i> Traveler 2 (Child btween 1-12yrs)</div>
                                                                            <div className='col-md-6'>

                                                                                <div className='float-lg-end'>
                                                                                    {/* <a className='btn btn-sm ms-1 text-12px btn-primary'><i className='fa fa-plus'></i> Add Adult</a> */}
                                                                                    <a className='ms-1  text-12px text-sky-blue'><i className='fa fa-plus'></i> Add Child</a></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-md-2'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Title<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={titles_name_child}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-10'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>First Name<span className="error-icon"> *</span></label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Middle Name</label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-4'>
                                                                                        <div className='control'>
                                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Last Name<span className="error-icon"> *</span></label>
                                                                                            <input  type='text' className="mb-0 form-control" id='fname'></input>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Gender<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={gender_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Date of Birth <i title=
                                                                                    "Date of Birth(Age of Child should be 2 - 12yrs on the date of travel)" className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Nationality</label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={wheel_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className='row'>
                                                                            {/* <div className='col-md-12'>
                                                                                <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Passport Details -</label>
                                                                            </div> */}
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport No. </label>
                                                                                    <input  type='text' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Issuing Country </label>
                                                                                    <Dropdown 
                                                                                        
                                                                                        options={wheel_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                    
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Passport Expiry <i title=
                                                                                    "Passport Expiry should be more than 6 months from date of travel." className="fa-solid fa-circle-info text-sky-blue"></i></label>
                                                                                    <input  type='date' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="float-end">
                                                                                {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>2</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                        aria-expanded="false" aria-controls="collapseTwo">Booking Contact Person
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseTwo" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Email<span className="error-icon"> *</span></label>
                                                                                    <input  type='text' className="mb-0 form-control" id='dob'></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone<span className="error-icon"> *</span></label>
                                                                                    <input  type='number' className="mb-0 form-control" id='phone'></input>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone at Destination</label>
                                                                                    <input  type='number' className="mb-0 form-control" id='phone'></input>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                                <div className="float-end">
                                                                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>3</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingThree">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                                        aria-expanded="false" aria-controls="collapseFour">
                                                                        Seat Selection
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseFour" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                                                    <div className='col-md-12'>
                                                                        <div class="row">
                                                                            <div class="col-md-12 pop-ups-tab-border-parent">
                                                                                <ul class="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                                    <li class="nav-item pb-0" role="presentation">
                                                                                        <button class="tabs-btn nav-link active" id="departure" data-bs-toggle="tab"
                                                                                        data-bs-target="#departure-tb" type="button" role="tab" aria-controls="departure-tb"
                                                                                        aria-selected="true">Departure</button>
                                                                                    </li>
                                                                                    <li class="nav-item pb-0" role="presentation">
                                                                                        <button class="tabs-btn nav-link " id="return" data-bs-toggle="tab"
                                                                                        data-bs-target="#return-tb" type="button" role="tab" aria-controls="return-tb"
                                                                                        aria-selected="true">Return</button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="col-md-12 ">
                                                                                <div class="px-3 tab-content include-exclude-tab" id="myTabContent">
                                                                                    <div class="tab-pane fade show active" id="departure-tb"    role="tabpanel" aria-labelledby="departure">
                                                                                        <div className=''>
                                                                                            <div className=' position-relative'>
                                                                                                <div className='row mt-3'>
                                                                                                    <div className='col-md-5 position relative'>
                                                                                                        <div className=''>
                                                                                                            <div className='col-md-12'>
                                                                                                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                                                                                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                                                                                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                                                                                                            </div>
                                                                                                            <div className='col-md-12 mt-3'>
                                                                                                                <div className='card'>
                                                                                                                    <div className='card-body'>
                                                                                                                        <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                                                                                                        <hr></hr>
                                                                                                                        <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                                                                                                        <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='col-md-7 gt-h-400px'>
                                                                                                        <div className="plane-seats">
                                                                                                            <div className="cockpit">
                                                                                                                <h5 className='plane-text'>Please select a seat</h5>
                                                                                                            </div>
                                                                                                            <div className="exit exit--front fuselage">
                                                                                                                
                                                                                                            </div>
                                                                                                            <ol className="cabin fuselage">
                                                                                                                <li className="row row--1">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1A" checked />
                                                                                                                    <label for="1A">1A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1B" />
                                                                                                                    <label for="1B">1B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1C" />
                                                                                                                    <label for="1C">1C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" disabled id="1D" />
                                                                                                                    <label for="1D">Occupied</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1E" />
                                                                                                                    <label for="1E">1E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1F" />
                                                                                                                    <label for="1F">1F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--2">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2A" />
                                                                                                                    <label for="2A">2A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2B" />
                                                                                                                    <label for="2B">2B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2C" />
                                                                                                                    <label for="2C">2C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2D" />
                                                                                                                    <label for="2D">2D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2E" />
                                                                                                                    <label for="2E">2E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2F" />
                                                                                                                    <label for="2F">2F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--3">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3A" />
                                                                                                                    <label for="3A">3A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3B" />
                                                                                                                    <label for="3B">3B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3C" />
                                                                                                                    <label for="3C">3C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3D" />
                                                                                                                    <label for="3D">3D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3E" />
                                                                                                                    <label for="3E">3E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3F" />
                                                                                                                    <label for="3F">3F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--4">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4A" />
                                                                                                                    <label for="4A">4A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4B" />
                                                                                                                    <label for="4B">4B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4C" />
                                                                                                                    <label for="4C">4C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4D" />
                                                                                                                    <label for="4D">4D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4E" />
                                                                                                                    <label for="4E">4E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4F" />
                                                                                                                    <label for="4F">4F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--5">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5A" />
                                                                                                                    <label for="5A">5A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5B" />
                                                                                                                    <label for="5B">5B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5C" />
                                                                                                                    <label for="5C">5C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5D" />
                                                                                                                    <label for="5D">5D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5E" />
                                                                                                                    <label for="5E">5E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5F" />
                                                                                                                    <label for="5F">5F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--6">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6A" />
                                                                                                                    <label for="6A">6A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6B" />
                                                                                                                    <label for="6B">6B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6C" />
                                                                                                                    <label for="6C">6C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6D" />
                                                                                                                    <label for="6D">6D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6E" />
                                                                                                                    <label for="6E">6E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6F" />
                                                                                                                    <label for="6F">6F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--7">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7A" />
                                                                                                                    <label for="7A">7A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7B" />
                                                                                                                    <label for="7B">7B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7C" />
                                                                                                                    <label for="7C">7C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7D" />
                                                                                                                    <label for="7D">7D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7E" />
                                                                                                                    <label for="7E">7E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7F" />
                                                                                                                    <label for="7F">7F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--8">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8A" />
                                                                                                                    <label for="8A">8A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8B" />
                                                                                                                    <label for="8B">8B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8C" />
                                                                                                                    <label for="8C">8C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8D" />
                                                                                                                    <label for="8D">8D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8E" />
                                                                                                                    <label for="8E">8E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8F" />
                                                                                                                    <label for="8F">8F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--9">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9A" />
                                                                                                                    <label for="9A">9A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9B" />
                                                                                                                    <label for="9B">9B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9C" />
                                                                                                                    <label for="9C">9C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9D" />
                                                                                                                    <label for="9D">9D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9E" />
                                                                                                                    <label for="9E">9E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9F" />
                                                                                                                    <label for="9F">9F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--10">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10A" />
                                                                                                                    <label for="10A">10A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10B" />
                                                                                                                    <label for="10B">10B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10C" />
                                                                                                                    <label for="10C">10C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10D" />
                                                                                                                    <label for="10D">10D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10E" />
                                                                                                                    <label for="10E">10E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10F" />
                                                                                                                    <label for="10F">10F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                            </ol>
                                                                                                            <div className="exit exit--back fuselage">
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="tab-pane fade show " id="return-tb" role="tabpanel" aria-labelledby="return">
                                                                                    <div className=''>
                                                                                            <div className=' position-relative'>
                                                                                                <div className='row mt-3'>
                                                                                                    <div className='col-md-5 position relative'>
                                                                                                        <div className=''>
                                                                                                            <div className='col-md-12'>
                                                                                                                <div className='text-muted'><i className="fa-solid fa-square"></i> Restricted</div>
                                                                                                                <div className='text-warning'><i className="fa-solid fa-square"></i> Occupied</div>
                                                                                                                <div className='text-sky-blue'><i className="fa-solid fa-square"></i> Preferred</div>
                                                                                                            </div>
                                                                                                            <div className='col-md-12 mt-3'>
                                                                                                                <div className='card'>
                                                                                                                    <div className='card-body'>
                                                                                                                        <div className=''>DXB <i className="fa-solid fa-arrow-right text-sky-blue"></i> LON</div>
                                                                                                                        <hr></hr>
                                                                                                                        <div className='text-12px'><span className='fw-bold'>Date: </span> 7th Jun 2023, 12:00 AM</div>
                                                                                                                        <div className='text-12px'><span className='fw-bold'>Flight: </span> BA108</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='col-md-7 gt-h-400px'>
                                                                                                        <div className="plane-seats">
                                                                                                            <div className="cockpit">
                                                                                                                <h5 className='plane-text'>Please select a seat</h5>
                                                                                                            </div>
                                                                                                            <div className="exit exit--front fuselage">
                                                                                                                
                                                                                                            </div>
                                                                                                            <ol className="cabin fuselage">
                                                                                                                <li className="row row--1">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1A" checked />
                                                                                                                    <label for="1A">1A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1B" />
                                                                                                                    <label for="1B">1B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1C" />
                                                                                                                    <label for="1C">1C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" disabled id="1D" />
                                                                                                                    <label for="1D">Occupied</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1E" />
                                                                                                                    <label for="1E">1E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="1F" />
                                                                                                                    <label for="1F">1F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--2">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2A" />
                                                                                                                    <label for="2A">2A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2B" />
                                                                                                                    <label for="2B">2B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2C" />
                                                                                                                    <label for="2C">2C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2D" />
                                                                                                                    <label for="2D">2D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2E" />
                                                                                                                    <label for="2E">2E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="2F" />
                                                                                                                    <label for="2F">2F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--3">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3A" />
                                                                                                                    <label for="3A">3A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3B" />
                                                                                                                    <label for="3B">3B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3C" />
                                                                                                                    <label for="3C">3C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3D" />
                                                                                                                    <label for="3D">3D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3E" />
                                                                                                                    <label for="3E">3E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="3F" />
                                                                                                                    <label for="3F">3F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--4">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4A" />
                                                                                                                    <label for="4A">4A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4B" />
                                                                                                                    <label for="4B">4B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4C" />
                                                                                                                    <label for="4C">4C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4D" />
                                                                                                                    <label for="4D">4D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4E" />
                                                                                                                    <label for="4E">4E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="4F" />
                                                                                                                    <label for="4F">4F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--5">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5A" />
                                                                                                                    <label for="5A">5A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5B" />
                                                                                                                    <label for="5B">5B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5C" />
                                                                                                                    <label for="5C">5C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5D" />
                                                                                                                    <label for="5D">5D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5E" />
                                                                                                                    <label for="5E">5E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="5F" />
                                                                                                                    <label for="5F">5F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--6">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6A" />
                                                                                                                    <label for="6A">6A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6B" />
                                                                                                                    <label for="6B">6B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6C" />
                                                                                                                    <label for="6C">6C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6D" />
                                                                                                                    <label for="6D">6D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6E" />
                                                                                                                    <label for="6E">6E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="6F" />
                                                                                                                    <label for="6F">6F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--7">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7A" />
                                                                                                                    <label for="7A">7A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7B" />
                                                                                                                    <label for="7B">7B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7C" />
                                                                                                                    <label for="7C">7C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7D" />
                                                                                                                    <label for="7D">7D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7E" />
                                                                                                                    <label for="7E">7E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="7F" />
                                                                                                                    <label for="7F">7F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--8">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8A" />
                                                                                                                    <label for="8A">8A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8B" />
                                                                                                                    <label for="8B">8B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8C" />
                                                                                                                    <label for="8C">8C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8D" />
                                                                                                                    <label for="8D">8D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8E" />
                                                                                                                    <label for="8E">8E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="8F" />
                                                                                                                    <label for="8F">8F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--9">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9A" />
                                                                                                                    <label for="9A">9A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9B" />
                                                                                                                    <label for="9B">9B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9C" />
                                                                                                                    <label for="9C">9C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9D" />
                                                                                                                    <label for="9D">9D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9E" />
                                                                                                                    <label for="9E">9E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="9F" />
                                                                                                                    <label for="9F">9F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                                <li className="row row--10">
                                                                                                                <ol className="seats" type="A">
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10A" />
                                                                                                                    <label for="10A">10A</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10B" />
                                                                                                                    <label for="10B">10B</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10C" />
                                                                                                                    <label for="10C">10C</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10D" />
                                                                                                                    <label for="10D">10D</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10E" />
                                                                                                                    <label for="10E">10E</label>
                                                                                                                    </li>
                                                                                                                    <li className="seat">
                                                                                                                    <input type="checkbox" id="10F" />
                                                                                                                    <label for="10F">10F</label>
                                                                                                                    </li>
                                                                                                                </ol>
                                                                                                                </li>
                                                                                                            </ol>
                                                                                                            <div className="exit exit--back fuselage">
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>    
                                                                            </div>
                                                                        </div>
                                                                        <div className='row my-4'>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                                                <div className="float-end">
                                                                                    <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button>
                                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>4</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingThree">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                                                        aria-expanded="false" aria-controls="collapseseven">
                                                                        Additional Service - Meal preference, Frequent flyer and more
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseseven" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                   <hr className=''></hr>
                                                                    <div className='row'>
                                                                        <div className='col-md-12'>
                                                                            <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Additional Information -</label>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Frequent Flyer Airline</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={airline_provider_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Frequent Flyer Number </label>
                                                                                
                                                                                 <input  type='text' className="mb-0 form-control" id='PN'></input>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Special Service Request</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={wheel_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <hr className=''></hr>
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <label className="h6 text-muted mb-3 col-lg-12" htmlFor='category'>Meal Preference -</label>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-check float-lg-end">
                                                                                <input className="form-check-input" type="checkbox" id="chkChainCodeSelectAll"></input>
                                                                                <label className="form-check-label" for="chkChainCodeSelectAll">
                                                                                    Select All Flights
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='row my-3'>
                                                                            <div className='col-md-2'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" id="veg" checked></input>
                                                                                    <label className="form-check-label" for="veg">
                                                                                        Veg
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" id="nonveg" checked></input>
                                                                                    <label className="form-check-label" for="nonveg">
                                                                                        Non Veg
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <div className='control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight # EK1 (DXB > LHR)</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={prefernce_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className='control'>
                                                                                 <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Meal Preference</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={prefernce_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <div className='control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Flight # EK2 (LHR > DXB)</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={prefernce_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className='control'>
                                                                                 <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Meal Preference</label>
                                                                                <Dropdown 
                                                                                    
                                                                                    options={prefernce_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                                                            <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="float-end">
                                                                                <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button>
                                                                                <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                {/* </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>5</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingThree">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                                        aria-expanded="false" aria-controls="collapseThree">
                                                                        Add Service Fee
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseThree" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                   <div className='row'>
                                                                        {/* <div className='col-md-12'>
                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Add Service Fee</label>
                                                                        </div> */}
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <input  type='text' className="mb-0 form-control" id='markup'></input>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                            <Dropdown 
                                                                                    
                                                                                    options={prefernce_list}
                                                                                    textField=""
                                                                                    dataItemKey=""
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                  
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 my-2">
                                                                            <div className="">
                                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Continue</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>6</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingThree">
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                                        aria-expanded="false" aria-controls="collapseFive">
                                                                        Payment Information
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseFive" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                                                   <div className="payment_filed_wrapper">
                                                                        <div className='row my-2'>
                                                                            {/* <div className='col-lg-2 text-muted mb-1 fw-bold h6'>Fare Type </div> */}
                                                                        
                                                                            <div className="col-lg-6 col-md-6 col-sm-6  text-muted">
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="white" checked />
                                                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                                                    Specify Credit Card
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6  text-muted">   
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="white"/>
                                                                                    <label className="form-check-label" for="flexRadioDefault2">
                                                                                    Pay using Sabre profile Credit Card
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2' htmlFor='Country'> Card Type</label>
                                                                                    <Dropdown                
                                                                                        options={card_list}
                                                                                        textField=""
                                                                                        dataItemKey=""
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"  />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                               <div className='control'>
                                                                                <div className="form-group">
                                                                                <label className='h6 text-primary mb-2' htmlFor='Country'> Card number</label>
                                                                                        <input type="text" className="form-control bg_input"
                                                                                            placeholder=""/>
                                                                                    </div>
                                                                               </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2' htmlFor='Country'>Cardholder name</label>
                                                                                        <input type="text" className="form-control bg_input"
                                                                                            placeholder=""/>
                                                                                    </div>
                                                                                </div>    
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2' htmlFor='Country'> Date of expiry</label>
                                                                                        <input type="date" className="form-control bg_input"
                                                                                            placeholder=""/>
                                                                                    </div>
                                                                                </div>    
                                                                            </div>
                                                                           
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='control input-placeholder'>
                                                                                <label htmlFor='address' className='h6 text-primary mb-2'>Address<span className="error-icon"> *</span></label>
                                                                            
                                                                                <textarea id='address'
                                                                                    
                                                                                    className="form-control form-control-sm"
                                                                                 
                                                                                    rows={3} cols={100} maxLength={300}
                                                                                
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-4'>
                                                                            <div className='col-md-6'>
                                                                                <div className='control'>
                                                                                    <label className='h6 text-primary mb-2' htmlFor='Country'>Country<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                                
                                                                                                options={wheel_list}
                                                                                                textField=""
                                                                                                dataItemKey=""
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                            
                                                                                            />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-6'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>State<span className="error-icon"> </span></label>
                                                                                    <Dropdown 
                                                                                                
                                                                                            options={wheel_list}
                                                                                            textField=""
                                                                                            dataItemKey=""
                                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                            
                                                                                        />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>City<span className="error-icon"> *</span></label>
                                                                                    <Dropdown 
                                                                                                
                                                                                                options={wheel_list}
                                                                                                textField=""
                                                                                                dataItemKey=""
                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                                            
                                                                                            />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-6 col-sm-12'>
                                                                                <div className='control input-placeholder'>
                                                                                    <label htmlFor='postalCode' className='h6 text-primary mb-2'>Postal Code<span className="error-icon"> *</span></label>
                                                                                    <input maxLength={12} className="form-control form-control-sm" type='text' 
                                                                                    />
                                                                                  
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 my-auto">
                                                                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                            </div>
                                                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                                                <div className="float-end">
                                                                                
                                                                                    <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm">Make Payment</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                <div className="left_side_search_area">
                                                    <div className="left_side_search_boxed">
                                                        <div className="left_side_search_heading mb-3">
                                                            <h5>Fare Summary</h5>
                                                        </div>
                                                        <div className="tour_details_boxed_inner fare-summary">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion_flex_area ">
                                                                    <div className="accordion-item fare-toggle-btn ">
                                                                        <h2 className="accordion-header" id="headingOne">
                                                                            <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                                               
                                                                               <div className='ms-4 col-md-7'>Base Fare</div>
                                                                               <div className='right-0 position-absolute'>$40,000</div>
                                                                            </button>
                                                                        </h2>
                                                                        <div  id="collapseOne1" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                          
                                                                                <div className="accordion_itinerary_list">
                                                                                  
                                                                                    <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                        <span className="area_flex_one text-muted">
                                                                                            <span>Adults(2 * $10,000)</span>
                                                                                            <span>$20,000</span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className="form-check-label text-12px text-muted" for="flexCheckDefaultf1">
                                                                                        <span className="area_flex_one">
                                                                                            <span>Children(1 * $20,000)</span>
                                                                                            <span>$20,000</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion_flex_area">
                                                                    <div className="accordion-item fare-toggle-btn">
                                                                        <h2 className="accordion-header" id="headingtwo">
                                                                            <button class="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                                               
                                                                               <div className='ms-4 col-md-7'> Taxes and Surcharges</div>
                                                                               <div className='right-0 position-absolute'>$20,000</div>
                                                                            </button>
                                                                            {/* <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#faretype2" aria-expanded="true"
                                                                                aria-controls="faretype2">
                                                                               Taxes and Subcharges - $20,000
                                                                            </button> */}
                                                                        </h2>
                                                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <div className="accordion_itinerary_list">
                                                                                    <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                        <span className="area_flex_one text-muted">
                                                                                            <span>Airline Taxes and Surcharges</span>
                                                                                            <span>$10,000</span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                        <span className="area_flex_one text-muted">
                                                                                            <span>Service Fee</span>
                                                                                            <span>$10,000</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion_flex_area">
                                                                    <div className="accordion-item fare-toggle-btn">
                                                                        <h2 className="accordion-header" id="headingthreee">
                                                                            <button class="accordion-button collapsed text-12px" type="button"  data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree3"> 
                                                                                <div className='ms-4 col-md-7'> Others </div>
                                                                                <div className='right-0 position-absolute'>$350</div>
                                                                            </button>
                                                                            {/* <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#faretype3" aria-expanded="true"
                                                                                aria-controls="faretype3">
                                                                               
                                                                               <div className='ms-4 col-md-7'> Other Services  </div>
                                                                               <div className='right-0 position-absolute'>$350</div>
                                                                            </button> */}
                                                                        </h2>
                                                                        <div id="collapsethree3" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body">
                                                                                <div className="accordion_itinerary_list">
                                                                                    <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                                                        <span className="area_flex_one text-muted">
                                                                                            <span>Seats</span>
                                                                                            <span>$350</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='my-2'></hr>  
                                                        <div className="">
                                                            <label className="form-check-label" for="flexCheckDefaultf1">
                                                                <span className="area_flex_one">
                                                                    <span className='fw-bold'>Total Amount</span>
                                                                    <span className='fw-bold'>$60,350</span>
                                                                </span>
                                                            </label>
                                                        </div> 
                                                        {/* <div className="tour_search_type">
                                                            <div className="">
                                                                <label className="form-check-label" for="flexCheckDefaultf1">
                                                                    <span className="area_flex_one">
                                                                        <span>Ecotourism</span>
                                                                        <span>17</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        
                                                        </div> */}
                                                    </div>
                                                </div>    
                                            </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
    
    </section>

    </Fragment>




};

export default FlightBook;
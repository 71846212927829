import React from "react";
import ReactDOM from "react-dom";

import Button from "../Button/Button";

/* 
const okButton = (props) => {
  return <div className='backdrop' onClick={props.onOk}></div>;
};

const cancelButton = (props) => {
    return <div className='backdrop' onClick={props.onCancel}></div>;
  }; */
const Backdrop = (props) => {
    return <div className='backdrop' onClick={props.onConfirm}></div>;
};

const ModalOverlay = (props) => {
    return (
        <div className='modalcard modal'>
            {/* <header className='header '>
        <h4 className="text-white">{props.title}</h4>
      </header> */}
            <header className='header'>
                {/* <h4>{props.title}</h4> */}
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                        <h4 className="text-white">{props.title}</h4>

                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end"> <span className='cross-icon-pop-up' onClick={props.onCancel}><h4 className="text-white cursor-pointer"><i className="fas fa-times text-white h5"></i></h4>  </span> </div>
                </div>
            </header>
            <div className='content'>
                <p>{props.message}</p>
            </div>
            <footer className='actions float-end px-3 py-2'>
                <Button className="btn-sm gt-main-btn" onClick={props.onCancel}>Cancel</Button>
                <span>&nbsp;&nbsp;</span>
                <Button className="btn-sm gt-main-btn" onClick={props.onOk}>OK</Button>
            </footer>
        </div>
    );
};

const ConfirmationModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClick={props.onConfirm}></Backdrop>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <Button onClick={props.onOk}></Button>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <Button onClick={props.onCancel}></Button>,
                document.getElementById("backdrop-root")
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                    title={props.title}
                    message={props.message}
                    onOk={props.onOk}
                    onCancel={props.onCancel}
                ></ModalOverlay>,
                document.getElementById("overlay-root")
            )}
        </React.Fragment>
    );
};
export default ConfirmationModal;

import { getPolicyID } from '../.../../utils/OBTFunction';

export const HotelDetailsJSON = (selectHotel) => {
    //console.log(JSON.stringify(createPaxInformation))
    const HotelSerach = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'))

    let travelRequestFieldsDataJson = "";
    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
    }

    console.log(selectHotel);
    const hotelImageSize = ({
        "Images": [
            {
                "ImageType": "ORIGINAL"
            },
            {
                "ImageType": "SMALL"
            }
        ]
    });
    const DetailsJSON = ({
        "officeID": JSON.parse(localStorage.getItem('officeID')),
        "Data": {
            "POS": null,
            "requestDetails": {
                "Environment": "PRODUCTION",
                "primaryLanguageId": "EN",
                "echoToken": JSON.parse(sessionStorage.getItem('HotelEchoToken')),
                "traceId": JSON.parse(localStorage.getItem('traceID')),
            },
            "hotels": HotelSerach.Data,
            "hotelResults": [selectHotel],
            "Images": [
                {
                    "ImageType": "LARGE"
                }
            ],
            "TPAExtensions": null,
            "policyApplyID": getPolicyID(),
            "tripReason": travelRequestFieldsDataJson.tripReason !== null ? travelRequestFieldsDataJson.tripReason : '',
        }
    })

    return DetailsJSON;
}
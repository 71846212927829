import React, { useState, useEffect, Fragment, useRef } from 'react';
import useHttp from '../../services/use-http';
import { sendHotelEmailProposal } from '../../services/SendMail-API';
import { validateEmail } from '../../utils/Validation';
import MdModal from '../Flights/MdModal';
import Recommendation from './Recommendation';
import ReactDOMServer from 'react-dom/server';
import { clearHotelRecommendation } from '../../utils/SendRecommendation';
import RoomRecommendation from './RoomRecommendation';
import RoomRecommendationTwo from './RecommendationTwo'


const HotelEmail = (props) => {
    const inputEmailIdRef = useRef("");
    const remarksInputRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(sendHotelEmailProposal);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState('');
    const errorHandler1 = () => {
        setError1(null);
    };

    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'FALSE') {
                setMailConfirm('Email sent successfully.');
                clearHotelRecommendation();
                document.getElementById("btnSend").disabled = true;
            } else {
                setMailConfirm("Some error occurred");
            }
            return;
        }

    }, [status])
    function onBlurCheck(event) {
        event.preventDefault();
        setMailConfirm('');
        document.querySelector('#msgSendMail').innerHTML = "";
    }
    function submitFormHandler(event) {
        event.preventDefault();


        let mail = inputEmailIdRef.current.value.trim()
        let cleanMail = mail.replace(/[\r\n]/gm, '');

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        if (inputEmailIdRef.current.value.includes(";")) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;
        }
        else {
            setInvalidEmailError(false);
        }

        if (!mail.includes(',') && validateEmail(mail) === null) {
            inputEmailIdRef.current.focus();
            setInvalidEmailError(true);
            return;

        } else {
            setInvalidEmailError(false);
        }
        if (cleanMail.includes(',')) {
            const mailArray = cleanMail.split(',');
            for (let email of mailArray) {
                if (validateEmail(email.trim()) === null) {
                    setInvalidEmailError(true);
                    inputEmailIdRef.current.focus();
                    return;

                } else {
                    setInvalidEmailError(false);
                }
            }

        }
        const hotelDetails1 = ReactDOMServer.renderToString(<html>
            <head >


                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha512-0S+nbAYis87iX26mmj/+fWt1MmaKCv80H+Mbo+Ne7ES4I6rxswpfnC6PxmLiw33Ywj2ghbtTw0FkLbMWqh4F7Q==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/fontawesome.min.css" integrity="sha512-RtgdY8FN0wFbR4ascGaGFDgjPJBzXP6WZZExVmmWJOaTCm4B0nF3e4G9iy9SfATuFQxyc+Mq7hXINynh3iwLDg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />

                <link href="https://dev.fareladder.com/assets/css/Recommendation.css" rel="stylesheet" />
                <link href="https://dev.fareladder.com/assets/css/style.css" rel="stylesheet" />


            </head>

            <body style={{ fontFamily: 'Poppins' }}>
                <Recommendation></Recommendation>
            </body></html>);
        const hotelDetails2 = ReactDOMServer.renderToString(<html>
            <head >


                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha512-0S+nbAYis87iX26mmj/+fWt1MmaKCv80H+Mbo+Ne7ES4I6rxswpfnC6PxmLiw33Ywj2ghbtTw0FkLbMWqh4F7Q==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/fontawesome.min.css" integrity="sha512-RtgdY8FN0wFbR4ascGaGFDgjPJBzXP6WZZExVmmWJOaTCm4B0nF3e4G9iy9SfATuFQxyc+Mq7hXINynh3iwLDg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />

                <link href="https://dev.fareladder.com/assets/css/Recommendation.css" rel="stylesheet" />
                <link href="https://dev.fareladder.com/assets/css/style.css" rel="stylesheet" />


            </head>

            <body style={{ fontFamily: 'Poppins' }}>
                <RoomRecommendationTwo></RoomRecommendationTwo>
            </body></html>);
        const roomData = ReactDOMServer.renderToString(<html>
            <head >


                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha512-0S+nbAYis87iX26mmj/+fWt1MmaKCv80H+Mbo+Ne7ES4I6rxswpfnC6PxmLiw33Ywj2ghbtTw0FkLbMWqh4F7Q==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/fontawesome.min.css" integrity="sha512-RtgdY8FN0wFbR4ascGaGFDgjPJBzXP6WZZExVmmWJOaTCm4B0nF3e4G9iy9SfATuFQxyc+Mq7hXINynh3iwLDg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />

                <link href="https://dev.fareladder.com/assets/css/Recommendation.css" rel="stylesheet" />
                <link href="https://dev.fareladder.com/assets/css/style.css" rel="stylesheet" />


            </head>

            <body style={{ fontFamily: 'Poppins' }}>
                <RoomRecommendation></RoomRecommendation>
            </body></html>);


        const inputData = {
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "data": {
                "receipent": cleanMail,
                "remarks": remarksInputRef.current.value,
                "subject": "Proposed Hotel",
                "pdfHtml": hotelDetails1,
                "pdfHtml1": hotelDetails2,
                "pdfHtml2": roomData,
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "userID": JSON.parse(localStorage.getItem('userID')),

            }
        }
        if (inputEmailIdRef.current.value !== '' && invalidEmailError === false) {
            sendRequest(
                inputData
            );
        }
    }

    function functionExit() {
        props.errorHandler();
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}


        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-1">
                <div className="container">
                    <div className="row">
                        <div className="gt-h-400px">
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Email ID<span className="error-icon"> * </span>
                                </label>
                                <div>
                                    <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={inputEmailIdRef}
                                        autoFocus>
                                    </textarea>
                                    <span className="h6 text-primary mb-2 col-lg-12" > <span className="text-black fw-bold"><i class="fas fa-info-circle"></i> Note : </span>  Please use commas to separate multiple Email IDs.</span>
                                    {balnkMailError && <p className="error-text">Email ID is required.</p>
                                    }
                                    {invalidEmailError && <p className="error-text">Please specify valid Email ID.</p>}

                                </div>
                            </div>
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Remarks
                                </label>
                                <div>
                                    <textarea id="Mail" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={remarksInputRef}
                                    >
                                    </textarea>
                                </div>
                                <span id="msgSendMail" className="error-text"></span>
                                {!balnkMailError && !invalidEmailError && <p className="success-text">{mailConfirm}</p>}
                            </div>


                        </div>
                        <div className="col-md-6 my-auto"></div>
                        <div className="col-md-6 my-auto align-right " >
                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler} >
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : 'Send'}
                            </button>
                            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnClose' onClick={functionExit} >
                                Close
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </Fragment>
}
export default HotelEmail;
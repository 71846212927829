import React, { useEffect, useRef, Fragment, useState } from 'react';
import MdModal from '../../Flights/MdModal';
import secureLocalStorage from 'react-secure-storage';
import { checkB2CClient } from '../../../utils/CommonFunction.js';

const SessionTimeout = () => {
    const timeoutIdRef = useRef(null);    
    const timeoutDuration = 20 * 60 * 1000;
    const [errors, setErrors] = useState();

    const logout = () => {
        setErrors({
            title: "Message",
            message: 'Your session is inactive. You will be redirected to home.',
        });
    };

    const resetTimeout = () => {
        const currentTime = Date.now();
        localStorage.setItem('lastActivityB2c', currentTime.toString());

        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(checkTimeout, timeoutDuration);
    };

    const checkTimeout = () => {
        const lastActivity = parseInt(localStorage.getItem('lastActivityB2c'), 10);
        const currentTime = Date.now();

        if (currentTime - lastActivity >= timeoutDuration) {
            logout();
        } else {
            resetTimeout();
        }
    };

    useEffect(() => {
        resetTimeout();

        const handleStorageChange = () => {
            checkTimeout();
        };

        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keydown', resetTimeout);
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('wheel', resetTimeout);
        window.addEventListener('DOMMouseScroll', resetTimeout);
        window.addEventListener('mousewheel', resetTimeout);
        window.addEventListener('mousedown', resetTimeout);
        window.addEventListener('touchstart', resetTimeout);
        window.addEventListener('touchmove', resetTimeout);
        window.addEventListener('MSPointerDown', resetTimeout);
        window.addEventListener('MSPointerMove', resetTimeout);
        window.addEventListener('visibilitychange', resetTimeout);
        window.addEventListener('focus', resetTimeout);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keydown', resetTimeout);
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('wheel', resetTimeout);
            window.removeEventListener('DOMMouseScroll', resetTimeout);
            window.removeEventListener('mousewheel', resetTimeout);
            window.removeEventListener('mousedown', resetTimeout);
            window.removeEventListener('touchstart', resetTimeout);
            window.removeEventListener('touchmove', resetTimeout);
            window.removeEventListener('MSPointerDown', resetTimeout);
            window.removeEventListener('MSPointerMove', resetTimeout);
            window.removeEventListener('visibilitychange', resetTimeout);
            window.removeEventListener('focus', resetTimeout);
        };
    }, []);

    const errorHandler = () => {
        setErrors(null);
        localStorage.removeItem("ProfileData");
        localStorage.removeItem("BookingData");
        sessionStorage.removeItem("HotelBookingData");
        secureLocalStorage.setItem("isCustomerAuthentication", JSON.stringify(false));
        window.location.pathname = checkB2CClient() ? '/launching' : '/b2c/launching';
    };

    return (
        <Fragment>
            {errors && (
                <MdModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></MdModal>
            )}
        </Fragment>);
};

export default SessionTimeout;
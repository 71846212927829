import { useTranslation } from 'react-i18next';
import HighRiskDestinationItem from './HighRiskDestinationItem';

const HighRiskDestinationList = ({ destinations, onSort, onDestinationDelete, sortCtrlRef }) => {

    const { t } = useTranslation(['translation.HighRiskDestination']);

    return (
        <div id="sortByText" className="container-fluid mb-3 list-view my-3">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "30px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6"></div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">Sort By</div>
                        </div>
                        <div className="col-md-4 form-group">
                            <select id="sortBy" ref={sortCtrlRef} onChange={onSort} className="form-control form-select bg_input">
                                <option value="ASC">{t('sortByCountryAsc')}</option>
                                <option value="DESC">{t('sortByCountryDesc')}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {destinations.map((destination) => (
                <HighRiskDestinationItem key={destination.id} destination={destination} onDestinationDelete={onDestinationDelete}></HighRiskDestinationItem>
            ))}
        </div>
    );
}

export default HighRiskDestinationList;
import UserForm from './Components/UserForm'
import { Fragment, useState, useEffect } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { addUser, updateUser, getSingleUserDetails } from './../../services/User-API';
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../services/use-http';
import useHttpGet from '../../services/use-http';
import ErrorModal from '../../components/Common/Error/ErrorModal';
import LoadingSpinnerWithCard from '../../components/Common/Loading/LoadingSpinnerWithCard';
import { checkSecurityDetails } from '../../utils/RoleUtils'
import { getConfigurationByValue } from '../../utils/ConfigurationsFunction'
const NewUser = (props) => {
    useDocumentTitle("User");
    const { t } = useTranslation(['translation.User']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;
    let varHttp;

    if (Id === undefined) {
        varHttp = addUser
    } else {
        varHttp = updateUser
    }
    const { sendRequest: sendRequest1, data: loadedUserDetails, status: status1, error } = useHttpGet(getSingleUserDetails);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();
    const sucsssMessage = (Id !== undefined) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (!checkSecurityDetails("User Management", "User", "User Management_User_Update") && Action === "1")
        history.push('/Unauthorized')
    else if (!checkSecurityDetails("User Management", "User", "User Management_User_View") && Action === "0")
        history.push('/Unauthorized')


    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {

        if (isEdit || Id === undefined) {
            if (status === 'completed') {
                if (loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${loadedQuote['data'].loginEmailID}${t('errorDuplicateRecord')}`,
                        });

                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }

                }

                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });

                }

                return;

            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, t, loadedQuote])



    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        if (Id !== undefined) {
            const strRequestDetails = {
                "ApprovalConfigurationID": getConfigurationByValue("APPROVER_TYPE").split("-")[0],
                "OfficeID": JSON.parse(localStorage.getItem('officeID')),
                //"ApprovalMasterID": "66209b44791b9b697846811a",
                "SequenceNumber": 0,
                "ApprovalLevel": 1,
                "ApprovalTypeID": "2",
                "IsCorporate": JSON.parse(localStorage.getItem("isCorporate")),
                "TraceID": "string",
                "UserID": Id,
                "IPAddress": "string"
            }

            sendRequest1(strRequestDetails)
        }
    }, [sendRequest1, Id])

    if (status1 === 'pending') {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }
    let loadedData = [];
    for (let i in loadedUserDetails) {
        if (loadedUserDetails['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedUserDetails[i];
            }
        }
    }
    const addUserHandler = (requestData) => {
        sendRequest(requestData);

    };

    const errorHandler = () => {
        setError(null);

        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/alluser');

        }


    };
    const userFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <p>{t('userDetailsError')}</p> :
        <UserForm loadedUserDetails={loadedData} isLoading={status === 'pending'} onAddUser={addUserHandler}
            IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {userFormContent}
    </Fragment>


};

export default NewUser;
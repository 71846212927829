import TravellerDetail from './SubComonents/TravellerDetail';
import moment from 'moment';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import { useTranslation } from 'react-i18next';
import { emailValidation } from '../../../../utils/Validation';
import useHttp from '../../../../services/use-http';
import { addCustomer } from '../../../../services/Customer-API';
import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CreateProfileJSON } from '../../../../utils/BookingFunction';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";

const TravellerDetails = (props) => {
    const { t } = useTranslation(['translation.Profile']);
    const { sendRequest, status, data: loadedCustomerDetails } = useHttp(addCustomer);
    const [error1, setError] = useState();
    const history = useHistory();
    const location = useLocation();

    window.scrollTo({
        top: 50,
        behavior: "smooth",
    });

    function assignTravelDetails(event) {
        event.preventDefault();
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));
        let setValidationStaus = true;

        profileJSON.data.travelerInfo.traveler.forEach(element => {

            if (element.PersonName.NamePrefix === '') {
                if (setValidationStaus) {
                    document.getElementById("msgtitle" + element.TravelerRefNumber).innerHTML = "Title is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("title" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                document.getElementById("msgtitle" + element.TravelerRefNumber).innerHTML = '';
            }

            if (document.getElementById("fName" + element.TravelerRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = "First Name is required.";
                    setValidationStaus = false;
                    document.getElementById("fName" + element.TravelerRefNumber).focus();
                }
            } else {
                document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = '';
            }
            if (document.getElementById("fName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("fName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = "First Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("fName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgfName" + element.TravelerRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("mName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("mName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msgmName" + element.TravelerRefNumber).innerHTML = "Middle Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("mName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msgmName" + element.TravelerRefNumber).innerHTML = '';
                }
            }
            if (document.getElementById("lName" + element.TravelerRefNumber).value === '') {
                if (setValidationStaus) {
                    document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = "Last Name is required.";
                    setValidationStaus = false;
                    document.getElementById("lName" + element.TravelerRefNumber).focus();
                }
            } else {
                document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = '';
            }

            if (document.getElementById("lName" + element.TravelerRefNumber).value !== '') {
                if (!document.getElementById("lName" + element.TravelerRefNumber).value.match('^[a-zA-Z ]+$')) {
                    if (setValidationStaus) {
                        document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = "Last Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("lName" + element.TravelerRefNumber).focus();
                    }
                } else if (document.getElementById("lName" + element.TravelerRefNumber).value.length < 2) {
                    if (setValidationStaus) {
                        document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = "Last Name is not valid.";
                        setValidationStaus = false;
                        document.getElementById("lName" + element.TravelerRefNumber).focus();
                    }
                } else {
                    document.getElementById("msglName" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            let nameLength = parseInt(document.getElementById("fName" + element.TravelerRefNumber).value.length) + parseInt(document.getElementById("mName" + element.TravelerRefNumber).value.length) + parseInt(document.getElementById("lName" + element.TravelerRefNumber).value.length);
            if (setValidationStaus) {
                if (nameLength > 59) {
                    document.getElementById("totalNameLength" + element.TravelerRefNumber).innerHTML = 'First Name, Middle Name and Last Name cannot exceed 59 characters in total.';
                    setValidationStaus = false;
                }
                else {
                    document.getElementById("totalNameLength" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.Gender === '') {
                if (setValidationStaus) {
                    document.getElementById("genderMsg" + element.TravelerRefNumber).innerHTML = "Gender is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("gender" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                document.getElementById("genderMsg" + element.TravelerRefNumber).innerHTML = '';
            }

            if (element.dateOfBirth.date.substring(8, 6) === '00') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Date" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.dateOfBirth.date.substring(6, 4) === '00') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Month" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            if (element.dateOfBirth.date.substring(0, 4) === '0000') {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is required.";
                    setValidationStaus = false;
                    const yOffset = -150;
                    const element2 = document.getElementById("dob-Year" + element.TravelerRefNumber);
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            } else {
                if (setValidationStaus) {
                    document.getElementById("dobMsg" + element.TravelerRefNumber).innerHTML = '';
                }
            }

            var travelingDate = sessionStorage.getItem("calFromDateDB1");
            var travelingYear = travelingDate.substring(0, 4);
            var travelingMonth = travelingDate.substring(4, 6);
            var travelingDay = travelingDate.substring(6, 8);
            var formattedTravelingDate = new Date(travelingYear, travelingMonth - 1, travelingDay);

            var dobDate = element.dateOfBirth.date;
            var dobYear = dobDate.substring(0, 4);
            var dobMonth = dobDate.substring(4, 6);
            var dobDay = dobDate.substring(6, 8);
            var formattedDobDate = new Date(dobYear, dobMonth - 1, dobDay);

            if (dobYear !== '0000' && dobMonth !== '00' && dobDay !== '00') {
                var dateDiffValue = dateDiff(formattedTravelingDate, formattedDobDate);

                if (moment(`${dobYear}-${dobMonth}-${dobDay}`).isValid() && dateDiffValue.years >= 12 && (dateDiffValue.months >= 0 || dateDiffValue.days > 0)) {
                    if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                        document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
                else {
                    if (setValidationStaus) {
                        if (document.querySelector("#dobMsg" + element.TravelerRefNumber) !== null) {
                            document.querySelector("#dobMsg" + element.TravelerRefNumber).innerHTML = "Date of Birth is not valid.";
                        }
                        setValidationStaus = false;
                    }
                }
            }

            if (document.getElementById("email").value === '') {
                if (setValidationStaus) {
                    document.getElementById("msgEmail").innerHTML = "Email is required.";
                    setValidationStaus = false;
                    document.getElementById("msgEmail").focus();
                }
            } else {
                document.getElementById("msgEmail").innerHTML = '';
            }
            if (document.getElementById("email").value !== '') {
                if (!emailValidation(document.getElementById("email").value)) {
                    if (setValidationStaus) {
                        document.getElementById("msgEmail").innerHTML = "Email is not valid.";
                        setValidationStaus = false;
                        document.getElementById("email").focus();
                    }
                } else {
                    document.getElementById("msgEmail").innerHTML = '';
                }
            }

            if (document.querySelectorAll('input[type=tel]')[0].value.length < 12) {
                if (setValidationStaus) {
                    document.getElementById("msgPhoneNumber").innerHTML = "Phone is required.";
                    setValidationStaus = false;
                    document.getElementById("msgPhoneNumber").focus();
                }
            } else {
                document.getElementById("msgPhoneNumber").innerHTML = '';
            }

            profileJSON.data.travelerInfo.traveler.forEach(element => {
                element.email[0].emailId = document.querySelector('#email').value;
                element.telephone[0].mobile = document.querySelectorAll('input[type=tel]')[0].value.split(' ')[0] + " " + document.querySelectorAll('input[type=tel]')[0].value.replaceAll(' ', '').substring(document.querySelectorAll('input[type=tel]')[0].value.split(' ')[0].length, document.querySelectorAll('input[type=tel]')[0].value.length);
            });

            let passportIsRequired = false;
            if (document.getElementById("passportNumber" + element.TravelerRefNumber) !== null && document.getElementById("passportNumber" + element.TravelerRefNumber).value !== '') {
                passportIsRequired = true;
            }

            if (passportIsRequired) {
                if (element.Document[0].DocHolderNationality === '') {
                    if (setValidationStaus) {
                        document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "Nationality is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("nationality" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("nationalityMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }

                if (document.getElementById("passportNumber" + element.TravelerRefNumber).value === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "Passport No. is required.";
                        setValidationStaus = false;
                        document.getElementById("passportNumber" + element.TravelerRefNumber).focus();
                    }
                    else {
                        document.getElementById("passportNumberMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
                else {
                    if (document.getElementById("passportNumber" + element.TravelerRefNumber).value !== '') {
                        if (setValidationStaus && !document.getElementById("passportNumber" + element.TravelerRefNumber).value.match('^[a-zA-Z0-9]+$')) {
                            document.getElementById("msgpassportNumber" + element.TravelerRefNumber).innerHTML = "Passport No. is not valid.";
                            setValidationStaus = false;
                            document.getElementById("passportNumber" + element.TravelerRefNumber).focus();
                        }
                        else {
                            document.getElementById("msgpassportNumber" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                }

                if (element.Document[0].ExpireDate === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
                else {
                    if (element.Document[0].ExpireDate.length === 8) {
                        if (element.Document[0].ExpireDate.substring(0, 4) === '0000' || element.Document[0].ExpireDate.substring(4, 6) === '00' || element.Document[0].ExpireDate.substring(6, 8) === '00') {
                            if (setValidationStaus) {
                                document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is required.";
                                setValidationStaus = false;
                                const yOffset = -150;
                                const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
                                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                            }
                            else {
                                document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                            }
                        } else if (element.Document[0].ExpireDate.substring(0, 4) !== '0000' && element.Document[0].ExpireDate.substring(4, 6) !== '00' && element.Document[0].ExpireDate.substring(6, 8) !== '00') {
                            if (setValidationStaus) {
                                var travelingDate = sessionStorage.getItem("calFromDateDB1");
                                var travelingYear = travelingDate.substring(0, 4);
                                var travelingMonth = travelingDate.substring(4, 6);
                                var travelingDay = travelingDate.substring(6, 8);
                                var formattedTravelingDate = new Date(travelingYear, travelingMonth - 1, travelingDay);

                                var pptExpireDate = element.Document[0].ExpireDate;
                                var pptExpireYear = pptExpireDate.substring(0, 4);
                                var pptExpireMonth = pptExpireDate.substring(4, 6);
                                var pptExpireDay = pptExpireDate.substring(6, 8);
                                var formattedPptExpireDate = new Date(pptExpireYear, pptExpireMonth - 1, pptExpireDay);

                                var dateDiffValue = dateDiff(formattedPptExpireDate, formattedTravelingDate);
                                let isExpiryDateIsValid = ((dateDiffValue.years >= 1 || dateDiffValue.months > 6) || (dateDiffValue.months === 6 && dateDiffValue.days >= 1));

                                if (moment(`${pptExpireYear}-${pptExpireMonth}-${pptExpireDay}`).isValid() && (!isExpiryDateIsValid)) {
                                    document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "Passport Expiry is not valid.";
                                    setValidationStaus = false;
                                    const yOffset = -150;
                                    const element2 = document.getElementById("passportExpiry-Date" + element.TravelerRefNumber);
                                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                    window.scrollTo({ top: y, behavior: 'smooth' });
                                }
                                else {
                                    document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                                }
                            }
                            else {
                                document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                            }
                        }
                        else {
                            document.getElementById("passportExpiryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                }

                if (element.Document[0].DocIssueCountry === '') {
                    if (setValidationStaus) {
                        document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                        setValidationStaus = false;
                        const yOffset = -150;
                        const element2 = document.getElementById("issueCountry" + element.TravelerRefNumber);
                        const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                    else {
                        document.getElementById("passportIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                    }
                }
            }
            if (element.PassengerTypeCode !== "INF" && element.PassengerTypeCode !== "INS") {
                let identificationTypeIsRequired = false;
                let identificationNumberIsRequired = false;
                let issueCountryIsRequired = false;

                if (element.Document[1].DocType !== undefined && element.Document[1].DocType !== '') {
                    identificationNumberIsRequired = true;

                } else if (element.Document[1].DocIssueCountry !== undefined && element.Document[1].DocIssueCountry !== '') {
                    issueCountryIsRequired = true;
                }
                else {
                    if (document.getElementById("Number" + element.TravelerRefNumber) !== null && document.getElementById("Number" + element.TravelerRefNumber).value !== '') {
                        identificationTypeIsRequired = true;
                    }
                }

                if (identificationNumberIsRequired) {
                    if (document.getElementById("Number" + element.TravelerRefNumber).value === '') {
                        if (setValidationStaus) {
                            if (element.Document[1].DocType === '') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Number is required.";
                            } else if (element.Document[1].DocType === 'RedressNumber') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is required.";
                            }
                            else {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is required.";
                            }

                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {

                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'RedressNumber') {

                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;

                        if (setValidationStaus && !redressNo.match('^[0-9]+$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 7) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'KnownTravelerNumber') {
                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;

                        if (setValidationStaus && !redressNo.match('^[a-zA-Z0-9 ]*$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 9) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }

                    if (element.Document[1].DocIssueCountry === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("IdentificationIssueCountry" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                }

                if (identificationTypeIsRequired) {
                    if (element.Document[1].DocType === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "Additional is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Identification" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (element.Document[1].DocIssueCountry === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "Issuing Country is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("IdentificationIssueCountry" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationIssuingCountryMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }

                }
                if (issueCountryIsRequired) {
                    if (element.Document[1].DocType === '') {
                        if (setValidationStaus) {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "Additional is required.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Identification" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("identificationTypeMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value === '') {

                        if (setValidationStaus) {
                            if (element.Document[1].DocType === '') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Number is required.";
                            } else if (element.Document[1].DocType === 'RedressNumber') {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is required.";
                            }
                            else {
                                document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is required.";
                            }
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {

                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }

                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'RedressNumber') {

                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;

                        if (setValidationStaus && !redressNo.match('^[0-9]+$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 7) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Redress Number is not valid.";
                            setValidationStaus = true;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }
                    }
                    if (document.getElementById("Number" + element.TravelerRefNumber).value !== '' && element.Document[1].DocType === 'KnownTravelerNumber') {
                        let redressNo = document.getElementById("Number" + element.TravelerRefNumber).value;

                        if (setValidationStaus && !redressNo.match('^[a-zA-Z0-9 ]*$')) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";

                            setValidationStaus = false;

                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        } else if (setValidationStaus && redressNo.length > 9) {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "Known Traveler Number is not valid.";
                            setValidationStaus = false;
                            const yOffset = -150;
                            const element2 = document.getElementById("Number" + element.TravelerRefNumber);
                            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({ top: y, behavior: 'smooth' });
                        }
                        else {
                            document.getElementById("NumberMsg" + element.TravelerRefNumber).innerHTML = "";
                        }

                    }
                }
                if (document.getElementById("Number" + element.TravelerRefNumber) !== null) {
                    element.Document[1].DocID = document.getElementById("Number" + element.TravelerRefNumber).value;
                }
            }

            element.PersonName.GivenName = document.getElementById("fName" + element.TravelerRefNumber).value.trim();
            element.PersonName.Surname = document.getElementById("lName" + element.TravelerRefNumber).value.trim();
            element.PersonName.MiddleName = document.getElementById("mName" + element.TravelerRefNumber).value.trim();
            // element.DateOfBirth.Date = document.getElementById("dob" + element.TravelerRefNumber).value;
            if (document.getElementById("passportNumber" + element.TravelerRefNumber) !== null) {
                element.Document[0].DocID = document.getElementById("passportNumber" + element.TravelerRefNumber).value.trim();
            }

            // element.Document[0].ExpireDate = document.getElementById("passportExpiry" + element.TravelerRefNumber).value;

            if (document.querySelector('#passengerName' + element.TravelerRefNumber) !== null) {
                document.querySelector('#passengerName' + element.TravelerRefNumber).innerHTML = element.PersonName.NamePrefix + " " + element.PersonName.GivenName + " " + element.PersonName.Surname;
            }
        });

        if (setValidationStaus) {
            const requestData = ({
                "id": props.customerId,
                "traceID": JSON.parse(localStorage.getItem('traceID')),
                "userID": JSON.parse(localStorage.getItem('userID')),
                "ipAddress": localStorage.getItem("IPAddress"),
                "title": profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix,
                "firstName": profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName,
                "middleName": profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName,
                "lastName": profileJSON.data.travelerInfo.traveler[0].PersonName.Surname,
                "emailID": profileJSON.data.travelerInfo.traveler[0].email[0].emailId,
                "phoneCode": "",
                "phoneNumber": "",
                "travelerPhoneCode": profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[0],
                "travelerPhoneNumber": profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[1],
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "address": "",
                "dateOfBirth": profileJSON.data.travelerInfo.traveler[0].dateOfBirth.date,
                "gender": profileJSON.data.travelerInfo.traveler[0].Gender,
                "nationality": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].DocHolderNationality !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[0].DocHolderNationality : '',
                "passportNo": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].DocID !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[0].DocID : '',
                "passportExp": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].ExpireDate !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[0].ExpireDate : '',
                "city": "",
                "country": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].DocIssueCountry !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[0].DocIssueCountry : '',
                "additional": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1].DocType !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[1].DocType : '',
                "additionalNumber": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1].DocID !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[1].DocID : '',
                "additionalCountry": profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1].DocIssueCountry !== undefined ? profileJSON.data.travelerInfo.traveler[0].Document[1].DocIssueCountry : ''
            });

            sendRequest(requestData);
        }
    }

    function changeLocation(event) {
        if (event.target.id === 'btnReset') {
            let profileJSON = CreateProfileJSON()
            localStorage.setItem("ProfileData", JSON.stringify(profileJSON))
            window.location.pathname = '/Profile';
        }
    }

    function dateDiff(targerDate, sourceDate) {
        var year = targerDate.getFullYear();
        var month = targerDate.getMonth() + 1;
        var day = targerDate.getDate();

        var yy = sourceDate.getFullYear();
        var mm = sourceDate.getMonth() + 1;
        var dd = sourceDate.getDate();
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
            months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
            years = years - 1;
            months = months + 12;
        }
        // days
        days = Math.floor((targerDate.getTime() - (new Date(yy + years, mm + months - 1, dd)).getTime()) / (24 * 60 * 60 * 1000));
        //
        return { years: years, months: months, days: days };
    }

    useEffect(() => {
        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });

        if (status === 'completed') {
            for (let i in loadedCustomerDetails) {
                if (loadedCustomerDetails['errors'].status === 'FALSE') {
                    viewCustomerDetails(loadedCustomerDetails.data);
                    setError({
                        title: "Message",
                        message: loadedCustomerDetails.errors.error.description,
                    });
                }
                else if (loadedCustomerDetails['errors'].status === 'TRUE') {
                    setError({
                        title: "Message",
                        message: loadedCustomerDetails.errors.error.description,
                    });
                }
            }
        }

    }, [status, loadedCustomerDetails])

    function viewCustomerDetails(param) {
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));

        profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix = param.title;
        profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName = param.firstName;
        profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName = param.middleName;
        profileJSON.data.travelerInfo.traveler[0].PersonName.Surname = param.lastName;

        profileJSON.data.travelerInfo.traveler[0].email[0].emailId = param.emailID;
        profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile = param.travelerPhoneNumber !== '' ? param.travelerPhoneCode + " " + param.travelerPhoneNumber.replace(/[^0-9]/g, '') : param.phoneCode + " " + param.phoneNumber.replace(/[^0-9]/g, '');
        profileJSON.data.travelerInfo.traveler[0].dateOfBirth.date = param.dateOfBirth !== undefined && param.dateOfBirth !== '' ? param.dateOfBirth : '00000000';
        profileJSON.data.travelerInfo.traveler[0].Gender = param.gender;

        if (profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].DocID !== undefined) {
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocHolderNationality = param.nationality;
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocID = param.passportNo;
            profileJSON.data.travelerInfo.traveler[0].Document[0].ExpireDate = param.passportExp !== undefined && param.passportExp !== '' ? param.passportExp : '00000000';
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocIssueCountry = param.country;
        }

        if (profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1].DocID !== undefined) {
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocType = param.additional;
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocID = param.additionalNumber;
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocIssueCountry = param.additionalCountry;
        }

        localStorage.setItem("ProfileData", JSON.stringify(profileJSON));
        history.push({ pathname: location.pathname });
    }

    const errorHandler = () => {
        setError(null);
        if (status === 'completed') {
            if (loadedCustomerDetails !== null && loadedCustomerDetails['errors'].status === 'FALSE') {
                window.location.pathname = '/FlightSearch';
            }
        }
    };

    return (<>
        {status === 'pending' && (
            <div className='loading'>
                <LoadingSpinner />
            </div>
        )}
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {props.customerId !== '' && props.traveler.travelerInfo.traveler.map((traveler) =>
            <TravellerDetail
                key={traveler.TravelerRefNumber}
                id={traveler.TravelerRefNumber}
                paxType={traveler.PassengerTypeCode}
                traveler={props.traveler}>
            </TravellerDetail>)}
        <div className='row'>
            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
            </div>
            <div className='mt-3 actions row'>
                <div className="col-lg-3 col-md-3 col-sm-3 my-auto"></div>
                <div className="col-lg-9 col-md-9 col-sm-9">
                    <div className="float-md-end float-lg-end">
                        {/* <a href="/SearchOffice" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a> */}
                        {(checkSecurityDetails("User Management", "Office", "User Management_Office_Insert") || checkSecurityDetails("User Management", "Office", "User Management_Office_Update")) ?
                            <button id='btnSave' onClick={assignTravelDetails} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('update')}</button>
                            : <button id='btnSave' onClick={assignTravelDetails} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('update')}</button>}
                        <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>
                    </div>
                </div>
            </div>
            <div className='mt-3 col-md-12 actions'>
            </div>
        </div>
    </>)
}
export default TravellerDetails
import React, { Fragment, useEffect, useState, Suspense } from 'react';
import RouterConfig from './navigation/RouterConfig'
import { appendScript, removeScript } from './utils';
import { getCustomizeDetails } from './utils/CustomizeUtils';
import "react-tooltip/dist/react-tooltip.css";
import LoadingSpinner from './components/Common/Loading/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { getConfigurationByValue } from './utils/ConfigurationsFunction';
import { CUSTOMIZE_IMAGE_URL } from './config/CONSTANTS';
function App() {
    //const RouterConfig = React.lazy(() => import('./navigation/RouterConfig'));
    //'--btn-bg-color', "radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd"
    // document.documentElement.style.setProperty('--main-color', "#38c1f5");
    document.documentElement.style.setProperty('--main-color', `${getCustomizeDetails("borderColor")}`);
    document.documentElement.style.setProperty('--bg-navy', `${getCustomizeDetails("headerBannerColor")}`);
    document.documentElement.style.setProperty('--body-bg', `${getCustomizeDetails("backgroundColor")}`);
    document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails("buttonColor")} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails("buttonGradientColor2")}`);
    document.documentElement.style.setProperty('--footer-bg1', `${getCustomizeDetails("footerBackgroundColor")}`);
    document.documentElement.style.setProperty('--text-box-bg-skyblue', `${getCustomizeDetails("textBackgroundColor")}`);
    document.documentElement.style.setProperty('--support-head-bg', getCustomizeDetails("headerSupportBgColor"));
    document.documentElement.style.setProperty('--text-support', getCustomizeDetails("headerSupportLblColor"));
    document.documentElement.style.setProperty('--text-white', getCustomizeDetails("headerSupportEmailIDColor"));
    document.documentElement.style.setProperty('--navlink-color', getCustomizeDetails("menuLabelColor"));
    /*   : radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd; */
    //document.documentElement.style.setProperty('--btn-bg-color', "radial-gradient(circle at 4% 2%,  0%, rgba(87, 29, 127, 0) 97%),");
    //--bg-navy: #020f3b; 

    const [loading, isLoading] = useState(false);

    const location = useLocation();

    function setFavIcon() {
        let favIconImage = getCustomizeDetails('faviconImagePath');
        let isIconPng = false;
        if (favIconImage) {            
            if(favIconImage.endsWith('.png')) {
                isIconPng = true;
            }
            favIconImage = CUSTOMIZE_IMAGE_URL + favIconImage;
        }
        else {
            favIconImage = process.env.PUBLIC_URL + '/fareladder.png?v=' + Math.random();
            isIconPng = true;
        }

        let favIconLink = document.querySelector('link[rel~="icon"]');
        let favIconLinkApple = document.querySelector('link[rel~="apple-touch-icon"]');

        if (!favIconLink) {
            favIconLink = document.createElement('link');
            favIconLink.rel = 'icon';
            document.head.appendChild(favIconLink);
        }

        if (!favIconLinkApple) {
            favIconLinkApple = document.createElement('link');
            favIconLinkApple.rel = 'apple-touch-icon';
            document.head.appendChild(favIconLinkApple);
        }

        favIconLink.type = isIconPng ? 'image/png': 'image/x-icon';
        favIconLink.href = favIconImage;
        favIconLinkApple.href = favIconImage;
    }

    useEffect(() => {

        appendScript("./assets/js/jquery-3.6.0.min.js");
        appendScript("./assets/js/bootstrap.bundle.js");
        appendScript("./assets/js/jquery.meanmenu.js");
        appendScript("./assets/js/owl.carousel.min.js");
        appendScript("./assets/js/wow.min.js");
        appendScript("./assets/js/nouislider.min.js");
        appendScript("./assets/js/wNumb.js");
        appendScript("./assets/js/custom.js");
        appendScript("./assets/js/add-form.js");
        
        return () => {
            removeScript("./assets/js/jquery-3.6.0.min.js");
            removeScript("./assets/js/bootstrap.bundle.js");
            removeScript("./assets/js/jquery.meanmenu.js");
            removeScript("./assets/js/owl.carousel.min.js");
            removeScript("./assets/js/wow.min.js");
            removeScript("./assets/js/nouislider.min.js");
            removeScript("./assets/js/wNumb.js");
            removeScript("./assets/js/custom.js");
            removeScript("./assets/js/add-form.js");
        }

    }, []);
    
    useEffect(() => {

        setFavIcon();

        if (location.pathname === "/MFA") {
            isLoading(true);
            setTimeout(() => {
                isLoading(false);
            }, 2000);
            sessionStorage.setItem("LoaderHeader", "1");
        }

        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG
        };
        TagManager.initialize(tagManagerArgs);

    }, []);

    useEffect(() => {
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' && sessionStorage.getItem("APPVersion") !== process.env.REACT_APP_VERSION) {
            window.location.pathname = '/Launching';

        }
        sessionStorage.setItem("APPVersion", process.env.REACT_APP_VERSION);
    }, [process.env.REACT_APP_VERSION]);

    return (
        <Fragment>
            {loading &&
                <div className="preloader">
                    <div className="d-table">
                        <div >
                            <div >
                                <LoadingSpinner></LoadingSpinner>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>}
            <RouterConfig />
        </Fragment>
    );
}

export default App;
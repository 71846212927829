import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import ErrorModal from "../../../../../components/Common/Error/ErrorModal";
import { sortWithTwoData } from '../../../../../utils';
import SelectedApproverItem from './SelectedApproverItem'

const SelectedApproverList = (props) => {
    const history = useHistory();
    const [error1, setError] = useState();
    const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);

    let sortedApprover = [...props.workflowDetails];

    sortedApprover = sortWithTwoData(sortedApprover, 'approvalTypeName', 'approvalLevel');

    const sortedApproverCount = sortedApprover.length;
    for (let approverIndex = 0; approverIndex < sortedApproverCount; approverIndex++) {
        let currentApprover = sortedApprover[approverIndex];
        let prevApprover = null;
        let nextApprover = null;

        if (approverIndex > 0) {
            prevApprover = sortedApprover[approverIndex - 1];
        }

        if ((approverIndex + 1) !== sortedApproverCount) {
            nextApprover = sortedApprover[approverIndex + 1];
        }

        if (approverIndex === 0 || (prevApprover && currentApprover.approvalTypeID !== prevApprover.approvalTypeID)) {
            currentApprover.isFirstNode = true;
        }
        else {
            currentApprover.isFirstNode = false;
        }

        if (!nextApprover || (nextApprover && currentApprover.approvalTypeID !== nextApprover.approvalTypeID)) {
            currentApprover.isLastNode = true;
        }
        else {
            currentApprover.isLastNode = false;
        }
    }

    function deleteItemsHandler(approverInfo) {
        props.onApproverDelete(approverInfo);
    }

    function errorHandler() {
        setError(null);
        if (!isErrorOnDelete) {

            history.go({
                pathname: '/SearchApprover'
            })
        }
        else {
            history.push({
                pathname: '/SearchApprover'
            })
        }
    }

    function onAddToSelectedApprovalWorkflow(approvers) {
        props.onAddToSelectedApprovalWorkflow(approvers);
    }

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}

        {sortedApprover.length > 0 &&
            <div className="col-md-12 mb-3 list-view">
                {sortedApprover.map((approver) => (
                    <SelectedApproverItem
                        onDelete={deleteItemsHandler}
                        key={`${approver.approvalUserID}${approver.approvalTypeID}`}
                        approver={approver}
                        workflowDetails={props.workflowDetails}
                        onAddToSelectedApprovalWorkflow={onAddToSelectedApprovalWorkflow}
                    />
                ))}
            </div>}
    </Fragment>
};

export default SelectedApproverList;
import { getToken } from '../utils';
import {
    BOOKING,
    Send_Mail,
    SEARCH_MYTRIP, RETRIEVE_TRIP,
    CANCEL_TRIP,
    UPDATE_NOTES,
    GET_HISTORY,
    GET_NOTES,
    SEARCH_LOGS,
    REBOOKING_TRIP,
    SEARCH_REBOOKING,
    FLIGHT_PLUS_HOTEL_RETRIEVE_TRIP,
    SHOPPING_CART_BOOKING,
    CANCEL_TRIP_SHOPPING_CART,
    GDS_RETRIEVE,
    IMPORT_PNR_BOOKING,
    GET_HISTORY_IMPORT_PNR,
    IMPORT_TICKET,
    BLOCK_TICKET,
    CANCEL_VOID_TRIP,
    REVIEW_BOOKING,
    ISSUE_TICKET,
    TRAVEL_REQUEST,
    RETRIEVE_TRAVEL_REQUEST,
    RETRIEVE_SERVICE_REQUEST,
    UPDATE_SERVICE_REQUEST,
} from './CONSTANTS';

export async function booking(bookinghData) {

    const response = await fetch(BOOKING(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function sendMail(mailData) {

    const response = await fetch(Send_Mail(), {
        method: 'POST',
        body: JSON.stringify(mailData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not send email.');
    }

    return data;

}

export async function searchMyTrip(searchData) {
    const response = await fetch(SEARCH_MYTRIP(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}

export async function getRetrieveTrip(name) {
    const response = await fetch(RETRIEVE_TRIP(name), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch country.');
    }

    return data;
}
export async function getRetrieveTravelRequest(name) {
    const response = await fetch(RETRIEVE_TRAVEL_REQUEST(name), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not Travel Request data.');
    }

    return data;
}

export async function getFlightPlusHotelRetrieveTrip(name) {
    const response = await fetch(FLIGHT_PLUS_HOTEL_RETRIEVE_TRIP(name), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not retrive trip.');
    }

    return data;
}

export async function cancelTrip(searchData) {
    const response = await fetch(CANCEL_TRIP(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}
export async function cancelVoidTrip(searchData) {
    const response = await fetch(CANCEL_VOID_TRIP(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}

export async function updateNotes(searchData) {
    const response = await fetch(UPDATE_NOTES(), {
        method: 'PUT',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}

export async function getNotes(searchData) {
    const response = await fetch(GET_NOTES(searchData), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch history.');
    }

    return data;
}

export async function getHistory(id) {
    const response = await fetch(GET_HISTORY(id), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch history.');
    }

    return data;
}

export async function getLogs(searchData) {
    const response = await fetch(SEARCH_LOGS(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch logs.');
    }

    return data;
}

export async function rebookingTrip(searchData) {
    const response = await fetch(REBOOKING_TRIP(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}

export async function getRebooking(searchData) {
    const response = await fetch(SEARCH_REBOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch rebooking.');
    }

    return data;
}

export async function shoppingCartBooking(bookinghData) {

    const response = await fetch(SHOPPING_CART_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function cancelTripShoppingCart(searchData) {
    const response = await fetch(CANCEL_TRIP_SHOPPING_CART(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }
    return data;
}

export async function getGdsRetrieveTrip(searchData) {
    const response = await fetch(GDS_RETRIEVE(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch trip.');
    }

    // const data2 = {
    //     "officeID": "6399713db476f3c3081f4719",
    //     "data": {
    //         "bookingReferenceNumber": "0001M5",
    //         "shoppingID": "5322",
    //         "requestType": "OF",
    //         "travelerInfo": {
    //             "specialReqDetails": [
    //                 {
    //                     "seatRequests": {
    //                         "seatRequest": []
    //                     },
    //                     "specialServiceRequests": {
    //                         "specialServiceRequest": [
    //                             {
    //                                 "ssrCode": "AVML",
    //                                 "ssrDescription": " Asian Veg.",
    //                                 "travelerRefNumber": "1",
    //                                 "flightRefNumber": "1",
    //                                 "ssrCharge": "0",
    //                                 "ssrType": "MEAL",
    //                                 "serviceQuantity": "1",
    //                                 "status": "true",
    //                                 "remarks": " Asian Veg.",
    //                                 "wayType": "1"
    //                             }
    //                         ]
    //                     }
    //                 }
    //             ],
    //             "traveler": [
    //                 {
    //                     "personName": {
    //                         "namePrefix": "Mr.",
    //                         "givenName": "ww",
    //                         "surname": "ww",
    //                         "middleName": ""
    //                     },
    //                     "gender": "M",
    //                     "telephone": [
    //                         {
    //                             "phoneNumber": "+1 2222222222",
    //                             "mobile": " "
    //                         }
    //                     ],
    //                     "email": [
    //                         {
    //                             "emailId": "Akhilesh@fareladder.us"
    //                         }
    //                     ],
    //                     "dateOfBirth": {
    //                         "date": "20110101"
    //                     },
    //                     "passengerTypeCode": "ADT",
    //                     "travelerRefNumber": "1",
    //                     "isHolder": false
    //                 }
    //             ]
    //         },
    //         "airItinerary": [
    //             {
    //                 "airItineraryPricingInfo": {
    //                     "totalFare": {
    //                         "amount": "148.90",
    //                         "currencyCode": "USD"
    //                     },
    //                     "totalBaseFare": {
    //                         "amount": "115.35",
    //                         "currencyCode": "USD"
    //                     },
    //                     "totalTax": {
    //                         "amount": "23.55",
    //                         "currencyCode": "USD"
    //                     },
    //                     "passengerTypeQuantity": [
    //                         {
    //                             "baseFare": {
    //                                 "amount": "115.35",
    //                                 "currencyCode": "USD"
    //                             },
    //                             "taxes": {
    //                                 "tax": [
    //                                     {
    //                                         "key": "36",
    //                                         "amount": "5.6",
    //                                         "currencyCode": "USD",
    //                                         "taxCode": "AY",
    //                                         "taxDesc": "PASSENGER CIVIL AVIATION SECURITY SERVICE FEE",
    //                                         "station": "JFK",
    //                                         "country": "US"
    //                                     },
    //                                     {
    //                                         "key": "27",
    //                                         "amount": "8.65",
    //                                         "currencyCode": "USD",
    //                                         "taxCode": "US1",
    //                                         "taxDesc": "TRANSPORTATION TAX DOMESTIC WHOLLY WITHIN US AND 225 MILE BUFFER ZONE",
    //                                         "station": "JFK",
    //                                         "country": "US"
    //                                     },
    //                                     {
    //                                         "key": "25",
    //                                         "amount": "4.5",
    //                                         "currencyCode": "USD",
    //                                         "taxCode": "XF",
    //                                         "taxDesc": "PASSENGER FACILITY CHARGE",
    //                                         "station": "JFK",
    //                                         "country": "US"
    //                                     },
    //                                     {
    //                                         "key": "17",
    //                                         "amount": "4.8",
    //                                         "currencyCode": "USD",
    //                                         "taxCode": "ZP",
    //                                         "taxDesc": "FLIGHT SEGMENT TAX DOMESTIC",
    //                                         "station": "JFK",
    //                                         "country": "US"
    //                                     }
    //                                 ],
    //                                 "amount": "23.55",
    //                                 "currencyCode": "USD"
    //                             },
    //                             "totalFare": {
    //                                 "amount": "138.9",
    //                                 "currencyCode": "USD"
    //                             },
    //                             "fareInfos": {
    //                                 "fareInfo": [
    //                                     {
    //                                         "classOfService": "Y",
    //                                         "fareBasisCode": "SI4QUEL1",
    //                                         "ruleInfo": {
    //                                             "chargesRules": {
    //                                                 "voluntaryChanges": {
    //                                                     "penalty": [
    //                                                         {
    //                                                             "penaltyType": "Exchange",
    //                                                             "departureStatus": "Before",
    //                                                             "currencyCode": "USD",
    //                                                             "amount": "100.00",
    //                                                             "conditions": "",
    //                                                             "changeable": "True"
    //                                                         },
    //                                                         {
    //                                                             "penaltyType": "Exchange",
    //                                                             "departureStatus": "After",
    //                                                             "conditions": "",
    //                                                             "changeable": "False"
    //                                                         }
    //                                                     ]
    //                                                 },
    //                                                 "voluntaryRefunds": {
    //                                                     "penalty": [
    //                                                         {
    //                                                             "penaltyType": "Refund",
    //                                                             "departureStatus": "Before",
    //                                                             "refundable": "False"
    //                                                         },
    //                                                         {
    //                                                             "penaltyType": "Refund",
    //                                                             "departureStatus": "After",
    //                                                             "refundable": "False"
    //                                                         }
    //                                                     ]
    //                                                 }
    //                                             }
    //                                         },
    //                                         "globalIndicatorCode": "ATP",
    //                                         "operation": "FROM",
    //                                         "segments": {
    //                                             "segment": [
    //                                                 {
    //                                                     "id": 0,
    //                                                     "seatAvailable": "0"
    //                                                 }
    //                                             ]
    //                                         },
    //                                         "fareType": "Published"
    //                                     }
    //                                 ]
    //                             },
    //                             "fees": {
    //                                 "fee": [
    //                                     {
    //                                         "currencyCode": "USD",
    //                                         "amount": "10.00",
    //                                         "text": "ServiceFee-Amount"
    //                                     }
    //                                 ]
    //                             },
    //                             "code": "ADT",
    //                             "quantity": "1"
    //                         }
    //                     ]
    //                 },
    //                 "sequenceNumber": "1",
    //                 "isCancelled": false,
    //                 "paymentInfo": {
    //                     "paymentMode": [
    //                         {
    //                             "name": "N/A",
    //                             "amount": "138.9",
    //                             "cardIsEncrypt": false,
    //                             "ignoreAddressVerification": false
    //                         }
    //                     ],
    //                     "ticketingInfo": {
    //                         "issueTicket": "S"
    //                     }
    //                 },
    //                 "originDestinationOptions": {
    //                     "originDestinationOption": [
    //                         {
    //                             "flightSegment": [
    //                                 {
    //                                     "bookingClassAvails": [
    //                                         {
    //                                             "cabinName": "Economy"
    //                                         }
    //                                     ],
    //                                     "dateChangeNbr": "1",
    //                                     "duration": "373",
    //                                     "elapsedLayoverTime": "",
    //                                     "flownMileageQty": "2586",
    //                                     "departureAirport": {
    //                                         "locationCode": "JFK",
    //                                         "locationName": "John F. Kennedy",
    //                                         "cityCode": "NYC",
    //                                         "cityName": "New York City",
    //                                         "countryCode": "US",
    //                                         "countryName": "United States of America"
    //                                     },
    //                                     "arrivalAirport": {
    //                                         "locationCode": "SFO",
    //                                         "locationName": "San Francisco Intl",
    //                                         "cityCode": "SFO",
    //                                         "cityName": "San Francisco",
    //                                         "countryCode": "US",
    //                                         "countryName": "United States of America"
    //                                     },
    //                                     "marketingAirline": {
    //                                         "code": "B6",
    //                                         "flightNumber": "2615",
    //                                         "name": "JetBlue",
    //                                         "airlineLogo": "B6.gif"
    //                                     },
    //                                     "operatingAirline": {
    //                                         "flightNumber": "2615",
    //                                         "code": "B6",
    //                                         "airlineLogo": "B6.gif",
    //                                         "name": "JetBlue"
    //                                     },
    //                                     "equipment": [
    //                                         {}
    //                                     ],
    //                                     "flightNumber": "2615",
    //                                     "departureDate": "20230905",
    //                                     "departureTime": "2140",
    //                                     "arrivalDate": "20230906",
    //                                     "arrivalTime": "0053",
    //                                     "resBookDesigCode": "L",
    //                                     "rph": "1",
    //                                     "flightID": "1",
    //                                     "frequency": "SMT****"
    //                                 }
    //                             ],
    //                             "segmentRefDetail": [
    //                                 {
    //                                     "pax": [
    //                                         {
    //                                             "baggageAllowance": [
    //                                                 {
    //                                                     "type": "Check-in Allowance",
    //                                                     "numberOfPieces": "0",
    //                                                     "airlineCode": "B6"
    //                                                 }
    //                                             ],
    //                                             "type": "ADT"
    //                                         }
    //                                     ]
    //                                 }
    //                             ],
    //                             "directionInd": "O",
    //                             "group": "1",
    //                             "rph": "1"
    //                         }
    //                     ]
    //                 },
    //                 "validatingCarrier": "B6",
    //                 "totalInBoundDuration": "373",
    //                 "totalOutBoundDuration": "373",
    //                 "totalItineraryDuration": "373",
    //                 "groupDurations": [
    //                     {
    //                         "id": "1",
    //                         "duration": "373"
    //                     }
    //                 ]
    //             }
    //         ],
    //         "miscellaneousRemarkInfo": {
    //             "miscellaneousInfo": [
    //                 {
    //                     "text": "",
    //                     "id": "1",
    //                     "remarkType": "General"
    //                 },
    //                 {
    //                     "text": "",
    //                     "id": "2",
    //                     "remarkType": "InternalRemarks"
    //                 }
    //             ]
    //         },
    //         "errors": {
    //             "error": {
    //                 "code": "1023",
    //                 "description": "Your flight information retrieve sucessfully"
    //             },
    //             "status": "FALSE"
    //         },
    //         "reasons": {},
    //         "userID": "639c081a5f75319494ad6312",
    //         "environment": "PRODUCTION"
    //     }
    // }

    return data;
}

export async function importPNRBooking(bookinghData) {

    const response = await fetch(IMPORT_PNR_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function getHistoryImportPnr(id) {
    const response = await fetch(GET_HISTORY_IMPORT_PNR(id), {
        method: 'POST',
        body: JSON.stringify(id),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch import pnr history.');
    }

    return data;
}

export async function importTicket(bookinghData) {

    const response = await fetch(IMPORT_TICKET(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}
export async function blockTicket(requestData) {

    const response = await fetch(BLOCK_TICKET(), {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function reviewBooking(bookinghData) {

    const response = await fetch(REVIEW_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function issueTicket(bookinghData) {

    const response = await fetch(ISSUE_TICKET(), {
        method: 'POST',
        body: JSON.stringify(bookinghData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not booked.');
    }

    return data;
}

export async function travelRequest(travelRequestData) {
    const response = await fetch(TRAVEL_REQUEST(), {
        method: 'POST',
        body: JSON.stringify(travelRequestData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch travel request.');
    }

    return data;
}

export async function getServiceRequests(confirmationNumber) {
    const response = await fetch(RETRIEVE_SERVICE_REQUEST(confirmationNumber), {
        method: 'POST',
        body: JSON.stringify(confirmationNumber),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch service requests.');
    }

    return data;
}

export async function updateServiceRequest(travelRequestData) {
    const response = await fetch(UPDATE_SERVICE_REQUEST(), {
        method: 'POST',
        body: JSON.stringify(travelRequestData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update service request.');
    }

    return data;
}
import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchOfficeApi, searchCommonList } from '../../../services/Office-API'
import OfficeList from './Components/OfficeList'

import { NavLink } from 'react-router-dom';
import { UPDATEOFFICE } from '../../../navigation/CONSTANTS'
import { useTranslation } from 'react-i18next';
import CountryDropDownList from '../../../components/Common/DropDownList/CountryDropDownList';
import Dropdown from 'react-select';
import { useHistory } from 'react-router-dom';

import CityDropDownList from '../../../components/Common/DropDownList/CityDropDownList';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { validateEmail } from '../../../utils/Validation'
const SearchOffice = () => {
    useDocumentTitle('Search Office');

    const [enteredEmailId, setEnteredEmailId] = useState("");
    const [enteredContactPersonEmailId, setEnteredContactPersonEmailId] = useState("");
    const [enteredCompanyName, setEnteredCompanyName] = useState("");
    const [categoryCode, setCategoryCode] = useState('');
    //const [categoryList, setCategoryList] = useState([]);
    const [country_Code, setCountry_Code] = useState('');
    //const [cityList, setCityList] = useState([]);
    const [isFirtTime, setFirstTime] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const cityNameInputRef = useRef("");
    const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);

    const { t } = useTranslation(['translation.Office']);
    const history = useHistory();

    const emailIdChangeHandler = (event) => {
        setEnteredEmailId(event.target.value);
    };
    const contactPersonEmailIdChangeHandler = (event) => {
        setEnteredContactPersonEmailId(event.target.value);
    };

    const companyNameChangeHandler = (event) => {
        setEnteredCompanyName(event.target.value);
    };

    const categoryChangeHandler = (event) => {
        setCategoryCode(event.value);
    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };

    const { sendRequest: sendRequestCommonList, data: loadedCategory } = useHttp(searchCommonList);

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const onSearchCommonList = ({
            methodName: "Category",
            methodCode: "CAT",
            listID: "",
            listValue: "",
            subListID: ""
        });

        sendRequestCommonList(onSearchCommonList);
    }, [sendRequestCommonList])

    let categoryOptions = []
    let categoryArrayData = [];
    for (let i in loadedCategory) {
        if (loadedCategory['errors'].status === 'FALSE') {
            if (i === 'data') {
                categoryArrayData = loadedCategory[i];
            }
        }
    }

    for (let index = 0; index < categoryArrayData.length; index++) {
        categoryOptions = [...categoryOptions, { value: categoryArrayData[index].listID, label: categoryArrayData[index].listValue }]
    }

    const countryCodeValue = (country_Code) => {
        setCountry_Code(country_Code);
        //setCityCode('');        
        //setCityList(cityOptions);
    };


    const { sendRequest, status, data: loadedOffice, error } = useHttp(searchOfficeApi);
    const onResetHandler = (event) => {
        history.push('/SearchOffice')
    }

    const enteredEmailIdInputRef = useRef('');
    const [enteredEmailIdInvalidInputError, setEnteredEmailIdInvalidInputError] = useState(false);
    const enteredContactPersonEmailIdInputRef = useRef('');
    const [enteredContactPersonEmailIdInvalidInputError, setEnteredContactPersonEmailIdInvalidInputError] = useState(false);
    const enteredCompanyNameInputRef = useRef('');
    const [enteredCompanyNameInvalidInputError, setEnteredCompanyNameInvalidInputError] = useState(false);

    function onBlurCheck() {
        const enteredCityName = cityNameInputRef.current.value;
        if (enteredEmailId.length !== 0) {
            if (!validateEmail(enteredEmailId)) {
                setEnteredEmailIdInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredEmailIdInvalidInputError(false);
            }
        }
        else {
            setEnteredEmailIdInvalidInputError(false);
        }

        if (enteredContactPersonEmailId.length !== 0) {
            if (!validateEmail(enteredContactPersonEmailId)) {
                setEnteredContactPersonEmailIdInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredContactPersonEmailIdInvalidInputError(false);
            }
        }
        else {
            setEnteredContactPersonEmailIdInvalidInputError(false);
        }

        if (enteredCompanyName.length !== 0) {
            if (!enteredCompanyName.match('^[a-zA-Z  0-9 &-.]+$')) {
                setEnteredCompanyNameInvalidInputError(true);
                if (document.querySelector('#sortByText') !== null) {
                    document.querySelector('#sortByText').style.display = "none";
                }
                return;
            }
            else {
                setEnteredCompanyNameInvalidInputError(false);
            }
        }
        else {
            setEnteredCompanyNameInvalidInputError(false);
        }
        if (enteredCityName.trim().length !== 0) {
            if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
                setCityNameInvalidInputError(true);

                return;
            }
        }
        else {
            setCityNameInvalidInputError(false);
        }
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("User Management", "Office", "User Management_Office_Search")) {
            return false;
        }

        const enteredCityName = cityNameInputRef.current.value;
        setFirstTime(true);

        if (enteredEmailId.length !== 0) {
            if (!validateEmail(enteredEmailId)) {
                enteredEmailIdInputRef.current.focus();
                setEnteredEmailIdInvalidInputError(true);
                return;
            }
            else {
                setEnteredEmailIdInvalidInputError(false);
            }
        }
        else {
            setEnteredEmailIdInvalidInputError(false);
        }

        if (enteredContactPersonEmailId.length !== 0) {
            if (!validateEmail(enteredContactPersonEmailId)) {
                enteredContactPersonEmailIdInputRef.current.focus();
                setEnteredContactPersonEmailIdInvalidInputError(true);
                return;
            }
            else {
                setEnteredContactPersonEmailIdInvalidInputError(false);
            }
        }
        else {
            setEnteredContactPersonEmailIdInvalidInputError(false);
        }

        if (enteredCompanyName.length !== 0) {
            if (!enteredCompanyName.match('^[a-zA-Z  0-9 &-.]+$')) {
                enteredCompanyNameInputRef.current.focus();
                setEnteredCompanyNameInvalidInputError(true);
                return;
            }
            else {
                setEnteredCompanyNameInvalidInputError(false);
            }
        }
        else {
            setEnteredCompanyNameInvalidInputError(false);
        }
        if (enteredCityName.trim().length !== 0) {
            if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
                setCityNameInvalidInputError(true);
                return;
            }
        }
        else {
            setCityNameInvalidInputError(false);
        }

        const onSearchOffice = ({
            companyName: enteredCompanyName,
            categoryID: categoryCode,
            countryCode: country_Code,
            cityCode: cityNameInputRef.current.value,
            loginEmailID: enteredEmailId,
            email: enteredContactPersonEmailId,
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
        });

        if (enteredEmailIdInvalidInputError === false && enteredContactPersonEmailIdInvalidInputError === false && enteredCompanyNameInvalidInputError === false) {
            setIsSubmitClicked(true);
            sendRequest(onSearchOffice);
        }
    }, [sendRequest, country_Code, enteredEmailIdInvalidInputError,
        enteredContactPersonEmailIdInvalidInputError, enteredCompanyNameInvalidInputError,
        categoryCode, enteredCompanyName, enteredContactPersonEmailId, enteredEmailId])

    useEffect((sendRequest) => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    // if (status === 'pending') {
    //     return (
    //         <Fragment>
    //             <section id='common_banner'><div className='container'></div></section>
    //             <div className='centered'>
    //                 <LoadingSpinner></LoadingSpinner>
    //             </div>
    //         </Fragment>
    //     )
    // }

    const errorHandler = () => {
        history.go('/searchoffice');
    };

    if (error) {
        history.push('/Error')
        /*   return <ErrorModal
              title={'Message'}
              message={t('ErrorMessage')}
              onConfirm={errorHandler}
          ></ErrorModal> */
    }

    let loadedData = [];
    for (let i in loadedOffice) {
        if (loadedOffice['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedOffice[i];
            }
        }
    }

    const ErrorShow = (!enteredEmailIdInvalidInputError && !enteredContactPersonEmailIdInvalidInputError && isSubmitClicked && enteredCompanyNameInvalidInputError === false) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const officeList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <OfficeList office={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Office</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='loginemailid'>{t('loginEmailId')}</label>
                                                            <input minLength={1} maxLength={100} type='text' className="mb-0 form-control form-control-sm col-lg-12" id='loginemailid' value={enteredEmailId} onChange={emailIdChangeHandler} ref={enteredEmailIdInputRef} onBlur={onBlurCheck} autoFocus />
                                                            {enteredEmailIdInvalidInputError &&
                                                                <p className="error-text">{t('validationEmailIdInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='contactpersonemailid'>{t('contactPersonEmailId')}</label>
                                                            <input minLength={1} maxLength={100} type='text' className="mb-2 form-control form-control-sm col-lg-12" id='contactpersonemailid' value={enteredContactPersonEmailId} onChange={contactPersonEmailIdChangeHandler} ref={enteredContactPersonEmailIdInputRef} onBlur={onBlurCheck} />
                                                            {enteredContactPersonEmailIdInvalidInputError &&
                                                                <p className="error-text">{t('validationContactPersonEmailIdInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='companyname'>{t('companyName')}</label>
                                                            <input minLength={1} maxLength={100} type='text' className="mb-2 form-control form-control-sm col-lg-12" id='companyname' value={enteredCompanyName} onChange={companyNameChangeHandler} ref={enteredCompanyNameInputRef} onBlur={onBlurCheck} />
                                                            {enteredCompanyNameInvalidInputError &&
                                                                <p className="error-text">{t('validationCompanyNameInvalidData')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>{t('category')}</label>
                                                            <Dropdown onChange={categoryChangeHandler}
                                                                //value={categoryCode}
                                                                value={categoryOptions.filter(option => option.value === categoryCode)}
                                                                options={categoryOptions}
                                                                textField="listValue"
                                                                dataItemKey="listID"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='country'>{t('country')}</label>
                                                    <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={country_Code} iSDisabled={false} />
                                                </div>
                                                <div className='control'>
                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}</label>
                                                    <input maxLength={100} className="form-control form-control-sm" type='text'
                                                        id='cityName'
                                                        ref={cityNameInputRef}
                                                        onBlur={onBlurCheck}

                                                    />
                                                    {cityNameInvalidInputError &&
                                                        <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                    }
                                                </div>

                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("User Management", "Office", "User Management_Office_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={UPDATEOFFICE}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("User Management", "Office", "User Management_Office_Search") &&
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {officeList}
        </Fragment>)
};

export default SearchOffice
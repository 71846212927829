import { Fragment } from 'react';
import { breakTeaxtOnFullStop, parseHotelPolicy } from '../../../utils/CommonFunction';

const Policies = (props) => {

    let cancellationPolicies = "";
    let hotelPolicy = "";
    let depositPolicy = "";
    let guarnteePolicy = "";

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.forEach(result => {
            if (result.text.type === 'CancellationPolicy') {
                cancellationPolicies = result.text.value;
            }
        });

        props.hotelDetails.data.hotelResults[0].descriptions.forEach(result => {
            if (result.text.type === 'Policies') {
                hotelPolicy = result.text.value;
            }
            if (result.text.type === 'GuaranteePolicy') {
                guarnteePolicy = result.text.value;
            }
            if (result.text.type === 'DepositPolicy') {
                depositPolicy = result.text.value;
            }
        });

    }

    return <Fragment>
        <div className="tour_details_boxed" >
            <h3 className="heading_theme mb-0" >Hotel Policies</h3>
            <div className="tour_details_boxed_inner">
                <div className="tour_package_details_bar_list first_child_padding_none">
                    <div className=''>
                        {parseHotelPolicy(hotelPolicy)}
                        {cancellationPolicies !== '' &&
                            < div className='fw-bold text-primary mt-1 mb-2'> Cancellation Policy</div>
                        }
                        {breakTeaxtOnFullStop(cancellationPolicies)}
                        {/*User Story 3234: Sabre Hotel values that are coming which we are not displaying*/}
                        {depositPolicy !== '' &&
                            < div className='fw-bold text-primary my-2 '> Deposit Policy</div>
                        }
                        {breakTeaxtOnFullStop(depositPolicy)}
                        {guarnteePolicy !== '' &&
                            < div className='fw-bold text-primary my-2'> Guarantee Policy</div>
                        }
                        {breakTeaxtOnFullStop(guarnteePolicy)}
                    </div>
                </div>

            </div>
        </div>
    </Fragment >
}

export default Policies;
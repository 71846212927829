import React, { useEffect, useState } from 'react';
import TravelDeviationList from './TravelDeviationList'
import ReactPaginate from 'react-paginate';

//let items = JSON.parse(sessionStorage.getItem("reportsData"));


function PaginatedItems({ items,itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)

    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //const currentItems = items.data;//items.data.slice(itemOffset, endOffset);
    let currentItems = [];
    for (let index = itemOffset; index < endOffset; index++) {
        if (items.data[index] !== undefined)
            currentItems = [...currentItems, items.data[index]]
        else
            break;
    }
    const pageCount = Math.ceil(items.data.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.data.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    console.log(currentItems);
    return (
        <>

            <div  style={{ justifyContent: "center" }}>
                <ReactPaginate
                    // nextLabel="Next >"
                    nextLabel={<span><span>Next</span> &gt;</span>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={1}
                    // previousLabel="< Previous"
                    previousLabel={<span>&lt;<span>Previous</span></span>}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>

            {/*  <Items currentItems={currentItems} /> */}
            <div className="col-lg-12" style={{ alignItems: "center" }}>
                <TravelDeviationList currentItems={currentItems}></TravelDeviationList>
                
            </div>
        </>
    );
}

export default PaginatedItems; 
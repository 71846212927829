import { Fragment } from 'react';
import Nearby from './NearBy';
import { removeFirstAndLastSpecialChar } from '../../../utils/CommonFunction';
import { containsOnlyDots } from '../../../utils/CommonFunction';


const AboutArea = (props) => {
    let attaractios = "";
    let transport;

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'TransportationInfo') {
                transport = result.text.value;
            }
        });
    }

    let transportInfo = "";
    let showTransport = [];
    if (transport !== undefined) {
        transportInfo = transport.split(/\r?\n/);
        transportInfo.forEach(element => {
            if (element !== '' && !containsOnlyDots(element) && !element.includes("-AIRPORT TRANSPORTATION-")) {
                let str = removeFirstAndLastSpecialChar(element);
                showTransport.push(<div className='row'>
                    <div className='col-md-12'>
                        <div className='my-1' style={{ fontSize: "16px" }}>
                            <i className="fas fa-circle text-8px text-muted py-1"></i> <span className="ms-1">{(str)}</span>
                        </div>
                    </div>
                </div>);
            }

        });
    }

    return (<Fragment>
        <div className="mt-2" style={{ paddingBottom: "4px", paddingTop: "4px" }} >
            <h3 className="heading_theme" style={{ marginBottom: "4px" }}>About this Area</h3>
            <div className='row' style={{ fontSize: "16px" }}>

                {attaractios !== '' && <div className='col-md-6'>
                    <Nearby hotelDetails={props.hotelDetails} />
                </div>}

                {showTransport.length !== 0 && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-car"></i> Getting Around</h4>
                    <table><tr><td className=''>  {showTransport}</td></tr></table>
                </div>}

            </div>
        </div>
    </Fragment>)
}

export default AboutArea;
import { Fragment } from 'react';
import { removeFirstAndLastSpecialChar, removeHtmlTags } from '../../../utils/CommonFunction';


const HotelDetails = (props) => {
    let foodAndDrink = "";
    let recreation = "";
    let service = '';
    let facilities;
    let safetyInfo = "";
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining') {
                foodAndDrink = result.text.value;
            }
            if (result.text.type === 'Recreation') {
                recreation = result.text.value.replace(/\n\n/g, '\n');
            }
            if (result.text.type === 'Services') {
                service = result.text.value;
            }
            if (result.text.type === 'Facilities') {
                facilities = result.text.value;
            }
            if (result.text.type === 'SafetyInfo') {
                safetyInfo = result.text.value;
            }
        });
    }

    let foodAndDrinks = "";
    let showFoodAndDrink = [];
    if (foodAndDrink !== undefined && foodAndDrink !== '') {
        foodAndDrinks = foodAndDrink.split(/\r?\n/);
        if (foodAndDrinks !== '') {
            foodAndDrinks.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showFoodAndDrink.push(<div className='py-1'>{item}</div>);

                }
            });
        } else {
            showFoodAndDrink.push(<div className='py-1'>{removeHtmlTags(removeFirstAndLastSpecialChar(foodAndDrink))}</div>)

        }

    }


    let services;
    if (service !== undefined) {
        services = service.split(/\r?\n/);
    }
    ;


    let facility = "";
    let firstArray = [];
    let secondArray = [];
    if (facilities !== undefined) {
        facility = facilities.split(/\r?\n/);

        if (facility.length > 0) {
            if (facility.length % 2 === 0) {
                firstArray = facility.slice(0, facility.length / 2);
                secondArray = facility.slice(facility.length / 2);

            } else {
                firstArray = facility.slice(0, Math.floor(facility.length / 2) + 1)
                secondArray = facility.slice(Math.floor(facility.length / 2) + 1);
            }
        }
    }
    let recreations = "";
    let ShowRecreation = [];
    if (recreation !== undefined && recreation !== '') {
        recreations = recreation.split(/\r?\n/);
        if (recreations !== '') {
            recreations.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    ShowRecreation.push(<div className='py-1'>{item}</div>);

                }
            });
        } else {
            ShowRecreation.push(<div className='py-1'>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</div>)

        }

    }
    let safetyInfos = "";
    let showSafetyInfo = [];
    if (safetyInfo !== undefined && safetyInfo !== '') {
        safetyInfos = safetyInfo.split(/\r?\n/);
        if (safetyInfos !== '') {
            safetyInfos.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showSafetyInfo.push(<div className='py-1'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{item}</div>);
                }
            });
        } else {
            showSafetyInfo.push(<div className='py-1'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</div>)

        }

    }
    return (<Fragment>

        <div className=" mt-2" >
            <h3 className="heading_theme" style={{ marginBottom: "4px" }}>Hotel Details</h3>
            <div className='row'>
                {foodAndDrinks !== '' && <div className='col-md-6'>
                    <h5 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Food and drink</h5>
                    <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                <div className='col-md-12'>
                                    <table><tr>
                                        <td>   {showFoodAndDrink}</td>
                                    </tr></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {service !== '' && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Services</h4>

                    <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                <table><tr><td className=''> {services !== '' && services.map(item => {
                                    let service = removeHtmlTags(removeFirstAndLastSpecialChar(item))
                                    if (service !== '') {

                                        return <div className='py-1 col-md-12' style={{ fontSize: "16px" }}><i className="fas fa-check  py-1 me-2 text-sky-blue"></i>{service}</div>
                                    }

                                })}</td></tr></table>
                            </div>
                        </div>
                    </div>
                </div>}
                {facility !== '' && <div className='row'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Facilities</h4>
                    <div className='col-md-6'>
                        <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                            <div className="">
                                <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                    <div className='row'>
                                        <table><tr> <td>  {facility !== '' && firstArray.map(item => {
                                            let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                            if (ele !== '') {
                                                return <div className='py-1 col-md-12'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{ele}</div>
                                            }

                                        })}</td></tr></table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                            <div className="">
                                <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                    <div className='row'>
                                        <table><tr><td className=''>
                                            {facility !== '' && secondArray.map(item => {
                                                let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                                if (ele !== '') {
                                                    return <div className='py-1 col-md-12'><i className="fas fa-check py-1 me-2 text-sky-blue"></i>{ele}</div>
                                                }

                                            })}
                                        </td> </tr></table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>}


            </div>
            {/* <div className='row'>
                {recreation !== '' && <div className='col-md-6'>
                    <h5 className="h5 mt-1 text-primary"><i class="fas fa-theater-masks"></i> Recreation</h5>
                    <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                <div className='row'>
                                    <table><tr><td>{ShowRecreation}</td></tr></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {safetyInfo !== '' && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i class="fas fa-shield-alt"></i> Safety Info</h4>
                    <div className=" tour_detail_right_sidebar" style={{ marginBottom: "4px" }}>
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2" style={{ fontSize: "16px" }}>
                                <div className='row'>
                                    <table><tr><td>{showSafetyInfo}</td></tr></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div> */}

        </div>


    </Fragment>)
}

export default HotelDetails;
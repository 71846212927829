import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import DelegatedDialogItem from "./DelegatedDialogItem";

const DelegatedDialogList = (props) => {
  const { t } = useTranslation(["translation.Delegate"]);

  function handleAddApproverToSelectedList(approverUserID) {
    props.onAddApproverToSelectedList(approverUserID);
  }

  function handleSortChange(event) {
    props.onSortChange(event);
  }

  return (
    <Fragment>
      <div className="container-fluid mb-3 list-view">
        {props.filteredMasterApprovers &&
          props.filteredMasterApprovers.length > 0 && (
            <Fragment>
              <div className="row">
                <div className="col-lg-12 mb-2">
                  <div className="row mb-3">
                    <div className="col-md-6"></div>
                    <div className="col-md-2 my-auto text-muted text-left">
                      <div className="float-lg-end float-md-end">{t("sortBy")}</div>
                    </div>
                    <div className="col-md-4 form-group">
                      <select
                        id="sortBy"
                        onChange={handleSortChange}
                        className="form-control form-select bg_input"
                      >
                        <option value="asc">{t("firstNameAsc")}</option>
                        <option value="desc">{t("firstNameDsc")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {props.filteredMasterApprovers.map((approver) => (
                <DelegatedDialogItem
                  key={"MA" + approver.id}
                  userID={approver.id}
                  title={approver.title}
                  firstName={approver.firstName}
                  middleName={approver.middleName}
                  lastName={approver.lastName}
                  employeeCode={approver.employeeCode}
                  corporateMasterName={approver.corporateMasterName}
                  approvalTypeName={approver.approvalTypeName[0]}
                  onAddApproverToSelectedList={handleAddApproverToSelectedList}
                  handleCloseModal={props.handleCloseModal}
                />
              ))}
            </Fragment>
          )}
      </div>
    </Fragment>
  );
};

export default DelegatedDialogList;

import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchWhoIsWhereApi } from '../../../services/Report-API'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import PaginatedItems from './Components/PaginatedItems'
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as React from "react";
import { getFormatDate } from './../../../utils/CommonFunction';
import ExportExcel from './Components/ExportExcel';
import { getAllCountry } from '../../../services/Country-API';
import { searchCity } from '../../../services/City-API';
import { sortData } from '../../../utils';

//Paul: The report pages are taken from flight booking report and quick changes have been done for demo purpose.
//When actual work is done, remember to change property names accordingly.

const SearchWhoIsWhere = () => {
    useDocumentTitle('Who is Where Report')
    
    const [companyNameInput, setCompanyNameInput] = useState(JSON.parse(localStorage.getItem('officeID')));
    const [companyNameLabelInput, setCompanyNameLabelInput] = useState(JSON.parse(localStorage.getItem('companyName')));

    const { t } = useTranslation(['translation.CreditLimit']);
    const [dateError, setDateError] = useState()
    const history = useHistory();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const enteredDateFromInputRef = useRef('');
    const enteredDateToInputRef = useRef('');

    const [dateFromInputHasError, setDateFromInputHasError] = useState(false);
    const [dateToInputHasError, setDateToInputHasError] = useState(false);
    const [error1, setError] = useState();
    const [dateFromInvalidInputError, setDateFromInvalidInputError] = useState(false);
    const [dateToInvalidInputError, setDateToInvalidInputError] = useState(false);

    const [isDisplayData, setIsDisplayData] = useState(false);

    const { sendRequest, status, data: loadedFlightBooking, error } = useHttp(searchWhoIsWhereApi);

    const [countryCode, setCountryCode] = useState('');    
    const [countryOptions, setCountryOptions] = useState([]);
    const { sendRequest: getCountryOptions, data: countryMaster, status: getCountryOptionsStatus } = useHttp(getAllCountry);

    const [cityCode, setCityCode] = useState('');
    const [cityOptions, setCityOptions] = useState([]);
    const { sendRequest: getCityOptions, data: cityMaster, status: getCityOptionsStatus } = useHttp(searchCity);

    const [whoIsWhereList, setWhoIsWhereList] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        var today = new Date().toISOString().split('T')[0];
        //document.getElementById("DateFrom").setAttribute('max', today);
        //document.getElementById("DateTo").setAttribute('max', today);
        document.getElementById("DateFrom").setAttribute('value', today);
        document.getElementById("DateTo").setAttribute('value', today);
    }, [])

    let companyOptions = [
        {
            value: 'All', label: 'All'
        },
        {
            value: JSON.parse(localStorage.getItem('officeID')), label: JSON.parse(localStorage.getItem('companyName'))
        }
    ];
    
    const onResetHandler = (event) => {
        history.push('/SearchWhoIsWhere');
        history.go();
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (enteredDateFromInputRef.current.value === '') {
            setDateFromInputHasError(true);
            return;
        }
        else {
            setDateFromInputHasError(false);
        }

        if (enteredDateToInputRef.current.value === '') {
            setDateToInputHasError(true);
            return;
        }
        else {
            setDateToInputHasError(false);
        }

        //var today = new Date().toISOString().split('T')[0];
        //if (enteredDateFromInputRef.current.value > today) {
        //    setDateFromInvalidInputError(true);
        //    return;
        //}
        //else {
        //    setDateFromInvalidInputError(false);
        //}

        //if (enteredDateToInputRef.current.value > today) {
        //    setDateToInvalidInputError(true);
        //    return;
        //}
        //else {
        //    setDateToInvalidInputError(false);
        //}

        if (enteredDateToInputRef.current.value < enteredDateFromInputRef.current.value) {

            setDateError({
                title: "Message",
                message: 'Travel Date To cannot be less than Travel Date From.'
            });
            return;
        }

        const onSearchFlightBooking = ({
            "officeID": companyNameInput === 'All' ? JSON.parse(localStorage.getItem('officeID')) : companyNameInput,
            "officeName": companyNameLabelInput,
            "userID": "",
            "dateFrom": enteredDateFromInputRef.current.value,
            "dateTo": enteredDateToInputRef.current.value,
            "status": 'All'
        });

        setIsSubmitClicked(true);
        sendRequest(onSearchFlightBooking);

    }, [sendRequest, companyNameInput]);

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                //onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    useEffect(() => {
        if (status === 'completed') {
            if (loadedFlightBooking.data !== null &&
                (loadedFlightBooking['errors'].status === 'TRUE' ||
                    (loadedFlightBooking.data[0].length <= 0 && loadedFlightBooking.data[0].length <= 0))) {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
            else {
                for (let i in loadedFlightBooking) {
                    if (loadedFlightBooking['errors'].status === 'FALSE') {
                        if (i === 'data') {

                            let clonedWhoIsWhereList = JSON.parse(JSON.stringify(loadedFlightBooking));

                            //console.log('clonedWhoIsWhereList', clonedWhoIsWhereList);

                            sortData(clonedWhoIsWhereList.data, "departureDate", false);

                            clonedWhoIsWhereList.data.forEach((item, index) => {
                                item.slNo = index + 1
                            });

                            clonedWhoIsWhereList.data[0].dateFrom = enteredDateFromInputRef.current.value;
                            clonedWhoIsWhereList.data[0].dateTo = enteredDateToInputRef.current.value;
                            clonedWhoIsWhereList.data[0].companyNameInput = companyNameLabelInput;
                            clonedWhoIsWhereList.data[0].statusInput = 'All';

                            //console.log('clonedWhoIsWhereList', clonedWhoIsWhereList);

                            setWhoIsWhereList(() => clonedWhoIsWhereList);
                        }
                    }
                }
                setIsDisplayData(true);
            }
        }

    }, [status, loadedFlightBooking])

    useEffect(() => {
        if (countryMaster
            && countryMaster.errors.status === 'FALSE'
            && countryMaster.data) {

            setCountryOptions(() => countryMaster.data.map(country => {
                return {
                    value: country.countryCode,
                    label: country.countryName
                };
            }));

        }
    }, [countryMaster]);

    //handle after getting city data from api
    useEffect(() => {
        if (cityMaster
            && cityMaster.errors.status === 'FALSE'
            && cityMaster.data) {

            //This condition is added in order to handle a case where user selects a country and immediately deselects/removes selection.
            //In this case, city options will not be populated
            if (countryCode) {
                setCityOptions(() => cityMaster.data.map(city => {
                    return {
                        value: city.cityCode,
                        label: `${city.cityName} (${city.cityCode})`
                    };
                }));
            }
        }
    }, [cityMaster]);

    useEffect(() => {
        getCountryOptions();
    }, []);

    if (error) {
        return <section id='common_banner'>
            <div className='container'>
                <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}
                </div>
            </div>
        </section>;
    }

    function dateErrorHandler() {
        setDateError(false);
    }

    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
        setCompanyNameLabelInput(event.label)
    }

    const errorHandler = () => {
        history.go('/SearchWhoIsWhere');
    };

    const ErrorShow = (isSubmitClicked && error1) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const onBlurCheck = (event) => {
        if (enteredDateFromInputRef.current.value !== '') {
            setDateFromInputHasError(false);
        }

        if (enteredDateToInputRef.current.value !== '') {
            setDateToInputHasError(false);
        }
    }

    function handleCountryChange(selectedOption) {
        if (selectedOption) {
            setCountryCode(selectedOption.value);
            setCityCode('');
            setCityOptions([]);
            getCities(selectedOption.value);
        } else {
            //clear triggered
            setCountryCode('');
            setCityCode('');
            setCityOptions([]);
        }
    }

    function getCities(countryCode) {
        getCityOptions({
            countryCode: countryCode,
            cityCode: '',
            cityName: ''
        });
    }

    function handleCityChange(selectedOption) {
        if (selectedOption) {
            setCityCode(selectedOption.value);
        } else {
            //clear triggered
            setCityCode('');
        }
    }

    return (<Fragment>
        {
            dateError && <ErrorModal
                title={dateError.title}
                message={dateError.message}
                onConfirm={dateErrorHandler}
            ></ErrorModal>
        }
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {!isDisplayData ?
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12">
                                                <i className="fas fa-globe-asia"></i> Who is Where
                                            </label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="mb-2 h6 text-primary col-lg-12"
                                                                htmlFor='UpdateAmount'>
                                                                Travel Date From<span className="error-icon"> *</span>
                                                            </label>
                                                            <input maxLength="10"
                                                                type='date'
                                                                ref={enteredDateFromInputRef}
                                                                className="form-control form-control-sm"
                                                                id='DateFrom'
                                                                onBlur={onBlurCheck} />

                                                            {dateFromInputHasError &&
                                                                <p className="error-text">{'Travel Date From is required.'}</p>
                                                            }
                                                            {dateFromInvalidInputError &&
                                                                <p className="error-text">{'Travel Date From cannot be greater than current date.'}</p>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="mb-2 h6 text-primary col-lg-12"
                                                                htmlFor='UpdateAmount'>
                                                                Travel Date To<span className="error-icon"> *</span>
                                                            </label>
                                                            <input maxLength="10" type='date'
                                                                ref={enteredDateToInputRef}
                                                                className="form-control form-control-sm" id='DateTo'
                                                                onBlur={onBlurCheck} />

                                                            {dateToInputHasError &&
                                                                <p className="error-text">{'Travel Date To is required.'}</p>
                                                            }
                                                            {dateToInvalidInputError &&
                                                                <p className="error-text">{'Travel Date To cannot be greater than current date.'}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>Company Name</label>
                                                            <Dropdown onChange={comapnyNameChaneHandler}
                                                                value={companyOptions.filter(option => option.value === companyNameInput)}
                                                                options={companyOptions}
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select" />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>Employee Name</label>
                                                            <input maxLength={100}
                                                                type='text'
                                                                className="form-control bg_input col-md-6" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>Country</label>
                                                            <Dropdown
                                                                value={countryOptions.filter(option => option.value === countryCode)}
                                                                options={countryOptions || []}
                                                                onChange={handleCountryChange}
                                                                isLoading={getCountryOptionsStatus === 'pending'}
                                                                controlClassName="form-control control form-select bg_input mb-0"
                                                                placeholder="Please Select"
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2" htmlFor='city'>City</label>
                                                            <Dropdown
                                                                value={cityOptions.filter(option => option.value === cityCode)}
                                                                options={cityOptions || []}
                                                                onChange={handleCityChange}
                                                                isLoading={getCityOptionsStatus === 'pending'}
                                                                controlClassName="form-control control form-select bg_input mb-0"
                                                                placeholder="Please Select"
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="mt-3">
                                                    <span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span>
                                                </p>

                                                <div className='mt-3 col-md-12 actions'>
                                                    <button
                                                        className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1'
                                                        onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>

                                                    <button
                                                        className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                        onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section> :
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label htmlFor=""
                                                            className="h5 text-primary">
                                                            <i className="fas fa-globe-asia"></i> Who is Where Report
                                                        </label>
                                                    </div>
                                                    <div className='col-md-6'>

                                                        <div className='float-lg-end'>
                                                            <span id="excel" className='float-end'>
                                                                <ExportExcel reportJSON={whoIsWhereList.data} ></ExportExcel>
                                                            </span>
                                                        </div>
                                                        <div className='float-lg-end d-none'>
                                                            <span id="pdf"
                                                                className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end'>
                                                                <i className="fas fa-file-pdf"></i>
                                                            </span>
                                                        </div>
                                                        <div className='float-lg-end'>
                                                            <a id='back' href="/SearchWhoIsWhere"
                                                                className='btn text-white gt-main-btn btn-sm ms-1 mt-1 float-lg-end'>
                                                                <span><i className="fa fa-arrow-left"></i></span>
                                                            </a>
                                                        </div>

                                                        <ReactTooltip
                                                            anchorId={"print"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Take a print"}
                                                        />
                                                        <ReactTooltip
                                                            anchorId={"pdf"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Export PDF"}
                                                        />
                                                        <ReactTooltip
                                                            anchorId={"excel"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Export Excel"}
                                                        />
                                                        <ReactTooltip
                                                            anchorId={"back"}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Back to Search"}
                                                        />

                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div
                                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="">
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                    </div>
                                                                    <div className="table-responsive my-2">
                                                                        <table className="table table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td scope='col' className='fw-bold'>Travel Date From</td>
                                                                                    <td scope="col">{getFormatDate(whoIsWhereList.data[0].dateFrom.replaceAll('-', ''))}</td>
                                                                                    <td scope='col' className='fw-bold'>Travel Date To</td>
                                                                                    <td scope="col">{getFormatDate(whoIsWhereList.data[0].dateTo.replaceAll('-', ''))}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='w-25 fw-bold'>Company Name</td>
                                                                                    <td className='w-25'>{whoIsWhereList.data[0].companyNameInput}</td>
                                                                                    <td className='w-25 fw-bold'></td>
                                                                                    <td className='w-25'></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12" >
                                                            <PaginatedItems items={whoIsWhereList} itemsPerPage={10} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        {ErrorShow}
    </Fragment>)
};

export default SearchWhoIsWhere;

import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import useHttp from '../../../services/use-http'
import { searchUserFriendly } from '../../../services/UserFriendly-API'
import { searchCurrency } from '../../../services/Currency-API'
import UserFriendlyList from './Components/UserFriendlyList'
import { NavLink, useHistory } from 'react-router-dom';
import { UPDATEUSERFRIENDLY } from '../../../navigation/CONSTANTS';
import Dropdown from 'react-select';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { useTranslation } from 'react-i18next';
import { searchCommonList } from '../../../services/Office-API'
const SearchUserFriendly = () => {
    useDocumentTitle('Search Message')
    const [currencyCode, setCurrencyCode] = useState('');
    const [isFirtTime, setFirstTime] = useState("");
    const { t } = useTranslation(['translation.UserFriendly']);
    const [module, setModule] = useState('');
    const [functionalties, setfunctionalties] = useState('');
    const history = useHistory();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const { sendRequest: sendRequestCommonListModule, data: loadedModule } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCommonListFunctinlites, data: loadedFunctinlites } = useHttp(searchCommonList);
    const { sendRequest: sendRequestCurrencyList, data: loadedCurrency } = useHttp(searchCurrency);
    const moduleChangeHandler = (event) => {
        setModule(event.value);

    };
    const functinlitesChangeHandler = (event) => {
        setfunctionalties(event.value);

    };
    useEffect(() => {

        const onSearchCommonListCat = ({
            methodName: "Module",
            methodCode: "MOD",
            listID: "",
            listValue: "",
            subListID: ""
        });
        sendRequestCommonListModule(onSearchCommonListCat);
        const onSearchCommonListSubCat = ({
            methodName: "",
            methodCode: "FUNS",
            listID: "",
            listValue: "",
            subListID: module
        });
        if (module !== '') {
            sendRequestCommonListFunctinlites(onSearchCommonListSubCat);
        }

    }, [sendRequestCommonListModule,
        sendRequestCommonListFunctinlites, module
    ]);

    let moduleOptions = []
    let moduleArrayData = [];
    for (let i in loadedModule) {
        if (loadedModule['errors'].status === 'FALSE') {
            if (i === 'data') {
                moduleArrayData = loadedModule[i];
            }
        }
    }
    for (let index = 0; index < moduleArrayData.length; index++) {
        moduleOptions = [...moduleOptions, { value: moduleArrayData[index].listID, label: moduleArrayData[index].listValue }]
    }
    let functinlitesOptions = []
    let functinlitesArrayData = [];
    for (let i in loadedFunctinlites) {
        if (loadedFunctinlites['errors'].status === 'FALSE') {
            if (i === 'data') {
                functinlitesArrayData = loadedFunctinlites[i];
            }
        }
    }

    for (let index = 0; index < functinlitesArrayData.length; index++) {
        functinlitesOptions = [...functinlitesOptions, { value: functinlitesArrayData[index].listID, label: functinlitesArrayData[index].listValue }]
    }



    let currencyOptions = []
    let currencyArrayData = [];
    for (let i in loadedCurrency) {
        if (loadedCurrency['errors'].status === 'FALSE') {
            if (i === 'data') {
                currencyArrayData = loadedCurrency[i];
            }
        }
    }

    for (let index = 0; index < currencyArrayData.length; index++) {
        currencyOptions = [...currencyOptions, { value: currencyArrayData[index].currCode, label: currencyArrayData[index].currName + '(' + currencyArrayData[index].currCode + ')' }]
    }


    const { sendRequest, status, data: loadedCreditLimit, error } = useHttp(searchUserFriendly);
    const onResetHandler = (event) => {

        history.push('/SearchUserFriendly');
        history.go();
    }

    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Master", "Message", "Master_Message_Search")) {
            return false;
        }

        setFirstTime(true);
        const onSearchCreditLimit = ({
            "functionlityCode": functionalties,
            "moduleCode": module,
            "currentMessage": "",
            "traceID": ""
        });

        setIsSubmitClicked(true);
        sendRequest(onSearchCreditLimit);

    }, [sendRequest, module, functionalties]);

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                //onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);


    if (error) {
        return <section id='common_banner'><div className='container'><div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div></div></section>
    }

    let loadedData = [];
    for (let i in loadedCreditLimit) {
        if (loadedCreditLimit['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCreditLimit[i];
            }
        }
    }


    const errorHandler = () => {
        history.go('/SearchUserFriendly');
    };
    const ErrorShow = (isSubmitClicked) ?
        <ErrorModal
            title={'Message'}
            message={t('ErrorMessage')}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const creditLimitList = !isFirtTime ? '' : (loadedData && loadedData.length !== 0) ?
        <UserFriendlyList creditLimit={loadedData} /> : (status !== 'pending' && ErrorShow)

    return (
        <Fragment>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Message</label>
                                            <form>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-6'>
                                                        <div className='control'>
                                                            <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Currency'>Module</label>
                                                            <Dropdown onChange={moduleChangeHandler}
                                                                id='currency'
                                                                value={moduleOptions.filter(option => option.value === module)}
                                                                options={moduleOptions}
                                                                textField="currName"
                                                                dataItemKey="currCode"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select"
                                                                autoFocus
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-sm-6'>
                                                        <div className='control'>
                                                            <label className="mb-2 h6 text-primary col-lg-12" htmlFor='Currency'>Functionality</label>
                                                            <Dropdown onChange={functinlitesChangeHandler}
                                                                id='currency'
                                                                value={functinlitesOptions.filter(option => option.value === functionalties)}
                                                                options={functinlitesOptions}
                                                                textField="currName"
                                                                dataItemKey="currCode"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input'
                                                                placeholder="Please Select"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "Message", "Master_Message_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={UPDATEUSERFRIENDLY}>
                                                            {t('new')}
                                                        </NavLink>
                                                    }
                                                    {checkSecurityDetails("Master", "Message", "Master_Message_Search") &&
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {creditLimitList}
        </Fragment>)
};

export default SearchUserFriendly

import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import { getRetrieveTrip } from '../../../../services/Booking-API';
import useHttp from '../../../../services/use-http';
import { useHistory } from "react-router-dom";
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction';

const FlightBookingItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    function openRetrieveBooking(id) {
        sendRequest(id);
    }

    useEffect(() => {
        if (status === 'completed') {
            let bookingJSON = [];


            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                let currentStatus = loadedRetrieveTripDetails.data.airItinerary[0] !== undefined && loadedRetrieveTripDetails.data.airItinerary[0].isCancelled !== undefined ? loadedRetrieveTripDetails.data.airItinerary[0].isCancelled : false;
                bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", currentStatus);
                sessionStorage.setItem("tripStatus", loadedRetrieveTripDetails.data.reasons !== undefined && loadedRetrieveTripDetails.data.reasons.reason !== undefined && loadedRetrieveTripDetails.data.reasons.reason.length !== 0 && loadedRetrieveTripDetails.data.reasons.reason[0].flightStatus !== undefined && loadedRetrieveTripDetails.data.reasons.reason[0].flightStatus === 'CR' ? "Cancelation Requested" : null);

                // history.push({
                //     pathname: '/RetrieveTrip',
                //     mytrip: { isCanceled: props.isCanceled !== undefined ? props.isCanceled : false }
                // });
                window.open('/RetrieveTrip?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])

    let resultCurrRoundingValue = props.reportJSON.roundingValue !== undefined && props.reportJSON.roundingValue !== null && props.reportJSON.roundingValue !== '' ? props.reportJSON.roundingValue : 2;
    //let baseCurrRoundingValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'), style: 'currency', currency: getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD' }).format(0).toString().split('.')[1].length;
    //let originalCurrencyValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: props.reportJSON.originalCurrencyCode }).format(props.reportJSON.originalFare).toString().match('[a-zA-Z]') === null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: props.reportJSON.originalCurrencyCode }).format(props.reportJSON.originalFare).toString().substring(1) : new Intl.NumberFormat('en-US', { style: 'currency', currency: props.reportJSON.originalCurrencyCode }).format(props.reportJSON.originalFare).toString().substring(3);
    let originalCurrencyDecimalLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: props.reportJSON.originalCurrencyCode }).format('0').split('.')[1].length;

    return <Fragment>
        {
            (props.reportJSON.sequenceNo !== "0") &&
            <tr>
                <td align={'right'}>{props.reportJSON.segmentNo}</td>
                <td scope="row"><a className='cursor-pointer d-flex' onClick={() => openRetrieveBooking(props.reportJSON.confirmationNumber)}><div className='min-width-75'>{props.reportJSON.confirmationNumber} &nbsp;</div> <i className="fas fa-external-link-alt line-height-22px"></i></a></td>
                <td scope="row">{getFormatDate(props.reportJSON.bookingData.split('T')[0].replaceAll('-', ''))} {props.reportJSON.bookingData.split('T')[1] !== undefined ? getFormatTime(props.reportJSON.bookingData.split('T')[1].replaceAll(':', '').substring(0, 4)) : ''}</td>
                <td scope="row">{props.reportJSON.companyName}</td>
                <td scope="row">{props.reportJSON.passengerName}</td>
                <td scope="row" align={'center'}>{props.reportJSON.passengerCount}</td>
                <td scope="row">{props.reportJSON.bookingType}</td>
                <td scope="row">{getFormatDate(props.reportJSON.departureDate)}</td>
                <td scope="row">{props.reportJSON.from + "-" + props.reportJSON.to}</td>
                <td scope="row" align={'center'}>{props.reportJSON.validating}</td>
                <td scope="row">{props.reportJSON.airline}</td>
                {getConfigurationByBoolen("ALLOW_RBD") && <td scope="row" align={'center'}>{props.reportJSON.rbd}</td>}
                <td scope="row" align={'center'}>{props.reportJSON.cabin}</td>
                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <td scope="row">{props.reportJSON.source}</td>}
                <td scope="row">{props.reportJSON.pnr}</td>
                <td scope="row">{props.reportJSON.airlinePNR}</td>
                <td scope="row">{props.reportJSON.status}</td>
                <td scope="row" align={'center'}>{props.reportJSON.currencyCode}</td>
                <td scope="row" align={'right'}>{props.reportJSON.baseFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.baseFare).toFixed(parseFloat(props.reportJSON.baseFare) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.taxes !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.taxes).toFixed(parseFloat(props.reportJSON.taxes) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row" align={'right'}>{props.reportJSON.serviceFee !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.serviceFee).toFixed(parseFloat(props.reportJSON.serviceFee) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>}
                <td scope="row" align={'right'}>{props.reportJSON.markup !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.markup).toFixed(parseFloat(props.reportJSON.markup) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.addOns !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.addOns).toFixed(parseFloat(props.reportJSON.addOns) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.airlineTxnChrg !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.airlineTxnChrg).toFixed(parseFloat(props.reportJSON.airlineTxnChrg) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.airlineCreditCardFees !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.airlineCreditCardFees).toFixed(parseFloat(props.reportJSON.airlineCreditCardFees) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.totalFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.totalFare).toFixed(parseFloat(props.reportJSON.totalFare) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>
                {/*                 <td scope="row" align={'center'}>{props.reportJSON.formOfPayment.toString().toLowerCase() === 'wallet' && props.reportJSON.equivalentCurrencyCode !== '' && props.reportJSON.equivalentCurrencyCode !== 'N/A' ? props.reportJSON.equivalentCurrencyCode : 'N/A'}</td>
                <td scope="row" align={'right'}>{props.reportJSON.formOfPayment.toString().toLowerCase() === 'wallet' && props.reportJSON.walletEquivalentAmount !== '' && props.reportJSON.walletEquivalentAmount !== 'N/A' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.walletEquivalentAmount).toFixed(2)) : 'N/A'}</td> */}
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row" align={'right'}>{props.reportJSON.commission !== '' && props.reportJSON.hideData !== true ? numberWithCommas(parseFloat(props.reportJSON.commission).toFixed(parseFloat(props.reportJSON.commission) > 0 ? resultCurrRoundingValue : 2)) : ''}</td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <td scope="row" align={'center'}>{props.reportJSON.equivalentCurrencyCode !== '' && props.reportJSON.hideData !== true ? props.reportJSON.equivalentCurrencyCode : ''}</td>}
                <td scope="row" align={'right'}>{props.reportJSON.conversionRate !== '' && props.reportJSON.hideData !== true ? props.reportJSON.conversionRate : ''}</td>
                {!getConfigurationByBoolen("DISPLAY_ORIGINAL_FARE") && <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(Number(props.reportJSON.equivTotalFare).toFixed(props.reportJSON.equivTotalFare.toString().split('.').length > 1 ? props.reportJSON.equivTotalFare.toString().split('.')[1].length : 2)) : ''}</td>}
                <td scope="row" align={'center'}>{props.reportJSON.originalCurrencyCode !== '' && props.reportJSON.hideData !== true ? props.reportJSON.originalCurrencyCode : ''}</td>
                <td scope="row" align={'right'}>{props.reportJSON.originalFare !== '' && props.reportJSON.hideData !== true ? numberWithCommas(Number(props.reportJSON.originalFare).toFixed(originalCurrencyDecimalLength)) : ''}</td>
                <td scope="row">{props.reportJSON.formOfPayment}</td>
                <td scope="row">{props.reportJSON.bookedBy}</td>
            </tr>
        }
        {
            (props.reportJSON.sequenceNo === "0" && props.reportJSON.equivTotalFare !== '') &&
            <tr>
                <td></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <td scope="row"></td>}
                <td scope="row"></td>
                <td scope="row"></td>
                <td scope="row"></td>
                {/* <td scope="row">Total</td> */}
                {getConfigurationByBoolen("ALLOW_COMISSION") && <td scope="row"></td>}
                {/* <td scope="row" align={'right'}>{props.reportJSON.equivTotalFare !== '' ? numberWithCommas(parseFloat(props.reportJSON.equivTotalFare).toFixed(2)) : ''}</td> */}
                <td scope="row"></td>
                <td scope="row"></td>
            </tr>
        }

    </Fragment>

};

export default FlightBookingItem;
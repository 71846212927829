import { getCustomizeDetails } from "../../../utils/CustomizeUtils";
const ServiceRequest = (props) => {

    function shouldShowPrice(request) {
        let shouldShowPrice = false;

        if (request.updateAmount && request.updateAmount.length > 0) {
            shouldShowPrice = true;
        }
        else if (request.totalRateOfService && request.totalRateOfService.length > 0) {
            shouldShowPrice = true;
        }

        return shouldShowPrice;
    }

    function getPriceHtml(request) {

        let priceHtml = '';

        if (request.updateAmount && request.updateAmount.length > 0) {
            priceHtml = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: request.updateCurrencyCode,
            }).format(request.updateAmount);
        }
        else if (request.totalRateOfService && request.totalRateOfService.length > 0) {
            priceHtml = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: request.currencyCode,
            }).format(request.totalRateOfService);
        }

        return priceHtml;
    }


    return <>
        {/* <hr className="my-2"></hr> */}
        {/* <p className="h5 mb-3 text-black fw-bold mt-3">Service Requests </p> */}
        <label for="" class="h5 text-primary my-3"><span class="gt-border-1px"> <i class="fas fa-cog"></i> Service Requests</span></label>

        {props.serviceRequest !== undefined && props.serviceRequest.length > 0 &&
            <div>
                <div className="col-lg-12 mb-1">
                    {props.serviceRequest.map(request => (
                        <div key={request.serviceID} className="card text-dark card-bg-blue mb-2">
                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="card-text mb-0 mt-1"><strong>Service </strong>{request.serviceName}</p>
                                        </div>
                                        <div className="col-md-4">
                                            {request.existingConfirmationNumber !== undefined && request.existingConfirmationNumber.length > 0 &&
                                                <p className="card-text mb-0 mt-1"><strong>Confirmation No. </strong>{request.existingConfirmationNumber}</p>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            {shouldShowPrice(request) &&
                                                <p className="card-text mb-0 mt-1"><strong>Price </strong>
                                                    {getPriceHtml(request)}
                                                </p>
                                            }
                                        </div>
                                        <div className="col-md-12">
                                            <hr className="my-2"></hr>
                                        </div>
                                        <div className="col-md-8">
                                            <p className="card-text mb-0 pb-1"><strong>Remarks </strong>
                                                <div dangerouslySetInnerHTML={{ __html: request.remarks }} />
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            {request.servicesAttachment &&
                                                request.servicesAttachment.length > 0 &&
                                                <>
                                                    <p className="card-text mb-0 pb-1"><strong>Attachments </strong> </p>
                                                    {request.servicesAttachment.map((file) =>
                                                        <span key={file.serviceID + file.name}>
                                                            <a href={file.documentBinaryContent}
                                                                download={file.name}>
                                                                {file.name} <i className="fas fa-download"></i>
                                                            </a>
                                                            <br></br>
                                                        </span>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>                
            </div>
        }

    </>
}

export default ServiceRequest;
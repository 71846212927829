import { Fragment } from 'react';
import { removeHtmlTags } from '../../../utils/CommonFunction';

const Overview = (props) => {
    let attaractios = "";
    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Attractions') {
                attaractios = result.text.value;
            }
        });
    }
    let show;
    if (props.hotelDetails.data.hotelResults[0].hotelDescription !== undefined && props.hotelDetails.data.hotelResults[0].hotelDescription !== null) {
        // Remove '\n' from the string
        const removedNewlines = props.hotelDetails.data.hotelResults[0].hotelDescription.replace(/\n/g, ' ');
        const sentences = removedNewlines.split(/(?<!sq|ft|0)\.(?![a-z]| ft(?= [a-z]))/i);
        if (sentences.length !== 0) {
            let desc = sentences.map((item, index) => {
                if (item.trim() !== '') {
                    const sentenceWithFullStop = index !== sentences.length - 1 ? item + '.' : item;
                    if (index === sentences.length - 1) {
                        return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}</li>;
                    }
                    return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}<br /><br /></li>;
                }

            });

            show = <ul>{desc}</ul>;
        }
        else {
            show = removeHtmlTags(props.hotelDetails.data.hotelResults[0].hotelDescription);
        }
    }
    return (<Fragment>
        <div className="col-lg-12">
            <div className="tour_details_leftside_wrapper" id='overview_section'>
                <div className="tour_details_heading_wrapper">
                    <div className="tour_details_top_heading">
                        <h2>{props.hotelDetails.data.hotelResults[0].hotelName !== undefined && removeHtmlTags(props.hotelDetails.data.hotelResults[0].hotelName)}</h2>
                        <h5 style={{ fontSize: "16px" }}><i className="fas fa-map-marker-alt"></i> {props.hotelDetails.data.hotelResults[0].address.addressLine.replace(//g, "")}, {props.hotelDetails.data.hotelResults[0].address.city.cityName}, {props.hotelDetails.data.hotelResults[0].address.country.countryName}</h5>
                    </div>
                </div>

                < div className=" mt-2" style={{ paddingBottom: "4px" }}>
                    <h3 className="heading_theme" style={{ marginBottom: "4px" }}>Overview</h3>
                    <div className='col-md-12 mb-2 display-line' style={{ fontSize: "16px" }}>
                        {show}
                    </div>
                </div>

            </div>
        </div>
    </Fragment >)
}

export default Overview;
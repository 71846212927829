import { Fragment} from 'react';

const HomePageFlight = () => {

    return (<Fragment>

        {/* <!-- Flight home Area --> */}
       
        <section id="offer_area" className="section_padding_top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="offer_area_box  img_animation">
                            <img src="assets/img/offer/flight22.png" alt="img"/>
                            <div className="offer_area_content">
                                <h2 className=''>Special Flight Deals for Employees!</h2>
                                <p>As a employee, you have exclusive access to special discounted rates on flights. Whether you are planning a business trip or a personal getaway, you can easily find and book top-flight options at unbeatable prices. Take advantage of these unique offers to make your travel more affordable and convenient.</p>
                                {/* <a href="#" className="btn btn_theme btn_md">Explore More <i className="fas fa-long-arrow-alt-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="offer_area_box img_animation">
                            <img src="assets/img/offer/offer2.png" alt="img"/>
                            <div className="offer_area_content">
                                <h2>News letter</h2>
                                <p>Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et.</p>
                                <a href="javascript:void(0);" className="btn btn_theme btn_md">Subscribe now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="offer_area_box img_animation">
                            <img src="assets/img/offer/offer3.png" alt="img"/>
                            <div className="offer_area_content">
                                <h2>Travel tips</h2>
                                <p>Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et.</p>
                                <a href="javascript:void(0);" className="btn btn_theme btn_md">Get tips</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
        <section id="go_beyond_area" className="section_padding_top">
            <div className="container">
                <div className="row align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="section_heading_center">
                            <h2>Go beyond your <span>imagination</span></h2>
                            <h5 className='mt-2'>Discover your ideal experience with us</h5>
                            </div>
                        </div>
                    </div>
                 
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/2.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Best Flight<span> Deals</span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/8.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Sydney <span></span></a></h3>
                        </div>
                    </div>
                    
                  
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/9.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Turkey <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/6.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Dubai <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/3.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Travel around<span> the world</span></a></h3>
                        </div>
                    </div>
                   
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/5.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Europe <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/10.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">Los Angeles <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="javascript:void(0);">
                                <img src="assets/img/imagination/7.png" alt="img"/>
                            </a>
                                <h3 className='pb-2'><a href="javascript:void(0);">London <span></span></a></h3>
                        </div>
                    </div>
                   
                    
                </div>
            </div>
        </section>
       
    </Fragment>)
};

export default HomePageFlight;

import {
  DELEGATE_APPROVER,
  GET_DELEGATE_APPROVER,
  DELETE_DELEGATE_APPROVER,
  UPDATE_DELEGATE_APPROVER,
} from "./CONSTANTS";

// Fetch all available delegate approvers
export const getAllDelegateApprovers = async (ID) => {
  try {
    const response = await fetch(GET_DELEGATE_APPROVER(ID), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch delegate approvers");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching delegate approvers:", error);
    throw error;
  }
};

export const deleteDelegateApprovers = async (ID) => {
  try {
    const response = await fetch(DELETE_DELEGATE_APPROVER(ID), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch delegate approvers");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching delegate approvers:", error);
    throw error;
  }
};

// Save the selected delegate approver details
export const saveDelegateApprover = async (approverData) => {
  const response = await fetch(DELEGATE_APPROVER(), {
    method: "POST",
    body: JSON.stringify(approverData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not save DelegateApprover.");
  }

  return data;
};
export const updateDelegateApprover = async (approverUpdateData) => {
  const response = await fetch(UPDATE_DELEGATE_APPROVER(), {
    method: "PUT",
    body: JSON.stringify(approverUpdateData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not save DelegateApprover.");
  }

  return data;
};

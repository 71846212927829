import {
    SEARCH_HIGHRISKDESTINATION,
    DELETE_HIGHRISKDESTINATION,
    GET_HIGHRISKDESTINATION,
    ADD_HIGHRISKDESTINATION,
    UPDATE_HIGHRISKDESTINATION
} from './CONSTANTS';

export async function searchHighRiskDestinations(searchData) {
    const response = await fetch(SEARCH_HIGHRISKDESTINATION(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch high-risk destination.');
    }

    return data;
}

export async function deleteHighRiskDestinations(deleteData) {
    const response = await fetch(DELETE_HIGHRISKDESTINATION(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete high-risk destination.');
    }

    return data;
}

export async function getSingleHighRiskDestination(ID) {

    const response = await fetch(GET_HIGHRISKDESTINATION(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch high-risk destination');
    }
    return data;

}

export async function addHighRiskDestination(addData) {
    const response = await fetch(ADD_HIGHRISKDESTINATION(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not add high-risk destination.');
    }

    return data;
}

export async function updateHighRiskDestination(updateData) {
    const response = await fetch(UPDATE_HIGHRISKDESTINATION(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update high-risk destination.');
    }

    return data;
}
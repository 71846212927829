import { getToken } from '../utils';
import {
    GET_CORPORATE_MASTER,
    ADD_CORPORATE_MASTER,
    UPDATE_CORPORATE_MASTER, 
    SEARCH_CORPORATE_MASTER,
    DELETE_CORPORATE_MASTER   
} from './CONSTANTS'


export async function searchCorporateMaster(searchData) {
    const response = await fetch(SEARCH_CORPORATE_MASTER(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch corporate master.');
    }
    
    return data;
}
export async function getSingleCorporateMaster(ID) {
    const response = await fetch(GET_CORPORATE_MASTER(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch corporate master.');
    }

    return data;
}

export async function addCorporateMaster(addData) {
    console.log(addData)
    const response = await fetch(ADD_CORPORATE_MASTER(), {
        method: 'POST',
        body: JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create corporate master.');
    }
    return data;
}
export async function updateCorporateMaster(updateData) {    
    const response = await fetch(UPDATE_CORPORATE_MASTER(), {
        method: 'PUT',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not update corporate master.');
    }
    return data;
}
export async function deleteCorporateMaster(deleteData) {
    const response = await fetch(DELETE_CORPORATE_MASTER(), {
        method: 'DELETE',
        body: JSON.stringify(deleteData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not delete corporate master.');
    }
    return data;
}


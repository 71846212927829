import { useCallback, useEffect, useState } from "react";
import { checkSecurityDetails } from "../../../utils/RoleUtils";
import { UPDATESTATICPAGE } from "../../../navigation/CONSTANTS";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { searchStaticPage } from "../../../services/StaticPage-API"
import useHttp from "../../../services/use-http";
import StaticPageList from "../StaticPage/Components/StaticPageList"
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import Dropdown from "react-select";
const pageNameOptions = [{ value: "", label: "Please Select" },
    { value: "1", label: "Terms and Conditions" },

]
const SearchStaticPage = () => {
    useDocumentTitle('Search Page Builder');
    const { t } = useTranslation(['translation.StaticPage']);
    const [pageName, setPageName] = useState('');
    const history = useHistory();
    const [error, setError] = useState(false);
    const [isFirstTime, setFirstTime] = useState("");
    const { sendRequest, status, data: loadedStaticPage, error: staticPageError } = useHttp(searchStaticPage);
    const pageNameChangeHandler = (event) => {
        setPageName(event.value);
    }
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        setFirstTime(true);
        const searchInput = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "ipAddress": "",
            "termAndCondition": "",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "pageID": "",
            "pageTitle": ""


        });
        sendRequest(searchInput);

    }, [sendRequest])
    useEffect(() => {
        if (status === 'completed') {
            if (loadedStaticPage['errors'].error['code'] === '1008' || loadedStaticPage['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedStaticPage])
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);
    if (staticPageError) {
        history.push('/Error')
        history.go();
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>

    }
    let loadedData = [];
    for (let i in loadedStaticPage) {
        if (loadedStaticPage['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedStaticPage[i];
            }
        }
    }
    function onResetHandler(event) {
        history.go('/SearchPageBuilder');
    }
    function okayHandler() {
        setError(false);
        history.go('/SearchPageBuilder');

    }
    const staticPageList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <StaticPageList staticPage={loadedData} /> : ""
    return (<>
        {error && <ErrorModal
            title="Message"
            message={`${t('ErrorMessage')}`}
            onConfirm={okayHandler}
        />}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    {status === 'pending' &&
                                        <div className=''>
                                            <div className=''>
                                                <LoadingSpinner></LoadingSpinner>
                                            </div>
                                        </div>
                                    }

                                    <div className="card-body">
                                        <div className="col-md-12 gt-country-details-form">
                                            <label className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('searchStaticPage')}</label>
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12 mb-4">
                                                        <label htmlFor="role" className="mb-2 h6 text-primary col-lg-12">{t('pageName')}</label>
                                                        <Dropdown onChange={pageNameChangeHandler}
                                                            value={pageNameOptions.filter(option => option.value === pageName)}
                                                            options={pageNameOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            autoFocus
                                                        />

                                                    </div>

                                                </div>
                                                <div>
                                                    <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' to={UPDATESTATICPAGE}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Master", "PageBuilder", "Master_PageBuilder_Search") && <button className='btn gt-btn_md text-white gt-main-btn mt-1 float-end ms-1' onClick={onSearchHandler}>
                                                        {t('search')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {staticPageList}
    </>
    );
}

export default SearchStaticPage;